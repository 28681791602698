import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../../../utils/ToastrAction'
import {
    RECEIVE_ZONES_COUNT,
    RECEIVE_CULTURES_COUNT,
    RECEIVE_CONDUITES_COUNT,
    RECEIVE_COMMUNES_COUNT,
    RECEIVE_INDICATEURS_COUNT,
    RECEIVE_ROTATIONS_COUNT,
    RECEIVE_RPG_COUNT,
    RECEIVE_MATRICE_REF_COUNT,
} from '../constants/DashboardConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { checkAuth, getAuthorization, getText } from '../../../../../utils/ActionUtils'

const DashboardAction = {
    fetchEveryCount() {
        return (dispatch) => Promise.all([
            DashboardAction.promiseCulturesCount(),
            DashboardAction.promiseConduitesCount(),
            DashboardAction.promiseZonesCount(),
            DashboardAction.promiseCommunesCount(),
            DashboardAction.promiseIndicateursCount(),
            DashboardAction.promiseRotationABCount(),
            DashboardAction.promiseRotationEcoCount(),
            DashboardAction.promiseRPGCount(),
            DashboardAction.promiseMatriceRefCount(),
        ])
            .then((json) => {
                dispatch(DashboardAction.receiveCulturesCount(json[0]))
                dispatch(DashboardAction.receiveConduitesCount(json[1]))
                dispatch(DashboardAction.receiveZonesCount(json[2]))
                dispatch(DashboardAction.receiveCommunesCount(json[3]))
                dispatch(DashboardAction.receiveIndicateursCount(json[4]))
                dispatch(DashboardAction.receiveRotationsCount(json[5], json[6]))
                dispatch(DashboardAction.receiveRPGCount(json[7]))
                dispatch(DashboardAction.receiveMatriceRefCount(json[8]))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.keyFigures} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.keyFigures)
            })
    },

    receiveZonesCount(zonesCount) {
        return { type: RECEIVE_ZONES_COUNT, zonesCount }
    },

    promiseZonesCount() {
        return fetch(ApplicationConf.referencial.zoneCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveCulturesCount(culturesCount) {
        return { type: RECEIVE_CULTURES_COUNT, culturesCount }
    },

    promiseCulturesCount() {
        return fetch(ApplicationConf.referencial.cultureCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveConduitesCount(conduitesCount) {
        return { type: RECEIVE_CONDUITES_COUNT, conduitesCount }
    },

    promiseConduitesCount() {
        return fetch(ApplicationConf.referencial.conduiteCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveCommunesCount(communesCount) {
        return { type: RECEIVE_COMMUNES_COUNT, communesCount }
    },

    promiseCommunesCount() {
        return fetch(ApplicationConf.referencial.cityCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveRPGCount(rpgCount) {
        return { type: RECEIVE_RPG_COUNT, rpgCount }
    },

    promiseRPGCount() {
        return fetch(ApplicationConf.referencial.countRPG(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveMatriceRefCount(matriceRefCount) {
        return { type: RECEIVE_MATRICE_REF_COUNT, matriceRefCount }
    },

    promiseMatriceRefCount() {
        return fetch(ApplicationConf.referencial.countMatriceRef(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    fetchMatriceRefCount() {
        return (dispatch) => DashboardAction.promiseMatriceRefCount().then((json) => {
            dispatch(DashboardAction.receiveMatriceRefCount(json))
        })
    },

    receiveIndicateursCount(indicateursCount) {
        return { type: RECEIVE_INDICATEURS_COUNT, indicateursCount }
    },

    promiseIndicateursCount() {
        return fetch(ApplicationConf.referencial.indicateursCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveRotationsCount(rotationABCount, rotationEcoCount) {
        const rotationsCount = parseInt(rotationABCount) + parseInt(rotationEcoCount)
        return { type: RECEIVE_ROTATIONS_COUNT, rotationsCount }
    },

    promiseRotationABCount() {
        return fetch(ApplicationConf.referencial.rotationABCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    promiseRotationEcoCount() {
        return fetch(ApplicationConf.referencial.rotationEcoCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },
}

export default DashboardAction
