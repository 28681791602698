import i18n from 'simple-react-i18n'
import AppStore from '../store/Appstore'
import ToastrAction from './ToastrAction'
import ExportAction from './export/actions/ExportAction'

const formatData = (body) => {
    const header = {}
    body[0].headers.map((e) => {
        header[e] = {
            value: (() => {
                try {
                    return i18n[e]
                } catch (err) {
                    return e
                }
            })(),
        }
    })

    const data = [header].concat(body).map((line) => body[0].headers.map((key) => {
        if (line[key] || line[key] == 0) {
            if (line[key].value) {
                const obj = { ...line[key], value: line[key].value.toString() }
                return obj
            }
            return { value: line[key].toString() }
        }
        return { value: '' }
    }))
    return body[0].tableTitle ? [[{ value: body[0].tableTitle }], ...data] : data
}

const exportFileManyTable = (exportData) => {
    if (exportData.data && exportData.data.length) {
        const data = exportData.data.map((t) => formatData(t))
        const dataExport = data.reduce((acc, table) => [...acc, [], [], ...table]) // space between two tables
        AppStore.dispatch(ExportAction.export(dataExport, exportData.exportType, exportData.titleFile))
    } else {
        ToastrAction.error(i18n.noData)
    }
}

const exportFile = (exportData) => {
    if (exportData.data && exportData.data.length) {
        AppStore.dispatch(ExportAction.export(formatData(exportData.data), exportData.exportType, exportData.titleFile))
    } else {
        ToastrAction.error(i18n.noData)
    }
}

export { formatData, exportFile, exportFileManyTable }
