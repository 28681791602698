import PropTypes from 'prop-types'
import DtoUserLayerAssignment from '../dto/DtoUserLayerAssignment'
import KeyFigureDto from '../dto/KeyFigureDto'
import {
    RECEIVE_ALL_BOOKMARKS,
    RECEIVE_HYDROMETRIC_STATIONS_BOOKMARKS,
    RECEIVE_PRODUCTION_UNIT_BOOKMARKS,
    RECEIVE_PIEZOMETER_BOOKMARKS,
    RECEIVE_PLUVIOMETER_BOOKMARKS,
    RECEIVE_QUALITOMETER_BOOKMARKS,
    RECEIVE_USER,
    RECEIVE_USER_CMS_STATISTICS,
    RECEIVE_USER_HABILITATIONS,
    RECEIVE_USER_KEYFIGURES,
    RECEIVE_USER_KEYFIGURES_ALL,
    RECEIVE_USER_LOGIN_HABILITATIONS,
    RECEIVE_USER_STATION_STATISTICS,
    RECEIVE_USER_STATIONS,
    RECEIVE_USER_STATISTIC,
    RECEIVE_USER_THEMES_ASSIGNMENT,
    RECEIVE_USER_USAGE_STATISTICS,
    RECEIVE_USERS,
    RECEIVE_USERS_THEMES_ASSIGNMENT,
    RESET_QUALITOMETER_BOOKMARKS,
    RESET_USER,
    RESET_USER_ATTEMPTS,
    RECEIVE_USER_VIEW,
    RECEIVE_GENERIC_USER_VIEW,
} from '../constants/UserConstants'
import AppStore from '../../../../../store/Appstore'
import UserAction from '../actions/UserAction'
import { arrayOf, instanceOf } from '../../../../../utils/StoreUtils'
import DtoUserStationStatistic from '../dto/DtoUserStationStatistic'
import DtoUserUsageStatistic from '../dto/DtoUserUsageStatistic'
import DtoUser from '../dto/DtoUser'
import DtoUserStatistic from '../dto/DtoUserStatistic'
import DtoUserStation from '../dto/DtoUserStation'
import DtoUserHabilitation from '../dto/DtoUserHabilitation'
import { RECEIVE_ALL_APPLICATION_HABILITATION } from '../constants/ApplicationHabilitationConstants'
import DtoApplicationHabilitation from '../dto/DtoApplicationHabilitation'
import DtoAccountHabilitation from '../../../../account/dto/DtoAccountHabilitation'
import AccountAction from '../../../../account/actions/AccountAction'
import DtoUserCmsStatistic from '../dto/DtoUserCmsStatistic'
import UserViewDto from '../dto/UserViewDto'

export const store = {
    user: {},
    users: [],
    usersThemes: [],
    userThemes: [],
    bookmarks: [],
    qualitometerBookmarks: [],
    piezometerBookmarks: [],
    pluviometerBookmarks: [],
    hydrometricStationBookmarks: [],
    productionUnitBookmarks: [],
    userKeyfiguresAll: [],
    habilitations: [],
    userStatistics: [],
    userKeyFigures: [],
    userStationStatistics: [],
    userUsageStatistics: [],
    userCmsStatistics: [],
    userStations: [],
    userHabilitations: [],
    applicationHabilitations: [],
    userView: [],
    genericUserView: [],
}

export function UserReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_USERS:
            return {
                ...state,
                users: action.users.map((user) => new DtoUser(user)),
            }
        case RECEIVE_USERS_THEMES_ASSIGNMENT:
            return {
                ...state,
                usersThemes: action.usersThemes.map((a) => new DtoUserLayerAssignment(a)),
            }
        case RECEIVE_USER_THEMES_ASSIGNMENT:
            return {
                ...state,
                userThemes: action.userThemes.map((a) => new DtoUserLayerAssignment(a)),
            }
        case RECEIVE_USER:
            return {
                ...state,
                user: new DtoUser(action.user),
            }
        case RESET_USER_ATTEMPTS:
            return {
                ...state,
                user: new DtoUser({
                    ...state.user,
                    connexionAttempts: 0,
                }),
            }
        case RECEIVE_USER_STATISTIC:
            return {
                ...state,
                userStatistics: action.userStatistics.map((el) => new DtoUserStatistic(el)),
            }
        case RECEIVE_USER_KEYFIGURES:
            return {
                ...state,
                userKeyFigures: action.userKeyFigures.map((el) => new KeyFigureDto(el)),
            }
        case RECEIVE_USER_STATION_STATISTICS:
            return {
                ...state,
                userStationStatistics: action.userStationStatistics.map(
                    (el) => new DtoUserStationStatistic(el),
                ),
            }
        case RECEIVE_USER_USAGE_STATISTICS:
            return {
                ...state,
                userUsageStatistics: action.userUsageStatistics.map(
                    (el) => new DtoUserUsageStatistic(el),
                ),
            }
        case RECEIVE_USER_CMS_STATISTICS:
            return {
                ...state,
                userCmsStatistics: action.userCmsStatistics.map(
                    (el) => new DtoUserCmsStatistic(el),
                ),
            }
        case RECEIVE_USER_HABILITATIONS:
            return {
                ...state,
                userHabilitations: action.userHabilitations.map(
                    (el) => new DtoUserHabilitation(el),
                ),
            }
        case RECEIVE_ALL_BOOKMARKS:
            return {
                ...state,
                bookmarks: action.bookmarks,
            }
        case RECEIVE_QUALITOMETER_BOOKMARKS:
            return {
                ...state,
                qualitometerBookmarks: action.bookmarks.map((bookmark) => bookmark.identifiant),
            }
        case RECEIVE_PIEZOMETER_BOOKMARKS:
            return {
                ...state,
                piezometerBookmarks: action.bookmarks.map((bookmark) => bookmark.identifiant),
            }
        case RECEIVE_PLUVIOMETER_BOOKMARKS:
            return {
                ...state,
                pluviometerBookmarks: action.bookmarks.map((bookmark) => bookmark.identifiant),
            }
        case RECEIVE_HYDROMETRIC_STATIONS_BOOKMARKS:
            return {
                ...state,
                hydrometricStationBookmarks: action.bookmarks.map(
                    (bookmark) => bookmark.identifiant,
                ),
            }
        case RECEIVE_PRODUCTION_UNIT_BOOKMARKS:
            return {
                ...state,
                productionUnitBookmarks: action.bookmarks.map((bookmark) => bookmark.identifiant),
            }
        case RECEIVE_USER_KEYFIGURES_ALL:
            return {
                ...state,
                userKeyfiguresAll: action.keyfigures.map(
                    (keyfigure) => new KeyFigureDto(keyfigure),
                ),
            }
        case RECEIVE_USER_LOGIN_HABILITATIONS:
            return {
                ...state,
                habilitations: action.habilitations.map((el) => new DtoUserHabilitation(el)),
            }
        case RESET_QUALITOMETER_BOOKMARKS:
            return {
                ...state,
                qualitometerBookmarks: [],
            }
        case RECEIVE_USER_STATIONS:
            return {
                ...state,
                userStations: action.userStations.map((el) => new DtoUserStation(el)),
            }
        case RECEIVE_ALL_APPLICATION_HABILITATION:
            return {
                ...state,
                applicationHabilitations: action.applicationHabilitations.map(
                    (el) => new DtoApplicationHabilitation(el),
                ),
            }
        case RECEIVE_USER_VIEW:
            return {
                ...state,
                userView: action.views.map((v) => new UserViewDto(v)),
            }
        case RECEIVE_GENERIC_USER_VIEW:
            return {
                ...state,
                genericUserView: action.views.map((v) => new UserViewDto(v)),
            }
        case RESET_USER:
            return {
                ...state,
                user: [],
                qualitometerBookmarks: [],
                piezometerBookmarks: [],
                pluviometerBookmarks: [],
                hydrometricStationBookmarks: [],
                productionUnitBookmarks: [],
                habilitations: [],
                userCmsStatistics: [],
                userView: [],
                genericUserView: [],
            }
        default:
            return state
    }
}

export const UserStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        qualitometerBookmarks: {
            fetch: UserAction.fetchBookmarks,
            store: appStore.UserReducer.qualitometerBookmarks,
            reset: UserAction.resetBookmarks,
            propType: arrayOf(PropTypes.string),
            add: UserAction.setBookmark,
            delete: UserAction.setBookmark,
        },
        piezometerBookmarks: {
            fetch: UserAction.fetchBookmarks,
            store: appStore.UserReducer.piezometerBookmarks,
            reset: UserAction.resetBookmarks,
            propType: arrayOf(PropTypes.string),
            add: UserAction.setBookmark,
            delete: UserAction.setBookmark,
        },
        pluviometerBookmarks: {
            fetch: UserAction.fetchBookmarks,
            store: appStore.UserReducer.pluviometerBookmarks,
            reset: UserAction.resetBookmarks,
            propType: arrayOf(PropTypes.string),
            add: UserAction.setBookmark,
            delete: UserAction.setBookmark,
        },
        hydrometricStationBookmarks: {
            fetch: UserAction.fetchBookmarks,
            store: appStore.UserReducer.hydrometricStationBookmarks,
            reset: UserAction.resetBookmarks,
            propType: arrayOf(PropTypes.string),
            add: UserAction.setBookmark,
            delete: UserAction.setBookmark,
        },
        productionUnitBookmarks: {
            fetch: UserAction.fetchBookmarks,
            store: appStore.UserReducer.productionUnitBookmarks,
            reset: UserAction.resetBookmarks,
            propType: arrayOf(PropTypes.string),
            add: UserAction.setBookmark,
            delete: UserAction.setBookmark,
        },
        userKeyfigures: {
            fetch: UserAction.fetchUserKeyFigures,
            store: appStore.UserReducer.userKeyfiguresAll,
            propType: arrayOf(KeyFigureDto),
        },
        user: {
            fetch: UserAction.fetchUser,
            store: appStore.UserReducer.user,
            propType: instanceOf(DtoUser),
            reset: UserAction.reset,
        },
        users: {
            fetch: UserAction.fetchUsers,
            store: appStore.UserReducer.users,
            propType: arrayOf(DtoUser),
        },
        habilitations: {
            fetch: AccountAction.fetchAccountHabilitations,
            store: appStore.AccountReducer.accountHabilitations,
            propType: arrayOf(DtoAccountHabilitation),
        },
    }
}
