import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SubList from '../simpleList/SubList'

const Wrapper = styled.div`
    background-color: transparent;
    margin: 0;
    display: block;
    width: 100%;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
`

class SubCardList extends SubList {
    render() {
        const colorfulCards = this.props.cards.map((card, i) => (
            <Wrapper key={i}>
                {card.content}
            </Wrapper>
        ))
        return this.getSuperComponent(colorfulCards)
    }
}

SubCardList.propTypes = {
    title: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        onClick: PropTypes.func,
        content: PropTypes.element.isRequired,
    })).isRequired,
}

export default SubCardList
