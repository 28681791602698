/* eslint-disable no-case-declarations */
import Card from '@material-ui/core/Card'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { orderBy } from 'lodash'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { Grid, Icon, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ReactEcharts from 'echarts-for-react'
import ActionComponent from '../../components/actions/ActionComponent'
import Input from '../../components/forms/Input'
import ModalElementaryBricks from './ModalElementaryBricks'
import ModalWarningDescriptif from './modal/ModalWarningDescriptif'
import { Flex } from '../../styles/main'
import { greenChart, redChart } from '../../styles/theme'
import { getLogin, getUser } from '../../utils/SettingUtils'
import HomeAction from '../home/actions/HomeAction'
import UserAction from '../administration/components/user/actions/UserAction'
import { GreenUploadLabel } from '../../styles/button'
import ToastrAction from '../../utils/ToastrAction'
import { round } from '../../utils/NumberUtil'
import { formatMilliers } from '../../utils/StringUtil'
import TerritoryAction from './actions/TerritoryAction'
import ProgressBar from '../../components/ProgressBar'
import { PinkBloc, PinkTitle } from '../../styles/container'
import { getSandreList } from '../../utils/StoreUtils'

const CardStyle = styled(Card)`
    padding: 3rem;
`

const Number = styled.div`
    font-size: 1.1rem;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

const IconRow = styled(Flex)`
    justify-content: space-around;
    position: relative;
    padding: 2rem 0;
`

const DldIcon = styled(Icon)`
    font-size: 1rem !important;
    display: inline-block;
    margin: 0 !important;
    color: #f9f8f6;
`

const DldIconText = styled.span`
    color: #f9f8f6;
    display: inline-block;
    transform: translate(-2px, -3px);
`

const ChartCulturesRPG = ({ data, legendsData, fileTitle, legendParams }) => {
    const options = useMemo(() => ({
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {
                    show: true,
                    title: 'Export',
                    name: fileTitle,
                },
            },
        },
        legend: {
            data: legendsData, // A générer
            orient: 'vertical',
            top: 10,
            left: 0,
            ...legendParams,
        },
        tooltip: {
            formatter: (params) => `${params.name} : ${formatMilliers(params.value)} ha`,
        },
        xAxis: {
            name: 'Surface cumulée (ha)',
            nameLocation: 'center',
            nameGap: 25,
        },
        yAxis: {
            data: data.map((s) => s.culture),
            axisLine: { onZero: true },
            splitLine: { show: true },
            splitArea: { show: false },
        },
        grid: {
            left: 250,
            right: 150,
        },
        series: {
            type: 'bar',
            color: greenChart,
            label: {
                show: true,
                position: 'inside',
                align: 'left',
                formatter: (params) => `${formatMilliers(params.value)} ha`,
            },
            data: data.map((s) => s.surface),
        },
    }), [data, legendsData, fileTitle, legendParams])

    return (
        <ReactEcharts
            option={options}
            style={{ height: '500px', width: '100%' }}
            className="react_for_echarts"
        />
    )
}

ChartCulturesRPG.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    legendsData: PropTypes.arrayOf(PropTypes.shape({})),
    legendParams: PropTypes.instanceOf(PropTypes.shape({})),
    fileTitle: PropTypes.string,
}

const selectDisabledMode = {
    backgroundColor: 'rgb(240, 240, 240)',
    height: '48px',
    width: '100%',
}

class TerritoryPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            currentUsers: [],
            currentZones: [],
            currentCultures: [],
            currentModesConduites: [],
            territory: props.territory,
            users: [],
            isEditMode: false,
            open: false,
            surfacesRPGLoaded: props.newTerritory,
            dataLoaded: false,
        }
    }

    componentDidMount() {
        const { isEditMode } = this.state
        const { newTerritory, territory, loadingSurfacesRPG, applicationSettings } = this.props
        if (!newTerritory) {
            if (!loadingSurfacesRPG) {
                this.props.fetchSurfacesRPGTerritory(territory.id).then(() => this.setState({ surfacesRPGLoaded: true }))
            }
            if (isEditMode) {
                this.setEditModeDescriptif()
            } else {
                this.setReadModeDescriptif()
            }
        }
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
            {
                title: i18n.new,
                href: 'territories/new',
            },
        ])
        this.props.fetchUsers().then(() => {
            const { users } = this.props
            if (users && users.length > 0) {
                const usersFiltered = getUser().isAdmin === '1' || getUser().consultant === '1'
                    ? users
                    : users.filter((u) => u.login !== getLogin())
                const usersFormatted = usersFiltered.map((u) => {
                    if (u.isAdmin === '1') {
                        return {
                            login: u.login,
                            role: i18n.admin,
                        }
                    }
                    if (u.labo === '1') {
                        return {
                            login: u.login,
                            role: i18n.animator,
                        }
                    }
                    if (u.consultant === '1') {
                        return {
                            login: u.login,
                            role: i18n.consultant,
                        }
                    }
                    return {
                        login: u.login,
                        role: i18n.noRole,
                    }
                })
                this.setState({ users: usersFormatted })
            }
        })
        this.props.setHelpPath('creer-un-territoire/definir-les-zones-cultures-modes/', applicationSettings)
    }

    setEditModeDescriptif = (afterWarning = false) => {
        const { haveMatrices, zonesSurfaces, assolements } = this.props
        if (haveMatrices) {
            this.props.modalUpdateIsOpen()
        } else if (
            (zonesSurfaces.length > 0 || assolements.length > 0)
            && !afterWarning
        ) {
            this.modalWarningIsOpen()
        } else {
            this.setState({ isEditMode: true })
            this.props.toggleEditMode(true)
            this.setActions({
                save: () => {
                    this.props.onSaveEvent()
                    this.setReadModeDescriptif()
                },
                cancel: () => {
                    this.props.setInitialTerritory()
                    this.setReadModeDescriptif()
                },
            })
        }
    }

    setReadModeDescriptif = () => {
        this.setState({ isEditMode: false })
        this.props.toggleEditMode(false)
        const { territory } = this.props
        const { statut, referencial } = territory
        const isAdmin = getUser().isAdmin === '1'
        const anim = getUser().labo === '1'
        if (referencial) {
            if (isAdmin) {
                this.setActions({
                    edit: () => this.setEditModeDescriptif(),
                    duplicate: () => {
                        this.props.onDuplicateEvent()
                    },
                    deleteCheck: () => this.props.modalIsOpen(),
                })
            } else if (anim) {
                this.setActions({
                    duplicate: () => {
                        this.props.onDuplicateEvent()
                    },
                })
            }
        } else if ((statut === 0 || statut === 1) && (isAdmin || anim)) {
            this.setActions({
                edit: () => this.setEditModeDescriptif(),
                duplicate: () => {
                    this.props.onDuplicateEvent()
                },
                deleteCheck: () => this.props.modalIsOpen(),
            })
        } else if (statut === 2) {
            if (isAdmin) {
                this.setActions({
                    edit: () => this.setEditModeDescriptif(),
                    deleteCheck: () => this.props.modalIsOpen(),
                })
            }
            if (anim) {
                this.setActions({
                    deleteCheck: () => this.props.modalIsOpen(),
                })
            }
        }
    }

    onChangeTerritory = (value) => {
        this.props.onChangeTerritory({
            ...this.props.territory,
            ...value,
        })
    }

    onChangeArrayTerritory = (key, value) => {
        const formattedData = value.map((v) => {
            if (key === 'utilisateurs') {
                return {
                    id: this.props.territoryDetail ? this.props.territory.id : -1,
                    login: v,
                    usermaj: getLogin(),
                }
            }
            return {
                id: this.props.territoryDetail ? this.props.territory.id : -1,
                dataId: v,
                usermaj: getLogin(),
            }
        })
        this.props.onChangeTerritory({
            ...this.props.territory,
            [key]: formattedData,
        })
    }

    onChangeUsers = (key, value) => {
        const formattedData = value.map((v) => ({
            id: this.props.territory.id,
            login: v,
            usermaj: getLogin(),
        }))
        this.props.onChangeTerritoryUsers(formattedData)
    }

    onChangeSelected = (key, value) => {
        this.setState({
            [key]: value,
        })
    }

    onCreateData = (key, id) => {
        const { currentZones, currentCultures, currentModesConduites } = this.state
        switch (key) {
            case 'zones':
                const newCurrentZones = [...currentZones, id]
                this.setState({ currentZones: newCurrentZones })
                break
            case 'cultures':
                const newCurrentCultures = [...currentCultures, id]
                this.setState({ currentCultures: newCurrentCultures })
                break
            case 'conduites':
                const newCurrentConduites = [...currentModesConduites, id]
                this.setState({ currentModesConduites: newCurrentConduites })
                break
            default:
                break
        }
    }

    modalWarningIsOpen = () => {
        this.setState({ open: true })
    }

    modalWarningIsClose = () => {
        this.setState({ open: false })
    }

    getNumberUsers = (territory) => {
        if (getUser().labo === '1') {
            return this.props.newTerritory
                ? territory.utilisateurs.length
                : territory.utilisateurs.length - 1
        }
        return territory.utilisateurs.length
    }

    onChangeTerritoryDescriptif = (value) => {
        if (this.props.newTerritory) {
            this.onChangeTerritory(value)
        } else {
            this.onChangeTerritory(value)
            if (!this.state.isEditMode) {
                this.props.onChangeTerritoryDescriptif(value)
            }
        }
    }

    getSelectedDatas = () => {
        const { currentZones, currentCultures, currentModesConduites } = this.state
        const { territory, zones, cultures, conduites } = this.props

        const selectionZones = []
        if (currentZones.length > 0) {
            currentZones.forEach((z) => {
                const zoneFind = zones.find((propsZone) => propsZone.id === z)
                if (zoneFind) {
                    selectionZones.push({
                        id: territory.id,
                        dataId: zoneFind.id,
                    })
                } else {
                    selectionZones.push({
                        id: territory.id,
                        dataId: z,
                    })
                }
            })
        }
        
        const selectionCultures = []
        if (currentCultures.length > 0) {
            currentCultures.forEach((c) => {
                const cultureFind = cultures.find((propsCulture) => propsCulture.id === c)
                if (cultureFind) {
                    selectionCultures.push({
                        id: territory.id,
                        dataId: cultureFind.id,
                    })
                } else {
                    selectionCultures.push({
                        id: territory.id,
                        dataId: c,
                    })
                }
            })
        }

        const selectionConduites = []
        if (currentModesConduites.length > 0) {
            currentModesConduites.forEach((mc) => {
                const conduiteFind = conduites.find((propsConduite) => propsConduite.id === mc)
                if (conduiteFind) {
                    selectionConduites.push({
                        id: territory.id,
                        dataId: conduiteFind.id,
                    })
                } else {
                    selectionConduites.push({
                        id: territory.id,
                        dataId: mc,
                    })
                }
            })
        }

        return [selectionZones, selectionCultures, selectionConduites]
    }

    onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            const file = event.target.files[0]
            reader.readAsText(file, 'UTF-8')
            reader.onload = (e) => {
                this.onChangeTerritory({ department: undefined, kml: e.target.result, kmlFileName: file.name, cityCode: undefined })
            }
            reader.onerror = () => this.props.toastError('Erreur lors de la lecture du fichier')
        }
    }

    getDataChartCultures = (surfacesCultures) => {
        const surfaceTotal = surfacesCultures.map((s) => s.surface).reduce((acc, s) => acc + s || 0, 0)
        let pourcentTotal = 0
        let othersCulturesSurf = 0
        const culturesToShow = []
        orderBy(surfacesCultures, 'surface', 'desc').forEach((s) => {
            if (pourcentTotal < 90) {
                const p = (s.surface * 100) / surfaceTotal
                pourcentTotal += p
                culturesToShow.push({ ...s, surfValue: s.surface, surface: round(s.surface, 0) })
            } else {
                othersCulturesSurf += s.surface
            }
        })
        return orderBy([...culturesToShow, { culture: i18n.othersCultures,
            surfValue: othersCulturesSurf,
            surface: 
            {
                value: round(othersCulturesSurf, 0),
                itemStyle: {
                    color: redChart,
                },
            } }], 'surfValue')
    }

    render() {
        const { currentUsers, currentZones, currentCultures, currentModesConduites, users, isEditMode, open, surfacesRPGLoaded } = this.state
        const { territory, newTerritory, territoryDetail, communes, citiesIndex, zones, cultures, conduites, surfacesRPG, sandreCodes } = this.props
        const statutOptions = [
            { value: 0, label: i18n.draft },
            { value: 1, label: i18n.published },
            { value: 2, label: i18n.archived },
        ]

        const editable = (() => {
            if (newTerritory) {
                return true
            } if (territory.statut !== 2) {
                return isEditMode
            }
            return false
        })()

        const selectedDatas = this.getSelectedDatas()

        return (
            <CardStyle>
                <Flex>
                    <div style={{ marginRight: 10 }}>
                        <Label htmlFor="descriptive_name">{`${i18n.name} :`}</Label>
                        <Input
                            disabled={!editable}
                            style={!editable ? { backgroundColor: 'rgb(240,240,240)' } : {}}
                            onChange={(v) => this.onChangeTerritory({ name: v })}
                            value={territory ? territory.name : null}
                        />
                    </div>
                    <FormControl variant="outlined" style={{ marginRight: 10 }}>
                        <Label htmlFor="statut_type">{`${i18n.status} :`}</Label>
                        <Select
                            disabled={!(getUser().isAdmin === '1' || getUser().labo === '1')}
                            style={
                                !(getUser().isAdmin === '1' || getUser().labo === '1')
                                    ? selectDisabledMode
                                    : { height: '48px', width: '200px' }
                            }
                            value={territory.statut ? territory.statut : 0}
                            onChange={(v) => this.onChangeTerritoryDescriptif({ statut: v.target.value })}
                        >
                            {statutOptions.map((item) => (
                                <MenuItem value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginRight: 10 }}>
                        <Label htmlFor="territoireRef">{`${i18n.territoireRef} :`}</Label>
                        <Select
                            disabled={getUser().isAdmin !== '1'}
                            style={
                                getUser().isAdmin !== '1'
                                    ? selectDisabledMode
                                    : { height: '48px', width: '200px' }
                            }
                            value={territory.referencial}
                            onChange={(v) => this.onChangeTerritoryDescriptif({ referencial: v.target.value })}
                        >
                            <MenuItem value={false}>{i18n.no}</MenuItem>
                            <MenuItem value>{i18n.yes}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginRight: 10 }}>
                        <Label htmlFor="territoireRef">{`${i18n.year} :`}</Label>
                        <Select
                            style={{ height: '48px', width: '200px' }}
                            value={territory.year}
                            onChange={(e) => this.onChangeTerritoryDescriptif({ year: e.target.value })}
                        >
                            {getSandreList(sandreCodes, 'RPG.ANNEE').map((item) => (
                                <MenuItem value={parseInt(item.name)}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {!territory.referencial && (
                        <Flex>
                            <ModalElementaryBricks
                                user
                                iconName="person"
                                text=""
                                usersData={users}
                                onSave={
                                    territoryDetail && !editable
                                        ? this.onChangeUsers
                                        : this.onChangeArrayTerritory
                                }
                                onChange={this.onChangeSelected}
                                selectedUsers={territory.utilisateurs}
                                currentUsers={currentUsers}
                                clickable
                                territoryId={newTerritory ? -1 : territory.id}
                                isEditMode={
                                    getUser().isAdmin === '1'
                                    || (getUser().labo === '1' && !territory.referencial)
                                }
                            />
                            <Number style={{ display: 'flex', alignItems: 'center' }}>{this.getNumberUsers(territory)}</Number>
                        </Flex>
                    )}
                </Flex>
                <PinkBloc>
                    <IconRow>
                        <PinkTitle>Localiser mon territoire (optionnel)</PinkTitle>
                        <Grid container>
                            <Grid item xs={3} style={{ paddingLeft: 10 }}>
                                <Label htmlFor="department">{`${i18n.number} ${i18n.department} :`}</Label>
                                <Input
                                    style={!editable ? selectDisabledMode : { height: '48px' }}
                                    divStyle={{ width: '100%' }}
                                    value={territory.department}
                                    onChange={(v) => this.onChangeTerritory({ department: v, kml: undefined, kmlFileName: '', cityCode: undefined })}
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item style={{ alignItems: 'center', marginRight: 10 }}>
                                Ou
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" style={{ marginRight: 10, width: '100%' }}>
                                    <Label htmlFor="communes">{`${i18n.city} :`}</Label>
                                    <Autocomplete
                                        id="territoryCity"
                                        name="communes"
                                        variant="outlined"
                                        value={territory.cityCode ? citiesIndex[territory.cityCode] : undefined}
                                        options={orderBy(communes, 'id')}
                                        onChange={(e, option) => this.onChangeTerritory({ department: undefined, kml: undefined, kmlFileName: '', cityCode: option?.id })}
                                        getOptionLabel={(option) => option.codeWithName || ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                style={!editable ? { backgroundColor: 'rgb(240, 240, 240)', height: '48px' } : { height: '48px', width: '100%' }}
                                            />
                                        )}
                                        key={territory?.cityCode}
                                        disabled={!editable}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item style={{ alignItems: 'center', marginRight: 10 }}>
                                Ou
                            </Grid>
                            <Grid item xs={3} container direction="column">
                                <input
                                    name="file"
                                    accept=".kml, .KML"
                                    id="uploadKML"
                                    type="file"
                                    className="uploadFileInput"
                                    onChange={this.onUploadFile}
                                    disabled={!editable}
                                />
                                {territory.kmlFileName || 'Sélectionner un fichier :'}
                                <GreenUploadLabel for="uploadKML" disabled={!editable}>
                                    <DldIcon>file_upload</DldIcon>&nbsp;&nbsp;
                                    <DldIconText>Fichier KML</DldIconText>
                                </GreenUploadLabel>
                            </Grid>
                        </Grid>
                    </IconRow>
                </PinkBloc>
                {!surfacesRPGLoaded && <ProgressBar />}
                {surfacesRPGLoaded && surfacesRPG.length > 0 && (
                    <ChartCulturesRPG
                        data={this.getDataChartCultures(surfacesRPG)}
                        legendsData={['Cultures']}
                        fileTitle={`${territory.name} - ${i18n.cultures} RPG`}
                    />
                )}
                <PinkBloc>
                    <IconRow>
                        <PinkTitle>Briques élémentaires</PinkTitle>
                        <ModalElementaryBricks
                            zone
                            iconName="map"
                            number={territory.zones.length}
                            text={territory.zones.length > 1 ? i18n.zones : i18n.zone}
                            zonesData={zones}
                            onSave={this.onChangeArrayTerritory}
                            onChange={this.onChangeSelected}
                            onCreateData={this.onCreateData}
                            selectedZones={selectedDatas[0].length > 0 ? selectedDatas[0] : territory.zones}
                            currentZones={currentZones}
                            clickable
                            territoryId={newTerritory ? -1 : territory.id}
                            isEditMode={editable}
                            addField={getUser().isAdmin === '1' || getUser().labo === '1'}
                        />
                        <ModalElementaryBricks
                            culture
                            iconName="local_florist"
                            number={territory.cultures.length}
                            text={territory.cultures.length > 1 ? i18n.cultures : i18n.culture}
                            culturesData={cultures}
                            onSave={this.onChangeArrayTerritory}
                            onChange={this.onChangeSelected}
                            onCreateData={this.onCreateData}
                            selectedCultures={
                                selectedDatas[1].length > 0 ? selectedDatas[1] : territory.cultures
                            }
                            currentCultures={currentCultures}
                            clickable
                            territoryId={newTerritory ? -1 : territory.id}
                            isEditMode={editable}
                            addField={getUser().isAdmin === '1' || getUser().labo === '1'}
                        />
                        <ModalElementaryBricks
                            driveMode
                            iconName="speed"
                            number={territory.modesConduites.length}
                            text={
                                territory.modesConduites.length > 1 ? i18n.conduites : i18n.conduite
                            }
                            conduitesData={conduites}
                            onSave={this.onChangeArrayTerritory}
                            onChange={this.onChangeSelected}
                            onCreateData={this.onCreateData}
                            selectedModesConduites={
                                selectedDatas[2].length > 0
                                    ? selectedDatas[2]
                                    : territory.modesConduites
                            }
                            currentModesConduites={currentModesConduites}
                            clickable
                            territoryId={newTerritory ? -1 : territory.id}
                            isEditMode={editable}
                            addField={getUser().isAdmin === '1' || getUser().labo === '1'}
                        />
                        {open && (
                            <ModalWarningDescriptif
                                handleClose={this.modalWarningIsClose}
                                setEditMode={this.setEditModeDescriptif}
                                open={open}
                            />
                        )}
                    </IconRow>
                </PinkBloc>
            </CardStyle>
        )
    }
}

TerritoryPanel.defaultProps = {
    warningModal: false,
}
TerritoryPanel.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        contentId: PropTypes.number,
    }),
    warningModal: PropTypes.bool,
    onChangeTerritoryUsers: PropTypes.func,
    fetchSurfacesRPGTerritory: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    assolements: store.TerritoryReducer.assolements,
    zonesSurfaces: store.TerritoryReducer.zonesSurfaces,
    surfacesRPG: store.TerritoryReducer.surfacesRPG,
    loadingSurfacesRPG: store.TerritoryReducer.loadingSurfacesRPG,
    communes: store.ReferencialReducer.communes,
    citiesIndex: store.ReferencialReducer.citiesIndex,
    users: store.UserReducer.users,
})

const mapDispatchToProps = {
    fetchUsers: UserAction.fetchUsers,
    setHelpPath: HomeAction.setHelpPath,
    fetchSurfacesRPGTerritory: TerritoryAction.fetchSurfacesRPGTerritory,
    setTitle: HomeAction.setTitle,
    toastError: ToastrAction.error,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryPanel)
