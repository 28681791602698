import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'

import '../../main.scss'

class Toaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openToaster: true,
        }
    }

    customToaster = (variant, message) => {
        const variantIcon = {
            success: CheckCircleIcon,
            warning: WarningIcon,
            error: ErrorIcon,
            info: InfoIcon,
        }

        const classes = makeStyles((theme) => ({
            icon: {
                fontSize: 20,
            },
            iconVariant: {
                opacity: 0.9,
                marginRight: theme.spacing(1),
            },
            message: {
                display: 'flex',
                alignItems: 'center',
            },
        }))
        const Icon = variantIcon[variant]

        return (
            <Snackbar
                className={`toasterComponent ${variant}`}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.openToaster}
                autoHideDuration={2000}
                onClick={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <SnackbarContent
                    className="toasterContent"
                    aria-describedby="client-snackbar"
                    message={
                        (
                            <span id="client-snackbar" className={classes.message}>
                                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                                {message}
                            </span>
                        )
                    }
                />
            </Snackbar>
        )
    }

    handleClose = () => {
        this.setState({ openToaster: false })
    }

    render() {
        return this.customToaster(this.props.variant, this.props.message)
    }
}

Toaster.propTypes = {
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    message: PropTypes.string.isRequired,
}

export default Toaster
