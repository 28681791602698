import DtoReferencial from '../../../dto/DtoReferencial'

export default class CommuneDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.codeCommune = object.id
        this.name = object.name
        this.situation = object.region_id
        this.departmentNumber = object.departmentNumber
        this.area = object.area
        this.countryCode = object.countryCode
        this.x = object.x
        this.y = object.y
        this.projection = object.projection
        this.codeWithName = `[${object.id}] ${object.name}`
        this.headers = ['codeCommune', 'name', 'situation', 'departmentNumber', 'area', 'countryCode', 'x', 'y', 'projection']
    }
}
