/* eslint-disable import/no-cycle */
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { WaitReducer, ToastrReducer } from 'react-aquasys'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import { JobReducer, store as JobReducerStore } from '../utils/import/reducers/JobReducer'
import { HomeReducer, store as HomeReducerStore } from '../pages/home/reducers/HomeReducer'
import {
    AccountReducer,
    store as AccountReducerStore,
} from '../pages/account/reducers/AccountReducer'
import {
    ContactReducer,
    store as ContactReducerStore,
} from '../pages/referencial/components/contact/reducers/ContactReducer'
import {
    ContributorReducer,
    store as ContributorReducerStore,
} from '../pages/referencial/components/contributor/reducers/ContributorReducer'
import { EventsReducer, store as EventsReducerStore } from '../pages/events/reducers/EventsReducer'
import {
    ReferencialReducer,
    store as ReferencialReducerStore,
} from '../pages/referencial/reducers/ReferencialReducer'
import {
    UserReducer,
    store as UserReducerStore,
} from '../pages/administration/components/user/reducers/UserReducer'
import {
    DashboardReducer,
    store as DashboardReducerStore,
} from '../pages/referencial/components/dashboard/reducers/DashboardReducer'
import { SieauReducer, store as SieauReducerStore } from '../components/sieau/SieauReducer'
import {
    AdministrationReducer,
    store as AdministrationReducerStore,
} from '../pages/administration/reducers/AdministrationReducer'
import {
    TerritoryReducer,
    store as TerritoryReducerStore,
} from '../pages/territory/reducers/TerritoryReducer'
import { SSEReducer, SSEStore as SSEReducerStore } from '../sse/reducers/SSEReducer'

export const history = createHashHistory()

const middlewares = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}
const enhancer = applyMiddleware(...middlewares)

const initialStore = {
    JobReducer: JobReducerStore,
    HomeReducer: HomeReducerStore,
    DashboardReducer: DashboardReducerStore,
    AccountReducer: AccountReducerStore,
    ReferencialReducer: ReferencialReducerStore,
    ContactReducer: ContactReducerStore,
    ContributorReducer: ContributorReducerStore,
    EventsReducer: EventsReducerStore,
    UserReducer: UserReducerStore,
    SieauReducer: SieauReducerStore,
    AdministrationReducer: AdministrationReducerStore,
    TerritoryReducer: TerritoryReducerStore,
    SSEReducer: SSEReducerStore,
    WaitReducer: {
        isWait: false,
        name: 'ball-grid-pulse',
        color: 'blue',
        fadeIn: 'none',
    },
    ToastrReducer: {
        success: '',
        error: '',
    },
}

const appReducers = combineReducers({
    ReferencialReducer,
    ContactReducer,
    ContributorReducer,
    EventsReducer,
    JobReducer,
    AccountReducer,
    HomeReducer,
    WaitReducer,
    ToastrReducer,
    DashboardReducer,
    UserReducer,
    SieauReducer,
    AdministrationReducer,
    TerritoryReducer,
    SSEReducer,
    router: connectRouter(history),
})

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.AdministrationReducer
        const administrationReducer = {
            ...initialStore.AdministrationReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            AdministrationReducer: administrationReducer,
        }
    }
    return appReducers(state, action)
}

const AppStore = createStore(rootReducer, initialStore, enhancer)

export default AppStore
