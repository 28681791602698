import React, { Component } from 'react'
import ol from 'openlayers'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import PopupContent from './PopupContent'

export default class Popup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            feature: [],
        }
    }

    getDefaultProps() {
        return {
            olMap: {},
            event: {},
            layers: [],
            popupProps: { filters: [] },
        }
    }

    componentDidMount() {
        const container = ReactDOM.findDOMNode(this.refs.popup)
        this.popup = new ol.Overlay({
            positioning: 'bottom-center',
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            offset: [0, 0],
        })
        this.props.olMap.addOverlay(this.popup)
        this.popup.setElement(container)
    }

    componentWillReceiveProps(nextProps) {
        const arr = []
        const { popup } = this
        nextProps.olMap.forEachFeatureAtPixel(this.props.olMap.getEventPixel(nextProps.event), (feature) => {
            const havePopup = (() => {
                const layer = this.props.layers.find((la) => la.checkUuid(feature.getId()))
                if (layer) {
                    return layer.getPopup()
                }
                return null
            })
            const isOnFilter = this.props.popupProps.filters.includes(feature.getGeometry().getType())
            if (havePopup && isOnFilter) {
                arr.push(feature)
            }
        })
        if (arr.length > 0) {
            this.setState({ feature: arr })
            popup.setPosition(this.props.olMap.getCoordinateFromPixel(this.props.olMap.getEventPixel(nextProps.event)))
        } else {
            popup.setPosition(undefined)
        }
    }

    getContent() {
        if (this.state.feature.length > 0) {
            const filterLayers = this.state.feature.map((ft) => this.props.layers.find((la) => la.checkUuid(ft.getId())))
            return (<PopupContent features={this.state.feature} layers={filterLayers} />)
        }
        return []
    }

    render() {
        const popupContent = this.getContent()
        return (
            <div ref="popup" className="ol-popup">
                { popupContent }
            </div>
        )
    }
}

Popup.propTypes = ({
    olMap: PropTypes.instanceOf(ol.Map),
    event: PropTypes.shape({ clientX: PropTypes.number, clientY: PropTypes.number }),
    layers: PropTypes.arrayOf(PropTypes.object),
    popupProps: PropTypes.shape({ filters: PropTypes.arrayOf(PropTypes.string) }),
})
