/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEqual, min, max } from 'lodash'
import {
    Icon,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Card,
    CircularProgress,
    Divider,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import ToastrAction from '../../../utils/ToastrAction'
import ActionComponent from '../../../components/actions/ActionComponent'
import { Flex } from '../../../styles/main'
import { GreenButton } from '../../../styles/button'
import AgGridTable from '../../../components/datatable/AgGridTable'
import { getNameById, pourcentageFormatter } from '../../../utils/ArrayUtils'
import { getFullDateMini } from '../../../utils/DateUtil'
import { formatFloat } from '../../../utils/NumberUtil'
import TerritoryAction from '../actions/TerritoryAction'
import EchartMap from '../../../components/datatable/EchartMap'
import EchartBar from '../../../components/datatable/EchartBar'
import { mainBlue } from '../../../styles/theme'
import HomeAction from '../../home/actions/HomeAction'

const sortByZone = (a, b) => {
    const idA = a.zid
    const idB = b.zid
    let comparison = 0
    if (idA && idB) {
        if (idA > idB) {
            comparison = 1
        } else if (idA < idB) {
            comparison = -1
        }
        return comparison
    }
    return comparison
}

const CardStyle = styled(Card)`
    margin: 0;
    padding: 0 3rem;
`

const HeaderGraph = styled(Grid)`
    width: 100%;
    height: 58px;
    padding: 0 20px;
    color: white;
    background-color: ${mainBlue};
`

const HeaderTitle = styled.span`
    color: white;
    font-size: 1.5rem;
`

const DldIcon = styled(Icon)`
    font-size: 1rem !important;
    display: inline-block;
    margin: 0 !important;
    color: #f9f8f6;
`

const IconButton = styled(Icon)`
    font-size: 2.5rem !important;
    background-color: black;
    width: 50px !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
`

const tableStyle = {
    marginTop: 20,
    height: 480,
    width: '100%',
}

const selectEditMode = {
    height: 48,
    width: 'fit-content',
    marginRight: 5,
}

class ScenarResult extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            editMode: this.props.editMode,
            isRunning: this.props.isRunning,
            exportLoading: false,
            columnDefsSurface: [
                {
                    headerName: i18n.zones,
                    field: 'zid',
                },
                {
                    headerName: i18n.conduites,
                    field: 'mcid',
                },
                {
                    headerName: i18n.cultures,
                    field: 'cid',
                },
                {
                    headerName: i18n.surfaceRef,
                    field: 'sRef',
                },
                {
                    headerName: i18n.surfaceOpti,
                    field: 'sOpti',
                },
                {
                    headerName: i18n.evolution,
                    field: 'evol',
                    valueFormatter: pourcentageFormatter,
                },
            ],
            columnDefsZone: [
                {
                    headerName: i18n.indicateursPerf,
                    field: 'iName',
                },
                {
                    headerName: i18n.zones,
                    field: 'zName',
                },
                {
                    headerName: i18n.valueRef,
                    field: 'vRef',
                },
                {
                    headerName: i18n.valueOpti,
                    field: 'vOpti',
                },
                {
                    headerName: i18n.evolution,
                    field: 'evol',
                    valueFormatter: pourcentageFormatter,
                },
            ],
            columnDefsCulture: [
                {
                    headerName: i18n.cultures,
                    field: 'cName',
                },
                {
                    headerName: i18n.zones,
                    field: 'dataName',
                },
                {
                    headerName: i18n.valueRef,
                    field: 'vRef',
                },
                {
                    headerName: i18n.valueOpti,
                    field: 'vOpti',
                },
                {
                    headerName: i18n.evolution,
                    field: 'evol',
                    valueFormatter: pourcentageFormatter,
                },
            ],
            rowDataSurface: [],
            rowDataIndic: [],
            checkboxIndics: [],
            tableSurface: false,
            tableZone: false,
            tableCulture: true,
            evolBy: 'zones',
            chartLoaded: true,
            autoScaleZone: true,
            autoScaleCulture: true,
            indicSelect: 1,
            // indicSelect:
            //     props.matrice && props.matrice.indicateurs.find((i) => i.iid === 1)
            //         ? 1
            //         : props.matrice.indicateurs[0].iid,
        }
    }

    componentDidMount() {
        this.props.fetchAssolementsTerritoires(this.props.territoire.id)
        if (this.state.editMode) {
            this.props.setEditMode()
        } else {
            this.props.setReadMode()
        }
        this.setResults()

        const checkboxIndics = []
        this.props.matrice.indicateurs.forEach((i) => {
            if (i.iid !== 1) {
                checkboxIndics.push(i.iid)
            }
        })
        checkboxIndics.push(0)
        this.setState({ checkboxIndics })
    }

    setResults() {
        if (this.props.scenario.results.length > 0) {
            this.setDataSurface(this.props.scenario.results)
            this.setDataIndicateurs(this.props.scenario.results, this.props.matrice.indicateurs)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isRunning && !this.props.isRunning) {
            this.setResults()
        }
    }

    setDataSurface = (result) => {
        const formattedData = []
        const keys = []
        result.forEach((r) => {
            const key = { zid: r.zid, cid: r.cid, mcid: r.mcid }
            if (!keys.some((k) => isEqual(k, key))) {
                const values = result.filter(
                    (rF) => r.zid === rF.zid && r.cid === rF.cid && r.mcid === rF.mcid,
                )
                let line = {
                    zid: getNameById(this.props.zones, r.zid),
                    cid: getNameById(this.props.cultures, r.cid),
                    mcid: getNameById(this.props.conduites, r.mcid),
                }
                values.forEach((v) => {
                    if (v.typeValeur === -4) {
                        line = { ...line, sOpti: v.surface === -999 ? 0 : v.surface }
                    } else if (v.typeValeur === -3) {
                        line = { ...line, sRef: v.surface }
                    } else if (v.typeValeur === -2) {
                        if (v.surface === -999) {
                            line = { ...line, evol: '-' }
                        } else {
                            line = { ...line, evol: `${v.surface}%` }
                        }
                    }
                })
                keys.push(key)
                formattedData.push(line)
            }
        })
        this.setState({ rowDataSurface: formattedData })
    }

    setDataIndicateurs = (result, indicateurs) => {
        const { indicateursValues } = this.props.matrice
        const formattedData = []
        const keys = []
        indicateurs.forEach((i) => {
            let sRef = 0
            let sOpti = 0
            let vRef = 0
            let vOpti = 0
            result.forEach((r) => {
                const key = { zid: r.zid, cid: r.cid, mcid: r.mcid }
                if (!keys.some((k) => isEqual(k, key))) {
                    const values = result.filter(
                        (rF) => r.zid === rF.zid && r.cid === rF.cid && r.mcid === rF.mcid,
                    )
                    const indicValue = indicateursValues.find(
                        (iV) => r.zid === iV.zid
                            && r.cid === iV.cid
                            && r.mcid === iV.mcid
                            && iV.iid === i.iid,
                    )
                    if (indicValue) {
                        values.forEach((v) => {
                            if (v.typeValeur === -4) {
                                sOpti += v.surface
                                vOpti += indicValue.value
                            } else if (v.typeValeur === -3) {
                                sRef += v.surface
                                vRef += indicValue.value
                            }
                        })
                    }
                    keys.push(key)
                }
            })
            const line = {
                iid: getNameById(this.props.indicateurs, i.iid),
                vRef: vRef / sRef,
                vOpti: vOpti / sOpti,
                evol: `${((vOpti - vRef) / vRef) * 100}%`,
            }
            formattedData.push(line)
        })
        this.setState({ rowDataIndic: formattedData })
    }

    startScenario = () => {
        this.props.startScenario()
    }

    exportResult = () => {
        this.setState({ exportLoading: true })
        ToastrAction.info(i18n.loadingDocument)
        this.props.exportExcelScenarioResult(this.props.scenario.id).then(() => {
            this.setState({ exportLoading: false })
        })
    }

    getExportButton = (scenario) => {
        if (scenario.results.length > 0) {
            return (
                <GreenButton borderRadius style={{ margin: '2rem' }} onClick={this.exportResult}>
                    <DldIcon>get_app</DldIcon>&nbsp;&nbsp;
                    {i18n.exportResults}
                </GreenButton>
            )
        }
        return null
    }

    setEchartMapData = (results = []) => {
        const zonesId = []
        const datas = []
        results.sort(sortByZone)
        results.forEach((r) => {
            const zoneName = getNameById(this.props.zones, r.zid)
            if (!zonesId.includes(r.zid)) {
                zonesId.push(r.zid)
                datas.push({
                    zid: r.zid,
                    name: zoneName,
                    path: zoneName,
                    value: 0,
                    children: [],
                })
            }
            datas.forEach((d) => {
                if (d.zid === r.zid) {
                    d.value += r.surface !== -999 ? r.surface : 0
                    const mcName = getNameById(this.props.conduites, r.mcid)
                    if (!d.children.find((mc) => mc.mcid === r.mcid)) {
                        d.children.push({
                            mcid: r.mcid,
                            name: mcName,
                            path: `${zoneName}/${mcName}`,
                            value: 0,
                            children: [],
                        })
                    }
                    d.children.forEach((mc) => {
                        if (r.mcid === mc.mcid) {
                            mc.value += r.surface !== -999 ? r.surface : 0
                            if (!mc.children.find((c) => c.cid === r.cid)) {
                                const cultureName = getNameById(this.props.cultures, r.cid)
                                mc.children.push({
                                    cid: r.cid,
                                    name: cultureName,
                                    path: `${zoneName}/${mcName}/${cultureName}`,
                                    value: r.surface !== -999 ? r.surface : 0,
                                })
                            }
                        }
                    })
                }
            })
        })
        return datas
    }

    setEchartBarZoneData = (resultRef, resultOpti) => {
        const { zones, indicateurs, territoire, matrice } = this.props
        const { checkboxIndics } = this.state
        const indicIds = []
        const indicsLabels = []
        const zonesIds = []
        const zonesLabels = []
        const datasDeviation = []
        const surfRefDeviation = []

        const map = []
        matrice.indicateurs.forEach((i) => {
            if (i.iid !== 1) {
                if (checkboxIndics.find((iid) => iid === i.iid)) {
                    if (!indicIds.includes(i.iid)) {
                        indicIds.push(i.iid)
                        indicsLabels.push(getNameById(indicateurs, i.iid))
                    }
                }
                let ensembleSurfaceRefValues = 0
                let ensembleSurfaceOptiValues = 0
                let ensembleMatriceRefValues = 0
                let ensembleMatriceOptiValues = 0
                territoire.zones.forEach((z) => {
                    if (!zonesIds.includes(z.dataId)) {
                        zonesIds.push(z.dataId)
                    }
                    let totalSurfaceRefValues = 0
                    let totalSurfaceOptiValues = 0
                    let totalMatriceRefValues = 0
                    let totalMatriceOptiValues = 0
                    territoire.cultures.forEach((c) => {
                        let totalSurfaceRefValuesABS = 0
                        let totalSurfaceOptiValuesABS = 0
                        territoire.modesConduites.forEach((mc) => {
                            let valueRef = 0
                            let valueOpti = 0
                            let valueMatrice = 0
                            matrice.indicateursValues.forEach((m) => {
                                if (
                                    m.zid === z.dataId
                                    && m.cid === c.dataId
                                    && m.mcid === mc.dataId
                                    && i.iid === m.iid
                                ) {
                                    valueMatrice = m.value || 0
                                }
                            })
                            resultRef.forEach((rRef) => {
                                if (
                                    rRef.zid === z.dataId
                                    && rRef.cid === c.dataId
                                    && rRef.mcid === mc.dataId
                                ) {
                                    valueRef += rRef.surface === -999 ? 0 : rRef.surface
                                }
                            })

                            resultOpti.forEach((rOpti) => {
                                if (
                                    rOpti.zid === z.dataId
                                    && rOpti.cid === c.dataId
                                    && rOpti.mcid === mc.dataId
                                ) {
                                    valueOpti += rOpti.surface === -999 ? 0 : rOpti.surface
                                }
                            })
                            totalSurfaceRefValues += valueRef
                            totalSurfaceOptiValues += valueOpti
                            totalSurfaceRefValuesABS += valueRef
                            totalSurfaceOptiValuesABS += valueOpti
                            totalMatriceRefValues += valueRef * valueMatrice
                            totalMatriceOptiValues += valueOpti * valueMatrice
                            ensembleSurfaceRefValues += valueRef
                            ensembleSurfaceOptiValues += valueOpti
                            ensembleMatriceRefValues += valueRef * valueMatrice
                            ensembleMatriceOptiValues += valueOpti * valueMatrice
                        })

                        if (!datasDeviation.find((d) => d.zid === z.dataId && d.cid === c.dataId)) {
                            datasDeviation.push({
                                zid: z.dataId,
                                cid: c.dataId,
                                value: Math.abs(
                                    totalSurfaceRefValuesABS - totalSurfaceOptiValuesABS,
                                ),
                            })
                        }
                    })

                    if (checkboxIndics.find((iid) => iid === 0) === 0) {
                        if (!surfRefDeviation.find((d) => d.zid === z.dataId)) {
                            surfRefDeviation.push({
                                zid: z.dataId,
                                value: totalSurfaceRefValues,
                            })
                        }
                    }

                    const evol = ((totalMatriceOptiValues - totalMatriceRefValues) / totalMatriceRefValues)
                        * 100
                    let vRef = 0
                    let vOpti = 0
                    if (i.iid === 1) {
                        vRef = totalMatriceRefValues
                        vOpti = totalMatriceOptiValues
                    } else {
                        if (totalSurfaceRefValues !== 0) {
                            vRef = totalMatriceRefValues / totalSurfaceRefValues
                        }
                        if (totalSurfaceOptiValues !== 0) {
                            vOpti = totalMatriceOptiValues / totalSurfaceOptiValues
                        }
                    }

                    if (checkboxIndics.find((iid) => iid === i.iid)) {
                        map.push({
                            iid: i.iid,
                            iName: getNameById(indicateurs, i.iid),
                            zid: z.dataId,
                            zName: getNameById(zones, z.dataId),
                            vRef: formatFloat(vRef),
                            vOpti: formatFloat(vOpti),
                            evol: formatFloat(evol, 0),
                        })
                    }
                })
                let totalVRef = 0
                let totalVOpti = 0
                if (i.iid === 1) {
                    totalVRef = ensembleMatriceRefValues
                    totalVOpti = ensembleMatriceOptiValues
                } else {
                    if (ensembleSurfaceRefValues !== 0) {
                        totalVRef = ensembleMatriceRefValues / ensembleSurfaceRefValues
                    }
                    if (ensembleSurfaceOptiValues !== 0) {
                        totalVOpti = ensembleMatriceOptiValues / ensembleSurfaceOptiValues
                    }
                }
                if (checkboxIndics.find((iid) => iid === 0) === 0) {
                    if (!surfRefDeviation.find((d) => d.zid === 0)) {
                        surfRefDeviation.push({
                            zid: 0,
                            value: ensembleSurfaceRefValues,
                        })
                    }
                }
                const totalEvol = formatFloat(((totalVOpti - totalVRef) / totalVRef) * 100)

                if (checkboxIndics.find((iid) => iid === i.iid)) {
                    map.push({
                        iid: i.iid,
                        iName: getNameById(indicateurs, i.iid),
                        zid: 0,
                        zName: 'Ensemble des zones',
                        vRef: formatFloat(totalVRef),
                        vOpti: formatFloat(totalVOpti),
                        evol: formatFloat(totalEvol, 0),
                    })
                }
            }
        })

        const emphasisStyle = {
            itemStyle: {
                barBorderWidth: 1,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0,0,0,0.5)',
            },
        }

        const series = []
        map.forEach((v) => {
            const serie = series.find((s) => s.zid === v.zid)
            if (serie) {
                const newValues = [...serie.data, v.evol]
                serie.data = newValues
            } else {
                zonesLabels.push(v.zid === 0 ? 'Ensemble des zones' : getNameById(zones, v.zid))
                const value = []
                value.push(v.evol)
                series.push({
                    zid: v.zid,
                    name: v.zid === 0 ? 'Ensemble des zones' : getNameById(zones, v.zid),
                    type: 'bar',
                    emphasis: emphasisStyle,
                    data: [...value],
                })
            }
        })

        const seriesWithDeviation = []
        surfRefDeviation.forEach((s) => {
            let deviation = 0
            datasDeviation.forEach((d) => {
                if (s.zid === 0) {
                    deviation += d.value
                } else if (d.zid === s.zid) {
                    deviation += d.value
                }
            })
            deviation /= 2 * s.value
            deviation *= 100
            const serieData = series.find((d) => d.zid === s.zid)
            if (checkboxIndics.find((iid) => iid === 0) === 0) {
                zonesLabels.push(s.zid === 0 ? 'Ensemble des zones' : getNameById(zones, s.zid))
                map.push({
                    iid: 0,
                    iName: 'Déviation',
                    zid: s.zid,
                    zName: s.zid === 0 ? 'Ensemble des zones' : getNameById(zones, s.zid),
                    vRef: '-',
                    vOpti: `${deviation}%`,
                    evol: '-',
                })
            }
            if (!serieData) {
                seriesWithDeviation.push({
                    zid: s.zid,
                    name: s.zid === 0 ? 'Ensemble des zones' : getNameById(zones, s.zid),
                    type: 'bar',
                    emphasis: emphasisStyle,
                    data: [deviation],
                })
            } else {
                const deviationData = {
                    ...serieData,
                    data: [...serieData.data, deviation],
                }
                seriesWithDeviation.push(deviationData)
            }
        })
        if (checkboxIndics.find((iid) => iid === 0) === 0) {
            const minValue = min(seriesWithDeviation.map((s) => s.data).flat())
            const maxValue = max(seriesWithDeviation.map((s) => s.data).flat())
            indicsLabels.push('Déviation')
            return [seriesWithDeviation, zonesLabels, indicsLabels, map, ...this.getBornes(minValue, maxValue)]
        }
        const minValue = min(series.map((s) => s.data).flat())
        const maxValue = max(series.map((s) => s.data).flat())
        return [series, zonesLabels, indicsLabels, map, ...this.getBornes(minValue, maxValue)]
    }

    getBornes = (minValue, maxValue) => {
        if (minValue >= 0 && minValue <= 100 && maxValue >= 0 && maxValue <= 100) {
            return [0, 100]
        }
        if (minValue >= -100 && minValue <= 0 && maxValue >= -100 && maxValue <= 0) {
            return [-100, 0]
        }
        if (minValue >= -100 && minValue <= 0 && maxValue >= 0 && maxValue <= 100) {
            return [-100, 100]
        }
        if (minValue >= 0 && maxValue >= 100) {
            return [0, null]
        }
        if (minValue <= 0 && maxValue <= -100) {
            return [null, 0]
        }
        if (minValue <= -100 && maxValue >= 100) {
            return [null, null]
        }
        return [null, null]
    }

    setEchartBarCultureData = (resultRef, resultOpti) => {
        const { zones, cultures, conduites, territoire, matrice } = this.props
        const { evolBy, indicSelect } = this.state
        const culturesIds = []
        const culturesLabels = []
        const ids = []
        const labels = []

        const map = []
        territoire.cultures.forEach((c) => {
            if (!culturesIds.includes(c.dataId)) {
                culturesIds.push(c.dataId)
                culturesLabels.push(getNameById(cultures, c.dataId))
            }
            if (evolBy === 'conduites') {
                territoire.modesConduites.forEach((mc) => {
                    let totalSurfaceRefValues = 0
                    let totalSurfaceOptiValues = 0
                    let totalMatriceRefValues = 0
                    let totalMatriceOptiValues = 0
                    if (!ids.includes(mc.dataId)) {
                        ids.push(mc.dataId)
                    }
                    territoire.zones.forEach((z) => {
                        let valueRef = 0
                        let valueOpti = 0
                        let valueMatrice = 0
                        matrice.indicateursValues.forEach((m) => {
                            if (
                                m.cid === c.dataId
                                && m.mcid === mc.dataId
                                && m.zid === z.dataId
                                && m.iid === indicSelect
                            ) {
                                valueMatrice = m.value || 0
                            }
                        })
                        if (valueMatrice !== 0) {
                            resultRef.forEach((rRef) => {
                                if (
                                    rRef.cid === c.dataId
                                    && rRef.mcid === mc.dataId
                                    && rRef.zid === z.dataId
                                ) {
                                    valueRef += rRef.surface === -999 ? 0 : rRef.surface
                                }
                            })
                            resultOpti.forEach((rOpti) => {
                                if (
                                    rOpti.cid === c.dataId
                                    && rOpti.mcid === mc.dataId
                                    && rOpti.zid === z.dataId
                                ) {
                                    valueOpti += rOpti.surface === -999 ? 0 : rOpti.surface
                                }
                            })
                        }
                        totalSurfaceRefValues += valueRef
                        totalSurfaceOptiValues += valueOpti
                        totalMatriceRefValues += valueRef * valueMatrice
                        totalMatriceOptiValues += valueOpti * valueMatrice
                    })
                    const evol = ((totalMatriceOptiValues - totalMatriceRefValues) / totalMatriceRefValues)
                        * 100
                    let vRef = totalMatriceRefValues
                    let vOpti = totalMatriceOptiValues
                    if (indicSelect !== 1) {
                        vRef = totalMatriceRefValues / totalSurfaceRefValues
                        vOpti = totalMatriceOptiValues / totalSurfaceOptiValues
                    }
                    map.push({
                        cid: c.dataId,
                        cName: getNameById(cultures, c.dataId),
                        dataId: mc.dataId,
                        dataName: getNameById(conduites, mc.dataId),
                        vRef: formatFloat(vRef),
                        vOpti: formatFloat(vOpti),
                        evol: formatFloat(evol, 0),
                    })
                })
            } else {
                let totalZoneSurfaceRefValues = 0
                let totalZoneSurfaceOptiValues = 0
                let totalZoneMatriceRefValues = 0
                let totalZoneMatriceOptiValues = 0
                territoire.zones.forEach((z) => {
                    let totalSurfaceRefValues = 0
                    let totalSurfaceOptiValues = 0
                    let totalMatriceRefValues = 0
                    let totalMatriceOptiValues = 0
                    if (!ids.includes(z.dataId)) {
                        ids.push(z.dataId)
                    }
                    territoire.modesConduites.forEach((mc) => {
                        let valueRef = 0
                        let valueOpti = 0
                        let valueMatrice = 0
                        matrice.indicateursValues.forEach((m) => {
                            if (
                                m.cid === c.dataId
                                && m.mcid === mc.dataId
                                && m.zid === z.dataId
                                && m.iid === indicSelect
                            ) {
                                valueMatrice = m.value || 0
                            }
                        })
                        if (valueMatrice !== 0) {
                            resultRef.forEach((rRef) => {
                                if (
                                    rRef.cid === c.dataId
                                    && rRef.mcid === mc.dataId
                                    && rRef.zid === z.dataId
                                ) {
                                    valueRef += rRef.surface === -999 ? 0 : rRef.surface
                                }
                            })
                            resultOpti.forEach((rOpti) => {
                                if (
                                    rOpti.cid === c.dataId
                                    && rOpti.mcid === mc.dataId
                                    && rOpti.zid === z.dataId
                                ) {
                                    valueOpti += rOpti.surface === -999 ? 0 : rOpti.surface
                                }
                            })
                        }
                        totalSurfaceRefValues += valueRef
                        totalSurfaceOptiValues += valueOpti
                        totalMatriceRefValues += valueRef * valueMatrice
                        totalMatriceOptiValues += valueOpti * valueMatrice
                    })
                    let vRef = totalMatriceRefValues
                    let vOpti = totalMatriceOptiValues
                    if (indicSelect !== 1) {
                        vRef = totalSurfaceRefValues === 0
                            ? 0
                            : totalMatriceRefValues / totalSurfaceRefValues
                        vOpti = totalSurfaceOptiValues === 0
                            ? 0
                            : totalMatriceOptiValues / totalSurfaceOptiValues
                    }
                    const evol = ((vOpti - vRef) / vRef) * 100
                    
                    totalZoneSurfaceRefValues += totalSurfaceRefValues
                    totalZoneSurfaceOptiValues += totalSurfaceOptiValues
                    totalZoneMatriceRefValues += totalMatriceRefValues
                    totalZoneMatriceOptiValues += totalMatriceOptiValues
                    map.push({
                        cid: c.dataId,
                        cName: getNameById(cultures, c.dataId),
                        dataId: z.dataId,
                        dataName: getNameById(zones, z.dataId),
                        vRef: formatFloat(vRef),
                        vOpti: formatFloat(vOpti),
                        evol: formatFloat(evol, 0),
                    })
                })
                let vRef = totalZoneMatriceRefValues
                let vOpti = totalZoneMatriceOptiValues
                if (indicSelect !== 1) {
                    vRef = totalZoneSurfaceRefValues === 0
                        ? 0
                        : totalZoneMatriceRefValues / totalZoneSurfaceRefValues
                    vOpti = totalZoneSurfaceOptiValues === 0
                        ? 0
                        : totalZoneMatriceOptiValues / totalZoneSurfaceOptiValues
                }
                const evol = ((vOpti - vRef) / vRef) * 100
                map.push({
                    cid: c.dataId,
                    cName: getNameById(cultures, c.dataId),
                    dataId: -1,
                    dataName: 'Ensemble des zones',
                    vRef: formatFloat(vRef),
                    vOpti: formatFloat(vOpti),
                    evol: formatFloat(evol, 0),
                })
            }
        })

        const emphasisStyle = {
            itemStyle: {
                barBorderWidth: 1,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0,0,0,0.5)',
            },
        }

        const series = []
        map.forEach((v) => {
            const serie = series.find((s) => s.dataId === v.dataId)
            if (serie) {
                const newValues = [...serie.data, v.evol]
                serie.data = newValues
            } else {
                const name = v.dataId === -1 ? 'Ensemble des zones' : getNameById(evolBy === 'conduites' ? conduites : zones, v.dataId)
                labels.push(name)
                const value = []
                value.push(v.evol)
                series.push({
                    dataId: v.dataId,
                    name,
                    type: 'bar',
                    emphasis: emphasisStyle,
                    data: [...value],
                })
            }
        })
        const minValue = min(series.map((s) => s.data).flat())
        const maxValue = max(series.map((s) => s.data).flat())
        return [series, labels, culturesLabels, map, ...this.getBornes(minValue, maxValue)]
    }

    onChangeSelectBy = (value) => {
        const columnDefsCulture = [
            {
                headerName: i18n.cultures,
                field: 'cName',
            },
            {
                headerName: i18n[value],
                field: 'dataName',
            },
            {
                headerName: i18n.valueRef,
                field: 'vRef',
            },
            {
                headerName: i18n.valueOpti,
                field: 'vOpti',
            },
            {
                headerName: i18n.evolution,
                field: 'evol',
                valueFormatter: pourcentageFormatter,
            },
        ]
        this.setState({ chartLoaded: false, evolBy: value, columnDefsCulture })
        setTimeout(() => {
            this.setState({ chartLoaded: true })
        }, 10)
    }

    onChangeSelectIndic = (value) => {
        this.setState({ indicSelect: value })
    }

    onChangeCheckboxIndics = (indicateurId) => {
        const { checkboxIndics } = this.state
        if (
            checkboxIndics.find((iid) => iid === indicateurId)
            || checkboxIndics.find((iid) => iid === indicateurId) === 0
        ) {
            const newIndics = checkboxIndics.filter((iid) => iid !== indicateurId)
            this.setState({ checkboxIndics: newIndics })
        } else {
            const newIndics = [...checkboxIndics, indicateurId]
            this.setState({ checkboxIndics: newIndics })
        }
    }

    render() {
        const { indicateurs, scenario, scenarios, isRunning, statut, matrice, applicationSettings } = this.props
        const {
            exportLoading,
            rowDataSurface,
            columnDefsSurface,
            columnDefsZone,
            columnDefsCulture,
            evolBy,
            // indicSelect,
            tableSurface,
            tableZone,
            tableCulture,
            chartLoaded,
            checkboxIndics,
            autoScaleZone,
            autoScaleCulture,
        } = this.state

        this.props.setHelpPath('creer-et-simuler-des-scenarios/lancer-le-calcul-et-explorer-les-resultats/', applicationSettings)

        // const indicateursSelect = matrice.indicateurs.map((i) => ({ id: i.iid, name: getNameById(indicateurs, i.iid) }))

        const deviationChecked = checkboxIndics.find((iid) => iid === 0) === 0

        const scRef = scenarios.filter((sc) => sc.id === scenario.scRef)
        const critOpti = indicateurs.filter((i) => i.id === scenario.iidDirection)

        const resultsRef = scenario.results.filter((r) => r.typeValeur === -3)
        const resultsOpti = scenario.results.filter((r) => r.typeValeur === -4)
        const datasMap = this.setEchartMapData(resultsOpti)
        const datasBarZone = this.setEchartBarZoneData(resultsRef, resultsOpti)
        const datasBarCulture = this.setEchartBarCultureData(resultsRef, resultsOpti)

        return (
            <CardStyle>
                <p>
                    {i18n.name} : {scenario.name}
                </p>
                <p>
                    {i18n.criteresOpti} : {critOpti[0] ? critOpti[0].name : ''}
                </p>
                <p>
                    {i18n.scenarioRef} : {scRef[0] ? scRef[0].name : 'Assolement actuel'}
                </p>
                <p>
                    {i18n.updatedAt} : {getFullDateMini(scenario.datemaj)} {i18n.by}{' '}
                    {scenario.usermaj}
                </p>
                {isRunning ? (
                    <CircularProgress />
                ) : (
                    <>
                        <GreenButton
                            disabled={statut === 2}
                            borderRadius
                            style={{ margin: '2rem' }}
                            onClick={this.startScenario}
                        >
                            <DldIcon>play_circle_outline</DldIcon>&nbsp;&nbsp;
                            {i18n.startScenario}
                        </GreenButton>
                        {exportLoading ? <CircularProgress /> : this.getExportButton(scenario)}
                        <Divider />
                        {scenario.results.length > 0 ? (
                            <>
                                <Flex col style={{ marginTop: '60px' }}>
                                    <HeaderGraph
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <HeaderTitle>{i18n.surfaces}</HeaderTitle>
                                        <IconButton
                                            className="clickable"
                                            onClick={() => this.setState({ tableSurface: !tableSurface })}
                                        >
                                            {tableSurface ? 'multiline_chart' : 'list'}
                                        </IconButton>
                                    </HeaderGraph>
                                    {tableSurface ? (
                                        <div className="ag-theme-balham" style={tableStyle}>
                                            <AgGridTable
                                                columnDefs={columnDefsSurface}
                                                rowData={rowDataSurface}
                                                exportable={false}
                                            />
                                        </div>
                                    ) : (
                                        <EchartMap
                                            data={datasMap}
                                            fileTitle={`${scenario.name} - ${i18n.surfaces}`}
                                        />
                                    )}
                                </Flex>
                                {scenario.scType !== -1 && (
                                    <Flex col style={{ marginTop: '60px' }}>
                                        <HeaderGraph
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <HeaderTitle>{i18n.evolIndicZone}</HeaderTitle>
                                            <IconButton
                                                className="clickable"
                                                onClick={() => this.setState({ tableZone: !tableZone })}
                                            >
                                                {tableZone ? 'multiline_chart' : 'list'}
                                            </IconButton>
                                        </HeaderGraph>
                                        <Grid container direction="row" justify="center">
                                            {matrice.indicateurs.map((i) => {
                                                if (i.iid !== 1) {
                                                    const find = checkboxIndics.find(
                                                        (iid) => iid === i.iid,
                                                    )
                                                    return (
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={find > 0} />
                                                                }
                                                                onChange={() => this.onChangeCheckboxIndics(i.iid)}
                                                                label={getNameById(indicateurs, i.iid)}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                return null
                                            })}
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={deviationChecked} />
                                                    }
                                                    onChange={() => this.onChangeCheckboxIndics(0)}
                                                    label="Déviation"
                                                />
                                            </Grid>
                                        </Grid>
                                        {checkboxIndics.length 
                                            ? tableZone ? (
                                                <div className="ag-theme-balham" style={tableStyle}>
                                                    <AgGridTable
                                                        columnDefs={columnDefsZone}
                                                        rowData={datasBarZone[3]}
                                                        exportable={false}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <EchartBar
                                                        data={datasBarZone[0]}
                                                        legendsData={datasBarZone[1]}
                                                        yLabels={datasBarZone[2]}
                                                        fileTitle={`${scenario.name} - ${i18n.evolIndicZone}`}
                                                        min={autoScaleZone ? null : datasBarZone[4]}
                                                        max={autoScaleZone ? null : datasBarZone[5]}
                                                    />
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={autoScaleZone} />
                                                            }
                                                            onChange={() => this.setState({ autoScaleZone: !autoScaleZone })}
                                                            label="Echelle automatique"
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                            : (
                                                <span>{i18n.noIndicateurSelected}</span>
                                            )}
                                    </Flex>
                                )}
                                {scenario.scType !== -1 && (
                                    <Flex col style={{ marginTop: '60px' }}>
                                        <HeaderGraph
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <HeaderTitle>{i18n.evolProdCulture}</HeaderTitle>
                                            <IconButton
                                                className="clickable"
                                                onClick={() => this.setState({ tableCulture: !tableCulture })}
                                            >
                                                {tableCulture ? 'multiline_chart' : 'list'}
                                            </IconButton>
                                        </HeaderGraph>
                                        <Grid container direction="row" style={{ marginTop: '10px' }}>
                                            <Grid item xs={2}>
                                                <FormControl variant="outlined">
                                                    <Select
                                                        name="sortBy"
                                                        style={selectEditMode}
                                                        value={evolBy}
                                                        onChange={(e) => this.onChangeSelectBy(e.target.value)}
                                                    >
                                                        <MenuItem value="zones">{i18n.byZone}</MenuItem>
                                                        <MenuItem value="conduites">
                                                            {i18n.byModeConduite}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                xs={9}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <FormControl variant="outlined">
                                                    <Select
                                                        name="indicSelect"
                                                        style={selectEditMode}
                                                        value={indicSelect}
                                                        onChange={(e) => this.onChangeSelectIndic(e.target.value)}
                                                    >
                                                        {indicateursSelect.map((i) => (
                                                            <MenuItem value={i.id}>
                                                                {i.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid> */}
                                        </Grid>
                                        {tableCulture ? (
                                            <div className="ag-theme-balham" style={tableStyle}>
                                                <AgGridTable
                                                    columnDefs={columnDefsCulture}
                                                    rowData={datasBarCulture[3]}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {chartLoaded ? (
                                                    <>
                                                        <EchartBar
                                                            data={datasBarCulture[0]}
                                                            legendsData={datasBarCulture[1]}
                                                            yLabels={datasBarCulture[2]}
                                                            fileTitle={`${scenario.name} - ${i18n.evolProdCulture}`}
                                                            min={autoScaleCulture ? null : datasBarCulture[4]}
                                                            max={autoScaleCulture ? null : datasBarCulture[5]}
                                                        />
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={autoScaleCulture} />
                                                                }
                                                                onChange={() => this.setState({ autoScaleCulture: !autoScaleCulture })}
                                                                label="Echelle automatique"
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <CircularProgress style={{ height: '500px' }} />
                                                )}
                                            </>
                                        )}
                                    </Flex>
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </>
                )}
            </CardStyle>
        )
    }
}

ScenarResult.propTypes = {
    startScenario: PropTypes.func,
}

const mapStateToProps = (store) => ({
    users: store.UserReducer.users,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    indicateurs: store.ReferencialReducer.indicateurs,
    matrice: store.TerritoryReducer.matrice,
    scenarios: store.TerritoryReducer.scenarios,
    results: store.TerritoryReducer.results,
    territoire: store.TerritoryReducer.territoire,
    assolements: store.TerritoryReducer.assolements,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    exportExcelScenarioResult: TerritoryAction.exportExcelScenarioResult,
    fetchAssolementsTerritoires: TerritoryAction.fetchAssolementsTerritoires,
    setHelpPath: HomeAction.setHelpPath,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarResult)
