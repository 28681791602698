/* eslint-disable linebreak-style */
import React, { PureComponent } from 'react'
import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'

export default class EchartMap extends PureComponent {
    getOption = () => {
        const { data, fileTitle } = this.props
        const formatUtil = echarts.format
        function getLevelOption() {
            return [
                {
                    colorSaturation: [0.35, 0.5],
                    itemStyle: {
                        borderWidth: 1,
                        gapWidth: 15,
                        borderColorSaturation: 0.6,
                    },
                },
                {
                    colorSaturation: [0.35, 0.5],
                    itemStyle: {
                        borderWidth: 1,
                        gapWidth: 5,
                        borderColorSaturation: 1,
                    },
                },
                {
                    colorSaturation: [0.35, 0.5],
                    itemStyle: {
                        gapWidth: 5,
                        borderColorSaturation: 0.6,
                    },
                },
            ]
        }
        const option = {
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        title: 'Export',
                        name: fileTitle,
                    },
                },
            },
            tooltip: {
                formatter(info) {
                    const { value } = info
                    const { treePathInfo } = info
                    const treePath = []
                    for (let i = 1; i < treePathInfo.length; i++) {
                        treePath.push(treePathInfo[i].name)
                    }
                    return [
                        `<div class="tooltip-title">${formatUtil.encodeHTML(
                            treePath.join('/'),
                        )}</div>`,
                        `Surface: ${formatUtil.addCommas(value)}`,
                    ].join('')
                },
            },
            series: [
                {
                    name: 'Surfaces',
                    type: 'treemap',
                    visibleMin: 0,
                    label: {
                        show: true,
                        formatter: '{b}',
                    },
                    itemStyle: {
                        normal: {
                            borderColor: '#fff',
                        },
                    },
                    levels: getLevelOption(),
                    data,
                    roam: 'move',
                },
            ],
        }
        return option
    }

    render() {
        return (
            <ReactEcharts
                option={this.getOption()}
                style={{ height: '500px', width: '100%' }}
                className="react_for_echarts"
            />
        )
    }
}
