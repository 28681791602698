// Choisir une des deux usedUrl ci dessous

// A utiliser pour travailler en local (NE PAS COMMIT !!!)
// const usedUrl = 'http://localhost:8000/'

// A utiliser pour le serveur
const usedUrl = (window.location.href || document.URL).split('#')[0]

/// ///////////////////////////////////////////////////////////
const pathFormat = usedUrl.endsWith('?') ? usedUrl.slice(0, -1) : usedUrl
const path = `${pathFormat}${pathFormat.endsWith('/') ? '' : '/'}api/`
const contentsPath = `${pathFormat}${pathFormat.endsWith('/') ? '' : '/'}contents/`

export { path, contentsPath }
