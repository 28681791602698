/* eslint-disable react/no-danger */
/* eslint-disable radix */
import Icon from '@material-ui/core/Icon'
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { sortBy, orderBy } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import '../../main.scss'
import HomeFooter from './components/Footer'
import HomeHeader from './components/Header'
import HomeSlide from './components/Slide'
import { mainBlue, white } from '../../styles/theme'
import { Center } from '../../styles/main'
import { GreenButton, DwldButton, WhiteButton } from '../../styles/button'
import { SecondaryTitle } from '../../styles/text'
import { textHeader } from './constants/LogosConstants'
import HomeAction from './actions/HomeAction'
import CMSEventDto from '../events/dto/CMSEventDto'
import { contentsPath } from '../../conf/basepath'

const BlueParagraph = styled.div`
    background-color: ${mainBlue};
    padding: 0.5rem 0;
    p {
        color: ${white};
        font-size: 2.3vh;
        margin: 1em 3em;
        text-align: justify;
    }
`

const CtaButton = styled(GreenButton)`
    font-size: 2.3vh;
`

const ContactWrapper = styled.div`
    background-color: ${mainBlue};
`

const Text = styled.p`
    text-align: center;
    margin: 0;
    font-size: 2vh;
`

const iconStyle = {
    color: { white },
    cursor: 'pointer',
    position: 'absolute',
    left: '35px',
}

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentsCMS: [],
            documentsLoaded: false,
        }
    }

    componentDidMount() {
        this.props.createHomeViewStat()
        const { cmsHomeDocuments, cmsHomeSocial, cmsHomeNewsLetter } = this.props
        if (!cmsHomeDocuments.length) {
            this.props.fetchCmsHomeDocuments().then(() => {
                this.setHomeDocuments()
            })
        } else {
            this.setHomeDocuments()
        }
        if (!cmsHomeSocial.length) {
            this.props.fetchCmsHomeSocial()
        }
        if (!cmsHomeNewsLetter.length) {
            this.props.fetchCmsHomeNewsLetter()
        }
    }

    setHomeDocuments = () => {
        const { cmsHomeDocuments } = this.props
        const documentsCMS = []
        cmsHomeDocuments.forEach((c) => {
            if (c.link) {
                documentsCMS.push({
                    id: c.id,
                    order: parseInt(c.subtitle) || null,
                    title: c.title,
                    link: c.link,
                })
            } else if (c.cmsDocument[0]) {
                documentsCMS.push({
                    id: c.id,
                    order: parseInt(c.subtitle) || null,
                    title: c.title,
                    link: `${contentsPath}CMS/${c.cmsDocument[0].name}`,
                })
            }
        })
        this.setState({ documentsCMS: sortBy(documentsCMS, 'order', 'id'), documentsLoaded: true })
    }

    showDocumentsCms = () => {
        const { documentsCMS } = this.state
        return documentsCMS.map((c) => (
            <DwldButton
                border="white"
                color="white"
                // href={c.link}
                onClick={() => this.openDoc(c)}
                target="_blank"
                style={{
                    height: '60px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: 'large',
                }}
            >
                <Icon style={iconStyle}>description</Icon>
                <Text>{c.title}</Text>
            </DwldButton>
        ))
    }

    getSocialMedia = () => {
        const { cmsHomeSocial } = this.props
        return cmsHomeSocial.map((c) => (
            <Grid item>
                <WhiteButton
                    border="white"
                    // href={c.link}
                    onClick={c.link ? () => this.openDoc(c) : () => {}}
                    target="_blank"
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        fontSize: 'large',
                        color: 'black',
                        padding: '0.5rem',
                    }}
                >
                    {c.cmsDocument && c.cmsDocument.length ? (
                        <img src={`${contentsPath}CMS/${c.cmsDocument[0].name}`} alt="socialMediaLogo" height="35px" style={{ marginRight: 8 }} />
                    ) : null}
                    <Text style={{ fontSize: '1rem' }}>{c.title}</Text>
                </WhiteButton>
            </Grid>
        ))
    }

    openDoc = (doc) => {
        window.open(doc.link, '_blank')
        this.props.createDocumentViewStats({
            login: 'homepage',
            module: 'CCE',
            document: doc.title,
        })
    }

    render() {
        const { cmsHome = [], cmsHomeNewsLetter = [] } = this.props
        const { documentsLoaded } = this.state
        const homeCMS = cmsHome.length ? cmsHome[0] : {}
        const newsLetterCMS = cmsHomeNewsLetter.length ? orderBy(cmsHomeNewsLetter, 'updateDate', 'desc')[0] : {}
        return (
            <div style={{ height: '100vh' }}>
                <HomeSlide home />
                <div className="home_content">
                    <div className="home_card">
                        <div>
                            <HomeHeader page="home" />
                            <div
                                style={{
                                    height: '83vh',
                                    overflowY: 'auto',
                                    // marginBottom: '2vh',
                                }}
                            >
                                <SecondaryTitle fontSize="3vh" lineHeight="4rem" margin="1vh" style={{ fontFamily: 'Raleway', fontWeight: 'bold' }}>
                                    {homeCMS?.title || 'Imaginez collectivement l’agriculture de demain sur votre territoire'}
                                </SecondaryTitle>
                                <BlueParagraph>
                                    <div dangerouslySetInnerHTML={{ __html: homeCMS?.comment || textHeader.text }} />
                                </BlueParagraph>
                                <ContactWrapper>
                                    <Center style={{ marginTop: '10px' }}>
                                        {documentsLoaded && this.showDocumentsCms()}
                                    </Center>
                                </ContactWrapper>
                                <Center>
                                    <SecondaryTitle fontSize="3vh" lineHeight="4rem" margin="1vh" style={{ fontFamily: 'Raleway', fontWeight: 'bold' }}>Co-Click’Eau vous intéresse ?</SecondaryTitle>
                                </Center>
                                <Grid container direction="row" alignItems="stretch" justify="space-evenly" style={{ marginBottom: '25px' }}>
                                    <Grid
                                        item
                                        md={4}
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        style={{ border: `1px solid ${mainBlue}`, padding: '1rem' }}
                                    >
                                        <span style={{ fontSize: '2vh', textAlign: 'center', marginBottom: '1rem' }}>Pour avoir accès au guide méthodologique et au simulateur​</span>
                                        <Link to="/askAccount">
                                            <CtaButton noMargin>{i18n.askAccountTitle}</CtaButton>
                                        </Link>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        style={{ border: `1px solid ${mainBlue}`, padding: '1rem' }}
                                    >
                                        <span style={{ fontSize: '2vh', textAlign: 'center', marginBottom: '1rem' }}>{'Besoin d\'aide ? Des questions ?'}</span>
                                        <Link to="/contact">
                                            <CtaButton noMargin>{i18n.contactTitle}</CtaButton>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" alignItems="stretch" justify="space-evenly" style={{ marginBottom: '25px' }}>
                                    <Grid
                                        item
                                        md={4}
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        style={{ border: `1px solid ${mainBlue}`, padding: '1rem' }}
                                    >
                                        <span style={{ fontSize: '2vh', textAlign: 'center', marginBottom: '1rem' }}>Pour recevoir notre newsletter trimestrielle​</span>
                                        <CtaButton noMargin onClick={() => this.openDoc(newsLetterCMS)}>{i18n.subscribe}</CtaButton>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ border: `1px solid ${mainBlue}`, padding: '1rem' }}
                                    >
                                        <Grid item xs={12} style={{ fontSize: '2vh', textAlign: 'center', marginBottom: '1rem' }}>Suivez notre actualité sur les réseaux​</Grid>
                                        {this.getSocialMedia()}
                                    </Grid>
                                </Grid>
                                {/* <Center>
                                    <SecondaryTitle style={{ lineHeight: '90px' }}>
                                        {i18n.followUs}
                                    </SecondaryTitle>
                                </Center>
                                <CenterWrapper>
                                    <TwitterCard>
                                        <Twitter />
                                    </TwitterCard>
                                </CenterWrapper>  */}
                            </div>
                        </div>
                    </div>
                    <HomeFooter />
                </div>
            </div>
        )
    }
}

Home.propTypes = {
    createDocumentViewStats: PropTypes.func,
    createHomeViewStat: PropTypes.func,
    fetchCmsHomeDocuments: PropTypes.func,
    fetchCmsHomeSocial: PropTypes.func,
    fetchCmsHomeNewsLetter: PropTypes.func,
    cmsHome: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
    cmsHomeDocuments: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
    cmsHomeSocial: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
    cmsHomeNewsLetter: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
}

const mapStateToProps = (store) => ({
    cmsHome: store.HomeReducer.cmsHome,
    cmsHomeDocuments: store.HomeReducer.cmsHomeDocuments,
    cmsHomeSocial: store.HomeReducer.cmsHomeSocial,
    cmsHomeNewsLetter: store.HomeReducer.cmsHomeNewsLetter,
})

const mapDispatchToProps = {
    createDocumentViewStats: HomeAction.createDocumentViewStats,
    createHomeViewStat: HomeAction.createHomeViewStat,
    fetchCmsHomeDocuments: HomeAction.fetchCmsHomeDocuments,
    fetchCmsHomeSocial: HomeAction.fetchCmsHomeSocial,
    fetchCmsHomeNewsLetter: HomeAction.fetchCmsHomeNewsLetter,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
