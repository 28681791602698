import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DtoUserStatistic from '../../../dto/DtoUserStatistic'
import { connect } from 'react-redux'
import { filter, orderBy, reduce } from 'lodash'
import echarts from 'echarts/lib/echarts'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/title'
import moment from 'moment'
import { enumerateBetweenDates, getMonthYear } from '../../../../../../../utils/DateUtil'
import ProgressBar from '../../../../../../../components/ProgressBar'

class AccessChartPanel extends Component {
    getReduceStatistics = (statistics) => reduce(
        statistics,
        (a, b) => {
            (a[getMonthYear(b.statisticDate)] || (a[getMonthYear(b.statisticDate)] = [])).push(
                b,
            )
            return a
        },
        {},
    )

    getInitXAxis = (statistics) => {
        const orderStatistics = orderBy(
            filter(statistics, (o) => o.value),
            'statisticDate',
            'asc',
        )
        const firstDate = orderStatistics[0]
        if (firstDate) {
            const rangeDate = enumerateBetweenDates(firstDate.statisticDate, moment(), 'months')
            return rangeDate.map((o) => getMonthYear(o))
        }
        return []
    }

    getOptions = (statistics) => {
        const xAxisDatas = this.getInitXAxis(statistics)
        const reduceDatas = this.getReduceStatistics(statistics)
        const dataSeries = xAxisDatas.map((o) => {
            if (reduceDatas[o]) {
                return reduceDatas[o].length
            }
            return 0
        })
        return {
            title: {
                text: 'Nombre de connexions par mois',
                top: 'top',
                left: 'center',
            },
            color: ['#29AE99'],
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: xAxisDatas,
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    type: 'bar',
                    data: dataSeries,
                },
            ],
        }
    }

    render() {
        const userStatistics = this.props.account
            ? this.props.accountStatistics
            : this.props.userStatistics
        const statistics = filter(userStatistics, (o) => o.value)
        if (statistics.length) {
            return (
                <ReactEchartsCore
                    echarts={echarts}
                    option={this.getOptions(statistics)}
                    notMerge
                    lazyUpdate
                    style={{ height: 200 }}
                />
            )
        }
        return <ProgressBar />
    }
}

AccessChartPanel.propTypes = {
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
}
const mapStateToProps = (store) => ({
    accountStatistics: store.AccountReducer.accountStatistics,
    userStatistics: store.UserReducer.userStatistics,
})

export default connect(mapStateToProps)(AccessChartPanel)
