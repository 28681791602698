import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { round } from '../utils/NumberUtil'
import { Center } from '../styles/main'

const Wrapper = styled.div`
    padding: 2rem;
    margin: 0 auto;
    width: ${(props) => props.width || '500px'};
`

const ColorLinearProgress = withStyles({
    root: {
        height: 5,
    },
})(LinearProgress)

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(3),
    },
}))

function ProgressBar({ progress, message, width }) {
    const classes = useStyles()
    if (progress) {
        return (
            <Wrapper width={width}>
                <Center>
                    {message || i18n.progressLoading} ({round(progress, 0)} %)
                </Center>
                <ColorLinearProgress className={classes.margin} />
            </Wrapper>
        )
    }
    return (
        <Wrapper width={width}>
            <Center>{message || i18n.progressLoading}</Center>
            <ColorLinearProgress className={classes.margin} />
        </Wrapper>
    )
}

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    message: PropTypes.string,
}

export default ProgressBar
