import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEqual, orderBy, slice, some } from 'lodash'
import i18n from 'simple-react-i18n'
import { WaitAction } from 'react-aquasys'
import TableBody from './TableBody'
import TableBodyColor from './TableBodyColor'
import TableHeader from './TableHeader'
import TableFilter from './TableFilter'
import TablePagination from './TablePagination'
import MoreVert from '../buttons/MoreVert'
import Input from '../forms/Input'
import { hasValue } from '../../utils/NumberUtil'
import { searchAllCharacters } from '../../utils/StringUtil'
import AppStore from '../../store/Appstore'
import Select from '../forms/Select'
import ExportAction from '../../utils/export/actions/ExportAction'
import { formatData } from '../../utils/ExportDataUtil'

class Table extends Component {
    state = {
        sortColumn: { column: '', sort: '' },
        filter: this.props.filter,
        page: 1,
        nbPerPage: -1,
        checked: false,
        data: [],
    }

    componentWillMount() {
        const newState = {
            datatable: this.props.data,
            checked: this.props.checked,
        }
        if (this.props.nbPerPageLabel && this.props.nbPerPageLabel.length > 0) {
            newState.nbPerPage = this.props.nbPerPageLabel[0].value
        }
        if (this.props.initialSort && this.props.initialSort.column) {
            newState.sortColumn = this.props.initialSort
        }
        this.setState(newState)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.length !== this.props.data.length && !this.props.keepSelectedPage) {
            this.changePage(1)
        }
    }

    onSort = (value) => {
        let sort = 'asc'
        if (this.state.sortColumn.sort === sort && this.state.sortColumn.column === value) {
            sort = 'desc'
        }
        this.props.onSort(value, sort)
        this.setState({ sortColumn: { column: value, sort } })
    }

    onFilter = (e) => {
        this.setState({ filter: e.target.value })
    }

    changePage = (page) => {
        this.setState({ page })
    }

    changeNbPerPage = (nbPerPage) => {
        this.setState({ nbPerPage })
    }

    export = (body) => [].concat(this.props.type, body).map((element) => Object.keys(element).map((key) => element[key]))

    onChangeValue = (value) => {
        this.setState({
            filter: value.toString(),
        })
    }

    getKeyResearch = () => {
        if (this.props.searchableKeys.length) {
            return this.props.searchableKeys
        } if (this.props.type.headers) {
            return this.props.type.headers
        }
        return Object.key(this.props.type).map((o) => o)
    }

    getDataHash = (d) => this.getKeyResearch().map((key) => {
        if (hasValue(d[key]) && hasValue(d[key].value)) {
            return d[key].value.toString()
        }
        if (hasValue(d[key])) {
            return d[key].toString()
        }
        return ''
    }).join('   ')

    searchableFunction = () => {
        if (this.state.filter) {
            return orderBy(
                this.props.data.filter((d) => searchAllCharacters(this.getDataHash(d)).includes(searchAllCharacters(this.state.filter))),
                [(d) => (hasValue(d.name) && hasValue(d.name.value) ? d.name.value.length : (hasValue(d.name) ? d.name.length : 0))],
            )
        }
        return this.props.data
    }

    getValues = (object) => object.map((key) => object[key])

    submitExport = (store, action, exportType, body, titleFile) => {
        store.dispatch(action(this.export(body), exportType, titleFile))
    }

    exportData = (functionElement, data) => {
        if (functionElement.transformFn) {
            const headers = this.getHeaders().map((h) => ({ value: i18n[h] }))
            const exportData = data.map((d) => functionElement.transformFn(d))
            exportData.splice(0, 0, headers)
            return exportData
        }
        return data
    }

    onChange = (value) => {
        this.setState({
            checked: value,
        })
    }

    onSubmit = () => {
        const exportData = []
        for (let idx = 0; idx < this.dataCheck.length; idx++) {
            const dataLine = this.dataCheck[idx]
            if (this.refs[idx].getValue()) {
                exportData.push(dataLine)
            }
        }
        return exportData
    }

    getExportDatas = () => {
        AppStore.dispatch(ExportAction.export(formatData(this.props.exportData.length ? this.props.exportData : this.props.data), 'xlsx', this.props.exportName))
    }

    getActions = (props = []) => {
        const properties = [...props]
        if (this.props.checkable) {
            return [].concat(['check'], properties)
        }
        if (this.props.duplicable) {
            properties.unshift('dupl')
        }
        if (this.props.alterable) {
            properties.unshift('alt')
        }
        if (this.props.deletable) {
            properties.unshift('del')
        }
        return properties
    }

    getHeaders = () => {
        const properties = (() => {
            if (this.props.type.headers) {
                return this.props.type.headers
            }
            return Reflect.ownKeys(this.props.type)
        })()
        return this.getActions(properties)
    }

    getTablePagination = (data) => {
        if (this.props.paging && this.state.nbPerPage != -1) {
            return (
                <TablePagination
                    nbElement={data.length}
                    index={this.state.page}
                    nbElementPerPage={this.state.nbPerPage}
                    smallPaging={this.props.smallPaging}
                    onChangePage={this.changePage}
                />
            )
        }
        return null
    }

    componentDidMount() {
        this.setState({ data: this.searchableFunction() })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.waitOnLoad && (this.state.filter !== prevState.filter || !isEqual(prevProps.data, this.props.data))) {
            AppStore.dispatch(WaitAction.waitStart())
            setTimeout(() => {
                this.setState({ data: this.searchableFunction() })
                AppStore.dispatch(WaitAction.waitStop())
            }, 20)
        } else if (!this.props.waitOnLoad && !isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.searchableFunction() })
        }
    }

    render() {
        // Searchable
        let { data } = this.state
        const nbElement = this.props.showNbElements ? (() => {
            const label = `${data.length} ${data.length ? i18n.elements : i18n.element}`
            return <i className="nbElements">{ `(${label})` }</i>
        })() : null

        // Sortable
        if (this.state.sortColumn.column) {
            const dateRegex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
            if (this.props.color) {
                if (data.length && some(data, (obj) => obj[this.state.sortColumn.column].value && dateRegex.test(obj[this.state.sortColumn.column].value))) {
                    data = orderBy(data, [(obj) => moment(obj[this.state.sortColumn.column].value, 'DD/MM/YYYY').valueOf()], this.state.sortColumn.sort)
                } else {
                    data = orderBy(data, [(obj) => obj[this.state.sortColumn.column].value], this.state.sortColumn.sort)
                }
            } else if (data.length && some(data, (obj) => obj[this.state.sortColumn.column] && dateRegex.test(obj[this.state.sortColumn.column]))) {
                data = orderBy(data, [(obj) => moment(obj[this.state.sortColumn.column], 'DD/MM/YYYY').valueOf()], this.state.sortColumn.sort)
            } else {
                data = orderBy(data, this.state.sortColumn.column, this.state.sortColumn.sort)
            }
        }
        const headers = this.getHeaders()
        const head = ((sortable) => {
            if (sortable) {
                return (
                    <thead>
                        <TableHeader
                            headers={headers}
                            sortColumn={this.state.sortColumn}
                            onSort={this.onSort}
                            condensed={this.props.condensed}
                            checked={this.state.checked}
                            checkable={this.props.checkable}
                            customHeaders={this.props.customHeaders}
                            headersOnClick={this.props.headersOnClick}
                            onChange={this.onChange}
                        />
                    </thead>
                )
            }
            return ''
        })(this.props.sortable)

        // Paging
        const tablePagination = (() => {
            if (this.props.paging && !this.props.smallPaging && data.length > this.props.nbPerPageLabel[0].value) {
                return (
                    <Select
                        elements={this.props.nbPerPageLabel}
                        onChange={this.changeNbPerPage}
                        selected={this.state.nbPerPage}
                    />
                )
            }
            return null
        })()

        const pagination = this.getTablePagination(data)
        if (this.state.nbPerPage != -1) {
            data = slice(data, (this.state.page - 1) * this.state.nbPerPage, (this.state.page * this.state.nbPerPage))
        }

        // Body
        const body = data.map((d, id) => {
            if (this.props.color) {
                return (
                    <TableBodyColor
                        data={d}
                        key={id}
                        ref={id}
                        editable={this.props.editable}
                        link={this.props.link}
                        subLink={this.props.subLink}
                        condensed={this.props.condensed}
                        checked={this.state.checked}
                        checkable={this.props.checkable}
                        headers={headers}
                        onClick={this.props.onClick}
                        bodyId={id}
                    />
                )
            }
            return (
                <TableBody
                    data={d}
                    key={id}
                    ref={id}
                    editable={this.props.editable}
                    deletable={this.props.deletable}
                    onDelete={this.props.onDelete}
                    duplicable={this.props.duplicable}
                    onDuplicate={this.props.onDuplicate}
                    alterable={this.props.alterable}
                    onAlter={this.props.onAlter}
                    link={this.props.link}
                    subLink={this.props.subLink}
                    condensed={this.props.condensed}
                    onLineOver={this.props.onLineOver}
                    checked={this.state.checked}
                    checkable={this.props.checkable}
                    onLineOut={this.props.onLineOut}
                    onClick={this.props.onClick}
                    headers={headers}
                    active={this.props.active}
                />
            )
        })

        this.dataCheck = data
        // Collapsable
        const icon = (() => '')()

        const moreVert = (() => {
            if (this.props.actions.length > 0) {
                return (<MoreVert links={this.props.actions} />)
            }
            return ''
        })()

        const collapseTable = (() => {
            if (icon) {
                let tableFilter = ''
                if (this.props.searchable) {
                    tableFilter = <TableFilter onFilter={this.onFilter} />
                }
                return (
                    <div className="card collapse collapseTable">
                        <div className="well">
                            { tableFilter }
                        </div>
                    </div>
                )
            }
            return ''
        })()

        const exports = (() => {
            if (this.props.exportFunction && this.props.exportFunction.length > 0 && data.length > 0) {
                return this.props.exportFunction.map((functionElement) => {
                    const iconMaterial = functionElement.iconName || 'insert_chart'
                    const className = functionElement.color ? `${functionElement.color} btn-floating` : 'red btn-floating'
                    const titleFile = functionElement.titleFile || 'export'

                    const exportData = this.exportData(functionElement, functionElement.full ? this.props.data : data)
                    return (
                        <span className="icon-table-toolbar"><a
                            className={className}
                            onClick={() => this.submitExport(functionElement.store, functionElement.action, functionElement.exportType, exportData, titleFile)}
                        ><i
                            className="material-icons"
                        >{ iconMaterial }
                            </i>
                        </a>
                        </span>
                    )
                })
            }
            return ''
        })()
        const paginationMenu = (() => {
            if (this.props.paging) {
                return (
                    <div className="row no-margin">
                        <div className="col l12 no-padding">
                            <div className="row no-margin">
                                <div className={this.props.smallPaging ? '' : 'col s1'}>
                                    { tablePagination }
                                </div>
                                <div className={this.props.smallPaging ? 'col s12' : 'col s11'}>
                                    { pagination }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            return null
        })()

        const exportButtonOnHeader = (() => {
            if (this.props.exportButtonOnHeader) {
                return (
                    <i
                        className="material-icons right clickable"
                        onClick={() => this.getExportDatas()}
                    >file_download
                    </i>
                )
            }
            return null
        })()

        const headerTitle = (() => {
            const activeClass = this.props.activeHeader ? ' active' : ''
            if (this.props.searchable) {
                return (
                    <div className={`tableTitle activator col s12${activeClass}`}>
                        <div className="col s6">
                            { icon }{ this.props.title } { nbElement }
                            { moreVert }
                        </div>
                        <Input
                            title={i18n.search}
                            value={this.state.filter}
                            col={6}
                            className="icon-color-black"
                            onEnterKeyPress={(value) => this.onChangeValue(value)}
                        />
                    </div>
                )
            }
            return (
                <div className={`tableTitle activator no-margin${activeClass}`}>
                    <div className="col s12 no-padding">
                        { icon }{ this.props.title } { nbElement }
                        { moreVert } { exportButtonOnHeader }
                    </div>
                </div>
            )
        })()

        const header = (() => {
            if (this.props.showTitle) {
                return headerTitle
            }
            return null
        })()
        return (
            <div className={`card${this.props.className}`}>
                { header }
                <div className="right text-center">{ exports }</div>
                <div className="card-content no-padding">
                    <div className="dataTables_wrapper dt-material no-footer">
                        { collapseTable }
                        <div className="row no-margin">
                            <div className="col l12 " style={{ height: `${this.props.height}px`, overflow: 'auto' }}>
                                <table
                                    className={`highlight table row-border order-column datatable no-footer responsive-table no-padding ${this.props.tableClassName}`}
                                    id={this.props.id}
                                >
                                    { head }
                                    <tbody>
                                        { body }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        { paginationMenu }
                    </div>
                </div>
            </div>
        )
    }
}

Table.propTypes = {
    icon: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.instanceOf(PropTypes.object),
    title: PropTypes.string,
    sortable: PropTypes.bool,
    searchable: PropTypes.bool,
    searchableKeys: PropTypes.instanceOf(PropTypes.string),
    editable: PropTypes.bool,
    duplicable: PropTypes.bool,
    onDuplicate: PropTypes.func,
    alterable: PropTypes.bool,
    onAlter: PropTypes.func,
    deletable: PropTypes.bool,
    onDelete: PropTypes.func,
    onSort: PropTypes.bool,
    initialSort: PropTypes.bool,
    className: PropTypes.string,
    customHeaders: PropTypes.object,
    headersOnClick: PropTypes.object,
    paging: PropTypes.bool,
    smallPaging: PropTypes.bool,
    nbPerPageLabel: PropTypes.arrayOf(PropTypes.element),
    link: PropTypes.string,
    subLink: PropTypes.string,
    condensed: PropTypes.bool,
    showNbElements: PropTypes.bool,
    showTitle: PropTypes.bool,
    waitOnLoad: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        onClick: PropTypes.func,
        color: PropTypes.string,
        iconName: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
    })),
    onClick: PropTypes.func,
    onLineOver: PropTypes.func,
    onLineOut: PropTypes.func,
    exportFunction: PropTypes.arrayOf(PropTypes.shape({
        store: PropTypes.object.isRequired,
        action: PropTypes.func.isRequired,
        exportType: PropTypes.string.isRequired,
        titleFile: PropTypes.string,
        iconName: PropTypes.string,
        color: PropTypes.string,
    })),
    color: PropTypes.bool,
    id: PropTypes.string,
    height: PropTypes.number,
    checked: PropTypes.bool,
    checkable: PropTypes.bool,
    fixed: PropTypes.bool,
    exportButtonOnHeader: PropTypes.bool,
    exportName: PropTypes.string,
    exportData: PropTypes.arrayOf(PropTypes.element),
    activeHeader: PropTypes.bool,
    active: PropTypes.bool,
    keepSelectedPage: PropTypes.bool,
    tableClassName: PropTypes.string,
    filter: PropTypes.string,
}

Table.defaultProps = {
    data: [],
    sortable: false,
    searchable: false,
    searchableKeys: [],
    editable: false,
    deletable: false,
    duplicable: false,
    alterable: false,
    paging: false,
    waitOnLoad: true,
    nbPerPageLabel: [],
    actions: [],
    customHeaders: {},
    headersOnClick: {},
    color: false,
    condensed: false,
    id: 'dataTableComponent',
    showNbElements: true,
    showTitle: true,
    exportButtonOnHeader: false,
    exportName: 'Export',
    exportData: [],
    tableClassName: '',
    onLineOver: () => {},
    onLineOut: () => {},
    onSort: () => {},
    onClick: undefined,
    onDuplicate: () => {},
    onAlter: () => {},
    className: '',
    activeHeader: false,
    active: true,
    filter: '',
}

export default Table
