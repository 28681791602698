import styled from 'styled-components'
import { mainPink } from './theme'

export const MainContainer = styled.div`
    margin: 1rem 0 0 ${(props) => (props.noWidth ? '2.5rem' : '0')};
    ${(props) => (props.noWidth ? '' : 'width: 85%;')};
`

// The container to use when there is a slider nav on the left of the page
export const SecondaryMainContainer = styled(MainContainer)`
    margin: ${(props) => (props.noMargin ? '1rem 0 0 0' : '1rem 0 0 4rem;')};
`

export const CenterWrapper = styled.div`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
`

export const Card = styled.div`
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
`

export const RefTable = styled.div`
    width: 90vw;
    height: 70vh;
`
export const Position = styled.div`
    position: absolute;
    right: 4rem;
    top: 60px;
`

export const PinkBloc = styled.fieldset`
    width: 100%;
    border: 4px solid ${mainPink};
    border-radius: 5px;
    margin: 2rem 0;
`

export const PinkTitle = styled.legend`
    width: fit-content;
    height: 30px;
    font-size: 1.4rem;
    color: ${mainPink};
    background-color: white;
    position: absolute;
    top: -20px;
    left: 10px;
    padding: 0 10px;
    text-align: center;
`
