import React, { Component } from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import User from '../../../pages/account/dto/User'
import { contactUrl } from '../../../conf/SieauConstants'
import ContactDto from '../../../pages/referencial/components/contact/dto/ContactDto'
import UserAction from '../../../pages/administration/components/user/actions/UserAction'

class AccountPanel extends Component {
    getContactInformations = () => {
        const values = (() => {
            const defaultValues = [this.props.accountUser.login, contactUrl]
            if (this.props.accountUser.contactCode) {
                const contact = find(this.props.contacts, (o) => o.id == this.props.accountUser.contactCode)
                if (contact) {
                    return [contact.name, contact.email]
                }
            }
            return defaultValues
        })()
        return (
            <div>
                <Link to={{ pathname: '/account', state: { login: this.props.accountUser.login } }}>
                    <span className="white-text name text-shadow">
                        <b>{values[0]}</b>
                    </span>
                    <br />
                    <span className="white-text email text-shadow">{values[1]}</span>
                </Link>
            </div>
        )
    }

    render() {
        return (
            <div className="userView">
                <div id="bg_slide" style={{ paddingTop: '3rem' }}>
                    <a href="/#/account">
                        <span
                            className="circle btn-floating background-aquasys"
                            style={{ margin: '1rem 0' }}
                        >
                            <i className="material-icons person">person</i>
                        </span>
                    </a>
                    {this.getContactInformations()}
                </div>
            </div>
        )
    }
}

AccountPanel.propTypes = {
    accountUser: PropTypes.instanceOf(User),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
}
const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    contacts: store.ContactReducer.contacts,
})

const mapDispatchToProps = {
    fetchUser: UserAction.fetchUser,
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountPanel)
