/* eslint-disable no-case-declarations */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'
import { orderBy } from 'lodash'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import HomeAction from '../../home/actions/HomeAction'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import { Flex } from '../../../styles/main'
import ModalBricksObjectifs from './ModalBricksObjectifs'
import SettingDto from '../../administration/dto/SettingDto'
import { GreenButton } from '../../../styles/button'
import { hasValue, round } from '../../../utils/NumberUtil'
import TerritoryAction from '../actions/TerritoryAction'

const ColWidth = styled.div`
    width: ${(props) => (props.width ? props.width : '15%')};
    margin: 0.3rem;
`

const LabelWrapper = styled.span`
    width: ${(props) => (props.short ? '20%' : '34%')};
`

const InputRow = styled.input`
    background-color: ${(props) => props.disabled && '#F0F0F0 !important'};
`

const BodyContent = styled(Flex)`
    padding: 5px 0 5px 2.4rem;
    border-bottom: 1px solid;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

const Wrapper = styled(Flex)`
    width: 100%;
`

const MainWrapper = styled.div`
    padding: 0 8px;
    position: relative;
`

const selectStyle = {
    height: '48px',
    width: '200px',
}

const selectDisabledMode = {
    ...selectStyle,
    backgroundColor: 'rgb(240, 240, 240)',
}

const styleInputError = {
    color: 'red',
    borderColor: 'red',
}

const parents = [
    { id: 1, name: 'Rendement +' },
    { id: 2, name: 'Raisonné' },
    { id: 3, name: 'Econome' },
    { id: 4, name: 'Econome +' },
    { id: 5, name: 'AB' },
]
const typeOptions = [
    { id: 4, name: 'Strictement égale à' },
    { id: 6, name: 'Inférieur ou égale à' },
    { id: 7, name: 'Supérieur ou égale à' },
]
const surfaceOptions = [
    { id: 0, name: 'Valeur (ha)' },
    { id: 1, name: 'Variable (%)' },
]

class ScenarObjectifs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectifNumber: ((props.scenario.objectifs || []).length || 3) * -1,
            indicObjectifs: this.getObjectifsByCategory([...props.scenario.objectifs], 2),
            prodObjectifs: this.getObjectifsByCategory([...props.scenario.objectifs], 1),
            surfObjectifs: this.getObjectifsByCategory([...props.scenario.objectifs], 0),
            editMode: props.editMode,
        }
    }

    componentDidMount() {
        const { scenario } = this.props
        if (scenario.scRef !== 0) this.props.fetchScenariosToCompare([scenario.scRef])
        if (this.state.editMode) {
            this.props.setEditMode()
        } else {
            this.props.setReadMode()
        }
    }

    onChangeObjectif = (objectif, key, value, bis) => {
        const valueFormatted = key === 'val_1' || key === 'val_2' ? parseFloat(value) : value
        const newObjectif = {
            ...objectif,
            valueRefCalc: key === 'category' || (key === 'objectifRef' && !bis) ? null : objectif.valueRefCalc,
            valueRefCalcBis: key === 'category' || (key === 'objectifRef' && bis) ? null : objectif.valueRefCalcBis,
            [key]: valueFormatted,
        }
        const { surfObjectifs, indicObjectifs, prodObjectifs } = this.state
        let newObjectifs = []
        if (objectif.category === 0) {
            const newState = surfObjectifs.map((obj) => {
                if (obj.id === newObjectif.id) {
                    return newObjectif
                }
                return obj
            })
            newObjectifs = indicObjectifs.concat(prodObjectifs, newState)
            this.setState({ surfObjectifs: newState })
        } else if (objectif.category === 1) {
            const newState = prodObjectifs.map((obj) => {
                if (obj.id === newObjectif.id) {
                    return newObjectif
                }
                return obj
            })
            newObjectifs = indicObjectifs.concat(newState, surfObjectifs)
            this.setState({ prodObjectifs: newState })
        } else if (objectif.category >= 2 || objectif.category === -1 || objectif.category === -2) {
            const newState = indicObjectifs.map((obj) => {
                if (obj.id === newObjectif.id) {
                    return newObjectif
                }
                return obj
            })
            newObjectifs = newState.concat(prodObjectifs, surfObjectifs)
            this.setState({ indicObjectifs: newState })
        }
        this.props.onChangeScenario('objectifs', newObjectifs)
    }

    getObjectifsByCategory(array, id) {
        let arrayFiltered = []
        if (id === 2) {
            arrayFiltered = array.filter((obj) => obj.category >= id || obj.category === -1 || obj.category === -2)
        } else {
            arrayFiltered = array.filter((obj) => obj.category === id)
        }
        arrayFiltered.sort((a, b) => b.id - a.id)
        return arrayFiltered
    }

    onChangeObjectifRefs = (key, value, bis) => {
        const data = this.state.selectedData.map((z) => ({
            scid: this.props.scenario.id,
            objid: value.id,
            typeR: key,
            refid: z,
        }))
        const result = data.concat(value.objectifRef.filter((objRef) => objRef.typeR !== key))
        this.onChangeObjectif(value, 'objectifRef', result, bis)
    }

    onChangeSelectedData = (key, value) => {
        this.setState({
            selectedData: value,
        })
    }

    removeObjectif = (type, objectif) => {
        const { indicObjectifs, prodObjectifs, surfObjectifs } = this.state
        let newsObjectifs = []
        switch (type) {
            case 'indicateurs':
                const newIndicObjs = indicObjectifs.filter((obj) => obj.id !== objectif.id)
                this.setState({
                    indicObjectifs: newIndicObjs,
                })
                newsObjectifs = newIndicObjs.concat(prodObjectifs, surfObjectifs)
                this.props.onChangeScenario('objectifs', newsObjectifs)
                break
            case 'production':
                const newProdObjs = prodObjectifs.filter((obj) => obj.id !== objectif.id)
                this.setState({
                    prodObjectifs: newProdObjs,
                })
                newsObjectifs = newProdObjs.concat(indicObjectifs, surfObjectifs)
                this.props.onChangeScenario('objectifs', newsObjectifs)
                break
            case 'surface':
                const newSurfObjs = surfObjectifs.filter((obj) => obj.id !== objectif.id)
                this.setState({
                    surfObjectifs: newSurfObjs,
                })
                newsObjectifs = newSurfObjs.concat(prodObjectifs, indicObjectifs)
                this.props.onChangeScenario('objectifs', newsObjectifs)
                break
            default:
                break
        }
    }

    findRefValue = (objectif, zonesSelected, culturesSelected, conduitesSelected) => {
        const { category: indicateurSelected } = objectif
        const { matrice, assolements, scenario, scenariosToCompare } = this.props
        const { indicateursValues } = matrice
        const values = indicateursValues.filter((v) => zonesSelected.find((z) => z.id === v.zid) && culturesSelected.find((c) => c.id === v.cid)
                && conduitesSelected.find((c) => c.id === v.mcid) && indicateurSelected === v.iid)
        let totalSurface = 0
        const refValues = scenario.scRef === 0 ? assolements : (scenariosToCompare[0].results || []).filter((r) => r.typeValeur === -4 && r.surface !== -999)
        const totalValues = values.map((v) => {
            const assolement = refValues.find((a) => a.zid === v.zid && a.cid === v.cid && a.mcid === v.mcid) || {}
            const surface = assolement.surface || 0
            totalSurface += surface
            return (v.value || 0) * surface
        }).reduce((a, b) => a + b, 0)
        const result = indicateurSelected === 1 ? totalValues : (totalValues / totalSurface)
        this.onChangeObjectif(
            objectif,
            'valueRefCalc',
            round(result || 0),
        )
    }

    findSurfaceRefValue = (objectif, zonesSelected, culturesSelected, conduitesSelected, bis) => {
        const { assolements } = this.props
        const values = assolements.filter((a) => zonesSelected.find((z) => z.id === a.zid) && culturesSelected.find((c) => c.id === a.cid)
                && conduitesSelected.find((c) => c.id === a.mcid))
        const result = values.map((v) => v.surface || 0).reduce((a, b) => a + b, 0)
        this.onChangeObjectif(
            objectif,
            bis ? 'valueRefCalcBis' : 'valueRefCalc',
            round(result || 0),
        )
    }

    getContentRow = (value, objectifProps) => {
        const { territoire, indicateurs, editMode, scenario,
            cultures, zones, conduites } = this.props
        const { indicObjectifs, prodObjectifs, surfObjectifs } = this.state

        const culturesFiltered = cultures.filter((ct) => territoire.cultures.some((c) => c.dataId === ct.id))

        const zonesFiltered = zones.filter((zt) => territoire.zones.some((z) => z.dataId === zt.id))

        const conduitesFiltered = conduites.filter((mct) => territoire.modesConduites.some((mc) => mc.dataId === mct.id))

        const culturesSelected = cultures.filter((ct) => objectifProps.objectifRef.some((c) => c.refid === ct.id && c.typeR === 'cid'))

        const zonesSelected = zones.filter((zt) => objectifProps.objectifRef.some((z) => z.refid === zt.id && z.typeR === 'zid'))

        const conduitesSelected = conduites.filter((mct) => objectifProps.objectifRef.some((mc) => mc.refid === mct.id && mc.typeR === 'mcid'))
        
        const conduitesWithParents = conduitesFiltered.map((c) => {
            const parent = parents.find((p) => p.id === c.parent)
            return {
                ...c,
                parent: parent.name,
            }
        })
        
        const indicateursOptions = indicateurs.filter(
            (i) => i.id !== scenario.iidDirection && i.id !== 1,
        )
        let objectif = {}
        if (value === 'indicateurs') {
            objectif = indicObjectifs.find((iObj) => iObj.id === objectifProps.id)
        } else if (value === 'production') {
            objectif = prodObjectifs.find((pObj) => pObj.id === objectifProps.id)
        } else if (value === 'surface') {
            objectif = surfObjectifs.find((sObj) => sObj.id === objectifProps.id)
        }

        let culturesSelectedPour = []
        let zonesSelectedPour = []
        let conduitesSelectedPour = []

        if (objectif.category === 0 && objectif.mode === 1) {
            culturesSelectedPour = cultures.filter((ct) => objectifProps.objectifRef.some((c) => c.refid === ct.id && c.typeR === 'ofCid'))

            zonesSelectedPour = zones.filter((zt) => objectifProps.objectifRef.some((z) => z.refid === zt.id && z.typeR === 'ofZid'))

            conduitesSelectedPour = conduites.filter((mct) => objectifProps.objectifRef.some(
                (mc) => mc.refid === mct.id && mc.typeR === 'ofMCid',
            ))
        }

        let nbObjectifs = 1
        switch (value) {
            case 'indicateurs':
                nbObjectifs = indicObjectifs.length
                break
            case 'production':
                nbObjectifs = prodObjectifs.length
                break
            case 'surface':
                nbObjectifs = surfObjectifs.length
                break
            default:
                break
        }

        return (
            <MainWrapper>
                <BodyContent>
                    <Wrapper>
                        <Checkbox
                            disabled={!editMode}
                            checked={objectif.actif}
                            onChange={(v) => this.onChangeObjectif(objectif, 'actif', v.target.checked)}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                {value === 'indicateurs' ? (
                                    <FormControl variant="outlined" disabled={!editMode} style={objectif.actif && (!hasValue(objectif.category) || objectif.category === -2) ? styleInputError : {}}>
                                        <Label htmlFor="status_type">
                                            Selectionner un indicateur&nbsp;:
                                        </Label>
                                        <Select
                                            className={objectif.actif && (!hasValue(objectif.category) || objectif.category === -2) ? 'errorCCE' : ''}
                                            style={
                                                !editMode
                                                    ? selectDisabledMode
                                                    : selectStyle
                                            }
                                            value={objectif.category}
                                            onChange={(v) => this.onChangeObjectif(
                                                objectif,
                                                'category',
                                                v.target.value,
                                            )}
                                        >
                                            {indicateursOptions.map((item) => (
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : ''}
                            </Grid>
                            <Grid item xs={8}>
                                <LabelWrapper>{i18n.description}&nbsp;:</LabelWrapper>
                                <InputRow
                                    className="sieau-input form-control"
                                    value={objectif.description}
                                    onChange={(e) => this.onChangeObjectif(
                                        objectif,
                                        'description',
                                        e.target.value,
                                    )}
                                    disabled={!editMode}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {editMode ? (
                                    <Icon
                                        className="clickable"
                                        onClick={() => this.addObjectif(value, objectif)}
                                    >
                                        control_point_duplicate
                                    </Icon>
                                ) : (
                                    ''
                                )}
                                {editMode && nbObjectifs > 1 ? (
                                    <Icon
                                        className="clickable"
                                        onClick={() => this.removeObjectif(value, objectif)}
                                    >
                                        remove_circle
                                    </Icon>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <ModalBricksObjectifs
                                    zone
                                    iconName="map"
                                    number={`${zonesSelected.length}/${territoire.zones.length}`}
                                    text={territoire.zones.length > 1 ? i18n.zones : i18n.zone}
                                    zonesData={zonesFiltered}
                                    onSave={this.onChangeObjectifRefs}
                                    onChange={this.onChangeSelectedData}
                                    selectedZones={zonesSelected}
                                    currentZones={zonesSelected}
                                    clickable
                                    isEditMode={editMode}
                                    addField={false}
                                    typeKey="zid"
                                    objectifId={objectif}
                                    error={objectif.actif && !zonesSelected.length}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ModalBricksObjectifs
                                    culture
                                    iconName="local_florist"
                                    number={`${culturesSelected.length}/${territoire.cultures.length}`}
                                    text={
                                        territoire.cultures.length > 1
                                            ? i18n.cultures
                                            : i18n.culture
                                    }
                                    culturesData={culturesFiltered}
                                    onSave={this.onChangeObjectifRefs}
                                    onChange={this.onChangeSelectedData}
                                    selectedCultures={culturesSelected}
                                    currentCultures={culturesSelected}
                                    clickable
                                    isEditMode={editMode}
                                    addField={false}
                                    typeKey="cid"
                                    objectifId={objectif}
                                    error={objectif.actif && !culturesSelected.length}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ModalBricksObjectifs
                                    driveMode
                                    iconName="speed"
                                    number={`${conduitesSelected.length}/${territoire.modesConduites.length}`}
                                    text={
                                        territoire.modesConduites.length > 1
                                            ? i18n.conduites
                                            : i18n.conduite
                                    }
                                    conduitesData={conduitesWithParents}
                                    onSave={this.onChangeObjectifRefs}
                                    onChange={this.onChangeSelectedData}
                                    selectedModesConduites={conduitesSelected}
                                    currentModesConduites={conduitesSelected}
                                    clickable
                                    isEditMode={editMode}
                                    addField={false}
                                    typeKey="mcid"
                                    objectifId={objectif}
                                    error={objectif.actif && !conduitesSelected.length}
                                />
                            </Grid>
                            {value === 'surface' ? (
                                <>
                                    <Grid item xs={3}>
                                        <FormControl variant="outlined" disabled={!editMode}>
                                            <Label htmlFor="status_type" style={objectif.actif && !hasValue(objectif.mode) ? styleInputError : {}}>{i18n.type}&nbsp;:</Label>
                                            <Select
                                                className={objectif.actif && !hasValue(objectif.mode) ? 'errorCCE' : ''}
                                                style={!editMode ? selectDisabledMode : selectStyle}
                                                value={objectif.mode}
                                                onChange={(v) => this.onChangeObjectif(
                                                    objectif,
                                                    'mode',
                                                    v.target.value,
                                                )}
                                            >
                                                {surfaceOptions.map((item) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ColWidth width="50%">
                                            <LabelWrapper style={objectif.actif && !hasValue(objectif.val_1) ? styleInputError : {}}>{i18n.min}&nbsp;:</LabelWrapper>
                                            <InputRow
                                                className="sieau-input form-control"
                                                value={objectif.val_1}
                                                onChange={(e) => this.onChangeObjectif(
                                                    objectif,
                                                    'val_1',
                                                    e.target.value,
                                                )}
                                                disabled={!editMode}
                                                type="number"
                                                step="0.01"
                                                style={objectif.actif && !hasValue(objectif.val_1) ? styleInputError : {}}
                                            />
                                        </ColWidth>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ColWidth width="50%">
                                            <LabelWrapper style={objectif.actif && !hasValue(objectif.val_2) ? styleInputError : {}}>{i18n.max}&nbsp;:</LabelWrapper>
                                            <InputRow
                                                className="sieau-input form-control"
                                                value={objectif.val_2}
                                                onChange={(e) => this.onChangeObjectif(
                                                    objectif,
                                                    'val_2',
                                                    e.target.value,
                                                )}
                                                disabled={!editMode}
                                                type="number"
                                                step="0.01"
                                                style={objectif.actif && !hasValue(objectif.val_2) ? styleInputError : {}}
                                            />
                                        </ColWidth>
                                    </Grid>
                                    {objectif.actif && zonesSelected.length && culturesSelected.length && conduitesSelected.length ? (
                                        <Grid item xs={3}>
                                            {hasValue(objectif.valueRefCalc) ? (
                                                <ColWidth width="50%">
                                                    <LabelWrapper>Valeur de référence :</LabelWrapper>
                                                    <InputRow
                                                        className="sieau-input form-control"
                                                        value={objectif.valueRefCalc}
                                                        disabled
                                                    />
                                                </ColWidth>
                                            ) : (
                                                <GreenButton
                                                    borderRadius
                                                    onClick={() => this.findSurfaceRefValue(objectif, zonesSelected, culturesSelected, conduitesSelected)}
                                                    style={{ height: 50 }}
                                                    disabled={!editMode}
                                                >
                                                    Calculer la valeur<br />de référence
                                                </GreenButton>
                                            )}
                                        </Grid>
                                    ) : null}
                                    {objectif.mode === 1 ? (
                                        <>
                                            <Grid item xs={4}>
                                                <ModalBricksObjectifs
                                                    zone
                                                    iconName="map"
                                                    number={`${zonesSelectedPour.length}/${territoire.zones.length}`}
                                                    text={
                                                        territoire.zones.length > 1
                                                            ? i18n.zones
                                                            : i18n.zone
                                                    }
                                                    zonesData={zonesFiltered}
                                                    onSave={(key, v) => this.onChangeObjectifRefs(key, v, true)}
                                                    onChange={this.onChangeSelectedData}
                                                    selectedZones={zonesSelectedPour}
                                                    currentZones={zonesSelectedPour}
                                                    clickable={editMode}
                                                    addField={false}
                                                    typeKey="ofZid"
                                                    objectifId={objectif}
                                                    error={objectif.actif && !zonesSelectedPour.length}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <ModalBricksObjectifs
                                                    culture
                                                    iconName="local_florist"
                                                    number={`${culturesSelectedPour.length}/${territoire.cultures.length}`}
                                                    text={
                                                        territoire.cultures.length > 1
                                                            ? i18n.cultures
                                                            : i18n.culture
                                                    }
                                                    culturesData={culturesFiltered}
                                                    onSave={(key, v) => this.onChangeObjectifRefs(key, v, true)}
                                                    onChange={this.onChangeSelectedData}
                                                    selectedCultures={culturesSelectedPour}
                                                    currentCultures={culturesSelectedPour}
                                                    clickable={editMode}
                                                    addField={false}
                                                    typeKey="ofCid"
                                                    objectifId={objectif}
                                                    error={objectif.actif && !culturesSelectedPour.length}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <ModalBricksObjectifs
                                                    driveMode
                                                    iconName="speed"
                                                    number={`${conduitesSelectedPour.length}/${territoire.modesConduites.length}`}
                                                    text={
                                                        territoire.modesConduites.length > 1
                                                            ? i18n.conduites
                                                            : i18n.conduite
                                                    }
                                                    conduitesData={conduitesWithParents}
                                                    onSave={(key, v) => this.onChangeObjectifRefs(key, v, true)}
                                                    onChange={this.onChangeSelectedData}
                                                    selectedModesConduites={conduitesSelectedPour}
                                                    currentModesConduites={conduitesSelectedPour}
                                                    clickable={editMode}
                                                    addField={false}
                                                    typeKey="ofMCid"
                                                    objectifId={objectif}
                                                    error={objectif.actif && !conduitesSelectedPour.length}
                                                />
                                            </Grid>
                                            {objectif.actif && zonesSelected.length && culturesSelected.length && conduitesSelected.length ? (
                                                <Grid item xs={3}>
                                                    {hasValue(objectif.valueRefCalcBis) ? (
                                                        <ColWidth width="50%">
                                                            <LabelWrapper>Valeur de référence :</LabelWrapper>
                                                            <InputRow
                                                                className="sieau-input form-control"
                                                                value={objectif.valueRefCalcBis}
                                                                disabled
                                                            />
                                                        </ColWidth>
                                                    ) : (
                                                        <GreenButton
                                                            borderRadius
                                                            onClick={() => this.findSurfaceRefValue(objectif, zonesSelectedPour, culturesSelectedPour, conduitesSelectedPour, true)}
                                                            style={{ height: 50 }}
                                                            disabled={!editMode}
                                                        >
                                                            Calculer la valeur<br />de référence
                                                        </GreenButton>
                                                    )}
                                                </Grid>
                                            ) : null}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                <>
                                    <Grid item xs={4}>
                                        <FormControl variant="outlined" disabled={!editMode} style={objectif.actif && !objectif.mode ? styleInputError : {}}>
                                            <Label htmlFor="status_type">
                                                Mode de comparaison&nbsp;:
                                            </Label>
                                            <Select
                                                className={objectif.actif && !objectif.mode ? 'errorCCE' : ''}
                                                style={!editMode ? selectDisabledMode : selectStyle}
                                                value={objectif.mode}
                                                onChange={(v) => this.onChangeObjectif(
                                                    objectif,
                                                    'mode',
                                                    v.target.value,
                                                )}
                                            >
                                                {typeOptions.map((item) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ColWidth width="50%">
                                            <LabelWrapper style={objectif.actif && !hasValue(objectif.val_1) ? styleInputError : {}}>
                                                {objectif.category === -1
                                                    ? `${i18n.valueLabel} (%)`
                                                    : i18n.valueLabel}
                                                &nbsp;:
                                            </LabelWrapper>
                                            <InputRow
                                                className="sieau-input form-control"
                                                value={objectif.val_1}
                                                onChange={(e) => this.onChangeObjectif(
                                                    objectif,
                                                    'val_1',
                                                    e.target.value,
                                                )}
                                                disabled={!editMode}
                                                type="number"
                                                step="0.01"
                                                style={objectif.actif && !hasValue(objectif.val_1) ? styleInputError : {}}
                                            />
                                        </ColWidth>
                                    </Grid>
                                    {objectif.actif && zonesSelected.length && culturesSelected.length && conduitesSelected.length && objectif.category ? (
                                        <Grid item xs={4}>
                                            {hasValue(objectif.valueRefCalc) ? (
                                                <ColWidth width="50%">
                                                    <LabelWrapper>Valeur de référence :</LabelWrapper>
                                                    <InputRow
                                                        className="sieau-input form-control"
                                                        value={objectif.valueRefCalc}
                                                        disabled
                                                    />
                                                </ColWidth>
                                            ) : (
                                                <GreenButton
                                                    borderRadius
                                                    onClick={() => this.findRefValue(objectif, zonesSelected, culturesSelected, conduitesSelected)}
                                                    style={{ height: 50 }}
                                                    disabled={!editMode}
                                                >
                                                    Calculer la valeur<br />de référence
                                                </GreenButton>
                                            )}
                                        </Grid>
                                    ) : null}
                                </>
                            )}
                        </Grid>
                    </Wrapper>
                </BodyContent>
                <Divider />
            </MainWrapper>
        )
    }

    addObjectif = (value, objectif = {}) => {
        const { indicObjectifs, prodObjectifs, surfObjectifs, objectifNumber } = this.state
        const { scenario } = this.props
        const id = objectifNumber - 1
        const objMerge = {
            scid: scenario.id,
            actif: false,
            mode: null,
            objectifRef: [],
            ...objectif,
            val_1: null,
            val_2: null,
            id,
        }
        switch (value) {
            case 'indicateurs':
                this.setState({
                    indicObjectifs: [
                        ...indicObjectifs,
                        {
                            ...objMerge,
                            category: -2,
                        },
                    ],
                    objectifNumber: id,
                })
                break
            case 'production':
                this.setState({
                    prodObjectifs: [
                        ...prodObjectifs,
                        {
                            ...objMerge,
                            category: 1,
                        },
                    ],
                    objectifNumber: id,
                })
                break
            case 'surface':
                this.setState({
                    surfObjectifs: [
                        ...surfObjectifs,
                        {
                            ...objMerge,
                            category: 0,
                        },
                    ],
                    objectifNumber: id,
                })
                break
            default:
                break
        }
    }

    getCardsObj = (value) => {
        const { indicObjectifs, prodObjectifs, surfObjectifs } = this.state
        switch (value) {
            case 'production':
                return {
                    cards: ([...orderBy(prodObjectifs.filter(({ id }) => id > 0), 'id'), ...orderBy(prodObjectifs.filter(({ id }) => id <= 0), 'id', 'desc')]).map((i) => ({
                        content: this.getContentRow(value, i),
                        color: 'null',
                        isFav: false,
                    })),
                    title: `Objectifs de production (${prodObjectifs.length} ${
                        prodObjectifs.length > 1 ? i18n.elements : i18n.element
                    })`,
                }
            case 'surface':
                return {
                    cards: ([...orderBy(surfObjectifs.filter(({ id }) => id > 0), 'id'), ...orderBy(surfObjectifs.filter(({ id }) => id <= 0), 'id', 'desc')]).map((i) => ({
                        content: this.getContentRow(value, i),
                        color: 'null',
                        isFav: false,
                    })),
                    title: `Objectifs de surface (${surfObjectifs.length} ${
                        surfObjectifs.length > 1 ? i18n.elements : i18n.element
                    })`,
                }
            case 'indicateurs': default:
                return {
                    cards: ([...orderBy(indicObjectifs.filter(({ id }) => id > 0), 'id'), ...orderBy(indicObjectifs.filter(({ id }) => id <= 0), 'id', 'desc')]).map((i) => ({
                        content: this.getContentRow(value, i),
                        color: 'null',
                        isFav: false,
                    })),
                    title: `Objectifs sur les indicateurs (${indicObjectifs.length} ${
                        indicObjectifs.length > 1 ? i18n.elements : i18n.element
                    })`,
                }
        }
    }

    getObjectifs = (value) => {
        const { cards, title } = this.getCardsObj(value)
        return (
            <>
                <GlobalCardList
                    style={{ padding: '8px 10px 10px' }}
                    data={[{
                        title: `${title}`,
                        cards,
                    }]}
                    collapsible
                />
                {this.props.editMode ? (
                    <Icon
                        className="clickable"
                        value={value}
                        onClick={() => this.addObjectif(value)}
                    >
                        add_circle
                    </Icon>
                ) : (
                    ''
                )}
                <Divider />
            </>
        )
    }

    render() {
        const { applicationSettings, scenario } = this.props
        this.props.setHelpPath('creer-et-simuler-des-scenarios/definir-des-objectifs/', applicationSettings)
        return (
            <>
                {this.getObjectifs('indicateurs')}
                {scenario.iidDirection !== 1 ? this.getObjectifs('production') : ''}
                {this.getObjectifs('surface')}
            </>
        )
    }
}

ScenarObjectifs.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    assolements: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    zones: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    conduites: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    indicateurs: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    scenariosToCompare: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    scenario: PropTypes.instanceOf(PropTypes.object),
    matrice: PropTypes.instanceOf(PropTypes.object),
    territoire: PropTypes.instanceOf(PropTypes.object),
    setHelpPath: PropTypes.func,
    setEditMode: PropTypes.func,
    setReadMode: PropTypes.func,
    onChangeScenario: PropTypes.func,
    editMode: PropTypes.bool,
    fetchScenariosToCompare: PropTypes.func,
}

const mapStateToProps = (store) => ({
    assolements: store.TerritoryReducer.assolements,
    territoires: store.TerritoryReducer.territoires,
    territoire: store.TerritoryReducer.territoire,
    matrice: store.TerritoryReducer.matrice,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    conduites: store.ReferencialReducer.conduites,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    scenariosToCompare: store.TerritoryReducer.scenariosToCompare,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    fetchScenariosToCompare: TerritoryAction.fetchScenariosToCompare,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarObjectifs)
