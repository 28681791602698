import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../../../utils/ToastrAction'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import { getUser } from '../../../../../utils/SettingUtils'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import { MainContainer, Position, RefTable } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import UpdatePanel from '../../../../../components/UpdatePanel'
import AccountAction from '../../../../account/actions/AccountAction'
import ModalWarning from '../../ModalWarning'
import ModalWarningPurge from '../../ModalWarningPurge'
import { getNameById } from '../../../../../utils/ArrayUtils'
import ModalAddReferencial from '../../ModalAddReferencial'
import ProgressBar from '../../../../../components/ProgressBar'
import { modesConduitesParentsList } from '../../../constants/ReferencialConstants'

class ConduitesApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            columnDefs: [],
            rowData: [],
            openWarningModal: false,
            openPurgeModal: false,
            openAddModal: false,
            elementsUsed: [],
            initialData: [],
            conduitesIdSelected: [],
            valuesChanged: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.conduites,
                href: 'referencial/conduites',
            },
        ])
        this.props.fetchConduites().then(() => {
            this.setData(this.props.conduites)
            this.setState({ initialData: this.props.conduites })
        })
        this.setReadOnlyMode()
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
    }

    setEditable(editable) {
        this.setState({
            columnDefs: [
                {
                    headerName: i18n.code,
                    field: 'id',
                    headerCheckboxSelection: editable,
                    headerCheckboxSelectionFilteredOnly: editable,
                    checkboxSelection: editable,
                },
                {
                    headerName: i18n.name,
                    field: 'name',
                },
                {
                    headerName: i18n.parent,
                    field: 'parent',
                },
                {
                    headerName: i18n.referencial,
                    field: 'referentiel',
                    cellRenderer: (params) => {
                        const input = document.createElement('input')
                        input.disabled = !editable
                        input.type = 'checkbox'
                        const valueFind = this.state.valuesChanged.find(
                            (value) => value.id === params.data.id,
                        )
                        input.checked = valueFind ? valueFind.referentiel : params.value
                        input.addEventListener('click', () => {
                            if (!this.state.valuesChanged.find((z) => z.id === params.data.id)) {
                                const newData = {
                                    ...params.data,
                                    referentiel: !params.value,
                                }
                                const newState = [...this.state.valuesChanged, newData]
                                this.setState({ valuesChanged: newState })
                            } else {
                                const newState = this.state.valuesChanged.filter(
                                    (z) => z.id !== params.data.id,
                                )
                                this.setState({ valuesChanged: newState })
                            }
                        })
                        return input
                    },
                },
                {
                    headerName: i18n.user,
                    field: 'usermaj',
                },
                {
                    headerName: i18n.modificationDate,
                    field: 'datemaj',
                },
            ],
        })
    }

    setEditMode = () => {
        this.setEditable(true)
        const actions = {
            cancel: () => {
                this.setState({ dataLoaded: false, conduitesIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchConduites()
                    .then(() => this.setData(this.props.conduites))
                    .then(() => this.setReadOnlyMode())
            },
        }
        if (getUser().isAdmin === '1') {
            actions.new = () => {
                this.modalAddIsOpen()
            }
            actions.save = () => {
                this.state.valuesChanged.forEach((mc) => {
                    const newModeConduite = {
                        ...mc,
                        parent: this.getParentId(mc.parent),
                        datemaj: null,
                    }
                    this.props.updateConduite(mc.id, newModeConduite)
                })
                this.setState({ dataLoaded: false, conduitesIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchConduites()
                    .then(() => this.setData(this.props.conduites))
                    .then(() => this.setReadOnlyMode())
            }
            actions.deleteCheck = () => {
                this.props.deleteConduites(this.state.conduitesIdSelected).then((json = {}) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                        this.setState({
                            dataLoaded: false,
                            conduitesIdSelected: [],
                            valuesChanged: [],
                        })
                        this.props
                            .fetchConduites()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.conduites))
                    } else if (json.used) {
                        const elementsUsedWithName = json.used.map((element) => ({
                            id: element,
                            name: getNameById(this.props.conduites, element),
                        }))
                        this.setState({
                            dataLoaded: false,
                            conduitesIdSelected: [],
                            valuesChanged: [],
                        })
                        this.props
                            .fetchConduites()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.conduites))
                        this.setState({ elementsUsed: elementsUsedWithName })
                        this.modalWarningIsOpen()
                    }
                })
            }
            actions.purge = () => {
                this.modalPurgeIsOpen()
            }
        }
        this.setActions(actions)
    }

    purgeConduites = () => {
        this.setState({
            dataLoaded: false,
            conduitesIdSelected: [],
            valuesChanged: [],
        })
        this.props.purgeConduites().then(() => {
            this.props.fetchConduites().then(() => this.setData(this.props.conduites))
        })
    }

    setReadOnlyMode = () => {
        this.setEditable(false)
        if (getUser().isAdmin === '1') {
            this.setActions({
                edit: () => this.setEditMode(),
                export: () => this.props.exportExcelConduites(),
            })
        } else {
            this.setActions({})
        }
    }

    getParentName(id) {
        const parent = modesConduitesParentsList.find((p) => p.value === id)
        if (parent) {
            return parent.label
        }
        return ''
    }

    getParentId(name) {
        const parent = modesConduitesParentsList.find((p) => p.label === name)
        if (parent) {
            return parent.value
        }
        return null
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = this.props.conduites.filter((c) => c.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    setData(conduites) {
        const arr = conduites.map((conduite) => ({
            id: conduite.id,
            parent: this.getParentName(conduite.parent),
            name: conduite.name,
            referentiel: conduite.referentiel,
            usermaj: conduite.usermaj,
            datemaj: conduite.datemaj,
        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    onSelectConduites = (key, data) => {
        this.setState({ conduitesIdSelected: data })
    }

    modalAddIsOpen = () => {
        this.setState({ openAddModal: true })
    }

    modalAddIsClose = () => {
        this.setState({ dataLoaded: false })
        this.props.fetchConduites().then(() => this.setData(this.props.conduites))
        this.setState({ openAddModal: false })
    }

    modalWarningIsOpen = () => {
        this.setState({ openWarningModal: true })
    }

    modalWarningIsClose = () => {
        this.setState({ openWarningModal: false })
    }

    modalPurgeIsOpen = () => {
        this.setState({ openPurgeModal: true })
    }

    modalPurgeIsClose = () => {
        this.setState({ openPurgeModal: false })
    }

    render() {
        const {
            dataLoaded,
            openAddModal,
            openWarningModal,
            openPurgeModal,
            elementsUsed,
            columnDefs,
            rowData,
        } = this.state
        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <RefTable className="ag-theme-balham">
                            <div style={{ width: '100%', margin: '3rem' }}>
                                <Position>{this.getUpdatePanel()}</Position>
                            </div>
                            <AgGridTable
                                columnDefs={columnDefs}
                                rowData={rowData}
                                onChange={this.onSelectConduites}
                            />
                        </RefTable>
                    ) : (
                        <ProgressBar />
                    )}
                    {openAddModal && (
                        <ModalAddReferencial
                            driveMode
                            handleClose={this.modalAddIsClose}
                            open={openAddModal}
                        />
                    )}
                    {openWarningModal && (
                        <ModalWarning
                            handleClose={this.modalWarningIsClose}
                            open={openWarningModal}
                            elementsUsed={elementsUsed}
                        />
                    )}
                    {openPurgeModal && (
                        <ModalWarningPurge
                            handleClose={this.modalPurgeIsClose}
                            open={openPurgeModal}
                            purge={this.purgeConduites}
                        />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

ConduitesApp.propTypes = {
    getLink: PropTypes.func,
}

const mapStateToProps = (store) => ({
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    conduites: store.ReferencialReducer.conduites,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchConduites: ReferencialAction.fetchConduites,
    updateConduite: ReferencialAction.updateConduite,
    createConduite: ReferencialAction.createConduite,
    deleteConduites: ReferencialAction.deleteConduites,
    purgeConduites: ReferencialAction.purgeConduites,
    exportExcelConduites: ReferencialAction.exportExcelConduites,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConduitesApp)
