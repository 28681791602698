export default class DtoEventQualitometer {
    constructor(obj) {
        this.id = obj.number
        this.code = obj.code
        this.number = obj.number
        this.eventType = obj.eventType
        this.date = obj.date
        this.volt = obj.volt
        this.comment = obj.comment
        this.chgtpiles = obj.chgtpiles
        this.graph = obj.graph
        this.bankExport = obj.bankExport
        this.problem = obj.problem
        this.solution = obj.solution
        this.eventHour = obj.eventHour
        this.razstation = obj.razstation
        this.valeurcalee = obj.valeurcalee
        this.sondeTemperature = obj.sondeTemperature
        this.centralTemperature = obj.centralTemperature
        this.contactCode = obj.contactCode
        this.ns = obj.ns
        this.nc = obj.nc
        this.closeDate = obj.closeDate
        this.campaignCode = obj.campaignCode
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.action = obj.action
        this.stationType = 3
        this.stationCode = obj.stationCode
        this.stationId = obj.stationId
    }
}
