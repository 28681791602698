module.exports = {
    PATH_APP: '/app',
    PATH_HOME: '/home',
    PATH_REGISTER: '/askAccount',
    PATH_LOGIN: '/login',
    PATH_RESET: '/reset',
    PATH_CONTACT: '/contact',
    PATH_DASHBOARD: 'dashboard',
    PATH_DASHBOARD_LINK: '/dashboard',
    PATH_DASHBOARD_TRACKING: '/tracking',
    PATH_INVENTORY: 'inventory',
    ADMINISTRATION: 'administration',
    REFERENCIAL: 'referencial',
    PATH_REFERENCIAL: '/referencial',
    PATH_REFERENCIAL_LINK: '/referencial/',
    PATH_REFERENCIAL_ITEM: '/referencialItem',
    PATH_REFERENCIAL_DASHBOARD: '/referencial/dashboard',
    PATH_REFERENCIAL_CULTURES: '/referencial/cultures',
    PATH_REFERENCIAL_RPG: '/referencial/rpg',
    PATH_REFERENCIAL_MATRICE_REF: '/referencial/matriceRef',
    PATH_REFERENCIAL_CONDUITES: '/referencial/conduites',
    PATH_REFERENCIAL_ZONES: '/referencial/zones',
    PATH_REFERENCIAL_COMMUNES: '/referencial/communes',
    PATH_REFERENCIAL_INDICATEURS: '/referencial/indicateurs',
    PATH_REFERENCIAL_ROTATION: '/referencial/rotation',
    CONTRIBUTOR: 'contributor',
    CONTRIBUTOR_LINK: 'contributor/',
    PATH_REFERENCIAL_CONTRIBUTOR: '/referencial/contributor',
    PATH_REFERENCIAL_CONTRIBUTOR_LINK: '/referencial/contributor/',
    PATH_REFERENCIAL_CONTRIBUTOR_NEW: '/referencial/contributor/new',
    LINK_REFERENCIAL_CONTRIBUTOR: 'referencial/contributor',
    WATERSHED: 'watershed',
    WATERSHED_LINK: 'watershed/',
    PATH_REFERENCIAL_WATERSHED: '/referencial/watershed',
    PATH_REFERENCIAL_WATERSHED_ITEM: '/referencialItem/watershed',
    PATH_REFERENCIAL_WATERSHED_ITEM_LINK: '/referencialItem/watershed/',
    PATH_REFERENCIAL_WATERSHED_ITEM_NEW: '/referencialItem/watershed/new',
    LINK_REFERENCIAL_WATERSHED_ITEM: 'referencialItem/watershed',
    CITY: 'city',
    CITY_LINK: 'city/',
    PATH_REFERENCIAL_CITY: '/referencial/city',
    PATH_REFERENCIAL_CITY_LINK: '/referencial/city/',
    PATH_REFERENCIAL_CITY_NEW: '/referencial/city/new',
    LINK_REFERENCIAL_CITY: 'referencial/city',
    CONTACT: 'contact',
    CONTACT_LINK: 'contact/',
    PATH_REFERENCIAL_CONTACT: '/referencial/contact',
    PATH_REFERENCIAL_CONTACT_LINK: '/referencial/contact/',
    PATH_REFERENCIAL_CONTACT_NEW: '/referencial/contact/new',
    LINK_REFERENCIAL_CONTACT: 'referencial/contact',
    HYDROGEOLOGICAL_ENTITY: 'hydrogeologicalEntity',
    HYDROGEOLOGICAL_ENTITY_LINK: 'hydrogeologicalEntity/',
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY:
        '/referencial/hydrogeologicalEntity',
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_LINK:
        '/referencial/hydrogeologicalEntity/',
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_NEW:
        '/referencial/hydrogeologicalEntity/new',
    LINK_REFERENCIAL_HYDROGEOLOGICAL_ENTITY:
        'referencial/hydrogeologicalEntity',
    UNIT: 'unit',
    UNIT_LINK: 'unit/',
    PATH_REFERENCIAL_UNIT_ENTITY: '/referencial/unit',
    PATH_REFERENCIAL_UNIT_ENTITY_LINK: '/referencial/unit/',
    PATH_REFERENCIAL_UNIT_ENTITY_NEW: '/referencial/unit/new',
    LINK_REFERENCIAL_UNIT_ENTITY: 'referencial/unit',
    METHOD: 'method',
    METHOD_LINK: 'method/',
    PATH_REFERENCIAL_METHOD: '/referencial/method',
    PATH_REFERENCIAL_METHOD_LINK: '/referencial/method/',
    PATH_REFERENCIAL_METHOD_NEW: '/referencial/method/new',
    LINK_REFERENCIAL_METHOD: 'referencial/method',
    PARAMETER: 'parameter',
    PARAMETER_LINK: 'parameter/',
    PATH_REFERENCIAL_PARAMETER: '/referencial/parameter',
    PATH_REFERENCIAL_PARAMETER_ITEM: '/referencialItem/parameter',
    PATH_REFERENCIAL_PARAMETER_ITEM_LINK: '/referencialItem/parameter/',
    PATH_REFERENCIAL_PARAMETER_ITEM_NEW: '/referencialItem/parameter/new',
    LINK_REFERENCIAL_PARAMETER_ITEM: 'referencialItem/parameter',
    FRACTION: 'fraction',
    FRACTION_LINK: 'fraction/',
    PATH_REFERENCIAL_FRACTION: '/referencial/fraction',
    PATH_REFERENCIAL_FRACTION_LINK: '/referencial/fraction/',
    PATH_REFERENCIAL_FRACTION_NEW: '/referencial/fraction/new',
    LINK_REFERENCIAL_FRACTION: 'referencial/fraction',
    NETWORK: 'network',
    NETWORK_LINK: 'network/',
    PATH_REFERENCIAL_NETWORK: '/referencial/network',
    PATH_REFERENCIAL_NETWORK_LINK: '/referencial/network/',
    PATH_REFERENCIAL_NETWORK_NEW: '/referencial/network/new',
    LINK_REFERENCIAL_NETWORK: 'referencial/network',
    WATERMASS: 'watermass',
    WATERMASS_LINK: 'watermass/',
    PATH_REFERENCIAL_WATERMASS: '/referencial/watermass',
    PATH_REFERENCIAL_WATERMASS_ITEM: '/referencialItem/watermass',
    PATH_REFERENCIAL_WATERMASS_ITEM_LINK: '/referencialItem/watermass/',
    PATH_REFERENCIAL_WATERMASS_ITEM_NEW: '/referencialItem/watermass/new',
    LINK_REFERENCIAL_WATERMASS_ITEM: 'referencialItem/watermass',
    SUPPORT: 'support',
    SUPPORT_LINK: 'support/',
    PATH_REFERENCIAL_SUPPORT: '/referencial/support',
    PATH_REFERENCIAL_SUPPORT_LINK: '/referencial/support/',
    PATH_REFERENCIAL_SUPPORT_NEW: '/referencial/support/new',
    LINK_REFERENCIAL_SUPPORT: 'referencial/support',
    PATH_CONTENTS: '/contents',
    PATH_CONTENTS_LINK: '/contents/',
    IMPORT: 'import',
    PATH_IMPORT: '/import',
    PATH_IMPORT_LINK: '/import/',
    GRAPHIC: 'graphic',
    PATH_GRAPHIC: '/graphic',
    JOB: 'job',
    PATH_JOB: '/job',
    PATH_JOB_EXECUTION: '/job/execution',
    PATH_JOB_LIST: '/job/list',
    CAMPAIGN: 'campaign',
    QUALITY: 'quality',
    QUALITY_LINK: 'quality/',
    QUALITY_CAMPAIGN_LINK: 'quality/campaign/',
    PATH_QUALITY: '/quality',
    PATH_QUALITY_OPTIONS: '/quality/qualityOptions',
    PIEZOMETRY: 'piezometry',
    PIEZOMETRY_LINK: 'piezometry/',
    PIEZOMETRYOPTIONS: 'piezometryOptions',
    PIEZOMETRICFOLLOWUP: 'piezometricFollowUp',
    PIEZOMETRY_CAMPAIGN: 'piezometry/campaign',
    PIEZOMETRY_CAMPAIGN_LINK: 'piezometry/campaign/',
    PATH_PIEZOMETRY: '/piezometry',
    PATH_PIEZOMETRY_CAMPAIGN: '/piezometry/campaign',
    PATH_PIEZOMETRY_CAMPAIGN_LINK: '/piezometry/campaign/',
    PATH_PIEZOMETRY_CAMPAIGN_NEW_DASHBOARD: 'piezometry/campaign/new/dashboard',
    PATH_PIEZOMETRY_CAMPAIGN_DASHBOARD: (id) => `/piezometry/campaign/${id}/dashboard`,
    PATH_QUALITY_CAMPAIGN_DASHBOARD: (id) => `/quality/campaign/${id}/dashboard`,
    PLUVIOMETRY: 'pluviometry',
    PLUVIOMETRY_LINK: 'pluviometry/',
    PLUVIOMETRYOPTIONS: 'pluviometryOptions',
    PATH_PLUVIOMETRY: '/pluviometry',
    HYDROMETRY: 'hydrometry',
    HYDROMETRY_LINK: 'hydrometry/',
    HYDROMETRYOPTIONS: 'hydrometryOptions',
    HYDROMETRICFOLLOWUP: 'hydrometricFollowUp',
    PATH_HYDROMETRY: '/hydrometry',
    PRODUCTION: 'productionUnit',
    PRODUCTION_LINK: 'productionUnit/',
    PATH_PRODUCTION: '/productionUnit',
    INSTALLATION: 'installation',
    INSTALLATION_LINK: 'installation/',
    INSTALLATIONFOLLOWUP: 'installationFollowUp',
    PATH_INSTALLATION: '/installation',
    DISTRIBUTION: 'distributionUnit',
    DISTRIBUTION_LINK: 'distributionUnit/',
    PATH_DISTRIBUTION: '/distributionUnit',
    PATH_GLOBALEVENTS: '/globalEvents',
    PATH_STATION: '/station',
    PATH_STATION_LINK: '/station/',
    PATH_MATERIEL: '/materiel',
    PATH_MATERIEL_LINK: 'materiel/',
    PATH_STATION_MATERIEL: 'materiel',
    PATH_STATION_VALIDATION: 'validation',
    PATH_MATERIEL_CENTRAL: 'central',
    PATH_MATERIEL_CENTRAL_LINK: 'central/',
    PATH_MATERIEL_POWER_SUPPLY: 'powerSupply',
    PATH_MATERIEL_POWER_SUPPLY_LINK: 'powerSupply/',
    PATH_MATERIEL_SENSOR: 'sensor',
    PATH_MATERIEL_SENSOR_LINK: 'sensor/',
    PATH_MATERIEL_SIM: 'sim',
    PATH_MATERIEL_SIM_LINK: 'sim/',
    PATH_MATERIEL_TELECOM: 'telecom',
    PATH_MATERIEL_TELECOM_LINK: 'telecom/',
    PATH_MATERIEL_VARIOUS_MATERIEL: 'variousMateriel',
    PATH_MATERIEL_VARIOUS_MATERIEL_LINK: 'variousMateriel/',
    PATH_MATERIEL_EQUIPMENT: 'equipment',
    PATH_MATERIEL_EQUIPMENT_LINK: 'equipment/',
    PATH_ADMINISTRATION: '/administration',
    PATH_ADMINISTRATION_SETTINGS: '/administration/settings',
    PATH_ADMINISTRATION_STATS: '/administration/stats',
    PATH_ADMINISTRATION_USER: '/administration/user',
    PATH_ADMINISTRATION_CARTOGRAPHY: '/administration/cartography',
    PATH_ADMINISTRATION_HOMEPAGE: '/administration/homepage',
    PATH_ADMINISTRATION_CONTENTS: '/administration/contents',
    PATH_TERRITORY: '/territories',
    PATH_TERRITORY_DETAIL: '/territories/territory',
    PATH_TERRITORY_MATRICE: '/territories/matrice',
    PATH_TERRITORY_SCENARIO: '/territories/scenario',
    PATH_NEW_TERRITORY: '/territories/new',
    PATH_ADMINISTRATION_PROBLEM_SOLUTION: '/administration/problemSolution',
    PATH_ALERT: '/alert',
    PATH_ALERT_CONFIGURATION: '/alert/config',
    PATH_GLOBAL_EVENTS: '/globalEvents',
    PATH_ARRESTS: '/arrests',
    PATH_DOCUMENTS: '/documents',
    PATH_SITEPLAN: '/sitePlan',
    PATH_ACCOUNT: '/account',
    SITUATION: 'situation',
    TRACKING: 'tracking',
    FOLLOWED: 'followed',
    REQUEST: 'request',
    ANALYSIS: 'analysis',
    STATIONS: 'stations',
    DEVELOP: 'develop',
    UPDATE: 'update',
    RESEARCH: 'research',
    SELECTION: 'selection',
    THRESHOLD: 'threshold',
    INDICATORS: 'indicators',
    GENERALOPTIONS: 'generalOptions',
    CONFORMITYOVERVIEW: 'conformityOverview',
    DESCRIPTION: 'description',
    OPERATION: 'operation',
    OPERATION_LINK: 'operation/',
    FOLLOWUPPC: 'followUpPC',
    SUIVIPC: 'suiviPC',
    SUIVIPCBIS: 'suiviPCBis',
    EVENT: 'event',
    EVENT_LINK: 'event/',
    PATH_EVENT: '/event',
    PATH_EVENT_LINK: '/event/',
    ASSOCIATED: 'associated',
    PICTURE: 'picture',
}
