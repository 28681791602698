/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
// import { isEqual, omit } from 'lodash'
import styled from 'styled-components'
// import { push } from 'react-router-redux'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { Divider } from '@material-ui/core'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import Button from '@material-ui/core/Button'
// import FormControl from '@material-ui/core/FormControl'
// import ToastrAction from '../../../utils/ToastrAction'
import { contentsPath } from '../../../conf/basepath'
import ActionComponent from '../../../components/actions/ActionComponent'
import ColorfulCard from '../../../components/card/ColorfulCard'
// import CustomizedSwitch from '../../../components/Switch'
import { whenReceiveProps } from '../../../utils/StoreUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getDate } from '../../../utils/DateUtil'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { getNewsColor } from '../../../utils/ColorUtil'
import MapEventPanel from './map/MapEventPanel'
import { lightGrey } from '../../../styles/theme'
import EventsAction from '../actions/EventsAction'
import ProgressBar from '../../../components/ProgressBar'
import UserAction from '../../administration/components/user/actions/UserAction'
import HomeAction from '../../home/actions/HomeAction'
import CMSEventDto from '../dto/CMSEventDto'

// const LabelWrapper = styled.span`
//     display: inline-block;
//     margin: 0.5rem;
// `

// const GridElement = styled(Grid)`
//     padding: 0px 8px !important;
// `

const ContentFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${lightGrey};
    padding: 0.2rem 1rem;
    border-bottom: 1px solid rgb(191, 191, 191);
`

class EventForm extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            event: {
                link: 'https://',
                idCategory: props.location.state
                    ? props.location.state.categoryId
                    : props.match.params.categoryId,
                eventId: props.location.state
                    ? props.location.state.contentId
                    : props.match.params.contentId,
                levelContent: null,
                status: 1,
                login: getLogin(),
                author: getUser().name || '',
                authorization: 'admin',
                x: -1.553621,
                y: 47.218371,
                cmsDocument: [],
            },
            selectedCategory: '',
            displayMode: true,
            dataLoaded: false,
            eventLoaded: {},
            checked: false,
        }
    }

    setTitle = () => {
        const { cmsCategories, cmsEvent: event, match } = this.props
        const { categoryId, contentId } = match.params
        const category = cmsCategories.find((c) => c.id === parseInt(categoryId))
        const name = category ? category.menu : i18n.communication
        this.props.setTitle([
            {
                title: name,
                href: `contents/${categoryId}`,
            },
            {
                title: this.state.event.eventId == 0 ? i18n.new : event.title || '',
                href: `contents/${categoryId}/${contentId}`,
            },
        ])
    }

    getInitialEvent = (props) => {
        const event = { ...props.cmsEvent }
        if (!event.link) {
            event.link = 'https://'
        }
        return event
    }

    // onDeleteEvent = () => {
    //     const eventToDelete = {
    //         ...this.state.event,
    //         id: this.state.event.eventId,
    //     }
    //     this.props.deleteCMSEvent(eventToDelete).then(() => {
    //         AppStore.dispatch(push(`/contents/${this.state.event.idCategory}`))
    //     })
    // }

    setEditMode = () => {
        this.setState({ displayMode: true })
        // const eventId = this.props.location.state
        //     ? this.props.location.state.contentId
        //     : this.props.match.params.contentId
        // const actions = {
        //     save: this.onSaveEvent,
        // }
        // if (eventId == 0) {
        //     actions.cancel = () => {
        //         AppStore.dispatch(push(`contents/${this.props.location.state.categoryId}`))
        //     }
        // }
        // if (eventId != 0) {
        //     actions.delete = () => {
        //         this.onDeleteEvent()
        //     }
        // }
        // if (getUser().consultant) {
        //     this.setActions(omit(actions, ['save', 'delete']))
        // } else {
        //     this.setActions(actions)
        // }
        this.setActions({})
    }

    setReadOnlyMode = () => {
        // if (
        //     (event.login === getLogin() && getUser().labo === '1')
        //     || (getUser().isAdmin === '1' && event.idCategory !== -1)
        // ) {
        //     this.setActions({
        //         deleteCheck: () => {
        //             this.onDeleteEvent()
        //         },
        //     })
        // } else if (AppStore.getState().HomeReducer.actions.filter((a) => !!a).length !== 0) {
        //     this.setActions({})
        // }
        this.setActions({})
        this.setState({ displayMode: true })
    }

    // onSaveEvent = () => {
    //     const eventId = this.props.location.state
    //         ? this.props.location.state.contentId
    //         : this.props.match.params.contentId
    //     if (!isEqual(this.state.event, this.state.initialEvent)) {
    //         const newEvent = {
    //             ...this.state.event,
    //             cmsDocument: [...this.state.event.document],
    //             author: this.state.event.author ? this.state.event.author : getUser().name,
    //             idCategory: parseInt(this.state.event.idCategory),
    //         }
    //         this.setState({ initialEvent: newEvent })
    //         if (newEvent.link === 'https://') {
    //             newEvent.link = ''
    //         }
    //         if (this.state.checked === false) {
    //             newEvent.x = null
    //             newEvent.y = null
    //         }
    //         this.props.updateCMSEvent(newEvent, eventId == 0)
    //         if (eventId == 0) {
    //             AppStore.dispatch(push(`/contents/${this.state.event.idCategory}`))
    //         } else {
    //             this.setReadOnlyMode(this.state.event)
    //         }
    //     } else {
    //         ToastrAction.info(i18n.nothingToChange)
    //     }
    // }

    componentDidMount() {
        const { match } = this.props
        this.props.fetchUsers()
        this.props.fetchCMSCategories()
        const actions = {
            //     save: this.onSaveEvent,
            //     cancel: () => {
            //         if (this.props.params.contentId == 0) {
            //             AppStore.dispatch(push(`contents/${this.state.event.idCategory}`))
            //         } else {
            //             this.setState({ event: this.state.event })
            //             this.setReadOnlyMode(this.state.event)
            //         }
            //     },
        }
        if (match.params.contentId) {
            this.props.fetchCMSEvent(match.params.contentId).then(() => {
                this.setTitle()
                this.setState({ dataLoaded: true })
            })
        }
        this.setActions(actions)
    }

    initializeComponent = (props) => {
        if (
            hasValue(this.state.event.eventId)
            && this.state.event.idCategory != 0
            && this.state.event.eventId != 0
        ) {
            this.props.fetchCMSEvent(this.state.event.eventId, () => {
                const eventLoad = new CMSEventDto(this.props.cmsEvent)
                this.setState({ dataLoaded: true, eventLoaded: eventLoad })
                this.setTitle(this.props.cmsEvent, this.props)
            })
            const event = this.getInitialEvent(props)
            this.setState({ event: event || {}, initialEvent: event || {} })
            if (props.cmsCategories.length) {
                this.setTitle(event, props)
            }
            this.setReadOnlyMode(event)
        }
        if (this.state.event.idCategory == 0) {
            if (props.cmsCategories.length) {
                this.setTitle({}, props)
            }
            this.setEditMode()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.state.event.eventId !== nextProps.match.params.contentId
            && nextProps.match.params.contentId != 0
            && !this.state.displayMode
        ) {
            const event = this.getInitialEvent(nextProps) || {}
            this.setState({
                event,
                initialEvent: event,
            })
            this.setReadOnlyMode(event)
            if (whenReceiveProps(this.props, nextProps, ['cmsEvent', 'cmsCategories'])) {
                this.setTitle(event, nextProps)
            }
        }
        if (this.state.event.eventId !== nextProps.match.params.contentId) {
            this.initializeComponent(nextProps)
        }
    }

    // onChangeEvent = (key, value) => {
    //     this.setState({
    //         event: {
    //             ...this.state.event,
    //             [key]: value,
    //         },
    //     })
    // }

    getEventCard = () => {
        const { cmsEvent: event } = this.props
        return (
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Card style={{ width: '80vw', margin: '2rem 4rem 0' }}>
                    <ColorfulCard color={getNewsColor(event.level)}>
                        <div className="row noMargin event-content">
                            <h4 className="noMargin padding-top-1">{event.title}</h4>
                            <h5
                                style={{ padding: '10px 0' }}
                                dangerouslySetInnerHTML={{ __html: event.subtitle }}
                            />
                            <p><div className="ignore-css" dangerouslySetInnerHTML={{ __html: event.comment || '' }} /></p>
                        </div>
                        <div className="row noMargin event-footer padding-left-2 padding-right-1">
                            <h6>{event.footer}</h6>
                            {event.footerContent}
                            {event.x && event.y ? (
                                <>
                                    <Divider />
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={3}>
                                            <p>Longitude : {event.x}</p>
                                            <p>Latitude : {event.y}</p>
                                        </Grid>
                                        <Grid item xs={9} style={{ height: '300px' }}>
                                            <MapEventPanel
                                                zoom={10}
                                                size={300}
                                                long={event.x}
                                                lat={event.y}
                                                consultMode
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : null}
                            {event.cmsDocument.length > 0 ? (
                                <span>Document(s)&nbsp;lié(s)&nbsp;:&nbsp;</span>
                            ) : (
                                ''
                            )}
                            {event.cmsDocument.map((d) => (
                                <button
                                    className="clickable"
                                    onClick={() => this.openDocument(d.name)}
                                >
                                    {d.name}
                                </button>
                            ))}
                            <h6 className="right">
                                <a className="blue-text" href={event.link} target="_blank" rel="noreferrer">
                                    {event.link !== 'https://' ? event.link : ''}
                                </a>
                            </h6>
                        </div>
                    </ColorfulCard>
                    <ContentFooter>
                        <i>
                            {`${i18n.writtenBy
                                + event.author
                            } ${i18n.atDate
                            } ${getDate(event.updateDate)
                            }, ${getDate(event.updateDate, 'HH:mm')}`}
                        </i>
                    </ContentFooter>
                </Card>
            </Grid>
        )
    }

    // eslint-disable-next-line class-methods-use-this
    openDocument(name) {
        const win = window.open(`${contentsPath}CMS/${name}`, '_blank')
        win.focus()
    }

    // onChangeSelect = (event) => {
    //     this.setState({ selectedCategory: event.target.value })
    //     this.setState({
    //         event: {
    //             ...this.state.event,
    //             idCategory: event.target.value,
    //         },
    //     })
    // }

    // onUploadFile = (event) => {
    //     // Read File
    //     const selectedFile = event.target.files
    //     // Check File is not Empty
    //     if (selectedFile.length > 0) {
    //         const cmsId = Number.parseInt(this.state.event.eventId)
    //         const path = ''
    //         const { name } = selectedFile[0]
    //         // Select the very first file from list
    //         const fileToLoad = selectedFile[0]
    //         // FileReader function for read the file.
    //         const fileReader = new FileReader()
    //         let content
    //         // Onload of file read the file content
    //         fileReader.onload = ((func) => (fileLoadedEvent) => {
    //             content = fileLoadedEvent.target.result
    //             func([{ cmsId, path, name, content }])
    //         })(this.onAddDocument)
    //         // Convert data to base64
    //         fileReader.readAsDataURL(fileToLoad)
    //     }
    // }

    // onAddDocument = (value) => {
    //     this.setState({
    //         event: {
    //             ...this.state.event,
    //             document: value,
    //         },
    //     })
    // }

    // useLocation = (event) => {
    //     this.setState({ checked: event.target.checked })
    // }

    // showDocumentName() {
    //     const { event } = this.state
    //     if (event.document && event.document.length > 0) {
    //         return event.document.map((d) => (
    //             <>
    //                 <GridElement item xs={1}>
    //                     <Button
    //                         borderRadius
    //                         variant="contained"
    //                         component="span"
    //                         onClick={() => this.onAddDocument([])}
    //                         style={{
    //                             display: 'flex',
    //                             justifyContent: 'flex-end',
    //                             width: '100%',
    //                             transform: 'translate(10px, 10px)',
    //                             backgroundColor: 'transparent',
    //                             border: 'none',
    //                             boxShadow: 'none',
    //                             color: 'black',
    //                         }}
    //                     >
    //                         X
    //                     </Button>
    //                 </GridElement>
    //                 <GridElement item xs={9} style={{ marginTop: '4px' }}>
    //                     <input className="sieau-input form-control" disabled value={d.name} />
    //                 </GridElement>
    //             </>
    //         ))
    //     }
    //     return (
    //         <GridElement item xs={10} style={{ marginTop: '4px' }}>
    //             <input className="sieau-input form-control" disabled />
    //         </GridElement>
    //     )
    // }

    // initAuthor = () => {
    //     const login = getLogin()
    //     this.onChangeEvent('author', login)
    //     return login
    // }

    // getEventForm = () => {
    //     const actions = {
    //         save: this.onSaveEvent,
    //         cancel: () => {
    //             if (eventId == 0) {
    //                 AppStore.dispatch(push(`/contents/${this.props.location.state.categoryId}`))
    //             } else {
    //                 this.setState({ event: this.state.initialEvent })
    //                 this.setReadOnlyMode()
    //             }
    //         },
    //     }
    //     this.setActions(actions)
    //     const statutOptions = [
    //         { value: 1, label: i18n.published },
    //         { value: 2, label: i18n.draft },
    //         { value: 3, label: i18n.archived },
    //     ]
    //     const visibilityOptions = [
    //         { value: 'admin', label: i18n.admins },
    //         { value: 'anim', label: `${i18n.admins}, ${i18n.animators}` },
    //         { value: 'all', label: `${i18n.admins}, ${i18n.animators}, ${i18n.consultants}` },
    //     ]
    //     const cmsCategories = this.props.cmsCategories.filter((f) => f.id !== -1)
    //     const { checked } = this.state
    //     return (
    //         <Card style={{ width: '80vw', margin: '2rem 10rem 0', padding: '10px 20px' }}>
    //             <Grid
    //                 container
    //                 direction="row"
    //                 justify="center"
    //                 alignItems="flex-start"
    //                 spacing={2}
    //             >
    //                 <GridElement item xs={6}>
    //                     <LabelWrapper style={{ width: '19%' }}>{i18n.author}&nbsp;:</LabelWrapper>
    //                     <input
    //                         className="sieau-input form-control"
    //                         value={event.author ? event.author : this.initAuthor()}
    //                         onChange={(v) => this.onChangeEvent('author', v.target.value)}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={6}>
    //                     <FormControl variant="outlined" style={{ width: '100%' }}>
    //                         <LabelWrapper htmlFor="categories">{i18n.category}&nbsp;:</LabelWrapper>
    //                         <Select
    //                             style={{ height: '48px' }}
    //                             value={
    //                                 this.state.selectedCategory ? this.state.selectedCategory : 2
    //                             }
    //                             onChange={this.onChangeSelect}
    //                             inputProps={{
    //                                 name: 'category',
    //                                 id: 'categories',
    //                             }}
    //                         >
    //                             {cmsCategories.map((category) => <MenuItem value={category.id}>{category.title}</MenuItem>)}
    //                         </Select>
    //                     </FormControl>
    //                 </GridElement>
    //                 <GridElement item xs={6}>
    //                     <LabelWrapper style={{ width: '14%' }}>{i18n.title}&nbsp;:</LabelWrapper>
    //                     <input
    //                         className="sieau-input form-control"
    //                         value={event.title}
    //                         onChange={(v) => this.onChangeEvent('title', v.target.value)}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={6}>
    //                     <FormControl variant="outlined" style={{ width: '100%' }}>
    //                         <LabelWrapper style={{ width: '45%' }}>
    //                             {i18n.visibleBy}&nbsp;:
    //                         </LabelWrapper>
    //                         <Select
    //                             style={{ height: '48px' }}
    //                             value={event.authorization ? event.authorization : 'admin'}
    //                             onChange={(v) => this.onChangeEvent('authorization', v.target.value)}
    //                         >
    //                             {visibilityOptions.map((item) => (
    //                                 <MenuItem value={item.value}>{item.label}</MenuItem>
    //                             ))}
    //                         </Select>
    //                     </FormControl>
    //                 </GridElement>
    //                 <GridElement item xs={6}>
    //                     <LabelWrapper style={{ width: '45%' }}>{i18n.subTitle}&nbsp;:</LabelWrapper>
    //                     <input
    //                         className="sieau-input form-control"
    //                         value={event.subtitle}
    //                         onChange={(v) => this.onChangeEvent('subtitle', v.target.value)}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={6}>
    //                     <FormControl variant="outlined" style={{ width: '100%' }}>
    //                         <LabelWrapper style={{ width: '45%' }}>
    //                             {i18n.status}&nbsp;:
    //                         </LabelWrapper>
    //                         <Select
    //                             style={{ height: '48px' }}
    //                             value={event.status ? event.status : 1}
    //                             onChange={(v) => this.onChangeEvent('status', v.target.value)}
    //                         >
    //                             {statutOptions.map((item) => (
    //                                 <MenuItem value={item.value}>{item.label}</MenuItem>
    //                             ))}
    //                         </Select>
    //                     </FormControl>
    //                 </GridElement>
    //                 <GridElement item xs={12}>
    //                     <LabelWrapper style={{ width: '45%' }}>{i18n.comment}&nbsp;:</LabelWrapper>
    //                     <textarea
    //                         name="content"
    //                         onChange={(v) => this.onChangeEvent('comment', v.target.value)}
    //                         value={event.comment}
    //                         placeholder="Contenu de l'article"
    //                         style={{ height: '20vh' }}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={12}>
    //                     <LabelWrapper style={{ width: '19%' }}>{i18n.link}&nbsp;:</LabelWrapper>
    //                     <input
    //                         className="sieau-input form-control"
    //                         value={event.link}
    //                         onChange={(v) => this.onChangeEvent('link', v.target.value)}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={12}>
    //                     <LabelWrapper>{i18n.footerTitle}&nbsp;:</LabelWrapper>
    //                     <input
    //                         className="sieau-input form-control"
    //                         value={event.footer}
    //                         onChange={(v) => this.onChangeEvent('footer', v.target.value)}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={12}>
    //                     <LabelWrapper>{i18n.footerContent}&nbsp;:</LabelWrapper>
    //                     <textarea
    //                         name="footerContent"
    //                         onChange={(v) => this.onChangeEvent('footerContent', v.target.value)}
    //                         value={event.footerContent}
    //                         placeholder="Contenu du pied de page"
    //                         style={{ height: '10vh' }}
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={2}>
    //                     <input
    //                         accept=".pdf,image/*"
    //                         id="uploadFile"
    //                         type="file"
    //                         onChange={this.onUploadFile}
    //                         style={{ display: 'none' }}
    //                     />
    //                     <label htmlFor="uploadFile">
    //                         <Button
    //                             borderRadius
    //                             variant="contained"
    //                             component="span"
    //                             style={{
    //                                 transform: 'translate(10px, 10px)',
    //                                 backgroundColor: '#29AE99',
    //                                 color: '#f9f8f6',
    //                                 width: '100%',
    //                             }}
    //                         >
    //                             {i18n.importLabel}
    //                         </Button>
    //                     </label>
    //                 </GridElement>
    //                 {this.showDocumentName()}
    //                 <GridElement
    //                     item
    //                     xs={12}
    //                     style={{ margin: '-3rem 1rem 0 1rem', transform: 'translate(3px, 52px)' }}
    //                 >
    //                     <CustomizedSwitch
    //                         checked={this.state.checked}
    //                         onChange={this.useLocation}
    //                         value="disabled"
    //                         color="primary"
    //                         label="Utiliser la localisation"
    //                     />
    //                 </GridElement>
    //                 <GridElement item xs={12} style={{ margin: '2rem 0' }}>
    //                     {checked ? (
    //                         <MapEventPanel
    //                             long={this.state.event.x}
    //                             lat={this.state.event.y}
    //                             changeLong={(v) => this.onChangeEvent('x', v)}
    //                             changeLat={(v) => this.onChangeEvent('y', v)}
    //                         />
    //                     ) : (
    //                         ''
    //                     )}
    //                 </GridElement>
    //             </Grid>
    //         </Card>
    //     )
    // }

    render() {
        const { dataLoaded } = this.state
        // if (displayMode) {
        if (dataLoaded) {
            return this.getEventCard()
        }
        return <ProgressBar />
        // }
        // return this.getEventForm(this.state.event, eventId)
    }
}

EventForm.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        contentId: PropTypes.number,
    }),
}

const mapStateToProps = (store) => ({
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsEvent: store.EventsReducer.cmsEvent,
    cmsCategories: store.EventsReducer.cmsCategories,
    document: store.EventsReducer.document,
    users: store.UserReducer.users,
})

const mapDispatchToProps = {
    fetchUsers: UserAction.fetchUsers,
    checkAdmin: UserAction.checkAdmin,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    fetchCMSEvent: EventsAction.fetchCMSEvent,
    fetchCMSDocument: EventsAction.fetchCMSDocument,
    updateCMSEvent: EventsAction.updateCMSEvent,
    deleteCMSEvent: EventsAction.deleteCMSEvent,
    saveCmsDocument: EventsAction.saveCmsDocument,
    setTitle: HomeAction.setTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventForm)
