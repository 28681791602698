import Grid from '@material-ui/core/Grid'
import React, { Component } from 'react'
import Recaptcha from 'react-recaptcha'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import ToastrAction from '../../utils/ToastrAction'
import { keyRecaptcha, mailAdmin } from '../../conf/AppConstants'
import HomeAction from './actions/HomeAction'
import HomeFooter from './components/Footer'
import HomeHeader from './components/Header'
import HomeSlide from './components/Slide'
import { MAIL_CONTENT, CONTACT_MAIL_SUBJECT } from './constants/HomeConstants'
import { Center } from '../../styles/main'
import { GreenButton } from '../../styles/button'

const CenterMargin = styled(Center)`
    margin-bottom: 30px !important;
`

class Contacts extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            disabled: true,
            loading: false,
            name: '',
            mail: '',
            content: '',
        }
        this.verifyCallback = this.verifyCallback.bind(this)
    }

    componentDidMount() {
        this.props.setHelpPath('faq')
    }

    onSendEmail = () => {
        const { name, mail, content } = this.state

        if (name && mail && content) {
            this.setState({ loading: true })
            const email = {
                to: mailAdmin,
                from: mailAdmin,
                subject: `${CONTACT_MAIL_SUBJECT} ${name} (${mail})`,
                message: `${MAIL_CONTENT + content}\n${mail}`,
            }
            this.props.sendMailToAdmin(email).then(() => {
                this.setState({ loading: false, name: '', mail: '', content: '' })
            })
        } else {
            ToastrAction.error(i18n.incompletForm)
        }
    }

    verifyCallback(recaptchaToken) {
        if (recaptchaToken) {
            this.setState({ disabled: false })
        }
    }

    onChangeName = (event) => {
        this.setState({ name: event.target.value })
    }

    onChangeMail = (event) => {
        this.setState({ mail: event.target.value })
    }

    onChangeContent = (event) => {
        this.setState({ content: event.target.value })
    }

    render() {
        const { disabled, loading, name, mail, content } = this.state
        return (
            <div>
                <div
                    className="row"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                        margin: '0',
                    }}
                >
                    <Grid container direction="row" justify="center" alignItems="center">
                        <HomeSlide />
                        <div className="home_content">
                            <div className="home_card">
                                <div>
                                    <HomeHeader page="contact" />
                                    <div
                                        className="row"
                                        style={{
                                            height: '75vh',
                                            overflowY: 'auto',
                                            padding: '0 20%',
                                        }}
                                    >
                                        <CenterMargin>
                                            <span className="title_home">{i18n.contactTitle}</span>
                                        </CenterMargin>
                                        <div className="row valign-wrapper o-margin">
                                            <i className="padding-glyph material-icons input_home">
                                                person
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="text"
                                                placeholder="Nom"
                                                onChange={(e) => this.onChangeName(e)}
                                                value={name}
                                            />
                                        </div>
                                        <div className="row valign-wrapper o-margin">
                                            <i className="padding-glyph material-icons input_home">
                                                email
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="email"
                                                onChange={(e) => this.onChangeMail(e)}
                                                value={mail}
                                                placeholder="Email"
                                            />
                                        </div>
                                        <div className="row valign-wrapper o-margin">
                                            <textarea
                                                name="content"
                                                onChange={(e) => this.onChangeContent(e)}
                                                value={content}
                                                placeholder="Votre message"
                                                style={{ height: '20vh', backgroundColor: 'white', borderRadius: 5, border: '1px solid lightgrey', padding: 10 }}
                                            />
                                        </div>
                                        <Grid direction="column" container alignItems="center">
                                            <Recaptcha
                                                render="onLoad"
                                                sitekey={keyRecaptcha}
                                                onloadCallback={this.onLoadRecaptcha}
                                                verifyCallback={this.verifyCallback}
                                                hl="fr"
                                            />
                                            {!loading ? (
                                                <GreenButton
                                                    id="submit"
                                                    onClick={this.onSendEmail}
                                                    disabled={disabled}
                                                >
                                                    Envoyer le message
                                                </GreenButton>
                                            ) : (
                                                <CircularProgress />
                                            )}
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            <HomeFooter />
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    sendMailToAdmin: HomeAction.sendMailToAdmin,
    setHelpPath: HomeAction.setHelpPath,
}

export default connect(null, mapDispatchToProps)(Contacts)
