import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { groupBy } from 'lodash'
import Icon from '@material-ui/core/Icon'
import Tabs from '@material-ui/core/Tabs'
import Card from '@material-ui/core/Card'
import { Grid } from '@material-ui/core'
import ProgressBar from '../../components/ProgressBar'
import { hasValue } from '../../utils/NumberUtil'
import Input from '../../components/forms/Input'
import { MainContainer } from '../../styles/container'
import AdministrationAction from '../administration/actions/AdministrationAction'
import { TabStyle } from '../../components/TabStyle'
import ActionComponent from '../../components/actions/ActionComponent'
import GlobalCardList from '../../components/list/cardList/GlobalCardList'
import HomeAction from '../home/actions/HomeAction'
import { getLabel } from '../../utils/StoreUtils'
import { getArticleFormat, getYearDate } from '../../utils/DateUtil'
import { getNewsColor } from '../../utils/ColorUtil'
import { getUser } from '../../utils/SettingUtils'
import { PATH_NEW_TERRITORY, PATH_TERRITORY_DETAIL } from '../home/constants/RouteConstants'
import { SpaceAround, Flex } from '../../styles/main'
import { lightGrey } from '../../styles/theme'
import TerritoryAction from './actions/TerritoryAction'
import UserAction from '../administration/components/user/actions/UserAction'

const InputWrapper = styled(Card)`
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding: 1rem 1rem;

    @media (max-width: 768px) {
        display: block;
        text-align: center;
    }
`

const IconFooter = styled(Icon)`
    font-size: 1.2rem !important;
`

const ContentFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${lightGrey};
    padding: 0.2rem 1rem;
    border-bottom: 1px solid rgb(191, 191, 191);
`

const FooterText = styled.div`
    font-style: italic;
    font-size: 0.8rem;
`

const ContentHeader = styled.div`
    display: flex;
`

const Title = styled.div`
    width: 85%;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 7px 2rem;
`

const Icons = styled(Flex)`
    width: 10%;
`

const Number = styled.div`
    padding: 10px;
    font-size: 1.2rem;
`

const IconsInRow = styled(Icon)`
    margin: 0.5rem 0 !important;
`

class TerritoriesApp extends ActionComponent {
    constructor(props) {
        super(props)

        const { content = {} } = props.selectedSearchValues
        const category = content && hasValue(content.category)
            ? parseInt(content.category)
            : parseInt(props.categoryId)
        const usedCategory = props.cmsCategories.find((c) => c.id === category) || category === -1
            ? category
            : parseInt(props.categoryId)
        const usedSortBy = content
            && hasValue(content.sortBy)
            && (content.sortBy !== 'category' || category === -1)
            ? content.sortBy
            : 'updateDate'
        if (!content || !hasValue(content.category)) {
            this.props.setSelectedSearchValues(usedCategory, usedSortBy)
        }
        this.state = {
            filter: {
                level: -1,
                statut: -1,
                searchValue: '',
            },
            tmpFilter: {
                level: -1,
                statut: -1,
                searchValue: '',
            },
            sortBy: usedSortBy,
            activeTabIndex: 'updateDate',
            menuId: this.props.location.state
                ? this.props.location.state.categoryId
                : this.props.match.params.categoryId,
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchUsers()
        this.props.fetchTerritoires().then(() => {
            this.setState({ dataLoaded: true })
        })
        const { filter, tmpFilter } = this.state
        this.setComponentActions()
        if (this.props.globalResearch) {
            this.setState({
                filter: {
                    ...filter,
                    searchValue: this.props.globalResearch,
                },
                tmpFilter: {
                    ...tmpFilter,
                    searchValue: this.props.globalResearch,
                },
            })
            this.props.updateGlobalResearch('')
        }
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
        ])
        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        const updateId = this.props.location.state
            ? this.props.location.state.categoryId
            : this.props.match.params.categoryId
        if (this.state.menuId !== updateId) {
            this.setState({ menuId: updateId })
        }
        this.setComponentActions()
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
        ])
    }

    componentWillReceiveProps(nextProps) {
        const { categoryId } = this.state
        if (categoryId !== nextProps.categoryId) {
            this.setState({
                category: nextProps.categoryId,
                sortBy: 'updateDate',
            })
        }
    }

    setComponentActions = () => {
        if (getUser().isAdmin === '1' || getUser().labo === '1') {
            this.setActions({
                new: this.redirectToContent(this.state.menuId, 0),
            })
        }
    }

    onChangeSortBy = (event, sortById) => {
        this.setState({ sortBy: sortById })
        this.props.setSelectedSearchValues({ sortBy: sortById })
    }

    redirectToContent = (categoryId, contentId) => () => {
        this.props.push({
            pathname: `${PATH_NEW_TERRITORY}`,
            state: {
                contentId,
                categoryId,
                menu: this.props.location.state ? this.props.location.state.menu : i18n.territory,
            },
        })
    }

    getStatutIcon = (statut) => {
        switch (statut) {
            case 0:
                return 'description'
            case 1:
                return 'checkCircle'
            case 2:
                return 'folderOpen'
            default:
                return 'checkCircle'
        }
    }

    getNumberUsers = (territory) => {
        if (getUser().labo === '1') {
            return territory.utilisateurs.length - 1
        }
        return territory.utilisateurs.length
    }

    getContentRow = (content) => {
        const dateCreation = getArticleFormat(content.dateCreation)
        const dateMaj = getArticleFormat(content.dateMaj)
        return (
            <div
                key={content.id}
                style={{ padding: '0 8px' }}
                onClick={() => this.props.push(`${PATH_TERRITORY_DETAIL}/${content.id}`)}
            >
                <ContentHeader>
                    <Title>{content.name}</Title>
                    <Icons>
                        {content.referencial ? (
                            <IconsInRow>bookmark</IconsInRow>
                        ) : (
                            <>
                                <IconsInRow>person</IconsInRow>
                                <Number>{this.getNumberUsers(content)}</Number>
                            </>
                        )}
                    </Icons>
                </ContentHeader>
                <ContentFooter>
                    <Grid container direction="row" justify="space-between">
                        <Grid item style={{ width: '96%' }}>
                            <FooterText>
                                {i18n.createdBy} {content.userMaj} {i18n.the} {dateCreation}{' '}
                                {dateCreation === dateMaj ? '' : `${i18n.andUpdatedAt} ${dateMaj}`}
                                &nbsp;
                            </FooterText>
                        </Grid>
                        <Grid item style={{ width: '2%' }}>
                            <IconFooter>{this.getStatutIcon(content.statut)}</IconFooter>
                        </Grid>
                    </Grid>
                </ContentFooter>
            </div>
        )
    }

    getSortByFunction = () => {
        const statusOptions = [
            { value: -1, label: i18n.all },
            { value: 0, label: i18n.draft },
            { value: 1, label: i18n.published },
            { value: 2, label: i18n.archived },
        ]
        switch (this.state.sortBy) {
            case 'updateDate':
                return (content) => getYearDate(content.dateCreation)
            case 'status':
                return (content) => getLabel(statusOptions, content.statut, null, 'value')
            case 'author':
                return (content) => getLabel(this.props.users, content.userMaj, 'login', 'login', 'Autres')
            default:
                return this.state.sortBy
        }
    }

    getResult = (filteredEvents) => {
        if (!filteredEvents.length) {
            const noContentLabels = [
                { code: 2, name: i18n.noNewsToDisplay },
                { code: 3, name: i18n.noLinksToDisplay },
                { code: 4, name: i18n.noDocumentsToDisplay },
                { code: 5, name: i18n.noContractsToDisplay },
            ]
            return (
                <h4 className="center-align padding-top-1 padding-bottom-1">
                    {getLabel(noContentLabels, this.state.filter.category)}
                </h4>
            )
        }
        filteredEvents.sort((a, b) => b.dateMaj - a.dateMaj)
        const groups = groupBy(filteredEvents, this.getSortByFunction())
        const data = Object.keys(groups).reduce((acc, key) => {
            const size = groups[key].length
            const label = size > 1 ? i18n.elements : i18n.element
            const cards = groups[key].map((e) => ({
                content: this.getContentRow(e),
                color: hasValue(e.level) ? getNewsColor(e.level) : 'null',
            }))
            return {
                ...acc,
                [key]: {
                    title: `${key} (${size} ${label})`,
                    cards,
                },
            }
        }, {})
        return <GlobalCardList style={{ padding: '8px 10px 10px' }} data={data} collapsible />
    }

    getFilteredTerritories = () => {
        const { territoires } = this.props
        const searchValue = this.state.tmpFilter.searchValue.toLowerCase()
        const user = getUser()
        return territoires.filter((e) => {
            if (
                user.isAdmin === '1'
                || user.labo === '1'
                || (user.consultant === '1' && e.statut === 1)
            ) {
                if (e.name.toLowerCase().includes(this.state.filter.searchValue)) {
                    return (
                        this.props.cmsEvents
                        && ['name', 'userMaj']
                            .map((key) => e[key])
                            .join('   ')
                            .toLowerCase()
                            .includes(searchValue)
                    )
                }
            }
        })
    }

    getTerritories = () => this.getResult(this.getFilteredTerritories())

    handleChange = (event) => {
        this.setState({ level: event.target.value })
    }

    getActive = (panel) => (panel === this.state.sortBy ? 'active' : '')

    onChangeFilter = (filter, value) => {
        const { tmpFilter } = this.state
        const newObj = {}
        newObj[filter] = value
        if (filter === 'category') {
            newObj.sortBy = 'updateDate'
        }
        this.setState({ tmpFilter: { ...tmpFilter, ...newObj } })
    }

    onChangeSearch = (key, value) => {
        const { tmpFilter } = this.state
        this.setState({ filter: { ...tmpFilter, [key]: value } })
    }

    handleChangeTab = (event, value) => {
        this.setState({ activeTabIndex: value })
        this.onChangeSortBy(event, value)
        this.getActive(value)
    }

    render() {
        const { activeTabIndex, dataLoaded } = this.state
        const { applicationSettings } = this.props
        
        const IconStyle = {
            position: 'absolute',
            left: '20px',
        }

        this.props.setHelpPath('', applicationSettings)
        return (
            <SpaceAround>
                <MainContainer>
                    <div style={{ width: '100%' }}>
                        <InputWrapper>
                            <Input
                                title={i18n.search}
                                onChange={(v) => {
                                    this.onChangeSearch('searchValue', v)
                                }}
                                style={{ width: '400px', height: '40px' }}
                            />
                        </InputWrapper>
                        <div style={{ margin: '0 0 2rem' }}>
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none',
                                    },
                                }}
                                style={{
                                    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
                                }}
                                variant="fullWidth"
                                value={activeTabIndex}
                                onChange={this.handleChangeTab}
                                aria-label="tabs"
                            >
                                <TabStyle
                                    value="updateDate"
                                    icon={<Icon style={IconStyle}>insert_invitation</Icon>}
                                    label={i18n.perYear}
                                />
                                <TabStyle
                                    value="author"
                                    icon={<Icon style={IconStyle}>person</Icon>}
                                    label={i18n.byAuthor}
                                />
                                <TabStyle
                                    value="status"
                                    icon={<Icon style={IconStyle}>edit</Icon>}
                                    label={i18n.byStatus}
                                />
                            </Tabs>
                            {dataLoaded ? this.getTerritories() : <ProgressBar />}
                        </div>
                    </div>
                </MainContainer>
            </SpaceAround>
        )
    }
}

TerritoriesApp.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        globalResearch: PropTypes.string,
    }),
}

const mapStateToProps = (store) => ({
    globalResearch: store.HomeReducer.globalResearch,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsEvent: store.EventsReducer.cmsEvent,
    cmsCategories: store.EventsReducer.cmsCategories,
    selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    territoires: store.TerritoryReducer.territoires,
    users: store.UserReducer.users,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    fetchTerritoires: TerritoryAction.fetchTerritoires,
    fetchUsers: UserAction.fetchUsers,
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritoriesApp)
