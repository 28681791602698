/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compact, merge, orderBy, min, max } from 'lodash'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Icon, MenuItem, Select } from '@material-ui/core'
import TerritoryAction from '../actions/TerritoryAction'
import HomeAction from '../../home/actions/HomeAction'
import ActionComponent from '../../../components/actions/ActionComponent'
import { getLocalStorageJson } from '../../../utils/FormUtils'
import { CCE_SCENARIOS_COMPARE } from '../constants/TerritoryConstants'
import { MainContainer } from '../../../styles/container'
import { Flex, SpaceAround } from '../../../styles/main'
import { mainBlue } from '../../../styles/theme'
import ProgressBar from '../../../components/ProgressBar'
import AgGridTable from '../../../components/datatable/AgGridTable'
import { getNameById, pourcentageFormatter } from '../../../utils/ArrayUtils'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import EchartBar from '../../../components/datatable/EchartBar'
import { round, formatFloat } from '../../../utils/NumberUtil'
import { GreenButton } from '../../../styles/button'
import ToastrAction from '../../../utils/ToastrAction'

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

const HeaderGraph = styled(Grid)`
    width: 100%;
    height: 58px;
    padding: 0 20px;
    color: white;
    background-color: ${mainBlue};
`

const HeaderTitle = styled.span`
    color: white;
    font-size: 1.5rem;
`

const IconButton = styled(Icon)`
    font-size: 2.5rem !important;
    background-color: black;
    width: 50px !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
`

const DldIcon = styled(Icon)`
    font-size: 1rem !important;
    display: inline-block;
    margin: 0 !important;
    color: #f9f8f6;
`

const DldIconText = styled.span`
    color: #f9f8f6;
    display: inline-block;
    transform: translate(-2px, -3px);
`

const tableStyle = {
    marginTop: 10,
    height: 480,
    width: '100%',
}

const selectEditMode = {
    height: 48,
    width: 'fit-content',
    marginRight: 5,
}

const emphasis = {
    itemStyle: {
        barBorderWidth: 1,
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: 'rgba(0,0,0,0.5)',
    },
}

const colors = ['red', 'green', 'blue', 'orange', 'violet', 'lightgreen', 'lightblue', 'darkviolet', 'yellow', 'brown']

class ScenariosCompareApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            scenariosIdsToCompare: getLocalStorageJson(CCE_SCENARIOS_COMPARE) || [],
            scenariosLoaded: false,
            territoryLoaded: false,
            scenarioReference: null,
            surfaceBy: 'modesConduites',
            tableSurface: false,
            chartLoaded: true,
            tableIndic: false,
            autoScaleIndic: true,
            checkboxIndics: [],
            tableProd: true,
            checkboxCultures: [],
            checkboxScenarios: [],
            autoScaleProd: true,
            chartProdLoaded: true,
            scenariosWithColor: [],
        }
    }

    componentDidMount() {
        const { match } = this.props
        const { scenariosIdsToCompare } = this.state
        this.props.fetchAllBricks().then(() => {
            this.props.fetchScenariosToCompare(scenariosIdsToCompare).then(() => {
                const { scenariosToCompare } = this.props
                const defaultScenario = scenariosToCompare.find((sc) => sc.scType === -1) || {}
                const checkboxScenarios = scenariosToCompare.map(({ id }) => id)
                const scenariosWithColor = orderBy(scenariosToCompare.map((sc, i) => ({
                    ...sc,
                    color: colors[i],
                })), ['reference', 'name'])
                this.setState({ scenariosLoaded: true, scenarioReference: defaultScenario.id, checkboxScenarios, scenariosWithColor })
            })
        })
        this.props.fetchMatrice(match.params.id).then(() => {
            const { matrice } = this.props
            this.props.fetchTerritory(matrice.tid).then(() => {
                const { territoire } = this.props
                const checkboxIndics = [...compact(matrice.indicateurs.map((i) => {
                    if (i.iid !== 1) {
                        return i.iid
                    }
                    return null
                })), 0]
                const checkboxCultures = [...compact(territoire.cultures.map((c) => c.dataId))]
                this.props.setTitle([
                    {
                        title: i18n.territories,
                        href: 'territories',
                    },
                    {
                        title: territoire.name,
                        href: `territories/territory/${territoire.id}`,
                    },
                    {
                        title: matrice.name,
                        href: `territories/matrice/${matrice.mid}`,
                    },
                    {
                        title: i18n.compareScenarios,
                        href: `territories/matrice/${matrice.mid}/scenariosCompare`,
                    },
                ])
                this.setState({ territoryLoaded: true, checkboxIndics, checkboxCultures })
            })
        })
    }

    getDataSurface = () => {
        const { cultures, conduites, territoire } = this.props
        const { scenarioReference, surfaceBy, scenariosWithColor } = this.state
        const keyValue = surfaceBy === 'modesConduites' ? 'mcid' : 'cid'

        const scenariosOrdered = orderBy(scenariosWithColor.map((sc) => {
            if (sc.id === scenarioReference) {
                return {
                    ...sc,
                    reference: true,
                }
            }
            return sc
        }), ['reference', 'name'])
        const legendsData = []
        const rowData = territoire[surfaceBy].map((d) => {
            const name = getNameById(surfaceBy === 'modesConduites' ? conduites : cultures, d.dataId)
            legendsData.push(name)
            const line = {
                [keyValue]: name,
                // echart conf
                name,
                type: 'bar',
                stack: 'total',
                label: { show: true },
                emphasis: { focus: 'series' },
            }
            let surfRef = 0
            const data = []
            const scValues = scenariosOrdered.map((sc) => {
                const surfaceTotal = sc.results.filter((r) => r.typeValeur === -4).map((r) => ({
                    ...r,
                    scid: sc.id,
                })).reduce((a, b) => a + (b.surface === -999 ? 0 : b.surface), 0)
                const surface = sc.results.filter((r) => r[keyValue] === d.dataId && r.typeValeur === -4).map((r) => ({
                    ...r,
                    scid: sc.id,
                })).reduce((a, b) => a + (b.surface === -999 ? 0 : b.surface), 0)
                const percent = round((surface / surfaceTotal) * 100, 2)
                data.push(percent || null)
                if (sc.id === scenarioReference) {
                    surfRef = surface
                }
                const keySurface = sc.reference ? 'sRef' : `surf_${sc.id}`
                const evol = sc.reference ? {} : {
                    [`evol_${sc.id}`]: surfRef === 0 ? '-' : `${Math.round(((surface - surfRef) / surfRef) * 100)}%`,
                }
                return {
                    [keySurface]: surface,
                    ...evol,
                }
            })
            return {
                ...line,
                ...merge(...[{}].concat(scValues)),
                // echart data
                data: data.reverse(),
            }
        })

        const yLabels = []
        const columns = compact(scenariosOrdered.flatMap((sc) => {
            if (!sc.reference) {
                yLabels.push(sc.name)
                return [
                    {
                        headerName: `${i18n.surface} ${sc.name}`,
                        field: `surf_${sc.id}`,
                    },
                    {
                        headerName: `${i18n.evolution} ${sc.name}`,
                        field: `evol_${sc.id}`,
                        valueFormatter: pourcentageFormatter,
                    },
                ]
            }
            yLabels.push(`${sc.name} (${i18n.reference})`)
            return null
        }))
        const firstCol = surfaceBy === 'modesConduites' ? {
            headerName: i18n.conduites,
            field: 'mcid',
        } : {
            headerName: i18n.cultures,
            field: 'cid',
        }
        const columnDefs = [
            firstCol,
            {
                headerName: i18n.surfaceRef,
                field: 'sRef',
            },
            ...orderBy(columns, 'field', 'desc'),
        ]
        return { rowData, columnDefs, legendsData, yLabels: yLabels.reverse() }
    }

    getEchartBarIndicsData = () => {
        const { indicateurs, territoire, matrice } = this.props
        const { checkboxIndics, scenarioReference, scenariosWithColor } = this.state
        const indicIds = []
        const indicsLabels = []
        const scenariosLabels = []

        const scenariosOrdered = orderBy(scenariosWithColor.map((sc) => {
            if (sc.id === scenarioReference) {
                return {
                    ...sc,
                    reference: true,
                }
            }
            return sc
        }), ['reference', 'name'])

        const map = []
        const table = []
        matrice.indicateurs.forEach((i) => {
            if (i.iid !== 1) {
                if (checkboxIndics.find((iid) => iid === i.iid)) {
                    if (!indicIds.includes(i.iid)) {
                        indicIds.push(i.iid)
                        indicsLabels.push(getNameById(indicateurs, i.iid))
                    }
                }
                let totalSurfaceRefValues = 0
                let totalMatriceRefValues = 0
                const line = {
                    iid: i.iid,
                    iName: getNameById(indicateurs, i.iid),
                }
                scenariosOrdered.forEach((sc) => {
                    let totalSurfaceOptiValues = 0
                    let totalMatriceOptiValues = 0
                    territoire.zones.forEach((z) => {
                        territoire.cultures.forEach((c) => {
                            territoire.modesConduites.forEach((mc) => {
                                let valueOpti = 0
                                let valueMatrice = 0
                                matrice.indicateursValues.forEach((m) => {
                                    if (
                                        m.zid === z.dataId
                                        && m.cid === c.dataId
                                        && m.mcid === mc.dataId
                                        && i.iid === m.iid
                                    ) {
                                        valueMatrice = m.value || 0
                                    }
                                })
                                const resultsOpti = sc.results.filter((r) => r.typeValeur === -4)
                                resultsOpti.forEach((rOpti) => {
                                    if (
                                        rOpti.zid === z.dataId
                                        && rOpti.cid === c.dataId
                                        && rOpti.mcid === mc.dataId
                                    ) {
                                        valueOpti += rOpti.surface === -999 ? 0 : rOpti.surface
                                    }
                                })
                                if (sc.reference) {
                                    totalSurfaceRefValues += valueOpti
                                    totalMatriceRefValues += valueOpti * valueMatrice
                                }
                                totalSurfaceOptiValues += valueOpti
                                totalMatriceOptiValues += valueOpti * valueMatrice
                            })
                        })
                    })
                    const evol = ((totalMatriceOptiValues - totalMatriceRefValues) / totalMatriceRefValues)
                        * 100
                    let vRef = 0
                    let vOpti = 0
                    if (i.iid === 1) {
                        vRef = totalMatriceRefValues
                        vOpti = totalMatriceOptiValues
                    } else {
                        if (totalSurfaceRefValues !== 0) {
                            vRef = totalMatriceRefValues / totalSurfaceRefValues
                        }
                        if (totalSurfaceOptiValues !== 0) {
                            vOpti = totalMatriceOptiValues / totalSurfaceOptiValues
                        }
                    }

                    if (checkboxIndics.find((iid) => iid === i.iid)) {
                        line[`value_${sc.reference ? 'Ref' : sc.id}`] = formatFloat(vOpti)
                        line[`evol_${sc.reference ? 'Ref' : sc.id}`] = formatFloat(evol, 0)
                        if (!sc.reference) {
                            map.push({
                                iid: i.iid,
                                iName: getNameById(indicateurs, i.iid),
                                scid: sc.id,
                                scName: sc.name,
                                vRef: formatFloat(vRef),
                                vOpti: formatFloat(vOpti),
                                evol: formatFloat(evol, 0),
                            })
                        }
                    }
                })
                table.push(line)
            }
        })

        const series = []
        map.forEach((v) => {
            const serie = series.find((s) => s.scid === v.scid)
            if (serie) {
                const newValues = [...serie.data, v.evol]
                serie.data = newValues
            } else {
                scenariosLabels.push(getNameById(scenariosWithColor, v.scid))
                series.push({
                    scid: v.scid,
                    name: getNameById(scenariosWithColor, v.scid),
                    type: 'bar',
                    color: (scenariosOrdered.find((sc) => sc.id === v.scid) || {}).color,
                    emphasis,
                    data: [v.evol],
                })
            }
        })
        const yLabels = []
        const columns = compact(scenariosOrdered.flatMap((sc) => {
            if (!sc.reference) {
                yLabels.push(sc.name)
                return [
                    {
                        headerName: sc.name,
                        field: `value_${sc.id}`,
                    },
                    {
                        headerName: `${i18n.evolution} ${sc.name}`,
                        field: `evol_${sc.id}`,
                        valueFormatter: pourcentageFormatter,
                    },
                ]
            }
            yLabels.push(`${sc.name} (${i18n.reference})`)
            return null
        }))
        const columnDefIndics = [
            {
                headerName: i18n.indicateursPerf,
                field: 'iName',
            },
            {
                headerName: i18n.scenarioRef,
                field: 'value_Ref',
            },
            ...orderBy(columns, 'field', 'desc'),
        ]
        const minValue = min(series.map((s) => s.data).flat())
        const maxValue = max(series.map((s) => s.data).flat())
        return [series, scenariosLabels, indicsLabels, table, ...this.getBornes(minValue, maxValue), columnDefIndics]
    }

    getEchartBarCultureData = () => {
        const { territoire, matrice, cultures } = this.props
        const { checkboxCultures, checkboxScenarios, scenarioReference, scenariosWithColor } = this.state
        const culturesIds = []
        const culturesLabels = []
        const scenariosLabels = []

        const scenariosOrdered = orderBy(scenariosWithColor.map((sc) => {
            if (sc.id === scenarioReference) {
                return {
                    ...sc,
                    reference: true,
                }
            }
            return sc
        }), ['reference', 'name'])

        const map = []
        let totalZoneMatriceRefValues = []
        scenariosOrdered.filter(({ id }) => checkboxScenarios.includes(id)).forEach((sc) => {
            territoire.cultures.filter(({ dataId }) => checkboxCultures.includes(dataId)).forEach((c) => {
                const cName = getNameById(cultures, c.dataId)
                if (!culturesIds.includes(c.dataId)) {
                    culturesIds.push(c.dataId)
                    culturesLabels.push(cName)
                }
                let totalZoneMatriceOptiValues = 0
                territoire.zones.forEach((z) => {
                    let totalMatriceRefValues = 0
                    let totalMatriceOptiValues = 0
                    territoire.modesConduites.forEach((mc) => {
                        // let valueRef = 0
                        let valueOpti = 0
                        let valueMatrice = 0
                        matrice.indicateursValues.forEach((m) => {
                            if (
                                m.cid === c.dataId
                                    && m.mcid === mc.dataId
                                    && m.zid === z.dataId
                                    && m.iid === 1
                            ) {
                                valueMatrice = m.value || 0
                            }
                        })
                        if (valueMatrice !== 0) {
                            // const resultsRef = sc.results.filter((r) => r.typeValeur === -3)
                            const resultsOpti = sc.results.filter((r) => r.typeValeur === -4)
                            /*
                            resultsRef.forEach((rRef) => {
                                if (
                                    rRef.cid === c.dataId
                                        && rRef.mcid === mc.dataId
                                        && rRef.zid === z.dataId
                                ) {
                                    valueRef += rRef.surface === -999 ? 0 : rRef.surface
                                }
                            })
                            */
                            resultsOpti.forEach((rOpti) => {
                                if (
                                    rOpti.cid === c.dataId
                                        && rOpti.mcid === mc.dataId
                                        && rOpti.zid === z.dataId
                                ) {
                                    valueOpti += rOpti.surface === -999 ? 0 : rOpti.surface
                                }
                            })
                        }
                        totalMatriceRefValues += valueOpti * valueMatrice
                        totalMatriceOptiValues += valueOpti * valueMatrice
                    })
                    if (sc.reference) {
                        const value = (totalZoneMatriceRefValues.find(({ cid }) => cid === c.dataId) || {}).value || 0
                        totalZoneMatriceRefValues = [
                            ...totalZoneMatriceRefValues.filter((d) => d.cid !== c.dataId),
                            { cid: c.dataId, value: totalMatriceRefValues + value },
                        ]
                    }
                    totalZoneMatriceOptiValues += totalMatriceOptiValues
                })
                const valueRefCulture = (totalZoneMatriceRefValues.find(({ cid }) => cid === c.dataId) || {}).value
                if (!sc.reference) {
                    const evol = ((totalZoneMatriceOptiValues - valueRefCulture) / valueRefCulture) * 100
                    map.push({
                        cid: c.dataId,
                        cName,
                        scid: sc.id,
                        scName: sc.name,
                        vRef: formatFloat(valueRefCulture),
                        vOpti: formatFloat(totalZoneMatriceOptiValues),
                        evol: formatFloat(evol, 0),
                    })
                }
            })
        })
        const series = []
        map.forEach((v) => {
            const serie = series.find((s) => s.scid === v.scid)
            if (serie) {
                serie.data = [...serie.data, v.evol]
            } else {
                const value = []
                scenariosLabels.push(getNameById(scenariosWithColor, v.scid))
                value.push(v.evol)
                series.push({
                    scid: v.scid,
                    name: v.scName,
                    type: 'bar',
                    color: (scenariosOrdered.find((sc) => sc.id === v.scid) || {}).color,
                    emphasis,
                    data: [...value],
                })
            }
        })
        const minValue = min(series.map((s) => s.data).flat())
        const maxValue = max(series.map((s) => s.data).flat())
        return [series, scenariosLabels, culturesLabels, map, ...this.getBornes(minValue, maxValue)]
    }

    getBornes = (minValue, maxValue) => {
        if (minValue >= 0 && minValue <= 100 && maxValue >= 0 && maxValue <= 100) {
            return [0, 100]
        }
        if (minValue >= -100 && minValue <= 0 && maxValue >= -100 && maxValue <= 0) {
            return [-100, 0]
        }
        if (minValue >= -100 && minValue <= 0 && maxValue >= 0 && maxValue <= 100) {
            return [-100, 100]
        }
        if (minValue >= 0 && maxValue >= 100) {
            return [0, null]
        }
        if (minValue <= 0 && maxValue <= -100) {
            return [null, 0]
        }
        if (minValue <= -100 && maxValue >= 100) {
            return [null, null]
        }
        return [null, null]
    }

    onChangeSelectBy = (value) => {
        this.setState({ chartLoaded: false, surfaceBy: value })
        setTimeout(() => {
            this.setState({ chartLoaded: true })
        }, 10)
    }

    onChangeReference = (value) => {
        this.setState({ chartLoaded: false, scenarioReference: value })
        setTimeout(() => {
            this.setState({ chartLoaded: true })
        }, 10)
    }

    onChangeCheckboxIndics = (indicateurId) => {
        const { checkboxIndics } = this.state
        const newIndics = (
            checkboxIndics.find((iid) => iid === indicateurId)
            || checkboxIndics.find((iid) => iid === indicateurId) === 0
        ) ? checkboxIndics.filter((iid) => iid !== indicateurId) : [...checkboxIndics, indicateurId]
        this.setState({ checkboxIndics: newIndics })
    }

    onChangeCheckboxCultures = (cultureId) => {
        const { checkboxCultures } = this.state
        const newCultures = checkboxCultures.find((cid) => cid === cultureId) ? checkboxCultures.filter((cid) => cid !== cultureId) : [...checkboxCultures, cultureId]
        this.setState({ checkboxCultures: newCultures })
    }

    onChangeCheckboxScenarios = (scenarioId) => {
        const { checkboxScenarios } = this.state
        const newScenarios = checkboxScenarios.find((scid) => scid === scenarioId) ? checkboxScenarios.filter((scid) => scid !== scenarioId) : [...checkboxScenarios, scenarioId]
        this.setState({ chartProdLoaded: false, checkboxScenarios: newScenarios })
        setTimeout(() => {
            this.setState({ chartProdLoaded: true })
        }, 10)
    }

    exportCompare = () => {
        const { scenarioReference, scenariosIdsToCompare } = this.state
        this.setState({ exportLoading: true })
        ToastrAction.info(i18n.loadingDocument)
        this.props.exportExcelScenariosComparison(scenarioReference, scenariosIdsToCompare).then(() => {
            this.setState({ exportLoading: false })
        })
    }

    getContent = () => {
        const { scenariosWithColor, scenarioReference, tableSurface, surfaceBy, chartLoaded,
            tableIndic, checkboxIndics, autoScaleIndic, exportLoading,
            tableProd, checkboxCultures, checkboxScenarios, autoScaleProd, chartProdLoaded } = this.state
        const { matrice, indicateurs, cultures, territoire } = this.props

        const { rowData, columnDefs, legendsData, yLabels } = this.getDataSurface()

        const datasBarIndics = this.getEchartBarIndicsData()
        const datasBarProd = this.getEchartBarCultureData()

        return (
            <div style={{ width: '100%', border: `solid ${mainBlue}`, backgroundColor: 'white', margin: '1rem 0 2rem' }}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                >
                    <div style={{ padding: '1rem', width: '100%', borderBottom: '1px solid lightgrey' }}>
                        <p className="bold">
                            {i18n.compareScenarios} : {scenariosWithColor.length} scénarios sélectionnés ({scenariosWithColor.reduce((a, b) => (a ? `${a}, ${b.name}` : `${b.name}`), '')})
                        </p>
                        <FormControl variant="outlined">
                            <Label htmlFor="status_type">{i18n.scenarioRef} :</Label>
                            <Select
                                style={{ height: 48, width: 250, marginRight: 5 }}
                                value={scenarioReference}
                                onChange={(e) => this.onChangeReference(e.target.value)}
                            >
                                {scenariosWithColor.map((sc) => <MenuItem value={sc.id}>{sc.name}</MenuItem>)}
                            </Select>
                        </FormControl><br />
                        {exportLoading ? <CircularProgress /> : (
                            <GreenButton borderRadius style={{ margin: '1rem 0' }} onClick={this.exportCompare} disabled={!scenarioReference}>
                                <DldIcon>get_app</DldIcon>&nbsp;&nbsp;
                                <DldIconText>{i18n.exportResults}</DldIconText>
                            </GreenButton>
                        )}
                    </div>
                </Grid>
                {scenarioReference ? (
                    <>
                        <Flex col style={{ margin: '1rem 1rem 60px' }}>
                            <HeaderGraph
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <HeaderTitle>{i18n.surfaces}</HeaderTitle>
                                <IconButton
                                    className="clickable"
                                    onClick={() => this.setState({ tableSurface: !tableSurface })}
                                >
                                    {tableSurface ? 'multiline_chart' : 'list'}
                                </IconButton>
                            </HeaderGraph>
                            <Grid item style={{ margin: '10px 0 0 10px' }}>
                                <FormControl variant="outlined">
                                    <Select
                                        name="sortBy"
                                        style={selectEditMode}
                                        value={surfaceBy}
                                        onChange={(e) => this.onChangeSelectBy(e.target.value)}
                                    >
                                        <MenuItem value="modesConduites">{i18n.byModeConduite}</MenuItem>
                                        <MenuItem value="cultures">{i18n.byCulture}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {tableSurface ? (
                                <div className="ag-theme-balham" style={tableStyle}>
                                    <AgGridTable
                                        rowData={rowData}
                                        columnDefs={columnDefs}
                                        exportable={false}
                                    />
                                </div>
                            ) : (
                                <>
                                    {chartLoaded ? (
                                        <EchartBar
                                            data={rowData}
                                            legendsData={legendsData}
                                            yLabels={yLabels}
                                            fileTitle={`${i18n.compareScenarios} - ${i18n.surfaces}`}
                                            legendParams={{
                                                orient: 'horizontal',
                                                top: 20,
                                                left: 0,
                                            }}
                                            xName="%"
                                            min={0}
                                            max={100}
                                            hideSymbolTooltip
                                            showLabelValues
                                        />
                                    ) : (
                                        <CircularProgress style={{ height: '500px' }} />
                                    )}
                                </>
                            )}
                        </Flex>
                        <Flex col style={{ margin: '1rem 1rem 60px' }}>
                            <HeaderGraph
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <HeaderTitle>{i18n.evolIndics}</HeaderTitle>
                                <IconButton
                                    className="clickable"
                                    onClick={() => this.setState({ tableIndic: !tableIndic })}
                                >
                                    {tableIndic ? 'multiline_chart' : 'list'}
                                </IconButton>
                            </HeaderGraph>
                            <Grid container direction="row" justify="center">
                                {matrice.indicateurs.map((i) => {
                                    if (i.iid !== 1) {
                                        const find = checkboxIndics.find(
                                            (iid) => iid === i.iid,
                                        )
                                        return (
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={find > 0} />
                                                    }
                                                    onChange={() => this.onChangeCheckboxIndics(i.iid)}
                                                    label={getNameById(indicateurs, i.iid)}
                                                />
                                            </Grid>
                                        )
                                    }
                                    return null
                                })}
                            </Grid>
                            {checkboxIndics.length 
                                ? tableIndic ? (
                                    <div className="ag-theme-balham" style={tableStyle}>
                                        <AgGridTable
                                            columnDefs={datasBarIndics[6]}
                                            rowData={datasBarIndics[3]}
                                            exportable={false}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <EchartBar
                                            data={datasBarIndics[0]}
                                            legendsData={datasBarIndics[1]}
                                            yLabels={datasBarIndics[2]}
                                            fileTitle={`${i18n.compareScenarios} - ${i18n.evolIndics}`}
                                            min={autoScaleIndic ? null : datasBarIndics[4]}
                                            max={autoScaleIndic ? null : datasBarIndics[5]}
                                        />
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={autoScaleIndic} />
                                                }
                                                onChange={() => this.setState({ autoScaleIndic: !autoScaleIndic })}
                                                label="Echelle automatique"
                                            />
                                        </Grid>
                                    </>
                                )
                                : (
                                    <span>{i18n.noIndicateurSelected}</span>
                                )}
                        </Flex>
                        <Flex col style={{ margin: '1rem 1rem 60px' }}>
                            <HeaderGraph
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                <HeaderTitle>{i18n.evolProdCulture}</HeaderTitle>
                                <IconButton
                                    className="clickable"
                                    onClick={() => this.setState({ tableProd: !tableProd })}
                                >
                                    {tableProd ? 'multiline_chart' : 'list'}
                                </IconButton>
                            </HeaderGraph>
                            <Grid container direction="row" justify="center">
                                {territoire.cultures.map((c) => (
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={checkboxCultures.find((cid) => cid === c.dataId)} />
                                            }
                                            onChange={() => this.onChangeCheckboxCultures(c.dataId)}
                                            label={getNameById(cultures, c.dataId)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container direction="row" justify="center" style={{ borderTop: '1px solid lightgrey' }}>
                                {scenariosWithColor.filter((sc) => sc.id !== scenarioReference).map((sc) => (
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={checkboxScenarios.find((scid) => scid === sc.id)} style={{ color: sc.color }} />
                                            }
                                            onChange={() => this.onChangeCheckboxScenarios(sc.id)}
                                            label={sc.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {checkboxCultures.length 
                                ? tableProd ? (
                                    <div className="ag-theme-balham" style={tableStyle}>
                                        <AgGridTable
                                            columnDefs={[
                                                {
                                                    headerName: i18n.culture,
                                                    field: 'cName',
                                                },
                                                {
                                                    headerName: i18n.scenario,
                                                    field: 'scName',
                                                },
                                                {
                                                    headerName: i18n.valueRef,
                                                    field: 'vRef',
                                                },
                                                {
                                                    headerName: i18n.valueOpti,
                                                    field: 'vOpti',
                                                },
                                                {
                                                    headerName: i18n.evolution,
                                                    field: 'evol',
                                                    valueFormatter: pourcentageFormatter,
                                                },
                                            ]}
                                            rowData={datasBarProd[3]}
                                            exportable={false}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {chartProdLoaded ? (
                                            <EchartBar
                                                data={datasBarProd[0]}
                                                legendsData={[]}
                                                yLabels={datasBarProd[2]}
                                                fileTitle={`${i18n.compareScenarios} - ${i18n.evolIndics}`}
                                                min={autoScaleProd ? null : datasBarProd[4]}
                                                max={autoScaleProd ? null : datasBarProd[5]}
                                            />
                                        ) : (
                                            <CircularProgress style={{ height: '500px' }} />
                                        )}
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={autoScaleProd} />
                                                }
                                                onChange={() => this.setState({ autoScaleProd: !autoScaleProd })}
                                                label="Echelle automatique"
                                            />
                                        </Grid>
                                    </>
                                )
                                : (
                                    <span>{i18n.noCultureSelected}</span>
                                )}
                        </Flex>
                    </>
                ) : null}
            </div>
        )
    }

    render() {
        const { scenariosLoaded, territoryLoaded } = this.state

        return (
            <SpaceAround>
                <MainContainer>
                    {scenariosLoaded && territoryLoaded ? this.getContent() : <ProgressBar />}
                </MainContainer>
            </SpaceAround>
        )
    }
}

ScenariosCompareApp.propTypes = {
    getLink: PropTypes.func,
    globalResearch: PropTypes.string,
    fetchScenariosToCompare: PropTypes.func,
    exportExcelScenariosComparison: PropTypes.func,
}

const mapStateToProps = (store) => ({
    territoires: store.TerritoryReducer.territoires,
    territoire: store.TerritoryReducer.territoire,
    matrice: store.TerritoryReducer.matrice,
    scenariosToCompare: store.TerritoryReducer.scenariosToCompare,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    indicateurs: store.ReferencialReducer.indicateurs,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchMatrice: TerritoryAction.fetchMatrice,
    fetchTerritory: TerritoryAction.fetchTerritory,
    fetchScenariosToCompare: TerritoryAction.fetchScenariosToCompare,
    exportExcelScenariosComparison: TerritoryAction.exportExcelScenariosComparison,
    fetchAllBricks: ReferencialAction.fetchAllBricks,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosCompareApp)
