import DtoReferencial from '../../../dto/DtoReferencial'

export default class CultureDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.labelWithCode = `${object.name} [${object.id}]`
        this.name = object.name
        this.teteRotation = object.teteRotation
        this.legumineuse = object.legumineuse
        this.periodeSemence = object.periodeSemence
        this.referentiel = object.referentiel
        this.headers = ['code', 'name']
        this.status = object.status // Option[Int] = None,
        this.idFamille = object.idFamille // Option[Int] = None,
        this.limitCropRotations = object.limitCropRotations // Option[Boolean] = None,
        this.usermaj = object.usermaj // Option[String] = None,
        this.datemaj = object.datemaj // Option[DateTime] = None

        this.linkCulturesRPG = object.linkCulturesRPG || [] // Option[Seq[String]] = None
    }
}
