import {
    SUBSCRIBE_SERVER_EVENT,
    SUBSCRIBE_SERVER_EVENTS,
    UNSUBSCRIBE_SERVER_EVENT,
    UNSUBSCRIBE_SERVER_EVENTS,
    START_EVENT_SOURCE_CONNECTION,
    START_EVENTS_SUBSCRIPTION,
    CLOSE_EVENT_SOURCE_CONNECTION,
} from '../constants/SSEConstants'
import ChannelDto from '../dto/ChannelDto'

const { GnsEventSource } = window

export const SSEStore = {
    channels: [],
}

export const SSEReducer = (state = SSEStore, action) => {
    switch (action.type) {
        case SUBSCRIBE_SERVER_EVENT:
            const newChannel = new ChannelDto(action.event)
            GnsEventSource.addEvent(newChannel.type, newChannel.action)
            return {
                ...state,
                channels: [...state.channels, newChannel],
            }
        case SUBSCRIBE_SERVER_EVENTS:
            const newChannels = action.events.map((e) => {
                GnsEventSource.addEvent(e.type, e.action)
                return new ChannelDto(e)
            })
            return {
                ...state,
                channels: [...state.channels, ...newChannels],
            }
        case UNSUBSCRIBE_SERVER_EVENT:
            const channels = state.channels.filter((e) => {
                const keepEvent = e.type !== action.event.type && e.action !== action.event.action
                if (!keepEvent) {
                    GnsEventSource.removeEvent(e.type, e.action)
                }
                return keepEvent
            })
            return {
                ...state,
                channels,
            }
        case UNSUBSCRIBE_SERVER_EVENTS:
            const unSubscribeChannels = state.channels.filter((e) => {
                const keepEvent = e.type !== action.event.type
                if (!keepEvent) {
                    GnsEventSource.removeEvent(e.type, e.action)
                }
                return keepEvent
            })
            return {
                ...state,
                channels: unSubscribeChannels,
            }
        case START_EVENT_SOURCE_CONNECTION:
            GnsEventSource.start(action.url, action.onConnect)
            return { ...state }
        case START_EVENTS_SUBSCRIPTION:
            state.channels.forEach((e) => {
                GnsEventSource.addEvent(e.type, e.action)
            })
            return { ...state }
        case CLOSE_EVENT_SOURCE_CONNECTION:
            GnsEventSource.stop()
            return {
                ...state,
                channels: [],
            }
        default:
            return state
    }
}
