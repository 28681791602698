import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys } from 'lodash'
import { push } from 'react-router-redux'
import { Checkbox } from 'react-aquasys'
import AppStore from '../../store/Appstore'
import { hasValue } from '../../utils/NumberUtil'

export default class TableBody extends Component {
    state = {
        editableColumn: -1,
        width: 0,
    }

    getDefaultProps() {
        return {
            data: {},
            editable: false,
            condensed: false,
            checked: false,
            duplicable: false,
            onDuplicate: () => {},
            alterable: false,
            onAlter: () => {},
        }
    }

    onClick(idx) {
        this.setState({ editableColumn: idx, width: this.refs[`col-${idx}`].offsetWidth })
    }

    goToLocation(link) {
        AppStore.dispatch(push(link))
    }

    onLink(id) {
        if (this.props.subLink) {
            this.goToLocation(`/${this.props.link}/${id}/${this.props.subLink}`)
        } else {
            this.goToLocation(`/${this.props.link}/${id}`)
        }
    }

    onBlur() {
        this.props.data[keys(this.props.data)[this.state.editableColumn]] = this.refs.inputRef.value
        this.setState({ editableColumn: -1 })
    }

    getValue() {
        return this.refs.checkbox.getValue()
    }

    render() {
        const rows = this.props.headers.map((val, idx) => {
            if (val === 'check') {
                if (this.props.condensed) {
                    return (
                        <td className="table-body-condensed column-checkbox">
                            <Checkbox checked={this.props.checked} condensed={this.props.condensed} ref="checkbox" />
                        </td>
                    )
                }
                return (
                    <td className="column-checkbox">
                        <Checkbox checked={this.props.checked} ref="checkbox" />
                    </td>
                )
            }
            if (val === 'del' && this.props.active) {
                const className = (() => {
                    const resultClass = 'small material-icons clickable'
                    if (!this.props.active) {
                        return `${resultClass} i-disabled`
                    }
                    return resultClass
                })()
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                return (
                    <td className={`${condensed} column-checkbox`}>
                        <i
                            className={className}
                            onClick={() => (this.props.active ? this.props.onDelete(this.props.data) : '')}
                        >close
                        </i>
                    </td>
                )
            }
            if (val === 'alt' && this.props.active) {
                const className = (() => {
                    const resultClass = 'small material-icons clickable'
                    if (!this.props.active) {
                        return `${resultClass} i-disabled`
                    }
                    return resultClass
                })()
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                return (
                    <td className={`${condensed} column-checkbox`}>
                        <i
                            className={className}
                            onClick={() => (this.props.active ? this.props.onAlter(this.props.data) : '')}
                        >create
                        </i>
                    </td>
                )
            }
            if (val === 'dupl' && this.props.active) {
                const className = (() => {
                    const resultClass = 'small material-icons clickable'
                    if (!this.props.active) {
                        return `${resultClass} i-disabled`
                    }
                    return resultClass
                })()
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                return (
                    <td className={`${condensed} column-checkbox`}>
                        <i
                            className={className}
                            onClick={() => (this.props.active ? this.props.onDuplicate(this.props.data) : '')}
                        >add_to_photos
                        </i>
                    </td>
                )
            }
            if (idx === this.state.editableColumn) {
                return (
                    <td key={idx} className="card col" style={{ position: 'absolute', width: this.state.width }}>
                        <input
                            autoFocus
                            type="text"
                            ref="inputRef"
                            onBlur={this.onBlur}
                            defaultValue={this.props.data[val]}
                        />
                    </td>
                )
            }

            // Editable
            const editable = (() => {
                if (this.props.editable) {
                    return () => this.onClick(idx)
                }
                return ''
            })()

            if (val && this.props.data[val]) {
                const clickFunc = (this.props.data[val] && this.props.data[val].onClick) ? this.props.data[val].onClick : null
                const clickable = clickFunc ? 'clickable ' : ''

                if (this.props.data[val].color) {
                    const addedClass = hasValue(this.props.data[val]) ? this.props.data[val].className || '' : ''
                    const tooltip = hasValue(this.props.data[val]) ? this.props.data[val].tooltip || '' : ''
                    const color = this.props.data[val].color && this.props.data[val].color !== 'white' ? this.props.data[val].color : ''
                    const textColor = this.props.data[val].textColor ? `${this.props.data[val].textColor}-text` : ''
                    if (this.props.condensed) {
                        return (
                            <td
                                key={idx}
                                ref={`col-${idx}`}
                                onClick={clickFunc}
                                data-tooltip={tooltip}
                                className={`${textColor} table-body-condensed truncate-body-table ${clickable}${color} ${addedClass}`}
                            ><span
                                    className="truncate"
                            >{ this.props.data[val].value }
                             </span>
                            </td>
                        )
                    }
                    return (
                        <td
                            key={idx}
                            ref={`col-${idx}`}
                            onClick={clickFunc}
                            data-tooltip={tooltip}
                            className={`${textColor} truncate-body-table ${clickable}${color} ${addedClass}`}
                        ><span
                                className="truncate"
                        >{ this.props.data[val].value }
                         </span>
                        </td>
                    )
                }
                if (this.props.condensed) {
                    return (
                        <td
                            key={idx}
                            ref={`col-${idx}`}
                            onClick={editable}
                            className={`table-body-condensed ${clickable}`}
                        > { this.props.data[val] }
                        </td>
                    )
                }
                return (
                    <td
                        key={idx}
                        ref={`col-${idx}`}
                        onClick={editable}
                        className={clickable}
                    > { this.props.data[val] }
                    </td>
                )
            }
            return (<td key={idx} ref={`col-${idx}`} onClick={editable}> </td>)
        })

        // Linkable
        const link = (() => {
            if (this.props.link) {
                return () => this.onLink(this.props.data.id)
            } if (this.props.data.link && !this.props.active) {
                return () => this.goToLocation(this.props.data.link)
            } if (this.props.onClick) {
                return () => this.props.onClick(this.props.data)
            }
            return ''
        })()
        const className = (() => {
            if (link) {
                return 'bottomMargin10 clickable'
            }
            return 'bottomMargin10'
        })()

        return (
            <tr
                className={className}
                onClick={link}
                onMouseOver={() => this.props.onLineOver(this.props.data)}
                onMouseOut={() => this.props.onLineOut(this.props.data)}
            >
                { rows }
            </tr>
        )
    }
}

TableBody.propTypes = {
    data: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
    onDelete: PropTypes.func,
    duplicable: PropTypes.bool,
    onDuplicate: PropTypes.func,
    alterable: PropTypes.bool,
    onAlter: PropTypes.func,
    link: PropTypes.string,
    subLink: PropTypes.string,
    onClick: PropTypes.func,
    condensed: PropTypes.bool,
    checkable: PropTypes.bool,
    checked: PropTypes.bool,
    onLineOver: PropTypes.func,
    onLineOut: PropTypes.func,
    headers: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.bool,
}
