import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys } from 'lodash'
import { push } from 'react-router-redux'
import { Checkbox } from 'react-aquasys'
import UUID from 'uuid'
import AppStore from '../../store/Appstore'
import { hasValue } from '../../utils/NumberUtil'

export default class TableBodyColor extends Component {
    state = {
        editableColumn: -1,
        width: 0,
    }

    getDefaultProps() {
        return {
            data: {},
            editable: false,
            condensed: false,
        }
    }

    getValue() {
        return this.refs.checkbox.getValue()
    }

    onClick(idx) {
        this.setState({ editableColumn: idx, width: this.refs[`col-${idx}`].offsetWidth })
    }

    onLink(id) {
        if (this.props.subLink) {
            AppStore.dispatch(push(`/${this.props.link}/${id}/${this.props.subLink}`))
        } else {
            AppStore.dispatch(push(`/${this.props.link}/${id}`))
        }
    }

    onBlur() {
        this.props.data[keys(this.props.data)[this.state.editableColumn]] = this.refs.inputRef.value
        this.setState({ editableColumn: -1 })
    }

    getCheck(properties) {
        const props = (() => {
            if (properties.value) {
                return properties.value.split(',')
            }
            return properties
        })()

        if (this.props.checkable) {
            return [].concat(['check'], props)
        }
        return props
    }

    render() {
        const rows = this.props.headers.map((val, idx) => {
            const idUUid = UUID.v4()
            if (val === 'check') {
                if (this.props.condensed) {
                    return (
                        <td className="table-body-condensed column-checkbox">
                            <Checkbox checked={this.props.checked} condensed={this.props.condensed} ref="checkbox" />
                        </td>
                    )
                }
                return (
                    <td className="column-checkbox">
                        <Checkbox checked={this.props.checked} ref="checkbox" />
                    </td>
                )
            }
            if (idx === this.state.editableColumn) {
                return (
                    <td key={idx} className="card col" style={{ position: 'absolute', width: this.state.width }}>
                        <input
                            autoFocus
                            type="text"
                            ref="inputRef"
                            onBlur={this.onBlur}
                            defaultValue={this.props.data[val]}
                        />
                    </td>
                )
            }

            const clickFunc = (this.props.data[val] && this.props.data[val].onClick) ? this.props.data[val].onClick : null
            const clickable = clickFunc ? 'clickable ' : ''

            const addedClass = hasValue(this.props.data[val]) ? this.props.data[val].className || '' : ''
            const tooltip = hasValue(this.props.data[val]) ? this.props.data[val].tooltip || '' : ''
            if (val && this.props.data[val] && this.props.data[val].color) {
                const color = this.props.data[val].color && this.props.data[val].color !== 'white' ? this.props.data[val].color : ''
                const textColor = this.props.data[val].textColor ? `${this.props.data[val].textColor}-text` : ''
                if (this.props.condensed) {
                    return (
                        <td
                            key={idUUid}
                            ref={`col-${idUUid}`}
                            onClick={clickFunc}
                            data-tooltip={tooltip}
                            className={`${textColor} table-body-condensed truncate-body-table ${clickable}${color} ${addedClass}`}
                        ><span
                                className="truncate"
                        >{ this.props.data[val].value }
                         </span>
                        </td>
                    )
                }
                return (
                    <td
                        key={idUUid}
                        ref={`col-${idUUid}`}
                        onClick={clickFunc}
                        data-tooltip={tooltip}
                        className={`${textColor} truncate-body-table ${clickable}${color} ${addedClass}`}
                    ><span
                            className="truncate"
                    >{ this.props.data[val].value }
                     </span>
                    </td>
                )
            }
            if (this.props.condensed) {
                return (
                    <td
                        key={idUUid}
                        ref={`col-${idUUid}`}
                        onClick={clickFunc}
                        data-tooltip={tooltip}
                        className={`table-body-condensed truncate-body-table ${clickable}${addedClass}`}
                    ><span
                            className="truncate"
                    >{ this.props.data[val] && this.props.data[val].value ? this.props.data[val].value : '' }
                     </span>
                    </td>
                )
            }
            return (
                <td
                    key={idUUid}
                    ref={`col-${idUUid}`}
                    onClick={clickFunc}
                    data-tooltip={tooltip}
                    className={`truncate-body-table ${clickable}${addedClass}`}
                ><span className="truncate">
                    { this.props.data[val] && this.props.data[val].value ? this.props.data[val].value : '' }
                 </span>
                </td>
            )
        })

        // Linkable
        const link = (() => {
            if (this.props.link) {
                return () => this.onLink(this.props.data.id)
            } if (this.props.onClick) {
                return () => this.props.onClick(this.props.data)
            }
            return ''
        })()

        const className = (() => {
            if (link) {
                return 'bottomMargin10 clickable'
            }
            return 'bottomMargin10'
        })()
        return (
            <tr
                className={className}
                onClick={link}
                id={this.props.bodyId}
            >
                { rows }
            </tr>
        )
    }
}

TableBodyColor.propTypes = {
    data: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    link: PropTypes.string,
    subLink: PropTypes.string,
    condensed: PropTypes.bool,
    checkable: PropTypes.bool,
    checked: PropTypes.bool,
    headers: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    bodyId: PropTypes.number,
}
