import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import { WaitAction } from 'react-aquasys'
import i18n from 'simple-react-i18n'
import { RECEIVE_ALL_CONTACTS, RECEIVE_CONTACT, RESET_CONTACT } from '../constants/ContactConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import AppStore from '../../../../../store/Appstore'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../../../utils/ToastrAction'
import {
    PATH_REFERENCIAL_CONTACT,
    PATH_REFERENCIAL_CONTACT_LINK,
} from '../../../../home/constants/RouteConstants'

const ContactAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().ContactReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveContacts(contacts) {
        return { type: RECEIVE_ALL_CONTACTS, contacts }
    },

    receiveContact(contact) {
        return { type: RECEIVE_CONTACT, contact }
    },

    updateContact(contact) {
        return (dispatch) => fetch(ApplicationConf.referencial.saveContact(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(contact),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1) {
                    dispatch(ContactAction.fetchContact(contact.code))
                    ToastrAction.success(i18n.contactSuccessUpdated)
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.contact} : ${err}`)
            })
    },

    createContact(contact) {
        return (dispatch) => fetch(ApplicationConf.referencial.contacts(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(contact),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.insert) {
                    dispatch(push(PATH_REFERENCIAL_CONTACT_LINK + json.id))
                    ToastrAction.success(i18n.successfulCreation)
                }
            })
            .catch((err) => {
                console.error(`${i18n.createError + i18n.contact} : ${err}`)
                ToastrAction.error(i18n.createError + i18n.contact)
            })
    },

    resetContact() {
        return { type: RESET_CONTACT }
    },

    fetchContact(code) {
        return (dispatch) => fetch(ApplicationConf.referencial.contact(code), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(ContactAction.receiveContact(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.contact} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.contact)
            })
    },

    promiseContacts() {
        return fetch(ApplicationConf.referencial.contacts(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchContacts() {
        return (dispatch) => ContactAction.promiseContacts()
            .then((json = []) => {
                dispatch(ContactAction.receiveContacts(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.contacts} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.contacts)
            })
    },

    deleteContact(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteContact(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                        dispatch(push(PATH_REFERENCIAL_CONTACT))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.deleteError + i18n.contact} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.contact)
                })
        }
    },

    purgeContact() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeContact(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`)
                        dispatch(ContactAction.fetchContacts())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.deleteError + i18n.contacts} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.contacts)
                })
        }
    },

    replaceContact(contact, contactReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceContact(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    id: contact,
                    replaceId: contactReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update) {
                        ToastrAction.success(i18n.contactSuccessUpdated)
                    } else if (json.update === 0) {
                        ToastrAction.success(`${json.update} ${i18n.replacement}`)
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.replace + i18n.contact} : ${err}`)
                })
        }
    },
}

export default ContactAction
