module.exports = {
    RECEIVE_ZONES_COUNT: 'RECEIVE_ZONES_COUNT',
    RECEIVE_CULTURES_COUNT: 'RECEIVE_CULTURES_COUNT',
    RECEIVE_RPG_COUNT: 'RECEIVE_RPG_COUNT',
    RECEIVE_CONDUITES_COUNT: 'RECEIVE_CONDUITES_COUNT',
    RECEIVE_COMMUNES_COUNT: 'RECEIVE_COMMUNES_COUNT',
    RECEIVE_INDICATEURS_COUNT: 'RECEIVE_INDICATEURS_COUNT',
    RECEIVE_ROTATIONS_COUNT: 'RECEIVE_ROTATIONS_COUNT',
    RECEIVE_MATRICE_REF_COUNT: 'RECEIVE_MATRICE_REF_COUNT',
}
