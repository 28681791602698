import { find, isEqual, remove } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import { CircularProgress } from '@material-ui/core'
import UserAction from '../../administration/components/user/actions/UserAction'
import {
    BASIC_HOMEPAGE_VIEWS,
    PANEL_STATISTICS,
    PANEL_USERINFO,
} from '../../administration/components/user/constants/UserConstants'
import DtoUser from '../../administration/components/user/dto/DtoUser'
import KeyFigureDto from '../../administration/components/user/dto/KeyFigureDto'
import UserViewDto from '../../administration/components/user/dto/UserViewDto'
import ActionComponent from '../../../components/actions/ActionComponent'
import { TabStyle } from '../../../components/TabStyle'
import KeyFigurePanel from '../../../components/keyFigures/KeyFigurePanel'
import HomeAction from '../../home/actions/HomeAction'
import UpdatePanel from '../../../components/UpdatePanel'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import DtoContributor from '../../referencial/components/contributor/dto/DtoContributor'
import { getProfileCard, getUser } from '../../../utils/SettingUtils'
import AccountAction from '../actions/AccountAction'
import DtoAccountHabilitation from '../dto/DtoAccountHabilitation'
import DtoAccountSettings from '../dto/DtoAccountSettings'
import UpdateUserForm from './form/UpdateUserForm'
import UserStatisticPanel from '../../administration/components/user/components/detailPanel/UserStatisticPanel'
import LastCGUPanel from '../../../components/LastCGUPanel'
import ProgressBar from '../../../components/ProgressBar'
import { mainBlue } from '../../../styles/theme'

class AccountUserApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            view: PANEL_USERINFO,
            settings: [],
            contact: {},
            userView: this.props.userView || [],
            dataloaded: false,
            editMode: false,
            contactLoaded: true,
        }
    }

    componentDidMount() {
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN')).then(() => {
            this.setState({ dataloaded: true })
        })
        this.props.fetchUserView()
        this.props.fetchApplicationHabilitations()
        this.props.setTitle([
            {
                title: i18n.myAccount,
                href: 'account',
            },
        ])
        this.initState()
    }

    componentWillReceiveProps(nextProps) {
        if (
            (nextProps.contacts.length || !isEqual(nextProps.contacts, this.props.contacts))
            && nextProps.accountUser.contactCode
            && !this.state.contact.code
        ) {
            this.initState(nextProps)
        }
        if (!isEqual(nextProps.userView, this.props.userView)) {
            this.setState({ userView: nextProps.userView })
        }
    }

    toggleEditMode = (bool) => {
        this.setState({ editMode: bool })
    }

    initState = (props = this.props) => {
        const findContact = find(props.contacts, (o) => o.code == props.accountUser.contactCode)
        if (findContact) {
            this.setState({
                contact: findContact,
            })
        }
    }

    changeContactValue = (key, value) => {
        const contact = {
            ...this.state.contact,
            [key]: value,
        }
        this.setState({ contact })
    }

    changeSettings = (sttngs) => {
        const tmp = [...this.getAccountSettings()]
        const filterSettings = remove(
            tmp,
            (o) => !find(sttngs, (sett) => o.parameter === sett.parameter),
        )
        const settings = [...filterSettings, ...sttngs].map(
            (o) => new DtoAccountSettings({
                ...o,
                login: this.props.accountUser.login,
            }),
        )
        this.setState({ settings })
    }

    changeSetting = (setting) => {
        const tmp = [...this.getAccountSettings()]
        const filterSettings = tmp.filter((o) => o.parameter !== setting.parameter)
        const settings = [...filterSettings, setting].map(
            (o) => new DtoAccountSettings({
                ...o,
                login: this.props.accountUser.login,
            }),
        )
        this.setState({ settings })
    }

    changeHomePageView = (view, changes) => {
        const newView = new UserViewDto({ ...view, ...changes })
        const userView = this.state.userView.filter((v) => v.panel != newView.panel)
        this.setState({ userView: [...userView, newView] })
    }

    resetView = () => {
        this.setState({ userView: BASIC_HOMEPAGE_VIEWS })
    }

    getUpdatePanel = () => {
        const { contact } = this.state
        if (contact.usermaj || contact.datemaj) {
            return <UpdatePanel updateLogin={contact.usermaj} updateDate={contact.datemaj} />
        }
        return null
    }

    changeState = (view) => {
        this.setState({ view })
    }

    getAccountSettings = () => {
        if (this.state.settings.length) {
            return this.state.settings
        }
        return this.props.accountUserSettings
    }

    toggleLoad = (bool) => {
        this.setState({ contactLoaded: bool })
    }

    getPanel = () => {
        const { view, contact, editMode } = this.state
        switch (view) {
            case PANEL_USERINFO:
                return (
                    <UpdateUserForm
                        editMode={editMode}
                        toggleEditMode={this.toggleEditMode}
                        toggleLoad={this.toggleLoad}
                        contact={contact}
                        changeContactValue={this.changeContactValue}
                        style={{ paddingTop: '10px' }}
                    />
                )
            case PANEL_STATISTICS:
                return <UserStatisticPanel account />
            default:
                return (
                    <UpdateUserForm
                        editMode={editMode}
                        toggleEditMode={this.toggleEditMode}
                        toggleLoad={this.toggleLoad}
                        contact={contact}
                        changeContactValue={this.changeContactValue}
                        style={{ paddingTop: '10px' }}
                    />
                )
        }
    }

    getCGUPanel = () => <LastCGUPanel login={this.props.accountUser.login} />

    handleChange = (event, newView) => {
        if (!this.state.editMode) {
            this.setState({ view: newView })
        }
    }

    render() {
        const { dataloaded, editMode, contactLoaded } = this.state
        return (
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-start"
                className="noPadding marginTop"
            >
                <Grid className="noPadding marginTop" item md={2}>
                    <Card
                        style={{
                            height: '90%',
                            backgroundColor: '#bbb',
                            color: 'white',
                            boxShadow: '2px 2px 5px grey',
                        }}
                    >
                        {getProfileCard(false, this.props, getUser())}
                    </Card>
                    {this.getCGUPanel()}
                </Grid>
                <Grid item md={7} sm={11}>
                    <div className="cardStyle marginTop">
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ padding: '0' }}>
                                <Tabs
                                    TabIndicatorProps={{
                                        style: {
                                            display: 'none',
                                        },
                                    }}
                                    style={{
                                        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
                                    }}
                                    value={this.state.view}
                                    variant="fullWidth"
                                    onChange={this.handleChange}
                                >
                                    <TabStyle label={i18n.informations} value={PANEL_USERINFO} />
                                    <TabStyle
                                        label={i18n.statistics}
                                        value={PANEL_STATISTICS}
                                        style={editMode ? { backgroundColor: '#F0F0F0' } : {}}
                                    />
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} style={{ border: `${mainBlue} solid` }}>
                                {dataloaded ? this.getPanel() : <ProgressBar />}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={2} className="noMargin">
                    {contactLoaded ? this.getUpdatePanel() : <CircularProgress />}
                    <KeyFigurePanel
                        data={this.props.userKeyFigures}
                        title="Statistiques"
                        icon="donut_large"
                        singleLine
                    />
                    {/* <AccountAccessApplicationChartPanel/> */}
                </Grid>
            </Grid>
        )
    }
}

AccountUserApp.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountUserKeyFigures: PropTypes.arrayOf(PropTypes.instanceOf(KeyFigureDto)),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributor)),
    userView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    user: PropTypes.instanceOf(DtoUser),
    userKeyFigures: PropTypes.arrayOf(PropTypes.instanceOf(KeyFigureDto)),
}
const mapStateToProps = (store) => ({
    contacts: store.ContactReducer.contacts,
    accountUser: store.AccountReducer.accountUser,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    accountUserKeyFigures: store.AccountReducer.accountUserKeyFigures,
    accountUserSettings: store.AccountReducer.accountUserSettings,
    contributors: store.ContributorReducer.contributors,
    userView: store.UserReducer.userView,
    user: store.AccountReducer.accountUser,
    userKeyFigures: store.AccountReducer.accountUserKeyFigures,
})

const mapDistachToProps = {
    setTitle: HomeAction.setTitle,
    fetchUserView: UserAction.fetchUserView,
    fetchApplicationHabilitations: UserAction.fetchApplicationHabilitations,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDistachToProps)(AccountUserApp)
