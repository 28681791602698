import DtoReferencial from '../../../dto/DtoReferencial'

export default class ContactDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = Number(object.id)
        this.title = object.title
        this.socialReason = object.socialReason
        this.address = object.address
        this.cityCode = object.cityCode
        this.cedexCode = object.cedexCode
        this.phoneTel = object.phoneTel
        this.phoneTelSecond = object.phoneTelSecond
        this.fax = object.fax
        this.desktopTel = object.desktopTel
        this.mobile = object.mobile
        this.email = object.email
        this.bankCode = object.bankCode
        this.comment = object.comment
        this.postalBox = object.postalBox
        this.datemaj = object.datemaj
    }
}
