import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../../../utils/ToastrAction'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import { getUser } from '../../../../../utils/SettingUtils'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import ProgressBar from '../../../../../components/ProgressBar'
import { MainContainer, RefTable, Position } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import UserViewDto from '../../../../administration/components/user/dto/UserViewDto'
import UpdatePanel from '../../../../../components/UpdatePanel'
import DtoUser from '../../../../administration/components/user/dto/DtoUser'
import AccountAction from '../../../../account/actions/AccountAction'
import ModalWarning from '../../ModalWarning'
import ModalWarningPurge from '../../ModalWarningPurge'
import { getNameById } from '../../../../../utils/ArrayUtils'
import ModalAddReferencial from '../../ModalAddReferencial'
import { getFullDateMini } from '../../../../../utils/DateUtil'

const seasonList = [
    { value: 0, label: 'Pluriannuelle' },
    { value: 1, label: 'Printemps' },
    { value: 2, label: 'Automne' },
]

class CulturesApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            columnDefs: [],
            openWarningModal: false,
            openPurgeModal: false,
            openAddModal: false,
            elementsUsed: [],
            rowData: [],
            initialData: [],
            editable: false,
            culturesIdSelected: [],
            valuesChanged: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.cultures,
                href: 'referencial/cultures',
            },
        ])
        this.props.fetchCultures().then(() => {
            this.setData(this.props.cultures)
            this.setState({ initialData: this.props.cultures })
        })
        this.setReadOnlyMode()
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
    }

    setEditable(editable) {
        this.setState({
            columnDefs: [
                {
                    headerName: i18n.code,
                    field: 'id',
                    headerCheckboxSelection: editable,
                    headerCheckboxSelectionFilteredOnly: editable,
                    checkboxSelection: editable,
                },
                {
                    headerName: i18n.name,
                    field: 'name',
                },
                {
                    headerName: i18n.teteRotation,
                    field: 'teteRotation',
                    cellRenderer: (params) => `<input disabled type='checkbox' ${
                        params.value ? 'checked' : ''
                    } value='${params.value}' />`,
                },
                {
                    headerName: i18n.legumineuse,
                    field: 'legumineuse',
                    cellRenderer: (params) => `<input disabled type='checkbox' ${
                        params.value ? 'checked' : ''
                    } value='${params.value}' />`,
                },
                {
                    headerName: i18n.periodeSemence,
                    field: 'periodeSemence',
                },
                {
                    headerName: i18n.referencial,
                    field: 'referentiel',
                    cellRenderer: (params) => {
                        const input = document.createElement('input')
                        input.disabled = !editable
                        input.type = 'checkbox'
                        const valueFind = this.state.valuesChanged.find(
                            (value) => value.id === params.data.id,
                        )
                        input.checked = valueFind ? valueFind.referentiel : params.value
                        input.addEventListener('click', () => {
                            if (!this.state.valuesChanged.find((z) => z.id === params.data.id)) {
                                const newData = {
                                    ...params.data,
                                    referentiel: !params.value,
                                }
                                const newState = [...this.state.valuesChanged, newData]
                                this.setState({ valuesChanged: newState })
                            } else {
                                const newState = this.state.valuesChanged.filter(
                                    (z) => z.id !== params.data.id,
                                )
                                this.setState({ valuesChanged: newState })
                            }
                        })
                        return input
                    },
                },
                {
                    headerName: 'RPG',
                    field: 'rpg',
                },
                {
                    headerName: i18n.user,
                    field: 'usermaj',
                },
                {
                    headerName: i18n.modificationDate,
                    field: 'datemaj',
                },
            ],
            editable,
        })
    }

    getUpdatePanel = () => {
        const culturesWithDatemaj = this.props.cultures.filter((c) => c.datemaj)
        if (culturesWithDatemaj.length) {
            const lastUpdate = orderBy(culturesWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    setEditMode = () => {
        this.setEditable(true)
        const actions = {
            cancel: () => {
                this.setState({ dataLoaded: false, culturesIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchCultures()
                    .then(() => this.setData(this.props.cultures))
                    .then(() => this.setReadOnlyMode())
            },
        }
        if (getUser().isAdmin === '1') {
            actions.new = () => {
                this.modalAddIsOpen()
            }
            actions.save = () => {
                this.state.valuesChanged.forEach((c) => {
                    const newCulture = {
                        ...c,
                        periodeSemence: this.getPeriodeId(c.periodeSemence),
                        datemaj: null,
                    }
                    this.props.updateCulture(c.id, { ...newCulture, usermaj: getUser().login })
                })
                this.setState({ dataLoaded: false, culturesIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchCultures()
                    .then(() => this.setData(this.props.cultures))
                    .then(() => this.setReadOnlyMode())
            }
            actions.deleteCheck = () => {
                this.props.deleteCultures(this.state.culturesIdSelected).then((json = {}) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                        this.setState({
                            dataLoaded: false,
                            culturesIdSelected: [],
                            valuesChanged: [],
                        })
                        this.props
                            .fetchCultures()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.cultures))
                    } else if (json.used) {
                        const elementsUsedWithName = json.used.map((element) => ({
                            id: element,
                            name: getNameById(this.props.cultures, element),
                        }))
                        this.setState({
                            dataLoaded: false,
                            culturesIdSelected: [],
                            valuesChanged: [],
                        })
                        this.props
                            .fetchCultures()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.cultures))
                        this.setState({ elementsUsed: elementsUsedWithName })
                        this.modalWarningIsOpen()
                    }
                })
            }
            actions.purge = () => {
                this.modalPurgeIsOpen()
            }
        }
        this.setActions(actions)
    }

    purgeCultures = () => {
        this.setState({
            dataLoaded: false,
            culturesIdSelected: [],
            valuesChanged: [],
        })
        this.props.purgeCultures().then(() => {
            this.props.fetchCultures().then(() => this.setData(this.props.cultures))
        })
    }

    setReadOnlyMode = () => {
        this.setEditable(false)
        if (getUser().isAdmin === '1') {
            this.setActions({
                edit: () => this.setEditMode(),
                export: () => this.props.exportExcelCultures(),
            })
        } else {
            this.setActions({})
        }
    }

    setData(cultures) {
        const arr = cultures.map((culture) => ({
            ...culture,
            id: culture.id,
            name: culture.name,
            teteRotation: culture.teteRotation,
            legumineuse: culture.legumineuse,
            referentiel: culture.referentiel,
            periodeSemence: this.getPeriodeName(culture.periodeSemence),
            rpg: culture.linkCulturesRPG.join(', '),
            usermaj: culture.usermaj,
            datemaj: getFullDateMini(culture.datemaj),
        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    getPeriodeName(id) {
        const parent = seasonList.find((p) => p.value === id)
        if (parent) {
            return parent.label
        }
        return ''
    }

    getPeriodeId(name) {
        const parent = seasonList.find((p) => p.label === name)
        if (parent) {
            return parent.value
        }
        return null
    }

    onSelectCultures = (key, data) => {
        this.setState({ culturesIdSelected: data })
    }

    modalAddIsOpen = () => {
        this.setState({ openAddModal: true })
    }

    modalAddIsClose = () => {
        this.setState({ dataLoaded: false })
        this.props.fetchCultures().then(() => this.setData(this.props.cultures))
        this.setState({ openAddModal: false })
    }

    modalWarningIsOpen = () => {
        this.setState({ openWarningModal: true })
    }

    modalWarningIsClose = () => {
        this.setState({ openWarningModal: false })
    }

    modalPurgeIsOpen = () => {
        this.setState({ openPurgeModal: true })
    }

    modalPurgeIsClose = () => {
        this.setState({ openPurgeModal: false })
    }

    render() {
        const {
            dataLoaded,
            openWarningModal,
            openPurgeModal,
            openAddModal,
            elementsUsed,
            columnDefs,
            rowData,
        } = this.state
        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <RefTable className="ag-theme-balham">
                            <div style={{ width: '100%', margin: '3rem' }}>
                                <Position>{this.getUpdatePanel()}</Position>
                            </div>
                            <AgGridTable
                                columnDefs={columnDefs}
                                rowData={rowData}
                                onChange={this.onSelectCultures}
                            />
                        </RefTable>
                    ) : (
                        <ProgressBar />
                    )}
                    {openAddModal && (
                        <ModalAddReferencial
                            culture
                            handleClose={this.modalAddIsClose}
                            open={openAddModal}
                        />
                    )}
                    {openWarningModal && (
                        <ModalWarning
                            handleClose={this.modalWarningIsClose}
                            open={openWarningModal}
                            elementsUsed={elementsUsed}
                        />
                    )}
                    {openPurgeModal && (
                        <ModalWarningPurge
                            handleClose={this.modalPurgeIsClose}
                            open={openPurgeModal}
                            purge={this.purgeCultures}
                        />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

CulturesApp.propTypes = {
    getLink: PropTypes.func,
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    accountUser: PropTypes.instanceOf(DtoUser),
}

const mapStateToProps = (store) => ({
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    cultures: store.ReferencialReducer.cultures,
    accountUser: store.AccountReducer.accountUser,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCultures: ReferencialAction.fetchCultures,
    updateCulture: ReferencialAction.updateCulture,
    createCulture: ReferencialAction.createCulture,
    exportExcelCultures: ReferencialAction.exportExcelCultures,
    deleteCultures: ReferencialAction.deleteCultures,
    purgeCultures: ReferencialAction.purgeCultures,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(CulturesApp)
