import { push } from 'react-router-redux'
import { WaitAction } from 'react-aquasys'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../../utils/ActionUtils'
import {
    RECEIVE_USER,
    RECEIVE_USER_CMS_STATISTICS,
    RECEIVE_USER_HABILITATIONS,
    RECEIVE_USER_KEYFIGURES,
    RECEIVE_USER_KEYFIGURES_ALL,
    RECEIVE_USER_LOGIN_HABILITATIONS,
    RECEIVE_USER_STATION_STATISTICS,
    RECEIVE_USER_STATIONS,
    RECEIVE_USER_STATISTIC,
    RECEIVE_USER_THEMES_ASSIGNMENT,
    RECEIVE_USER_USAGE_STATISTICS,
    RECEIVE_USERS,
    RECEIVE_USERS_THEMES_ASSIGNMENT,
    RESET_USER,
    RESET_USER_ATTEMPTS,
    RECEIVE_USER_VIEW,
    RECEIVE_GENERIC_USER_VIEW,
} from '../constants/UserConstants'
import AppStore from '../../../../../store/Appstore'
import ToastrAction from '../../../../../utils/ToastrAction'
import SieauAction from '../../../../../components/sieau/SieauAction'
import { RECEIVE_ALL_APPLICATION_HABILITATION } from '../constants/ApplicationHabilitationConstants'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'

const UserAction = {
    receiveUsers(users) {
        return { type: RECEIVE_USERS, users }
    },

    promiseUsers() {
        return fetch(ApplicationConf.user.userPath(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchUsers() {
        return (dispatch) => UserAction.promiseUsers().then((json) => {
            dispatch(UserAction.receiveUsers(json))
        })
    },

    checkAdmin(isAdmin) {
        return (dispatch) => {
            if (isAdmin !== '1') {
                dispatch(push('/'))
            }
        }
    },

    receiveUser(user) {
        return { type: RECEIVE_USER, user }
    },

    fetchUser(login) {
        return (dispatch) => fetch(ApplicationConf.user.get(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUser(json))
                dispatch(UserAction.fetchUserStatistics(json.login))
                dispatch(UserAction.fetchUserKeyfigure(json.login))
                dispatch(UserAction.fetchUserStationStatistic(json.login))
                dispatch(UserAction.fetchUserCmsStatistic(json.login))
                dispatch(UserAction.fetchUserUsageStatistic(json.login))
                dispatch(UserAction.fetchUserStations(json.login))
                dispatch(UserAction.fetchUserHabilitations(json.login))
            })
    },
    resetUserAttempts() {
        return { type: RESET_USER_ATTEMPTS }
    },
    receiveUserKeyfigures(userKeyFigures) {
        return { type: RECEIVE_USER_KEYFIGURES, userKeyFigures }
    },

    fetchUserKeyfigure(login) {
        return (dispatch) => fetch(ApplicationConf.user.getKeyFigures(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserKeyfigures(json))
            })
    },
    receiveUserStatistics(userStatistics) {
        return { type: RECEIVE_USER_STATISTIC, userStatistics }
    },

    fetchUserStatistics(login) {
        return (dispatch) => fetch(ApplicationConf.user.getStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserStatistics(json))
            })
    },
    receiveUserStationStatistic(userStationStatistics) {
        return {
            type: RECEIVE_USER_STATION_STATISTICS,
            userStationStatistics,
        }
    },

    fetchUserStationStatistic(login) {
        return (dispatch) => fetch(ApplicationConf.user.getStationStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserStationStatistic(json))
            })
    },
    receiveUserUsageStatistic(userUsageStatistics) {
        return { type: RECEIVE_USER_USAGE_STATISTICS, userUsageStatistics }
    },

    fetchUserCmsStatistic(login) {
        return (dispatch) => fetch(ApplicationConf.user.getCmsStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserCmsStatistic(json))
            })
    },
    receiveUserCmsStatistic(userCmsStatistics) {
        return { type: RECEIVE_USER_CMS_STATISTICS, userCmsStatistics }
    },

    fetchUserUsageStatistic(login) {
        return (dispatch) => fetch(ApplicationConf.user.getUsageStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserUsageStatistic(json))
            })
    },
    receiveUserStations(userStations) {
        return { type: RECEIVE_USER_STATIONS, userStations }
    },
    fetchUserStations(login) {
        return (dispatch) => fetch(ApplicationConf.user.userStations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserStations(json))
            })
    },
    updateUserStations(login, userStations) {
        return (dispatch) => fetch(ApplicationConf.user.userStations(login), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(userStations),
        })
            .then(checkAuth)
            .then(getJson)
            .then(() => {
                dispatch(UserAction.fetchUserStations(login))
            })
    },
    updateUser(user) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.put(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(user),
            }).then(() => {
                dispatch(UserAction.fetchUser(user.login))
                dispatch(ContactAction.fetchContacts())
                dispatch(WaitAction.waitStop())
                ToastrAction.success(i18n.saveSuccess)
            })
        }
    },

    receiveUserHabilitation(userHabilitations) {
        return { type: RECEIVE_USER_HABILITATIONS, userHabilitations }
    },
    fetchUserHabilitations(login) {
        return (dispatch) => fetch(ApplicationConf.user.habilitations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserHabilitation(json))
            })
            .then(() => {
                dispatch(WaitAction.waitStop())
            })
    },
    updateUserHabilitations(login, userHabilitations) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.habilitations(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(userHabilitations),
            })
                .then(checkAuth)
                .then(getJson)
                .then(() => {
                    dispatch(UserAction.fetchUserHabilitations(login))
                })
        }
    },
    receiveApplicationHabilitations: (applicationHabilitations) => ({
        type: RECEIVE_ALL_APPLICATION_HABILITATION,
        applicationHabilitations,
    }),
    fetchApplicationHabilitations: () => (dispatch) => fetch(ApplicationConf.user.applicationHabilitations(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            dispatch(UserAction.receiveApplicationHabilitations(json))
        }),

    fetchBookmarks(type, stations, force) {
        return (dispatch) => {
            if (!force && AppStore.getState().SieauReducer.receivedProps.includes('bookmarks')) {
                return dispatch(
                    UserAction.fetchSpecificBookmarks(
                        type,
                        AppStore.getState().UserReducer.bookmarks,
                        stations,
                    ),
                )
            }
            return fetch(ApplicationConf.user.getBookmarks(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    dispatch(UserAction.receiveAllBookmarks(json))
                    dispatch(SieauAction.receiveProps('bookmarks'))
                    return dispatch(UserAction.fetchSpecificBookmarks(type, json, stations))
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError + i18n.bookmarks} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.bookmarks)
                })
        }
    },

    fetchSpecificBookmarks(type, bookmarks, stations) {
        return (dispatch) => {
            switch (type) {
                case 'quality':
                    return dispatch(
                        UserAction.receiveQualitometerBookmarks(
                            bookmarks.filter((b) => stations.find((q) => q.code === b.identifiant)),
                        ),
                    )
                case 'piezometry':
                    return dispatch(
                        UserAction.receivePiezometerBookmarks(
                            bookmarks.filter((b) => stations.find((p) => p.code === b.identifiant)),
                        ),
                    )
                case 'hydrometry':
                    return dispatch(
                        UserAction.receiveHydrometricStationsBookmarks(
                            bookmarks.filter((b) => stations.find((p) => p.code === b.identifiant)),
                        ),
                    )
                case 'pluviometry':
                    return dispatch(
                        UserAction.receivePluviometerBookmarks(
                            bookmarks.filter((b) => stations.find((p) => p.code === b.identifiant)),
                        ),
                    )
                case 'productionUnit':
                    return dispatch(
                        UserAction.receiveProductionUnitBookmarks(
                            bookmarks.filter((b) => stations.find((p) => p.code === b.identifiant)),
                        ),
                    )
                default:
                    return null
            }
        }
    },

    receiveUserKeyFigures(keyfigures) {
        return { type: RECEIVE_USER_KEYFIGURES_ALL, keyfigures }
    },

    fetchUserKeyFigures() {
        return (dispatch) => fetch(ApplicationConf.keyfigures.user(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserKeyFigures(json))
            })
    },
    receiveHabilitations(habilitations) {
        return { type: RECEIVE_USER_LOGIN_HABILITATIONS, habilitations }
    },
    fetchHabilitations() {
        return (dispatch) => fetch(ApplicationConf.user.habilitations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveHabilitations(json))
            })
    },

    reset() {
        return { type: RESET_USER }
    },

    addUser(user) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.userPath(), {
                method: 'POST',
                body: JSON.stringify(user),
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(() => {
                    dispatch(UserAction.fetchUsers())
                    dispatch(WaitAction.waitStop())
                    ToastrAction.success(i18n.addUserSuccessMessage)
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(`${i18n.addUserErrorMessage} : ${err}`)
                    ToastrAction.error(i18n.addUserErrorMessage)
                })
        }
    },
    deleteUser(login) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.delete(login), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(() => {
                    dispatch(UserAction.fetchUsers())
                    dispatch(push('/administration/user/'))
                    dispatch(WaitAction.waitStop())
                    ToastrAction.success(i18n.deleteUserSuccessMessage)
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(`${i18n.addUserErrorMessage} : ${err}`)
                    ToastrAction.error(i18n.addUserErrorMessage)
                })
        }
    },

    fetchUsersThemesAssignment: () => (dispatch) => fetch(ApplicationConf.user.assignedThemesLayers(), {
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            dispatch(UserAction.receiveUsersThemesAssignment(json))
        }),

    receiveUsersThemesAssignment: (usersThemes) => ({
        type: RECEIVE_USERS_THEMES_ASSIGNMENT,
        usersThemes,
    }),

    fetchUserThemesAssignment: () => (dispatch) => fetch(ApplicationConf.user.themeAssign(), {
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            dispatch(UserAction.receiveUserThemesAssignment(json))
        }),

    receiveUserThemesAssignment: (userThemes) => ({
        type: RECEIVE_USER_THEMES_ASSIGNMENT,
        userThemes,
    }),

    assignThemeToUsers: (themeId, logins = []) => (dispatch) => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.user.themeAssign(), {
            method: 'POST',
            body: JSON.stringify({ logins, themeId }),
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(() => {
                dispatch(WaitAction.waitStop())
                dispatch(UserAction.fetchUsersThemesAssignment())
                ToastrAction.success(i18n.AccessThemeWellModified)
            })
            .catch(() => dispatch(WaitAction.waitStop()))
    },

    receiveUserView: (views) => ({ type: RECEIVE_USER_VIEW, views }),

    fetchUserView() {
        return (dispatch) => fetch(ApplicationConf.user.userView(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveUserView(json))
            })
    },
    updateUserView: (data) => (dispatch) => fetch(ApplicationConf.user.userView(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            if (json.error) {
                ToastrAction.error(i18n.updateError + i18n.homepage)
            } else {
                dispatch(UserAction.fetchUserView())
                ToastrAction.success(i18n.elementUpdateSuccess)
            }
        })
        .catch((err) => {
            console.error(`${i18n.updateError + i18n.homepage} : ${err}`)
            ToastrAction.error(i18n.updateError + i18n.homepage)
        }),
    receiveGenericUserView: (views) => ({ type: RECEIVE_GENERIC_USER_VIEW, views }),

    fetchGenericUserView() {
        return (dispatch) => fetch(ApplicationConf.user.genericUserView(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(UserAction.receiveGenericUserView(json))
            })
    },
    updateGenericUserView: (data, callback = () => {}) => (dispatch) => fetch(ApplicationConf.user.genericUserView(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            if (json.error) {
                ToastrAction.error(i18n.updateError + i18n.homepage)
            } else {
                dispatch(UserAction.fetchGenericUserView())
                callback()
                ToastrAction.success(i18n.elementUpdateSuccess)
            }
        })
        .catch((err) => {
            console.error(`${i18n.updateError + i18n.homepage} : ${err}`)
            ToastrAction.error(i18n.updateError + i18n.homepage)
        }),
}

export default UserAction
