import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import ActionComponent from '../../../components/actions/ActionComponent'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import { Flex } from '../../../styles/main'
import EventsAction from '../../events/actions/EventsAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import UserViewDto from '../../administration/components/user/dto/UserViewDto'
import HomeAction from '../../home/actions/HomeAction'

const LabelWrapper = styled.span`
    width: ${(props) => (props.short ? '20%' : '34%')};
`

const InputRow = styled.input`
    background-color: ${(props) => props.disabled && '#F0F0F0 !important'};
    height: 48px;
    width: 100% !important;
    margin-right: 5px;
`

const CardStyle = styled(Card)`
    padding: 3rem;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

class ScenarDescriptive extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = this.getInitialState(props)
    }

    getInitialState = () => ({
        currentZones: [],
        currentCultures: [],
        currentModesConduites: [],
        selectedCategory: '',
        displayMode: false,
        dataLoaded: false,
        eventLoaded: {},
        checked: true,
        open: false,
        editMode: this.props.editMode,
        homepageViews: this.props.genericUserView,
        territory: this.props.territory,
        direction: null,
    })

    componentDidMount() {
        if (this.state.editMode) {
            this.props.setEditMode()
        } else {
            this.props.setReadMode()
        }
    }

    onChangeIndicateur = (v) => {
        this.props.onChangeScenario('iidDirection', v.target.value)
    }

    handleChange = (event) => {
        this.setState({ direction: event.target.value })
        this.props.onChangeScenario('direction', parseInt(event.target.value))
    }

    render() {
        const { indicateurs, editMode, scenario, applicationSettings, scenarios } = this.props
        const scenariosOptions = [
            ...scenarios.filter((sc) => sc.id !== scenario.id && sc.scType !== -1),
            { id: 0, name: 'Territoire actuel' },
        ]
        const selectDisabledMode = {
            backgroundColor: 'rgb(240, 240, 240)',
            height: '48px',
            width: '250px',
            marginRight: '5px',
        }
        
        this.props.setHelpPath('creer-et-simuler-des-scenarios/definir-le-critere-doptimisation/', applicationSettings)
        return (
            <CardStyle>
                <Flex>
                    <FormControl variant="outlined" style={{ width: '250px', display: 'flex' }}>
                        <LabelWrapper>{i18n.name}&nbsp;:</LabelWrapper>
                        <InputRow
                            className="sieau-input form-control"
                            value={scenario ? scenario.name : null}
                            onChange={(e) => this.props.onChangeScenario('name', e.target.value)}
                            disabled={!editMode}
                        />
                    </FormControl>
                </Flex>
                <Flex>
                    <FormControl variant="outlined" style={{ width: '500px', display: 'flex' }}>
                        <LabelWrapper>{i18n.descriptive}&nbsp;:</LabelWrapper>
                        <InputRow
                            className="sieau-input form-control"
                            value={scenario ? scenario.description : null}
                            onChange={(e) => this.props.onChangeScenario('description', e.target.value)}
                            disabled={!editMode}
                        />
                    </FormControl>
                </Flex>
                <Flex>
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <FormControl variant="outlined" disabled={!editMode}>
                            <Label htmlFor="scenario_ref">{i18n.scenarioRef}&nbsp;:</Label>
                            <Select
                                style={
                                    !editMode
                                        ? selectDisabledMode
                                        : { height: '48px', width: '250px', marginRight: '5px' }
                                }
                                value={scenario.scRef}
                                onChange={(v) => this.props.onChangeScenario('scRef', v.target.value)}
                            >
                                {scenariosOptions.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" disabled={!editMode}>
                            <Label htmlFor="status_type">{i18n.criteresOpti}&nbsp;:</Label>
                            <Select
                                style={
                                    !editMode
                                        ? selectDisabledMode
                                        : { height: '48px', width: '250px', marginRight: '5px' }
                                }
                                value={scenario.iidDirection}
                                onChange={(v) => this.onChangeIndicateur(v)}
                            >
                                {indicateurs.map((item) => {
                                    if (item.id !== 1) {
                                        return <MenuItem value={item.id}>{item.name}</MenuItem>
                                    }
                                    return null
                                })}
                            </Select>
                        </FormControl>
                        <FormControl
                            component="fieldset"
                            disabled={!!(!editMode || scenario.iidDirection === -1)}
                        >
                            <Label component="legend">Direction&nbsp;:</Label>
                            <RadioGroup
                                aria-label="position"
                                name="position"
                                value={parseInt(scenario.direction)}
                                onChange={this.handleChange}
                                row
                            >
                                <FormControlLabel
                                    value={0}
                                    control={<Radio color="primary" />}
                                    label="Minimiser"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio color="primary" />}
                                    label="Maximiser"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Flex>
            </CardStyle>
        )
    }
}

ScenarDescriptive.defaultProps = {
    warningModal: false,
}
ScenarDescriptive.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        contentId: PropTypes.number,
    }),
    warningModal: PropTypes.bool,
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
}

const mapStateToProps = (store) => ({
    genericUserView: store.UserReducer.genericUserView,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsEvent: store.EventsReducer.cmsEvent,
    cmsCategories: store.EventsReducer.cmsCategories,
    document: store.EventsReducer.document,
    users: store.UserReducer.users,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    scenarios: store.TerritoryReducer.scenarios,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    setHelpPath: HomeAction.setHelpPath,
    fetchConduites: ReferencialAction.fetchConduites,
    fetchZones: ReferencialAction.fetchZones,
    fetchCultures: ReferencialAction.fetchCultures,
    fetchUsers: UserAction.fetchUsers,
    checkAdmin: UserAction.checkAdmin,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    fetchCMSEvent: EventsAction.fetchCMSEvent,
    fetchCMSDocument: EventsAction.fetchCMSDocument,
    updateCMSEvent: EventsAction.updateCMSEvent,
    deleteCMSEvent: EventsAction.deleteCMSEvent,
    saveCmsDocument: EventsAction.saveCmsDocument,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarDescriptive)
