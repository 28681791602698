import { uniqWith } from 'lodash'
import {
    ADD_JOB_EXECUTIONS,
    RECEIVE_JOB,
    RECEIVE_JOB_EXECUTIONS,
    RECEIVE_JOB_LOGS,
    RECEIVE_JOBS_LOGS,
    RECEIVE_JOB_ROWS,
    SELECT_FILTER,
    RELOAD_JOB_LOGS,
} from '../constants/JobConstants'
import DtoJob from '../dto/DtoJob'
import DtoJobExecution from '../dto/DtoJobExecution'
import DtoJobLog from '../dto/DtoJobLog'

export function JobReducer(state = {}, action) {
    switch (action.type) {
        case SELECT_FILTER:
            return { ...state, filter: action.filter }
        case RECEIVE_JOB_ROWS:
            return { ...state, jobs: action.jobs }
        case RECEIVE_JOB:
            return { ...state, job: new DtoJob(action.job) }
        case RECEIVE_JOB_EXECUTIONS:
            return { ...state, jobExecutions: action.jobExecutions.map((el) => new DtoJobExecution(el)) }
        case ADD_JOB_EXECUTIONS:
            return { ...state,
                jobExecutions: uniqWith(
                    [].concat(
                        state.jobExecutions,
                        action.jobExecutions.map((el) => new DtoJobExecution(el)),
                        (a, b) => a.id === b.id && a.jobId === b.jobId,
                    ),
                ) }
        case RECEIVE_JOB_LOGS:
            return { ...state, jobLogs: action.jobLogs.map((el) => new DtoJobLog(el, action.jobExecutionId)) }
        case RECEIVE_JOBS_LOGS:
            return { ...state, jobsLogs: action.jobsLogs.map((l) => new DtoJobLog(l)) }
        case RELOAD_JOB_LOGS:
            return { ...state,
                jobsLogs: state.jobsLogs
                    .filter((l) => l.jobExecutionId != action.jobExecutionId)
                    .concat(action.jobsLogs.map((l) => new DtoJobLog(l))) }
        default:
            return state
    }
}

export const store = {
    jobs: [],
    jobsLogs: [],
    jobExecutions: [],
    filter: 'QUALITOMETER',
    job: new DtoJob(),
    jobLogs: [],
}
