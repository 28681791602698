import styled from 'styled-components'
import { mainBlue } from './theme'

export const GreenButton = styled.button`
    border: ${(props) => (props.greenReverse ? '1px solid #29AE99' : 'none')};
    color: ${(props) => (props.greenReverse ? '#29AE99' : '#f9f8f6')};
    disabled: ${(props) => props.disabled && '#484848'};
    background-color: ${(props) => (props.greenReverse ? '#f9f8f6' : '#29AE99')};
    background-color: ${(props) => props.disabled && '#c5c5c5'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => (props.noMargin ? '0' : '1rem')};
    padding: 0.5rem 2rem;
    font-size: 1rem;
    ${(props) => props.theme.fontFamilyNormal};
    font-weight: ${(props) => props.theme.fontWeightBold};
    transition: all 500ms ease-out;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    width: auto;
    font-family: Poppins;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    border-radius: ${(props) => props.borderRadius && '5px'};
    
    &:focus {
        background-color: ${(props) => (props.greenReverse ? '#f9f8f6' : '#29AE99')};
    }
    &:active {
        transform: scale(0.95);
    }
`

export const GreenUploadLabel = styled.label`
    border: ${(props) => (props.greenReverse ? '1px solid #29AE99' : 'none')};
    color: ${(props) => (props.greenReverse ? '#29AE99' : '#f9f8f6')};
    disabled: ${(props) => props.disabled && '#484848'};
    background-color: ${(props) => (props.greenReverse ? '#f9f8f6' : '#29AE99')};
    background-color: ${(props) => props.disabled && '#c5c5c5'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    ${(props) => props.theme.fontFamilyNormal};
    font-weight: ${(props) => props.theme.fontWeightBold};
    transition: all 500ms ease-out;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    width: auto;
    font-family: Poppins;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    border-radius: ${(props) => props.borderRadius && '5px'};

    &:focus {
        background-color: ${(props) => props.disabled && '#c5c5c5'};
    }
    display: inline-flex;
    align-items: center;
`

export const WhiteButton = styled.button`
    border: 1px solid #f9f8f6;
    color: #f9f8f6;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    ${(props) => props.theme.fontFamilyNormal};
    font-weight: ${(props) => props.theme.fontWeightBold};
    transition: all 500ms ease-out;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    width: auto;
    font-family: Poppins;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    &:hover,
    &:focus {
        transform: scale(0.95);
        text-decoration: none;
        background-color: transparent;
    }
`

export const DwldButton = styled.a`
    position: relative;
    height: 50px;
    border: 1px solid ${(props) => (props.border ? props.border : mainBlue)};
    color: ${(props) => (props.color ? props.color : mainBlue)};
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem 4rem 1rem 6rem;
    font-size: 1rem;
    ${(props) => props.theme.fontFamilyNormal};
    transition: all 500ms ease-out;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    width: auto;
    font-family: Poppins;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    &:focus {
        background-color: ${(props) => props.theme.secondaryBlue};
    }
`
