import React, { Component } from 'react'
import UUID from 'uuid'
import PropTypes from 'prop-types'
import DisplayedValue from './DisplayedValue'

const { $ } = window

class Input extends Component {
    state = { id: UUID.v4(), editing: false }

    onChangeValue = (event) => {
        if (this.props.onEnterKeyPress && (event.keyCode === 13 || event.which === 13)) {
            this.props.onEnterKeyPress(event.target.value)
            if (this.state.editing) {
                this.setState({ editing: false })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value && !nextProps.value) {
            $(`#${this.state.id}`).val('')
        }
    }

    onTableEditing = () => {
        this.setState({ editing: true }, () => {
            $(`#${this.state.id}`).focus()
            $(`#${this.state.id}`).blur(() => this.setState({ editing: false }))
        })
    }

    getClearFunction = () => {
        if (this.props.clearFunction) {
            return (
                <i
                    data-clear-input
                    className={
                        `material-icons clickable right ${this.props.disabled ? 'grey-text' : ''}`
                    }
                    onClick={() => this.onClear()}
                >
                    clear
                </i>
            )
        }
        return null
    }

    onClear = () => {
        if (!this.props.disabled) {
            if (this.props.onEnterKeyPress) {
                this.props.onEnterKeyPress('')
            } else if (this.props.onChange) {
                this.props.onChange('')
            }
            $(`#${this.state.id}`).val('')
        }
    }

    getInput = () => {
        if (this.props.readMode || (this.props.tableEditable && !this.state.editing)) {
            return (
                <DisplayedValue
                    label={this.props.title}
                    value={this.props.value}
                    hideNull={this.props.hideNull}
                    onClick={this.onTableEditing}
                    tableEditable={this.props.tableEditable}
                />
            )
        }
        const disabled = { readOnly: this.props.disabled }
        const dropDown = this.props.dropDownId
            ? { 'data-position': 'bottom', 'data-activates': this.props.dropDownId }
            : {}
        if (this.props.noLabel) {
            return (
                <div className={this.props.noInputFieldClass ? '' : 'input-field'}>
                    <input
                        style={this.props.style}
                        type={this.props.passwordType ? 'password' : 'text'}
                        className={`sieau-input form-control input-sm ${this.props.className}`}
                        data-mode={this.state.id}
                        id={this.props.id || this.state.id}
                        onKeyPress={(e) => this.onChangeValue(e)}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        placeholder={this.props.placeholder}
                        data-tooltip={this.props.tooltip}
                        maxLength={this.props.maxLength}
                        {...this.props.otherInputProps}
                        {...dropDown}
                        {...disabled}
                    />
                </div>
            )
        }
        if (this.props.float) {
            return (
                <div className={this.props.noInputFieldClass ? '' : 'input-field'}>
                    <label htmlFor={this.state.id}>{this.props.title}</label>
                    <input
                        style={this.props.style}
                        type="number"
                        step="0.01"
                        value={this.props.value}
                        className={`sieau-input form-control input-sm ${this.props.className}`}
                        data-mode={this.state.id}
                        id={this.props.id || this.state.id}
                        onKeyPress={(e) => this.onChangeValue(e)}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        placeholder={this.props.placeholder}
                        data-tooltip={this.props.tooltip}
                        maxLength={this.props.maxLength}
                        {...this.props.otherInputProps}
                        {...dropDown}
                        {...disabled}
                    />
                </div>
            )
        }
        if (this.props.phone) {
            return (
                <div className={this.props.noInputFieldClass ? '' : 'input-field'}>
                    <label htmlFor={this.state.id}>{this.props.title}</label>
                    <input
                        style={this.props.style}
                        type="number"
                        value={this.props.value}
                        className={`sieau-input form-control input-sm ${this.props.className}`}
                        data-mode={this.state.id}
                        id={this.props.id || this.state.id}
                        onKeyPress={(e) => this.onChangeValue(e)}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        placeholder={this.props.placeholder}
                        data-tooltip={this.props.tooltip}
                        maxLength="10"
                        {...this.props.otherInputProps}
                        {...dropDown}
                        {...disabled}
                    />
                </div>
            )
        }
        return (
            <div className={this.props.noInputFieldClass ? '' : 'input-field'} style={this.props.divStyle}>
                <label htmlFor={this.state.id}>{this.props.title}</label>
                <input
                    style={this.props.style}
                    type={this.props.passwordType ? 'password' : 'text'}
                    className={`sieau-input form-control input-sm ${this.props.className}`}
                    data-mode={this.state.id}
                    id={this.props.id || this.state.id}
                    onKeyPress={(e) => this.onChangeValue(e)}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    placeholder={this.props.placeholder}
                    data-tooltip={this.props.tooltip}
                    maxLength={this.props.maxLength}
                    {...this.props.otherInputProps}
                    {...dropDown}
                    {...disabled}
                />
            </div>
        )
    }

    render() {
        return this.props.col ? (
            <div className={`col s${this.props.col}`}>{this.getInput()}</div>
        ) : (
            this.getInput()
        )
    }

    componentDidMount() {
        if (this.props.value) {
            $(`#${this.state.id}`).val(this.props.value)
        }
    }

    componentDidUpdate() {
        if (this.props.value) {
            $(`#${this.state.id}`).val(this.props.value)
        }
    }
}

Input.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    value: PropTypes.string,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    passwordType: PropTypes.bool,
    noInputFieldClass: PropTypes.bool,
    dropDownId: PropTypes.bool,
    placeholder: PropTypes.string,
    otherInputProps: PropTypes.shape({}),
    style: PropTypes.shape({}),
    divStyle: PropTypes.shape({}),
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    tableEditable: PropTypes.bool,
    maxLength: PropTypes.number,
    clearFunction: PropTypes.bool,
    id: PropTypes.string,
    noLabel: PropTypes.bool,
    float: PropTypes.bool,
}

Input.defaultProps = {
    onChange: () => {},
    style: {},
    otherInputProps: {},
    readMode: false,
    clearFunction: false,
    float: false,
}

export default Input
