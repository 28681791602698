module.exports = {
    applicationName: "Co-Click'Eau",
    keyRecaptcha: '6LfvpYceAAAAACxddQ1PgAJ8Z7rRHaQ2NEHC8PUT',
    cguID: '4740',
    // mailAdmin: 'julien.roba@aquasys.fr',
    mailAdmin: 'coclickeau@inrae.fr',
    // mailAdmin: 'contact@aquasys.fr',
    mailSupport: 'contact@aquasys.fr',
    twitterAccount: 'INRAE_France',
}
