import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppStore from '../../store/Appstore'
import HomeAction from '../../pages/home/actions/HomeAction'
import {
    Cancel,
    Clear,
    Compare,
    Delete,
    DeleteCheck,
    Duplicate,
    Edit,
    Export,
    New,
    FastArrowNav,
    Links,
    Update,
    Replace,
    Shared,
    Save,
    Start,
    Purge,
} from './actionsList'
import Notif from '../Notif'

class ActionComponent extends Component {
    setActions = (actions) => {
        const arrayActions = new Array(13)
        const keys = Object.keys(actions)
        keys.filter((k) => k !== 'other').forEach((key) => {
            this.addAction(key, arrayActions, actions)
        })
        if (actions.other) {
            Object.keys(actions.other).forEach((key) => {
                this.addAction(key, arrayActions, actions.other)
            })
        }
        AppStore.dispatch(HomeAction.setActions(arrayActions))
    }

    onClickAction = (func) => {
        func()
    }

    addAction = (key, arrayActions, actions, deleteCheck) => {
        switch (key) {
            case 'purge':
                arrayActions[20] = <Purge onPurge={() => this.onClickAction(actions[key])} />
                break
            case 'export':
                arrayActions[19] = <Export onExport={() => this.onClickAction(actions[key])} />
                break
            case 'start':
                arrayActions[18] = <Start onStart={() => this.onClickAction(actions[key])} />
                break
            case 'deleteCheck':
                arrayActions[17] = <DeleteCheck onDelete={() => this.onClickAction(actions[key])} />
                break
            case 'duplicate':
                arrayActions[16] = (
                    <Duplicate onDuplicate={() => this.onClickAction(actions[key])} />
                )
                break
            case 'arrowNav':
                arrayActions[15] = <FastArrowNav nav={actions[key]} />
                break
            case 'edit':
                arrayActions[14] = <Edit onEdit={() => this.onClickAction(actions[key])} />
                break
            case 'clear':
                arrayActions[13] = <Clear onClear={() => this.onClickAction(actions[key])} />
                break
            case 'cancel':
                arrayActions[12] = <Cancel onCancel={() => this.onClickAction(actions[key])} />
                break
            case 'save':
                arrayActions[11] = <Save onSave={() => this.onClickAction(actions[key])} />
                break
            case 'delete':
                arrayActions[10] = <Delete onDelete={() => this.onClickAction(actions[key])} />
                break
            case 'new':
                arrayActions[9] = <New onNew={() => this.onClickAction(actions[key])} />
                break
            case 'replace':
                arrayActions[8] = <Replace onReplace={() => this.onClickAction(actions[key])} />
                break
            case 'update':
                arrayActions[7] = <Update onUpdate={() => this.onClickAction(actions[key])} />
                break
            case 'links':
                arrayActions[6] = <Links links={actions[key]} />
                break
            case 'share':
                arrayActions[2] = <Shared links={actions[key]} />
                break
            case 'compare':
                arrayActions[1] = <Compare onCompare={() => this.onClickAction(actions[key])} />
                break
            default:
                arrayActions.unshift(actions[key])
                break
        }
    }

    setNotifActions = (actions) => {
        AppStore.dispatch(
            HomeAction.setActions(
                actions.map((o) => (
                    <Notif
                        onClick={o.func}
                        icon={o.icon}
                        label={o.label}
                        name={o.name}
                        color={o.color}
                        content={o.content}
                        onClickTitle={o.onClickTitle}
                    />
                )),
            ),
        )
    }

    componentWillUnmount() {
        if (!this.props.persistantActions) {
            AppStore.dispatch(HomeAction.setActions([]))
        }
    }

    render() {
        return null
    }
}

ActionComponent.propTypes = {
    persistantActions: PropTypes.bool,
}

export default ActionComponent
