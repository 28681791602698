import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import i18n from 'simple-react-i18n'
import AppStore, { history } from './store/Appstore'
import Home from './pages/home/Home'
import Login from './pages/home/Login'
import AskAccount from './pages/home/AskAccount'
import Contacts from './pages/home/Contacts'
import AuthRoute from './pages/home/router/AuthRoute'
import App from './pages/home/App'
import Dashboard from './pages/home/Dashboard'
import ResetPasswordApp from './pages/home/ResetPasswordApp'
import AccountUserApp from './pages/account/components/AccountUserApp'
import ContentsApp from './pages/events/ContentsApp'
import EventForm from './pages/events/components/EventForm'
// import AdministrationApp from './pages/administration/components/AdministrationApp'
// import AdministrationContentsApp from './pages/administration/components/contents/AdministrationContentsApp'
// import UsersApp from './pages/administration/components/user/components/UsersApp'
// import UserApp from './pages/administration/components/user/components/UserApp'
// import SettingApp from './pages/administration/components/setting/SettingApp'
import TerritoriesApp from './pages/territory/TerritoriesApp'
import ReferencialDashboardApp from './pages/referencial/components/dashboard/components/ReferencialDashboardApp'
import ReferencialDashboardPanel from './pages/referencial/components/dashboard/components/ReferencialDashboardPanel'
import CulturesApp from './pages/referencial/components/cultures/components/CulturesApp'
import ConduitesApp from './pages/referencial/components/conduites/components/ConduitesApp'
import CommunesApp from './pages/referencial/components/communes/components/CommunesApp'
import RotationApp from './pages/referencial/components/rotation/components/RotationApp'
import ZonesApp from './pages/referencial/components/zones/components/ZonesApp'
import IndicateursApp from './pages/referencial/components/indicateurs/components/IndicateursApp'
import NewTerritory from './pages/territory/NewTerritory'
import MatriceApp from './pages/territory/matrices/MatriceApp'
import ScenarioDetail from './pages/territory/scenarios/ScenarioDetail'
import TerritoryDetail from './pages/territory/TerritoryDetail'
import ScenariosCompareApp from './pages/territory/scenarios/ScenariosCompareApp'
import RPGApp from './pages/referencial/components/cultures/components/RPGApp'
import MatriceRefApp from './pages/referencial/components/cultures/components/MatriceRefApp'

import './assets/fonts/Open_Sans/OpenSans-Bold.ttf'
import './assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf'
import './assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf'
import './assets/fonts/Open_Sans/OpenSans-Light.ttf'
import './assets/fonts/Open_Sans/OpenSans-Regular.ttf'

import './assets/fonts/Roboto/Roboto-Black.ttf'
import './assets/fonts/Roboto/Roboto-Bold.ttf'
import './assets/fonts/Roboto/Roboto-Italic.ttf'
import './assets/fonts/Roboto/Roboto-Light.ttf'
import './assets/fonts/Roboto/Roboto-Medium.ttf'

import './assets/fonts/Poppins/Poppins-Bold.ttf'
import './assets/fonts/Poppins/Poppins-Light.ttf'
import './assets/fonts/Poppins/Poppins-Medium.ttf'
import './assets/fonts/Poppins/Poppins-Regular.ttf'
import './assets/fonts/Poppins/Poppins-SemiBold.ttf'

import {
    PATH_HOME,
    PATH_CONTACT,
    PATH_REGISTER,
    PATH_RESET,
    PATH_LOGIN,
    PATH_ACCOUNT,
    PATH_CONTENTS,
    PATH_TERRITORY,
    PATH_NEW_TERRITORY,
    PATH_TERRITORY_DETAIL,
    PATH_TERRITORY_MATRICE,
    PATH_TERRITORY_SCENARIO,
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_DASHBOARD,
    // PATH_ADMINISTRATION,
    // PATH_ADMINISTRATION_CONTENTS,
    // PATH_ADMINISTRATION_SETTINGS,
    // PATH_ADMINISTRATION_USER,
    PATH_REFERENCIAL_CULTURES,
    PATH_REFERENCIAL_CONDUITES,
    PATH_REFERENCIAL_COMMUNES,
    PATH_REFERENCIAL_ZONES,
    PATH_REFERENCIAL_ROTATION,
    PATH_REFERENCIAL_INDICATEURS,
    PATH_REFERENCIAL_RPG,
    PATH_REFERENCIAL_MATRICE_REF,
} from './pages/home/constants/RouteConstants'

import fr from './i18n/properties_fr.json'
import en from './i18n/properties_en.json'

const { $ } = window

i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

// const checkAuth = (replace) => {
//     const payload = getPayload()
//     if (!payload) {
//         replace(PATH_LOGIN)
//     } else {
//         const exp = moment(JSON.parse(payload).exp)
//         if (moment().isAfter(exp)) {
//             replace(PATH_LOGIN)
//             AppStore.dispatch(HomeAction.logout())
//         }
//     }
// }

// const onEnter = (nextState, replace) => {
//     checkAuth(replace)
// }
//
// const onChange = (prevState, nextState, replace) => {
//     checkAuth(replace)
// }

const customTheme = createMuiTheme({
    typography: {
        fontFamily: 'Poppins',
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#057DCC',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#29AE99',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
    },
    checkbox: {},
    svgIcon: {},
})

const store = AppStore

$(() => {
    if (window.location.href.includes('https://recette.co-click-eau.fr/static')) {
        window.location.href = 'https://recette.co-click-eau.fr/#/'
    }
    /*
     * this swallows backspace keys on any non-input element.
     * stops backspace -> back
     */
    const rx = /INPUT|SELECT|TEXTAREA/i
    $(document).bind('keydown keypress', (e) => {
        if (e.which === 8) { // 8 == backspace
            if (!rx.test(e.target.tagName) || e.target.disabled || e.target.readOnly) {
                e.preventDefault()
            }
        }
    })
})

render(
    <MuiThemeProvider theme={customTheme}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <>
                    <Switch>
                        <Route exact path={PATH_HOME} component={(props) => <Home {...props} />} />
                        <Route
                            exact
                            path={PATH_LOGIN}
                            component={(props) => <Login {...props} />}
                        />
                        <Route
                            exact
                            path={PATH_REGISTER}
                            component={(props) => <AskAccount {...props} />}
                        />
                        <Route
                            exact
                            path={PATH_CONTACT}
                            component={(props) => <Contacts {...props} />}
                        />
                        <Route
                            exact
                            path={PATH_RESET}
                            component={(props) => <ResetPasswordApp {...props} />}
                        />
                        <Route
                            path="/"
                            component={(props) => <AuthRoute {...props} component={App} />}
                        />
                    </Switch>
                    <Route exact path="/" component={(props) => <Dashboard {...props} />} />
                    <Switch>
                        <Route
                            exact
                            path={`${PATH_CONTENTS}/:categoryId`}
                            component={(props) => <ContentsApp {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_CONTENTS}/:categoryId/:contentId`}
                            component={(props) => <EventForm {...props} />}
                        />
                    </Switch>

                    <Switch>
                        <Route
                            exact
                            path={PATH_TERRITORY}
                            render={(props) => <TerritoriesApp {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_TERRITORY_DETAIL}/:id`}
                            render={(props) => <TerritoryDetail {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_TERRITORY_MATRICE}/:id`}
                            render={(props) => <MatriceApp {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_TERRITORY_SCENARIO}/:id`}
                            render={(props) => <ScenarioDetail {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_NEW_TERRITORY}`}
                            render={(props) => <NewTerritory {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_TERRITORY_MATRICE}/:id/scenariosCompare`}
                            render={(props) => <ScenariosCompareApp {...props} />}
                        />
                    </Switch>

                    <Route
                        exact
                        path={PATH_ACCOUNT}
                        component={(props) => <AccountUserApp {...props} />}
                    />
                    <Route
                        path={PATH_REFERENCIAL}
                        render={(props) => <ReferencialDashboardApp {...props} />}
                    />
                    <Switch>
                        <Route
                            path={PATH_REFERENCIAL_DASHBOARD}
                            render={(props) => <ReferencialDashboardPanel {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_CULTURES}
                            render={(props) => <CulturesApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_RPG}
                            render={(props) => <RPGApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_MATRICE_REF}
                            render={(props) => <MatriceRefApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_COMMUNES}
                            render={(props) => <CommunesApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_CONDUITES}
                            render={(props) => <ConduitesApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_ZONES}
                            render={(props) => <ZonesApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_ROTATION}
                            render={(props) => <RotationApp {...props} />}
                        />
                        <Route
                            path={PATH_REFERENCIAL_INDICATEURS}
                            render={(props) => <IndicateursApp {...props} />}
                        />
                    </Switch>

                    {/* <Route
                        path={PATH_ADMINISTRATION}
                        render={(props) => <AdministrationApp {...props} />}
                    />
                    <Switch>
                        <Route
                            exact
                            path={PATH_ADMINISTRATION_CONTENTS}
                            render={(props) => <AdministrationContentsApp {...props} />}
                        />
                        <Route
                            exact
                            path={PATH_ADMINISTRATION_SETTINGS}
                            render={(props) => <SettingApp {...props} />}
                        />
                        <Route
                            exact
                            path={PATH_ADMINISTRATION_USER}
                            render={(props) => <UsersApp {...props} />}
                        />
                        <Route
                            exact
                            path={`${PATH_ADMINISTRATION_USER}/:login`}
                            render={(props) => <UserApp {...props} />}
                        />
                    </Switch> */}
                </>
            </ConnectedRouter>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('app'),
)
