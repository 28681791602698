/* eslint-disable react/no-danger */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from '@material-ui/core/Modal'
import Zoom from '@material-ui/core/Zoom'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import AccountAction from '../account/actions/AccountAction'
import HomeAction from './actions/HomeAction'
import HomeFooter from './components/Footer'
import HomeHeader from './components/Header'
import HomeSlide from './components/Slide'
import { GreenButton } from '../../styles/button'
import { aquasysCGUPath } from '../../conf/SieauConstants'
import { cguID } from '../../conf/AppConstants'
import Toaster from '../../components/notifications/Toaster'
import { Center } from '../../styles/main'

const CenterMargin = styled(Center)`
    margin-bottom: 30px !important;
`

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            cgu: '',
            cguTitle: '',
            cguDate: '',
            loading: false,
            identifiant: '',
            password: '',
        }
    }

    componentDidMount() {
        this.props.setHelpPath('faq')
        this.getCGU()
    }

    handleOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.props.logout()
        this.setState({ open: false })
    }

    validateCGU = () => {
        const { identifiant } = this.state
        const currentDate = new Date()
        const newStat = {
            login: identifiant,
            eventType: 'CGU',
            module: 'CGU',
            version: currentDate,
        }
        this.props.updateAccountCGUStatistic(newStat).then(() => {
            this.connectApp(identifiant)
        })
    }

    getModal = () => {
        const { open, cguTitle, cgu } = this.state
        if (!open) {
            return null
        }
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={this.handleClose}
                disableBackdropClick
            >
                <>
                    <Slide direction="down" in={open} timeout={500}>
                        <Toaster variant="info" message={i18n.validateNewCGU} />
                    </Slide>
                    <Zoom in={open} timeout={500}>
                        <div
                            id="cguModal"
                            style={{
                                position: 'absolute',
                                width: '50%',
                                height: '70%',
                                backgroundColor: 'white',
                                top: '15%',
                                left: '25%',
                                margin: 'auto',
                                padding: '10px',
                                overflowY: 'auto',
                            }}
                        >
                            <div className="cgu">
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: cguTitle,
                                    }}
                                />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: cgu,
                                    }}
                                />
                            </div>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end"
                            >
                                <Grid item>
                                    <a
                                        id="submit"
                                        onClick={this.handleClose}
                                        className="btn waves-effect waves-light col s12 valign"
                                        style={{
                                            backgroundColor: '#e30000',
                                            marginRight: '15px',
                                        }}
                                    >
                                        {i18n.cancel}
                                    </a>
                                </Grid>
                                <Grid item>
                                    <button
                                        id="submit"
                                        className="btn waves-effect waves-light col s12 valign"
                                        onClick={() => this.validateCGU()}
                                    >
                                        {i18n.readAndAcceptCGU}
                                    </button>
                                </Grid>
                            </Grid>
                        </div>
                    </Zoom>
                </>
            </Modal>
        )
    }

    getCGU = () => {
        fetch(aquasysCGUPath + cguID)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({
                    cgu: jsonData.content.rendered,
                    cguTitle: jsonData.title.rendered,
                    cguDate: jsonData.modified,
                })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    onSubmit = (event) => {
        event.preventDefault()
        const { identifiant, password, cguDate } = this.state
        if (password && identifiant) {
            this.setState({ loading: true })
            this.props.login(identifiant, password).then(() => {
                this.setState({ loading: false })
                this.props.fetchAccountCGUDate(identifiant).then((json = {}) => {
                    const dateUser = new Date(json.date)
                    const dateCGU = new Date(cguDate)
                    if (!dateUser.getTime() && dateUser.getTime() !== 0) {
                        this.props.logout()
                    } else if (!json.date || dateUser.getTime() < dateCGU.getTime()) {
                        this.handleOpen()
                    } else {
                        this.connectApp(identifiant)
                    }
                })
            })
        }
    }

    connectApp = (identifiant) => {
        this.props.fetchAccountHabilitations(identifiant).then(() => {
            this.props.push('/')
        })
    }

    onChangeLogin = (event) => {
        this.setState({ identifiant: event.target.value })
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value })
    }

    render() {
        const { loading, identifiant, password } = this.state
        return (
            <div>
                <div
                    className="row"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                        margin: '0',
                    }}
                >
                    <Grid container direction="row" justify="center" alignItems="center">
                        <HomeSlide />
                        <div className="home_content">
                            <div className="home_card">
                                <HomeHeader page="login" />
                                <div
                                    className="row"
                                    style={{
                                        height: '75vh',
                                        overflowY: 'auto',
                                        padding: '6rem 12rem 0px',
                                    }}
                                >
                                    <CenterMargin>
                                        <span className="title_home">
                                            {i18n.loginTitle}
                                        </span>
                                    </CenterMargin>
                                    <form name="login">
                                        <div className="row valign-wrapper">
                                            <i className="padding-glyph material-icons input_home">
                                                person
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="text"
                                                placeholder="Identifiant"
                                                onChange={(e) => this.onChangeLogin(e)}
                                                value={identifiant}
                                            />
                                        </div>
                                        <div className="row valign-wrapper" style={{ margin: '0' }}>
                                            <i className="padding-glyph material-icons input_home">
                                                lock
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="password"
                                                placeholder="Mot de passe"
                                                onChange={(e) => this.onChangePassword(e)}
                                                value={password}
                                            />
                                        </div>
                                        <Grid container justify="center">
                                            {!loading ? (
                                                <GreenButton onClick={this.onSubmit}>Connexion</GreenButton>
                                            ) : (
                                                <CircularProgress />
                                            )}
                                        </Grid>
                                        <div
                                            className="row valign-wrapper"
                                            style={{ margin: '1rem 0', justifyContent: 'center' }}
                                        >
                                            <Link to="/reset">Mot de passe oublié</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <HomeFooter />
                        </div>
                    </Grid>
                </div>
                {this.getModal()}
            </div>
        )
    }
}

Login.propTypes = {
    fetchAccountHabilitations: PropTypes.func,
    push: PropTypes.func,
    fetchAccountCGUDate: PropTypes.func,
    login: PropTypes.func,
    logout: PropTypes.func,
    updateAccountCGUStatistic: PropTypes.func,
    setHelpPath: PropTypes.func,
}

const mapDispatchToProps = {
    login: HomeAction.login,
    logout: HomeAction.logout,
    setHelpPath: HomeAction.setHelpPath,
    fetchAccountHabilitations: AccountAction.fetchAccountHabilitations,
    fetchAccountCGUDate: AccountAction.fetchAccountCGUDate,
    updateAccountCGUStatistic: AccountAction.updateAccountCGUStatistic,
    push,
}

export default connect(null, mapDispatchToProps)(Login)
