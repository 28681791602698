import {
    RECEIVE_TERRITOIRES,
    RECEIVE_TERRITORY,
    RECEIVE_TERRITORY_ID,
    RECEIVE_TERRITORY_ASSOLEMENTS,
    RECEIVE_TERRITORY_ZONES_SURFACES,
    RECEIVE_ALL_MATRICES,
    RECEIVE_TERRITORY_MATRICES,
    RECEIVE_MATRICE,
    RECEIVE_ALL_SCENARIOS,
    RECEIVE_MATRICE_SCENARIOS,
    RECEIVE_SCENARIO,
    RECEIVE_SCENARIO_RESULTS,
    RECEIVE_SCENARIOS_COMPARE,
    RECEIVE_TERRITORY_AVERAGE_RPG,
    RECEIVE_TERRITORY_SURFACES_RPG,
    LOADING_TERRITORY_AVERAGE_RPG,
} from '../constants/TerritoryConstants'
import DtoTerritory from '../dto/DtoTerritory'

export const store = {
    territoires: [],
    assolements: [],
    zonesSurfaces: [],
    allMatrices: [],
    matrices: [],
    matrice: {},
    territoire: {},
    allScenarios: [],
    scenarios: [],
    scenariosToCompare: [],
    scenario: {},
    results: [],
    territoireId: null,
    loadingAverageRPG: false,
    averageRPGData: {},
    loadingSurfacesRPG: false,
    surfacesRPG: [],
}

export function TerritoryReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_TERRITOIRES:
            return {
                ...state,
                territoires: action.territoires.map((territoire) => new DtoTerritory(territoire)),
            }
        case RECEIVE_TERRITORY:
            return {
                ...state,
                territoire: new DtoTerritory(action.territoire),
            }
        case RECEIVE_TERRITORY_ID:
            return {
                ...state,
                territoireId: action.territoireId,
            }
        case RECEIVE_TERRITORY_ASSOLEMENTS:
            return {
                ...state,
                assolements: action.assolements,
            }
        case RECEIVE_TERRITORY_AVERAGE_RPG:
            return {
                ...state,
                loadingAverageRPG: false,
                averageRPGData: action.averageRPGData,
            }
        case LOADING_TERRITORY_AVERAGE_RPG:
            return {
                ...state,
                loadingAverageRPG: action.loading,
            }
        case RECEIVE_TERRITORY_SURFACES_RPG:
            return {
                ...state,
                loadingSurfacesRPG: false,
                surfacesRPG: action.surfacesRPG,
            }
        case RECEIVE_TERRITORY_ZONES_SURFACES:
            return {
                ...state,
                zonesSurfaces: action.zonesSurfaces,
            }
        case RECEIVE_ALL_MATRICES:
            return {
                ...state,
                allMatrices: action.matrices,
            }
        case RECEIVE_TERRITORY_MATRICES:
            return {
                ...state,
                matrices: action.matrices,
            }
        case RECEIVE_MATRICE:
            return {
                ...state,
                matrice: action.matrice,
            }
        case RECEIVE_ALL_SCENARIOS:
            return {
                ...state,
                allScenarios: action.scenarios,
            }
        case RECEIVE_MATRICE_SCENARIOS:
            return {
                ...state,
                scenarios: action.scenarios,
            }
        case RECEIVE_SCENARIO:
            return {
                ...state,
                scenario: action.scenario,
            }
        case RECEIVE_SCENARIOS_COMPARE:
            return {
                ...state,
                scenariosToCompare: action.scenarios,
            }
        case RECEIVE_SCENARIO_RESULTS:
            return {
                ...state,
                results: action.results,
            }
        default:
            return state
    }
}
