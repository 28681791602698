import { Icon } from '@material-ui/core'
import styled from 'styled-components'

export const MainTitle = styled.h1`
    ${(props) => props.fontFamilyNormal};
    font-size: 2.9rem;
    font-weight: ${(props) => (props.bold ? '600' : '400')};
    color: black;
    margin: 3rem 7rem;
    text-align: center;
    line-height: 70px;
`

export const SecondaryTitle = styled.h2`
    ${(props) => props.fontFamilyNormal};
    font-size: ${(props) => props.fontSize || '2.8rem'};
    font-weight: ${(props) => (props.bold ? '600' : '400')};
    color: ${(props) => (props.white ? '#f9f8f6' : 'black')};
    text-align: center;
    line-height: ${(props) => props.lineHeight || '70px'};
    ${(props) => (props.margin ? `margin: ${props.margin}` : '')};
`

export const SmallTitle = styled.h3`
  ${(props) => props.fontFamilyNormal};
  font-size: 1.7rem;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  color: black;
`

export const WhiteTitle = styled.h1`
  ${(props) => props.fontFamilyNormal};
  font-size: 2rem;
  color: #FFF;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
`

export const BlackTitle = styled.h3`
  ${(props) => props.fontFamilyNormal};
  font-size: 1.3rem;
  color: '#333';
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  margin: 0;
`

export const DldIcon = styled(Icon)`
    font-size: 1rem !important;
    display: inline-block;
    margin: 0 !important;
    color: #f9f8f6;
`

export const DldIconText = styled.span`
    color: #f9f8f6;
    display: inline-block;
    transform: translate(-2px, -3px);
`
