import logo_coclickeau from '../../../assets/pictures/logos/logo_coclickeau.png'
import logo_aquasys from '../../../assets/pictures/logos/logo_aquasys.png'
import doc_icon from '../../../assets/pictures/pictos/doc_icon.png'

export const doc = {
    src: doc_icon,
}

export const logo = {
    src: logo_coclickeau,
}

export const textHeader = {
    text: 'Co-click’eau peut vous intéresser si vous intervenez sur un territoire ou l’évolution de l’agriculture est en discussion : préservation de la qualité de l’eau, alimentation durable, adaptation au climat… Elle peut être mobilisée pour élaborer ou renouveler un programme d’actions, voire pour animer des actions en cours. Participative, cette démarche mise sur le dialogue entre les acteurs locaux pour explorer collectivement des scénarios prospectifs qui combinent des actions favorables à l’environnement et viables économiquement et techniquement. Pour cela, la démarche est documentée par des guides méthodologiques et outillée d’une application qui permet de simuler les conséquences des scénarios imaginés sur l’environnement, la rentabilité ou encore le temps de travail. Ces scénarios tiennent compte des objectifs des différents acteurs concernés et sont caractérisés par une répartition des cultures et modes de conduite par grandes zones à l’échelle du territoire en question.',
}

export const home = {
    rowOne: {
        number: 1,
        title: 'Constitution de la base de données',
        subtitle: 'techniques propres au territoire',
        text: "Un groupe d'experts techniques propose une description des différents modes de conduite des cultures et évalue leurs performances environnementales et socio-économiques.",
    },
    rowTwo: {
        number: 2,
        title: 'Expression des objectifs',
        subtitle: 'des acteurs du territoire',
        text: 'Les membres du comité de pilotage expriment les résultats techniques, économiques et environnementaux auxquels ils souhaiteraient arriver à moyen terme pour le territoire.',
    },
    rowThree: {
        number: 3,
        title: 'Simulation des scénarios',
        subtitle: "à l'échelle du territoire",
        text: "Des scénarios sont construits à l'aide de l'outils de simulation en ligne et déterminent les objectifs environnementaux à atteindre et les logiques d'actions.",
    },
}

export const logoAquasys = {
    src: logo_aquasys,
    link: 'https://www.aquasys.fr/',
    // style: { width: '15%' },
}
