module.exports = {
    RECEIVE_ALL_APPLICATION_HABILITATION: 'RECEIVE_ALL_APPLICATION_HABILITATION',
    QUALITO: 'QUALITO',
    PIEZO: 'PIEZO',
    PLUVIO: 'PLUVIO',
    HYDRO: 'HYDRO',
    PRODUCTION: 'PRODUCTION',
    DISTRIBUTION: 'DISTRIBUTION',
    INSTALLATION: 'INSTALLATION',
    MAT: 'MAT',
    CARTO: 'CARTO',
    GEMAPI: 'GEMAPI',
    FORBIDEN: 'FORBIDEN',
    ALL: 'ALL',
    MAT_FORBIDEN: 'MAT_FORBIDEN',
    QUALITO_FORBIDEN: 'QUALITO_FORBIDEN',
    PIEZO_FORBIDEN: 'PIEZO_FORBIDEN',
    HYDRO_FORBIDEN: 'HYDRO_FORBIDEN',
    PLUVIO_FORBIDEN: 'PLUVIO_FORBIDEN',
    PRODUCTION_FORBIDEN: 'PRODUCTION_FORBIDEN',
    DISTRIBUTION_FORBIDEN: 'DISTRIBUTION_FORBIDEN',
    INSTALLATION_FORBIDEN: 'INSTALLATION_FORBIDEN',
    GEMAPI_FORBIDEN: 'GEMAPI_FORBIDEN',
}
