import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import User from '../pages/account/dto/User'
import ContactDto from '../pages/referencial/components/contact/dto/ContactDto'
import ContactAction from '../pages/referencial/components/contact/actions/ContactAction'
import HomeAction from '../pages/home/actions/HomeAction'
import { applicationName } from '../conf/AppConstants'
import { getSetting } from '../utils/SettingUtils'
import AdministrationAction from '../pages/administration/actions/AdministrationAction'
import SieauParameterDto from '../pages/administration/dto/SieauParameterDto'
import { faqUrl } from '../conf/SieauConstants'

const TooltipStyled = withStyles({
    tooltip: {
        fontSize: '1rem',
    },
})(Tooltip)

class BubbleHelp extends Component {
    constructor(props) {
        super(props)
        this.props.fetchApplicationSettings()
    }

    componentDidMount() {
        document.title = getSetting(this.props.applicationSettings, 'applicationTitle') || applicationName
    }

    componentDidUpdate() {
        document.title = getSetting(this.props.applicationSettings, 'applicationTitle') || applicationName
    }

    handleOpen = (link) => {
        window.open(link, '_blank')
    }

    render() {
        const { helpPath } = this.props
        return (
            <>
                {this.props.children
                    && React.cloneElement(this.props.children, {
                        getLink: this.getLink,
                    })}
                <div className="fixed-action-btn">
                    <TooltipStyled title={i18n.goToOnlineHelp} placement="top" arrow>
                        <button
                            type="button"
                            id="menu"
                            className="btn_help btn-floating btn-large secondary-color-back"
                            onClick={() => this.handleOpen(helpPath)}
                        >
                            <i className="material-icons" style={{ fontSize: '3rem' }}>help</i>
                        </button>
                    </TooltipStyled>
                </div>
            </>
        )
    }
}

BubbleHelp.defaultProps = {
    modules: [
        {
            name: 'SIQ',
            folder: 'docs_siq',
        },
        {
            name: 'SIES',
            folder: 'docs_sies',
        },
        {
            name: 'SIH',
            folder: 'docs_sih',
        },
        {
            name: 'SIP',
            folder: 'docs_sip',
        },
        {
            name: 'SIADM',
            folder: 'docs_siadm',
        },
        {
            name: 'SIG',
            folder: 'docs_sig',
        },
        {
            name: 'SIMAT',
            folder: 'docs_simat',
        },
    ],
    defaultPage: '',
    defaultLink: '',
    helpPath: faqUrl,
    contact: PropTypes.instanceOf(ContactDto),
    accountUser: PropTypes.instanceOf(User),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
}

const mapStateToProps = (store) => ({
    helpPath: store.HomeReducer.helpPath,
    contact: store.ContactReducer.contact,
    accountUser: store.AccountReducer.accountUser,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    fetchContact: ContactAction.fetchContact,
    sendMailToSupport: HomeAction.sendMailToSupport,
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(BubbleHelp)
