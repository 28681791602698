import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ol from 'openlayers'
import Grid from '@material-ui/core/Grid'
import NumberField from '../../../../components/forms/NumberField'
import OSMSatellite from '../../../../utils/mapUtils/layers/OSMSatellite'
import OlMap from '../../../../utils/mapUtils/Map'

const LabelWrapper = styled.span`
    display: inline-block;
    margin: 0.5rem;
`

class MapEventPanel extends Component {
    state = {
        readMode: false,
        x: null,
        y: null,
    }

    changeMap = (value) => {
        const point = ol.proj.transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const x_coordinate = point[0]
        const y_coordinate = point[1]
        this.setState({ x: x_coordinate, y: y_coordinate })
        this.props.changeLong(x_coordinate)
        this.props.changeLat(y_coordinate)
    }

    render() {
        const { readMode, consultMode, zoom, size, long, lat } = this.props
        const x_coordinate = long
        const y_coordinate = lat
        if (!readMode && !consultMode) {
            return (
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    className="row"
                >
                    <Grid xs={4} container direction="column" className="noMargin">
                        <LabelWrapper>{i18n.longitude}&nbsp;:</LabelWrapper>
                        <NumberField value={x_coordinate} readMode={this.state.readMode} />
                        <LabelWrapper>{i18n.latitude}&nbsp;:</LabelWrapper>
                        <NumberField value={y_coordinate} readMode={this.state.readMode} />
                    </Grid>
                    <Grid item xs={7}>
                        <OlMap
                            mapConf={{
                                center: [x_coordinate, y_coordinate],
                                zoom: zoom || 7,
                                size: size || 300,
                            }}
                            layers={[OSMSatellite()]}
                            onMoveEnd={this.changeMap}
                            marker
                            readMode={readMode}
                            consultMode={consultMode}
                        />
                    </Grid>
                </Grid>
            )
        }
        return (
            <OlMap
                mapConf={{
                    center: [x_coordinate, y_coordinate],
                    zoom,
                    size,
                }}
                layers={[OSMSatellite()]}
                marker
                readMode={readMode}
                consultMode={consultMode}
            />
        )
    }
}

MapEventPanel.propTypes = {
    readMode: PropTypes.bool,
    zoom: PropTypes.number,
    size: PropTypes.number,
}

MapEventPanel.defaultProps = {
    readMode: false,
    zoom: 10,
    size: 300,
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(MapEventPanel)
