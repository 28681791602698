import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import Grid from '@material-ui/core/Grid'
import M from 'materialize-css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tabs from '@material-ui/core/Tabs'
import ToastrAction from '../../utils/ToastrAction'
import Input from '../../components/forms/InputLogin'
import HomeFooter from './components/Footer'
import HomeHeader from './components/Header'
import HomeSlide from './components/Slide'
import AppStore from '../../store/Appstore'
import { getSetting } from '../../utils/SettingUtils'
import HomeAction from './actions/HomeAction'
import SettingDto from '../administration/dto/SettingDto'
import { TabStyle } from '../../components/TabStyle'
import { mainBlue } from '../../styles/theme'

class ResetPasswordApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultBackground: false,
            login: null,
            email: null,
            error: false,
            tab: 'login',
        }
    }

    componentDidMount() {
        const elems = document.querySelectorAll('.slider')
        const options = {
            indicators: false,
            duration: 1000,
            interval: 4000,
        }
        M.Slider.init(elems, options)
    }

    componentWillReceiveProps(nextProps) {
        if (
            getSetting(
                this.props.applicationSettings,
                'applicationLogoConnexion',
            )
            !== getSetting(
                nextProps.applicationSettings,
                'applicationLogoConnexion',
            )
        ) {
            this.setState({ error: false })
        }
    }

    onValidatingPassword = (e) => {
        e.preventDefault()
        const newPassword = this.refs.newPassword.refs.newPassword.value
        const newPasswordAgain = this.refs.newPasswordAgain.refs
            .newPasswordAgain.value
        const regex = new RegExp(
            getSetting(this.props.applicationSettings, 'passwordPolicy'),
        )
        if (newPassword !== newPasswordAgain) {
            ToastrAction.error(i18n.confirmPasswordError)
        } else if (!regex.test(newPassword)) {
            ToastrAction.error(i18n.passwordPolicyError)
        } else {
            AppStore.dispatch(
                HomeAction.updatePassword(
                    newPassword,
                    this.props.location.search.replace('?token=', ''),
                ),
            )
        }
    }

    onImageError = (e) => {
        if (e && !this.state.error) {
            this.setState({ error: true })
        }
    }

    getTab = () => {
        const { tab, login, email } = this.state
        if (tab === 'login') {
            return (
                <div name="registration">
                    <div className="row" />
                    <div className="row valign-wrapper">
                        <i className="padding-glyph material-icons input_home">
                            person
                        </i>
                        <input
                            className="sieau-input form-control"
                            refChild="login"
                            type="text"
                            placeholder="Identifiant"
                            onChange={(e) => this.setState({ login: e.target.value })}
                            value={login}
                            required
                        />
                    </div>
                    <div className="row valign-wrapper">
                        <button
                            className={[
                                'btn',
                                'waves-effect',
                                'waves-light',
                                'col',
                                's12',
                                'valign',
                            ].join(' ')}
                            onClick={this.onSubmit}
                        >
                            {i18n.retrievePassword} via mon identifiant
                        </button>
                    </div>
                </div>
            )
        }
        return (
            <div name="registration">
                <div className="row" />
                <div className="row valign-wrapper">
                    <i className="padding-glyph material-icons input_home">
                        email
                    </i>
                    <input
                        className="sieau-input form-control"
                        refChild="email"
                        onChange={(e) => this.setState({ email: e.target.value })}
                        value={email}
                        type="text"
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="row valign-wrapper">
                    <button
                        className={[
                            'btn',
                            'waves-effect',
                            'waves-light',
                            'col',
                            's12',
                            'valign',
                        ].join(' ')}
                        onClick={this.onSubmit}
                    >
                        {i18n.retrievePassword} via mon email
                    </button>
                </div>
            </div>
        )
    }

    getPanel = (token) => {
        if (token) {
            return (
                <form name="registration">
                    <div className="row" />
                    <div className="row no-margin padding-bottom-1">
                        <span>
                            {getSetting(
                                this.props.applicationSettings,
                                'securityPasswordDescription',
                            )}
                        </span>
                    </div>
                    <div className="row no-margin">
                        <Input
                            refChild="newPassword"
                            ref="newPassword"
                            type="password"
                            required
                        >
                            {i18n.newPassword}
                        </Input>
                    </div>
                    <div className="row no-margin">
                        <Input
                            refChild="newPasswordAgain"
                            ref="newPasswordAgain"
                            type="password"
                            required
                        >
                            {i18n.newPasswordAgain}
                        </Input>
                    </div>
                    <div className="row valign-wrapper">
                        <button
                            className="btn waves-effect waves-light col s12 valign"
                            onClick={this.onValidatingPassword}
                        >
                            {i18n.validate}
                        </button>
                    </div>
                </form>
            )
        }
        const { tab } = this.state
        return (
            <div style={{ margin: '1rem 0 2rem' }}>
                <div className="row no-margin center">
                    <span className="title_home">{i18n.forgetPassword}</span>
                </div>
                <div className="row no-margin">
                    <p className="resetPwdText">{i18n.forgetPasswordText}</p>
                </div>
                <Tabs
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                    style={{
                        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
                    }}
                    variant="fullWidth"
                    value={tab}
                    onChange={(e, v) => this.setState({ tab: v })}
                    aria-label="tabs"
                >
                    <TabStyle value="login" label={i18n.login} />
                    <TabStyle value="email" label={i18n.email} />
                </Tabs>
                <div style={{ border: `${mainBlue} solid`, padding: 8 }}>
                    {this.getTab()}
                </div>
            </div>
        )
    }

    onSubmit = (e) => {
        const { tab, login, email } = this.state
        e.preventDefault()
        if (tab === 'login') {
            this.props.resetPassword(login)
        } else {
            this.props.resetPasswordByEmail(email)
        }
    }

    render() {
        const { applicationSettings } = this.props
        const query = this.props.location
        const token = query.search.replace('?token=', '')
        return (
            <div>
                <div
                    className="row"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                        margin: '0',
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <HomeSlide />
                        <div className="home_content">
                            <div className="home_card">
                                <div>
                                    <HomeHeader page="home" />
                                    <div
                                        className="row"
                                        style={{
                                            paddingTop: '20%',
                                            paddingLeft: '20%',
                                            paddingRight: '20%',
                                        }}
                                    >
                                        {applicationSettings.length && this.getPanel(token)}
                                    </div>
                                </div>
                            </div>
                            <HomeFooter />
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}

ResetPasswordApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
    resetPassword: PropTypes.func,
    resetPasswordByEmail: PropTypes.func,
}

const mapDispatchToProps = {
    resetPassword: HomeAction.resetPassword,
    resetPasswordByEmail: HomeAction.resetPasswordByEmail,
    push,
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordApp)
