import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'

const ColorfulCard = ({
    objectId,
    color,
    className = '',
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
    children,
}) => (
    <Card
        className="cursor cardStyle"
        onClick={onClick}
        onMouseOver={() => onMouseOver(objectId)}
        onMouseOut={() => onMouseOut(objectId)}
        style={{ width: '100%' }}
    >
        {children}
    </Card>
)

ColorfulCard.propTypes = {
    objectId: PropTypes.object,
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    children: PropTypes.element.isRequired,
}

ColorfulCard.defaultProps = {
    onMouseOver: () => {},
    onMouseOut: () => {},
}

export default ColorfulCard
