/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import i18n from 'simple-react-i18n'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { uniq, uniqBy } from 'lodash'
import { DialogActions, Grid } from '@material-ui/core'
import { grey, mainPink } from '../../styles/theme'
import AgGridTable from '../../components/datatable/AgGridTable'
import Input from '../../components/forms/Input'
import { GreenButton, GreenUploadLabel } from '../../styles/button'
import DtoZone from '../referencial/components/zones/dto/DtoZone'
import CultureDto from '../referencial/components/cultures/dto/CultureDto'
import ModeConduiteDto from '../referencial/components/conduites/dto/ModeConduiteDto'
import ReferencialAction from '../referencial/action/ReferencialAction'
import { getLogin } from '../../utils/SettingUtils'
import ToastrAction from '../../utils/ToastrAction'
import { DldIcon, DldIconText } from '../../styles/text'
import { modesConduitesParentsList, seasonList } from '../referencial/constants/ReferencialConstants'
import { PinkBloc } from '../../styles/container'

const ModalTitle = styled.h5`
    font-size: 1.5rem;
    font-weight: 600;
`

const Subtitle = styled.p`
    margin: 0.5rem 0;
`

const TextStyle = styled.div`
    margin: 2rem 0;
    font-size: 1.6rem;
    text-decoration: none;
    color: ${grey};
    &:hover {
        color: black;
    }
`

const IconStyle = styled(Icon)`
    font-size: 3rem !important;
    color: ${grey};
    opacity: 0.6;
    margin: 23px 15px 0 0 !important;
    &:hover {
        opacity: 1;
    }
`

const MoreIcon = styled(IconStyle)`
    font-size: 3rem !important;
    margin: 40px 13px 0 0 !important;
`

const PinkTitle = styled.legend`
    height: 30px;
    font-size: 1.4rem;
    color: ${mainPink};
    background-color: white;
    padding: 0 10px;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

export function getModalStyle() {
    const top = 60
    const left = 60

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '10px 20px 0px',
    },
}))

const tableStyle = {
    height: '30vh',
    width: '100%',
    marginBottom: '3.5rem',
}

const tableStyleUsers = {
    height: '60vh',
    width: '100%',
    paddingBottom: '50px',
}

const inputStyle = {
    width: '200px',
    height: '48px',
}

const noMargin = {
    margin: '0 !important',
}

const ModalElementaryBricks = ({
    onCreateData,
    iconName,
    number,
    text,
    user,
    usersData,
    zone,
    zonesData,
    culture,
    culturesData,
    driveMode,
    conduitesData,
    onChange,
    selectedUsers,
    selectedZones,
    selectedCultures,
    selectedModesConduites,
    onSave,
    currentUsers,
    currentZones,
    currentCultures,
    currentModesConduites,
    clickable,
    addField,
    typeKey,
    objectifId,
    isEditMode,
    territoryId,
}) => {
    const classes = useStyles()
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle)
    const [open, setOpen] = useState(false)
    const [newNameZone, setNewNameZone] = useState('nouvelleZone')
    const [newKmlZone, setNewKmlZone] = useState()
    const [newKmlFileName, setNewKmlFileName] = useState('')
    const [newNameCulture, setNewNameCulture] = useState('nouvelleCulture')
    const [newPeriodeCulture, setNewPeriodeCulture] = useState(0)
    const [newRotationCulture, setNewRotationCulture] = useState(false)
    const [newLegumineuseCulture, setNewLegumineuseCulture] = useState(false)
    const [newNameConduite, setNewNameConduite] = useState('nouveauMode')
    const [newParentConduite, setNewParentConduite] = useState(1)
    const [users, setUsers] = useState(usersData)
    const [zones, setZones] = useState(zonesData)
    const [cultures, setCultures] = useState(culturesData)
    const [conduites, setConduites] = useState(conduitesData)
    const [createdZones, setCreatedZones] = useState([])
    const [createdCultures, setCreatedCultures] = useState([])
    const [createdConduites, setCreatedConduites] = useState([])

    const dispatch = useDispatch()

    const setNewConduites = (newConduites) => {
        const conduitesWithParents = newConduites.map((c) => {
            const parent = modesConduitesParentsList.find((p) => p.value === c.parent)
            if (parent) {
                return {
                    ...c,
                    parent: parent.label,
                }
            }
            return c
        })
        setConduites(conduitesWithParents)
    }

    const setNewCultures = (newCultures) => {
        const culturesWithSeason = newCultures.map((c) => {
            const season = seasonList.find((p) => p.value === c.periodeSemence)
            if (season) {
                return {
                    ...c,
                    periodeSemence: season.label,
                }
            }
            return c
        })
        setCultures(culturesWithSeason)
    }

    useEffect(() => {
        if (zonesData && zonesData.length > 0) {
            setZones(zonesData)
        }
        if (culturesData && culturesData.length > 0) {
            setNewCultures(culturesData)
        }
        if (usersData && usersData.length > 0) {
            setUsers(usersData)
        }
        if (conduitesData && conduitesData.length > 0) {
            setNewConduites(conduitesData)
        }
    }, [zonesData, culturesData, conduitesData, usersData])

    const columnDefsZones = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { headerName: i18n.name, field: 'name' },
        {
            headerName: i18n.referencial,
            field: 'referentiel',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        { headerName: 'KML', field: 'kml' },
        {
            headerName: i18n.user,
            field: 'usermaj',
        },
    ]

    const columnDefsUsers = [
        {
            headerName: i18n.login,
            field: 'login',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { headerName: i18n.role, field: 'role' },
    ]

    const columnDefsConduites = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { headerName: i18n.name, field: 'name' },
        { headerName: i18n.parent, field: 'parent' },
        {
            headerName: i18n.referencial,
            field: 'referentiel',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.user,
            field: 'usermaj',
        },
    ]

    const columnDefsCultures = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        {
            headerName: i18n.name,
            field: 'name',
        },
        {
            headerName: i18n.teteRotation,
            field: 'teteRotation',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.legumineuse,
            field: 'legumineuse',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.periodeSemence,
            field: 'periodeSemence',
        },
        {
            headerName: i18n.referencial,
            field: 'referentiel',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.user,
            field: 'usermaj',
        },
    ]

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const closeAndReset = (type) => {
        handleClose()
        onChange(type, [])
    }

    const closeAndSave = (type, value) => {
        handleClose()
        onSave(type, value.length ? uniq(value) : value)
    }

    const onCreateZone = () => {
        const newZone = new DtoZone({
            id: -1,
            name: newNameZone,
            referentiel: false,
            kml: newKmlZone,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createZone(newZone)).then((id) => {
            const newCreatedZones = [...createdZones, { ...newZone, id }]
            setCreatedZones(newCreatedZones)
            if (territoryId) {
                dispatch(ReferencialAction.fetchZonesTerritoire(territoryId)).then((newZones) => {
                    onCreateData('zones', id)
                    setZones(uniqBy([...newZones, ...newCreatedZones], 'id'))
                })
            } else {
                dispatch(ReferencialAction.fetchZones()).then((newZones) => {
                    onCreateData('zones', id)
                    setZones(uniqBy([...newZones, ...newCreatedZones], 'id'))
                })
            }
        })
    }

    const onCreateCulture = () => {
        const newCulture = new CultureDto({
            id: -1,
            name: newNameCulture,
            teteRotation: newRotationCulture,
            legumineuse: newLegumineuseCulture,
            periodeSemence: newPeriodeCulture,
            referentiel: false,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createCulture(newCulture)).then((id) => {
            const newCreatedCultures = [...createdCultures, { ...newCulture, id }]
            setCreatedCultures(newCreatedCultures)
            if (territoryId) {
                dispatch(ReferencialAction.fetchCulturesTerritoire(territoryId)).then((newCultures) => {
                    onCreateData('cultures', id)
                    setNewCultures(uniqBy([...newCultures, ...newCreatedCultures], 'id'))
                })
            } else {
                dispatch(ReferencialAction.fetchCultures()).then((newCultures) => {
                    onCreateData('cultures', id)
                    setNewCultures(uniqBy([...newCultures, ...newCreatedCultures], 'id'))
                })
            }
        })
    }

    const onCreateConduite = () => {
        const newConduite = new ModeConduiteDto({
            id: -1,
            name: newNameConduite,
            parent: newParentConduite,
            referentiel: false,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createConduite(newConduite)).then((id) => {
            const newCreatedConduites = [...createdConduites, { ...newConduite, id }]
            setCreatedConduites(newCreatedConduites)
            if (territoryId) {
                dispatch(ReferencialAction.fetchModesConduitesTerritoire(territoryId)).then((newConduites) => {
                    onCreateData('conduites', id)
                    setNewConduites(uniqBy([...newConduites, ...newCreatedConduites], 'id'))
                })
            } else {
                dispatch(ReferencialAction.fetchConduites()).then((newConduites) => {
                    onCreateData('conduites', id)
                    setNewConduites(uniqBy([...newConduites, ...newCreatedConduites], 'id'))
                })
            }
        })
    }

    const onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            const file = event.target.files[0]
            reader.readAsText(file, 'UTF-8')
            reader.onload = (e) => {
                setNewKmlZone(e.target.result)
                setNewKmlFileName(file.name)
            }
            reader.onerror = () => dispatch(ToastrAction.error('Erreur lors de la lecture du fichier'))
        }
    }
    
    const getTable = (
        key,
        columnDefs = [],
        rowsData = [],
        selectedData = [],
        othersOptions = { selectedDataKey: 'dataId' },
    ) => (
        <AgGridTable
            exportable={false}
            columnDefs={columnDefs}
            rowData={rowsData}
            onChange={onChange}
            tableKey={key}
            selectedData={selectedData}
            {...othersOptions}
        />
    )

    return (
        <>
            <div>
                <Grid container alignItems="center">
                    <div onClick={clickable ? handleOpen : () => {}} className={clickable ? 'clickable' : ''}>
                        <IconStyle>{iconName}</IconStyle>
                    </div>
                    <div onClick={clickable ? handleOpen : () => {}} className={clickable ? 'clickable' : ''}>
                        <TextStyle>
                            <span>{number}</span>&nbsp;
                            <span>{text}</span>
                        </TextStyle>
                    </div>
                </Grid>
                <Modal
                    style={{ width: '100%' }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={handleClose}
                    disableBackdropClick
                >
                    <div style={modalStyle} className={classes.paper}>
                        {user && (
                            <>
                                <ModalTitle style={{ margin: '0.5rem 0' }}>{i18n.users}</ModalTitle>
                                <div className="ag-theme-balham" style={tableStyleUsers}>
                                    {getTable('currentUsers', columnDefsUsers, users, selectedUsers, { users, rowDataKey: 'login', selectedDataKey: 'login' })}
                                </div>
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(
                                                    this,
                                                    typeKey || 'utilisateurs',
                                                )}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'utilisateurs',
                                                    objectifId || currentUsers,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton onClick={handleClose} borderRadius>
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                        {zone && (
                            <>
                                <ModalTitle>{i18n.addOrCreateZones}</ModalTitle>
                                <Subtitle>{i18n.territoryZonesList}&nbsp;:</Subtitle>
                                <div className="ag-theme-balham" style={tableStyle}>
                                    {getTable('currentZones', columnDefsZones, zones, selectedZones)}
                                </div>
                                {addField && (
                                    <PinkBloc>
                                        <PinkTitle>{i18n.createNewZone}</PinkTitle>
                                        <Grid container alignItems="center" style={{ padding: 10 }}>
                                            <div>
                                                <Label htmlFor="zone_name">
                                                    {i18n.name}&nbsp;:
                                                </Label>
                                                <Input
                                                    style={inputStyle}
                                                    value={newNameZone}
                                                    onChange={(event) => setNewNameZone(event)}
                                                />
                                            </div>
                                            <Grid item xs={3} container direction="column">
                                                <input
                                                    name="file"
                                                    accept=".kml, .KML"
                                                    id="uploadKMLZone"
                                                    type="file"
                                                    className="uploadFileInput"
                                                    onChange={onUploadFile}
                                                />
                                                {newKmlFileName || 'Sélectionner un fichier :'}
                                                <GreenUploadLabel for="uploadKMLZone">
                                                    <DldIcon>file_upload</DldIcon>&nbsp;&nbsp;
                                                    <DldIconText>Fichier KML</DldIconText>
                                                </GreenUploadLabel>
                                            </Grid>
                                            <MoreIcon
                                                className="no-margin"
                                                style={noMargin}
                                                onClick={onCreateZone}
                                            >
                                                add_box
                                            </MoreIcon>
                                        </Grid>
                                    </PinkBloc>
                                )}
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(this, 'currentZones')}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'zones',
                                                    objectifId || currentZones,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton
                                            onClick={closeAndReset.bind(this, 'currentZones')}
                                            borderRadius
                                        >
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                        {culture && (
                            <>
                                <ModalTitle>{i18n.addOrCreateCultures}</ModalTitle>
                                <Subtitle>{i18n.territoryCulturesList}&nbsp;:</Subtitle>
                                <div className="ag-theme-balham" style={tableStyle}>
                                    {getTable('currentCultures', columnDefsCultures, cultures, selectedCultures)}
                                </div>
                                {addField && (
                                    <PinkBloc>
                                        <PinkTitle>{i18n.createNewCulture}</PinkTitle>
                                        <Grid container alignItems="center" style={{ padding: 10 }}>
                                            <div>
                                                <Label htmlFor="culture_name">
                                                    {i18n.name}&nbsp;:
                                                </Label>
                                                <Input
                                                    style={inputStyle}
                                                    value={newNameCulture}
                                                    onChange={(event) => setNewNameCulture(event)}
                                                />
                                            </div>
                                            <FormControl variant="outlined">
                                                <Label htmlFor="semence">
                                                    {i18n.sowingDate}&nbsp;:
                                                </Label>
                                                <Select
                                                    name="semence"
                                                    value={newPeriodeCulture}
                                                    onChange={(v) => setNewPeriodeCulture(v.target.value)}
                                                >
                                                    {seasonList.map((item) => (
                                                        <MenuItem value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    style={{
                                                        transform: 'translate(10px, 13px)',
                                                    }}
                                                    control={(
                                                        <Checkbox
                                                            checked={newRotationCulture}
                                                            onChange={(v) => setNewRotationCulture(
                                                                v.target.checked,
                                                            )}
                                                            value="rotation"
                                                            color="primary"
                                                        />
                                                    )}
                                                    label="Tête de rotation"
                                                />
                                                <FormControlLabel
                                                    style={{
                                                        transform: 'translate(10px, 13px)',
                                                    }}
                                                    control={(
                                                        <Checkbox
                                                            checked={newLegumineuseCulture}
                                                            onChange={(v) => setNewLegumineuseCulture(
                                                                v.target.checked,
                                                            )}
                                                            value="legumineuse"
                                                            color="primary"
                                                        />
                                                    )}
                                                    label="Légumineuse"
                                                />
                                            </FormGroup>
                                            <MoreIcon
                                                className="no-margin"
                                                style={noMargin}
                                                onClick={onCreateCulture}
                                            >
                                                add_box
                                            </MoreIcon>
                                        </Grid>
                                    </PinkBloc>
                                )}
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(
                                                    this,
                                                    'currentCultures',
                                                )}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'cultures',
                                                    objectifId || currentCultures,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton
                                            onClick={closeAndReset.bind(this, 'currentCultures')}
                                            borderRadius
                                        >
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                        {driveMode && (
                            <>
                                <ModalTitle>{i18n.addOrCreateDrivenMode}</ModalTitle>
                                <Subtitle>{i18n.territoryDrivenModeList}&nbsp;:</Subtitle>
                                <div className="ag-theme-balham" style={tableStyle}>
                                    {getTable('currentModesConduites', columnDefsConduites, conduites, selectedModesConduites)}
                                </div>
                                {addField && (
                                    <PinkBloc>
                                        <PinkTitle>{i18n.createNewDrivenMode}</PinkTitle>
                                        <Grid container alignItems="center" style={{ padding: 10 }}>
                                            <div>
                                                <Label htmlFor="name">{i18n.name}&nbsp;:</Label>
                                                <Input
                                                    style={inputStyle}
                                                    value={newNameConduite}
                                                    onChange={(event) => setNewNameConduite(event)}
                                                />
                                            </div>
                                            <div>
                                                <FormControl variant="outlined">
                                                    <Label htmlFor="rotate">
                                                        {i18n.inheritedRotationRule}&nbsp;:
                                                    </Label>
                                                    <Select
                                                        name="rotate"
                                                        value={newParentConduite}
                                                        onChange={(v) => setNewParentConduite(v.target.value)}
                                                    >
                                                        {modesConduitesParentsList.map((item) => (
                                                            <MenuItem value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <MoreIcon
                                                className="no-margin"
                                                style={noMargin}
                                                onClick={onCreateConduite}
                                            >
                                                add_box
                                            </MoreIcon>
                                        </Grid>
                                    </PinkBloc>
                                )}
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(
                                                    this,
                                                    'currentModesConduites',
                                                )}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'modesConduites',
                                                    objectifId || currentModesConduites,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton
                                            onClick={closeAndReset.bind(
                                                this,
                                                'currentModesConduites',
                                            )}
                                            borderRadius
                                        >
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    )
}

ModalElementaryBricks.defaultProps = {
    user: false,
    zone: false,
    culture: false,
    driveMode: false,
    selectedZones: [],
    selectedCultures: [],
    selectedModesConduites: [],
    currentZones: [],
    currentCultures: [],
    currentModesConduites: [],
    clickable: true,
    addField: true,
    typeKey: '',
    objectifId: null,
    isEditMode: true,
}

ModalElementaryBricks.propTypes = {
    iconName: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    zone: PropTypes.bool,
    user: PropTypes.bool,
    culture: PropTypes.bool,
    driveMode: PropTypes.bool,
    usersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    zonesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    culturesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    conduitesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    selectedZones: PropTypes.arrayOf(PropTypes.shape({})),
    selectedCultures: PropTypes.arrayOf(PropTypes.shape({})),
    selectedModesConduites: PropTypes.arrayOf(PropTypes.shape({})),
    currentZones: PropTypes.arrayOf(PropTypes.number),
    currentCultures: PropTypes.arrayOf(PropTypes.number),
    currentModesConduites: PropTypes.arrayOf(PropTypes.number),
    clickable: PropTypes.bool,
    addField: PropTypes.bool,
    typeKey: PropTypes.string,
    objectifId: PropTypes.number,
    isEditMode: PropTypes.bool,
    territoryId: PropTypes.number,
    matriceId: PropTypes.number,
    onCreateData: PropTypes.func,
    selectedUsers: PropTypes.arrayOf(PropTypes.shape({})),
    currentUsers: PropTypes.arrayOf(PropTypes.shape({})),
}

export default ModalElementaryBricks
