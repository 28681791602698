import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import CardList from './CardList'

class GlobalCardList extends Component {
    getTitle = (nbCard) => {
        if (this.props.title) {
            return (
                <div>
                    { this.props.title } ({ `${nbCard} ${i18n.element}`}{ (nbCard > 1) && 's' })
                </div>
            )
        }
        return null
    }

    render() {
        const sortedKeys = Object.keys(this.props.data).sort((a, b) => b - a)
        const data = sortedKeys.map((key) => this.props.data[key])
        const nbCard = data.sort().reduce((res, sl) => sl.cards.length + res, 0)
        return (
            <List>
                { this.getTitle(nbCard) }
                <CardList
                    data={data}
                    collapsible={this.props.collapsible}
                    dontOpenFirst={this.props.dontOpenFirst}
                />
            </List>
        )
    }
}

GlobalCardList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        cards: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            onClick: PropTypes.func,
            content: PropTypes.element.isRequired,
        })).isRequired,
    })).isRequired,
    title: PropTypes.string,
    collapsible: PropTypes.bool,
    dontOpenFirst: PropTypes.bool,
}

export default GlobalCardList
