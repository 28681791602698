const optionsDefault = {
    positionClass: 'toast-top-right',
}

const isHome = () => {
    const { href } = window.location
    return href.includes('/login') || href.includes('/home')
}

const ToastrAction = {
    success(message, forceShow, options) {
        if (!isHome() || forceShow) {
            window.toastr.options = { ...optionsDefault, ...options }
            window.toastr.success(message, '')
        }
    },
    info(message, forceShow, options) {
        if (!isHome() || forceShow) {
            window.toastr.options = { ...optionsDefault, ...options }
            window.toastr.info(message, '')
        }
    },
    warning(message, forceShow, options) {
        if (!isHome() || forceShow) {
            window.toastr.options = { ...optionsDefault, ...options }
            window.toastr.warning(message, '')
        }
    },
    error(message, forceShow, options) {
        if (!isHome() || forceShow) {
            window.toastr.options = { ...optionsDefault, ...options }
            window.toastr.error(message, '')
        }
    },
}

export default ToastrAction
