import {
    RECEIVE_ZONES_COUNT,
    RECEIVE_CULTURES_COUNT,
    RECEIVE_CONDUITES_COUNT,
    RECEIVE_COMMUNES_COUNT,
    RECEIVE_INDICATEURS_COUNT,
    RECEIVE_ROTATIONS_COUNT,
    RECEIVE_RPG_COUNT,
    RECEIVE_MATRICE_REF_COUNT,
} from '../constants/DashboardConstants'
import {
    PATH_REFERENCIAL_CONDUITES,
    PATH_REFERENCIAL_ZONES,
    PATH_REFERENCIAL_CULTURES,
    PATH_REFERENCIAL_COMMUNES,
    PATH_REFERENCIAL_INDICATEURS,
    PATH_REFERENCIAL_ROTATION,
    PATH_REFERENCIAL_RPG,
    PATH_REFERENCIAL_MATRICE_REF,
} from '../../../../home/constants/RouteConstants'

export function DashboardReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ZONES_COUNT:
            return {
                ...state,
                zones: {
                    ...state.zones,
                    number: parseInt(action.zonesCount || 0),
                },
            }
        case RECEIVE_CULTURES_COUNT:
            return {
                ...state,
                cultures: {
                    ...state.cultures,
                    number: parseInt(action.culturesCount || 0),
                },
            }
        case RECEIVE_RPG_COUNT:
            return {
                ...state,
                rpg: {
                    ...state.rpg,
                    number: parseInt(action.rpgCount || 0),
                },
            }
        case RECEIVE_CONDUITES_COUNT:
            return {
                ...state,
                modes_conduites: {
                    ...state.modes_conduites,
                    number: parseInt(action.conduitesCount || 0),
                },
            }
        case RECEIVE_COMMUNES_COUNT:
            return {
                ...state,
                communes: {
                    ...state.communes,
                    number: parseInt(action.communesCount || 0),
                },
            }
        case RECEIVE_INDICATEURS_COUNT:
            return {
                ...state,
                indicateurs_performances: {
                    ...state.indicateurs_performances,
                    number: parseInt(action.indicateursCount || 0),
                },
            }
        case RECEIVE_ROTATIONS_COUNT:
            return {
                ...state,
                regles_rotation: {
                    ...state.regles_rotation,
                    number: parseInt(action.rotationsCount || 0),
                },
            }
        case RECEIVE_MATRICE_REF_COUNT:
            return {
                ...state,
                matriceRef: {
                    ...state.matriceRef,
                    number: parseInt(action.matriceRefCount || 0),
                },
            }
        default:
            return state
    }
}

export const store = {
    zones: {
        index: 1,
        id: 'ZONES',
        icon: 'map',
        url: 'zones',
        name: 'Zones',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_ZONES,
    },
    cultures: {
        index: 3,
        id: 'CULTURES',
        icon: 'local_florist',
        url: 'cultures',
        name: 'Cultures',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_CULTURES,
    },
    modes_conduites: {
        index: 2,
        id: 'MODES.CONDUITES',
        icon: 'speed',
        url: 'conduites',
        name: 'Modes de conduites',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_CONDUITES,
    },
    communes: {
        index: 0,
        id: 'COMMUNES',
        icon: 'location_city',
        url: 'communes',
        name: 'Communes',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_COMMUNES,
    },
    indicateurs_performances: {
        index: 4,
        id: 'INDICATEURS.PERFORMANCES',
        icon: 'show_chart',
        url: 'indicateurs',
        name: 'Indicateurs de performances',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_INDICATEURS,
    },
    regles_rotation: {
        index: 5,
        id: 'REGLES.ROTATION',
        icon: 'loop',
        url: 'rotation',
        name: 'Règles de rotation',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_ROTATION,
    },
    rpg: {
        index: 6,
        id: 'RPG',
        icon: 'border_all',
        url: 'rpg',
        name: 'RPG Explorer',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_RPG,
    },
    matriceRef: {
        index: 7,
        id: 'MATRICE.REF',
        icon: 'apps',
        url: 'matriceRef',
        name: 'Matrice de référence',
        date: '',
        number: 0,
        href: PATH_REFERENCIAL_MATRICE_REF,
    },
}
