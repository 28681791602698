import DtoReferencial from '../../../dto/DtoReferencial'

export default class CityDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = object.id
        this.labelWithCode = `${object.name} [${object.id}]`
        this.situation = object.situation
        this.departmentNumber = object.departmentNumber
        this.area = object.area
        this.countryCode = object.countryCode
        this.headers = ['code', 'name']
    }
}
