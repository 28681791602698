import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { groupBy, orderBy } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ProgressBar from '../../../components/ProgressBar'
import { hasValue } from '../../../utils/NumberUtil'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import ActionComponent from '../../../components/actions/ActionComponent'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import HomeAction from '../../home/actions/HomeAction'
import { getLabel } from '../../../utils/StoreUtils'
import { getArticleFormat, getYearDate } from '../../../utils/DateUtil'
import { getNewsColor } from '../../../utils/ColorUtil'
import { getUser } from '../../../utils/SettingUtils'
import { PATH_TERRITORY_MATRICE, PATH_TERRITORY_SCENARIO } from '../../home/constants/RouteConstants'
import { Flex } from '../../../styles/main'
import { lightGrey } from '../../../styles/theme'
import TerritoryAction from '../actions/TerritoryAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import ModalNewScenario from './ModalNewScenario'
import { setLocalStorageJson } from '../../../utils/FormUtils'
import { CCE_SCENARIOS_COMPARE } from '../constants/TerritoryConstants'

const MainWrapper = styled.div`
    padding: 0 8px;
    position: relative;
`

const ContentFooter = styled.div`
    background-color: ${lightGrey};
    padding: 0.2rem 1rem;
    border-bottom: 1px solid rgb(191, 191, 191);
`

const BodyContent = styled(Flex)`
    padding: 5px 0 5px 2.4rem;
`

const Wrapper = styled(Flex)`
    width: 100%;
`

const NoContentBodyContent = styled.div`
    padding: 1.5rem 0 0rem 2rem;
    display: flex;
    background-color: white;
    cursor: pointer;
    height: 60px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
`

const FooterText = styled.div`
    font-style: italic;
    font-size: 0.8rem;
`

const Title = styled.div`
    width: 40%;
    font-size: 1.3rem;
    font-weight: bold;
`

const TitleNoContent = styled.div`
    width: 80%;
    margin: 0px 0 10px 20px;
    font-size: 1.1rem;
`

const Text = styled.div`
    width: 55%;
    font-style: italic;
`

class ScenariosApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                level: -1,
                status: -1,
                searchValue: '',
            },
            tmpFilter: {
                level: -1,
                status: -1,
                searchValue: '',
            },
            dataLoaded: false,
            open: false,
            scenariosToCompare: [],
        }
    }

    componentDidMount() {
        this.props.fetchUsers()
        this.props.fetchScenariosMatrice(this.props.mid).then(() => {
            this.setState({ dataLoaded: true })
        })
        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        const { scenariosToCompare } = this.state
        const { territoire } = this.props
        if (territoire && territoire.statut !== 2 && (getUser().isAdmin === '1' || getUser().labo === '1')) {
            const compareAction = scenariosToCompare.length > 1 ? {
                compare: () => this.compareScenarios(),
            } : {}
            this.setActions({
                ...compareAction,
                new: () => this.modalIsOpen(),
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { categoryId } = this.state
        if (categoryId !== nextProps.categoryId) {
            this.setState({
                category: nextProps.categoryId,
                sortBy: 'updateDate',
            })
        }
    }

    compareScenarios = () => {
        const { scenariosToCompare } = this.state
        const { mid } = this.props
        setLocalStorageJson(CCE_SCENARIOS_COMPARE, scenariosToCompare)
        this.props.push(`${PATH_TERRITORY_MATRICE}/${mid}/scenariosCompare`)
    }

    onChangeSortBy = (event, sortById) => {
        this.setState({ sortBy: sortById })
        this.props.setSelectedSearchValues({ sortBy: sortById })
    }

    getContentRow = (scenario) => {
        const { scenariosToCompare } = this.state
        const find = !!scenariosToCompare.find((id) => id === scenario.id)
        const scenariosUpdated = find ? scenariosToCompare.filter((id) => id !== scenario.id) : [...scenariosToCompare, scenario.id]
        return (
            <MainWrapper key={scenario.id}>
                <BodyContent>
                    <Wrapper
                        onClick={() => {
                            this.props.push({
                                pathname: `${PATH_TERRITORY_SCENARIO}/${scenario.id}`,
                                state: {
                                    indicateurs: this.props.indicateurs,
                                },
                            })
                        }}
                    >
                        <Title>{scenario.name}</Title>
                        {scenario.description ? (
                            <Text>{scenario.description}</Text>
                        ) : (
                            <Text>{i18n.noDescriptionToShow}</Text>
                        )}
                    </Wrapper>
                    <Checkbox
                        checked={find}
                        disabled={scenariosToCompare.length >= 10 && !find}
                        onChange={() => this.setState({ scenariosToCompare: [...scenariosUpdated] })}
                    />
                </BodyContent>
                <ContentFooter>
                    <FooterText>
                        {i18n.createdBy}&nbsp;
                        {scenario.usermaj ? scenario.usermaj : <span>Inconnu</span>}&nbsp;
                        {i18n.the}&nbsp;
                        {getArticleFormat(scenario.datecreation)}
                    </FooterText>
                </ContentFooter>
            </MainWrapper>
        )
    }

    getSortByFunction = () => {
        const statusOptions = [
            { value: -1, label: i18n.all },
            { value: 1, label: i18n.published },
            { value: 2, label: i18n.draft },
            { value: 3, label: i18n.archived },
        ]
        switch (this.state.sortBy) {
            case 'updateDate':
                return (content) => getYearDate(content.dateCreation)
            case 'status':
                return (content) => getLabel(statusOptions, content.status, null, 'value')
            case 'category':
                return (content) => getLabel(this.props.cmsCategories, content.idCategory, 'title', 'id')
            default:
                return this.state.sortBy
        }
    }

    getData = (scenarios, title) => {
        const groups = groupBy(scenarios, this.getSortByFunction())
        return Object.keys(groups).reduce((acc, key) => {
            const size = groups[key].length
            const label = size > 1 ? i18n.elements : i18n.element
            const cards = groups[key].map((e) => ({
                content: this.getContentRow(e),
                color: hasValue(e.level) ? getNewsColor(e.level) : 'null',
                isFav: false,
            }))
            return {
                ...acc,
                [key]: {
                    title: `${title} (${size} ${label})`,
                    cards,
                },
            }
        }, {})
    }

    getResult = (filteredScenarios) => {
        const { scenarios } = this.props

        if (!scenarios.length) {
            return (
                <NoContentBodyContent>
                    <TitleNoContent>{i18n.noScenarioToShow}</TitleNoContent>
                </NoContentBodyContent>
            )
        }
        const scenariosTerritory = orderBy(filteredScenarios.filter((sc) => sc.scType === -1), 'datecreation', 'desc')
        const scenariosOther = orderBy(filteredScenarios.filter((sc) => sc.scType !== -1), 'datecreation', 'desc')
        return (
            <>
                <GlobalCardList style={{ padding: '8px 10px 10px' }} data={this.getData(scenariosTerritory, 'Territoire actuel')} collapsible />
                <GlobalCardList style={{ padding: '8px 10px 10px' }} data={this.getData(scenariosOther, i18n.scenarios)} collapsible />
            </>
        )
    }

    getFilteredScenarios = (scenarios = this.props.scenarios) => scenarios.filter((e) => ['name', 'userMaj']
        .map((key) => e[key])
        .join('   ')
        .toLowerCase())

    getScenarios = (scenarios) => this.getResult(this.getFilteredScenarios(scenarios))

    getStatusList = () => {
        const statusOptions = [
            { value: -1, label: i18n.all },
            { value: 1, label: i18n.published },
            { value: 2, label: i18n.draft },
            { value: 3, label: i18n.archived },
        ]
        return (
            <Grid item xs={3}>
                <InputLabel htmlFor="age-customized-select">Statut</InputLabel>
                <Select
                    title={i18n.status}
                    selected={this.state.tmpFilter.status}
                    onChange={(v) => this.onChangeFilter('status', v)}
                >
                    {statusOptions.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </Grid>
        )
    }

    handleChange = (event) => {
        this.setState({ level: event.target.value })
    }

    getActive = (panel) => (panel === this.state.sortBy ? 'active' : '')

    onChangeFilter = (filter, value) => {
        const { tmpFilter } = this.state
        const newObj = {}
        newObj[filter] = value
        if (filter === 'category') {
            newObj.sortBy = 'updateDate'
        }
        this.setState({ tmpFilter: { ...tmpFilter, ...newObj } })
    }

    onValidate = () => {
        const { tmpFilter } = this.state

        this.setState({ filter: { ...tmpFilter } })
        this.props.setSelectedSearchValues({
            category: tmpFilter.category,
        })
    }

    modalIsOpen = () => {
        this.setState({ open: true })
    }

    modalIsClose = () => {
        this.setState({ open: false })
    }

    render() {
        const { dataLoaded, open } = this.state
        const { mid, indicateurs, scenarios, applicationSettings } = this.props
        this.props.setHelpPath('', applicationSettings)
        return (
            <>
                {dataLoaded ? this.getScenarios(scenarios) : <ProgressBar />}
                {open ? (
                    <ModalNewScenario
                        open={open}
                        handleClose={this.modalIsClose}
                        mid={mid}
                        indicateurs={indicateurs}
                    />
                ) : ''}
            </>
        )
    }
}

ScenariosApp.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        globalResearch: PropTypes.string,
    }),
}

const mapStateToProps = (store) => ({
    scenarios: store.TerritoryReducer.scenarios,
    territoire: store.TerritoryReducer.territoire,
    users: store.UserReducer.users,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    fetchScenariosMatrice: TerritoryAction.fetchScenariosMatrice,
    fetchUsers: UserAction.fetchUsers,
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosApp)
