module.exports = {
    ACTIONS: 'ACTIONS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    CCE_TOKEN: 'CCE_TOKEN',
    CCE_LOGIN: 'CCE_LOGIN',
    DISCONNECTED: 'DISCONNECTED',
    SHOW_WELCOME_POPUP: 'SHOW_WELCOME_POPUP',
    TITLE: 'TITLE',
    HELP_PATH: 'HELP_PATH',
    CONTACT_SUPPORT_MAIL_SUBJECT: '[CCE]Demande de support',
    ASK_ACCOUNT_MAIL_SUBJECT: '[CCE]Demande de compte pour ',
    ASK_ACCOUNT_MAIL_MESSAGE: 'Informations fournies pour la cr&eacuteation du compte : ',
    CONTACT_MAIL_SUBJECT: '[CCE]Contact de ',
    MAIL_CONTENT: 'Contenu du message : ',
    TOKEN_CCE_LOGIN:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDgyNjAxMDc3NjEsImxvZ2luIjoiY21zX2NjZSIsImNvbm5lY3Rpb24iOiIyMDIyLTAzLTI1IDE1OjAxOjQ3IiwibGljZW5zZSI6WyJTSUciLCJTSVAiLCJTSU1BVCIsIlNJSCIsIlNJQURNIiwiU0lFUyIsIlNJUSIsIk1ZTElBUSJdfQ._YCrt3DutmanQradidm-3CjSSvofFU9RGDVyvbVrdqk',
}
