import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import theme from '../../styles/theme'
import AccountAction from '../account/actions/AccountAction'
import AdministrationAction from '../administration/actions/AdministrationAction'
import BubbleHelp from '../../components/BubbleHelp'
import AppBar from '../../components/navbar/AppBar'
import SlideNav from '../../components/navbar/SlideNav'
import ReferencialAction from '../referencial/action/ReferencialAction'

class App extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        openDrawer: false,
    }

    componentDidMount() {
        this.props.fetchSettings()
        this.props.fetchApplicationSettings()
        this.props.fetchSandreCodes()
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        this.setState({ openDrawer: open })
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <div style={{ margin: '4rem 0 0 0' }}>
                    <AppBar
                        toggleDrawer={this.toggleDrawer}
                        pathname={this.props.location.pathname}
                    />
                    <SlideNav
                        toggleDrawer={this.toggleDrawer}
                        history={this.props.history}
                        {...this.state}
                    />
                    <BubbleHelp />
                </div>
            </ThemeProvider>
        )
    }
}

App.propTypes = {
    children: PropTypes.element,
    fetchSettings: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchUser: PropTypes.func,
}

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchSettings: AdministrationAction.fetchSettings,
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
    fetchUser: AccountAction.fetchUser,
}

export default connect(
    null,
    mapDispatchToProps,
)(App)
