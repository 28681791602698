import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import AccountAction from '../pages/account/actions/AccountAction'
import { getFullDateMini } from '../utils/DateUtil'

class UpdatePanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cguDate: '',
            userCGUDate: '',
        }
        this.getCGU()
    }

    componentDidMount() {
        this.getUserCGU()
    }

    getCGU = () => {
        fetch('https://helpcenter.coclickeau.aquasys.fr/wp-json/wp/v2/pages/4740')
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ cguDate: new Date(jsonData.modified) })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    getUserCGU = () => {
        this.props.fetchAccountCGUDate(this.props.login).then((json = {}) => {
            if (new Date(json.date).toString() != this.state.userCGUDate.toString()) {
                if (json.date) {
                    this.setState({ userCGUDate: new Date(json.date) })
                } else {
                    this.setState({ userCGUDate: false })
                }
            } else {
                
            }
        })
    }

    getContent = () => {
        if (this.state.userCGUDate) {
            return (
                <span>
                    {i18n.lastCGUValidation} {getFullDateMini(this.state.userCGUDate)}
                </span>
            )
        } 
        return (
            <span>
                {this.props.login} {i18n.noCGUDate}
            </span>
        )
    }

    render() {
        if (this.props.login) {
            return (
                <Grid
                    className="cardStyle"
                    xs={12}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                    {this.getContent()}
                    <br />
                    <span className="italic">
                        {i18n.lastCGUUpdate} {getFullDateMini(this.state.cguDate)}
                    </span>
                </Grid>
            )
        }
        return null
    }
}

UpdatePanel.propTypes = {
    login: PropTypes.string,
}
const mapDispatchToProps = {
    fetchAccountCGUDate: AccountAction.fetchAccountCGUDate,
}

export default connect(null, mapDispatchToProps)(UpdatePanel)
