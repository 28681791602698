import React, { Component } from 'react'
import { every } from 'lodash'
import i18n from 'simple-react-i18n'
import UUID from 'uuid'
import PropTypes from 'prop-types'

import DisplayedValue from './DisplayedValue'
import { hasValue } from '../../utils/NumberUtil'

const SELECT_NULL_VALUE = 'SELECT_NULL_VALUE'
const { $ } = window

class Select extends Component {
    state = { id: UUID.v4(), editing: false }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate() {
        this.init()
    }

    init = () => {
        const ul = $(`#${this.state.id}`).parent().find('ul')
        if (ul.length && ul.children().length) {
            const liElements = ul.children()
            const elements = this.getElements()
            const codeToReturn = [this.props.codeToReturn, 'code', 'id', 'value'].find((key) => hasValue(elements[elements.length - 1][key]))
            elements.map((elem, index) => {
                $(liElements[index]).on('mousedown', () => {
                    const value = elem[codeToReturn] === SELECT_NULL_VALUE ? null : elem[codeToReturn]
                    if (this.props.integerValue) {
                        this.props.onChange(hasValue(value) ? parseInt(value) : value)
                    } else if (this.props.stringValue) {
                        this.props.onChange(hasValue(value) ? value.toString() : value)
                    } else {
                        this.props.onChange(value)
                    }
                    this.setState({ editing: false })
                })
            })
        }
    }

    getElements = () => {
        const elements = [
            {
                id: SELECT_NULL_VALUE,
                code: SELECT_NULL_VALUE,
                value: SELECT_NULL_VALUE,
                label: this.props.nullLabel || '',
            },
            ...this.props.elements,
        ]
        if (elements.includes(undefined)) {
            console.error(i18n.problemWithSelectElements)
            console.error(`${i18n.selectTitle} : ${this.props.title}`)
            console.error(`${i18n.elements} : `)
            console.error(this.props.elements)
            return [...elements[0]]
        }
        if (!this.props.selected || this.props.selected == -1) {
            elements[0].selected = true
        }
        return elements
    }

    getOptions = () => {
        const elements = this.getElements()
        if (this.props.codeToReturn) {
            return this.getElementsOptions(elements, this.props.codeToReturn)
        }
        if (every(elements, (e) => (e.code || e.code === 0) && (e[this.props.labelKey] || e.name || e.label || e.label === ''))) {
            return this.getElementsOptions(elements, 'code')
        }
        if (every(elements, (e) => (e.id || e.id === 0) && (e[this.props.labelKey] || e.name || e.label || e.label === ''))) {
            return this.getElementsOptions(elements, 'id')
        }
        if (every(elements, (e) => (e.value || e.value === 0 || e.value === '') && (e[this.props.labelKey] || e.label || e.label === ''))) {
            return this.getElementsOptions(elements, 'value')
        }
        return []
    }

    getOptionIcon = (el) => el.img || el.icon || el.image || ''

    getOptionLabel = (el) => (this.props.labelKey && el[this.props.labelKey] ? el[this.props.labelKey] : (el.name || el.label || el.title || ' '))

    getElementsOptions = (elements, codeKey) => {
        if (this.props.multiple) {
            return elements.map((el) => {
                const selected = el.value === SELECT_NULL_VALUE || this.props.selected.find((s) => el[codeKey] == s) !== undefined
                const icon = this.getOptionIcon(el)
                return (
                    <option
                        value={el[codeKey]}
                        disabled={el.value == SELECT_NULL_VALUE}
                        selected={selected}
                        data-icon={icon}
                        className={icon && 'left circle'}
                    >
                        &nbsp;{ this.getOptionLabel(el) }
                    </option>
                )
            })
        }
        return elements.map((el) => {
            const icon = this.getOptionIcon(el)
            return this.props.withThresholdGroups && el.referenceCode ? (
                <option
                    value={el[codeKey]}
                    selected={el[codeKey] == this.props.selected}
                    data-icon={icon}
                    className={icon && 'left circle'}
                >
                    &nbsp;&nbsp;&nbsp;&nbsp;{ this.getOptionLabel(el) }
                </option>
            ) : (
                <option
                    value={el[codeKey]}
                    selected={el[codeKey] == this.props.selected}
                    data-icon={icon}
                    className={icon && 'left circle'}
                >
                    { this.getOptionLabel(el) }
                </option>
            )
        })
    }

    onTableEditing = () => {
        this.setState({ editing: true }, () => {
            const input = $(`#${this.state.id}`).parent().find('input')
            input.focus()
            input.on('focusout', () => {
                this.setState({ editing: false })
            })
        })
    }

    getSelect = () => {
        if (this.props.readMode || (this.props.tableEditable && !this.state.editing)) {
            const found = hasValue(this.props.selected) ? this.props.elements.find((e) => (this.props.codeToReturn ? e[this.props.codeToReturn] == this.props.selected
                : e.code == this.props.selected || e.id == this.props.selected || e.value == this.props.selected)) : null
            return (
                <DisplayedValue
                    label={this.props.title}
                    value={found ? found.simpleLabel || found[this.props.labelKey] || found.name || found.label || found.title || '' : ''}
                    hideNull={this.props.hideNull}
                    onClick={this.onTableEditing}
                    tableEditable={this.props.tableEditable}
                />
            )
        }
        const options = this.getOptions()
        const disabled = { disabled: this.props.disabled }
        const className = `${!this.props.noInputFieldClass && 'input-field'} no-margin-top ${!this.props.col && this.props.className}`
        return (
            <>
                <label
                    className="select-label sieau-input"
                    {...disabled}
                >
                    {this.props.title}
                </label>
                <select
                    ref={(ref) => { this.selectRef = ref }}
                    id={this.state.id}
                    {...disabled}
                    className="sieau-select-hidden"
                    multiple={this.props.multiple}
                >
                    { options }
                </select>
            </>
        )
    }

    render() {
        return this.props.col ? (
            <div className={`relative col s${this.props.col} ${this.props.className}`}>
                { this.getSelect()}
            </div>
        ) : this.getSelect()
    }
}

Select.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    selected: PropTypes.any,
    title: PropTypes.string,
    col: PropTypes.number,
    nullLabel: PropTypes.string,
    disabled: PropTypes.bool,
    codeToReturn: PropTypes.string,
    withThresholdGroups: PropTypes.bool,
    readMode: PropTypes.bool,
    integerValue: PropTypes.bool,
    multiple: PropTypes.bool,
    icon: PropTypes.bool,
    stringValue: PropTypes.bool,
    hideNull: PropTypes.bool,
    tableEditable: PropTypes.bool,
    noInputFieldClass: PropTypes.bool,
    labelKey: PropTypes.string,
}

Select.defaultProps = {
    label: '',
    className: '',
    multiple: false,
    readMode: false,
    icon: false,
}

export default Select
