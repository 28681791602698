import React from 'react'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'

export const TabStyle = withStyles((theme) => ({
    root: {
        opacity: 'O.6',
        padding: '1rem 0',
        textTransform: 'none',
        backgroundColor: 'white',
        color: '#484848',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.2rem',
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Poppins'].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            backgroundColor: '#057DCC',
            color: 'white',
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    tab: {
        padding: '0.5rem',
        fontFamily: 'Open Sans',
        fontSize: '2rem',
        backgroundColor: 'grey',
        color: 'black',
        '&:hover': {
            opacity: 1,
        },
    },
    selected: {},
}))((props) => <Tab {...props} />)
