import Grid from '@material-ui/core/Grid'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import HomeAction from '../actions/HomeAction'
import { logoAquasys } from '../constants/LogosConstants'
import CMSEventDto from '../../events/dto/CMSEventDto'
import { contentsPath } from '../../../conf/basepath'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            partenaires: [],
        }
    }

    componentDidMount() {
        const { cmsPartenaires } = this.props
        if (!cmsPartenaires.length) {
            this.props.fetchCmsPartenaires().then(() => {
                this.setPartenairesCms()
            })
        } else {
            this.setPartenairesCms()
        }
    }

    setPartenairesCms = () => {
        const { cmsPartenaires } = this.props
        const partenaires = []
        cmsPartenaires.map((c) => {
            if (c.cmsDocument[0]) {
                partenaires.push({
                    id: c.id,
                    order: parseInt(c.subtitle) || null,
                    link: c.link,
                    docName: c.cmsDocument[0].name,
                })
            }
        })
        this.setState({ partenaires })
    }

    render() {
        const { partenaires } = this.state

        return (
            <>
                <Grid
                    id="home_footer"
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    style={{
                        height: '10vh',
                    }}
                >
                    {sortBy(partenaires, 'order', 'id').map((i, index) => (
                        <a
                            key={index}
                            href={i.link}
                            target="_blank"
                            style={{
                                marginLeft: '10px',
                            }}
                            rel="noreferrer"
                        >
                            <img
                                src={`${contentsPath}CMS/${i.docName}`}
                                alt="logo"
                                style={{
                                    maxHeight: '7vh',
                                    width: 'auto',
                                }}
                            />
                        </a>
                    ))}
                    <a
                        href={logoAquasys.link}
                        target="_blank"
                        style={{
                            marginLeft: '10px',
                        }}
                        rel="noreferrer"
                    >
                        <img
                            src={logoAquasys.src}
                            alt="logo"
                            style={{
                                maxHeight: '7vh',
                                width: 'auto',
                            }}
                        />
                    </a>
                    {/* {logos.map((img, index) => (
                        <a
                            key={index}
                            href={img.link}
                            target="_blank"
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <img
                                src={img.src}
                                alt="logo"
                                style={{
                                    maxHeight: '7vh',
                                    width: 'auto',
                                }}
                            />
                        </a>
                    ))} */}
                </Grid>
            </>
        )
    }
}

Footer.propTypes = {
    fetchCmsPartenaires: PropTypes.func,
    cmsPartenaires: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
}

const mapStateToProps = (store) => ({
    cmsPartenaires: store.HomeReducer.cmsPartenaires,
})

const mapDispatchToProps = {
    fetchCmsPartenaires: HomeAction.fetchCmsPartenaires,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
