import React, { Component } from 'react'
import PropTypes from 'prop-types'

class InputLogin extends Component {
    getIconInput = () => {
        if (this.props.iconInput) {
            return (
                <i
                    data-view-input
                    className="material-icons clickable right"
                    onClick={() => this.props.onClickIcon()}
                    style={{ cursor: 'pointer' }}
                >{ this.props.iconInput }
                </i>
            )
        }
        return null
    }

    render() {
        const iconsTag = ((icon) => {
            if (icon) {
                return <i className="padding-glyph material-icons">{ this.props.icon }</i>
            }
            return ''
        })(this.props.icon)

        const required = ((r) => {
            if (r) {
                return 'required'
            }
            return ''
        })(this.props.icon)

        return (
            <div>
                <div className="row valign-wrapper" style={{ marginBottom: '10px' }}>
                    <label htmlFor={this.props.refChild} className="col s3">
                        { iconsTag }
                        { this.props.children }
                    </label>
                    <input
                        id={this.props.refChild}
                        name={this.props.refChild}
                        ref={this.props.refChild}
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        className="required input-field col s9 no-padding"
                        required={required}
                    />
                    { this.getIconInput() }
                </div>
            </div>
        )
    }
}

InputLogin.propTypes = {
    iconInput: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    refChild: PropTypes.string.isRequired,
    required: PropTypes.bool,
    onClickIcon: PropTypes.func,
}

export default InputLogin
