import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { WS, CSV, XML } from './constants/ImportFormat'

class ImportFileFormat extends Component {
    constructor(props) {
        super(props)
        this.state = { format: WS }
    }

    onChangeFormat(value) {
        this.setState({ format: value })
    }

    importFileXML() {
        return (
            <div>
                <p className="caption">{ i18n.importXmlFileToZanderReference }</p>
                <form action="#">
                    <div className="file-field input-field">
                        <div className="btn">
                            <span>{ i18n.xml }</span>
                            <input type="file" />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                    </div>
                </form>
                <form action="#">
                    <p>
                        <input name="fileXML" type="radio" id="databaseUpdate" checked />
                        <label htmlFor="databaseUpdate">{ i18n.databaseUpdate }</label>
                        <input name="fileXML" type="radio" id="importAll" />
                        <label htmlFor="importAll">{ i18n.importAllSupports }</label>
                    </p>
                    <p>
                        <input type="checkbox" className="filled-in" id="doActivate" />
                        <label htmlFor="doActivate">{ i18n.activateAllSupports }</label>
                    </p>
                </form>
            </div>
        )
    }

    importFileCSV() {
        return (
            <div>
                <p className="caption">{ i18n.importCsvZanderReference }</p>
                <form action="#">
                    <div className="file-field input-field">
                        <div className="btn">
                            <span>{ i18n.csv }</span>
                            <input type="file" />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                    </div>
                </form>
                <form action="#">
                    <p>
                        <input name="fileCSV" type="radio" id="databaseUpdate" />
                        <label htmlFor="databaseUpdate">{ i18n.databaseUpdate }</label>
                        <input name="fileCSV" type="radio" id="importAll" />
                        <label htmlFor="importAll">{ i18n.importAllSupports }</label>
                    </p>
                    <p>
                        <input type="checkbox" className="filled-in" id="doActivate" />
                        <label htmlFor="doActivate">{ i18n.activateAllSupports }</label>
                    </p>
                </form>
            </div>
        )
    }

    importFileWS() {
        return (
            <div>
                <div className="card-content">
                    <div className="row no-margin">
                        <p className="caption">Dernière mise à jour: 02/04/2017 10:15:00</p>
                        <p>
                            <input type="checkbox" className="filled-in" id="doAutomaticSynchronize" />
                            <label htmlFor="doAutomaticSynchronize">{ i18n.automaticSynchronization }</label>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    getItems = () => [
        { value: WS, label: i18n.ws },
        { value: XML, label: i18n.xml },
        { value: CSV, label: i18n.csv },
    ]

    getChoice = () => {
        switch (this.state.format) {
            case XML:
                return this.importFileXML()
            case CSV:
                return this.importFileCSV()
            case WS:
                return this.importFileWS()
            default:
                return null
        }
    }

    getSidenav = () => this.getItems().map((item) => {
        const className = item.value == this.state.format ? 'collection-item active clickable' : 'collection-item clickable'
        return (
            <a className={className} onClick={() => { this.onChangeFormat(item.value) }}>{ item.label }</a>
        )
    })

    render() {
        const sideNav = this.getSidenav()

        return (
            <div className="row">
                <div className="col s3">
                    <div className="collection">
                        { sideNav }
                    </div>
                </div>
                <div className="col s9">
                    <div className="card-title activator">
                        { i18n.synchronisationZanderReference }
                    </div>
                    <div className="row">
                        { this.getChoice() }
                    </div>
                    <div className="row">
                        <a className="btn btn-small right" type="submit" onClick={this.props.onUpdate} name="action">{ i18n.synchronize }</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImportFileFormat
