/* eslint-disable radix */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { push } from 'react-router-redux'
import { orderBy, groupBy, find } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Tabs from '@material-ui/core/Tabs'
import { Card } from '@material-ui/core'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import EventsAction from './actions/EventsAction'
import AdministrationAction from '../administration/actions/AdministrationAction'
import ActionComponent from '../../components/actions/ActionComponent'
import GlobalCardList from '../../components/list/cardList/GlobalCardList'
import HomeAction from '../home/actions/HomeAction'
import Input from '../../components/forms/Input'
import { getLabel } from '../../utils/StoreUtils'
import { getArticleFormat, getYearDate } from '../../utils/DateUtil'
import { getNewsColor } from '../../utils/ColorUtil'
import { getUser } from '../../utils/SettingUtils'
import { hasValue } from '../../utils/NumberUtil'
import { PATH_CONTENTS } from '../home/constants/RouteConstants'
import ProgressBar from '../../components/ProgressBar'
import { TabStyle } from '../../components/TabStyle'
import { lightGrey } from '../../styles/theme'

const moment = extendMoment(Moment)
moment.locale('fr')

const InputWrapper = styled(Card)`
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding: 1rem 1rem;

    @media (max-width: 768px) {
        display: block;
        text-align: center;
    }
`
const ContentFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${lightGrey};
    padding: 0.2rem 1rem;
    margin: 0 8px;
    border-bottom: 1px solid rgb(191, 191, 191);
`

const FooterText = styled.div`
    font-style: italic;
    font-size: 0.8rem;
`

const IconFooter = styled(Icon)`
    font-size: 1.2rem !important;
`

const IconGrid = styled(Grid)`
    width: 2%;
    display: flex;
    align-items: center;
`

class NewsApp extends ActionComponent {
    constructor(props) {
        super(props)

        const { content = {} } = props.selectedSearchValues
        const category = content && hasValue(content.category)
            ? parseInt(content.category)
            : parseInt(props.categoryId)
        const usedCategory = props.cmsCategories.find((c) => c.id === category) || category === -1
            ? category
            : parseInt(props.categoryId)
        const usedSortBy = content && hasValue(content.sortBy) && (content.sortBy !== 'category' || category == -1)
            ? content.sortBy
            : 'updateDate'
        if (!content || !hasValue(content.category)) {
            this.props.setSelectedSearchValues(usedCategory, usedSortBy)
        }
        this.state = {
            filter: {
                level: -1,
                status: -1,
                searchValue: '',
                category: usedCategory,
            },
            tmpFilter: {
                level: -1,
                status: -1,
                searchValue: '',
                category: usedCategory,
            },
            sortBy: usedSortBy,
            activeTabIndex: 'updateDate',
            idWP: -1,
            dataLoaded: false,
            progress: 0,
            menuId: this.props.location.state
                ? this.props.location.state.categoryId
                : this.props.match.params.categoryId,
            cmsMenu: [],
        }
    }

    componentDidMount() {
        if (!this.state.dataLoaded) {
            this.props.loadCMSData(
                () => {
                    this.setState({ dataLoaded: true })
                    this.setCMSMenu()
                },
                (p) => this.setState({ progress: p }),
            )
        }
        // this.setComponentActions(
        //     this.state.filter.category !== -1 ? this.state.filter.category : this.props.categoryId,
        // )
        if (this.props.globalResearch) {
            this.setState({
                filter: {
                    ...this.state.filter,
                    searchValue: this.props.globalResearch,
                },
                tmpFilter: {
                    ...this.state.tmpFilter,
                    searchValue: this.props.globalResearch,
                },
            })
            this.props.updateGlobalResearch('')
        }
        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        const updateId = this.props.location.state
            ? this.props.location.state.categoryId
            : this.props.match.params.categoryId
        if (this.state.menuId !== updateId) {
            this.setState({ menuId: updateId })
        }
        this.setTitle(this.props)
        // this.setComponentActions(
        //     this.state.filter.category !== -1 ? this.state.filter.category : this.props.categoryId,
        // )
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.categoryId !== nextProps.categoryId) {
            this.setState({
                category: nextProps.categoryId,
                sortBy: 'updateDate',
            })
        }
    }

    setCMSMenu = () => {
        const { cmsCategories } = this.props
        const cmsMenu = []
        if (cmsCategories) {
            cmsCategories.forEach((c) => {
                if (c.displayOnMenu) {
                    const obj = {
                        menu: c.menu,
                        displayOnMenu: c.displayOnMenu,
                        id: c.id,
                    }
                    if (!cmsMenu.find((menu) => JSON.stringify(cmsMenu[menu]) === JSON.stringify(obj))) {
                        cmsMenu.push(obj)
                    }
                }
            })
        }
        if (JSON.stringify(this.state.cmsMenu) != JSON.stringify(cmsMenu)) {
            this.setState({ cmsMenu })
        }
    }

    setTitle(props) {
        const category = props.cmsCategories.find((c) => c.id === parseInt(this.state.menuId) || c.id === props.categoryId)
        const name = category ? category.menu : i18n.communication
        props.setTitle([
            {
                title: name,
                href: `contents/${this.state.menuId}`,
            },
        ])
    }

    setComponentActions = () => {
        if (getUser().isAdmin === '1' || getUser().labo === '1') {
            this.setActions({
                new: this.redirectToContent(this.state.menuId, 0),
            })
        }
    }

    onChangeSortBy = (event, sortById) => {
        this.setState({ sortBy: sortById })
        this.props.setSelectedSearchValues({ sortBy: sortById })
    }

    redirectToContent = (categoryId, contentId) => () => {
        this.props.push({
            pathname: `${PATH_CONTENTS}/${categoryId}/${contentId}`,
            state: {
                contentId,
                categoryId,
                menu: this.props.location.state
                    ? this.props.location.state.menu
                    : i18n.communication,
            },
        })
    }

    getStatutIcon = (statut) => {
        switch (statut) {
            case 1:
                return 'checkCircle'
            case 2:
                return 'description'
            case 3:
                return 'folderOpen'
            default:
                return 'checkCircle'
        }
    }

    getContentRow = (content) => {
        const color = content.status !== 1 ? 'lighten-2' : ''
        const dateCreation = getArticleFormat(content.updateDate)
        return (
            <div onClick={this.redirectToContent(this.state.menuId, content.id)}>
                <Grid
                    container
                    key={content.id}
                    className={`collection-item noMargin valign-wrapper ${color}`}
                    style={{ padding: '15px 20px' }}
                    spacing={1}
                >
                    <Grid item xs={3}>
                        <h5 className="thin">{getArticleFormat(content.updateDate)}</h5>
                    </Grid>
                    <Grid item xs={7}>
                        <h5 className="clickable">{content.title}</h5>
                        {content.subtitle ? <h6 className="clickable">{content.subtitle}</h6> : ''}
                    </Grid>
                </Grid>
                <ContentFooter>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid
                            item
                            style={{
                                width: '94%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <FooterText>
                                {i18n.createdBy} {content.author} {i18n.the} {dateCreation}
                                &nbsp;
                            </FooterText>
                        </Grid>
                        {content.cmsDocument.length > 0 ? (
                            <IconGrid item>
                                <IconFooter>attach_file</IconFooter>
                            </IconGrid>
                        ) : (
                            ''
                        )}
                        {content.x && content.y ? (
                            <IconGrid item>
                                <IconFooter>location_on</IconFooter>
                            </IconGrid>
                        ) : (
                            ''
                        )}
                        <IconGrid item>
                            <IconFooter>{this.getStatutIcon(content.statut)}</IconFooter>
                        </IconGrid>
                    </Grid>
                </ContentFooter>
            </div>
        )
    }

    getSortByFunction = () => {
        const statusOptions = [
            { value: -1, label: i18n.all },
            { value: 1, label: i18n.published },
            { value: 2, label: i18n.draft },
            { value: 3, label: i18n.archived },
        ]
        switch (this.state.sortBy) {
            case 'updateDate':
                return (content) => getYearDate(content.updateDate)
            case 'status':
                return (content) => getLabel(statusOptions, content.status, null, 'value')
            case 'category':
                return (content) => getLabel(
                    this.props.cmsCategories,
                    content.idCategory,
                    'title',
                    'id',
                    i18n.others,
                )
            default:
                return this.state.sortBy
        }
    }

    getResult = (filteredEvents) => {
        if (!filteredEvents.length) {
            const noContentLabels = [
                { code: 2, name: i18n.noNewsToDisplay },
                { code: 3, name: i18n.noLinksToDisplay },
                { code: 4, name: i18n.noDocumentsToDisplay },
                { code: 5, name: i18n.noContractsToDisplay },
            ]
            return (
                <h4 className="center-align padding-top-1 padding-bottom-1">
                    {getLabel(noContentLabels, this.state.filter.category)}
                </h4>
            )
        }
        const orderedEvents = orderBy(filteredEvents, ['updateDate'], ['desc'])
        const groups = groupBy(orderedEvents, this.getSortByFunction())
        const data = Object.keys(groups).reduce((acc, key) => {
            const size = groups[key].length
            const label = size > 1 ? i18n.elements : i18n.element
            const cards = groups[key].map((e) => ({
                content: this.getContentRow(e),
                color: hasValue(e.level) ? getNewsColor(e.level) : 'null',
            }))
            return {
                ...acc,
                [key]: {
                    title: `${key} (${size} ${label})`,
                    cards,
                },
            }
        }, {})
        return <GlobalCardList style={{ padding: '8px 10px 10px' }} data={data} collapsible />
    }

    getFilteredCMSEvents = (
        categories = this.props.cmsCategories,
        events = this.props.cmsEvents,
    ) => {
        const { cmsMenu, menuId } = this.state
        const { users } = this.props
        const menuCategory = cmsMenu.find((c) => c.id === parseInt(menuId))
        const categoriesFiltered = cmsMenu.filter((c) => c.menu === menuCategory.menu)
        const filteredEvents = events.filter((e) => categoriesFiltered.find((c) => c.id === e.idCategory))
        const user = getUser()
        const adminFilters = user.isAdmin === '1' ? filteredEvents : filteredEvents.filter((e) => e.authorization !== 'admin' || e.author === user.login)
        const laboFilters = user.labo === '1' ? adminFilters : adminFilters.filter((e) => e.authorization !== 'anim' || e.author === user.login)
        const authorFilters = user.isAdmin === '1' ? laboFilters : laboFilters.filter((e) => e.author === user.login || (users.find((u) => u.login === e.author) || {}).labo !== '1')
        const searchValue = this.state.tmpFilter.searchValue.toLowerCase()
        const eventCategory = (() => {
            if (this.state.tmpFilter.category) {
                return categories.find((c) => c.id === this.state.tmpFilter.category)
            }
            return categories.find((c) => c.id === this.props.categoryId)
        })()
        return authorFilters.filter((e) => {
            if (!this.state.tmpFilter.category) {
                return (
                    this.props.cmsEvents
                    && (this.state.filter.status === -1 || e.status === this.state.filter.status)
                    && (this.state.filter.level === -1 || e.level === this.state.filter.level)
                    && (e.status === 1 || e.login === user.login)
                    && ['title', 'subtitle', 'comment', 'author']
                        .map((key) => e[key])
                        .join('   ')
                        .toLowerCase()
                        .includes(searchValue)
                )
            }
            return (
                (e.idCategory === this.state.tmpFilter.category
                    || (this.state.tmpFilter.category === -1
                        && eventCategory
                        && eventCategory.displayOnMenu))
                && (this.state.filter.status === -1 || e.status === this.state.filter.status)
                && (this.state.filter.level === -1 || e.level === this.state.filter.level)
                && (e.status === 1 || e.login === user.login)
                && ['title', 'subtitle', 'comment', 'author']
                    .map((key) => e[key])
                    .join('   ')
                    .toLowerCase()
                    .includes(searchValue)
            )
        })
    }

    getCMSFromCategory = (categories, events) => this.getResult(this.getFilteredCMSEvents(categories, events))

    handleChange = (event) => {
        this.setState({ level: event.target.value })
    }

    getActive = (panel) => (panel === this.state.sortBy ? 'active' : '')

    onChangeFilter = (filter, value) => {
        const newObj = {}
        newObj[filter] = value
        if (filter === 'category') {
            newObj.sortBy = 'updateDate'
        }
        this.setState({ tmpFilter: { ...this.state.tmpFilter, ...newObj } })
    }

    onValidate = () => {
        this.setState({ filter: { ...this.state.tmpFilter } })
        this.props.setSelectedSearchValues({ category: this.state.tmpFilter.category })
    }

    getCategories = () => {
        const filterWPCategories = groupBy(
            this.props.cmsEvents.filter((o) => o.categoryWPName),
            'categoryWPName',
        )
        const categories = Object.keys(filterWPCategories).map((o) => o)
        const wpCategories = categories.map((o, i) => ({
            id: this.state.idWP + i,
            title: o,
            displayOnMenu: true,
            menu: 'Communication',
        }))
        return [...this.props.cmsCategories, ...wpCategories]
    }

    getEvents = (categories) => this.props.cmsEvents.map((o) => {
        if (o.categoryWPName) {
            const findCategory = find(categories, (b) => b.title === o.categoryWPName)
            if (findCategory) {
                return { ...o, idCategory: findCategory.id }
            }
        }
        return o
    })

    handleChangeTab = (event, value) => {
        this.setState({ activeTabIndex: value })
        this.onChangeSortBy(event, value)
        this.getActive(value)
    }

    render() {
        const { tmpFilter, activeTabIndex, progress } = this.state
        const { applicationSettings } = this.props
        const IconStyle = {
            position: 'absolute',
            left: '20px',
        }

        this.props.setHelpPath('faq', applicationSettings)

        if (this.state.dataLoaded) {
            const categories = this.getCategories()
            const events = this.getEvents(categories)

            return (
                <div id="contents-app">
                    <Grid
                        style={{ margin: '5rem 0' }}
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <div style={{ width: '100%' }}>
                                    <InputWrapper>
                                        <Input
                                            title={i18n.search}
                                            value={tmpFilter.searchValue}
                                            onChange={(v) => this.onChangeFilter('searchValue', v)}
                                            style={{ width: '400px', height: '40px' }}
                                        />
                                    </InputWrapper>
                                    <Tabs
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none',
                                            },
                                        }}
                                        style={{ boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)' }}
                                        variant="fullWidth"
                                        value={activeTabIndex}
                                        onChange={this.handleChangeTab}
                                        aria-label="tabs"
                                    >
                                        <TabStyle
                                            value="updateDate"
                                            icon={<Icon style={IconStyle}>insert_invitation</Icon>}
                                            label={i18n.perYear}
                                        />
                                        <TabStyle
                                            value="author"
                                            icon={<Icon style={IconStyle}>person</Icon>}
                                            label={i18n.byAuthor}
                                        />
                                        <TabStyle
                                            value="status"
                                            icon={<Icon style={IconStyle}>edit</Icon>}
                                            label={i18n.byStatus}
                                        />
                                        <TabStyle
                                            value="category"
                                            icon={
                                                <Icon style={IconStyle}>collections_bookmark</Icon>
                                            }
                                            label={i18n.byCategory}
                                        />
                                    </Tabs>
                                    {this.getCMSFromCategory(categories, events)}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                </div>
            )
        }
        return (
            <div style={{ width: '40vw', margin: '5rem auto' }}>
                <ProgressBar progress={progress} />
            </div>
        )
    }
}

NewsApp.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        globalResearch: PropTypes.string,
    }),
}

const mapStateToProps = (store) => ({
    globalResearch: store.HomeReducer.globalResearch,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsEvent: store.EventsReducer.cmsEvent,
    cmsCategories: store.EventsReducer.cmsCategories,
    selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    users: store.UserReducer.users,
})

const mapDispatchToProps = {
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    loadCMSData: EventsAction.loadCMSData,
    fetchCMSEvents: EventsAction.fetchCMSEvents,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsApp)
