import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import i18n from 'simple-react-i18n'
import { getFullDateMini } from '../utils/DateUtil'

const UpdatePanel = ({ updateDate, updateLogin }) => {
    if (updateLogin && updateDate) {
        return (
            <Grid
                className="cardStyle"
                xs={12}
                style={{ marginTop: '10px', marginBottom: '10px' }}
            >
                <span className="italic">
                    {i18n.updatedAt}{' '}
                    {getFullDateMini(updateDate) === 'Invalid date'
                        ? updateDate
                        : getFullDateMini(updateDate)}{' '}
                    {i18n.by} {updateLogin}
                </span>
            </Grid>
        )
    }
    return null
}

UpdatePanel.propTypes = {
    updateLogin: PropTypes.string,
    updateDate: PropTypes.instanceOf(Date),
}
export default UpdatePanel
