import DtoReferencial from '../../../dto/DtoReferencial'

export default class DtoContributor extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = object.id
        this.name = object.name
        this.siret = object.siret
        this.mnemonique = object.mnemonique
        this.sandre = object.sandre
        this.domain = object.domain
        this.postalBox = object.postalBox
        this.road = object.road
        this.addressComplement = object.addressComplement
        this.statePlace = object.statePlace
        this.department = object.department
        this.city = object.city
        this.cityCode = object.cityCode
        this.cedexCode = object.cedexCode
        this.comments = object.comments
        this.phoneTel = object.phoneTel
        this.email = object.email
        this.phoneTelSecond = object.phoneTelSecond
        this.fax = object.fax
        this.mobile = object.mobile
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.auteur = object.auteur
        this.otherReference = object.otherReference
    }
}
