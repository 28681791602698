/* eslint-disable global-require */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Recaptcha from 'react-recaptcha'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { ASK_ACCOUNT_MAIL_MESSAGE, ASK_ACCOUNT_MAIL_SUBJECT } from './constants/HomeConstants'
import { keyRecaptcha, mailAdmin } from '../../conf/AppConstants'
import HomeAction from './actions/HomeAction'
import HomeFooter from './components/Footer'
import HomeHeader from './components/Header'
import HomeSlide from './components/Slide'
import { Center } from '../../styles/main'
import { GreenButton } from '../../styles/button'
import ToastrAction from '../../utils/ToastrAction'

const CenterMargin = styled(Center)`
    margin-bottom: 30px !important;
`

class AskAccount extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            disabled: true,
            loading: false,
            name: '',
            organization: '',
            mail: '',
            howKnow: '',
        }
        this.verifyCallback = this.verifyCallback.bind(this)
    }

    componentDidMount() {
        this.props.setHelpPath('faq')
    }

    onSendEmail = () => {
        const { name, organization, mail, howKnow } = this.state

        if (name && organization && mail && howKnow) {
            this.setState({ loading: true })
            const email = {
                to: mailAdmin,
                from: mailAdmin,
                subject: ASK_ACCOUNT_MAIL_SUBJECT + name,
                message:
                    `${ASK_ACCOUNT_MAIL_MESSAGE
                    }<br/> Nom : ${name
                    }<br/> Organisme : ${organization
                    }<br/> Mail : ${mail
                    }<br/> Comment avez-vous connu Co-Click'Eau : ${howKnow}`,
            }
            this.props.sendMailToAdmin(email).then(() => {
                this.setState({ loading: false, name: '', organization: '', mail: '', howKnow: '' })
            })
        } else {
            ToastrAction.error(i18n.incompletForm)
        }
    }

    verifyCallback(recaptchaToken) {
        if (recaptchaToken) {
            this.setState({ disabled: false })
        }
    }

    render() {
        const { disabled, loading, name, organization, mail, howKnow } = this.state
        return (
            <div>
                <div
                    className="row"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                        margin: '0',
                    }}
                >
                    <Grid container direction="row" justify="center" alignItems="center">
                        <HomeSlide />
                        <div className="home_content">
                            <div className="home_card">
                                <div>
                                    <HomeHeader page="askAccount" />
                                    <div
                                        className="row"
                                        style={{
                                            height: '75vh',
                                            overflowY: 'auto',
                                            padding: '0 20%',
                                        }}
                                    >
                                        <CenterMargin>
                                            <span className="title_home">
                                                {i18n.askAccountTitle}
                                            </span>
                                        </CenterMargin>
                                        <div className="row valign-wrapper o-margin">
                                            <i className="padding-glyph material-icons input_home">
                                                person
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="text"
                                                placeholder="Nom"
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                                value={name}
                                            />
                                        </div>
                                        <div className="row valign-wrapper o-margin">
                                            <i className="padding-glyph material-icons input_home">
                                                store
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="text"
                                                placeholder="Organisme"
                                                onChange={(e) => this.setState({ organization: e.target.value })}
                                                value={organization}
                                            />
                                        </div>
                                        <div className="row valign-wrapper o-margin">
                                            <i className="padding-glyph material-icons input_home">
                                                email
                                            </i>
                                            <input
                                                className="sieau-input form-control"
                                                type="text"
                                                placeholder="Email"
                                                onChange={(e) => this.setState({ mail: e.target.value })}
                                                value={mail}
                                            />
                                        </div>
                                        <div className="row valign-wrapper o-margin">
                                            <textarea
                                                name="content"
                                                onChange={(e) => this.setState({ howKnow: e.target.value })}
                                                value={howKnow}
                                                placeholder="Comment avez-vous connu Co-Click'Eau ?"
                                                style={{ height: '20vh', backgroundColor: 'white', borderRadius: 5, border: '1px solid lightgrey', padding: 10 }}
                                            />
                                        </div>
                                        <Grid direction="column" container alignItems="center">
                                            <Recaptcha
                                                sitekey={keyRecaptcha}
                                                onloadCallback={this.onLoadRecaptcha}
                                                render="onLoad"
                                                verifyCallback={this.verifyCallback}
                                                hl="fr"
                                            />
                                            {!loading ? (
                                                <GreenButton
                                                    id="submit"
                                                    onClick={this.onSendEmail}
                                                    disabled={disabled}
                                                >
                                                    Envoyer la demande
                                                </GreenButton>
                                            ) : (
                                                <CircularProgress />
                                            )}
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            <HomeFooter />
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}

AskAccount.propTypes = {
    setHelpPath: PropTypes.func,
    sendMailToAdmin: PropTypes.func,
}

const mapDispatchToProps = {
    sendMailToAdmin: HomeAction.sendMailToAdmin,
    setHelpPath: HomeAction.setHelpPath,
}

export default connect(null, mapDispatchToProps)(AskAccount)
