import DtoReferencial from '../../../dto/DtoReferencial'

export default class DtoZone extends DtoReferencial {
    constructor(obj) {
        super(obj)
        this.labelWithCode = `${obj.name} [${obj.id}]`
        this.name = obj.name
        this.referentiel = obj.referentiel
        this.kml = obj.kml
        this.headers = ['code', 'name']
    }
}
