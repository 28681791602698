import DtoReferencial from '../../../dto/DtoReferencial'

export default class ContributorItem extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = object.id
        this.name = object.name
        this.siret = object.siret
        this.mnemonique = object.mnemonique
        this.sandre = object.sandre
        this.domain = object.domain
        this.postalCode = object.postalCode
        this.city = object.city
        this.cityCode = object.cityCode
        this.email = object.email
        this.phoneTel = object.phoneTel
        this.status = object.status
        this.noNewsLetterDate = object.noNewsLetterDate
        this.noNewLetterLogin = object.noNewLetterLogin
        this.headers = ['siret', 'name', 'mnemonique', 'postcode', 'city', 'phoneTel', 'domain', 'sandre', 'status']

        this.labelDisplay = object.mnemonique || object.name

        this.auteur = object.auteur // Option[String] = None,
        this.postalBox = object.postalBox // Option[String] = None,
        this.addressComplement = object.addressComplement // Option[String] = None,
        this.road = object.road // Option[String] = None,
        this.statePlace = object.statePlace // Option[String] = None,
        this.city = object.city // Option[String] = None,
        this.phoneTelSecond = object.phoneTelSecond // Option[String] = None,
        this.fax = object.fax // Option[String] = None,
        this.mobile = object.mobile // Option[String] = None,
        this.cedexCode = object.cedexCode // Option[Double] = None,
        this.structureType = object.structureType // Option[Long] = None,
    }
}
