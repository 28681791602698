module.exports = {
    RECEIVE_SAVED_RESEARCH_CRITERIAS: 'RECEIVE_SAVED_RESEARCH_CRITERIAS',
    ADD_SAVED_RESEARCH_CRITERIAS: 'ADD_SAVED_RESEARCH_CRITERIAS',
    RECEIVE_SETTINGS: 'RECEIVE_SETTINGS',
    RESET_ADMINISTRATION: 'RESET_ADMINISTRATION',
    RECEIVE_PICTOS: 'RECEIVE_PICTOS',
    RECEIVE_SELECTED_SEARCH_VALUES: 'RECEIVE_SELECTED_SEARCH_VALUES',
    RECEIVE_CARTOGRAPHY_THEMES: 'RECEIVE_CARTOGRAPHY_THEMES',
    RECEIVE_ADMIN_THEMES: 'RECEIVE_ADMIN_THEMES',
    RECEIVE_THEME: 'RECEIVE_THEME',
    RECEIVE_ADMIN_THEME_LAYERS: 'RECEIVE_ADMIN_THEME_LAYERS',
    RESET_THEME: 'RESET_THEME',
    RECEIVE_UDI_KML_EXISTANCE: 'RECEIVE_UDI_KML_EXISTANCE',
    UPDATE_STATE_PARAMETERS: 'UPDATE_STATE_PARAMETERS',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    LIST_TYPE: {
        0: 'Paramètres physico-chimiques',
        2: 'Indices',
        1: 'Taxons',
        3: 'Etats',
    },
    SELECTION_LIST_TYPE: {
        0: 'PCparameters',
        1: 'taxons',
        2: 'indices',
        3: 'environmentalParameters',
        4: 'states',
    },
    SELECTION_LIST_TYPE_LABELS: [
        { code: 0, label: 'PCparameters' },
        { code: 1, label: 'taxons' },
        { code: 2, label: 'indices' },
        { code: 3, label: 'environmentalParameters' },
        { code: 4, label: 'states' },
    ],
    SELECTION: 'SELECTION',
    SELECTION_PARAMETER: 'SELECTION_PARAMETER',
    THRESHOLD: 'THRESHOLD',
    PARAMETER_THRESHOLD: 'PARAMETER_THRESHOLD',
    ICONS_PRESELECTION: ['account_balance', 'assistant_photo', 'attachment', 'attach_file', 'business', 'business_center', 'card_travel', 'cast_connected', 'contact_mail', 'event_note', 'drafts', 'email', 'error', 'favorite', 'feedback', 'fiber_new', 'file_download', 'flash_on', 'grade', 'home', 'info', 'insert_link', 'language', 'location_on', 'map', 'notifications', 'people', 'pie_chart', 'report_problem', 'school'],
    LAYER_TYPES: [
        { code: 0, name: 'WMS' },
        { code: 1, name: 'KML' },
    ],
    PICTURES_EXTENSIONS: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG'],
}
