import proj4 from 'proj4'
import i18n from 'simple-react-i18n'
import ol from 'openlayers'
import { path } from '../../conf/basepath'
import { round } from '../NumberUtil'

const getWGS84Coordinate = (loc) => {
    if (loc.projection) {
        switch (loc.projection) {
            case 2:
                return proj4('+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
            case 5:
                return proj4('+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
            case 26:
                return proj4('+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
            case 16:
                return [+loc.x, +loc.y]
            case 31:
                return [+loc.x, +loc.y]
            case 39:
                return proj4('+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
            case 40:
                return proj4('+proj=utm +zone=22 +ellps=GRS80 +towgs84=2,2,-2,0,0,0,0 +units=m +no_defs')
            case 0:
                return [2.4983333333333335, 46.6058333]
            default:
                console.error(i18n.unknownProjection)
                return proj4('+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
        }
    }
    return proj4('+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
}

const defaultCoordinates = {
    'https://vendeeeau.aquasys.fr/api/': [-1.403488, 46.700867],
    'https://vendeeeau.sieau.aquasys.fr/api/': [-1.403488, 46.700867],
    'https://reseau.sieau.aquasys.fr/api/': [-0.363682, 49.170523],
    'https://nice.sieau.aquasys.fr/api/': [7.102891, 44.103643],
    'https://eaudazur.sieau.aquasys.fr/api/': [7.102891, 44.103643],
    'https://cacg.sieau.aquasys.fr/api/': [0.102891, 43.103643],
    'https://aprona.sieau.aquasys.fr/api/': [7.752111, 48.573405],
    'https://chlordecone.sieau.aquasys.fr/api/': [-61.024174, 14.641528],
    'https://aep49.sieau.aquasys.fr/api/': [-0.5518256, 47.4711615],
}

const getDefaultCoordinates = () => {
    if (defaultCoordinates[path]) {
        return defaultCoordinates[path]
    }
    return [-1.7541604, 47.1325782]
}

const getWGS84KML = (location) => 
    // return proj4('EPSG:3857','+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs', [+location.x, +location.y])
    proj4('+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs', 'WGS84', [+location.x, +location.y])

const wgs84Sphere = new ol.Sphere(6378137)

const getDistance = (station1, station2) => round(wgs84Sphere.haversineDistance(getWGS84Coordinate(station1.localisation), getWGS84Coordinate(station2.localisation)), 0)

const EPSG4326 = 'EPSG:4326'

const EPSG3857 = 'EPSG:3857'

export { getWGS84Coordinate, getDefaultCoordinates, getDistance, getWGS84KML, EPSG4326, EPSG3857 }
