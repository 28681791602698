import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ol from 'openlayers'
import ReactDOM from 'react-dom'
import { find, maxBy, minBy } from 'lodash'
import Popup from './Popup'
import { EPSG3857, EPSG4326, getWGS84KML } from './CoordinateUtils'

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            event: {},
            popup: [],
            fixed: false,
        }
    }

    componentWillMount() {
        const { layers, drawFunction, readMode, consultMode, mouseWheelZoom, mapConf } = this.props
        let layersTab = layers.map((el) => el.getLayer())
        if (drawFunction) {
            const source = new ol.source.Vector({ wrapX: false })

            const vector = new ol.layer.Vector({
                source,
            })
            layersTab = [].concat(layersTab, vector)
            this.source = source
        }
        let interactions = {}
        if (readMode) {
            interactions = ol.interaction.defaults({
                doubleClickZoom: false,
                dragAndDrop: false,
                dragPan: false,
                keyboardPan: false,
                keyboardZoom: false,
                mouseWheelZoom: false,
                pointer: false,
                select: false,
            })
        } else if (consultMode) {
            interactions = ol.interaction.defaults({
                doubleClickZoom: false,
                dragAndDrop: true,
                dragPan: true,
                keyboardPan: false,
                keyboardZoom: true,
                mouseWheelZoom: true,
                pointer: false,
                select: false,
            })
        } else {
            interactions = ol.interaction.defaults({ mouseWheelZoom })
        }
        const map = new ol.Map({
            layers: layersTab,
            interactions,
            view: new ol.View({
                center: ol.proj.fromLonLat(mapConf.center),
                zoom: mapConf.zoom,
            }),
        })
        const ghostZoom = map.getView().getZoom()
        const self = this
        map.on('moveend', () => {
            if (ghostZoom != map.getView().getZoom()) {
                self.setState({ zoom: map.getView().getZoom() })
            }
            self.props.onMoveEnd(map)
        })

        map.on('pointermove', (e) => {
            const pixel = map.getEventPixel(e.originalEvent)
            const hit = map.hasFeatureAtPixel(pixel)
            map.getTarget().style.cursor = hit ? 'pointer' : ''
        })

        this.olMap = map
        if (drawFunction) {
            const draw = new ol.interaction.Draw({
                source: this.source,
                type: 'Circle',
            })
            map.addInteraction(draw)
            this.listener = ''
            this.coord = ''
            this.radius = ''
            this.measureTooltipElement = ''
            this.measureTooltip = ''
            this.createMeasureTooltip()
            draw.on(
                'drawstart',
                (evt) => {
                    evt.feature.getGeometry().on('change', (event) => {
                        const units = map.getView().getProjection().getUnits()
                        self.listener = event.target
                        self.measureTooltipElement.innerHTML = (
                            (self.listener.getRadius() * ol.proj.METERS_PER_UNIT[units])
                            / 1000
                        ).toFixed(2)
                        self.measureTooltip.setPosition(self.listener.getLastCoordinate())
                    })
                },
                this,
            )
            draw.on(
                'drawend',
                function (evt) {
                    self.coord = ol.proj.transform(
                        evt.feature.getGeometry().getFirstCoordinate(),
                        EPSG3857,
                        EPSG4326,
                    )
                    self.radius = self.listener.getRadius() / 1000
                    self.props.radius(this.coord, this.radius)
                },
                this,
            )
        }
    }

    createMeasureTooltip = () => {
        if (this.measureTooltipElement) {
            this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement)
        }
        this.measureTooltipElement = document.createElement('div')
        this.measureTooltipElement.className = 'tooltip tooltip-measure'
        this.measureTooltip = new ol.Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        })
        this.olMap.addOverlay(this.measureTooltip)
    }

    componentDidMount() {
        const map = ReactDOM.findDOMNode(this.refs.map)
        this.olMap.setTarget(map)

        if (this.props.mapConf.extent && this.props.mapConf.extent.length == 4) {
            this.olMap
                .getView()
                .fit(
                    ol.proj.transformExtent(this.props.mapConf.extent, EPSG4326, EPSG3857),
                    this.olMap.getSize(),
                )
        }
        if (this.props.fixed) {
            this.setState({ fixed: true })
        }
    }

    flatDeep = (arr) => arr.reduce((acc, val) => (val[0] != undefined && Array.isArray(val[0])
        ? acc.concat(this.flatDeep(val))
        : acc.concat([val])), [])

    componentWillReceiveProps(nextProps) {
        const added = nextProps.layers.filter((next) => !this.props.layers.find((prec) => prec === next))

        added.forEach((element) => {
            this.olMap.addLayer(element.getLayer())
        }, this)

        const removed = this.props.layers.filter((prec) => !nextProps.layers.find((next) => prec === next))

        removed.forEach((element) => {
            this.olMap.removeLayer(element.getLayer())
        }, this)

        if (
            nextProps.mapConf.center[0] !== this.props.mapConf.center[0]
            || nextProps.mapConf.center[1] !== this.props.mapConf.center[1]
        ) {
            this.olMap.getView().setCenter(ol.proj.fromLonLat(nextProps.mapConf.center))
        }
        if (nextProps.mapConf.zoom !== this.props.mapConf.zoom) {
            this.olMap.getView().setZoom(nextProps.mapConf.zoom)
        }
        if (
            nextProps.mapConf.extent
            && nextProps.mapConf.extent !== this.props.mapConf.extent
            && nextProps.mapConf.extent.length === 4
        ) {
            this.olMap
                .getView()
                .fit(
                    ol.proj.transformExtent(nextProps.mapConf.extent, EPSG4326, EPSG3857),
                    this.olMap.getSize(),
                )
        }
        if (nextProps.mapConf.wfscenter) {
            const findWFS = find(nextProps.layers, (o) => o.layer.type == 'VECTOR')
            if (findWFS) {
                if (findWFS.layer.getSource().getFeatures().length) {
                    const tmp = (() => {
                        const tmpGeometry = findWFS.layer
                            .getSource()
                            .getFeatures()[0]
                            .getGeometry()
                            .getCoordinates()
                        return this.flatDeep(tmpGeometry)
                    })()
                    const minX = minBy(tmp, (o) => o[0])
                    const maxX = maxBy(tmp, (o) => o[0])
                    const minY = minBy(tmp, (o) => o[1])
                    const maxY = maxBy(tmp, (o) => o[1])
                    const minCoordinate = getWGS84KML({ x: minX[0], y: minY[1] })
                    const maxCoordinate = getWGS84KML({ x: maxX[0], y: maxY[1] })
                    const extent = [
                        minCoordinate[0],
                        minCoordinate[1],
                        maxCoordinate[0],
                        maxCoordinate[1],
                    ]
                    this.olMap
                        .getView()
                        .fit(
                            ol.proj.transformExtent(extent, EPSG4326, EPSG3857),
                            this.olMap.getSize(),
                        )
                }
            }
        }
    }

    clickMap = (e) => {
        e.preventDefault()
        if (!this.props.drawFunction) {
            this.setState({ event: { clientX: e.clientX, clientY: e.clientY } })
            if (this.props.event) {
                const coordinate = this.olMap.getCoordinateFromPixel(
                    this.olMap.getEventPixel({
                        clientX: e.clientX,
                        clientY: e.clientY,
                    }),
                )
                const pointTransform = ol.proj.transform(coordinate, EPSG3857, EPSG4326)
                this.props.event(pointTransform, this.state.zoom)
            }
        }
    }

    render() {
        if (this.props.marker) {
            const layer = new ol.layer.Vector({
                source: new ol.source.Vector({
                    features: [
                        new ol.Feature({
                            geometry: new ol.geom.Point(
                                ol.proj.fromLonLat(this.props.mapConf.center),
                            ),
                        }),
                    ],
                }),
            })
            this.olMap.addLayer(layer)
        }
        return (
            <div
                id="map"
                className={`sieau-ol-map ${this.props.className || ''} ${
                    this.state.fixed && 'fixed'
                }`}
                style={{ height: `${this.props.mapConf.size}px` }}
                ref="map"
                onClick={this.clickMap}
            >
                <Popup
                    olMap={this.olMap}
                    layers={this.props.layers}
                    event={this.state.event}
                    popupProps={this.props.popupProps}
                />
            </div>
        )
    }

    componentDidUpdate(nextProps) {
        if (this.props.fullScreen !== nextProps.fullScreen) {
            this.olMap.updateSize()
        }
    }
}

Map.propTypes = {
    layers: PropTypes.arrayOf(PropTypes.object),
    popupProps: PropTypes.shape({ filters: PropTypes.arrayOf(PropTypes.string) }),
    mapConf: PropTypes.shape({
        size: PropTypes.number,
        center: PropTypes.array,
        extent: PropTypes.array,
        zoom: PropTypes.number,
    }),
    event: PropTypes.func,
    drawFunction: PropTypes.bool,
    className: PropTypes.bool,
    fixed: PropTypes.bool,
    radius: PropTypes.func,
    mouseWheelZoom: PropTypes.bool,
    onMoveEnd: PropTypes.func,
    fullScreen: PropTypes.bool,
}

Map.defaultProps = {
    mapConf: {
        size: 300,
        center: [1.75, 47.5],
        zoom: 6,
    },
    layers: [],
    popupProps: { filters: [] },
    drawFunction: false,
    event() {},
    radius() {},
    zoom: 10,
    mouseWheelZoom: true,
    onMoveEnd: () => {},
}

export default Map
