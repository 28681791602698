import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import i18n from 'simple-react-i18n'
import TextField from '@material-ui/core/TextField'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import TerritoryAction from '../actions/TerritoryAction'
import { Flex, Right } from '../../../styles/main'
import Input from '../../../components/forms/Input'
import { GreenButton } from '../../../styles/button'
import { getLogin } from '../../../utils/SettingUtils'

const Wrapper = styled(Flex)`
    padding: 1.5rem 0 0 0;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

function ModalNewMatrice({ open, handleClose, createMatrice, tid }) {
    const [newNameMatrice, setNewNameMatrice] = useState('')
    const [newMatriceDescription, setNewMatriceDescription] = useState('')
    const [isFav, setIsFav] = useState(false)

    const closeModal = () => {
        handleClose()
    }

    const closeAndSaveNewMatrice = () => {
        closeModal()
        const createNewMatrice = {
            mid: -1,
            name: newNameMatrice,
            descriptif: newMatriceDescription,
            tid,
            indicateurs: [],
            indicateursValues: [],
            favoris: isFav,
            usermaj: getLogin(),
        }
        createMatrice(createNewMatrice)
    }

    const SetDescription = (event) => {
        setNewMatriceDescription(event.target.value)
    }

    const onClickFav = () => {
        setIsFav(!isFav)
    }

    const inputStyle = {
        width: '200px',
        height: '48px',
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={closeModal}
        >
            <DialogTitle>{i18n.addMatrice}</DialogTitle>
            <DialogContent>
                <Right>
                    <Icon
                        onClick={(e) => onClickFav(e)}
                        style={{ color: isFav ? '#ff9700' : '' }}
                    >
                        star
                    </Icon>
                </Right>
                <Wrapper>
                    <div>
                        <Label htmlFor="new_matrice_name">{i18n.name}&nbsp;:</Label>
                        <Input
                            style={inputStyle}
                            onChange={(event) => setNewNameMatrice(event)}
                        />
                    </div>
                </Wrapper>
                <div>
                    <Label htmlFor="new_matrice_description" style={{ margin: '1rem 0' }}>
                        {i18n.descriptive}&nbsp;:
                    </Label>
                    <TextField
                        title={i18n.descriptive}
                        style={{ width: '500px' }}
                        onChange={SetDescription}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <GreenButton
                    greenReverse
                    borderRadius
                    style={{ margin: '0 1rem' }}
                    onClick={closeModal}
                >
                    {i18n.cancel}
                </GreenButton>
                <GreenButton borderRadius onClick={closeAndSaveNewMatrice}>
                    {i18n.add}
                </GreenButton>
            </DialogActions>
        </Dialog>
    )
}

ModalNewMatrice.defaultProps = {
    culturesData: [],
    conduitesData: [],
    indicateursData: [],
    zonesData: [],
    createMatrice: [],
    open: false,
}
ModalNewMatrice.propTypes = {
    zonesData: PropTypes.shape({}),
    culturesData: PropTypes.shape({}),
    conduitesData: PropTypes.shape({}),
    indicateursData: PropTypes.shape({}),
    createMatrice: PropTypes.func,
    tid: PropTypes.number.isRequired,
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
}
const mapStateToProps = (store) => ({
    tid: store.TerritoryReducer.territoire.id,
    matrices: store.TerritoryReducer.matrices,
})

const mapDispatchToProps = {
    createMatrice: TerritoryAction.createMatrice,
    fetchTerritory: TerritoryAction.fetchTerritory,
    fetchAssolementsTerritoires: TerritoryAction.fetchAssolementsTerritoires,
    fetchZonesSurfacesTerritoires: TerritoryAction.fetchZonesSurfacesTerritoires,
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalNewMatrice)
