/* eslint-disable import/no-cycle */
import { orderBy } from 'lodash'
import {
    RECEIVE_CHECK_DELETE,
    RECEIVE_REFERENCIAL_DOMAIN,
    RECEIVE_REFERENCIAL_SANDRE_CODES,
    RECEIVE_REFERENCIAL_STATUS,
    RECEIVE_REFERENCIAL_SUPPORT,
    RESET_CHECK_DELETE,
    RECEIVE_ALL_DATA_TYPE,
    RECEIVE_CULTURES,
    RECEIVE_CULTURE,
    RECEIVE_CONDUITES,
    RECEIVE_CONDUITES_PARENTS,
    RECEIVE_ZONES,
    RECEIVE_COMMUNES,
    RECEIVE_ROTATIONS_AB,
    RECEIVE_ROTATIONS_ECO,
    RECEIVE_INDICATEURS,
    RECEIVE_INDICATEUR,
    RECEIVE_CULTURES_RPG,
    RECEIVE_RPG,
    RECEIVE_SANDRE_CODES,
    RECEIVE_MATRICE_REF,
} from '../constants/ReferencialConstants'
import DtoReferencial from '../dto/DtoReferencial'
import DtoReferencialItem from '../dto/DtoReferencialItem'
import DtoContributor from '../components/contributor/dto/DtoContributor'
import ContactDto from '../components/contact/dto/ContactDto'
import CultureDto from '../components/cultures/dto/CultureDto'
import DtoCultureRPG from '../components/cultures/dto/DtoCultureRPG'
import RotationDto from '../components/rotation/dto/RotationDto'
import ModeConduiteDto from '../components/conduites/dto/ModeConduiteDto'
import DtoZone from '../components/zones/dto/DtoZone'
import CommuneDto from '../components/communes/dto/CommuneDto'
import IndicateurDto from '../components/indicateurs/dto/IndicateurDto'
import ReferencialAction from '../action/ReferencialAction'
import ContributorAction from '../components/contributor/actions/ContributorAction'
import ContactAction from '../components/contact/actions/ContactAction'
import { arrayOf, createIndex, instanceOf } from '../../../utils/StoreUtils'
import AppStore from '../../../store/Appstore'
import ContributorTypeDto from '../components/contributor/dto/ContributorTypeDto'
import DtoReferencialInt from '../dto/DtoReferencialInt'
import DtoDataType from '../dto/DtoDataType'
import ReferencialSandreCodeDto from '../dto/ReferencialSandreCodeDto'
import DtoRPG from '../components/cultures/dto/DtoRPG'
import DtoSandreCode from '../dto/DtoSandreCode'
import DtoMatriceRef from '../components/cultures/dto/DtoMatriceRef'

export function ReferencialReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CULTURES:
            return {
                ...state,
                cultures: action.cultures.map((culture) => new CultureDto(culture)),
            }
        case RECEIVE_CULTURES_RPG:
            return {
                ...state,
                culturesRPG: action.culturesRPG.map((c) => new DtoCultureRPG(c)),
            }
        case RECEIVE_RPG:
            return {
                ...state,
                rpg: action.rpg.map((d) => new DtoRPG(d)),
            }
        case RECEIVE_MATRICE_REF:
            return {
                ...state,
                matriceRef: action.matriceRef.map((d) => new DtoMatriceRef(d)),
            }
        case RECEIVE_CULTURE:
            return {
                ...state,
                culture: new CultureDto(action.culture),
            }
        case RECEIVE_CONDUITES:
            const conduites = action.conduites.map((conduite) => new ModeConduiteDto(conduite))
            return {
                ...state,
                conduites,
            }
        case RECEIVE_CONDUITES_PARENTS:
            const conduitesParents = action.conduitesParents.map(
                (parent) => new ModeConduiteDto(parent),
            )
            return {
                ...state,
                conduitesParents,
            }
        case RECEIVE_ZONES:
            const zones = action.zones.map((zone) => new DtoZone(zone))
            return {
                ...state,
                zones,
            }
        case RECEIVE_COMMUNES:
            const communes = orderBy(action.communes, 'id').map((commune) => new CommuneDto(commune))
            return {
                ...state,
                communes,
                citiesIndex: createIndex(communes),
            }
        case RECEIVE_ROTATIONS_AB:
            const rotationsAB = action.rotationsAB.map((rotationAB) => new RotationDto(rotationAB))
            return {
                ...state,
                rotationsAB,
            }
        case RECEIVE_ROTATIONS_ECO:
            const rotationsEco = action.rotationsEco.map(
                (rotationEco) => new RotationDto(rotationEco),
            )
            return {
                ...state,
                rotationsEco,
            }
        case RECEIVE_INDICATEURS:
            const indicateurs = action.indicateurs.map(
                (indicateur) => new IndicateurDto(indicateur),
            )
            return {
                ...state,
                indicateurs,
            }
        case RECEIVE_INDICATEUR:
            const indicateur = new IndicateurDto(action.indicateur)
            return {
                ...state,
                indicateur,
            }
        case RECEIVE_REFERENCIAL_SANDRE_CODES:
            return {
                ...state,
                referencialSandreCodes: action.referencialSandreCodes.map(
                    (referencialSandreCode) => new ReferencialSandreCodeDto(referencialSandreCode),
                ),
            }
        case RECEIVE_SANDRE_CODES:
            return {
                ...state,
                sandreCodes: action.sandreCodes.map((c) => new DtoSandreCode(c)),
            }
        case RECEIVE_REFERENCIAL_DOMAIN:
            return {
                ...state,
                referencialDomain: action.referencialDomain.map(
                    (referencialDomain) => new DtoReferencial(referencialDomain),
                ),
            }
        case RECEIVE_REFERENCIAL_STATUS:
            return {
                ...state,
                referencialStatus: action.referencialStatus.map(
                    (referencialStatus) => new DtoReferencialInt(referencialStatus),
                ),
            }
        case RECEIVE_REFERENCIAL_SUPPORT:
            return {
                ...state,
                referencialSupport: action.supports.map(
                    (referencialSupport) => new DtoReferencialItem(referencialSupport),
                ),
            }
        case RECEIVE_CHECK_DELETE:
            return {
                ...state,
                checkDelete: action.checkDelete,
            }
        case RESET_CHECK_DELETE:
            return {
                ...state,
                checkDelete: null,
            }
        case RECEIVE_ALL_DATA_TYPE:
            return {
                ...state,
                dataTypes: action.dataTypes.map((o) => new DtoDataType(o)),
            }
        default:
            return state
    }
}

export const store = {
    referencialStatus: [],
    referencialDomain: [],
    referencialSupport: [],
    stationTypes: [],
    sandreCodes: [],
    referencialSandreCodes: [],
    checkDelete: null,
    dataTypes: [],
    culturesRPG: [],
    rpg: [],
    cultures: [],
    culture: {},
    conduites: [],
    conduitesParents: [],
    zones: [],
    communes: [],
    citiesIndex: {},
    rotationsAB: [],
    rotationsEco: [],
    indicateurs: [],
    indicateur: {},
    matriceRef: [],
}

export const ReferencialStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        referencial: {
            reset: ReferencialAction.resetReferencial,
        },
        referencialSandreCodes: {
            fetch: ReferencialAction.fetchReferencialSandreCodes,
            store: appStore.ReferencialReducer.referencialSandreCodes,
            propType: instanceOf(ReferencialSandreCodeDto),
        },
        contributor: {
            fetch: ContributorAction.fetchContributor,
            store: appStore.ContributorReducer.contributor,
            propType: instanceOf(DtoContributor),
        },
        contributors: {
            fetch: ContributorAction.fetchContributors,
            store: appStore.ContributorReducer.contributors,
            propType: arrayOf(DtoContributor),
        },
        contributorTypes: {
            fetch: ContributorAction.fetchContributorTypes,
            store: appStore.ContributorReducer.contributorTypes,
            propType: arrayOf(ContributorTypeDto),
        },
        contact: {
            fetch: ContactAction.fetchContact,
            store: appStore.ContactReducer.contact,
            propType: instanceOf(ContactDto),
        },
        contacts: {
            fetch: ContactAction.fetchContacts,
            store: appStore.ContactReducer.contacts,
            propType: arrayOf(ContactDto),
        },
        cultures: {
            fetch: ReferencialAction.fetchCultures,
            store: appStore.ReferencialReducer.cultures,
            propType: arrayOf(CultureDto),
        },
        conduites: {
            fetch: ReferencialAction.fetchConduites,
            store: appStore.ReferencialReducer.conduites,
            propType: arrayOf(ModeConduiteDto),
        },
        zones: {
            fetch: ReferencialAction.fetchZones,
            store: appStore.ReferencialReducer.zones,
            propType: arrayOf(DtoZone),
        },
    }
}
