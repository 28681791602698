import DtoReferencial from '../../../dto/DtoReferencial'

export default class RotationDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.labelWithCode = `${object.name} [${object.id}]`
        this.scid = object.scid
        this.id = object.id
        this.min = object.min
        this.max = object.max
        this.ensemble = object.ensemble
        this.cultureType = object.cultureType
        this.toutesCultures = object.toutesCultures
        this.respect = object.respect
        this.cultures = object.cultures ? object.cultures : []
        this.headers = ['id']
    }
}
