/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, DialogActions, Icon, Grid } from '@material-ui/core'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import { grey } from '../../../styles/theme'
import AgGridTable from '../../../components/datatable/AgGridTable'
import { GreenButton } from '../../../styles/button'
import ReferencialAction from '../../referencial/action/ReferencialAction'

const ModalTitle = styled.h5`
    font-size: 1.5rem;
    font-weight: 600;
`

const Subtitle = styled.p`
    margin: 0.5rem 0;
`

const TextStyle = styled.div`
    margin: 2rem 0;
    font-size: 1.6rem;
    text-decoration: none;
    color: ${grey};
    &:hover {
        color: black;
    }
`

const IconStyle = styled(Icon)`
    font-size: 3rem !important;
    color: ${grey};
    opacity: 0.6;
    margin: 23px 15px 0 0 !important;

    &:hover {
        opacity: 1;
    }
`

export function getModalStyle() {
    const top = 60
    const left = 60

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '10px 20px 0px',
    },
}))

const ModalBricksObjectifs = ({
    iconName,
    number,
    text,
    zone,
    zonesData,
    culture,
    culturesData,
    driveMode,
    conduitesData,
    onChange,
    selectedZones,
    selectedCultures,
    selectedModesConduites,
    onSave,
    currentZones,
    currentCultures,
    currentModesConduites,
    clickable,
    typeKey,
    objectifId,
    isEditMode,
    error,
}) => {
    const classes = useStyles()
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle)
    const [open, setOpen] = useState(false)
    const [zones] = useState(zonesData)
    const [cultures] = useState(culturesData)
    const [conduites] = useState(conduitesData)

    const columnDefs = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { headerName: i18n.name, field: 'name' },
    ]

    const columnDefsConduites = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { headerName: i18n.name, field: 'name' },
        { headerName: i18n.parent, field: 'parent' },
    ]

    const columnDefsCultures = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        {
            headerName: i18n.name,
            field: 'name',
        },
        {
            headerName: i18n.teteRotation,
            field: 'teteRotation',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.legumineuse,
            field: 'legumineuse',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.periodeSemence,
            field: 'periodeSemence',
        },
        {
            headerName: i18n.referencial,
            field: 'referentiel',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
    ]

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const closeAndReset = (type, value) => {
        handleClose()
        onChange(type, value)
    }

    const closeAndSave = (type, value) => {
        handleClose()
        onSave(type, value)
    }

    const tableStyle = {
        height: '30vh',
        width: '100%',
        marginBottom: '3.5rem',
    }

    return (
        <>
            <div>
                <Grid container alignItems="center">
                    <div onClick={clickable ? handleOpen : () => {}} className={clickable ? 'clickable' : ''}>
                        <IconStyle>{iconName}</IconStyle>
                    </div>
                    <div onClick={clickable ? handleOpen : () => {}} className={clickable ? 'clickable' : ''}>
                        <TextStyle style={error ? { color: 'red' } : {}}>
                            <span>{number}</span>&nbsp;
                            <span>{text}</span>
                        </TextStyle>
                    </div>
                </Grid>
                <Modal
                    style={{ width: '100%' }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={handleClose}
                    disableBackdropClick
                >
                    <div style={modalStyle} className={classes.paper}>
                        {zone && (
                            <>
                                <ModalTitle>{i18n.addOrCreateZones}</ModalTitle>
                                <Subtitle>{i18n.territoryZonesList}&nbsp;:</Subtitle>
                                <div className="ag-theme-balham" style={tableStyle}>
                                    <AgGridTable
                                        columnDefs={columnDefs}
                                        rowData={zones}
                                        onChange={onChange}
                                        tableKey="currentZones"
                                        selectedData={selectedZones}
                                    />
                                </div>
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(
                                                    this,
                                                    'zones',
                                                    selectedZones,
                                                )}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'zones',
                                                    objectifId || currentZones,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton onClick={handleClose} borderRadius>
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                        {culture && (
                            <>
                                <ModalTitle>{i18n.addOrCreateCultures}</ModalTitle>
                                <Subtitle>{i18n.territoryCulturesList}&nbsp;:</Subtitle>
                                <div className="ag-theme-balham" style={tableStyle}>
                                    <AgGridTable
                                        columnDefs={columnDefsCultures}
                                        rowData={cultures}
                                        onChange={onChange}
                                        tableKey="currentCultures"
                                        selectedData={selectedCultures}
                                    />
                                </div>
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(
                                                    this,
                                                    'cultures',
                                                    selectedCultures,
                                                )}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'cultures',
                                                    objectifId || currentCultures,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton onClick={handleClose} borderRadius>
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                        {driveMode && (
                            <>
                                <ModalTitle>{i18n.addOrCreateDrivenMode}</ModalTitle>
                                <Subtitle>{i18n.territoryDrivenModeList}&nbsp;:</Subtitle>
                                <div className="ag-theme-balham" style={tableStyle}>
                                    <AgGridTable
                                        columnDefs={columnDefsConduites}
                                        rowData={conduites}
                                        onChange={onChange}
                                        tableKey="currentModesConduites"
                                        selectedData={selectedModesConduites}
                                    />
                                </div>
                                <DialogActions>
                                    {isEditMode ? (
                                        <>
                                            <GreenButton
                                                onClick={closeAndReset.bind(
                                                    this,
                                                    'modesConduites',
                                                    selectedModesConduites,
                                                )}
                                                greenReverse
                                                borderRadius
                                                style={{ margin: '0 1rem' }}
                                            >
                                                {i18n.cancel}
                                            </GreenButton>
                                            <GreenButton
                                                onClick={closeAndSave.bind(
                                                    this,
                                                    typeKey || 'modesConduites',
                                                    objectifId || currentModesConduites,
                                                )}
                                                borderRadius
                                            >
                                                {i18n.validate}
                                            </GreenButton>
                                        </>
                                    ) : (
                                        <GreenButton onClick={handleClose} borderRadius>
                                            {i18n.close}
                                        </GreenButton>
                                    )}
                                </DialogActions>
                            </>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    )
}

ModalBricksObjectifs.defaultProps = {
    zone: false,
    culture: false,
    driveMode: false,
    selectedZones: [],
    selectedCultures: [],
    selectedModesConduites: [],
    currentZones: [],
    currentCultures: [],
    currentModesConduites: [],
    clickable: true,
    typeKey: '',
    objectifId: null,
    isEditMode: true,
}

ModalBricksObjectifs.propTypes = {
    iconName: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    zone: PropTypes.bool,
    culture: PropTypes.bool,
    driveMode: PropTypes.bool,
    zonesData: PropTypes.shape({}).isRequired,
    culturesData: PropTypes.shape({}).isRequired,
    conduitesData: PropTypes.shape({}).isRequired,
    indicateursData: PropTypes.shape({}).isRequired,
    selectedZones: PropTypes.arrayOf(PropTypes.object),
    selectedCultures: PropTypes.arrayOf(PropTypes.object),
    selectedModesConduites: PropTypes.arrayOf(PropTypes.object),
    currentZones: PropTypes.arrayOf(PropTypes.object),
    currentCultures: PropTypes.arrayOf(PropTypes.object),
    currentModesConduites: PropTypes.arrayOf(PropTypes.object),
    clickable: PropTypes.bool,
    typeKey: PropTypes.string,
    objectifId: PropTypes.number,
    isEditMode: PropTypes.bool,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    error: PropTypes.bool,
}

const mapDispatchToProps = {
    createZone: ReferencialAction.createZone,
    createCulture: ReferencialAction.createCulture,
    createConduite: ReferencialAction.createConduite,
    createIndicateur: ReferencialAction.createIndicateur,
}

export default connect(null, mapDispatchToProps)(ModalBricksObjectifs)
