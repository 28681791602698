/* eslint-disable import/no-cycle */
import fetch from 'isomorphic-fetch'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import { WaitAction } from 'react-aquasys'
import ToastrAction from '../../../utils/ToastrAction'
import {
    ACTIONS,
    DISCONNECTED,
    LOGIN_FAIL,
    CCE_LOGIN,
    CCE_TOKEN,
    SHOW_WELCOME_POPUP,
    TITLE,
    UPDATE_GLOBAL_RESEARCH,
    ADD_NOTIFICATION,
    RECEIVE_NOTIFICATIONS,
    READ_NOTIFICATION,
    HELP_PATH,
} from '../constants/HomeConstants'
import ApplicationConf from '../../../conf/ApplicationConf'
import {
    checkAuth,
    getAuthorization,
    getAuthorizationLogin,
    getJson,
} from '../../../utils/ActionUtils'
import AppStore from '../../../store/Appstore'
import UserAction from '../../administration/components/user/actions/UserAction'
import {
    POPUP,
    POPUP_CLOSED,
    OPEN_POPUP,
    RESET_POPUP,
    faqUrl,
} from '../../../conf/SieauConstants'
import { getSetting } from '../../../utils/SettingUtils'
import {
    RECEIVE_PARTENAIRES_CMS,
    RECEIVE_SLIDER_CMS,
    RECEIVE_SLIDER_STEPS_CMS,
    RECEIVE_HOME_CMS,
    RECEIVE_HOME_DOCUMENTS_CMS,
    RECEIVE_HOME_SOCIAL_CMS,
    RECEIVE_HOME_NEWSLETTER_CMS,
} from '../../events/constants/EventsConstants'

const HomeAction = {
    receiveCmsPartenaires(cmsPartenaires) {
        return { type: RECEIVE_PARTENAIRES_CMS, cmsPartenaires }
    },

    fetchCmsPartenaires() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(2), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsPartenaires(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    receiveCmsSlider(cmsSlider) {
        return { type: RECEIVE_SLIDER_CMS, cmsSlider }
    },

    fetchCmsSlider() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(6), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsSlider(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    receiveCmsSliderSteps(cmsSliderSteps) {
        return { type: RECEIVE_SLIDER_STEPS_CMS, cmsSliderSteps }
    },

    fetchCmsSliderSteps() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(5), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsSliderSteps(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    receiveCmsHome(cmsHome) {
        return { type: RECEIVE_HOME_CMS, cmsHome }
    },

    fetchCmsHome() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(3), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsHome(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    receiveCmsHomeDocuments(cmsHomeDocuments) {
        return { type: RECEIVE_HOME_DOCUMENTS_CMS, cmsHomeDocuments }
    },

    fetchCmsHomeDocuments() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(4), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsHomeDocuments(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    receiveCmsHomeSocial(cmsHomeSocial) {
        return { type: RECEIVE_HOME_SOCIAL_CMS, cmsHomeSocial }
    },

    fetchCmsHomeSocial() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(7), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsHomeSocial(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    receiveCmsHomeNewsLetter(cmsHomeNewsLetter) {
        return { type: RECEIVE_HOME_NEWSLETTER_CMS, cmsHomeNewsLetter }
    },

    fetchCmsHomeNewsLetter() {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEventsByCategory(8), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(getJson)
            .then((json) => {
                dispatch(HomeAction.receiveCmsHomeNewsLetter(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
            })
    },

    setPopup(popup) {
        return { type: POPUP, popup }
    },
    setPopupIsClosed() {
        return { type: POPUP_CLOSED }
    },
    openPopup() {
        return { type: OPEN_POPUP }
    },
    resetPopup() {
        return { type: RESET_POPUP }
    },
    loginFail: (message) => ({
        type: LOGIN_FAIL,
        message,
    }),
    disconnected: (message) => ({
        type: DISCONNECTED,
        message,
    }),
    login: (login, password) => (dispatch) => {
        dispatch(WaitAction.waitStart())
        const basicAuth = btoa(`${login}:${password}`)
        return fetch(ApplicationConf.login(), {
            method: 'POST',
            headers: {
                Authorization: `Basic ${basicAuth}`,
                module: 'coclickeau',
            },
            body: JSON.stringify({
                module: 'coclickeau',
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                localStorage.setItem(CCE_LOGIN, login)
                localStorage.setItem(CCE_TOKEN, json)
                dispatch(WaitAction.waitStop())
            })
            .catch((err) => {
                console.error(`${i18n.unauthorizedLogin} : ${err}`)
                ToastrAction.error(i18n.unauthorizedLogin, true)
                dispatch(WaitAction.waitStop())
            })
    },
    logout: () => (dispatch) => fetch(ApplicationConf.logout(), {
        method: 'POST',
        headers: getAuthorization(),
    }).then((response) => {
        localStorage.removeItem(CCE_TOKEN)
        dispatch(push('/home'))
        switch (response.status) {
            case 200:
                dispatch(push('/home'))
                ToastrAction.success(i18n.logout, true)
                break
            default:
                // ToastrAction.error(i18n.error)
                // throw new Error(response.statusText)
                console.error(response.statusText)
        }
    }),
    createViewStat(object) {
        return () => fetch(ApplicationConf.user.postUserCGUDate(), {
            method: 'POST',
            headers: getAuthorizationLogin(),
            body: JSON.stringify(object),
        })
    },
    createHomeViewStat() {
        return () => fetch(ApplicationConf.statisticApplication.statisticApplication(), {
            method: 'POST',
            headers: getAuthorizationLogin(),
            body: JSON.stringify({ eventType: 'USAGE', module: 'CCE', value: 'homepage' }),
        })
    },
    createDocumentViewStats(object) {
        return () => fetch(ApplicationConf.referencial.documentsStats(), {
            method: 'POST',
            headers: getAuthorizationLogin(),
            body: JSON.stringify(object),
        })
    },
    sendMailToAdmin: (mail) => () => fetch(ApplicationConf.mailAdmin(), {
        method: 'POST',
        body: JSON.stringify(mail),
    }).then((response) => {
        switch (response.status) {
            case 200:
                ToastrAction.success(i18n.sendMailAdmin, true)
                break
            default:
                ToastrAction.error(i18n.errorOnSendMail, true)
                throw new Error(response.statusText)
        }
    }),
    sendMailToSupport: (mail) => () => fetch(ApplicationConf.mailSupport(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(mail),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            if (json.error) {
                ToastrAction.error(i18n.errorOnSendMail, true)
            } else {
                ToastrAction.success(i18n.sendMailAdmin, true)
            }
        })
        .catch((err) => {
            console.error(`${i18n.errorOnSendMail} : ${err}`)
            ToastrAction.error(i18n.errorOnSendMail, true)
        }),
    showWelcomePopup: (value) => ({
        type: SHOW_WELCOME_POPUP,
        value,
    }),
    setActions: (actions) => ({
        type: ACTIONS,
        actions,
    }),
    updateGlobalResearch: (value) => ({
        type: UPDATE_GLOBAL_RESEARCH,
        value,
    }),
    setTitle(title) {
        const storeTitleHash = AppStore.getState()
            .HomeReducer.title.map((obj) => obj.title)
            .join()
        const titleHash = title.map((obj) => obj.title).join()
        if (storeTitleHash !== titleHash) {
            return { type: TITLE, title }
        }
        return { type: TITLE, title }
    },
    setHelpPath(path = '', applicationSettings = []) { // MUST PASS this.props.applicationSettings !!
        const formationToken = getSetting(applicationSettings, 'formationToken')
        const helpUrl = getSetting(applicationSettings, 'FichierAide')
        if (path === 'faq' || !applicationSettings.length || !helpUrl || !formationToken) {
            return { type: HELP_PATH, faqUrl }
        }
        const formattedUrl = helpUrl.endsWith('?tmp=') ? helpUrl.replace(helpUrl.substring(helpUrl.length - 5), '') : helpUrl
        const helpPath = `${formattedUrl}${formattedUrl.endsWith('/') ? '' : '/'}${path}?tmp=${formationToken}`
        return { type: HELP_PATH, helpPath }
    },
    resetPassword(login, redirectLogin = true) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resetPassword(), {
                method: 'POST',
                body: JSON.stringify({ login }),
            })
                .then((response) => checkAuth(response, {
                    404: () => {
                        ToastrAction.error(i18n.noEmailWithLogin, true)
                        dispatch(WaitAction.waitStop())
                    },
                    409: () => {
                        ToastrAction.error(i18n.emailAlreadySend, true)
                        dispatch(WaitAction.waitStop())
                    },
                    200: () => {
                        if (redirectLogin) {
                            dispatch(push('/login'))
                        }
                        ToastrAction.success(i18n.sendEmail, true)
                        dispatch(WaitAction.waitStop())
                        dispatch(UserAction.resetUserAttempts())
                    },
                }))
                .catch((e) => {
                    console.error(
                        `${i18n.fetchError + i18n.retrievePassword} : ${e}`,
                    )
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword, true)
                    dispatch(WaitAction.waitStop())
                })
        }
    },
    resetPasswordByEmail(email, redirectLogin = true) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resetPasswordByEmail(), {
                method: 'POST',
                body: JSON.stringify({ email }),
            })
                .then((response) => checkAuth(response, {
                    404: () => {
                        ToastrAction.error("L'email ne correspond à aucun compte", true)
                        dispatch(WaitAction.waitStop())
                    },
                    409: () => {
                        ToastrAction.error(i18n.emailAlreadySend, true)
                        dispatch(WaitAction.waitStop())
                    },
                    413: () => {
                        ToastrAction.error('Plusieurs comptes sont liés à cet email. Veuillez contacter votre administrateur', true)
                        dispatch(WaitAction.waitStop())
                    },
                    200: () => {
                        if (redirectLogin) {
                            dispatch(push('/login'))
                        }
                        ToastrAction.success(i18n.sendEmail, true)
                        dispatch(WaitAction.waitStop())
                        dispatch(UserAction.resetUserAttempts())
                    },
                }))
                .catch((e) => {
                    console.error(
                        `${i18n.fetchError + i18n.retrievePassword} : ${e}`,
                    )
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword, true)
                    dispatch(WaitAction.waitStop())
                })
        }
    },
    updatePassword(password, token) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resetPassword(), {
                method: 'PUT',
                body: JSON.stringify({ password, token }),
            })
                .then(checkAuth)
                .then(() => {
                    dispatch(push('/login'))
                    ToastrAction.success(i18n.passwordUpdate, true)
                    dispatch(WaitAction.waitStop())
                })
                .catch((e) => {
                    console.error(
                        `${i18n.fetchError + i18n.newPassword} : ${e}`,
                    )
                    ToastrAction.error(i18n.fetchError + i18n.newPassword, true)
                    dispatch(WaitAction.waitStop())
                })
        }
    },
    fetchNotifications() {
        return (dispatch) => {
            dispatch(
                HomeAction.receiveNotifications({
                    treatments: 2,
                    documents: 4,
                }),
            )
        }
    },
    resetNotifications: () => (dispatch) => dispatch(HomeAction.receiveNotifications({})),
    readNotification: (notification) => ({
        type: READ_NOTIFICATION,
        notification,
    }),
    addNotification: (notification) => ({
        type: ADD_NOTIFICATION,
        notification,
    }),
    receiveNotifications: (notifications) => ({
        type: RECEIVE_NOTIFICATIONS,
        notifications,
    }),
}

export default HomeAction
