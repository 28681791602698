import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SubCardList from './SubCardList'

class CardList extends Component {
    getContent = (subLists) => {
        if (this.props.collapsible) {
            return (
                <ul className="sieau-collapsible popout">
                    { subLists }
                </ul>
            )
        }
        return subLists
    }

    render() {
        const subLists = this.props.data.map((subList, i) => (
            <SubCardList
                key={i}
                title={subList.title}
                cards={subList.cards}
                collapsible={this.props.collapsible}
                dontOpenFirst={this.props.dontOpenFirst}
            />
        ))
        return (
            <div>
                { this.getContent(subLists) }
            </div>
        )
    }
}

CardList.defaultProps = {
    collapsible: false,
    dontOpenFirst: false,
}
CardList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        cards: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            onClick: PropTypes.func,
            content: PropTypes.element.isRequired,
        })).isRequired,
    })).isRequired,
    collapsible: PropTypes.bool,
    dontOpenFirst: PropTypes.bool,
}

export default CardList
