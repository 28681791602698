import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../../utils/ToastrAction'
import AppStore from '../../../../store/Appstore'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import HomeAction from '../../../home/actions/HomeAction'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ActionComponent from '../../../../components/actions/ActionComponent'
import AccountAction from '../../actions/AccountAction'
import User from '../../dto/User'
import { Right } from '../../../../styles/main'
import { GreenButton } from '../../../../styles/button'
import ProgressBar from '../../../../components/ProgressBar'

const InputWrapper = styled.div`
    display: ${(props) => props.flex && 'flex'};
`

const ColWidth = styled.div`
    width: ${(props) => (props.width ? props.width : '15%')};
    margin: 0.3rem;
`

const LabelWrapper = styled.span`
    width: ${(props) => (props.short ? '20%' : '34%')};
`

const InputRow = styled.input`
    background-color: ${(props) => props.disabled && '#F0F0F0 !important'};
`

class UpdateUserForm extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            contact: {},
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchCities()
        this.props
            .fetchContact(this.props.accountUser.contactCode)
            .then(() => this.setState({ contact: this.props.contact, dataLoaded: true }))
        this.props.setTitle([
            {
                title: i18n.myAccount,
                href: 'account',
            },
        ])
        this.createActions()
    }

    componentWillReceiveProps() {
        this.createActions()
    }

    createActions = () => {
        if (this.props.editMode) {
            const actions = {
                save: () => {
                    this.saveFunction()
                },
                cancel: () => {
                    this.setState({ dataLoaded: false })
                    this.props.toggleEditMode(false)
                    this.props.fetchContact(this.props.accountUser.contactCode).then(() => {
                        this.setState({ contact: this.props.contact, dataLoaded: true })
                    })
                },
            }
            this.setActions(actions)
        } else {
            const actions = {
                edit: () => {
                    this.props.toggleEditMode(true)
                },
            }
            this.setActions(actions)
        }
    }

    saveFunction = () => {
        const { contact } = this.state
        this.setState({ dataLoaded: false })
        this.props.toggleEditMode(false)
        this.props.toggleLoad(false)
        this.props
            .updateContact(
                { ...contact, datemaj: null },
                !this.props.contacts.find((c) => c.code === contact.code),
            )
            .then(() => {
                this.setState({ dataLoaded: true })
                this.props.toggleLoad(true)
            })
    }

    resetPassword = () => {
        if (!this.props.contact || !this.props.contact.email) {
            ToastrAction.error(i18n.noEmailWithLogin)
        } else {
            AppStore.dispatch(HomeAction.resetPassword(this.props.accountUser.login, false))
        }
    }

    changeCity = (value) => {
        this.setState({
            contact: {
                ...this.state.contact,
                cityCode: value,
            },
        })
        this.props.changeContactValue('cityCode', value.id)
    }

    changeValue = (name, event) => {
        this.setState({
            contact: {
                ...this.state.contact,
                [name]: event.target.value,
            },
        })
        this.props.changeContactValue(name, event.target.value)
    }

    render() {
        const { dataLoaded, contact } = this.state
        const { editMode } = this.props
        if (dataLoaded) {
            return (
                <div className="padding-top-1">
                    <InputWrapper flex>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.name}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                value={contact.name}
                                onChange={(e) => this.changeValue('name', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.email}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                value={contact.email}
                                onChange={(e) => this.changeValue('email', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                    </InputWrapper>
                    <InputWrapper flex>
                        <ColWidth width="65%">
                            <LabelWrapper short>{i18n.address}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                value={contact.address}
                                placeholder="Rue, avenue, etc"
                                onChange={(e) => this.changeValue('address', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                    </InputWrapper>
                    <InputWrapper flex>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.postcode}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                placeholder={i18n.postcode}
                                value={contact.postalBox}
                                maxLength={5}
                                onChange={(e) => this.changeValue('postalBox', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.city}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                placeholder={i18n.city}
                                value={contact.cityCode}
                                onChange={(e) => this.changeValue('cityCode', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                    </InputWrapper>
                    <InputWrapper flex>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.mobile}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                value={contact.mobile}
                                onChange={(e) => this.changeValue('mobile', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.phoneTel}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                value={contact.phoneTel}
                                onChange={(e) => this.changeValue('phoneTel', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                        <ColWidth width="32%">
                            <LabelWrapper>{i18n.desktopTel}&nbsp;:</LabelWrapper>
                            <InputRow
                                className="sieau-input form-control"
                                value={contact.desktopTel}
                                onChange={(e) => this.changeValue('desktopTel', e)}
                                disabled={!editMode}
                            />
                        </ColWidth>
                    </InputWrapper>
                    <Right>
                        <Tooltip title={i18n.resetPasswordExplanation}>
                            <GreenButton borderRadius onClick={() => this.resetPassword()}>
                                {i18n.passwordReset}
                            </GreenButton>
                        </Tooltip>
                    </Right>
                </div>
            )
        }
        return <ProgressBar />
    }
}

UpdateUserForm.propTypes = {
    contact: PropTypes.instanceOf(ContactDto),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    communes: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    changeContactValue: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
}
const mapStateToProps = (store) => ({
    contacts: store.ContactReducer.contacts,
    contact: store.ContactReducer.contact,
    communes: store.ReferencialReducer.communes,
    accountUser: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchContact: ContactAction.fetchContact,
    fetchCities: CityAction.fetchCities,
    updateContact: AccountAction.updateContact,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserForm)
