import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { filter, isEqual, orderBy, reduce, take } from 'lodash'
import DtoUserStatistic from '../../../dto/DtoUserStatistic'
import 'echarts/lib/chart/bar'
import Table from '../../../../../../../components/datatable/Table'
import DtoUserStatisticItem from '../../../dto/DtoUserStatisticItem'
import { getDate, getFullDateMini } from '../../../../../../../utils/DateUtil'
import i18n from 'simple-react-i18n'
import DtoUser from '../../../dto/DtoUser'
import { getModuleValue } from '../../../../../../../utils/StatisticUtil'

class StatisticsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userStatistics: this.props.account
                ? this.props.accountStatistics
                : this.props.userStatistics,
            user: this.props.account ? this.props.accountUser : this.props.user,
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            !isEqual(nextProps.userStatistics, this.state.userStatistics)
            || !isEqual(nextProps.user, this.state.user)
        )
    }

    getReduceStatistics = (statistics) => reduce(
        statistics,
        (a, b) => {
            if (!a[`${getDate(b.statisticDate)}_${b.value}`]) {
                a[`${getDate(b.statisticDate)}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${getDate(b.statisticDate)}_${b.value}`].datas.push(b)
            return a
        },
        {},
    )

    getDatas = (statistics) => {
        const reduceValues = this.getReduceStatistics(statistics)
        return orderBy(
            Object.keys(reduceValues).map((o) => {
                const statisticObject = reduceValues[o]
                const listOfStatistics = statisticObject.datas
                const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
                return new DtoUserStatisticItem({
                    module: statisticObject.module,
                    value: getModuleValue(statisticObject.value),
                    lastAccess: getFullDateMini(lastVisit.statisticDate),
                    statisticDate: lastVisit.statisticDate,
                    nbAccess: listOfStatistics.length,
                })
            }),
            'statisticDate',
            'desc',
        )
    }

    render() {
        const statistics = filter(this.state.userStatistics, (o) => o.value)
        if (statistics.length) {
            const data = this.getDatas(statistics)
            return (
                <Table
                    title={`5 ${i18n.userStatistics}`}
                    type={new DtoUserStatisticItem({})}
                    data={take(data, 5)}
                    exportButtonOnHeader
                    exportData={data}
                    showNbElements={false}
                    exportName={i18n.userStatisticsExport + this.state.user.login}
                />
            )
        }
        return null
    }
}

StatisticsPanel.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    user: PropTypes.instanceOf(DtoUser),
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
}
const mapStateToProps = (store) => ({
    accountStatistics: store.AccountReducer.accountStatistics,
    accountUser: store.AccountReducer.accountUser,
    userStatistics: store.UserReducer.userStatistics,
    user: store.UserReducer.user,
})

export default connect(mapStateToProps)(StatisticsPanel)
