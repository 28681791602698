import DtoReferencial from '../../../dto/DtoReferencial'

export default class IndicateurDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.labelWithCode = `${object.name} [${object.id}]`
        this.id = object.id
        this.icid = object.icid
        this.name = object.name
        this.referentiel = object.referentiel
        this.headers = ['id', 'icid', 'name']
    }
}
