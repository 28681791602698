import PropTypes from 'prop-types'
import {
    RECEIVE_CMS_CATEGORIES,
    RECEIVE_CMS_EVENT,
    RECEIVE_CMS_EVENTS,
    RECEIVE_CMS_DOCUMENT,
    RECEIVE_EVENT,
    RECEIVE_HYDROMETRY_STATION_ALL_EVENTS,
    RECEIVE_INSTALLATION_ALL_EVENTS,
    RECEIVE_MONITORED_STATIONS_EVENTS,
    RECEIVE_PIEZOMETRY_ALL_EVENTS,
    RECEIVE_QUALITOMETRY_ALL_EVENTS,
    RECEIVE_STATION_EVENTS,
    RESET_CMS_EVENT,
    RESET_EVENT,
    RESET_STATION_EVENTS,
    UPDATE_CMS_CATEGORIES,
    RECEIVE_PIEZOMETER_DIAGNOSTICS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES,
    RECEIVE_PIEZOMETER_SOLUTIONS,
    RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS,
    RECEIVE_PIEZOMETER_ACTIONS,
} from '../constants/EventsConstants'
import CMSEventDto from '../dto/CMSEventDto'
import DtoEvent from '../dto/DtoEvent'
import CMSCategoryDto from '../dto/CMSCategoryDto'
import EventsAction from '../actions/EventsAction'
import { arrayOf, instanceOf } from '../../../utils/StoreUtils'
import MonitoredStationEventDto from '../dto/MonitoredStationEventDto'
import DtoEventPiezometer from '../dto/DtoEventPiezometer'
import DtoEventQualitometer from '../dto/DtoEventQualitometer'
import DtoEventHydrological from '../dto/DtoEventHydrological'
import DtoEventInstallation from '../dto/DtoEventInstallation'
import DiagnosticDto from '../dto/piezometryDiagnostic/DiagnosticDto'
import DiagnosticLinkMaterialDto from '../dto/piezometryDiagnostic/DiagnosticLinkMaterialDto'
import DiagnosticLinkEventTypeDto from '../dto/piezometryDiagnostic/DiagnosticLinkEventTypeDto'
import SolutionDto from '../dto/piezometryDiagnostic/SolutionDto'
import SolutionLinkProblemDto from '../dto/piezometryDiagnostic/SolutionLinkProblemDto'
import DiagnosticActionDto from '../dto/piezometryDiagnostic/DiagnosticActionDto'
import AppStore from '../../../store/Appstore'

export function EventsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PIEZOMETRY_ALL_EVENTS:
            return {
                ...state,
                piezometryAllEvents: action.piezometryAllEvents.map(
                    (e) => new DtoEventPiezometer(e),
                ),
            }
        case RECEIVE_QUALITOMETRY_ALL_EVENTS:
            return {
                ...state,
                qualitometryAllEvents: action.qualitometryAllEvents.map(
                    (e) => new DtoEventQualitometer(e),
                ),
            }
        case RECEIVE_HYDROMETRY_STATION_ALL_EVENTS:
            return {
                ...state,
                hydrometryStationAllEvents: action.hydrometryStationAllEvents.map(
                    (e) => new DtoEventHydrological(e),
                ),
            }
        case RECEIVE_INSTALLATION_ALL_EVENTS:
            return {
                ...state,
                installationAllEvents: action.installationAllEvents.map(
                    (e) => new DtoEventInstallation(e),
                ),
            }
        case RECEIVE_CMS_EVENTS:
            return { ...state, cmsEvents: action.cmsEvents.map((n) => new CMSEventDto(n)) }
        case RECEIVE_CMS_EVENT:
            return { ...state, cmsEvent: new CMSEventDto(action.cmsEvent) }
        case RECEIVE_CMS_CATEGORIES:
            return { ...state, cmsCategories: action.categories.map((n) => new CMSCategoryDto(n)) }
        case UPDATE_CMS_CATEGORIES:
            return { ...state, cmsCategories: action.categories.map((n) => new CMSCategoryDto(n)) }
        case RECEIVE_EVENT:
            return { ...state, event: new DtoEvent(action.event) }
        case RECEIVE_CMS_DOCUMENT:
            return { ...state, document: action.document }
        case RESET_EVENT:
            return { ...state, event: {} }
        case RESET_CMS_EVENT:
            return { ...state, cmsEvent: {} }
        case RECEIVE_STATION_EVENTS:
            return { ...state, stationEvents: action.events.map((e) => new DtoEvent(e)) }
        case RECEIVE_MONITORED_STATIONS_EVENTS:
            return {
                ...state,
                monitoredStations: action.stationEvents.map((s) => new MonitoredStationEventDto(s)),
            }
        case RESET_STATION_EVENTS:
            return { ...state, stationEvents: [] }
        case RECEIVE_PIEZOMETER_DIAGNOSTICS:
            return {
                ...state,
                eventPiezometerDiagnostics: action.piezometerDiagnostics.map(
                    (el) => new DiagnosticDto(el),
                ),
            }
        case RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS:
            return {
                ...state,
                eventPiezometerDiagnosticsLinkMaterials: action.piezometerDiagnosticsLinkMaterials.map(
                    (el) => new DiagnosticLinkMaterialDto(el),
                ),
            }
        case RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES:
            return {
                ...state,
                eventPiezometerDiagnosticsLinkEventTypes: action.piezometerDiagnosticsLinkEventTypes.map(
                    (el) => new DiagnosticLinkEventTypeDto(el),
                ),
            }
        case RECEIVE_PIEZOMETER_SOLUTIONS:
            return {
                ...state,
                eventPiezometerSolutions: action.piezometerSolutions.map(
                    (el) => new SolutionDto(el),
                ),
            }
        case RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS:
            return {
                ...state,
                eventPiezometerSolutionsLinkProblems: action.piezometerSolutionsLinkProblems.map(
                    (el) => new SolutionLinkProblemDto(el),
                ),
            }
        case RECEIVE_PIEZOMETER_ACTIONS:
            return {
                ...state,
                eventPiezometerActions: action.piezometerActions.map(
                    (el) => new DiagnosticActionDto(el),
                ),
            }
        default:
            return state
    }
}

export const store = {
    stationEvents: [],
    cmsEvents: [],
    cmsEvent: {},
    cmsCategories: [],
    event: {},
    document: {},
    monitoredStations: [],
    piezometryAllEvents: [],
    qualitometryAllEvents: [],
    installationAllEvents: [],
    hydrometryStationAllEvents: [],
    eventPiezometerDiagnostics: [],
    eventPiezometerDiagnosticsLinkMaterials: [],
    eventPiezometerDiagnosticsLinkEventTypes: [],
    eventPiezometerSolutions: [],
    eventPiezometerSolutionsLinkProblems: [],
    eventPiezometerActions: [],
}

export const EventsStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        stationEvents: {
            fetch: EventsAction.fetchStationEvents,
            reset: EventsAction.resetStationEvents,
            store: appStore.EventsReducer.stationEvents,
            propType: arrayOf(DtoEvent),
        },
        cmsEvent: {
            fetch: EventsAction.fetchCMSEvent,
            store: appStore.EventsReducer.cmsEvent,
            propType: arrayOf(CMSEventDto),
        },
        cmsEvents: {
            fetch: EventsAction.fetchCMSEvents,
            store: appStore.EventsReducer.cmsEvents,
            update: EventsAction.updateCMSEvent,
            delete: EventsAction.deleteCMSEvent,
            propType: arrayOf(CMSEventDto),
        },
        cmsCategories: {
            fetch: EventsAction.fetchCMSCategories,
            store: appStore.EventsReducer.cmsCategories,
            propType: arrayOf(CMSCategoryDto),
            update: EventsAction.updateCMSCategory,
            delete: EventsAction.deleteCMSCategory,
        },
        event: {
            fetch: EventsAction.fetchEvent,
            store: appStore.EventsReducer.event,
            add: EventsAction.addEvent,
            reset: EventsAction.resetEvent,
            propType: instanceOf(DtoEvent),
            update: EventsAction.updateEvent,
        },
        document: {
            fetch: EventsAction.fetchCMSDocument,
            store: appStore.EventsReducer.document,
            propType: PropTypes.object,
        },
        monitoredStations: {
            fetch: EventsAction.fetchMonitoredStations,
            store: appStore.EventsReducer.monitoredStations,
            propType: arrayOf(MonitoredStationEventDto),
        },
    }
}
