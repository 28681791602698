import { push } from 'react-router-redux'
import fetch from 'isomorphic-fetch'
import download from 'downloadjs'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../utils/ToastrAction'
import ApplicationConf from '../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../utils/ActionUtils'
import {
    RECEIVE_TERRITOIRES,
    RECEIVE_TERRITORY,
    RECEIVE_TERRITORY_ID,
    RECEIVE_TERRITORY_ASSOLEMENTS,
    RECEIVE_TERRITORY_ZONES_SURFACES,
    RECEIVE_ALL_MATRICES,
    RECEIVE_TERRITORY_MATRICES,
    RECEIVE_MATRICE,
    RECEIVE_ALL_SCENARIOS,
    RECEIVE_MATRICE_SCENARIOS,
    RECEIVE_SCENARIO,
    RECEIVE_SCENARIO_RESULTS,
    RECEIVE_SCENARIOS_COMPARE,
    RECEIVE_TERRITORY_AVERAGE_RPG,
    RECEIVE_TERRITORY_SURFACES_RPG,
    LOADING_TERRITORY_AVERAGE_RPG,
} from '../constants/TerritoryConstants'
import {
    PATH_TERRITORY,
    PATH_TERRITORY_DETAIL,
    PATH_TERRITORY_MATRICE,
    PATH_TERRITORY_SCENARIO,
} from '../../home/constants/RouteConstants'
import { getLogin } from '../../../utils/SettingUtils'

const TerritoryAction = {
    receiveTerritoires(territoires) {
        return { type: RECEIVE_TERRITOIRES, territoires }
    },

    promiseTerritoires() {
        return fetch(ApplicationConf.territory.territoires(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchTerritoires() {
        return (dispatch) => TerritoryAction.promiseTerritoires().then((json) => {
            dispatch(TerritoryAction.receiveTerritoires(json))
        })
    },

    receiveTerritoryId(territoireId) {
        return { type: RECEIVE_TERRITORY_ID, territoireId }
    },

    receiveTerritory(territoire) {
        return { type: RECEIVE_TERRITORY, territoire }
    },

    promiseTerritory(id) {
        return fetch(ApplicationConf.territory.territoire(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchTerritory(id) {
        return (dispatch) => TerritoryAction.promiseTerritory(id).then((json) => {
            dispatch(TerritoryAction.receiveTerritory(json))
        })
    },

    createTerritory(territory) {
        return (dispatch) => fetch(ApplicationConf.territory.territoires(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ ...territory, surfacesCultures: [] }),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.id) {
                    ToastrAction.success(i18n.territoryAddSuccess)
                    dispatch(push(`${PATH_TERRITORY_DETAIL}/${json.id}`))
                } else {
                    console.error(i18n.updateError + i18n.territory)
                    ToastrAction.error(i18n.updateError + i18n.territory)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.territory + err)
                ToastrAction.error(i18n.updateError + i18n.territory)
            })
    },

    updateTerritory(territory) {
        return () => fetch(ApplicationConf.territory.territoire(territory.id), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(territory),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.update) {
                    ToastrAction.success(i18n.territoryUpdateSuccess)
                } else {
                    console.error(i18n.updateError + i18n.territory)
                    ToastrAction.error(i18n.updateError + i18n.territory)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.territory + err)
                ToastrAction.error(i18n.updateError + i18n.territory)
            })
    },

    updateDescriptifTerritory(territory) {
        return () => fetch(ApplicationConf.territory.territoireDescriptif(territory.id), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(territory),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.update) {
                    ToastrAction.success(i18n.territoryUpdateSuccess)
                } else {
                    console.error(i18n.updateError + i18n.territory)
                    ToastrAction.error(i18n.updateError + i18n.territory)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.territory + err)
                ToastrAction.error(i18n.updateError + i18n.territory)
            })
    },

    updateTerritoryUsers(territoireId, usersList) {
        return () => fetch(ApplicationConf.territory.territoireUsers(territoireId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(usersList),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.update >= 0) {
                    ToastrAction.success(i18n.territoryUpdateSuccess)
                } else {
                    console.error(i18n.updateError + i18n.event)
                    ToastrAction.error(i18n.updateError + i18n.event)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.event + err)
                ToastrAction.error(i18n.updateError + i18n.event)
            })
    },

    deleteTerritory(territoryId) {
        return (dispatch) => fetch(ApplicationConf.territory.deleteTerritoire(territoryId), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.territoryDeleteSuccess)
                    dispatch(push(PATH_TERRITORY))
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.event + err)
                ToastrAction.error(i18n.deleteError + i18n.event)
            })
    },

    setZonesSurfaces(zonesSurfaces, tid) {
        return () => fetch(ApplicationConf.territory.zonesSurfacesTerritoire(tid), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(zonesSurfaces),
        })
            .then(checkAuth)
            .then(getJson)
            .catch((err) => {
                console.error(i18n.updateError + i18n.event + err)
                ToastrAction.error(i18n.updateError + i18n.event)
            })
    },

    setAssolements(assolements, tid) {
        return () => fetch(ApplicationConf.territory.assolementsTerritoire(tid), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(assolements),
        })
            .then(checkAuth)
            .then(getJson)
            .catch((err) => {
                console.error(i18n.updateError + i18n.event + err)
                ToastrAction.error(i18n.updateError + i18n.event)
            })
    },

    receiveAssolementsTerritory(assolements) {
        return { type: RECEIVE_TERRITORY_ASSOLEMENTS, assolements }
    },

    promiseAssolementsTerritory(id) {
        return fetch(ApplicationConf.territory.assolementsTerritoire(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAssolementsTerritoires(id) {
        return (dispatch) => TerritoryAction.promiseAssolementsTerritory(id).then((json) => {
            dispatch(TerritoryAction.receiveAssolementsTerritory(json))
        })
    },

    receiveAverageRPGTerritory(averageRPGData) {
        return { type: RECEIVE_TERRITORY_AVERAGE_RPG, averageRPGData }
    },

    loadingAverageRPGTerritory(loading) {
        return { type: LOADING_TERRITORY_AVERAGE_RPG, loading }
    },

    promiseAverageRPGTerritory(id) {
        return fetch(ApplicationConf.territory.averageRPG(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAverageRPGTerritory(id) {
        ToastrAction.info(i18n.loadingAverageRPG)
        return (dispatch) => {
            dispatch(TerritoryAction.loadingAverageRPGTerritory(true))
            return TerritoryAction.promiseAverageRPGTerritory(id).then((json) => {
                dispatch(TerritoryAction.receiveAverageRPGTerritory({ ...json, id }))
            })
        }
    },

    receiveSurfacesRPGTerritory(surfacesRPG) {
        return { type: RECEIVE_TERRITORY_SURFACES_RPG, surfacesRPG }
    },

    promiseSurfacesRPGTerritory(id) {
        return fetch(ApplicationConf.territory.surfacesRPG(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchSurfacesRPGTerritory(id) {
        ToastrAction.info(i18n.loadingSurfacesRPG)
        return (dispatch) => TerritoryAction.promiseSurfacesRPGTerritory(id).then((json) => {
            dispatch(TerritoryAction.receiveSurfacesRPGTerritory(json))
        })
    },

    receiveZonesSurfacesTerritory(zonesSurfaces) {
        return { type: RECEIVE_TERRITORY_ZONES_SURFACES, zonesSurfaces }
    },

    promiseZonesSurfacesTerritory(id) {
        return fetch(ApplicationConf.territory.zonesSurfacesTerritoire(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchZonesSurfacesTerritoires(id) {
        return (dispatch) => TerritoryAction.promiseZonesSurfacesTerritory(id).then((json) => {
            dispatch(TerritoryAction.receiveZonesSurfacesTerritory(json))
        })
    },

    receiveMatricesTerritory(matrices) {
        return { type: RECEIVE_TERRITORY_MATRICES, matrices }
    },

    promiseMatricesTerritory(id) {
        return fetch(ApplicationConf.territory.matriceTerritoire(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchMatricesTerritoires(id) {
        return (dispatch) => TerritoryAction.promiseMatricesTerritory(id).then((json) => {
            dispatch(TerritoryAction.receiveMatricesTerritory(json))
        })
    },

    receiveAllMatrices(matrices) {
        return { type: RECEIVE_ALL_MATRICES, matrices }
    },

    promiseAllMatrices() {
        return fetch(ApplicationConf.territory.matrices(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAllMatrices() {
        return (dispatch) => TerritoryAction.promiseAllMatrices().then((json) => {
            dispatch(TerritoryAction.receiveAllMatrices(json))
        })
    },

    autoFillMatrice(matriceId) {
        return () => fetch(ApplicationConf.territory.matriceAutoFill(matriceId), {
            method: 'POST',
            headers: getAuthorization(),
        })
            .then((json) => checkAuth(json, { 404: () => {}, 500: () => {} }))
            .then(getJson)
            .then((json) => {
                if (json.insert > 0) {
                    ToastrAction.success(`Calcul réussi : ${json.insert} ${json.insert > 1 ? 'valeurs intégrées ou remplacées' : 'valeur intégrée ou remplacée'} dans la matrice actuelle.`)
                } else if (json.insert === 0) {
                    ToastrAction.info('Aucune valeur intégrée dans la matrice actuelle.')
                }
            })
    },

    createMatrice(matrice) {
        return (dispatch) => fetch(ApplicationConf.territory.matrices(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({
                ...matrice,
                indicateurs: matrice.indicateurs.map((i) => ({
                    mid: -1,
                    iid: i.iid,
                    usermaj: getLogin(),
                })),
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.id) {
                    ToastrAction.success(i18n.matriceAddSuccess)
                    const newScenario = {
                        mid: json.id,
                        name: 'Territoire Actuel',
                        scRef: 0,
                        scType: -1,
                        direction: 0,
                        iidDirection: -1,
                        usermaj: getLogin(),
                        objectifs: [
                            {
                                id: 1,
                                scid: null,
                                category: -2,
                                mode: null,
                                val_1: null,
                                val_2: null,
                                actif: false,
                                description: '',
                                objectifRef: [],
                            },
                            {
                                id: 2,
                                scid: null,
                                category: 1,
                                mode: null,
                                val_1: null,
                                val_2: null,
                                actif: false,
                                description: '',
                                objectifRef: [],
                            },
                            {
                                id: 3,
                                scid: null,
                                category: 0,
                                mode: null,
                                val_1: null,
                                val_2: null,
                                actif: false,
                                description: '',
                                objectifRef: [],
                            },
                        ],
                        constraintsAB: [],
                        constraintsEco: [],
                        respect: true,
                    }
                    dispatch(TerritoryAction.createScenario(newScenario, null, true))
                    dispatch(
                        push({
                            pathname: `${PATH_TERRITORY_MATRICE}/${json.id}`,
                            state: { create: true },
                        }),
                    )
                }
                dispatch(TerritoryAction.fetchMatricesTerritoires(matrice.tid))
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.event + err)
                ToastrAction.error(i18n.updateError + i18n.event)
            })
    },

    updateMatrice(matriceId, matrice) {
        return () => fetch(ApplicationConf.territory.matrice(matriceId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(matrice),
        })
            .then(checkAuth)
            .then(getJson)
            .catch((err) => {
                console.error(i18n.updateError + i18n.matrice + err)
                ToastrAction.error(i18n.updateError + i18n.matrice)
            })
    },

    deleteMatrice(id) {
        return () => fetch(ApplicationConf.territory.matrice(id), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.delete || json.delete === 0) {
                    ToastrAction.success(i18n.matriceDeleteSuccess)
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.matrice + err)
            })
    },

    deleteMatricesByTerritory(id) {
        return () => fetch(ApplicationConf.territory.matriceTerritoire(id), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.matricesDeleteSuccess)
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.matrices + err)
            })
    },

    saveMatriceValues(matriceId, values) {
        return () => fetch(ApplicationConf.territory.matriceValues(matriceId), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(values),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.insert >= 0) {
                    ToastrAction.success(i18n.matriceUpdateSuccess)
                } else {
                    ToastrAction.error(i18n.updateError + i18n.matrice)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.matrice + err)
                ToastrAction.error(i18n.updateError + i18n.matrice)
            })
    },

    receiveMatrice(matrice) {
        return { type: RECEIVE_MATRICE, matrice }
    },

    promiseMatrice(id) {
        return fetch(ApplicationConf.territory.matrice(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchMatrice(id) {
        return (dispatch) => TerritoryAction.promiseMatrice(id).then((json) => {
            dispatch(TerritoryAction.receiveMatrice(json))
        })
    },

    receiveScenariosMatrice(scenarios) {
        return { type: RECEIVE_MATRICE_SCENARIOS, scenarios }
    },

    promiseScenariosMatrice(id) {
        return fetch(ApplicationConf.territory.scenariosMatrice(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchScenariosMatrice(id) {
        return (dispatch) => TerritoryAction.promiseScenariosMatrice(id).then((json) => {
            dispatch(TerritoryAction.receiveScenariosMatrice(json))
        })
    },

    receiveScenario(scenario) {
        return { type: RECEIVE_SCENARIO, scenario }
    },

    promiseScenario(id) {
        return fetch(ApplicationConf.territory.scenario(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchScenario(id) {
        return (dispatch) => TerritoryAction.promiseScenario(id).then((json) => {
            dispatch(TerritoryAction.receiveScenario(json))
        })
    },

    receiveScenariosToCompare(scenarios) {
        return { type: RECEIVE_SCENARIOS_COMPARE, scenarios }
    },

    promiseScenariosToCompare(ids) {
        return fetch(ApplicationConf.territory.scenariosMultiple(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(ids),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchScenariosToCompare(ids) {
        return (dispatch) => TerritoryAction.promiseScenariosToCompare(ids).then((json) => {
            dispatch(TerritoryAction.receiveScenariosToCompare(json))
        })
    },

    receiveAllScenarios(scenarios) {
        return { type: RECEIVE_ALL_SCENARIOS, scenarios }
    },

    promiseAllScenarios() {
        return fetch(ApplicationConf.territory.scenarios(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAllScenarios() {
        return (dispatch) => TerritoryAction.promiseAllScenarios().then((json) => {
            dispatch(TerritoryAction.receiveAllScenarios(json))
        })
    },

    createScenario(scenario, indicateurs, noRedirect) {
        return (dispatch) => fetch(ApplicationConf.territory.scenarios(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(scenario),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.id) {
                    ToastrAction.success(i18n.scenarioAddSuccess)
                    if (!noRedirect) {
                        dispatch(
                            push({
                                pathname: `${PATH_TERRITORY_SCENARIO}/${json.id}`,
                                state: {
                                    indicateurs,
                                },
                            }),
                        )
                    }
                }
                dispatch(TerritoryAction.fetchScenariosMatrice(scenario.mid))
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.scenario + err)
                ToastrAction.error(i18n.updateError + i18n.scenario)
            })
    },

    updateScenario(scenarioId, scenario) {
        return () => fetch(ApplicationConf.territory.scenario(scenarioId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(scenario),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.update) {
                    ToastrAction.success(i18n.scenarioUpdateSuccess)
                } else {
                    ToastrAction.error(i18n.updateError + i18n.scenario)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.scenario + err)
            })
    },

    deleteScenario(id) {
        return () => fetch(ApplicationConf.territory.scenario(id), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.scenarioDeleteSuccess)
                } else {
                    ToastrAction.error(i18n.deleteError + i18n.scenario)
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.scenario + err)
            })
    },

    deleteScenarioByMatriceId(id) {
        return () => fetch(ApplicationConf.territory.scenariosMatrice(id), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.scenarioDeleteSuccess)
                } else {
                    ToastrAction.error(i18n.deleteError + i18n.scenarios)
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.scenarios + err)
            })
    },

    receiveScenarioResults(results) {
        return { type: RECEIVE_SCENARIO_RESULTS, results }
    },

    startScenario(id) {
        return (dispatch) => fetch(ApplicationConf.territory.scenarioStart(id), {
            method: 'get',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.nosolution) {
                    ToastrAction.error(i18n.noSolutionFound)
                } else if (!json.error) {
                    dispatch(TerritoryAction.receiveScenarioResults(json))
                    ToastrAction.success(i18n.scenarioStartSuccess)
                }
            })
            .catch((err) => {
                ToastrAction.error(`${i18n.error} : ${i18n.scenarioIncomplet}`)
                console.error(i18n.error + i18n.scenario + err)
            })
    },

    exportExcelScenarioResult(id) {
        return () => fetch(ApplicationConf.territory.scenarioExport(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.scenario + err)
                ToastrAction.error(i18n.fetchError + i18n.scenario)
            })
    },

    exportExcelScenariosComparison(scenarioRef, ids) {
        return () => fetch(ApplicationConf.territory.scenariosExportComparison(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ scenarioRef, ids }),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.scenarios + err)
                ToastrAction.error(i18n.fetchError + i18n.scenarios)
            })
    },

    matriceUploadExcel(matriceId, file) {
        return () => fetch(ApplicationConf.territory.matriceUploadExcel(matriceId), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(file),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    if (json.insert === 1) {
                        ToastrAction.success(i18n.matriceUpdateSuccess)
                    } else if (json.insert === 2) {
                        ToastrAction.success(i18n.matriceUpdateSuccessWithError)
                    }
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.matrice + err)
                ToastrAction.error(i18n.updateError + i18n.matrice)
            })
    },

    matriceExportExcel(matriceId) {
        return () => fetch(ApplicationConf.territory.matriceExportExcel(matriceId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.exportError + i18n.matrice + err)
                ToastrAction.error(i18n.exportError + i18n.matrice)
            })
    },

    assolementUploadExcel(file) {
        return () => fetch(ApplicationConf.territory.assolementUploadExcel(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(file),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    if (json.insert === 1) {
                        ToastrAction.success(i18n.territoryUpdateSuccess)
                    } else if (json.insert === 2) {
                        ToastrAction.success(i18n.territoryUpdateSuccessWithError)
                    }
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.territory + err)
                ToastrAction.error(i18n.updateError + i18n.territory)
            })
    },

    assolementExportExcel(territoireId) {
        return () => fetch(ApplicationConf.territory.assolementExportExcel(territoireId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.territory + err)
                ToastrAction.error(i18n.fetchError + i18n.territory)
            })
    },
}

export default TerritoryAction
