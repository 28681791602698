import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Checkbox } from 'react-aquasys'

export default class TableHeader extends Component {
    render() {
        const rows = this.props.headers.map((header) => {
            if (header === 'check') {
                if (this.props.condensed) {
                    return (
                        <th className="table-body-condensed column-checkbox">
                            <Checkbox
                                checked={this.props.checked}
                                condensed={this.props.condensed}
                                onChange={this.props.onChange}
                            />
                        </th>
                    )
                }
                return (
                    <th className="column-checkbox">
                        <Checkbox checked={this.props.checked} onChange={this.props.onChange} />
                    </th>
                )
            }
            const iconClass = (() => {
                let icon = 'fa'
                if (header === this.props.sortColumn.column) {
                    icon = `${icon} fa-sort-${this.props.sortColumn.sort}`
                } else {
                    icon += ' fa-sort'
                }
                icon += ' color-grey'
                return icon
            })()

            const name = (() => {
                if (this.props.customHeaders[header]) {
                    return this.props.customHeaders[header]
                }
                if (!/^\d+$/.test(header) && /^[0-9A-Za-z]+$/.test(header)) {
                    try {
                        return i18n[header]
                    } catch (e) {
                        return header
                    }
                }
                return header
            })()

            const onClickFunc = this.props.headersOnClick[header] || (() => this.props.onSort(header))

            if (this.props.condensed) {
                return (
                    <th key={header} onClick={onClickFunc} className="clickable no-padding">{ name }<i className={iconClass} aria-hidden="true" /></th>
                )
            }
            return (
                <th key={header} onClick={onClickFunc} className="clickable">{ name }<i className={iconClass} aria-hidden="true" /></th>
            )
        })
        return (
            <tr>
                { rows }
            </tr>
        )
    }
}

TableHeader.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSort: PropTypes.func,
    checkable: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    customHeaders: PropTypes.object,
    headersOnClick: PropTypes.object,
    sortColumn: PropTypes.shape({
        column: PropTypes.string.isRequired,
        sort: PropTypes.string.isRequired,
    }),
    condensed: PropTypes.bool,
}
