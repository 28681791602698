import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import AccessChartPanel from './charts/AccessChartPanel'
import StatisticsPanel from './statistic/StatisticsPanel'
import CmsStatisticsPanel from './statistic/CmsStatisticsPanel'
import EventsAction from '../../../../../events/actions/EventsAction'

class UserStatisticPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsCategories: {},
        }
    }

    render() {
        return (
            <Card style={{ padding: '10px' }}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    style={{ marginTop: '10px' }}
                >
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={8}>
                                <AccessChartPanel account={this.props.account} />
                            </Grid>
                            <Grid item xs={4}>
                                <StatisticsPanel account={this.props.account} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CmsStatisticsPanel account={this.props.account} />
                    </Grid>
                </Grid>
            </Card>
        )
    }
}

const mapDispatchToProps = {
    fetchCMSCategories: EventsAction.fetchCMSCategories,
}

UserStatisticPanel.propTypes = {
    account: PropTypes.bool,
}

UserStatisticPanel.defaultProps = {
    account: false,
}

export default connect(null, mapDispatchToProps)(UserStatisticPanel)
