/* eslint-disable prefer-destructuring */
export default class DtoMatriceRef {
    constructor(obj) {
        this.cultureId = obj[0] // Long,
        this.department = obj[1] // String,
        this.typeSuccession = obj[2] // Int,
        this.driveModeId = obj[3] // Int,
        this.indicatorId = obj[4] // Int,
        this.value = obj[5] // Option[Double]
    }
}
