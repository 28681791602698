/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import i18n from 'simple-react-i18n'
import { uniq, uniqBy } from 'lodash'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { grey, mainPink } from '../../styles/theme'
import AgGridTable from '../../components/datatable/AgGridTable'
import Input from '../../components/forms/Input'
import { GreenButton } from '../../styles/button'
import IndicateurDto from '../referencial/components/indicateurs/dto/IndicateurDto'
import ReferencialAction from '../referencial/action/ReferencialAction'
import { getLogin } from '../../utils/SettingUtils'

const Subtitle = styled.p`
    margin: 0.5rem 0;
`

const IconStyle = styled(Icon)`
    font-size: 3rem !important;
    color: ${grey};
    opacity: 0.6;
    margin: 23px 15px 0 0 !important;
    &:hover {
        opacity: 1;
    }
`

const MoreIcon = styled(IconStyle)`
    font-size: 3rem !important;
    margin: 40px 13px 0 0 !important;
`

const PinkBloc = styled.div`
    width: 100%;
    border: 4px solid ${mainPink};
    border-radius: 5px;
    position: relative;
`

const PinkTitle = styled.div`
    width: fit-content;
    height: 30px;
    font-size: 1.4rem;
    color: ${mainPink};
    background-color: white;
    position: absolute;
    top: -20px;
    left: 10px;
    text-align: center;
    padding: 0 10px 0 10px;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

export function getModalStyle() {
    const top = 60
    const left = 60

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const tableStyle = {
    height: '30vh',
    width: '100%',
    marginBottom: '3.5rem',
}

const inputStyle = {
    width: '200px',
    height: '48px',
}

const noMargin = {
    margin: '0 !important',
}

const ModalIndicator = ({
    onCreateData,
    indicateursData,
    onChange,
    selectedIndicateurs,
    onSave,
    currentIndicateurs,
    addField,
    typeKey,
    objectifId,
    isEditMode,
    matriceId,
    open,
    handleClose,
}) => {
    const [newNameIndic, setNewNameIndic] = useState('nouvelIndicateur')
    const [indicators, setIndicators] = useState(indicateursData)
    const [createdIndicators, setCreatedIndicators] = useState([])

    const dispatch = useDispatch()

    const columnDefs = [
        {
            headerName: i18n.code,
            field: 'id',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        { headerName: i18n.name, field: 'name' },
        {
            headerName: i18n.referencial,
            field: 'referentiel',
            cellRenderer: (params) => `<input disabled type='checkbox' ${params.value ? 'checked' : ''} value='${
                params.value
            }' />`,
        },
        {
            headerName: i18n.user,
            field: 'usermaj',
        },
    ]

    const closeAndReset = (type) => {
        handleClose()
        onChange(type, [])
    }

    const closeAndSave = (type, value) => {
        handleClose()
        onSave(type, value.length ? uniq(value) : value)
    }

    const onCreateNewIndicator = () => {
        const newIndicator = new IndicateurDto({
            id: -1,
            name: newNameIndic,
            referentiel: false,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createIndicateur(newIndicator)).then((id) => {
            const newCreatedIndicators = [...createdIndicators, { ...newIndicator, id }]
            setCreatedIndicators(newCreatedIndicators)
            if (matriceId) {
                dispatch(ReferencialAction.fetchIndicateursMatrices(matriceId)).then((newIndicateurs) => {
                    onCreateData('indicateurs', id)
                    setIndicators(uniqBy([...newIndicateurs, ...newCreatedIndicators], 'id'))
                })
            } else {
                dispatch(ReferencialAction.fetchIndicateurs()).then((newIndicateurs) => {
                    onCreateData('indicateurs', id)
                    setIndicators(uniqBy([...newIndicateurs, ...newCreatedIndicators], 'id'))
                })
            }
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{i18n.addOrCreatePerformanceIndicators}</DialogTitle>
            <DialogContent>
                <Subtitle>{`${i18n.territoryPerformanceIndicatorsList} :`}</Subtitle>
                <div className="ag-theme-balham" style={tableStyle}>
                    <AgGridTable
                        exportable={false}
                        columnDefs={columnDefs}
                        rowData={indicators}
                        onChange={onChange}
                        tableKey="currentIndicateurs"
                        selectedData={selectedIndicateurs}
                        selectedDataKey="iid"
                    />
                </div>
                {addField && (
                    <PinkBloc>
                        <PinkTitle>{i18n.createNewIndicatorPerformance}</PinkTitle>
                        <Grid container alignItems="center" style={{ padding: 10 }}>
                            <div>
                                <Label htmlFor="ind_perf_name">
                                    {i18n.name}&nbsp;:
                                </Label>
                                <Input
                                    style={inputStyle}
                                    value={newNameIndic}
                                    onChange={(event) => setNewNameIndic(event)}
                                />
                            </div>
                            <MoreIcon
                                className="no-margin"
                                style={noMargin}
                                onClick={onCreateNewIndicator}
                            >
                                add_box
                            </MoreIcon>
                        </Grid>
                    </PinkBloc>
                )}
            </DialogContent>
            <DialogActions>
                {isEditMode ? (
                    <>
                        <GreenButton
                            onClick={closeAndReset.bind(
                                this,
                                'currentIndicateurs',
                            )}
                            greenReverse
                            borderRadius
                            style={{ margin: '0 1rem' }}
                        >
                            {i18n.cancel}
                        </GreenButton>
                        <GreenButton
                            onClick={closeAndSave.bind(
                                this,
                                typeKey || 'indicateurs',
                                objectifId || currentIndicateurs,
                            )}
                            borderRadius
                        >
                            {i18n.validate}
                        </GreenButton>
                    </>
                ) : (
                    <GreenButton
                        onClick={closeAndReset.bind(this, 'currentIndicateurs')}
                        borderRadius
                    >
                        {i18n.close}
                    </GreenButton>
                )}
            </DialogActions>
        </Dialog>
    )
}

ModalIndicator.defaultProps = {
    selectedIndicateurs: [],
    currentIndicateurs: [],
    addField: true,
    typeKey: '',
    objectifId: null,
    isEditMode: true,
}

ModalIndicator.propTypes = {
    indicateursData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    handleClose: PropTypes.func,
    selectedIndicateurs: PropTypes.arrayOf(PropTypes.object),
    currentIndicateurs: PropTypes.arrayOf(PropTypes.number),
    addField: PropTypes.bool,
    typeKey: PropTypes.string,
    objectifId: PropTypes.number,
    isEditMode: PropTypes.bool,
    open: PropTypes.bool,
    matriceId: PropTypes.number,
    onCreateData: PropTypes.func,
}

export default ModalIndicator
