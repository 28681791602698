import sl1 from '../../../assets/pictures/background/slide_1.png'
import sl2 from '../../../assets/pictures/background/slide_2.png'
import sl4 from '../../../assets/pictures/background/slide_4.png'
import sl5 from '../../../assets/pictures/background/slide_5.png'
import sl6 from '../../../assets/pictures/background/slide_6.png'
import sl7 from '../../../assets/pictures/background/slide_7.png'
import sl8 from '../../../assets/pictures/background/slide_8.png'
import sl9 from '../../../assets/pictures/background/slide_9.png'

export const slides = [
    {
        src: sl1,
    },
    {
        src: sl2,
    },
    {
        src: sl4,
    },
    {
        src: sl5,
    },
    {
        src: sl6,
    },
    {
        src: sl7,
    },
    {
        src: sl8,
    },
    {
        src: sl9,
    },
]

export default {
    slides,
}
