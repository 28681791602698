export default class {
    constructor(object) {
        this.id = object.id
        this.code = object.id
        this.name = object.name
        this.siret = object.siret
        this.mnemonique = object.mnemonique
        this.sandre = object.sandre
    }
}
