import { MOBILE, WEB } from '../pages/administration/components/user/constants/StatisticConstants'

const getModuleValue = (value) => {
    switch (value) {
        case MOBILE:
            return 'Mobile'
        case WEB:
            return 'Web'
        default:
            return 'Web'
    }
}
const getModuleColor = (value) => {
    switch (value) {
        case MOBILE:
            return 'red'
        case WEB:
            return 'blue'
        default:
            return 'orange'
    }
}

export {
    getModuleValue,
    getModuleColor,
}
