import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { orderBy } from 'lodash'
import HomeAction from '../../../../home/actions/HomeAction'
import UserAction from '../../../../administration/components/user/actions/UserAction'
import FixedSlideNav from '../../../../../components/navbar/FixedSlideNav'
import { PATH_REFERENCIAL_DASHBOARD } from '../../../../home/constants/RouteConstants'
import { Center } from '../../../../../styles/main'
import { getUser } from '../../../../../utils/SettingUtils'

class ReferencialDashboardApp extends Component {
    componentDidMount() {
        this.props.checkAdmin(getUser().isAdmin)
        const { setTitle } = this.props
        setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.dashboard,
                href: 'referencial/dashboard',
            },
        ])
    }

    render() {
        const dashboardLink = [
            {
                href: PATH_REFERENCIAL_DASHBOARD,
                icon: 'dashboard',
                name: i18n.dashboard,
            },
        ]
        const referencialList = orderBy(this.props.referencials, 'index', 'asc')
        const links = [].concat(dashboardLink, referencialList)
        return (
            <Center style={{ margin: '3.5rem 5rem 0 9rem' }}>
                <Grid className="fixed_bar_container" item xs={1}>
                    <FixedSlideNav links={links} />
                </Grid>
                <Grid item xs={12}>
                    {this.props.children && React.cloneElement(this.props.children)}
                </Grid>
            </Center>
        )
    }
}

ReferencialDashboardApp.propTypes = {
    setTitle: PropTypes.shape({}).isRequired,
    checkAdmin: PropTypes.func,
    referencials: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.element,
}

const mapStateToProps = (store) => ({
    referencials: [
        store.DashboardReducer.cultures,
        store.DashboardReducer.modes_conduites,
        store.DashboardReducer.zones,
        store.DashboardReducer.communes,
        store.DashboardReducer.indicateurs_performances,
        store.DashboardReducer.regles_rotation,
        store.DashboardReducer.rpg,
        store.DashboardReducer.matriceRef,
    ],
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    checkAdmin: UserAction.checkAdmin,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencialDashboardApp)
