import React from 'react'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import AppStore from '../store/Appstore'

const Name = styled.h4`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 5px;
`
const HeadCard = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 50%;
    justify-content: center;
`

const Icons = styled(Icon)`
    color: black;
    text-align: center;
    font-size: 1.5rem !important;
`

const IconProfile = styled(Icon)`
    font-size: 3rem !important;
`

const getExploitationAEP = (settingsProps) => {
    const found = settingsProps.find((s) => s.parameter === 'ExploitationAEP')
    if (found) {
        return found.value === 'True' || found.value === 'OUI'
    }
    return false
}

const getLogin = () => localStorage.getItem('CCE_LOGIN')

const isConnected = () => {
    if (localStorage.getItem('CCE_TOKEN')) {
        return true
    }
    return false
}

const getUser = () => ({
    ...(AppStore.getState().UserReducer.users.find((u) => u.login === getLogin()) || {}),
    ...AppStore.getState().AccountReducer.accountUser,
})

const getSetting = (settingList, parameter) => {
    const found = settingList.find((s) => s.parameter === parameter)
    return found ? found.value : null
}

const getProfileCard = (admin, props, user, maximumNumberTry) => {
    const showedPicture = user.picture ? (
        <img src={user.picture} className="circle responsive-img row profilePicture" />
    ) : (
        <IconProfile>account_circle</IconProfile>
    )

    const showedAdmin = user.isAdmin === '1' ? (
        <>
            <Tooltip title={i18n.admin}>
                <Icons>school</Icons>
            </Tooltip>
        </>
    ) : null
    const showedAnimator = user.labo === '1' ? (
        <>
            <Tooltip title={i18n.animator}>
                <Icons>folder</Icons>
            </Tooltip>
        </>
    ) : null
    const showedConsulting = user.consultant === '1' ? (
        <>
            <Tooltip title={i18n.consultant}>
                <Icons>remove_red_eye</Icons>
            </Tooltip>
        </>
    ) : null
    const blockedUser = user.connexionAttempts >= maximumNumberTry ? (
        <i
            className="material-icons small blue-text center-align tooltipped"
            data-tooltip={i18n.blockedUser}
        >
            lock
        </i>
    ) : null
    const lastConnexion = admin ? <Grid item>{user.lastConnexion}</Grid> : null
    const icons = [showedAdmin, showedAnimator, showedConsulting, blockedUser].filter((i) => !!i)
    const name = user.name ? <Grid item>{user.name}</Grid> : <Grid item>{user.login}</Grid>
    return (
        <>
            <HeadCard>
                {showedPicture}
                <div className="col s12 center-align">
                    <Name>{user.login}</Name>
                    {icons}
                </div>
            </HeadCard>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '50%' }}
            >
                {name}
                {lastConnexion}
            </Grid>
        </>
    )
}

const isDeveloppmentEnv = () => process.env.NODE_ENV !== 'production'

const hasHabilitation = (hab) => {
    if (getUser().isAdmin === '1') {
        return true
    }
    return !!AppStore.getState().AccountReducer.accountHabilitations.find(
        (h) => h.habilitation === hab,
    )
}

const hasForbidenHabilitation = (hab) => {
    if (getUser().isAdmin === '1') {
        return false
    }
    return !!AppStore.getState().AccountReducer.accountHabilitations.find(
        (h) => h.habilitation === hab,
    )
}

export {
    getExploitationAEP,
    getLogin,
    isConnected,
    getUser,
    getSetting,
    getProfileCard,
    isDeveloppmentEnv,
    hasHabilitation,
    hasForbidenHabilitation,
}
