import React from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import { MainContainer } from '../../styles/container'
import { TabStyle } from '../../components/TabStyle'
import ProgressBar from '../../components/ProgressBar'
import ActionComponent from '../../components/actions/ActionComponent'
import HomeAction from '../home/actions/HomeAction'
import { SpaceAround, Relative } from '../../styles/main'
import TerritoryAction from './actions/TerritoryAction'
import TerritoryPanel from './TerritoryPanel'
import Assolement from './Assolement'
import MatricesApp from './matrices/MatricesApp'
import ModalWarningDelete from './modal/ModalWarningDelete'
import ModalWarningUpdate from './modal/ModalWarningUpdate'
import ModalWarningAssolement from './modal/ModalWarningAssolement'
import { getLogin } from '../../utils/SettingUtils'
import { mainBlue } from '../../styles/theme'
import ReferencialAction from '../referencial/action/ReferencialAction'

class TerritoryDetail extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            territoire: {},
            initialTerritoire: {},
            activeTab: props.location.state ? 'matrices' : 'descriptive',
            dataLoaded: false,
            bricksLoaded: false,
            isEditMode: false,
            openDelete: false,
            openAssolement: false,
            haveMatrices: false,
            resultAssolement: [],
        }
    }

    componentDidMount() {
        this.getTerritory()
    }

    getTerritory = () => {
        const tid = this.props.match.params.id
        this.props.fetchTerritory(tid).then(() => {
            const { territoire } = this.props
            if (territoire.id) {
                this.props.fetchElementaryBricks(territoire.id).then(() => {
                    this.setState({ bricksLoaded: true })
                })
                this.props.fetchMatricesTerritoires(tid).then(() => {
                    if (this.props.matrices.length > 0) {
                        this.setState({ haveMatrices: true })
                    } else {
                        this.setState({ haveMatrices: false })
                    }
                })
                this.setInitialTerritory()
                this.props.fetchZonesSurfacesTerritoires(territoire.id).then(() => {
                    this.props.fetchAssolementsTerritoires(territoire.id).then(() => {
                        this.checkDatas()
                    })
                })
            }
        })
    }

    checkDatas = () => {
        const { assolements, zonesSurfaces } = this.props
        let isValide = true
        if (zonesSurfaces.length === 0 || assolements.length === 0) {
            isValide = false
        } else {
            zonesSurfaces.forEach((zone) => {
                const filteredData = assolements.filter((a) => zone.zid === a.zid)
                let total = 0
                filteredData.forEach((d) => {
                    total += d.surface
                })
                if (zone.surface !== total) {
                    isValide = false
                }
            })
        }
        this.setState({ assolementIsValide: isValide, dataLoaded: true })
    }

    componentDidUpdate() {
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
            {
                title: this.state.dataLoaded ? this.props.territoire.name : '',
                href: `territories/territory/${this.props.match.params.id}`,
            },
        ])
    }

    setInitialTerritory = () => {
        this.setState({
            initialTerritoire: this.props.territoire,
            territoire: this.props.territoire,
        })
    }

    onChangeTerritory = (territoire) => {
        this.setState({ territoire })
    }

    onChangeTerritoryUsers = (users) => {
        this.setState({ dataLoaded: false })
        this.props.updateTerritoryUsers(this.props.match.params.id, users).then(() => {
            this.getTerritory(this.props.match.params.id)
        })
    }

    onChangeTerritoryDescriptif = (value) => {
        this.setState({ dataLoaded: false })
        const territoire = { ...this.state.territoire, ...value, usermaj: getLogin() }
        this.props.updateDescriptifTerritory(territoire).then(() => {
            this.props.fetchTerritory(this.props.match.params.id).then(() => {
                this.setInitialTerritory()
                this.setState({ dataLoaded: true })
            })
        })
    }

    checkAssolement = (bool) => {
        this.setState({ assolementIsValide: bool })
    }

    toggleEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    onSaveEvent = () => {
        const territoire = { ...this.state.territoire, usermaj: getLogin() }
        if (this.state.territoire.referencial) {
            territoire.utilisateurs = []
        }
        this.setState({ dataLoaded: false })
        this.props.updateTerritory(territoire).then(() => {
            this.props.fetchTerritory(territoire.id).then(() => {
                this.setInitialTerritory()
                this.props.fetchElementaryBricks(territoire.id).then(() => this.setState({ dataLoaded: true }))
            })
        })
    }

    onDuplicateEvent = () => {
        this.setState({ dataLoaded: false })
        const duplicateTerritory = {
            ...this.props.territoire,
            id: -1,
            name: `${this.props.territoire.name}_dupliqué`,
            referencial: false,
            usermaj: getLogin() || '',
            utilisateurs: [],
        }
        this.props.createTerritory(duplicateTerritory).then(() => {
            this.getTerritory()
        })
    }

    handleChangeTab = (event, value) => {
        const { isEditMode, dataLoaded, assolementIsValide } = this.state
        if (!isEditMode && dataLoaded) {
            if (value !== 'matrices') {
                this.setState({ activeTab: value })
            } else if (!assolementIsValide) {
                this.modalAssolementIsOpen()
            } else if (assolementIsValide) {
                this.setState({ activeTab: value })
            }
        }
    }

    onDeleteTerritory = () => {
        this.props.deleteTerritory(this.props.territoire.id)
    }

    onDeleteMatrices = () => {
        this.setState({ dataLoaded: false })
        this.props.deleteMatricesByTerritory(this.props.territoire.id).then(() => {
            this.getTerritory()
        })
    }

    getPanel = () => {
        const { territoire, haveMatrices } = this.state

        switch (this.state.activeTab) {
            case 'descriptive':
                return (
                    <TerritoryPanel
                        territoryDetail
                        territory={territoire}
                        haveMatrices={haveMatrices}
                        modalUpdateIsOpen={this.modalUpdateIsOpen}
                        onDeleteTerritory={this.onDeleteTerritory}
                        toggleEditMode={this.toggleEditMode}
                        onChangeTerritory={this.onChangeTerritory}
                        onChangeTerritoryUsers={this.onChangeTerritoryUsers}
                        onChangeTerritoryDescriptif={this.onChangeTerritoryDescriptif}
                        setInitialTerritory={this.setInitialTerritory}
                        onSaveEvent={this.onSaveEvent}
                        onDuplicateEvent={this.onDuplicateEvent}
                        modalIsOpen={this.modalDeleteIsOpen}
                    />
                )
            case 'assolement':
                return (
                    <Assolement
                        territory={territoire}
                        haveMatrices={haveMatrices}
                        modalUpdateIsOpen={this.modalUpdateIsOpen}
                        zones={this.props.zones}
                        cultures={this.props.cultures}
                        conduites={this.props.conduites}
                        toggleEditMode={this.toggleEditMode}
                        setInitialTerritory={this.setInitialTerritory}
                        modalIsOpen={this.modalDeleteIsOpen}
                        onDuplicateEvent={this.onDuplicateEvent}
                        checkAssolement={this.checkAssolement}
                    />
                )
            case 'matrices':
                return (
                    <Relative>
                        <MatricesApp territory={territoire} tid={this.props.match.params.id} />
                    </Relative>
                )
            default:
                return (
                    <Relative>
                        <MatricesApp territory={territoire} tid={this.props.match.params.id} />
                    </Relative>
                )
        }
    }

    modalDeleteIsOpen = () => {
        this.setState({ openDelete: true })
    }

    modalDeleteIsClose = () => {
        this.setState({ openDelete: false })
    }

    modalUpdateIsOpen = () => {
        this.setState({ openUpdate: true })
    }

    modalUpdateIsClose = () => {
        this.setState({ openUpdate: false })
    }

    modalAssolementIsOpen = () => {
        this.setState({ openAssolement: true })
    }

    modalAssolementIsClose = () => {
        this.setState({ openAssolement: false })
    }

    render() {
        const {
            activeTab,
            openDelete,
            openUpdate,
            openAssolement,
            isEditMode,
            dataLoaded,
            bricksLoaded,
        } = this.state
        return (
            <SpaceAround>
                <MainContainer>
                    <div style={{ width: '100%' }}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ marginBottom: '15px' }}
                        />
                        <div style={{ margin: '1rem 0 2rem' }}>
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none',
                                    },
                                }}
                                style={{
                                    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
                                }}
                                variant="fullWidth"
                                value={activeTab}
                                onChange={this.handleChangeTab}
                                aria-label="tabs"
                            >
                                <TabStyle
                                    value="descriptive"
                                    style={
                                        isEditMode && activeTab !== 'descriptive'
                                            ? { backgroundColor: 'rgb(244,244,244)' }
                                            : {}
                                    }
                                    label={i18n.descriptive}
                                />
                                <TabStyle
                                    value="assolement"
                                    style={
                                        (isEditMode && activeTab !== 'assolement') || !dataLoaded
                                            ? { backgroundColor: 'rgb(244,244,244)' }
                                            : {}
                                    }
                                    label={i18n.rotation}
                                />
                                <TabStyle
                                    value="matrices"
                                    style={
                                        isEditMode || !dataLoaded
                                            ? { backgroundColor: 'rgb(244,244,244)' }
                                            : {}
                                    }
                                    label={i18n.matrices}
                                />
                            </Tabs>
                            <div style={{ border: `${mainBlue} solid` }}>
                                {(dataLoaded && bricksLoaded) ? this.getPanel() : <ProgressBar />}
                            </div>
                        </div>
                    </div>
                    {openDelete && (
                        <ModalWarningDelete
                            handleClose={this.modalDeleteIsClose}
                            open={openDelete}
                            onDeleteTerritory={this.onDeleteTerritory}
                        />
                    )}
                    {openUpdate && (
                        <ModalWarningUpdate
                            handleClose={this.modalUpdateIsClose}
                            open={openUpdate}
                            onDeleteMatrices={this.onDeleteMatrices}
                            onDuplicateEvent={this.onDuplicateEvent}
                        />
                    )}
                    {openAssolement && (
                        <ModalWarningAssolement
                            handleClose={this.modalAssolementIsClose}
                            open={openAssolement}
                        />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchTerritory: TerritoryAction.fetchTerritory,
    fetchMatricesTerritoires: TerritoryAction.fetchMatricesTerritoires,
    fetchAssolementsTerritoires: TerritoryAction.fetchAssolementsTerritoires,
    fetchZonesSurfacesTerritoires: TerritoryAction.fetchZonesSurfacesTerritoires,
    deleteMatricesByTerritory: TerritoryAction.deleteMatricesByTerritory,
    createTerritory: TerritoryAction.createTerritory,
    updateTerritory: TerritoryAction.updateTerritory,
    updateDescriptifTerritory: TerritoryAction.updateDescriptifTerritory,
    updateTerritoryUsers: TerritoryAction.updateTerritoryUsers,
    deleteTerritory: TerritoryAction.deleteTerritory,
    fetchElementaryBricks: ReferencialAction.fetchElementaryBricks,
    push,
}

const mapStateToProps = (store) => ({
    territoire: store.TerritoryReducer.territoire,
    assolements: store.TerritoryReducer.assolements,
    zonesSurfaces: store.TerritoryReducer.zonesSurfaces,
    users: store.UserReducer.users,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    matrices: store.TerritoryReducer.matrices,
})

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryDetail)
