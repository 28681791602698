module.exports = {
    SIEAU: 'SIEAU',
    QUALITY: 'QUALITY',
    PIEZOMETRY: 'PIEZOMETRY',
    HYDROMETRY: 'HYDROMETRY',
    PLUVIOMETRY: 'PLUVIOMETRY',
    INSTALLATION: 'INSTALLATION',
    DISTRIBUTION_UNIT: 'DISTRIBUTION_UNIT',
    PRODUCTION_UNIT: 'PRODUCTION_UNIT',
    MATERIEL: 'MATERIEL',
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
    WEB: 'WEB',
    MOBILE: 'MOBILE',
}
