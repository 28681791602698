import React from 'react'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'

function Counter({ number, time }) {
    return <CountUp end={number} duration={time} />
}

Counter.propTypes = {
    number: PropTypes.number,
    time: PropTypes.number,
}

Counter.defaultProps = {
    number: 0,
    time: 3,
}

export default Counter
