/* eslint-disable no-plusplus */
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { Grid, Icon, Select, FormControl, MenuItem, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { faqUrl } from '../../conf/SieauConstants'
import header from '../../assets/pictures/headerDashboard.png'
import HomeAction from './actions/HomeAction'
import EventsAction from '../events/actions/EventsAction'
import AdministrationAction from '../administration/actions/AdministrationAction'
import TerritoryAction from '../territory/actions/TerritoryAction'
import {
    PATH_TERRITORY,
    PATH_REFERENCIAL_DASHBOARD,
    PATH_CONTENTS,
} from './constants/RouteConstants'
import { isConnected, getSetting } from '../../utils/SettingUtils'
import { sortByIdRevert } from '../../utils/ArrayUtils'
import ActionComponent from '../../components/actions/ActionComponent'
import { DwldButton } from '../../styles/button'
import { mainBlue, mainGreen } from '../../styles/theme'
import { FR } from '../referencial/constants/ReferencialConstants'
import ProgressBar from '../../components/ProgressBar'
import TwitterComponent from './components/TwitterComponent'

const IconStyle = {
    position: 'absolute',
    left: '30px',
}

const Text = styled.p`
    text-align: center;
    margin: 0;
    color: ${mainBlue};
`
const MenuButton = styled(DwldButton)`
    width: 90%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
`
const Head = styled(Grid)`
    width: 100%;
    height: 40px;
    padding: 0 20px;
    color: white;
    background-color: ${mainBlue};
`

const HeadTitle = styled.span`
    color: white;
    font-size: 1.5rem;
`

const Content = styled.p`
    text-align: center;
    margin: 3px 0;
`

const TooltipStyled = withStyles({
    tooltip: {
        fontSize: '1rem',
    },
})(Tooltip)

class Dashboard extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            date: new Date(),
            documents: [],
            dataLoaded: false,
            territoriesDataLoaded: false,
            selectedTerritory: 0,
            filteredMatrices: [],
            selectedMatrice: 0,
            matricesCompleted: 0,
            matricesUncompleted: 0,
            scenariosValid: 0,
            scenariosUnvalid: 0,
            googleCalendarId: '',
        }
    }

    componentDidMount() {
        const actions = {}
        this.setActions(actions)
        if (isConnected()) {
            this.props.fetchAllMatrices()
            this.props.fetchAllScenarios()
            this.props.fetchTerritoires().then(() => {
                this.setState({ territoriesDataLoaded: true })
            })
            // this.props.fetchCMSEvents('dashboard').then(() => {
            //     if (this.props.cmsEvents) {
            //         this.fetchHomeDocuments(this.props.cmsEvents)
            //     }
            // })
            this.props.fetchApplicationSettings().then(() => {
                this.setState({
                    googleCalendarId: getSetting(
                        this.props.applicationSettings,
                        'GoogleCalendarID',
                    ),
                })
            })
            this.props.setTitle([
                {
                    title: i18n.dashboard,
                    href: '',
                },
            ])
        }
        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        const actions = {}
        this.setActions(actions)
    }

    handleChange = () => {
        this.setState({ date: new Date() })
    }

    // fetchHomeDocuments(cmsEvents) {
    //     let filteredEvents = cmsEvents
    //     if (getUser().isAdmin === '0') {
    //         filteredEvents = cmsEvents.filter((e) => e.authorization !== 'admin')
    //         if (getUser().labo === '0') {
    //             filteredEvents = filteredEvents.filter((e) => e.authorization !== 'anim')
    //         }
    //     }
    //     filteredEvents.forEach((e) => {
    //         if (e.idCategory === -2 && e.document.length !== 0) {
    //             e.document.forEach((d) => {
    //                 const { documents } = this.state
    //                 const newDocs = documents.concat({
    //                     ...d,
    //                     url: `${contentsPath}DOCUMENTS/${d.name}`,
    //                 })
    //                 this.setState({ documents: newDocs })
    //             })
    //         }
    //     })
    //     this.setState({ dataLoaded: true })
    // }

    // setHomeDocuments() {
    //     return this.state.documents.map((d) => {
    //         const nameArr = d.name.split('_')
    //         let name = ''
    //         for (let i = 1; i < nameArr.length; i++) {
    //             name += nameArr[i]
    //         }
    //         return (
    //             <a
    //                 href={d.url}
    //                 target="_blank"
    //                 rel="noopener noreferrer"
    //                 alt="pdf"
    //                 style={{ paddingTop: '15px', paddingBottom: '15px' }}
    //             >
    //                 <Icon className="pdf_home">description</Icon>
    //                 <p className="title_pdf_home" style={{ wordBreak: 'break-word' }}>{name}</p>
    //             </a>
    //         )
    //     })
    // }

    onChangeSelectTerritory = (value) => {
        const { allMatrices = [], allScenarios = [] } = this.props
        const filteredMatrices = allMatrices.filter((m) => m.tid === value)
        this.setState({ selectedTerritory: value, selectedMatrice: 0, filteredMatrices })
        let matricesCompleted = 0
        let matricesUncompleted = 0
        if (filteredMatrices.length > 0) {
            filteredMatrices.forEach((m) => {
                if (allScenarios.find((sc) => sc.mid === m.mid)) {
                    matricesCompleted++
                } else {
                    matricesUncompleted++
                }
            })
        }
        this.setState({ matricesCompleted, matricesUncompleted })
        this.setState({ scenariosValid: 0, scenariosUnvalid: 0 })
    }

    onChangeSelectMatrice = (value) => {
        this.setState({ selectedMatrice: value })
        let scenariosValid = 0
        let scenariosUnvalid = 0
        this.props.fetchScenariosMatrice(value).then(() => {
            if (this.props.scenarios.length > 0) {
                this.props.scenarios.forEach((sc) => {
                    this.props.fetchScenario(sc.id).then(() => {
                        const { scenario } = this.props
                        if (scenario && scenario.results.length > 0) {
                            scenariosValid++
                        } else {
                            scenariosUnvalid++
                        }
                        this.setState({ scenariosValid, scenariosUnvalid })
                    })
                })
            } else {
                this.setState({ scenariosValid, scenariosUnvalid })
            }
        })
    }

    render() {
        const {
            selectedTerritory,
            filteredMatrices,
            selectedMatrice,
            matricesCompleted,
            matricesUncompleted,
            scenariosValid,
            scenariosUnvalid,
            googleCalendarId,
            territoriesDataLoaded,
            dataLoaded,
        } = this.state
        const { territoires, applicationSettings } = this.props
        territoires.sort(sortByIdRevert)

        const selectEditMode = {
            height: '40px',
            width: '100%',
            marginRight: '5px',
        }
        const selectDisabledMode = {
            ...selectEditMode,
            backgroundColor: 'rgb(240, 240, 240)',
        }

        const urlLang = (
            getSetting(applicationSettings, 'supportLanguage') || FR
        ).toLowerCase()
        const formationToken = getSetting(applicationSettings, 'formationToken')

        const calendarUrl = `https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FParis&src=${googleCalendarId}&color=%23039BE5&showCalendars=0&showTz=0&showTabs=0&showPrint=0&showDate=1&showNav=1&showTitle=0`

        this.props.setHelpPath('', applicationSettings)
        return (
            <div>
                <img
                    className="imgHeader"
                    target="_blank"
                    rel="noopener noreferrer"
                    src={header}
                    alt="coquelicot"
                    style={{ width: '100%', marginTop: '-2px' }}
                />
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="flex-start"
                    style={{ paddingTop: '20px', margin: '0' }}
                >
                    <Grid
                        item
                        md={3}
                        sm={5}
                        xs={11}
                        style={{ marginBottom: '10px', backgroundColor: 'white' }} 
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Head container direction="row" justify="center" alignItems="center">
                            <HeadTitle>{i18n.myTerritories}</HeadTitle>
                        </Head>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            style={{ border: `${mainBlue} solid` }}
                        >
                            {territoriesDataLoaded ? (
                                <>
                                    <FormControl variant="outlined">
                                        <Select
                                            name="sortBy"
                                            defaultValue={0}
                                            value={selectedTerritory}
                                            style={selectEditMode}
                                            onChange={(e) => this.onChangeSelectTerritory(e.target.value)}
                                        >
                                            <MenuItem value={0} disabled>
                                                {i18n.selectTerritory}
                                            </MenuItem>
                                            {territoires.map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <Grid item>
                                        <Content>
                                            {matricesCompleted > 1
                                                ? i18n.matricesCompleted
                                                : i18n.matriceCompleted}
                                            &nbsp;:&nbsp;
                                            <span style={{ color: 'green' }}>
                                                {matricesCompleted}
                                            </span>
                                        </Content>
                                    </Grid>
                                    <Grid item>
                                        <Content>
                                            {matricesUncompleted > 1
                                                ? i18n.matricesUncompleted
                                                : i18n.matriceUncompleted}
                                            &nbsp;:&nbsp;
                                            <span style={{ color: 'red' }}>
                                                {matricesUncompleted}
                                            </span>
                                        </Content>
                                    </Grid>
                                    <FormControl variant="outlined">
                                        <Select
                                            name="sortBy"
                                            defaultValue={0}
                                            disabled={selectedTerritory === 0}
                                            value={selectedMatrice}
                                            style={
                                                selectedTerritory === 0
                                                    ? selectDisabledMode
                                                    : selectEditMode
                                            }
                                            onChange={(e) => this.onChangeSelectMatrice(e.target.value)}
                                        >
                                            <MenuItem value={0} disabled>
                                                {i18n.selectMatrice}
                                            </MenuItem>
                                            {filteredMatrices.map((m) => <MenuItem value={m.mid}>{m.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <Grid item>
                                        <Content>
                                            {scenariosValid > 1
                                                ? i18n.scenariosValid
                                                : i18n.scenarioValid}
                                            &nbsp;:&nbsp;
                                            <span style={{ color: 'green' }}>{scenariosValid}</span>
                                        </Content>
                                    </Grid>
                                    <Grid item>
                                        <Content>
                                            {scenariosUnvalid > 1
                                                ? i18n.scenariosUnvalid
                                                : i18n.scenarioUnvalid}
                                            &nbsp;:&nbsp;
                                            <span style={{ color: 'red' }}>{scenariosUnvalid}</span>
                                        </Content>
                                    </Grid>
                                </>
                            ) : (
                                <ProgressBar width="100%" />
                            )}
                        </Grid>
                        <Grid item>
                            <iframe
                                title="calendar"
                                src={calendarUrl}
                                style={{ border: 'solid 1px #777', marginTop: '10px', width: '100%' }}
                                height="400"
                                frameBorder="0"
                                scrolling="no"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        sm={5}
                        xs={11}
                        style={{ marginBottom: '10px', textAlign: 'center' }}
                    >
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="center"
                            className="cardStyle clickable"
                            style={{ marginBottom: '10px' }}
                        >
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                color: mainGreen,
                            }}
                            >
                                <Icon onClick={() => window.open(faqUrl, '_blank')}>help</Icon>
                            </div>
                            <div
                                role="link"
                                onClick={() => {
                                    this.props.push(PATH_TERRITORY)
                                }}
                                style={{
                                    paddingTop: '0',
                                    paddingBottom: '30px',
                                    color: mainBlue,
                                }}
                            >
                                <Icon className="pdf_home">filter_hdr</Icon>
                                <p>Créer ou consulter un territoire</p>
                            </div>
                        </Grid>
                        {/* <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="center"
                            className="cardStyle"
                        >
                            {dataLoaded ? this.setHomeDocuments() : <CircularProgress />}
                        </Grid> */}
                    </Grid>
                    <Grid
                        item
                        md={3}
                        sm={5}
                        xs={11}
                        style={{ marginBottom: '10px' }}
                        className="cardStyle"
                        style={{ textAlign: 'center' }}
                    >
                        <TooltipStyled
                            title="Consulter les différents documents de présentation ou de méthodologie"
                            placement="right"
                            arrow
                        >
                            <MenuButton
                                onClick={() => {
                                    this.props.push(`${PATH_CONTENTS}/-2`)
                                }}
                            >
                                <Icon style={IconStyle}>folder</Icon>
                                <Text>{i18n.documentation}</Text>
                            </MenuButton>
                        </TooltipStyled>
                        <a
                            href={faqUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <MenuButton>
                                <Icon style={IconStyle}>help</Icon>
                                <Text>Foire Aux Questions</Text>
                            </MenuButton>
                        </a>
                        {this.props.accountUser.isAdmin === '1' ? (
                            <TooltipStyled
                                title="Consulter les paramètres de territoires déjà existant"
                                placement="right"
                                arrow
                            >
                                <MenuButton
                                    onClick={() => {
                                        this.props.push(PATH_REFERENCIAL_DASHBOARD)
                                    }}
                                >
                                    <Icon style={IconStyle}>search</Icon>
                                    <Text>{i18n.referencials}</Text>
                                </MenuButton>
                            </TooltipStyled>
                        ) : (
                            ''
                        )}
                        <MenuButton
                            target="_blank"
                            href={`https://helpcenter.coclickeau.aquasys.fr/about_${urlLang}/?tmp=${formationToken}`}
                        >
                            <Icon style={IconStyle}>info</Icon>
                            <Text>{i18n.about}</Text>
                        </MenuButton>
                        <MenuButton
                            target="_blank"
                            href={`https://helpcenter.coclickeau.aquasys.fr/confidential_${urlLang}/?tmp=${formationToken}`}
                        >
                            <Icon style={IconStyle}>security</Icon>
                            <Text>{i18n.confidentiality}</Text>
                        </MenuButton>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        sm={5}
                        xs={11}
                        style={{ marginBottom: '10px' }}
                        className="cardStyle"
                    >
                        <TwitterComponent />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsCategories: store.EventsReducer.cmsCategories,
    document: store.EventsReducer.document,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    territoires: store.TerritoryReducer.territoires,
    allMatrices: store.TerritoryReducer.allMatrices,
    allScenarios: store.TerritoryReducer.allScenarios,
    scenarios: store.TerritoryReducer.scenarios,
    scenario: store.TerritoryReducer.scenario,
})

const mapDispatchToProps = {
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    fetchCMSEvents: EventsAction.fetchCMSEvents,
    fetchCMSDocument: EventsAction.fetchCMSDocument,
    fetchTerritoires: TerritoryAction.fetchTerritoires,
    fetchAllMatrices: TerritoryAction.fetchAllMatrices,
    fetchAllScenarios: TerritoryAction.fetchAllScenarios,
    fetchMatricesTerritoires: TerritoryAction.fetchMatricesTerritoires,
    fetchScenariosMatrice: TerritoryAction.fetchScenariosMatrice,
    fetchScenario: TerritoryAction.fetchScenario,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
