export default class {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.authorization = obj.authorization
        this.visibility = obj.visibility
        this.quality = obj.quality
        this.piezometry = obj.piezometry
        this.hydrometry = obj.hydrometry
        this.pluviometry = obj.pluviometry
        this.homepage = obj.homepage
        this.referencials = obj.referencials
        this.productionUnit = obj.productionUnit
        this.distributionUnit = obj.distributionUnit
        this.installation = obj.installation
        this.color = obj.color && !obj.color.includes('rgb') ? `rgb(${obj.color})` : obj.color
        this.isAquaDBTheme = obj.isAquaDBTheme
        this.themeOrder = obj.themeOrder
    }
}
