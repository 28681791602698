import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import TextField from '@material-ui/core/TextField'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import TerritoryAction from '../actions/TerritoryAction'
import Input from '../../../components/forms/Input'
import { GreenButton } from '../../../styles/button'
import { getLogin } from '../../../utils/SettingUtils'

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

const ModalNewScenario = ({ open, handleClose, mid, indicateurs, createScenario }) => {
    const [newNameScenario, setNewNameScenario] = useState('')
    const [newScenarioDescription, setNewScenarioDescription] = useState('')

    const closeModal = () => {
        handleClose()
    }

    const closeAndSaveNewScenario = () => {
        closeModal()
        const newScenario = {
            mid,
            name: newNameScenario,
            description: newScenarioDescription,
            scRef: 0,
            direction: 0,
            iidDirection: -1,
            datecreation: Date.now(),
            usermaj: getLogin(),
            datemaj: null,
            objectifs: [
                {
                    id: 1,
                    scid: null,
                    category: -2,
                    mode: null,
                    val_1: null,
                    val_2: null,
                    actif: false,
                    description: '',
                    objectifRef: [],
                },
                {
                    id: 2,
                    scid: null,
                    category: 1,
                    mode: null,
                    val_1: null,
                    val_2: null,
                    actif: false,
                    description: '',
                    objectifRef: [],
                },
                {
                    id: 3,
                    scid: null,
                    category: 0,
                    mode: null,
                    val_1: null,
                    val_2: null,
                    actif: false,
                    description: '',
                    objectifRef: [],
                },
            ],
            constraintsAB: [],
            constraintsEco: [],
            respect: true,
        }
        createScenario(newScenario, indicateurs)
    }

    const SetDescription = (event) => {
        setNewScenarioDescription(event.target.value)
    }

    const inputStyle = {
        width: '200px',
        height: '48px',
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={closeModal}
        >
            <DialogTitle>{i18n.addScenario}</DialogTitle>
            <DialogContent>
                <div>
                    <Label htmlFor="new_scenario_name">{i18n.name}&nbsp;:</Label>
                    <Input
                        style={inputStyle}
                        onChange={(event) => setNewNameScenario(event)}
                    />
                </div>
                <div>
                    <Label htmlFor="new_scenario_description" style={{ margin: '1rem 0' }}>
                        {i18n.descriptive}&nbsp;:
                    </Label>
                    <TextField
                        title={i18n.descriptive}
                        style={{ width: '500px' }}
                        onChange={SetDescription}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <GreenButton
                    greenReverse
                    borderRadius
                    style={{ margin: '0 1rem' }}
                    onClick={closeModal}
                >
                    {i18n.cancel}
                </GreenButton>
                <GreenButton borderRadius onClick={closeAndSaveNewScenario}>
                    {i18n.add}
                </GreenButton>
            </DialogActions>
        </Dialog>
    )
}

ModalNewScenario.defaultProps = {
    open: false,
}
ModalNewScenario.propTypes = {
    indicateurs: PropTypes.arrayOf(PropTypes.shape({})),
    createScenario: PropTypes.func,
    mid: PropTypes.number.isRequired,
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
}
const mapStateToProps = (store) => ({
    mid: store.TerritoryReducer.matrice.mid,
})

const mapDispatchToProps = {
    createScenario: TerritoryAction.createScenario,
    fetchTerritory: TerritoryAction.fetchTerritory,
    fetchAssolementsTerritoires: TerritoryAction.fetchAssolementsTerritoires,
    fetchZonesSurfacesTerritoires: TerritoryAction.fetchZonesSurfacesTerritoires,
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalNewScenario)
