import React, { Component } from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import CommuneDto from '../dto/CommuneDto'
import { MainContainer, RefTable, Position } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import UpdatePanel from '../../../../../components/UpdatePanel'
import AccountAction from '../../../../account/actions/AccountAction'
import ProgressBar from '../../../../../components/ProgressBar'

class CommunesApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columnDefs: [
                {
                    headerName: i18n.code,
                    field: 'id',
                },
                {
                    headerName: i18n.name,
                    field: 'name',
                },
                {
                    headerName: i18n.department,
                    field: 'departmentNumber',
                },
                {
                    headerName: i18n.area,
                    field: 'area',
                },
                {
                    headerName: i18n.countryCode,
                    field: 'countryCode',
                },
                {
                    headerName: i18n.x,
                    field: 'x',
                },
                {
                    headerName: i18n.y,
                    field: 'y',
                },
                {
                    headerName: i18n.projection,
                    field: 'projection',
                },
            ],
            dataLoaded: false,
            rowData: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.cities,
                href: 'referencial/communes',
            },
        ])
        this.props.fetchCommunes().then(() => {
            this.setData(this.props.communes)
        })
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = this.props.communes.filter((c) => c.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    setData(communes) {
        const arr = communes.map((commune) => ({
            id: commune.codeCommune,
            name: commune.name,
            departmentNumber: commune.departmentNumber,
            area: commune.area,
            countryCode: commune.countryCode,
            x: commune.x,
            y: commune.y,
            projection: commune.projection,
        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    render() {
        const { dataLoaded } = this.state
        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <RefTable className="ag-theme-balham">
                            <div style={{ width: '100%', margin: '3rem' }}>
                                <Position>{this.getUpdatePanel()}</Position>
                            </div>
                            <AgGridTable
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                frameworkComponents={this.state.frameworkComponents}
                            />
                        </RefTable>
                    ) : (
                        <ProgressBar />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

CommunesApp.propTypes = {
    fetchCommunes: PropTypes.func,
    setTitle: PropTypes.func,
    fetchUser: PropTypes.func,
    communes: PropTypes.arrayOf(CommuneDto),
}

const mapStateToProps = (store) => ({
    communes: store.ReferencialReducer.communes,
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCommunes: ReferencialAction.fetchCommunes,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunesApp)
