import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import ActionComponent from '../../components/actions/ActionComponent'
import ReferencialAction from '../referencial/action/ReferencialAction'
import { getLogin } from '../../utils/SettingUtils'
import HomeAction from '../home/actions/HomeAction'
import TerritoryAction from './actions/TerritoryAction'
import TerritoryPanel from './TerritoryPanel'
import ProgressBar from '../../components/ProgressBar'

const Container = styled.div`
    margin: 2rem 6rem 5rem 8rem;
`

class NewTerritory extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            territory: {
                id: 0,
                name: 'Nouveau territoire',
                statut: 1,
                referencial: false,
                usermaj: getLogin(),
                zones: [],
                cultures: [],
                modesConduites: [],
                utilisateurs: [],
                indicateurs: [],
                login: getLogin() || '',
            },
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.setActions({
            save: () => this.props.createTerritory(this.state.territory),
            cancel: () => {
                this.props.push('/territories')
            },
        })
        this.props.fetchElementaryBricks(-1).then(() => this.setState({ dataLoaded: true }))
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
            {
                title: i18n.new,
                href: 'territories/new',
            },
        ])
        window.scrollTo(0, 0)
    }

    onChangeTerritory = (territory) => {
        this.setState({ territory })
    }

    onChangeArrayTerritory = (key, value) => {
        const { territory } = this.state
        const formattedData = value.map((v) => ({
            id: -1,
            dataId: v,
            usermaj: getLogin(),
        }))
        this.setState({
            territory: {
                ...territory,
                [key]: formattedData,
            },
        })
    }

    onChangeSelected = (key, value) => {
        this.setState({
            [key]: value,
        })
    }

    render() {
        const { territory, dataLoaded } = this.state
        return (
            <Container>
                {dataLoaded ? (
                    <TerritoryPanel
                        newTerritory
                        onChangeTerritory={this.onChangeTerritory}
                        territory={territory}
                    />
                ) : <ProgressBar />}
            </Container>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchElementaryBricks: ReferencialAction.fetchElementaryBricks,
    createTerritory: TerritoryAction.createTerritory,
    push,
}

export default connect(null, mapDispatchToProps)(NewTerritory)
