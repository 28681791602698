import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import { Chip, Grid } from '@material-ui/core'
import { Flex } from '../../../styles/main'
import { mainPink } from '../../../styles/theme'
import Input from '../../../components/forms/Input'
import { GreenButton, GreenUploadLabel } from '../../../styles/button'
import DtoZone from './zones/dto/DtoZone'
import CultureDto from './cultures/dto/CultureDto'
import IndicateurDto from './indicateurs/dto/IndicateurDto'
import ModeConduiteDto from './conduites/dto/ModeConduiteDto'
import ReferencialAction from '../action/ReferencialAction'
import { getLogin } from '../../../utils/SettingUtils'
import ToastrAction from '../../../utils/ToastrAction'
import { DldIcon, DldIconText } from '../../../styles/text'
import { modesConduitesParentsList, seasonList } from '../constants/ReferencialConstants'

const Right = styled.div`
    display: flex;
    justify-content: end;
`

const PinkBloc = styled.div`
    width: 100%;
    border: 4px solid ${mainPink};
    border-radius: 5px;
    position: relative;
    margin: 10px 0;
`

const PinkTitle = styled.div`
    width: fit-content;
    height: 30px;
    font-size: 1.4rem;
    color: ${mainPink};
    background-color: white;
    position: absolute;
    top: -20px;
    left: 10px;
    text-align: center;
    padding: 0 10px 0 10px;
`

const Wrapper = styled(Flex)`
    padding: 1rem;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

export function getModalStyle() {
    const top = 60
    const left = 60

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const ModalAddReferencial = ({
    zone,
    culture,
    driveMode,
    perf,
    open,
    handleClose,
}) => {
    const [newNameZone, setNewNameZone] = useState('nouvelleZone')
    const [newKmlZone, setNewKmlZone] = useState()
    const [newKmlFileName, setNewKmlFileName] = useState('')
    const [newNameCulture, setNewNameCulture] = useState('nouvelleCulture')
    const [newPeriodeCulture, setNewPeriodeCulture] = useState(0)
    const [newRPGCulture, setNewRPGCulture] = useState([])
    const [newRotationCulture, setNewRotationCulture] = useState(false)
    const [newLegumineuseCulture, setNewLegumineuseCulture] = useState(false)
    const [newNameConduite, setNewNameConduite] = useState('nouveauMode')
    const [newParentConduite, setNewParentConduite] = useState(1)
    const [newNameIndic, setNewNameIndic] = useState('nouvelIndicateur')
    
    const dispatch = useDispatch()

    const {
        culturesRPG,
    } = useSelector((store) => ({
        culturesRPG: store.ReferencialReducer.culturesRPG,
    }), shallowEqual)

    const closeModal = () => {
        handleClose()
    }

    const onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            const file = event.target.files[0]
            reader.readAsText(file, 'UTF-8')
            reader.onload = (e) => {
                setNewKmlZone(e.target.result)
                setNewKmlFileName(file.name)
            }
            reader.onerror = () => ToastrAction.error('Erreur lors de la lecture du fichier')
        }
    }

    const onValidateZone = () => {
        const newZone = new DtoZone({
            id: -1,
            name: newNameZone,
            referentiel: false,
            kml: newKmlZone,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createZone(newZone)).then(() => dispatch(ReferencialAction.fetchZones()))
    }

    const onValidateCulture = () => {
        const newCulture = new CultureDto({
            id: -1,
            name: newNameCulture,
            teteRotation: newRotationCulture,
            legumineuse: newLegumineuseCulture,
            periodeSemence: newPeriodeCulture,
            linkCulturesRPG: newRPGCulture,
            referentiel: false,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createCulture(newCulture)).then(() => dispatch(ReferencialAction.fetchCultures()))
    }

    const onValidateConduite = () => {
        const newConduite = new ModeConduiteDto({
            id: -1,
            name: newNameConduite,
            parent: newParentConduite,
            usermaj: getLogin(),
        })
        dispatch(ReferencialAction.createConduite(newConduite)).then(() => dispatch(ReferencialAction.fetchConduites()))
    }

    const onValidateIndicator = () => {
        const newIndicator = new IndicateurDto({ id: -1, name: newNameIndic, usermaj: getLogin() })
        dispatch(ReferencialAction.createIndicateur(newIndicator)).then(() => dispatch(ReferencialAction.fetchIndicateurs()))
    }

    const inputStyle = {
        width: '200px',
        height: '48px',
    }

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={closeModal}
            disableBackdropClick
        >
            <div style={{ padding: 10 }}>
                {zone && (
                <>
                    <PinkBloc>
                        <PinkTitle>{i18n.createNewZone}</PinkTitle>
                        <Wrapper>
                            <div>
                                <Label htmlFor="zone_name">{i18n.name}&nbsp;:</Label>
                                <Input
                                    style={inputStyle}
                                    value={newNameZone}
                                    onChange={(event) => setNewNameZone(event)}
                                />
                            </div>
                            <Grid item xs={3} container direction="column">
                                <input
                                    name="file"
                                    accept=".kml, .KML"
                                    id="uploadKML"
                                    type="file"
                                    className="uploadFileInput"
                                    onChange={onUploadFile}
                                />
                                {newKmlFileName || 'Sélectionner un fichier :'}
                                <GreenUploadLabel for="uploadKML">
                                    <DldIcon>file_upload</DldIcon>&nbsp;&nbsp;
                                    <DldIconText>Fichier KML</DldIconText>
                                </GreenUploadLabel>
                            </Grid>
                        </Wrapper>
                    </PinkBloc>
                    <Right>
                        <GreenButton
                            onClick={() => closeModal()}
                            greenReverse
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.close}
                        </GreenButton>
                        <GreenButton
                            onClick={onValidateZone}
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.add}
                        </GreenButton>
                    </Right>
                </>
                )}
                {culture && (
                <>
                    <PinkBloc>
                        <PinkTitle>
                            {i18n.createNewCulture} :
                        </PinkTitle>
                        <Wrapper>
                            <div>
                                <Label htmlFor="culture_name">{i18n.name}&nbsp;:</Label>
                                <Input
                                    style={inputStyle}
                                    value={newNameCulture}
                                    onChange={(event) => setNewNameCulture(event)}
                                />
                            </div>
                            <FormControl variant="outlined" style={{ marginRight: 10 }}>
                                <Label htmlFor="semence">
                                    {i18n.sowingDate}&nbsp;:
                                </Label>
                                <Select
                                    name="semence"
                                    value={newPeriodeCulture}
                                    onChange={(v) => setNewPeriodeCulture(v.target.value)}
                                >
                                    {seasonList.map((item) => (
                                        <MenuItem value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" style={{ width: 400 }}>
                                <Label htmlFor="culturesRPG">
                                    {i18n.culturesRPG}&nbsp;:
                                </Label>
                                <Select
                                    name="culturesRPG"
                                    multiple
                                    value={newRPGCulture}
                                    onChange={(e) => setNewRPGCulture(e.target.value)}
                                    renderValue={(selected) => (
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} style={{ margin: 2 }} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {culturesRPG.map((item) => (
                                        <MenuItem value={item.code}>
                                            {item.codeWithName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormGroup row>
                                <FormControlLabel
                                    style={{
                                        transform: 'translate(10px, 13px)',
                                    }}
                                    control={(
                                        <Checkbox
                                            checked={newRotationCulture}
                                            onChange={(v) => setNewRotationCulture(v.target.checked)}
                                            value="rotation"
                                            color="primary"
                                        />
                                    )}
                                    label="Tête de rotation"
                                />
                                <FormControlLabel
                                    style={{
                                        transform: 'translate(10px, 13px)',
                                    }}
                                    control={(
                                        <Checkbox
                                            checked={newLegumineuseCulture}
                                            onChange={(v) => setNewLegumineuseCulture(
                                                v.target.checked,
                                            )}
                                            value="legumineuse"
                                            color="primary"
                                        />
                                    )}
                                    label="Légumineuse"
                                />
                            </FormGroup>
                        </Wrapper>
                    </PinkBloc>
                    <Right>
                        <GreenButton
                            onClick={() => closeModal()}
                            greenReverse
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.close}
                        </GreenButton>
                        <GreenButton
                            onClick={onValidateCulture}
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.add}
                        </GreenButton>
                    </Right>
                </>
                )}
                {driveMode && (
                <>
                    <PinkBloc>
                        <PinkTitle>{i18n.createNewDrivenMode}</PinkTitle>
                        <Wrapper>
                            <div>
                                <Label htmlFor="name">{i18n.name}&nbsp;:</Label>
                                <Input
                                    style={inputStyle}
                                    value={newNameConduite}
                                    onChange={(event) => setNewNameConduite(event)}
                                />
                            </div>
                            <div>
                                <FormControl variant="outlined">
                                    <Label htmlFor="rotate">
                                        {i18n.inheritedRotationRule}&nbsp;:
                                    </Label>
                                    <Select
                                        name="rotate"
                                        value={newParentConduite}
                                        onChange={(v) => setNewParentConduite(v.target.value)}
                                    >
                                        {modesConduitesParentsList.map((item) => (
                                            <MenuItem value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Wrapper>
                    </PinkBloc>
                    <Right>
                        <GreenButton
                            onClick={() => closeModal()}
                            greenReverse
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.close}
                        </GreenButton>
                        <GreenButton
                            onClick={onValidateConduite}
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.add}
                        </GreenButton>
                    </Right>
                </>
                )}
                {perf && (
                <>
                    <PinkBloc>
                        <PinkTitle>{i18n.createNewIndicatorPerformance}</PinkTitle>
                        <Wrapper>
                            <div>
                                <Label htmlFor="ind_perf_name">
                                    {i18n.name}&nbsp;:
                                </Label>
                                <Input
                                    style={inputStyle}
                                    value={newNameIndic}
                                    onChange={(event) => setNewNameIndic(event)}
                                />
                            </div>
                        </Wrapper>
                    </PinkBloc>
                    <Right>
                        <GreenButton
                            onClick={() => closeModal()}
                            greenReverse
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.close}
                        </GreenButton>
                        <GreenButton
                            onClick={onValidateIndicator}
                            borderRadius
                            style={{ margin: '0 1rem 10px' }}
                        >
                            {i18n.add}
                        </GreenButton>
                    </Right>
                </>
                )}
            </div>
        </Dialog>
    )
}

ModalAddReferencial.defaultProps = {
    zone: false,
    culture: false,
    driveMode: false,
    perf: false,
}

ModalAddReferencial.propTypes = {
    handleClose: PropTypes.func,
    zone: PropTypes.bool,
    culture: PropTypes.bool,
    driveMode: PropTypes.bool,
    perf: PropTypes.bool,
    open: PropTypes.bool.isRequired,
}

export default ModalAddReferencial
