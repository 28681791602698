import { getFullDateMini } from '../../../utils/DateUtil'

export default class DtoReferencial {
    constructor(object) {
        this.id = object.id
        this.code = object.code
        this.name = object.name
        this.status = object.status
        this.usermaj = object.usermaj
        this.datemaj = object.datemaj ? getFullDateMini(object.datemaj) : null
    }
}
