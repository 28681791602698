import DtoReferencial from '../../../dto/DtoReferencial'

export default class ModeConduiteDto extends DtoReferencial {
    constructor(object) {
        super(object)
        this.labelWithCode = `${object.name} [${object.id}]`
        this.name = object.name
        this.parent = object.parent
        this.referentiel = object.referentiel
        this.headers = ['code', 'name']
    }
}
