import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TableFilter extends Component {
    render() {
        return (
            <div className="dataTables_filter">
                <input
                    type="text"
                    className="input-field validate form-control input-sm"
                    placeholder="Rechercher"
                    aria-controls="tableStationQualito"
                    onChange={this.props.onFilter}
                />
            </div>
        )
    }
}

TableFilter.propTypes = {
    onFilter: PropTypes.func,
}
