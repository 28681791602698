module.exports = {
    modesConduitesParentsList: [
        { value: 1, label: 'Rendement +' },
        { value: 2, label: 'Raisonné' },
        { value: 3, label: 'Econome' },
        { value: 4, label: 'Econome +' },
        { value: 5, label: 'AB' },
    ],
    seasonList: [
        { value: 0, label: 'Pluriannuelle' },
        { value: 1, label: 'Printemps' },
        { value: 2, label: 'Automne' },
    ],
    nbPerPageLabel: [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelMedium: [
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelShorty: [
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelShort: [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        {
            label: '100',
            value: 100,
        },
        { label: 'Tout', value: -1 },
    ],
    nbPerPageLabelTiny: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: 'Tout', value: -1 },
    ],
    RECEIVE_CULTURES: 'RECEIVE_CULTURES',
    RECEIVE_CULTURES_RPG: 'RECEIVE_CULTURES_RPG',
    RECEIVE_RPG: 'RECEIVE_RPG',
    RECEIVE_MATRICE_REF: 'RECEIVE_MATRICE_REF',
    RECEIVE_CULTURE: 'RECEIVE_CULTURE',
    RECEIVE_ZONES: 'RECEIVE_ZONES',
    RECEIVE_COMMUNES: 'RECEIVE_COMMUNES',
    RECEIVE_CONDUITES: 'RECEIVE_CONDUITES',
    RECEIVE_ROTATIONS_AB: 'RECEIVE_ROTATIONS_AB',
    RECEIVE_ROTATIONS_ECO: 'RECEIVE_ROTATIONS_ECO',
    RECEIVE_INDICATEURS: 'RECEIVE_INDICATEURS',
    RECEIVE_INDICATEUR: 'RECEIVE_INDICATEUR',
    RECEIVE_CONDUITES_PARENTS: 'RECEIVE_CONDUITES_PARENTS',
    RECEIVE_REFERENCIAL_STATUS: 'RECEIVE_REFERENCIAL_STATUS',
    RECEIVE_REFERENCIAL_SUPPORT: 'RECEIVE_REFERENCIAL_SUPPORT',
    RECEIVE_STATION_TYPES: 'RECEIVE_STATION_TYPES',
    RECEIVE_REFERENCIAL_DOMAIN: 'RECEIVE_REFERENCIAL_DOMAIN',
    RECEIVE_SANDRE_CODES: 'RECEIVE_SANDRE_CODES',
    RECEIVE_CHECK_DELETE: 'RECEIVE_CHECK_DELETE',
    RESET_CHECK_DELETE: 'RESET_CHECK_DELETE',
    RECEIVE_REFERENCIAL_SANDRE_CODES: 'RECEIVE_REFERENCIAL_SANDRE_CODES',
    RECEIVE_ALL_DATA_TYPE: 'RECEIVE_ALL_DATA_TYPE',
    SANDRE: {
        USAGES: 'PIEZOMETRES_PRELEVEMENTS.USAGES',
        ARRANGEMENT_TYPES: 'AMENAGEMENT.TYPE_AMENAGEMENT',
        LITHOLOGY: 'LITHOLOGIE.CODE_LITHOLOGIE',
        PROJECTION: 'PIEZOMETRES.TYPEPROJECTION',
        CONTRIBUTOR_TYPES: 'LIEN_PIEZO_INTERVENANTS.TYPEINTERVENANT',
        CASING_MATERIAL: 'TUBAGES.MATERIEAU',
        FIELD_MODES: 'PIEZOMETRES.MODEGISEMENT',
        PIEZOMETER_NATURE: 'PIEZOMETRES.CODENATURE',
        ALTIMETRIC_SYSTEM_NATURE: 'SYSTEME_ALTIMETRIQUE.CODENATURE',
        STATE_CODES: 'QUALITOMETRES.CODEETAT',
        PIEZOMETER_MEASURE_NATURE: 'PIEZOMETRES.TYPE',
        PIEZOMETER_EXPRESSION_COTE: 'PIEZOMETRES.EXPRESSIONCOTE',
        CENTRALE_FREQUENCE_TRANSMISSION: 'CENTRALE.FREQUENCE_TRANSMISSION',
    },
    REFERENCIAL_LABELS: [
        { single: 'city', plural: 'cities' },
        { single: 'contact', plural: 'contacts' },
        { single: 'network', plural: 'networks' },
        { single: 'fraction', plural: 'fractions' },
        { single: 'contributor', plural: 'contributors' },
        { single: 'watermass', plural: 'watermasses' },
        { single: 'method', plural: 'methods' },
        { single: 'parameter', plural: 'parameters' },
        { single: 'support', plural: 'supports' },
        { single: 'unit', plural: 'units' },
        { single: 'hydrogeologicalEntity', plural: 'hydrogeologicalEntities' },
        { single: 'watershed', plural: 'watersheds' },
    ],
    FR: 'FR',
    SUPPORTED_LANGUAGES: [
        { label: 'french', value: 'FR' },
        { label: 'english', value: 'EN' },
    ],
}
