export default class DashboardReferencialExportDto {
    constructor(object) {
        this.referencialName = object.referencialName
        this.updateDate = object.updateDate
        this.referencialVersion = object.referencialVersion
        this.updateStatus = object.updateStatus
        this.updateUser = object.updateUser
        this.recordingNumber = object.recordingNumber
        this.headers = ['referencialName', 'updateDate', 'referencialVersion', 'updateStatus', 'updateUser', 'recordingNumber']
    }
}
