/* eslint-disable import/no-cycle */
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../utils/ToastrAction'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import ApplicationConf from '../../../conf/ApplicationConf'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import { checkAuth, checkError, getAuthorization, getAuthorizationLogin, getJson } from '../../../utils/ActionUtils'
import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_HABILITATION,
    RECEIVE_ACCOUNT_STATIONS,
    RECEIVE_ACCOUNT_STATION_STATISTICS,
    RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USAGE_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
    RECEIVE_ACCOUNT_USER_CMS_STATISTICS,
} from '../constants/AccountConstants'

const AccountAction = {
    receiveUser(accountUser) {
        return {
            type: RECEIVE_ACCOUNT,
            accountUser,
        }
    },
    savedUser(user) {
        return {
            type: SAVED_USER,
            user,
        }
    },

    fetchUser(login) {
        return (dispatch) => fetch(ApplicationConf.user.get(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveUser(json))
                dispatch(AccountAction.fetchAccountHabilitations(json.login))
                dispatch(AccountAction.fetchAccountUserKeyfigure(json.login))
                dispatch(AccountAction.fetchAccountUserSettings())
                dispatch(AccountAction.fetchAccountStatistics(json.login))
                dispatch(AccountAction.fetchAccountStationStatistics(json.login))
                dispatch(AccountAction.fetchAccountUsageStatistics(json.login))
                dispatch(AccountAction.fetchAccountStations(json.login))
                dispatch(AccountAction.fetchAccountUserCmsStatistic(json.login))
                dispatch(AdministrationAction.fetchSettings())
                dispatch(ContactAction.fetchContacts())
            })
    },

    receiveUserCmsStatistic(accountUserCmsStatistics) {
        return { type: RECEIVE_ACCOUNT_USER_CMS_STATISTICS, accountUserCmsStatistics }
    },

    fetchAccountUserCmsStatistic(login) {
        return (dispatch) => fetch(ApplicationConf.user.getCmsStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveUserCmsStatistic(json))
            })
    },

    receiveAccountUserKeyfigures(accountUserKeyFigures) {
        return {
            type: RECEIVE_ACCOUNT_USER_KEYFIGURES,
            accountUserKeyFigures,
        }
    },

    fetchAccountUserKeyfigure(login) {
        return (dispatch) => fetch(ApplicationConf.user.getKeyFigures(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveAccountUserKeyfigures(json))
            })
    },
    fetchAccountCGUDate(login) {
        return () => fetch(ApplicationConf.user.getUserCGUDate(login), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                console.error(err)
                ToastrAction.error(i18n.errorGetUserInfo)
            })
    },
    receiveAccountHabilitation(accountHabilitations) {
        return { type: RECEIVE_ACCOUNT_HABILITATION, accountHabilitations }
    },
    fetchAccountHabilitations(login) {
        return (dispatch) => fetch(ApplicationConf.user.habilitations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveAccountHabilitation(json))
            })
    },
    receiveAccountStatistics(accountStatistics) {
        return { type: RECEIVE_ACCOUNT_STATISTICS, accountStatistics }
    },

    fetchAccountStatistics(login) {
        return (dispatch) => fetch(ApplicationConf.user.getStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveAccountStatistics(json))
            })
    },
    receiveAccountStationStatistics(accountStationStatistics) {
        return {
            type: RECEIVE_ACCOUNT_STATION_STATISTICS,
            accountStationStatistics,
        }
    },

    fetchAccountStationStatistics(login) {
        return (dispatch) => fetch(ApplicationConf.user.getStationStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveAccountStationStatistics(json))
            })
    },
    receiveAccountUsageStatistics(accountUsageStatistics) {
        return {
            type: RECEIVE_ACCOUNT_USAGE_STATISTICS,
            accountUsageStatistics,
        }
    },

    fetchAccountUsageStatistics(login) {
        return (dispatch) => fetch(ApplicationConf.user.getUsageStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveAccountUsageStatistics(json))
            })
    },
    receiveAccountStations(accountStations) {
        return { type: RECEIVE_ACCOUNT_STATIONS, accountStations }
    },
    fetchAccountStations(login) {
        return (dispatch) => fetch(ApplicationConf.user.userStations(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                dispatch(AccountAction.receiveAccountStations(json))
            })
    },
    updateContact(contact, isNew) {
        return (dispatch) => fetch(ApplicationConf.referencial.saveContact(), {
            method: isNew ? 'POST' : 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(contact),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1 || json.insert === 1) {
                    ToastrAction.success(i18n.saveSuccess)
                    dispatch(ContactAction.fetchContacts())
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.contact} : ${err}`)
                ToastrAction.error(i18n.updateError + i18n.contact)
            })
    },
    updateAccountCGUStatistic(object) {
        return () => fetch(ApplicationConf.user.postUserCGUDate(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(object),
        })
    },
    updateSettings(login, settings) {
        return (dispatch) => fetch(ApplicationConf.user.saveSettings(login), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(settings),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => dispatch(AccountAction.fetchAccountUserSettings()))
            .then(() => dispatch(AdministrationAction.fetchSettings()))
    },
    receiveAccountUserSettings(accountUserSettings) {
        return { type: RECEIVE_ACCOUNT_USER_SETTINGS, accountUserSettings }
    },
    fetchAccountUserSettings() {
        return (dispatch) => fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                dispatch(AccountAction.receiveAccountUserSettings(json))
            })
    },
}

export default AccountAction
