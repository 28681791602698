import styled from 'styled-components'

// GENERIC

export const Center = styled.div`
  text-align: center;
`

export const Clear = styled.div`
  clear: both;
`

export const Relative = styled.div`
  position: relative;
`

export const Left = styled.div`
  float: left;
`

export const Right = styled.div`
  float: right;
`

export const SpaceAround = styled.div`
  display: flex;
  justify-content: space-around;
`

// LINKS

export const UnderlinedLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
  color: ${(props) => props.theme.secondaryBlue};
`

// GRID & FLEX

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? 'column' : 'row')};
`
