export default class DtoUserStatisticItem {
    constructor(obj) {
        this.lastAccess = obj.lastAccess
        this.module = obj.module
        this.value = obj.value
        this.nbAccess = obj.nbAccess
        this.statisticDate = obj.statisticDate
        this.headers = ['lastAccess', 'module', 'value', 'nbAccess']
    }
}
