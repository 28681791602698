export default class DtoJobExecution {
    constructor(obj) {
        this.id = obj.id
        this.date = obj.date
        this.duration = obj.duration
        this.status = obj.status
        this.jobId = obj.jobId
        this.headers = ['id', 'date', 'duration', 'status']
    }
}
