import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { find, isEqual, orderBy } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import DashboardAction from '../actions/DashboardAction'
import ReferencialAction from '../../../action/ReferencialAction'
import ReferencialSandreCodeDto from '../../../dto/ReferencialSandreCodeDto'
import { getFullDate } from '../../../../../utils/DateUtil'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import DashboardReferencialExportDto from '../dto/DashboardReferencialExportDto'
import Counter from '../../../../../components/Counter'
import Icon from '../../../../../components/Icon'
import { SecondaryMainContainer } from '../../../../../styles/container'
import { SpaceAround, Flex } from '../../../../../styles/main'

const FlexCenter = styled(Flex)`
    align-items: center;
    justify-content: center;
`

const Name = styled.p`
    font-size: 20px;
    margin: 0;
`

const IconSmall = styled(Icon)`
    font-size: 3rem !important;
`

const Row = styled.div`
    margin: 0 0.5rem;
`

const BottomRow = styled.div`
    margin: 0 1rem;
    transform: translate(8px, 6px);
`

const Position = styled.span`
    transform: translate(4px, -13px);
    display: inline-block;
`

class ReferencialDashboardPanel extends ActionComponent {
    toReferencial = (path) => {
        this.props.push(path)
    }

    componentDidUpdate(prevProps) {
        if (
            !isEqual(prevProps.referencials, this.props.referencials)
            || !isEqual(prevProps.referencialSandreCodes, this.props.referencialSandreCodes)
        ) {
            const data = this.getExportDataReferencial(this.props)
            this.getActions(this.props, data)
        }
    }

    componentDidMount() {
        const data = this.getExportDataReferencial(this.props)
        this.getActions(this.props, data)
        this.props.fetchEveryCount()
        this.props.fetchReferencialSandreCodes()
    }

    getReferencialSandreCodes = (element) => {
        const referencialSandreCodes = find(this.props.referencialSandreCodes, (o) => o.field === element)
        return referencialSandreCodes
    }

    getDate = (fieldReferencial) => {
        const findReferencialUpdateDate = this.getReferencialSandreCodes(fieldReferencial)
        if (findReferencialUpdateDate) {
            if (findReferencialUpdateDate.updateDate) {
                return i18n.updatedOn + getFullDate(findReferencialUpdateDate.updateDate)
            }
        }
        return null
    }

    getIconUpdateSandre = (updateSandre) => {
        const findUpdateSandre = this.getReferencialSandreCodes(updateSandre)
        if (findUpdateSandre) {
            if (findUpdateSandre.updateSandre === '1') {
                return 'wifi'
            }
        }
        return null
    }

    getUserName = (userName) => {
        const findUserName = this.getReferencialSandreCodes(userName)
        if (findUserName) {
            if (findUserName.updateUser) {
                return findUserName.updateUser
            }
        }
        return i18n.user.toLowerCase()
    }

    getReferentialNumber = (referentialNumber) => {
        const findReferentialNumber = this.getReferencialSandreCodes(referentialNumber)
        if (findReferentialNumber) {
            if (findReferentialNumber.referentialNumber) {
                return `v ${findReferentialNumber.referentialNumber}`
            }
        }
        return ''
    }

    getExportDataReferencial = (props) => orderBy(props.referencials, 'index', 'asc').map(
        (referencial) => new DashboardReferencialExportDto({
            referencialName: referencial.name,
            updateDate: this.getDate(referencial.id),
            updateStatus:
                this.getIconUpdateSandre(referencial.id) === '1'
                    ? 'automatique'
                    : 'manuelle',
            updateUser: this.getUserName(referencial.id).toString(),
            recordingNumber: parseInt(referencial.number.toLocaleString()),
            referencialVersion: this.getReferentialNumber(referencial.id),
        }),
    )

    getActions = (props, data) => {
        if (props.showExportButton) {
            const actions = {
                export: () => ({
                    data,
                    exportType: 'xlsx',
                    titleFile: i18n.referencials,
                }),
            }
            this.setActions(actions)
        }
    }

    render() {
        const referencials = orderBy(this.props.referencials, 'index', 'asc').map((referencial) => (
            <Grid item md={4} sm={10}>
                <Link to={referencial.url}>
                    <Card className="cardStyle" style={{ padding: '20px 0 0', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <FlexCenter>
                            <Row>
                                <IconSmall size="medium" icon={referencial.icon} />
                            </Row>
                            <Row>
                                <Grid item>
                                    <Name className="bold">{referencial.name}</Name>
                                </Grid>
                            </Row>
                            <Row>
                                <div style={{ fontSize: '2rem' }}>
                                    <Counter number={referencial.number} />
                                </div>
                            </Row>
                        </FlexCenter>
                        <FlexCenter style={{ margin: '0.3rem 0 0.8rem 0' }}>
                            <BottomRow style={{ transform: 'translate(8px, 6px)' }}>
                                <Icon size="small" icon="account_circle" />
                                <Position className="font-size-x-small">
                                    {this.getUserName(referencial.id)}
                                </Position>
                            </BottomRow>
                            <Row>
                                <i>{this.getDate(referencial.id)}</i>
                            </Row>
                        </FlexCenter>
                        <Flex>
                            <Grid
                                item
                                xs={12}
                                className="referencialFooter"
                                style={{ padding: '5px' }}
                            >
                                <i>{`${this.getReferentialNumber(referencial.id)}`}</i>
                                <Row>
                                    <Icon size="tiny" icon="wifi" />
                                </Row>
                                <Icon
                                    size="tiny"
                                    icon={this.getIconUpdateSandre(referencial.id)}
                                />
                            </Grid>
                        </Flex>
                    </Card>
                </Link>
            </Grid>
        ))
        return (
            <SpaceAround>
                <SecondaryMainContainer>
                    <Grid container spacing={3}>
                        {referencials}
                    </Grid>
                </SecondaryMainContainer>
            </SpaceAround>
        )
    }
}

ReferencialDashboardPanel.propTypes = {
    referencials: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            url: PropTypes.string,
            name: PropTypes.string,
            date: PropTypes.string,
            number: PropTypes.number,
        }),
    ),
    value: PropTypes.bool,
    referencialSandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(ReferencialSandreCodeDto)),
    showExportButton: PropTypes.bool,
}

ReferencialDashboardPanel.defaultProps = {
    showExportButton: true,
}

const mapStateToProps = (store) => ({
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    referencials: [
        store.DashboardReducer.cultures,
        store.DashboardReducer.modes_conduites,
        store.DashboardReducer.zones,
        store.DashboardReducer.communes,
        store.DashboardReducer.indicateurs_performances,
        store.DashboardReducer.regles_rotation,
        store.DashboardReducer.rpg,
        store.DashboardReducer.matriceRef,
    ],
})

const mapDispatchToProps = {
    fetchEveryCount: DashboardAction.fetchEveryCount,
    fetchReferencialSandreCodes: ReferencialAction.fetchReferencialSandreCodes,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencialDashboardPanel)
