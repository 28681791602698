import React, { Component } from 'react'
import UUID from 'uuid'
import { hasValue } from '../../utils/NumberUtil'
import DisplayedValue from './DisplayedValue'

const { $ } = window

class NumberField extends Component {
    state = { editing: false }

    componentWillMount() {
        this.setState({ id: UUID.v4() })
    }

    onChangeValue = (event) => {
        if (this.props.onChange && (event.keyCode === 13 || event.which === 13)) {
            const val = this.props.floatValue ? parseFloat(event.target.value) : parseInt(event.target.value)
            if (hasValue(val)) {
                this.props.onChange(val)
                if (this.state.editing) {
                    this.setState({ editing: false })
                }
            } else if (this.props.dispatchOnNull) {
                this.props.onChange(null)
            } else {
                $(`#${this.state.id}`).val(this.props.value)
            }
        }
    }

    onBlur = (event) => {
        if (this.props.onChange && this.props.value != event.target.value) {
            const val = this.props.floatValue ? parseFloat(event.target.value) : parseInt(event.target.value)
            if (hasValue(val)) {
                this.props.onChange(val)
            } else if (this.props.dispatchOnNull) {
                this.props.onChange(null)
            } else {
                $(`#${this.state.id}`).val(this.props.value)
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.value !== nextProps.value) {
            $(`#${this.state.id}`).val(nextProps.value)
        }
    }

    onTableEditing = () => {
        this.setState({ editing: true }, () => {
            $(`#${this.state.id}`).focus()
            $(`#${this.state.id}`).blur(() => this.setState({ editing: false }))
        })
    }

    getInput = () => {
        if (this.props.readMode || (this.props.tableEditable && !this.state.editing)) {
            return <DisplayedValue label={this.props.title} value={this.props.value} hideNull={this.props.hideNull} onClick={this.onTableEditing} tableEditable={this.props.tableEditable} />
        }
        const disabled = { readOnly: this.props.disabled }
        const divClass = this.props.noInputFieldClass ? '' : 'input-field'
        const defaultValue = this.props.value || this.props.value === 0 ? this.props.value : ' '
        return (
            <div className={divClass}>
                <input
                    id={this.state.id}
                    type="number"
                    step={this.props.step || 1}
                    min={this.props.min}
                    max={this.props.max}
                    className={`sieau-input form-control input-sm ${this.props.className}`}
                    data-mode={this.state.id}
                    data-tooltip={this.props.tooltip}
                    defaultValue={defaultValue}
                    onKeyPress={(e) => this.onChangeValue(e)}
                    onBlur={(e) => this.onBlur(e)}
                    onClick={(v) => this.onBlur(v)}
                    {...disabled}
                />
                <label htmlFor={this.state.id}>{ this.props.title }</label>
            </div>
        )
    }

    render() {
        return this.props.col ? (
            <div className={`col s${this.props.col}`}>
                { this.getInput() }
            </div>
        ) : this.getInput()
    }
}

export default NumberField
