import PropTypes from 'prop-types'
import DtoSavedResearchCriterias from '../dto/DtoSavedResearchCriterias'
import SettingDto from '../dto/SettingDto'
import PictoDto from '../dto/PictoDto'
import CartographyThemeDto from '../dto/CartographyThemeDto'
import ThemeDto from '../dto/ThemeDto'
import LayerThemeDto from '../dto/LayerThemeDto'
import SieauParameterDto from '../dto/SieauParameterDto'
import {
    ADD_SAVED_RESEARCH_CRITERIAS,
    RECEIVE_SAVED_RESEARCH_CRITERIAS,
    RECEIVE_SETTINGS,
    RESET_ADMINISTRATION,
    RECEIVE_PICTOS,
    RECEIVE_SELECTED_SEARCH_VALUES,
    RECEIVE_CARTOGRAPHY_THEMES,
    RECEIVE_ADMIN_THEMES,
    RECEIVE_THEME,
    RECEIVE_ADMIN_THEME_LAYERS,
    RESET_THEME,
    RECEIVE_UDI_KML_EXISTANCE,
    RECEIVE_APPLICATION_SETTINGS,
} from '../constants/AdministrationConstants'
import AppStore from '../../../store/Appstore'
import { arrayOf } from '../../../utils/StoreUtils'
import AdministrationAction from '../actions/AdministrationAction'

export function AdministrationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_SAVED_RESEARCH_CRITERIAS:
            return { ...state,
                savedResearchCriterias: action.criterias.map(
                    (obj) => new DtoSavedResearchCriterias(obj),
                ) }
        case ADD_SAVED_RESEARCH_CRITERIAS:
            return { ...state,
                savedResearchCriterias: state.savedResearchCriterias.concat([
                    new DtoSavedResearchCriterias(action.criteria),
                ]) }
        case RECEIVE_SETTINGS:
            return { ...state, settings: action.settings.map((setting) => new SettingDto(setting)) }
        case RECEIVE_CARTOGRAPHY_THEMES:
            return { ...state, cartographyThemes: action.themes.map((theme) => new CartographyThemeDto(theme)) }
        case RECEIVE_ADMIN_THEMES:
            return { ...state, adminThemes: action.themes.map((theme) => new CartographyThemeDto(theme)) }
        case RECEIVE_THEME:
            return {
                ...state,
                theme: new ThemeDto(action.theme),
            }
        case RESET_THEME:
            return {
                ...state,
                theme: new ThemeDto(),
                layers: [],
            }
        case RECEIVE_ADMIN_THEME_LAYERS:
            return {
                ...state,
                layers: action.layers.map((l) => new LayerThemeDto(l)),
            }
        case RECEIVE_PICTOS:
            return { ...state, pictos: action.pictos.map((picto) => new PictoDto(picto)) }
        case RECEIVE_SELECTED_SEARCH_VALUES:
            const allValues = state.selectedSearchValues
            const searchValues = {
                
                ...allValues[action.stationType] || {},
                ...action.object,
            }
            const stationTypeObj = {}
            stationTypeObj[action.stationType] = searchValues
            return { ...state, selectedSearchValues: { ...allValues, ...stationTypeObj } }
        case RESET_ADMINISTRATION:
            return { ...state,
                settings: [],
                savedResearchCriterias: [] }
        case RECEIVE_UDI_KML_EXISTANCE:
            return { ...state, udiKMLExistance: action.existance }
        case RECEIVE_APPLICATION_SETTINGS:
            return { ...state, applicationSettings: action.settings.map((s) => new SieauParameterDto(s)) }
        default:
            return state
    }
}

export const store = {
    savedResearchCriterias: [],
    selectedSearchValues: {},
    settings: [],
    cartographyThemes: [],
    adminThemes: [],
    theme: new ThemeDto(),
    layers: [],
    pictos: [],
    applicationSettings: [],
}

export const AdministrationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        savedResearchCriterias: {
            fetch: null,
            store: appStore.AdministrationReducer.savedResearchCriterias,
            propType: arrayOf(DtoSavedResearchCriterias),
            update: AdministrationAction.addSavedResearchCriterias,
            delete: AdministrationAction.deleteSavedResearchCriterias,
        },
        cartographyThemes: {
            fetch: AdministrationAction.fetchCartographyThemes,
            store: appStore.AdministrationReducer.cartographyThemes,
            propType: arrayOf(CartographyThemeDto),
            update: AdministrationAction.updateCartographyTheme,
        },
        selectedSearchValues: {
            update: AdministrationAction.setSelectedSearchValues,
            store: appStore.AdministrationReducer.selectedSearchValues,
            propType: PropTypes.shape({
                quality: PropTypes.shape({
                    searchValue: PropTypes.string,
                    filter: PropTypes.number,
                }),
                piezometry: PropTypes.shape({
                    searchValue: PropTypes.string,
                    filter: PropTypes.number,
                }),
            }),
        },
        setting: {
            update: AdministrationAction.updateSetting,
        },
        settings: {
            fetch: AdministrationAction.fetchSettings,
            update: AdministrationAction.updateSettings,
            store: appStore.AdministrationReducer.settings,
            propType: arrayOf(SettingDto),
        },
        pictos: {
            fetch: AdministrationAction.fetchPictos,
            store: appStore.AdministrationReducer.pictos,
            propType: arrayOf(PictoDto),
        },
        administration: {
            reset: AdministrationAction.reset,
        },
        udiKMLExistance: {
            fetch: AdministrationAction.fetchUDIKMLExistance,
            store: appStore.AdministrationReducer.udiKMLExistance,
            propType: PropTypes.bool,
        },
        applicationSettings: {
            fetch: AdministrationAction.fetchApplicationSettings,
            store: appStore.AdministrationReducer.applicationSettings,
            propType: PropTypes.arrayOf(SieauParameterDto),
        },
    }
}
