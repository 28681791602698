import { css } from 'styled-components'

// ----------------------
// Theme
// ----------------------

const fontWeightNormal = 400
const fontFamilyNormal = "'Open Sans', sans-serif"
const fontFamilyFeature = "'Poppins', sans-serif"
const mainTextColor = '#2d2c2c'

export const mainBlue = '#057DCC'
export const secondaryBlue = '#004C9B'
export const mainGreen = '#29AE99'
export const secondaryGreen = '#00806B'
export const grey = '#484848'
export const mediumGrey = '#a1a1a1'
export const lightGrey = '#EAEAEA'
export const black = '#333'
export const mainPink = '#EA4C64'
export const mainPinkTwo = '#bf0832'
export const secondaryPink = '#cf2d53'
export const white = '#f9f8f6'
export const greenChart = '#70AA50'
export const redChart = '#a90000'

// ----------------------
// Media queries
// ----------------------

export default {

    // Fonts.

    fontWeightNormal,
    fontWeightLight: 300,
    fontWeightBold: 600,
    fontFamilyNormal,
    mainFont: css`
    font-family: ${fontFamilyNormal};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: ${(props) => (props.bold ? '600' : '400')};
    font-variant: none;
    color: ${mainTextColor};
  `,
    fontFamilyFeature,
    featureFont: css`
      font-family: ${fontFamilyFeature};
      font-variant: none;
      text-transform: uppercase;
    `,
    fontSizeSmallModifier: '75%',

    // Inputs.
    minWidthInput: '23rem',
    borderRadiusInput: '4px',

    // container.

    // container: {
    //     containerPadding: '9rem',
    //     containerPaddingSmall: '0rem',
    //     containerPaddingXLarge: '12rem',
    //     stepPadding: '2rem',
    //     stepPaddingSmall: '0rem',
    // },
}
