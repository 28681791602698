import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import { Flex, Right } from '../../../styles/main'
import { GreenButton } from '../../../styles/button'
import { mainPink } from '../../../styles/theme'

const MainTitle = styled.h5`
    width: 100%;
    border-bottom: 4px solid ${mainPink};
    position: relative;
    margin: 0.5rem;
    padding: 0.5rem 0;
    color: ${mainPink};
`

const Wrapper = styled.div`
    margin: 0.5rem;
    padding: 0.5rem 0;
`

const Text = styled.h5`
    font-weight: ${(props) => (props.bold ? '700' : '400')};
    line-height: 35px;
`

const PinkText = styled.span`
    font-weight: ${(props) => (props.bold ? '700' : '400')};
    line-height: 35px;
    color: ${mainPink};
`

const Margin = styled.div`
    margin: 1rem 0;
`

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

function ModalWarningPurge({ handleClose, open, purge }) {
    const classes = useStyles()
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle)

    const cancel = () => {
        handleClose()
    }

    const purgeAndClose = () => {
        purge()
        handleClose()
    }

    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                open={open}
                onClose={handleClose}
                disableBackdropClick
            >
                <div style={modalStyle} className={classes.paper}>
                    <MainTitle id="modal-title">{i18n.beware}</MainTitle>
                    <Wrapper>
                        <Text bold>
                            La purge supprimera l&apos;ensemble des élements qui ne sont pas
                            utilisés dans des territoires.
                            <br />
                            <PinkText bold>Cette action est irréversible.</PinkText>
                        </Text>
                        <Margin>
                            <Text>Confirmer la purge ?</Text>
                            <Flex
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                }}
                            >
                                <Right>
                                    <GreenButton
                                        style={{ margin: '1rem 0' }}
                                        borderRadius
                                        greenReverse
                                        onClick={cancel}
                                    >
                                        {i18n.cancel}
                                    </GreenButton>
                                    <GreenButton
                                        borderRadius
                                        style={{ margin: '0 1rem' }}
                                        onClick={purgeAndClose}
                                    >
                                        {i18n.continuer}
                                    </GreenButton>
                                </Right>
                            </Flex>
                        </Margin>
                    </Wrapper>
                </div>
            </Modal>
        </>
    )
}
ModalWarningPurge.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    purge: PropTypes.func.isRequired,
}

export default ModalWarningPurge
