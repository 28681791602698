/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react'
import { compact, uniq, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import i18n from 'simple-react-i18n'
import { AgGridReact } from '@ag-grid-community/react'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { Grid } from '@material-ui/core'
import NumericEditor from './editors/NumericEditor'
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-material.css'
import { GreenUploadLabel, GreenButton } from '../../styles/button'

const DldIcon = styled(Icon)`
    font-size: 1rem !important;
    display: inline-block;
    margin: 0 !important;
    color: #f9f8f6;
`

class AgGridTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                editable: false,
                page: null,
            },
            frameworkComponents: { numericEditor: NumericEditor },
            gridApi: null,
            savedProps: [],
            localeText: {
                to: 'à',
                of: 'sur',
                noRowsToShow: 'Pas de données',
                loadingOoo: 'Chargements des données',
                next: 'Suivant',
                previous: 'Précédent',
                last: 'Dernier',
                first: 'Premier',
                filterOoo: 'Filtrer',
                searchOoo: 'Rechercher',
                andCondition: 'ET',
                orCondition: 'OU',
            },
            sortedRowData: orderBy(props.rowData.map((r) => ({ ...r, selectedRow: props.selectedData?.find((s) => s[props.selectedDataKey] === r[props.rowDataKey]) })), ['selectedRow', 'id'], ['asc', 'asc']),
        }
        this.onGridReady = this.onGridReady.bind(this)
        this.getSelectedRows = this.getSelectedRows.bind(this)
        this.onModelUpdated = this.onModelUpdated.bind(this)
        this.getAllRows = this.getAllRows.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rowData !== this.props.rowData) {
            if (this.state.gridApi) {
                if (this.state.savedProps.length > 0) {
                    this.state.gridApi.setRowData(this.state.savedProps)
                }
                this.state.gridApi.setRowData(this.props.rowData)
            } else if (this.savedProps !== this.props.rowData) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ savedProps: this.props.rowData })
            }
        }
    }

    onModelUpdated(params) {
        const { selectedData } = this.props
        if (selectedData) {
            params.api.forEachNode((node) => {
                selectedData.forEach((row) => {
                    if (row.dataId) {
                        if (row.dataId === node.data.id) {
                            node.setSelected(true)
                        }
                    } else if (row.login) {
                        if (row.login === node.data.login) {
                            node.setSelected(true)
                        }
                    } else if (row.iid) {
                        if (row.iid === node.data.id) {
                            node.setSelected(true)
                        }
                    } else if (row.id) {
                        if (row.id === node.data.id) {
                            node.setSelected(true)
                        }
                    }
                })
            })
        }
    }

    onRemoveSelected() {
        const { gridApi } = this.state
        const selectedData = gridApi.getSelectedRows()
        gridApi.updateRowData({ remove: selectedData })
    }

    onGridReady(params) {
        const { fullHeaders, columnDefs } = this.props
        if (fullHeaders) {
            params.columnApi.autoSizeColumns((columnDefs || []).map((col) => col.field))
        } else {
            params.api.sizeColumnsToFit()
        }
        this.setState({ gridApi: params.api })
    }

    onBtnExportDataAsCsv() {
        this.state.gridApi.exportDataAsCsv(this.getParams())
    }

    onBtnExportDataAsExcel() {
        this.state.gridApi.exportDataAsExcel(this.getParams())
    }

    getSelectedRows(params) {
        const rowsSelection = params.api.getSelectedRows()
        const dataSelected = rowsSelection.map((r) => (this.props.users ? r.login : r.id))
        this.props.onChange(this.props.tableKey, dataSelected, rowsSelection)
    }

    getParams() {
        return {
            allColumns: true,
            fileName: 'CCE Export',
        }
    }

    getAllRows() {
        const rowData = []
        if (this.props.getRowData) {
            this.state.gridApi.forEachNode((node) => rowData.push(node.data))
            this.props.getRowData(rowData)
        }
    }

    goToPage = () => {
        const { page, gridApi } = this.state
        if (page && page <= gridApi.paginationGetTotalPages()) {
            gridApi.paginationGoToPage(page - 1)
        }
    }

    render() {
        const { columnDefs, rowData, rowSelection, exportable, importableXLSX, importableCSV, isEditMode,
            pagination, domLayout, noPageButton, assolements, disableImport } = this.props
        const { defaultColDef, frameworkComponents, localeText, page, sortedRowData } = this.state
        const newRowData = []
        if (assolements) {
            const assolementsDupl = [...rowData]
            const mcNames = uniq(compact(assolementsDupl.map((a) => {
                const keys = Object.keys(a)
                if (keys.length > 3) {
                    for (let i = 3; i < keys.length; i++) {
                        return keys[i]
                    }
                }
                return null
            })))
            rowData.forEach((r) => {
                let total = 0.0
                mcNames.forEach((name) => {
                    if (r[name]) {
                        total += parseFloat(r[name])
                    }
                })
                newRowData.push({
                    ...r,
                    surfaceT: total,
                })
            })
        }

        return (
            <>
                <AgGridReact
                    columnDefs={columnDefs}
                    localeText={localeText}
                    defaultColDef={defaultColDef}
                    rowData={assolements ? newRowData : sortedRowData}
                    onModelUpdated={this.onModelUpdated}
                    frameworkComponents={frameworkComponents}
                    rowSelection={rowSelection}
                    onGridReady={this.onGridReady}
                    onSelectionChanged={this.getSelectedRows}
                    onCellValueChanged={this.getAllRows}
                    suppressRowClickSelection
                    pagination={pagination}
                    paginationAutoPageSize
                    modules={AllCommunityModules}
                    rowMultiSelectWithClick
                    stopEditingWhenGridLosesFocus
                    singleClickEdit
                    enableCellTextSelection
                    domLayout={domLayout ? 'autoHeight' : ''}
                />
                <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item xs={8} container direction="row" alignItems="flex-start" justify="flex-start">
                        {exportable && (
                            <GreenButton
                                onClick={
                                    this.props.onExportFile
                                        ? this.props.onExportFile
                                        : this.onBtnExportDataAsCsv.bind(this)
                                }
                                disabled={isEditMode}
                            >
                                <DldIcon>get_app</DldIcon>&nbsp;&nbsp;
                                {this.props.onExportFile ? i18n.excelExport : i18n.CSVExport}
                            </GreenButton>
                        )}
                        {importableXLSX && (
                            <>
                                <input
                                    name="file"
                                    accept=".xls, .xlsx"
                                    id="uploadFileXLSX"
                                    type="file"
                                    className="uploadFileInput"
                                    onChange={this.props.onUploadFileXLSX}
                                    disabled={!isEditMode}
                                />
                                <GreenUploadLabel for="uploadFileXLSX" disabled={disableImport || !isEditMode}>
                                    <DldIcon>file_upload</DldIcon>&nbsp;&nbsp;
                                    {i18n.excelImport}
                                </GreenUploadLabel>
                            </>
                        )}
                        {importableCSV && (
                            <>
                                <input
                                    name="file"
                                    accept=".csv"
                                    id="uploadFileCSV"
                                    type="file"
                                    className="uploadFileInput"
                                    onChange={this.props.onUploadFileCSV}
                                    disabled={!isEditMode}
                                />
                                <GreenUploadLabel for="uploadFileCSV" disabled={disableImport || !isEditMode}>
                                    <DldIcon>file_upload</DldIcon>&nbsp;&nbsp;
                                    {i18n.csvImport}
                                </GreenUploadLabel>
                            </>
                        )}
                    </Grid>
                    {!domLayout && !noPageButton && (
                        <Grid item xs={4} container direction="row" alignItems="center" justify="flex-end">
                            <Grid item>
                                <GreenButton onClick={this.goToPage}>Aller à la page</GreenButton>
                            </Grid>
                            <Grid item xs={2}>
                                <input className="sieau-input form-control" type="number" min={1} value={page} onChange={(e) => this.setState({ page: e.target.value })} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </>
        )
    }
}

AgGridTable.defaultProps = {
    rowSelection: 'multiple',
    tableKey: '',
    exportable: true,
    importableCSV: false,
    importableXLSX: false,
    isEditMode: false,
    assolements: false,
    pagination: true,
    users: false,
    fullHeaders: false,
    noPageButton: false,
    rowDataKey: 'id',
    selectedDataKey: 'id',
}

AgGridTable.propTypes = {
    columnDefs: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowData: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowSelection: PropTypes.string,
    tableKey: PropTypes.string,
    rowDataKey: PropTypes.string,
    selectedDataKey: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    getRowData: PropTypes.func.isRequired,
    domLayout: PropTypes.bool,
    exportable: PropTypes.bool,
    importableCSV: PropTypes.bool,
    importableXLSX: PropTypes.bool,
    onUploadFileCSV: PropTypes.func,
    onUploadFileXLSX: PropTypes.func,
    onExportFile: PropTypes.func,
    isEditMode: PropTypes.bool,
    assolements: PropTypes.bool,
    pagination: PropTypes.bool,
    users: PropTypes.bool,
    noPageButton: PropTypes.bool,
    fullHeaders: PropTypes.bool,
    disableImport: PropTypes.bool,
    selectedData: PropTypes.arrayOf(PropTypes.object),
}

export default AgGridTable
