import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Grid from '@material-ui/core/Grid'
import { BlackTitle } from '../../../styles/text'
import { Flex } from '../../../styles/main'

const { $ } = window

const Background = styled.div`
    width: 100%;
    color: ${(props) => (props.blueBgd ? '#333 !important' : '#EAEAEA')};
    background-color: ${(props) => (props.blueBgd ? '#EAEAEA' : '#057DCC')};
`

class SubList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
        }
    }

    onClickAction(e, action) {
        const elem = $(e.target).parent().parent().parent()
            .parent()
            .parent()
        const parent = elem.parent()
        const content = parent.find('.collapsible-body')
        elem.addClass('active')
        parent.addClass('active')
        content.addClass('block')
        setTimeout(() => {
            content.removeClass('block')
            content.css('display', 'block')
        }, 1500)
        action.onClick()
        $('.tooltipped').tooltip('remove')
    }

    getTitle = (actions) => {
        const { smallTitle, title, collapsible } = this.props
        const { open } = this.state
        const panelTitle = <div className={smallTitle ? 'smallSubListTitle' : ''}>{title}</div>
        if (collapsible) {
            return (
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ padding: '0 2rem' }}
                >
                    <BlackTitle bold>{panelTitle}</BlackTitle>
                    {actions}
                    <Grid item>
                        <Flex>
                            <i
                                className="material-icons right medium"
                                style={{ color: !open ? '#333' : '#EAEAEA' }}
                            >
                                {!open ? 'expand_more' : 'expand_less'}
                            </i>
                        </Flex>
                    </Grid>
                </Grid>
            )
        }
        return (
            <div>
                <div className="left">{title}</div>
                <div className="right">{actions}</div>
            </div>
        )
    }

    getSuperComponent(component, actions = [], icon = '') {
        const { loadOnClick } = this.props
        const { open } = this.state
        const actionIcons = actions.map((action) => (
            <button
                type="button"
                className="right waves-effect tooltipped white-text"
                data-position="bottom"
                data-tooltip={action.label}
                onClick={(e) => this.onClickAction(e, action)}
            >
                <i className="small material-icons clickable right-align">{action.icon}</i>
            </button>
        ))
        const actionsComponent = actionIcons.length ? (
            <div className="valign-wrapper">{actionIcons}</div>
        ) : null
        const title = (() => {
            if (icon) {
                return (
                    <div className="col s12 no-padding">
                        <div className="row no-margin valign-wrapper">
                            <div className="col s1 no-padding">
                                <i className="material-icons">{icon}</i>
                            </div>
                            <div className="col s11 no-padding">
                                {this.getTitle(actionsComponent)}
                            </div>
                        </div>
                    </div>
                )
            }
            return <div className="col s12 no-padding">{this.getTitle(actionsComponent)}</div>
        })()

        return (
            <ExpansionPanel
                onChange={this.changeIcon}
                backgroundColor="#484848"
                expanded={open}
                TransitionProps={{ timeout: 200 }}
            >
                <ExpansionPanelSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: 0 }}
                >
                    <Background blueBgd={!open}>{title}</Background>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ transition: 'none' }}>
                    <Grid
                        container
                        spacing={2}
                        style={{ padding: '8px !important', height: 'auto' }}
                    >
                        {!loadOnClick ? component : null}
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    changeIcon = () => {
        const { open } = this.state
        this.setState({ open: !open })
    }

    render() {
        return null
    }
}

SubList.propTypes = {
    title: PropTypes.string.isRequired,
    collapsible: PropTypes.bool,
    smallTitle: PropTypes.bool,
    loadOnClick: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
}

SubList.defaultProps = {
    smallTitle: false,
    loadOnClick: false,
    collapsible: false,
    modalIsOpen: false,
}

export default SubList
