import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { find, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import DtoAccountHabilitation from '../../pages/account/dto/DtoAccountHabilitation'
import { FORBIDEN } from '../../pages/administration/components/user/constants/ApplicationHabilitationConstants'
import SieauParameterDto from '../../pages/administration/dto/SieauParameterDto'
import { aquasysPath } from '../../conf/SieauConstants'
import HomeAction from '../../pages/home/actions/HomeAction'
import {
    PATH_TERRITORY,
    PATH_REFERENCIAL_DASHBOARD,
    PATH_CONTENTS,
} from '../../pages/home/constants/RouteConstants'
import aquasysLogoPath from '../../assets/pictures/logos/logo_aquasys.png'
import { FR } from '../../pages/referencial/constants/ReferencialConstants'
import AppStore from '../../store/Appstore'
import { getSetting, getUser } from '../../utils/SettingUtils'
import AccountPanel from './account/AccountPanel'
import EventsAction from '../../pages/events/actions/EventsAction'
import packageJson from '../../../package.json'

class SlideNav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsMenu: [],
        }
    }

    componentDidMount() {
        this.props.fetchCMSCategories().then(() => this.setCMSMenu())
    }

    setCMSMenu = () => {
        const { cmsCategories } = this.props
        const cmsMenu = []
        cmsCategories.forEach((c) => {
            if (c.displayOnMenu) {
                const obj = {
                    menu: c.menu,
                    displayOnMenu: c.displayOnMenu,
                    icon: c.icon,
                    id: c.id,
                }
                if (!cmsMenu.find((menu) => JSON.stringify(cmsMenu[menu]) === JSON.stringify(obj))) {
                    cmsMenu.push(obj)
                }
            }
        })
        if (JSON.stringify(this.state.cmsMenu) != JSON.stringify(cmsMenu)) {
            this.setState({ cmsMenu })
        }
    }

    logout = () => {
        this.props.logout().then(() => {
            AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        })
    }

    createLinkObject = (habilitationParameter, title, link, icon) => ({
        habilitationParameter,
        title,
        link,
        icon,
    })

    createLink = (link) => (
        <ListItem button key={link.title} component={Link} to={link.link}>
            <ListItemIcon>
                <Icon>{link.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={link.title} />
        </ListItem>
    )

    findHabilitation = (parameter) => !find(this.props.accountHabilitations, (hab) => hab.habilitation === `${parameter}_${FORBIDEN}`)

    getTerritoireLink = () => {
        const objectLink = this.createLinkObject('', i18n.territories, PATH_TERRITORY, 'filter_hdr')
        return this.createLink(objectLink)
    }

    getReferencielLink = () => {
        if (getUser().isAdmin === '1') {
            const objectLink = this.createLinkObject(
                '',
                i18n.referencials,
                PATH_REFERENCIAL_DASHBOARD,
                'search',
            )
            return this.createLink(objectLink)
        }
        return null
    }

    getContent = () => {
        const { cmsMenu } = this.state
        if (cmsMenu && cmsMenu.length) {
            const filterDisplay = cmsMenu.filter((category) => category.displayOnMenu)
            const linkObjects = uniqBy(filterDisplay, 'menu').map((o) => ({
                habilitationParameter: '',
                title: o.menu,
                link: `/contents/${o.id}`,
                icon: o.icon,
                id: o.id,
            }))
            return linkObjects.map((o) => (
                <Link
                    className="slideLink"
                    to={{
                        pathname: o.link,
                        state: {
                            menu: o.title,
                            categoryId: o.id,
                            cmsMenu,
                        },
                    }}
                >
                    <ListItem button key={o.title}>
                        <ListItemIcon>
                            <Icon>{o.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={o.title} />
                    </ListItem>
                </Link>
            ))
        }
        return (
            <ListItem button component={Link} to={PATH_CONTENTS}>
                <ListItemIcon>
                    <Icon>event_note</Icon>
                </ListItemIcon>
                <ListItemText primary={i18n.communication} />
            </ListItem>
        )
    }

    getAdministrationLink = () => null
    // if (getUser().isAdmin === '1') {
    //     const objectLink = this.createLinkObject(
    //         '',
    //         i18n.administration,
    //         PATH_ADMINISTRATION_SETTINGS,
    //         'settings',
    //     )
    //     return this.createLink(objectLink)
    // }

    getCommonLinks = () => {
        const urlLang = (
            getSetting(this.props.applicationSettings, 'supportLanguage') || FR
        ).toLowerCase()
        const formationToken = getSetting(this.props.applicationSettings, 'formationToken') || 'teiCD2.RzCpYU'

        return (
            <List>
                <ListItem
                    button
                    key="about"
                    component="a"
                    target="_blank"
                    href={`https://helpcenter.coclickeau.aquasys.fr/about_${urlLang}/?tmp=${formationToken}`}
                >
                    <ListItemIcon>
                        <Icon>help</Icon>
                    </ListItemIcon>
                    <ListItemText primary={i18n.about} />
                </ListItem>
                <ListItem
                    button
                    key="confidentiality"
                    component="a"
                    target="_blank"
                    href={`https://helpcenter.coclickeau.aquasys.fr/confidential_${urlLang}/?tmp=${formationToken}`}
                >
                    <ListItemIcon>
                        <Icon>security</Icon>
                    </ListItemIcon>
                    <ListItemText primary={i18n.confidentiality} />
                </ListItem>
            </List>
        )
    }

    render() {
        const classes = makeStyles({
            list: {
                width: 250,
            },
            fullList: {
                width: 'auto',
            },
        })

        return (
            <div>
                <Drawer
                    open={this.props.openDrawer}
                    onClose={this.props.toggleDrawer(false)}
                    style={{ width: '20vw' }}
                >
                    <div
                        className={classes.list}
                        role="presentation"
                        onClick={this.props.toggleDrawer(false)}
                        onKeyDown={this.props.toggleDrawer(false)}
                    >
                        <List
                            style={{
                                padding: '20%',
                                backgroundColor: '#464E51',
                            }}
                        >
                            <AccountPanel />
                        </List>
                        <Divider />
                        <ListItem button key={i18n.homepage} component={Link} to="/">
                            <ListItemIcon>
                                <Icon>home</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.homepage} />
                        </ListItem>
                        <Divider />
                        {this.getContent()}
                        <Divider />
                        {this.getTerritoireLink()}
                        <Divider />
                        {this.getReferencielLink()}
                        <Divider />
                        {this.getAdministrationLink()}
                        <Divider />
                        {this.getCommonLinks()}
                        <Divider />
                        <ListItem button key={i18n.logout} onClick={this.logout}>
                            <ListItemIcon>
                                <Icon>power_settings_new</Icon>
                            </ListItemIcon>
                            <ListItemText primary={i18n.logout} />
                        </ListItem>
                        <Divider />
                        <List>
                            <div className="col s12 center-align">
                                <span>v{packageJson.version}</span><br />
                                <a href={aquasysPath} target="_blank" rel="noreferrer">
                                    <img
                                        src={
                                            getSetting(
                                                this.props.applicationSettings,
                                                'applicationLogoConnexion',
                                            ) || aquasysLogoPath
                                        }
                                        alt=""
                                        width="70px"
                                    />
                                </a>
                            </div>
                        </List>
                    </div>
                </Drawer>
            </div>
        )
    }
}

SlideNav.propTypes = {
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    cmsCategories: PropTypes.arrayOf(PropTypes.object),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    toggleDrawer: PropTypes.func,
    openDrawer: PropTypes.bool,
    logout: PropTypes.func,
    fetchCMSCategories: PropTypes.func,
}

SlideNav.defaultProps = {
    cmsCategories: [],
}

const mapDispatchToProps = {
    logout: HomeAction.logout,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    push,
}

const mapStateToProps = (store) => ({
    accountHabilitations: store.AccountReducer.accountHabilitations,
    cmsCategories: store.EventsReducer.cmsCategories,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps, mapDispatchToProps)(SlideNav)
