import PropTypes from 'prop-types'
import {
    LOGIN_FAIL,
    DISCONNECTED,
    TITLE,
    BACK_PATH,
    NAVBAR_LINKS,
    ACTIONS,
    RECEIVE_NOTIFICATIONS,
    READ_NOTIFICATION,
    ADD_NOTIFICATION,
    RECEIVE_THEME_LAYERS,
    RECEIVE_LAST_DOCUMENTS,
    SHOW_WELCOME_POPUP,
    UPDATE_GLOBAL_RESEARCH,
    HELP_PATH,
} from '../constants/HomeConstants'
import DtoArrest from '../dto/DtoArrest'
import LayerDto from '../dto/LayerDto'
import DtoDepartments from '../dto/DtoDepartments'
import AppStore from '../../../store/Appstore'
import HomeAction from '../actions/HomeAction'
import { arrayOf, deleteKeys } from '../../../utils/StoreUtils'
import DocumentDto from '../dto/DocumentDto'
import { faqUrl } from '../../../conf/SieauConstants'
import {
    RECEIVE_PARTENAIRES_CMS,
    RECEIVE_SLIDER_CMS,
    RECEIVE_SLIDER_STEPS_CMS,
    RECEIVE_HOME_CMS,
    RECEIVE_HOME_DOCUMENTS_CMS,
    RECEIVE_HOME_SOCIAL_CMS,
    RECEIVE_HOME_NEWSLETTER_CMS,
} from '../../events/constants/EventsConstants'
import CMSEventDto from '../../events/dto/CMSEventDto'

export function HomeReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PARTENAIRES_CMS:
            return {
                ...state,
                cmsPartenaires: action.cmsPartenaires.map((c) => new CMSEventDto(c)),
            }
        case RECEIVE_SLIDER_CMS:
            return {
                ...state,
                cmsSlider: action.cmsSlider.map((c) => new CMSEventDto(c)),
            }
        case RECEIVE_SLIDER_STEPS_CMS:
            return {
                ...state,
                cmsSliderSteps: action.cmsSliderSteps.map((c) => new CMSEventDto(c)),
            }
        case RECEIVE_HOME_CMS:
            return {
                ...state,
                cmsHome: action.cmsHome.map((c) => new CMSEventDto(c)),
            }
        case RECEIVE_HOME_DOCUMENTS_CMS:
            return {
                ...state,
                cmsHomeDocuments: action.cmsHomeDocuments.map((c) => new CMSEventDto(c)),
            }
        case RECEIVE_HOME_SOCIAL_CMS:
            return {
                ...state,
                cmsHomeSocial: action.cmsHomeSocial.map((c) => new CMSEventDto(c)),
            }
        case RECEIVE_HOME_NEWSLETTER_CMS:
            return {
                ...state,
                cmsHomeNewsLetter: action.cmsHomeNewsLetter.map((c) => new CMSEventDto(c)),
            }
        case LOGIN_FAIL:
            return {
                ...state,
                message: action.message,
            }
        case DISCONNECTED:
            return {
                ...state,
                message: action.message,
            }
        case TITLE:
            return {
                ...state,
                title: action.title,
            }
        case HELP_PATH:
            return {
                ...state,
                helpPath: action.helpPath,
            }
        case BACK_PATH:
            return {
                ...state,
                backPath: action.backPath,
            }
        case NAVBAR_LINKS:
            return {
                ...state,
                links: action.links,
            }
        case ACTIONS:
            return {
                ...state,
                actions: action.actions,
            }
        case RECEIVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
            }
        case READ_NOTIFICATION:
            return {
                ...state,
                notifications: deleteKeys(state.notifications, [action.notification]),
            }
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    ...action.notification,
                },
            }
        case RECEIVE_THEME_LAYERS:
            return {
                ...state,
                themeLayers: action.layers.map((l) => new LayerDto(l)),
            }
        case RECEIVE_LAST_DOCUMENTS:
            return {
                ...state,
                lastDocuments: action.documents.map((d) => new DocumentDto(d)),
            }
        case SHOW_WELCOME_POPUP:
            return {
                ...state,
                showWelcomePopup: action.value,
            }
        case UPDATE_GLOBAL_RESEARCH:
            return { ...state, globalResearch: action.value }
        default:
            return state
    }
}

export const store = {
    message: '',
    token: '',
    title: [],
    helpPath: faqUrl,
    backPath: '',
    links: [],
    arrests: [],
    departments: [],
    actions: [],
    notifications: {},
    themeLayers: [],
    lastDocuments: [],
    showWelcomePopup: false,
    globalResearch: '',
    cmsPartenaires: [],
    cmsSlider: [],
    cmsSliderSteps: [],
    cmsHome: [],
    cmsHomeDocuments: [],
    cmsHomeSocial: [],
    cmsHomeNewsLetter: [],
}

export const HomeStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        title: {
            fetch: HomeAction.setTitle,
            store: appStore.HomeReducer.title,
            propType: arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    href: PropTypes.string,
                }),
            ),
        },
        notifications: {
            fetch: HomeAction.fetchNotifications,
            reset: HomeAction.resetNotifications,
            store: appStore.HomeReducer.notifications,
            propType: PropTypes.objectOf(PropTypes.number),
        },
        departments: {
            fetch: HomeAction.fetchDepartments,
            store: appStore.HomeReducer.departments,
            propType: arrayOf(PropTypes.instanceOf(DtoDepartments)),
        },
        arrests: {
            fetch: HomeAction.fetchArrests,
            reset: HomeAction.resetArrests,
            store: appStore.HomeReducer.arrests,
            propType: arrayOf(PropTypes.instanceOf(DtoArrest)),
        },
        themeLayers: {
            fetch: HomeAction.fetchThemeLayers,
            store: appStore.HomeReducer.themeLayers,
            propType: arrayOf(PropTypes.arrayOf(LayerDto)),
            update: HomeAction.updateThemeLayer,
        },
        lastDocuments: {
            fetch: HomeAction.fetchLastDocuments,
            store: appStore.HomeReducer.lastDocuments,
            propType: arrayOf(PropTypes.arrayOf(DocumentDto)),
        },
    }
}
