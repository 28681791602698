export default class ReferencialSandreCodeDto {
    constructor(object) {
        this.field = object.field
        this.code = object.code
        this.referentialNumber = object.referentialNumber
        this.updateDate = object.updateDate
        this.updateSandre = object.updateSandre
        this.updateUser = object.updateUser
    }
}
