import React from 'react'
import { Timeline } from 'react-twitter-widgets'
import { shallowEqual, useSelector } from 'react-redux'
import { getSetting } from '../../../utils/SettingUtils'

const TwitterComponent = () => {
    const {
        applicationSettings,
    } = useSelector((store) => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const user = getSetting(applicationSettings, 'TwitterUser')
    
    return (
        <Timeline
            dataSource={{
                sourceType: 'profile',
                screenName: user,
            }}
            options={{
                username: user,
                height: 600,
            }}
        />
    )
}
        
export default TwitterComponent
