import DtoReferencial from '../../../dto/DtoReferencial'

export default class ContactItem extends DtoReferencial {
    constructor(object) {
        super(object)
        this.code = Number(object.id)
        this.cityCode = object.cityCode
        this.city = object.cityCode
        this.phoneTel = object.phoneTel
        this.desktopTel = object.desktopTel
        this.mobile = object.mobile
        this.socialReason = object.socialReason
        this.postalCode = object.postalBox
        this.postalBox = object.postalBox
        this.address = object.address
        this.email = object.email
        this.nullValue = object.picto
        this.headers = ['nullValue', 'name', 'postalCode', 'city', 'phoneTel', 'desktopTel', 'mobile', 'email', 'socialReason']
    }
}
