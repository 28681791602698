import {
    RECEIVE_CONTRIBUTOR,
    RECEIVE_ALL_CONTRIBUTORS,
    RECEIVE_CONTRIBUTOR_DOMAIN,
    RECEIVE_PRODUCERS,
    RESET_CONTRIBUTOR,
    RECEIVE_CONTRIBUTOR_TYPES,
} from '../constants/ContributorConstants'
import DtoContributor from '../dto/DtoContributor'
import ContributorDomain from '../dto/ContributorDomain'
import ProducerDto from '../dto/ProducerDto'
import ContributorTypeDto from '../dto/ContributorTypeDto'
import ContributorItem from '../dto/ContributorItem'

export const store = {
    contributors: [],
    producers: [],
    contributor: {},
    contributorDomain: [],
    contributorTypes: [],
}
export function ContributorReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_CONTRIBUTORS:
            return { ...state, contributors: action.contributors.map((contributor) => new ContributorItem(contributor)) }
        case RECEIVE_CONTRIBUTOR:
            return { ...state, contributor: new DtoContributor(action.contributor) }
        case RECEIVE_CONTRIBUTOR_DOMAIN:
            return { ...state, contributorDomain: action.contributorDomain.map((contributorDomain) => new ContributorDomain(contributorDomain)) }
        case RESET_CONTRIBUTOR:
            return { ...state, contributor: {} }
        case RECEIVE_PRODUCERS:
            return { ...state, producers: action.producers.map((producer) => new ProducerDto(producer)) }
        case RECEIVE_CONTRIBUTOR_TYPES:
            return { ...state, contributorTypes: action.contributorTypes.map((type) => new ContributorTypeDto(type)) }
        default:
            return state
    }
}
