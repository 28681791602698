import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import HomeAction from '../../home/actions/HomeAction'
import ActionComponent from '../../../components/actions/ActionComponent'
import { MainContainer } from '../../../styles/container'
import { SpaceAround, Relative } from '../../../styles/main'
import { TabStyle } from '../../../components/TabStyle'
import Matrice from './Matrice'
import ScenariosApp from '../scenarios/ScenariosApp'
import TerritoryAction from '../actions/TerritoryAction'
import { mainBlue } from '../../../styles/theme'

class MatriceApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            isEditMode: false,
            activeTab: props?.location?.state?.scenarios ? 'scenarios' : 'matrice',
            newMatrice:
                !!(props.location && props.location.state && props.location.state.create),
            indicateursLink: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
            {
                title: this.props.territoire.name,
                href: `territories/territory/${this.props.territoire.id}`,
            },
            {
                title: this.props.matrice.name,
                href: `territories/matrice/${this.props.matrice.mid}`,
            },
        ])
    }

    setIndicateurs = (array) => {
        this.setState({ indicateursLink: array })
    }

    toggleDataLoaded = (bool) => {
        this.setState({ dataLoaded: bool, newMatrice: false })
    }

    toggleEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    getPanel = () => {
        switch (this.state.activeTab) {
            case 'matrice':
                return (
                    <Matrice
                        newMatrice={this.state.newMatrice}
                        mid={this.props.match.params.id}
                        toggleDataLoaded={this.toggleDataLoaded}
                        toggleEditMode={this.toggleEditMode}
                        setIndicateurs={this.setIndicateurs}
                    />
                )
            case 'scenarios':
                return (
                    <Relative>
                        <ScenariosApp
                            mid={this.props.match.params.id}
                            indicateurs={this.state.indicateursLink}
                        />
                    </Relative>
                )
            default:
                return (
                    <Matrice
                        newMatrice={this.state.newMatrice}
                        mid={this.props.match.params.id}
                        toggleDataLoaded={this.toggleDataLoaded}
                        setIndicateurs={this.setIndicateurs}
                    />
                )
        }
    }

    getActive = (panel) => (panel === this.state.sortBy ? 'active' : '')

    handleChangeTab = (event, value) => {
        const { isEditMode } = this.state
        if (!isEditMode) {
            this.setState({ activeTab: value })
        }
    }

    render() {
        const { activeTab, isEditMode } = this.state
        return (
            <SpaceAround>
                <MainContainer>
                    <div style={{ width: '100%' }}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ marginBottom: '15px' }}
                        />
                        <div style={{ margin: '1rem 0 2rem' }}>
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none',
                                    },
                                }}
                                style={{
                                    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
                                }}
                                variant="fullWidth"
                                value={activeTab}
                                onChange={this.handleChangeTab}
                                aria-label="tabs"
                            >
                                <TabStyle value="matrice" label={i18n.matrice} />
                                <TabStyle
                                    value="scenarios"
                                    style={isEditMode ? { backgroundColor: 'rgb(244,244,244)' } : {}}
                                    label={i18n.scenarios}
                                />
                            </Tabs>
                            <div style={{ border: `${mainBlue} solid` }}>{this.getPanel()}</div>
                        </div>
                    </div>
                </MainContainer>
            </SpaceAround>
        )
    }
}

MatriceApp.propTypes = {
    getLink: PropTypes.func,
    globalResearch: PropTypes.string,
}

const mapStateToProps = (store) => ({
    territoires: store.TerritoryReducer.territoires,
    territoire: store.TerritoryReducer.territoire,
    matrice: store.TerritoryReducer.matrice,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchMatrice: TerritoryAction.fetchMatrice,
    fetchTerritory: TerritoryAction.fetchTerritory,
}

export default connect(mapStateToProps, mapDispatchToProps)(MatriceApp)
