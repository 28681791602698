import { postgrestPath } from './SieauConstants'
import { path } from './basepath'

export default {
    job: {
        jobPath() {
            return `${path}job/`
        },
        execute(id) {
            return `${this.jobPath() + id}/execute`
        },
    },
    user: {
        userPath() {
            return `${path}user/`
        },
        get(userId) {
            return this.userPath() + userId
        },
        delete(login) {
            return this.userPath() + login
        },
        getKeyFigures(login) {
            return `${this.userPath()}keyfigures/${login}`
        },
        getStatistics(login) {
            return `${this.userPath()}statistic/${login}`
        },
        getStationStatistics(login) {
            return `${this.userPath()}statistic/station/${login}`
        },
        getCmsStatistics(login) {
            return `${this.userPath()}statistic/cms/${login}`
        },
        getUsageStatistics(login) {
            return `${this.userPath()}statistic/usage/${login}`
        },
        getUserCGUDate(login) {
            return `${this.userPath()}statistic/cgu/${login}`
        },
        postUserCGUDate() {
            return `${path}statisticApplication/user/cgu`
        },
        userStations(login) {
            return `${this.userPath()}station/${login}`
        },
        put() {
            return this.userPath()
        },
        filters() {
            return `${this.userPath()}filters/station`
        },
        filterResults(module, filterCode) {
            return `${this.userPath()}filters/station/${module}/${filterCode}/execute`
        },
        getBookmarks() {
            return `${this.userPath()}bookmarks`
        },
        setBookmark() {
            return `${this.userPath()}bookmark`
        },
        settings() {
            return `${this.userPath()}parameters`
        },
        applicationSettings() {
            return `${this.userPath()}parameter/sieau`
        },
        saveSettings(login) {
            return `${this.userPath()}parameters/${login}`
        },
        setting() {
            return `${this.userPath()}parameter`
        },
        habilitations(login = '') {
            if (login) {
                return `${this.userPath()}habilitations/${login}`
            }
            return `${this.userPath()}habilitations`
        },
        themeAssign() {
            return `${this.userPath()}theme`
        },
        assignedThemesLayers() {
            return `${this.userPath()}theme/all`
        },
        applicationHabilitations() {
            return `${this.userPath()}applicationHabilitations`
        },
        sieauParameters(parameter) {
            return `${this.setting()}/sieau/${parameter}`
        },
        userView() {
            return `${this.userPath()}view/`
        },
        genericUserView() {
            return `${this.userPath()}view/generic/`
        },
    },
    login() {
        return `${path}login`
    },
    logout() {
        return `${path}logout`
    },
    resetPassword() {
        return `${path}reset`
    },
    resetPasswordByEmail() {
        return `${path}resetByEmail`
    },
    mailSupport() {
        return `${path}mail/support`
    },
    mailAdmin() {
        return `${path}mail/admin`
    },
    qualitometer: {
        qualitometerPath() {
            return `${path}qualitometer/`
        },
        qualitometerParameter(parameter) {
            return `${path}qualitometer?parameters=${parameter}`
        },
        getAll() {
            return this.qualitometerPath()
        },
        getAllCampaigns() {
            return `${this.qualitometerPath()}campaign`
        },
        get(id) {
            return this.qualitometerPath() + id
        },
        getWaterMass(id) {
            return `${this.qualitometerPath() + id}/watermass`
        },
        getCampains(id) {
            return `${this.qualitometerPath() + id}/campaign`
        },
        getQualitometerCampains(idQualitometer) {
            return `${this.qualitometerPath() + idQualitometer}/campaign`
        },
        getNetworks(id) {
            return `${this.qualitometerPath() + id}/network`
        },
        getContributors(id) {
            return `${this.qualitometerPath() + id}/contributor`
        },
        updateContributors(id) {
            return `${path}station/qualitometer/${id}/contributor`
        },
        getLocations(id) {
            return `${this.qualitometerPath() + id}/location`
        },
        getOperations(id) {
            return `${this.qualitometerPath() + id}/operation`
        },
        getSample(qualitometerId, sampleId) {
            return `${this.qualitometerPath() + qualitometerId}/sample/${sampleId}`
        },
        getAllEvents() {
            return `${this.qualitometerPath()}allEvents`
        },
        events(id) {
            return `${path}qualitometer/${id}/event`
        },
        event(qualitometerId, eventId) {
            return `${path}qualitometer/${qualitometerId}/event/${eventId}`
        },
        eventPostFacebook(qualitometerId, eventId) {
            return `${path}qualitometer/${qualitometerId}/event/${eventId}/facebook`
        },
        calculateParameter(idQualito, idsOperation) {
            return `${this.qualitometerPath()}calculate/${idQualito}?idOperations=${idsOperation}`
        },
        savedResearchCriterias() {
            return `${path}qualitometer/superposition`
        },
        deleteResearchCriterias(id) {
            return `${path}qualitometer/superposition/${id}`
        },
    },
    piezometer: {
        piezometerPath() {
            return `${path}piezometer/`
        },
        getAll() {
            return this.piezometerPath()
        },
        getAllCampaigns() {
            return `${this.piezometerPath()}campaigns`
        },
        getAllCurrentCampaigns() {
            return `${this.piezometerPath()}currentCampaigns`
        },
        getAllCompletedCampaigns() {
            return `${this.piezometerPath()}completedCampaigns`
        },
        getAllNetworkPiezoLink() {
            return `${this.piezometerPath()}networkLink`
        },
        getAllAccessibilities() {
            return `${this.piezometerPath()}accessibilities/`
        },
        getAllContributorLinks() {
            return `${path}station/piezometer/contributor/`
        },
        getCampaign(idCampaign) {
            return `${this.piezometerPath()}campaign/${idCampaign}`
        },
        getCampaignProgress(idCampaign) {
            return `${this.piezometerPath()}campaign/${idCampaign}/progress`
        },
        getCampaignsProgress() {
            return `${this.piezometerPath()}campaign/progress/`
        },
        deletePiezometryCampaign(idCampaign) {
            return `${path}station/campaign/${idCampaign}`
        },
        savePiezometryCampaign(idCampaign) {
            return `${path}station/campaign/${idCampaign}`
        },
        getCampaignsStations() {
            return `${path}station/campaigns/station`
        },
        getCampaignStations(idCampaign) {
            return `${path}station/campaign/${idCampaign}/station`
        },
        insertCampaignStations(idCampaign) {
            return `${path}station/campaign/${idCampaign}/station`
        },
        getPiezometerCampaigns(idPiezometer) {
            return `${this.piezometerPath() + idPiezometer}/campaigns`
        },
        get(id) {
            return this.piezometerPath() + id
        },
        piezometerMeasures(stationId) {
            return `${this.piezometerPath()}measure/${stationId}`
        },
        piezometerMeasuresMin(stationId) {
            return `${this.piezometerPath()}measure/${stationId}/min`
        },
        piezometerMeasuresBrute(stationId) {
            return `${this.piezometerPath()}measure/${stationId}/brute`
        },
        piezometerAdditionalMeasures(stationId, type) {
            return `${this.piezometerPath()}measure/${stationId}/type/${type}`
        },
        piezometerSamples(stationId) {
            return `${this.piezometerPath()}sample/${stationId}`
        },
        getStatisticsMax(stationId, year) {
            return `${this.piezometerPath()}statistic/${stationId}?type=MAX&year=${year}`
        },
        getStatisticsMin(stationId, year) {
            return `${this.piezometerPath()}statistic/${stationId}?type=MIN&year=${year}`
        },
        getStatisticsAverage(stationId, year) {
            return `${this.piezometerPath()}statistic/${stationId}?type=AVERAGE&year=${year}`
        },
        getEventsByCampaign(idCampaign) {
            return `${this.piezometerPath()}campaign/${idCampaign}/event`
        },
        getAllEvents() {
            return `${this.piezometerPath()}allEvents`
        },
        events(piezometerId) {
            return `${this.piezometerPath() + piezometerId}/event`
        },
        eventsWithDiagnostic() {
            return `${this.piezometerPath()}eventDiagnostic`
        },
        eventWithDiagnostic(eventId) {
            return `${this.piezometerPath()}eventDiagnostic/${eventId}`
        },
        event(piezometerId, eventId) {
            return `${this.piezometerPath() + piezometerId}/event/${eventId}`
        },
        eventPostFacebook(piezometerId, eventId) {
            return `${this.piezometerPath() + piezometerId}/event/${eventId}/facebook`
        },
        getSPI(stationId, SPIDate) {
            return `${this.piezometerPath()}spi/${stationId}/${SPIDate}`
        },
        getAllSPI() {
            return `${this.piezometerPath()}spi/all`
        },
        situation(type) {
            return `${this.piezometerPath()}situation/stations/${type}`
        },
        dataTypes() {
            return `${this.piezometerPath()}dataTypes`
        },
        thresholds(stationId) {
            return `${this.piezometerPath()}piezometerThreshold/${stationId}`
        },
        chartOptions(stationId) {
            return `${this.piezometerPath()}chartOptions/${stationId}`
        },
        diagnostic() {
            return `${this.piezometerPath()}diagnostic/`
        },
        diagnosticLinkMaterial() {
            return `${this.piezometerPath()}diagnostic/linkMaterial`
        },
        diagnosticLinkEventType() {
            return `${this.piezometerPath()}diagnostic/linkEventType`
        },
        solution() {
            return `${this.piezometerPath()}solution/`
        },
        solutionLinkProblem() {
            return `${this.piezometerPath()}solution/linkProblem`
        },
        diagnosticAction(piezoId, eventId) {
            return `${this.piezometerPath() + piezoId}/event/${eventId}/actions`
        },
    },
    pluviometer: {
        pluviometerPath() {
            return `${path}pluviometer/`
        },
        getAll() {
            return this.pluviometerPath()
        },
        get(id) {
            return this.pluviometerPath() + id
        },
        events(pluviometerId) {
            return `${path}pluviometer/${pluviometerId}/event`
        },
        event(pluviometerId, eventId) {
            return `${path}pluviometer/${pluviometerId}/event/${eventId}`
        },
    },
    hydrometricStation: {
        hydrometricStationPath() {
            return `${path}hydrologicalStation/`
        },
        getAll() {
            return this.hydrometricStationPath()
        },
        get(id) {
            return this.hydrometricStationPath() + id
        },
        hydrometricStationDebitMeasures(stationId) {
            return `${this.hydrometricStationPath() + stationId}/measures`
        },
        hydrometricStationFlow(stationId) {
            return `${this.hydrometricStationPath() + stationId}/measures`
        },
        getAllEvents() {
            return `${this.hydrometricStationPath()}allEvents`
        },
        events(hydroId) {
            return `${path}hydrologicalStation/${hydroId}/event`
        },
        event(hydroId, eventId) {
            return `${path}hydrologicalStation/${hydroId}/event/${eventId}`
        },
    },
    productionUnit: {
        productionUnitPath() {
            return `${path}productionUnit/`
        },
        getAll() {
            return this.productionUnitPath()
        },
        get(id) {
            return this.productionUnitPath() + id
        },
        getAssociations(id) {
            return `${this.productionUnitPath()}associated/${id}`
        },
        events(id) {
            return `${this.productionUnitPath() + id}/event`
        },
        event(id, eventId) {
            return `${this.productionUnitPath() + id}/event/${eventId}`
        },
    },
    distributionUnit: {
        distributionUnitPath() {
            return `${path}distributionUnit/`
        },
        getAll() {
            return this.distributionUnitPath()
        },
        get(id) {
            return this.distributionUnitPath() + id
        },
        getAssociations(id) {
            return `${this.distributionUnitPath()}associated/${id}`
        },
        getSector(id) {
            return `${this.distributionUnitPath()}sector/${id}`
        },
    },
    installation: {
        installationPath() {
            return `${path}installation/`
        },
        getAll() {
            return this.installationPath()
        },
        get(id) {
            return this.installationPath() + id
        },
        getAssociations(id) {
            return `${this.installationPath()}associated/${id}`
        },
        getBoreholes() {
            return `${this.installationPath()}borehole/`
        },
        getBorehole(id) {
            return `${this.installationPath()}borehole/${id}`
        },
        updateBorehole() {
            return `${this.installationPath()}borehole/`
        },
        getAllEvents() {
            return `${this.installationPath()}allEvents`
        },
        events(id) {
            return `${this.installationPath() + id}/event`
        },
        event(id, eventId) {
            return `${this.installationPath() + id}/event/${eventId}`
        },
        analysis(id) {
            return `${this.installationPath() + id}/analysis`
        },
        usages(id) {
            return `${this.get(id)}/usages`
        },
        contributorLinks(id) {
            return `${this.get(id)}/contributors`
        },
        arrangements(id) {
            return `${this.get(id)}/arrangements`
        },
        cartographyData(id) {
            return `${this.get(id)}/cartographyData`
        },
        casings(id) {
            return `${this.getBorehole(id)}/casings`
        },
        drillings(id) {
            return `${this.getBorehole(id)}/drilling`
        },
        boreholeComments(id) {
            return `${this.getBorehole(id)}/boreholeComment`
        },
        lithology(id) {
            return `${this.getBorehole(id)}/lithology`
        },
        aquifer(id) {
            return `${this.getBorehole(id)}/aquifer`
        },
        mergeInstallations(fromId, toId) {
            return `${this.installationPath()}merge/${fromId}/${toId}`
        },
        changeBssCode() {
            return `${this.installationPath()}changeBssCode/`
        },
    },
    sector: {
        sectorPath() {
            return `${path}sector/`
        },
        getAll() {
            return this.sectorPath()
        },
        get(id) {
            return this.sectorPath() + id
        },
        getQualitometers(id) {
            return `${this.sectorPath()}qualitometer/${id}`
        },
    },
    nature: {
        naturePath() {
            return `${path}nature/`
        },
        get(id) {
            return this.naturePath() + id
        },
    },
    sandre: {
        sandrePath() {
            return `${path}sandre/`
        },
        get(field, code) {
            return `${this.sandrePath() + field}/${code}`
        },
        getField(field) {
            return this.sandrePath() + field
        },
    },
    sse: {
        get(user = '') {
            const sse = user ? `sse?user=${user}` : 'sse'
            return path + sse
        },
    },
    referencial: {
        referencialPath() {
            return `${path}referencial/`
        },
        RPG() {
            return `${this.referencialPath()}RPG`
        },
        countRPG() {
            return `${this.referencialPath()}RPG/count`
        },
        matriceRef() {
            return `${this.referencialPath()}matriceRef`
        },
        countMatriceRef() {
            return `${this.referencialPath()}matriceRef/count`
        },
        culturesRPG() {
            return `${this.referencialPath()}cultureRPG`
        },
        cultureRPG(code) {
            return `${this.referencialPath()}cultureRPG/${code}`
        },
        cultures() {
            return `${this.referencialPath()}culture`
        },
        culture(code) {
            return `${this.referencialPath()}culture/${code}`
        },
        culturesExport() {
            return `${path}export/referencial/culture`
        },
        culturesTerritory(code) {
            return `${this.referencialPath()}culture/territory/${code}`
        },
        deleteCulture(code) {
            return `${this.referencialPath()}culture/${code}`
        },
        purgeCulture() {
            return `${this.referencialPath()}culture/purge`
        },
        saveCulture(code) {
            return `${this.referencialPath()}culture/${code}`
        },
        replaceCulture() {
            return `${this.referencialPath()}culture/replace`
        },
        cultureCount() {
            return `${this.referencialPath()}culture/count`
        },
        cultureCountDelete(code) {
            return `${this.referencialPath()}culture/countDelete/${code}`
        },
        zones() {
            return `${this.referencialPath()}zone`
        },
        zone(code) {
            return `${this.referencialPath()}zone/${code}`
        },
        zonesExport() {
            return `${path}export/referencial/zone`
        },
        zonesTerritory(code) {
            return `${this.referencialPath()}zone/territory/${code}`
        },
        deleteZone(code) {
            return `${this.referencialPath()}zone/${code}`
        },
        purgeZone() {
            return `${this.referencialPath()}zone/purge`
        },
        saveZone(code) {
            return `${this.referencialPath()}zone/${code}`
        },
        replaceZone() {
            return `${this.referencialPath()}zone/replace`
        },
        zoneCount() {
            return `${this.referencialPath()}zone/count`
        },
        zoneCountDelete(code) {
            return `${this.referencialPath()}zone/countDelete/${code}`
        },
        conduitesParents() {
            return `${this.referencialPath()}conduiteParents`
        },
        conduites() {
            return `${this.referencialPath()}conduite`
        },
        conduite(code) {
            return `${this.referencialPath()}conduite/${code}`
        },
        conduitesExport() {
            return `${path}export/referencial/conduite`
        },
        conduitesTerritory(code) {
            return `${this.referencialPath()}conduite/territory/${code}`
        },
        deleteConduite(code) {
            return `${this.referencialPath()}conduite/${code}`
        },
        purgeConduite() {
            return `${this.referencialPath()}conduite/purge`
        },
        saveConduite(code) {
            return `${this.referencialPath()}conduite/${code}`
        },
        replaceConduite() {
            return `${this.referencialPath()}conduite/replace`
        },
        conduiteCount() {
            return `${this.referencialPath()}conduite/count`
        },
        conduiteCountDelete(code) {
            return `${this.referencialPath()}conduite/countDelete/${code}`
        },
        rotationsExport() {
            return `${path}export/referencial/reglesRotation`
        },
        rotationsAB() {
            return `${this.referencialPath()}reglesRotationAB`
        },
        rotationAB(code) {
            return `${this.referencialPath()}reglesRotationAB/${code}`
        },
        deleteRotationAB(code) {
            return `${this.referencialPath()}reglesRotationAB/${code}`
        },
        rotationABCount() {
            return `${this.referencialPath()}reglesRotationAB/count`
        },
        rotationsEco() {
            return `${this.referencialPath()}reglesRotationEco`
        },
        rotationEco(code) {
            return `${this.referencialPath()}reglesRotationEco/${code}`
        },
        deleteRotationEco(code) {
            return `${this.referencialPath()}reglesRotationEco/${code}`
        },
        rotationEcoCount() {
            return `${this.referencialPath()}reglesRotationEco/count`
        },
        indicateurs() {
            return `${this.referencialPath()}indicateurs`
        },
        indicateur(code) {
            return `${this.referencialPath()}indicateurs/${code}`
        },
        indicateursExport() {
            return `${path}export/referencial/indicateurs`
        },
        indicateursTerritory(code) {
            return `${this.referencialPath()}indicateurs/territory/${code}`
        },
        deleteIndicateur(code) {
            return `${this.referencialPath()}indicateurs/${code}`
        },
        purgeIndicateur() {
            return `${this.referencialPath()}indicateurs/purge`
        },
        indicateursCount() {
            return `${this.referencialPath()}indicateurs/count`
        },
        checkPurgeReferencialIsRunning(referencial) {
            return `${this.referencialPath() + referencial}/purge/running`
        },
        contributorStatut() {
            return `${this.referencialPath()}contributor/statut`
        },
        stationTypes() {
            return `${this.referencialPath()}stationType`
        },
        supports() {
            return `${this.referencialPath()}support`
        },
        support(code) {
            return `${this.referencialPath()}support/${code}`
        },
        supportCount() {
            return `${this.referencialPath()}support/count`
        },
        supportCountDelete(code) {
            return `${this.referencialPath()}support/countDelete/${code}`
        },
        updateSupport() {
            return `${path}synchronise/support`
        },
        saveSupport(code) {
            return `${this.referencialPath()}support/${code}`
        },
        deleteSupport(code) {
            return `${this.referencialPath()}support/${code}`
        },
        purgeSupport() {
            return `${this.referencialPath()}support`
        },
        replaceSupport() {
            return `${this.referencialPath()}support/replace`
        },
        parameter() {
            return `${this.referencialPath()}parameter`
        },
        parameterCount() {
            return `${this.referencialPath()}parameter/count`
        },
        parameterCountDelete(code) {
            return `${this.referencialPath()}parameter/countDelete/${code}`
        },
        saveParameter(code) {
            return `${this.referencialPath()}parameter/${code}`
        },
        deleteParameter(code) {
            return `${this.referencialPath()}parameter/${code}`
        },
        purgeParameter() {
            return `${this.referencialPath()}parameter`
        },
        replaceParameter() {
            return `${this.referencialPath()}parameter/replace`
        },
        selectedParameters(selectedParameters) {
            return `${this.parameter()}?codes=${selectedParameters}`
        },
        parameterId(code) {
            return `${this.referencialPath()}parameter/${code}`
        },
        selectedUnits(selectedUnits) {
            return `${this.units()}?units=${selectedUnits}`
        },
        units() {
            return `${this.referencialPath()}unit`
        },
        unit(code) {
            return `${this.referencialPath()}unit/${code}`
        },
        unitCount() {
            return `${this.referencialPath()}unit/count`
        },
        unitCountDelete(code) {
            return `${this.referencialPath()}unit/countDelete/${code}`
        },
        saveUnit(code) {
            return `${this.referencialPath()}unit/${code}`
        },
        deleteUnit(code) {
            return `${this.referencialPath()}unit/${code}`
        },
        purgeUnit() {
            return `${this.referencialPath()}unit`
        },
        replaceUnit() {
            return `${this.referencialPath()}unit/replace`
        },
        watermasses() {
            return `${this.referencialPath()}watermass`
        },
        watermass(code) {
            return `${this.referencialPath()}watermass/${code}`
        },
        watermassStations(watermassCode) {
            return `${this.watermass(watermassCode)}/stations`
        },
        watermassCount() {
            return `${this.referencialPath()}watermass/count`
        },
        watermassCountDelete(code) {
            return `${this.referencialPath()}watermass/countDelete/${code}`
        },
        saveWatermass(code) {
            return `${this.referencialPath()}watermass/${code}`
        },
        deleteWatermass(code) {
            return `${this.referencialPath()}watermass/${code}`
        },
        purgeWatermass() {
            return `${this.referencialPath()}watermass`
        },
        replaceWatermass() {
            return `${this.referencialPath()}watermass/replace`
        },
        updateSelections() {
            return `${path}quality/selection/`
        },
        selection(type) {
            return `${path}quality/selection/${type}`
        },
        selectionParameter(code) {
            return `${path}quality/selection/${code}/parameter`
        },
        updateThresholds() {
            return `${path}quality/threshold/all`
        },
        threshold() {
            return `${path}quality/threshold`
        },
        qualityThreshold(id, thresholdType) {
            return `${path}quality/threshold/${id}/quality/${thresholdType}`
        },
        method() {
            return `${this.referencialPath()}method`
        },
        methodCount() {
            return `${this.referencialPath()}method/count`
        },
        methodCountDelete(code) {
            return `${this.referencialPath()}method/countDelete/${code}`
        },
        selectedMethod(code) {
            return `${this.referencialPath()}method/${code}`
        },
        saveMethod(code) {
            return `${this.referencialPath()}method/${code}`
        },
        deleteMethod(code) {
            return `${this.referencialPath()}method/${code}`
        },
        purgeMethod() {
            return `${this.referencialPath()}method`
        },
        replaceMethod() {
            return `${this.referencialPath()}method/replace`
        },
        contributors() {
            return `${this.referencialPath()}contributor`
        },
        contributor(code) {
            return `${this.referencialPath()}contributor/${code}`
        },
        deleteContributor(code) {
            return `${this.referencialPath()}contributor/${code}`
        },
        purgeContributor() {
            return `${this.referencialPath()}contributor`
        },
        replaceContributor() {
            return `${this.referencialPath()}contributor/replace`
        },
        contributorProducers() {
            return `${this.referencialPath()}contributor/producer`
        },
        contributorCount() {
            return `${this.referencialPath()}contributor/count`
        },
        contributorCountDelete(code) {
            return `${this.referencialPath()}contributor/countDelete/${code}`
        },
        contributorDomain() {
            return `${this.referencialPath()}contributor/domain`
        },
        contributorTypes() {
            return `${this.referencialPath()}contributor/type/qualito`
        },
        contributorsPLuvioType() {
            return `${this.referencialPath()}contributor/type/pluvio`
        },
        saveContributor(id) {
            return `${this.referencialPath()}contributor/${id}`
        },
        networks() {
            return `${this.referencialPath()}network`
        },
        network(code) {
            return `${this.referencialPath()}network/${code}`
        },
        networkCount() {
            return `${this.referencialPath()}network/count`
        },
        networkCountDelete(code) {
            return `${this.referencialPath()}network/countDelete/${code}`
        },
        saveNetwork(code) {
            return `${this.referencialPath()}network/${code}`
        },
        deleteNetwork(code) {
            return `${this.referencialPath()}network/${code}`
        },
        purgeNetwork() {
            return `${this.referencialPath()}network`
        },
        replaceNetwork() {
            return `${this.referencialPath()}network/replace`
        },
        status() {
            return `${this.referencialPath()}status`
        },
        qualification() {
            return `${this.referencialPath()}qualification`
        },
        remark() {
            return `${this.referencialPath()}remark`
        },
        fractions() {
            return `${this.referencialPath()}fraction`
        },
        fraction(code) {
            return `${this.referencialPath()}fraction/${code}`
        },
        fractionCount() {
            return `${this.referencialPath()}fraction/count`
        },
        fractionCountDelete(code) {
            return `${this.referencialPath()}fraction/countDelete/${code}`
        },
        saveFraction(code) {
            return `${this.referencialPath()}fraction/${code}`
        },
        deleteFraction(code) {
            return `${this.referencialPath()}fraction/${code}`
        },
        purgeFraction() {
            return `${this.referencialPath()}fraction`
        },
        replaceFraction() {
            return `${this.referencialPath()}fraction/replace`
        },
        contacts() {
            return `${this.referencialPath()}contact`
        },
        contact(code) {
            return `${this.referencialPath()}contact/${code}`
        },
        contactCount() {
            return `${this.referencialPath()}contact/count`
        },
        contactCountDelete(code) {
            return `${this.referencialPath()}contact/countDelete/${code}`
        },
        saveContact() {
            return `${this.referencialPath()}contact`
        },
        deleteContact(code) {
            return `${this.referencialPath()}contact/${code}`
        },
        purgeContact() {
            return `${this.referencialPath()}contact`
        },
        replaceContact() {
            return `${this.referencialPath()}contact/replace`
        },
        filterStation() {
            return `${this.referencialPath()}filterStation`
        },
        city(code) {
            return `${path}city/${code}`
        },
        cities() {
            return `${path}city`
        },
        cityCount() {
            return `${path}city/count`
        },
        cityCedex() {
            return `${path}city/cedex`
        },
        cityCountDelete(code) {
            return `${path}city/countDelete/${code}`
        },
        saveCity(code) {
            return `${path}city/${code}`
        },
        deleteCity(code) {
            return `${path}city/${code}`
        },
        purgeCity() {
            return `${path}city`
        },
        replaceCity() {
            return `${path}city/replace`
        },
        cmsEvents() {
            return `${path}cms`
        },
        cmsEventsByCategory(id) {
            return `${path}cms/${id}/category`
        },
        cmsEvent(id) {
            return `${path}cms/${id}`
        },
        cmsEventFacebook(id) {
            return `${path}cms/${id}/facebook`
        },
        cmsCategories() {
            return `${path}cms/category`
        },
        documentsStats() {
            return `${path}cms/documents/stats`
        },
        referencialSandreCodes() {
            return `${this.referencialPath()}sandre`
        },
        referencialSandreCode(field) {
            return `${this.referencialPath()}sandre${field}`
        },
        sandreCodes() {
            return `${path}sandre`
        },
    },
    territory: {
        territoryPath() {
            return `${path}territory/`
        },
        territoires() {
            return `${this.territoryPath()}territoires`
        },
        territoire(code) {
            return `${this.territoryPath()}territoires/${code}`
        },
        territoireDescriptif(code) {
            return `${this.territoryPath()}territoires/descriptif/${code}`
        },
        territoireUsers(code) {
            return `${this.territoryPath()}territoires/users/${code}`
        },
        deleteTerritoire(code) {
            return `${this.territoryPath()}territoires/${code}`
        },
        zonesSurfaces() {
            return `${this.territoryPath()}zonesSurfaces`
        },
        zoneSurface(tid) {
            return `${this.territoryPath()}zonesSurfaces/${tid}`
        },
        zonesSurfacesTerritoire(tid) {
            return `${this.territoryPath()}zonesSurfaces/territoires/${tid}`
        },
        assolements() {
            return `${this.territoryPath()}assolements`
        },
        assolement(tid) {
            return `${this.territoryPath()}assolements/${tid}`
        },
        assolementsTerritoire(tid) {
            return `${this.territoryPath()}assolements/territoires/${tid}`
        },
        averageRPG(tid) {
            return `${this.territoryPath()}rpg/averageTerritory/${tid}`
        },
        surfacesRPG(tid) {
            return `${this.territoryPath()}rpg/surfacesTerritory/${tid}`
        },
        assolementUploadExcel() {
            return `${this.territoryPath()}assolements/upload`
        },
        assolementExportExcel(tid) {
            return `${this.territoryPath()}assolements/export/${tid}`
        },
        matrices() {
            return `${this.territoryPath()}matrices`
        },
        matrice(id) {
            return `${this.territoryPath()}matrices/${id}`
        },
        matriceValues(id) {
            return `${this.territoryPath()}matrices/values/${id}`
        },
        matriceUploadExcel(id) {
            return `${this.territoryPath()}matrices/upload/${id}`
        },
        matriceExportExcel(id) {
            return `${this.territoryPath()}matrices/export/${id}`
        },
        matriceAutoFill(id) {
            return `${this.territoryPath()}matrices/autoFill/${id}`
        },
        matriceTerritoire(code) {
            return `${this.territoryPath()}matrices/territoires/${code}`
        },
        scenarios() {
            return `${this.territoryPath()}scenarios`
        },
        scenariosMultiple() {
            return `${this.territoryPath()}scenariosMultiple`
        },
        scenario(code) {
            return `${this.territoryPath()}scenarios/${code}`
        },
        scenariosMatrice(code) {
            return `${this.territoryPath()}scenarios/matrice/${code}`
        },
        scenarioStart(code) {
            return `${this.territoryPath()}scenarios/start/${code}`
        },
        scenarioExport(code) {
            return `${path}export/scenario/results/${code}`
        },
        scenariosExportComparison() {
            return `${path}export/scenarios/comparison`
        },
    },
    export: {
        export() {
            return `${path}export/`
        },
        exportData() {
            return `${this.export()}data`
        },
    },
    files: {
        file() {
            return `${path}files/`
        },
        getDocument(name) {
            return `${this.file()}document/${name}`
        },
        saveCmsDoc() {
            return `${this.file()}upload`
        },
        uploadFtp() {
            return `${this.file()}uploadFtp`
        },
    },
    layers: {
        getCityCenter(townCodes) {
            return `${postgrestPath}gns_cities?select=code,x_coordinate,y_coordinate&code=in.(${townCodes})`
        },
    },
    station: {
        getMonitoredStations() {
            return `${path}station/monitored`
        },
    },
    statisticApplication: {
        statisticApplication() {
            return `${path}statisticApplication/`
        },
    },
}
