module.exports = {
    RECEIVE_TERRITOIRES: 'RECEIVE_TERRITOIRES',
    LOADING_TERRITOIRES: 'LOADING_TERRITOIRES',
    RECEIVE_TERRITORY: 'RECEIVE_TERRITORY',
    RECEIVE_TERRITORY_ID: 'RECEIVE_TERRITORY_ID',
    RECEIVE_TERRITORY_ASSOLEMENTS: 'RECEIVE_TERRITORY_ASSOLEMENTS',
    RECEIVE_TERRITORY_ZONES_SURFACES: 'RECEIVE_TERRITORY_ZONES_SURFACES',
    RECEIVE_TERRITORY_SURFACES_RPG: 'RECEIVE_TERRITORY_SURFACES_RPG',
    RECEIVE_TERRITORY_AVERAGE_RPG: 'RECEIVE_TERRITORY_AVERAGE_RPG',
    LOADING_TERRITORY_AVERAGE_RPG: 'LOADING_TERRITORY_AVERAGE_RPG',
    RECEIVE_ALL_MATRICES: 'RECEIVE_ALL_MATRICES',
    RECEIVE_TERRITORY_MATRICES: 'RECEIVE_TERRITORY_MATRICES',
    RECEIVE_MATRICE: 'RECEIVE_MATRICE',
    RECEIVE_ALL_SCENARIOS: 'RECEIVE_ALL_SCENARIOS',
    RECEIVE_MATRICE_SCENARIOS: 'RECEIVE_MATRICE_SCENARIOS',
    RECEIVE_SCENARIO: 'RECEIVE_SCENARIO',
    RECEIVE_SCENARIOS_COMPARE: 'RECEIVE_SCENARIOS_COMPARE',
    RECEIVE_SCENARIO_RESULTS: 'RECEIVE_SCENARIO_RESULTS',
    CCE_SCENARIOS_COMPARE: 'CCE_SCENARIOS_COMPARE',
}
