/* eslint-disable import/no-cycle */
import fetch from 'isomorphic-fetch'
import download from 'downloadjs'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import ToastrAction from '../../../utils/ToastrAction'
import {
    RECEIVE_CHECK_DELETE,
    RECEIVE_CONDUITES,
    RECEIVE_CONDUITES_PARENTS,
    RECEIVE_CULTURES,
    RECEIVE_CULTURE,
    RECEIVE_ZONES,
    RESET_CHECK_DELETE,
    RECEIVE_REFERENCIAL_SANDRE_CODES,
    RECEIVE_COMMUNES,
    RECEIVE_ROTATIONS_AB,
    RECEIVE_ROTATIONS_ECO,
    RECEIVE_INDICATEURS,
    RECEIVE_INDICATEUR,
    RECEIVE_CULTURES_RPG,
    RECEIVE_RPG,
    RECEIVE_SANDRE_CODES,
    RECEIVE_MATRICE_REF,
} from '../constants/ReferencialConstants'
import ApplicationConf from '../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../utils/ActionUtils'
import AppStore from '../../../store/Appstore'
import { RESET_CONTACT } from '../components/contact/constants/ContactConstants'
import { RESET_CONTRIBUTOR } from '../components/contributor/constants/ContributorConstants'
import ModeConduiteDto from '../components/conduites/dto/ModeConduiteDto'
import CultureDto from '../components/cultures/dto/CultureDto'
import DtoRPG from '../components/cultures/dto/DtoRPG'
import DtoZone from '../components/zones/dto/DtoZone'
import IndicateurDto from '../components/indicateurs/dto/IndicateurDto'
import DtoMatriceRef from '../components/cultures/dto/DtoMatriceRef'

const ReferencialAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().ReferencialReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    fetchReferencialStatus() {
        return (dispatch) => fetch(ApplicationConf.referencial.contributorStatut(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(getJson)
            .then((json = []) => {
                dispatch(ReferencialAction.receiveReferencialStatus(json))
            })
            .catch((err) => {
                console.error(
                    `${i18n.fetchError + i18n.status} ${i18n.contributor} : ${err}`,
                )
                ToastrAction.error(`${i18n.fetchError + i18n.status} ${i18n.contributor}`)
            })
    },

    fetchReferencialSupport() {
        return (dispatch) => fetch(ApplicationConf.referencial.supports(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(getJson)
            .then((json = []) => {
                dispatch(ReferencialAction.receiveReferencialSupport(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.supports} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.supports)
            })
    },

    fetchStationTypes() {
        return (dispatch) => fetch(ApplicationConf.referencial.stationTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(getJson)
            .then((json = []) => {
                dispatch(ReferencialAction.receiveStationTypes(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.stationTypes} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.stationTypes)
            })
    },

    receiveSandreCodes(sandreCodes) {
        return { type: RECEIVE_SANDRE_CODES, sandreCodes }
    },

    promiseSandreCodes() {
        return fetch(ApplicationConf.referencial.sandreCodes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSandreCodes() {
        return (dispatch) => ReferencialAction.promiseSandreCodes()
            .then((json = []) => {
                dispatch(ReferencialAction.receiveSandreCodes(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.sandreCode} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.sandreCode)
            })
    },

    promiseReferencialSandreCodes() {
        return fetch(ApplicationConf.referencial.referencialSandreCodes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    receiveReferencialSandreCodes(methods) {
        return {
            type: RECEIVE_REFERENCIAL_SANDRE_CODES,
            referencialSandreCodes: methods,
        }
    },

    fetchReferencialSandreCodes() {
        return (dispatch) => ReferencialAction.promiseReferencialSandreCodes()
            .then((json = []) => {
                dispatch(ReferencialAction.receiveReferencialSandreCodes(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.referencialUpdate} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.referencialUpdate)
            })
    },

    fetchReferencialSandreCode(field) {
        return (dispatch) => fetch(ApplicationConf.referencial.referencialSandreCode(field), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(ReferencialAction.receiveReferencialSandreCodes(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.referencialUpdate} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.referencialUpdate)
            })
    },

    receiveCheckDelete(checkDelete) {
        return { type: RECEIVE_CHECK_DELETE, checkDelete }
    },

    fetchCheckDelete(applicationConfDelete) {
        return (dispatch) => fetch(applicationConfDelete, {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                dispatch(ReferencialAction.receiveCheckDelete(json.value))
            })
            .catch((err) => {
                console.error(`${i18n.checkDelete} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.checkDelete)
            })
    },

    fetchCheckPurgeReferencialIsRunning(referencial) {
        return () => fetch(ApplicationConf.referencial.checkPurgeReferencialIsRunning(referencial), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                console.error(`${i18n.verificationErrorPurgeInProgress} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.verificationErrorPurgeInProgress)
            })
    },

    resetReferencial() {
        return (dispatch) => {
            dispatch({ type: RESET_CONTACT })
            dispatch({ type: RESET_CONTRIBUTOR })
        }
    },

    resetCheckDelete() {
        return { type: RESET_CHECK_DELETE }
    },

    receiveCultures(cultures) {
        return { type: RECEIVE_CULTURES, cultures }
    },

    promiseCultures() {
        return fetch(ApplicationConf.referencial.cultures(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCultures() {
        return (dispatch) => ReferencialAction.promiseCultures()
            .then((json) => {
                dispatch(ReferencialAction.fetchCulturesRPG())
                dispatch(ReferencialAction.receiveCultures(json))
                return json.map((culture) => new CultureDto(culture))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cultures} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.cultures)
            })
    },

    receiveRPG(rpg) {
        return { type: RECEIVE_RPG, rpg }
    },

    promiseRPG(year) {
        return fetch(ApplicationConf.referencial.RPG(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ year }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchRPG(year = moment().year()) {
        return (dispatch) => ReferencialAction.promiseRPG(year)
            .then((json) => {
                dispatch(ReferencialAction.receiveRPG(json))
                return json.map((d) => new DtoRPG(d))
            })
            .catch((err) => {
                console.error(`${`${i18n.fetchError}RPG`} : ${err}`)
                ToastrAction.error(`${i18n.fetchError}RPG`)
            })
    },

    receiveMatriceRef(matriceRef) {
        return { type: RECEIVE_MATRICE_REF, matriceRef }
    },

    promiseMatriceRef() {
        return fetch(ApplicationConf.referencial.matriceRef(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchMatriceRef() {
        return (dispatch) => ReferencialAction.promiseMatriceRef()
            .then((json) => {
                dispatch(ReferencialAction.receiveMatriceRef(json))
                return json.map((culture) => new DtoMatriceRef(culture))
            })
            .catch((err) => {
                console.error(`${`${i18n.fetchError}${i18n.matriceRef}`} : ${err}`)
                ToastrAction.error(`${i18n.fetchError}${i18n.matriceRef}`)
            })
    },

    createMatriceRefData(matriceRefData) {
        return () => fetch(ApplicationConf.referencial.matriceRef(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(matriceRefData),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.insert) {
                    ToastrAction.success(i18n.addSuccess)
                } 
            })
            .catch((err) => {
                console.error(`${i18n.saveError} : ${err}`)
            })
    },

    deleteMatriceRefData(matriceRefDatas) {
        return () => fetch(ApplicationConf.referencial.matriceRef(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(matriceRefDatas),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.elementsDeleteSuccess)
                } 
            })
            .catch((err) => {
                console.error(`${i18n.deleteError} : ${err}`)
            })
    },

    receiveCulturesRPG(culturesRPG) {
        return { type: RECEIVE_CULTURES_RPG, culturesRPG }
    },

    promiseCulturesRPG() {
        return fetch(ApplicationConf.referencial.culturesRPG(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCulturesRPG() {
        return (dispatch) => ReferencialAction.promiseCulturesRPG()
            .then((json = []) => {
                dispatch(ReferencialAction.receiveCulturesRPG(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.culturesRPG} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.culturesRPG)
            })
    },

    exportExcelCultures() {
        return () => fetch(ApplicationConf.referencial.culturesExport(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.cultures + err)
                ToastrAction.error(i18n.fetchError + i18n.cultures)
            })
    },

    receiveCulture(culture) {
        return { type: RECEIVE_CULTURE, culture }
    },

    promiseCulture(id) {
        return fetch(ApplicationConf.referencial.culture(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCulture(id) {
        return (dispatch) => ReferencialAction.promiseCulture(id)
            .then((json) => {
                dispatch(ReferencialAction.receiveCulture(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cultures} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.cultures)
            })
    },

    updateCulture(cultureId, culture) {
        return () => fetch(ApplicationConf.referencial.culture(cultureId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(culture),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1) {
                    ToastrAction.success(i18n.culturesSuccessUpdated)
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cultures} : ${err}`)
            })
    },

    createCulture(culture) {
        return () => fetch(ApplicationConf.referencial.cultures(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(culture),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.id) {
                    ToastrAction.success(i18n.culturesSuccessUpdated)
                    return json.id
                } 
                throw new Error()
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cultures} : ${err}`)
            })
    },

    deleteCultures(culturesId) {
        return () => fetch(ApplicationConf.referencial.cultures(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(culturesId),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.cultures} : ${err}`)
            })
    },

    purgeCultures() {
        return () => fetch(ApplicationConf.referencial.purgeCulture(), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.purgeSuccess)
                }
                if (json.delete === 0) {
                    ToastrAction.success(i18n.allElementsUsed)
                }
            })
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.cultures} : ${err}`)
            })
    },

    receiveConduitesParents(conduitesParents) {
        return { type: RECEIVE_CONDUITES_PARENTS, conduitesParents }
    },

    promiseConduitesParents() {
        return fetch(ApplicationConf.referencial.conduitesParents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchConduitesParents() {
        return (dispatch) => ReferencialAction.promiseConduitesParents()
            .then((json) => {
                dispatch(ReferencialAction.receiveConduitesParents(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.conduites} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.conduites)
            })
    },

    receiveConduites(conduites) {
        return { type: RECEIVE_CONDUITES, conduites }
    },

    promiseConduites() {
        return fetch(ApplicationConf.referencial.conduites(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchConduites() {
        return (dispatch) => ReferencialAction.promiseConduites()
            .then((json) => {
                dispatch(ReferencialAction.receiveConduites(json))
                return json.map((conduite) => new ModeConduiteDto(conduite))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.conduites} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.conduites)
            })
    },

    exportExcelConduites() {
        return () => fetch(ApplicationConf.referencial.conduitesExport(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.conduites + err)
                ToastrAction.error(i18n.fetchError + i18n.conduites)
            })
    },

    updateConduite(conduiteId, conduite) {
        return () => fetch(ApplicationConf.referencial.conduite(conduiteId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(conduite),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1) {
                    ToastrAction.success(i18n.conduitesSuccessUpdated)
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.conduites} : ${err}`)
            })
    },

    createConduite(conduite) {
        return () => fetch(ApplicationConf.referencial.conduites(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(conduite),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.id || json.insert) {
                    ToastrAction.success(i18n.conduitesSuccessUpdated)
                    return json.id || json.insert
                } 
                throw new Error()
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.conduites} : ${err}`)
            })
    },

    deleteConduites(conduitesId) {
        return () => fetch(ApplicationConf.referencial.conduites(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(conduitesId),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.elementDeleteSuccess)
                }
            })
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.conduites} : ${err}`)
            })
    },

    purgeConduites() {
        return () => fetch(ApplicationConf.referencial.purgeConduite(), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.purgeSuccess)
                }
                if (json.delete === 0) {
                    ToastrAction.success(i18n.allElementsUsed)
                }
            })
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.conduites} : ${err}`)
            })
    },

    promiseConduite(id) {
        return fetch(ApplicationConf.referencial.conduite(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAllConduites: (ids) => () => Promise.all(ids.map((id) => ReferencialAction.promiseConduite(id))),

    receiveZones(zones) {
        return { type: RECEIVE_ZONES, zones }
    },

    promiseZones() {
        return fetch(ApplicationConf.referencial.zones(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchZones() {
        return (dispatch) => ReferencialAction.promiseZones()
            .then((json) => {
                dispatch(ReferencialAction.receiveZones(json))
                return json.map((zone) => new DtoZone(zone))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.zones} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.zones)
            })
    },

    exportExcelZones() {
        return () => fetch(ApplicationConf.referencial.zonesExport(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.zones + err)
                ToastrAction.error(i18n.fetchError + i18n.zones)
            })
    },

    updateZone(zoneId, zone) {
        return () => fetch(ApplicationConf.referencial.zone(zoneId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(zone),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1) {
                    ToastrAction.success(i18n.zonesSuccessUpdated)
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.zones} : ${err}`)
            })
    },

    createZone(zone) {
        return () => fetch(ApplicationConf.referencial.zones(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(zone),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.id) {
                    ToastrAction.success(i18n.zonesSuccessUpdated)
                    return json.id
                } 
                throw new Error()
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.zones} : ${err}`)
            })
    },

    deleteZones(zonesId) {
        return () => fetch(ApplicationConf.referencial.zones(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(zonesId),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.zones} : ${err}`)
            })
    },

    purgeZones() {
        return () => fetch(ApplicationConf.referencial.purgeZone(), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.purgeSuccess)
                }
                if (json.delete === 0) {
                    ToastrAction.success(i18n.allElementsUsed)
                }
            })
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.zones} : ${err}`)
            })
    },

    promiseZonesTerritoire(territoryId) {
        return fetch(ApplicationConf.referencial.zonesTerritory(territoryId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    promiseCulturesTerritoire(territoryId) {
        return fetch(ApplicationConf.referencial.culturesTerritory(territoryId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    promiseModesConduitesTerritoires(territoryId) {
        return fetch(ApplicationConf.referencial.conduitesTerritory(territoryId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCulturesTerritoire(territoryId) {
        return (dispatch) => ReferencialAction.promiseCulturesTerritoire(territoryId)
            .then((json) => {
                dispatch(ReferencialAction.receiveCultures(json))
                return json.map((culture) => new CultureDto(culture))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cultures} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.cultures)
            })
    },

    fetchZonesTerritoire(territoryId) {
        return (dispatch) => ReferencialAction.promiseZonesTerritoire(territoryId)
            .then((json) => {
                dispatch(ReferencialAction.receiveZones(json))
                return json.map((zone) => new DtoZone(zone))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.zones} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.zones)
            })
    },

    fetchModesConduitesTerritoire(territoryId) {
        return (dispatch) => ReferencialAction.promiseModesConduitesTerritoires(territoryId)
            .then((json) => {
                dispatch(ReferencialAction.receiveConduites(json))
                return json.map((conduite) => new ModeConduiteDto(conduite))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.conduites} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.conduites)
            })
    },

    fetchElementaryBricks(territoryId) {
        return (dispatch) => Promise.all([
            ReferencialAction.promiseZonesTerritoire(territoryId),
            ReferencialAction.promiseCulturesTerritoire(territoryId),
            ReferencialAction.promiseModesConduitesTerritoires(territoryId),
            ReferencialAction.promiseCommunes(),
        ])
            .then((json) => {
                dispatch(ReferencialAction.receiveZones(json[0]))
                dispatch(ReferencialAction.receiveCultures(json[1]))
                dispatch(ReferencialAction.receiveConduites(json[2]))
                dispatch(ReferencialAction.receiveCommunes(json[3]))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.referencials} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.referencials)
            })
    },

    receiveCommunes(communes) {
        return { type: RECEIVE_COMMUNES, communes }
    },

    promiseCommunes() {
        return fetch(ApplicationConf.referencial.cities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCommunes() {
        return (dispatch) => ReferencialAction.promiseCommunes()
            .then((json) => {
                dispatch(ReferencialAction.receiveCommunes(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cities} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.zones)
            })
    },

    exportExcelReglesRotations() {
        return () => fetch(ApplicationConf.referencial.rotationsExport(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.reglesRotation + err)
                ToastrAction.error(i18n.fetchError + i18n.reglesRotation)
            })
    },

    receiveRotationsAB(rotationsAB) {
        return { type: RECEIVE_ROTATIONS_AB, rotationsAB }
    },

    promiseRotationAB() {
        return fetch(ApplicationConf.referencial.rotationsAB(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchRotationAB() {
        return (dispatch) => ReferencialAction.promiseRotationAB()
            .then((json) => {
                dispatch(ReferencialAction.receiveRotationsAB(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.reglesRotation} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.reglesRotation)
            })
    },

    receiveRotationsEco(rotationsEco) {
        return { type: RECEIVE_ROTATIONS_ECO, rotationsEco }
    },

    promiseRotationEco() {
        return fetch(ApplicationConf.referencial.rotationsEco(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchRotationEco() {
        return (dispatch) => ReferencialAction.promiseRotationEco()
            .then((json) => {
                dispatch(ReferencialAction.receiveRotationsEco(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.reglesRotation} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.reglesRotation)
            })
    },

    receiveIndicateurs(indicateurs) {
        return { type: RECEIVE_INDICATEURS, indicateurs }
    },

    promiseIndicateurs() {
        return fetch(ApplicationConf.referencial.indicateurs(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchIndicateurs() {
        return (dispatch) => ReferencialAction.promiseIndicateurs()
            .then((json) => {
                dispatch(ReferencialAction.receiveIndicateurs(json))
                return json.map((indicateur) => new IndicateurDto(indicateur))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.indicateursPerf} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.indicateursPerf)
            })
    },

    fetchAllBricks: () => (dispatch) => Promise.all([
        ReferencialAction.promiseConduites(),
        ReferencialAction.promiseZones(),
        ReferencialAction.promiseCultures(),
        ReferencialAction.promiseCulturesRPG(),
        ReferencialAction.promiseIndicateurs(),
        ReferencialAction.promiseMatriceRef(),
    ]).then((jsonTab) => {
        dispatch(ReferencialAction.receiveConduites(jsonTab[0]))
        dispatch(ReferencialAction.receiveZones(jsonTab[1]))
        dispatch(ReferencialAction.receiveCultures(jsonTab[2]))
        dispatch(ReferencialAction.receiveCulturesRPG(jsonTab[3]))
        dispatch(ReferencialAction.receiveIndicateurs(jsonTab[4]))
        dispatch(ReferencialAction.receiveMatriceRef(jsonTab[5]))
    }),

    exportExcelIndicateurs() {
        return () => fetch(ApplicationConf.referencial.indicateursExport(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    download(atob(json.content), json.fileName)
                }
            })
            .catch((err) => {
                console.error(i18n.fetchError + i18n.indicateursPerf + err)
                ToastrAction.error(i18n.fetchError + i18n.indicateursPerf)
            })
    },

    promiseIndicateursMatrice(matriceId) {
        return fetch(ApplicationConf.referencial.indicateursTerritory(matriceId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchIndicateursMatrices(matriceId) {
        return (dispatch) => ReferencialAction.promiseIndicateursMatrice(matriceId)
            .then((json) => {
                dispatch(ReferencialAction.receiveIndicateurs(json))
                return json.map((indicateur) => new IndicateurDto(indicateur))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.indicateursPerf} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.indicateursPerf)
            })
    },

    receiveIndicateur(indicateur) {
        return { type: RECEIVE_INDICATEUR, indicateur }
    },

    promiseIndicateur(id) {
        return fetch(ApplicationConf.referencial.indicateur(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAllIndicateurs: (ids) => () => Promise.all(ids.map((id) => ReferencialAction.promiseIndicateur(id))),

    fetchIndicateur(id) {
        return (dispatch) => ReferencialAction.promiseIndicateur(id)
            .then((json) => {
                dispatch(ReferencialAction.receiveIndicateur(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.indicateursPerf} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.indicateursPerf)
            })
    },

    updateIndicateur(indicateurId, indicateur) {
        return () => fetch(ApplicationConf.referencial.indicateur(indicateurId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(indicateur),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1) {
                    ToastrAction.success(i18n.indicateursSuccessUpdated)
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.indicateursPerf} : ${err}`)
            })
    },

    createIndicateur(indicateur) {
        return () => fetch(ApplicationConf.referencial.indicateurs(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(indicateur),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.id) {
                    ToastrAction.success(i18n.indicateursSuccessUpdated)
                    return json.id
                } 
                throw new Error()
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.indicateursPerf} : ${err}`)
            })
    },

    deleteIndicateurs(indicateursId) {
        return () => fetch(ApplicationConf.referencial.indicateurs(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(indicateursId),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.indicateursPerf} : ${err}`)
            })
    },

    purgeIndicateurs() {
        return () => fetch(ApplicationConf.referencial.purgeIndicateur(), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete) {
                    ToastrAction.success(i18n.purgeSuccess)
                }
                if (json.delete === 0) {
                    ToastrAction.success(i18n.allElementsUsed)
                }
            })
            .catch((err) => {
                console.error(`${i18n.deleteError + i18n.indicateursPerf} : ${err}`)
            })
    },

    updateRotationAB(reglesRotation) {
        return () => fetch(ApplicationConf.referencial.rotationAB(reglesRotation.id), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(reglesRotation),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update) {
                    ToastrAction.success(i18n.rotationUpdateSuccess)
                } else {
                    ToastrAction.error(i18n.updateError + i18n.regleRotation)
                }
            })
            .catch((err) => {
                console.error(`${i18n.updateError + i18n.regleRotation} : ${err}`)
            })
    },

    updateRotationEco(reglesRotation) {
        return () => fetch(ApplicationConf.referencial.rotationEco(reglesRotation.id), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(reglesRotation),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update) {
                    ToastrAction.success(i18n.rotationUpdateSuccess)
                } else {
                    ToastrAction.error(i18n.updateError + i18n.regleRotation)
                }
            })
            .catch((err) => {
                console.error(`${i18n.updateError + i18n.regleRotation} : ${err}`)
            })
    },
}

export default ReferencialAction
