module.exports = {
    RECEIVE_USERS: 'RECEIVE_USERS',
    RECEIVE_USERS_THEMES_ASSIGNMENT: 'RECEIVE_USERS_THEMES_ASSIGNMENT',
    RECEIVE_USER_THEMES_ASSIGNMENT: 'RECEIVE_USER_THEMES_ASSIGNMENT',
    RECEIVE_USER: 'RECEIVE_USER',
    RECEIVE_USER_STATISTIC: 'RECEIVE_USER_STATISTIC',
    RECEIVE_USER_KEYFIGURES: 'RECEIVE_USER_KEYFIGURES',
    RECEIVE_USER_STATION_STATISTICS: 'RECEIVE_USER_STATION_STATISTICS',
    RECEIVE_USER_USAGE_STATISTICS: 'RECEIVE_USER_USAGE_STATISTICS',
    RECEIVE_USER_CMS_STATISTICS: 'RECEIVE_USER_CMS_STATISTICS',
    RECEIVE_QUALITOMETER_BOOKMARKS: 'RECEIVE_QUALITOMETER_BOOKMARKS',
    RECEIVE_USER_KEYFIGURES_ALL: 'RECEIVE_USER_KEYFIGURES_ALL',
    RECEIVE_USER_LOGIN_HABILITATIONS: 'RECEIVE_USER_LOGIN_HABILITATIONS',
    RECEIVE_USER_HABILITATIONS: 'RECEIVE_USER_HABILITATIONS',
    RECEIVE_USER_STATIONS: 'RECEIVE_USER_STATIONS',
    RESET_USER_ATTEMPTS: 'RESET_USER_ATTEMPTS',
    CCE_TOKEN: 'CCE_TOKEN',
    RESET_QUALITOMETER_BOOKMARKS: 'RESET_QUALITOMETER_BOOKMARKS',
    RESET_USER: 'RESET_USER',
    RECEIVE_PIEZOMETER_BOOKMARKS: 'RECEIVE_PIEZOMETER_BOOKMARKS',
    RECEIVE_PLUVIOMETER_BOOKMARKS: 'RECEIVE_PLUVIOMETER_BOOKMARKS',
    RECEIVE_HYDROMETRIC_STATIONS_BOOKMARKS: 'RECEIVE_HYDROMETRIC_STATIONS_BOOKMARKS',
    RECEIVE_PRODUCTION_UNIT_BOOKMARKS: 'RECEIVE_PRODUCTION_UNIT_BOOKMARKS',
    RECEIVE_ALL_BOOKMARKS: 'RECEIVE_ALL_BOOKMARKS',
    PANEL_FILE: 'PANEL_FILE',
    PANEL_MODULES: 'PANEL_MODULES',
    PANEL_ACCESS: 'PANEL_ACCESS',
    PANEL_STATISTICS: 'PANEL_STATISTICS',
    PANEL_MAP: 'PANEL_MAP',
    PANEL_HOME: 'PANEL_HOME',
    PANEL_OPTION: 'PANEL_OPTION',
    PANEL_USERINFO: 'PANEL_USERINFO',
    KEY_ADMIN: 'isAdmin',
    KEY_ANIMATOR: 'labo',
    KEY_LABO: 'labo',
    KEY_METADATA: 'metadata',
    KEY_CONSULTANT: 'consultant',
    KEY_CONTACT_CODE: 'contactCode',
    RECEIVE_USER_VIEW: 'RECEIVE_USER_VIEW',
    RECEIVE_GENERIC_USER_VIEW: 'RECEIVE_GENERIC_USER_VIEW',
    USER_FORBIDDEN_SECTIONS: ['QUALITY_FORBIDDEN', 'PIEZOMETRY_FORBIDDEN', 'PLUVIOMETRY_FORBIDDEN', 'HYDROMETRY_FORBIDDEN'],
    BASIC_HOMEPAGE_VIEWS: [
        {
            panel: 'quality',
            visible: true,
        },
        {
            panel: 'piezometry',
            visible: true,
        },
        {
            panel: 'hydrometry',
            visible: true,
        },
        {
            panel: 'pluviometry',
            visible: true,
        },
        {
            panel: 'map',
            visible: true,
        },
        {
            panel: 'keyFigures',
            visible: true,
        },
        {
            panel: 'arrests',
            visible: true,
        },
        {
            panel: 'tweets',
            visible: true,
        },
        {
            panel: 'productionUnit',
            visible: true,
        },
    ],
}
