import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import ProgressBar from '../../../../../components/ProgressBar'
import { MainContainer, RefTable, Position } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import UserViewDto from '../../../../administration/components/user/dto/UserViewDto'
import UpdatePanel from '../../../../../components/UpdatePanel'
import DtoUser from '../../../../administration/components/user/dto/DtoUser'
import AccountAction from '../../../../account/actions/AccountAction'
import { getSandreLabel } from '../../../../../utils/StringUtil'
import { getUser } from '../../../../../utils/SettingUtils'
import JobAction from '../../../../../utils/import/actions/JobAction'
import ToastrAction from '../../../../../utils/ToastrAction'
import ModalAddMatriceRefData from '../../ModalAddMatriceRefData'

class MatriceRefApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            dataSelected: [],
            rowData: [],
            editable: false,
            openModal: false,
            newData: null,
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.matriceRef,
                href: 'referencial/matriceRef',
            },
        ])
        this.props.fetchAllBricks().then(() => {
            this.setData(this.props.matriceRef)
        })
        this.setReadOnlyMode()
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
    }

    setEditable(editable) {
        this.setState({
            columnDefs: [
                {
                    headerName: i18n.culture,
                    field: 'culture',
                    headerCheckboxSelection: editable,
                    headerCheckboxSelectionFilteredOnly: editable,
                    checkboxSelection: editable,
                },
                {
                    headerName: i18n.department,
                    field: 'department',
                },
                {
                    headerName: i18n.typeSuccession,
                    field: 'typeSuccessionLabel',
                },
                {
                    headerName: i18n.conduite,
                    field: 'driveMode',
                },
                {
                    headerName: i18n.indicateurPerf,
                    field: 'indicator',
                },
                {
                    headerName: i18n.valueLabel,
                    field: 'value',
                },
            ],
            editable,
        })
    }

    setReadOnlyMode = () => {
        this.setEditable(false)
        if (getUser().isAdmin === '1') {
            this.setActions({
                edit: () => this.setEditMode(),
            })
        } else {
            this.setActions({})
        }
    }

    setEditMode = () => {
        this.setEditable(true)
        const actions = {
            cancel: () => {
                this.setState({ dataLoaded: false, dataSelected: [] })
                this.props
                    .fetchMatriceRef()
                    .then(() => this.setData(this.props.matriceRef))
                    .then(() => this.setReadOnlyMode())
            },
        }
        if (getUser().isAdmin === '1') {
            actions.new = () => this.setState({ openModal: true, newData: {} })
            actions.deleteCheck = () => {
                this.props.deleteMatriceRefData(this.state.dataSelected).then(() => {
                    this.setState({
                        dataLoaded: false,
                        dataSelected: [],
                    })
                    this.props
                        .fetchMatriceRef()
                        .then(() => this.setReadOnlyMode())
                        .then(() => this.setData(this.props.matriceRef))
                })
            }
        }
        this.setActions(actions)
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = this.props.matriceRef.filter((c) => c.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    setData(matriceRef) {
        const { cultures, conduites, indicateurs } = this.props
        const arr = matriceRef.map((d) => ({
            ...d,
            culture: (cultures.find((c) => c.id === d.cultureId) || {}).name,
            typeSuccessionLabel: getSandreLabel(this.props.sandreCodes, 'AGRI_RPG.TYPE_SUCCESSION', d.typeSuccession),
            driveMode: (conduites.find((c) => c.id === d.driveModeId) || {}).name,
            indicator: (indicateurs.find((c) => c.id === d.indicatorId) || {}).name,

        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    onSelectLine = (key, data, lines) => {
        this.setState({ dataSelected: lines })
    }

    onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.props.uploadFile({
                    stationType: 0,
                    stationId: 0,
                    path: 'DATA/',
                    name: 'importDataMatriceRef.csv',
                    content: e.target.result,
                }).then(() => JobAction.promiseExecuteJob(-16)).then(() => ToastrAction.info(`${i18n.jobProgressMessage}\n${i18n.jobProgressDescription}`))
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    getModalAdd = () => (
        <ModalAddMatriceRefData
            open
            onValidate={(data) => this.props.createMatriceRefData(data).then(() => this.props.fetchMatriceRef().then(() => this.setData(this.props.matriceRef)))}
            handleClose={() => this.setState({ openModal: false })}
        />
    )

    render() {
        const {
            dataLoaded,
            columnDefs,
            rowData,
            editable,
            openModal,
        } = this.state
        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <RefTable className="ag-theme-balham">
                            <div style={{ width: '100%', margin: '3rem' }}>
                                <Position>{this.getUpdatePanel()}</Position>
                            </div>
                            <AgGridTable
                                columnDefs={columnDefs}
                                rowData={rowData}
                                onChange={this.onSelectLine}
                                importableCSV
                                onUploadFileCSV={this.onUploadFile}
                                isEditMode={editable}
                            />
                        </RefTable>
                    ) : (
                        <ProgressBar />
                    )}
                    {openModal && this.getModalAdd()}
                </MainContainer>
            </SpaceAround>
        )
    }
}

MatriceRefApp.propTypes = {
    getLink: PropTypes.func,
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    accountUser: PropTypes.instanceOf(DtoUser),
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    matriceRef: store.ReferencialReducer.matriceRef,
    cultures: store.ReferencialReducer.cultures,
    conduites: store.ReferencialReducer.conduites,
    indicateurs: store.ReferencialReducer.indicateurs,
    accountUser: store.AccountReducer.accountUser,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchMatriceRef: ReferencialAction.fetchMatriceRef,
    fetchAllBricks: ReferencialAction.fetchAllBricks,
    createMatriceRefData: ReferencialAction.createMatriceRefData,
    deleteMatriceRefData: ReferencialAction.deleteMatriceRefData,
    fetchUser: AccountAction.fetchUser,
    uploadFile: JobAction.uploadFile,
    promiseExecuteJob: JobAction.promiseExecuteJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(MatriceRefApp)
