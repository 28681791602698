module.exports = {
    maxGoogleFetch: 20,
    applicationName: 'Co-Click-Eau',
    sandreParameterLink:
        'http://www.sandre.eaufrance.fr/urn.php?urn=urn%3Asandre%3Adonnees%3APAR%3AFRA%3Acode%3A${ parameter }%3A%3A%3Areferentiel%3A2%3Ahtml',
    sandreParameterDefaultLink: 'http://www.sandre.eaufrance.fr/jeu-de-donnees/param%C3%A8tres',
    sandreHydrogeologicalEntityLink: 'http://www.sandre.eaufrance.fr/geo/EntiteHydrogeol/',
    sandreHydrogeologicalEntityDefaultLink: 'https://bdlisa.eaufrance.fr/carte',
    sandreWatershedDefaultLink: 'http://www.sandre.eaufrance.fr/jeu-de-donnees/bassin-versant-rnde',
    sandreWatershedLevel1Link:
        'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:RegionHydro:FRA:code:',
    sandreWatershedLevel2Link:
        'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:SecteurHydro:FRA:code:',
    sandreWatershedLevel3Link: 'http://www.sandre.eaufrance.fr/geo/SousSecteurHydro/',
    sandreWatershedLevel4Link: 'http://www.sandre.eaufrance.fr/geo/ZoneHydro/',
    sandreFractionDefaultLink:
        'http://www.sandre.eaufrance.fr/jeu-de-donnees/fraction-analys%C3%A9e',
    sandreFractionLink: 'http://id.eaufrance.fr/fan/',
    sandreNetworkDefaultLink:
        'http://www.sandre.eaufrance.fr/atlas/srv/fre/catalog.search#/metadata/cf1a18df-bc82-4b7f-b6f2-a39237dc6b67',
    sandreNetworkLink:
        'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:DC:FRA:code:${ network }:::referentiel:3.1:html',
    sandreWatermassDefaultLink: 'http://www.sandre.eaufrance.fr/notice-doc/masses-deau',
    sandreWatermassLink: 'http://www.sandre.eaufrance.fr/geo/MasseDEauSouterraine/',
    sandreUnitDefaultLink: 'http://id.eaufrance.fr/urf/',
    sandreSupportDefaultLink: 'http://id.eaufrance.fr/sup/',
    sandreContributorDefaultLink:
        'http://www.sandre.eaufrance.fr/Rechercher-une-donnee-d-un-jeu?keyword=&ss_item_code=&ss_lbref_sandre=Intervenants',
    sandreContributorLink: 'http://id.eaufrance.fr/int/',
    sandreMethodDefaultLink:
        'http://www.sandre.eaufrance.fr/atlas/srv/fre/catalog.search#/metadata/9bac10a7-f0cd-4560-b52f-f755a9b28fd5',
    sandreMethodLink: 'http://id.eaufrance.fr/met/',
    inerisParameterLink: 'http://www.ineris.fr/substances/fr/substance/cas/',
    inerisParameterDefaultLink: 'http://www.ineris.fr/substances/fr/search/index',
    echaParameterLink: 'https://echa.europa.eu/fr/',
    echaParameterDefaultLink: 'https://echa.europa.eu/fr/',
    eauFranceLink: 'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:STQ:FRA:code:',
    adesLink: 'http://www.ades.eaufrance.fr/FichePtEau.aspx?code=',
    infoterreLink: 'http://ficheinfoterre.brgm.fr/InfoterreFiche/ficheBss.action?id=',
    adesWatermassLink: 'http://www.ades.eaufrance.fr/fmasseseau/2009/FR',
    adesHydrogeologicalEntityLink: 'http://www.ades.eaufrance.fr/fhydro/',
    inseeCityLink: 'https://www.insee.fr/fr/statistiques/1405599?geo=COM-',
    inseeCityDefaultLink: 'https://www.insee.fr/fr/information/2028028',
    georisquesCityLink:
        'http://www.georisques.gouv.fr/connaitre_les_risques_pres_de_chez_soi/ma_commune_face_aux_risques/rapport?codeInsee=',
    georisquesCityDefaultLink: 'http://www.georisques.gouv.fr/',
    gesteauCityLink: 'http://www.gesteau.fr/',
    gesteauCityDefaultLink: 'http://www.gesteau.fr/',
    eaufranceCityLink: 'http://www.services.eaufrance.fr/donnees/commune/',
    carmencartoWatermassDefaultLink: 'http://carmen.carmencarto.fr/74/at_entree.map#',
    atlasanteLink: 'https://www.atlasante.fr/infofactures/R52/',
    societeDefaultUrl: 'https://www.societe.com',
    societeUrl: 'https://www.societe.com/cgi-bin/search?champs=',
    supportUrl: 'https://helpcenter.coclickeau.aquasys.fr/',
    formationUrl: 'https://helpcenter.coclickeau.aquasys.fr/centre-de-formation/',
    faqUrl: 'https://helpcenter.coclickeau.aquasys.fr/f-a-q/',
    sandrePath: 'https://services.sandre.eaufrance.fr/',
    aquaDBPath: 'https://aquadb.fr/',
    postgrestPath: 'https://aquadb.fr/postgrest/',
    aquasysLogoPath: 'https://www.aquasys.fr/wp-content/uploads/2017/12/aqua-logo-1.jpg',
    aquasysCGUPath: 'https://helpcenter.coclickeau.aquasys.fr/wp-json/wp/v2/pages/',
    aquasysPath: 'https://www.aquasys.fr/',
}
