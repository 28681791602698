/* eslint-disable no-restricted-globals */
const round = (number = 0, precision = 2) => {
    const factor = Math.pow(10, precision)
    return Math.round(number * factor) / factor
}

const nFormatter = (numS) => {
    const num = parseInt(numS)
    if (num >= 1000000000) {
        return (
            `${round(num / 1000000000)
                .toString()
                .replace(/\.0$/, '')}G`
        )
    }
    if (num >= 1000000) {
        return (
            `${round(num / 1000000)
                .toString()
                .replace(/\.0$/, '')}M`
        )
    }
    if (num >= 1000) {
        return (
            `${round(num / 1000)
                .toString()
                .replace(/\.0$/, '')}K`
        )
    }
    return num
}

const formatFloat = (value, decimals = 2) => (isNaN(value) || !isFinite(value) ? 0 : parseFloat(value.toFixed(decimals)))

const fixedNumber = (number, fixed = 2) => {
    if (number) {
        return Number(number).toFixed(fixed)
    }
    return 0
}

const getNumberFormat = (number) => Number(number).toLocaleString('fr-FR')

const getLongNumber = (number) => nFormatter(number)

const formatNumber = (number) => number.toFixed(3)

const formatFixedNumber = (number, fxdNumber) => number.toFixed(fxdNumber)

const getSuperiorAround = (value) => Math.ceil(value)
const getInferiorAround = (value) => Math.floor(value)

const hasValue = (value) => value || value === 0 || value === '0'

const hasBooleanValue = (value) => value === true || value === false

export {
    round,
    formatFloat,
    fixedNumber,
    getNumberFormat,
    getLongNumber,
    nFormatter,
    formatNumber,
    formatFixedNumber,
    getSuperiorAround,
    getInferiorAround,
    hasValue,
    hasBooleanValue,
}
