import Moment from 'moment'
import { extendMoment } from 'moment-range'
import localization from 'moment/locale/fr'
import { capitalizeFirstLetter } from './StringUtil'

const moment = extendMoment(Moment)
moment().locale('fr', localization)

const getDate = (date, format) => {
    if (date) {
        if (format) {
            return moment(date).format(format)
        }
        return moment(date).format('DD/MM/YYYY')
    }
    return ''
}

const getFullDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY - HH:mm:ss')
    }
    return ''
}

const getFullDateMini = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm')
    }
    return ''
}

const getDateWithHour = (date, hour) => {
    if (date && hour) {
        const mhour = moment(hour)
        return moment(date).hour(mhour.hour()).minute(mhour.minute()).second(mhour.second())
    }
    if (date) {
        return moment(date)
    }
    return null
}

const getDateWithHourString = (date, hour) => {
    const result = getDateWithHour(date, hour)
    return result ? result.format('DD/MM/YYYY HH:mm:ss') : ''
}

const getHour = (hour) => {
    if (hour) {
        return (
            moment(hour).format('HH:mm:ss')
        )
    }
    return ''
}

const convertValidHour = (hour) => {
    if (hour) {
        return moment(moment(hour).utcOffset(moment().utcOffset()).year(2000).format()).valueOf()
    }
    return null
}

const getHourValue = (hour) => {
    if (hour) {
        const mhour = moment(hour)
        return moment().hour(mhour.hour()).minute(mhour.minute()).second(mhour.second())
    }
    return null
}

const getMiniDate = (date) => {
    if (date) {
        return (
            moment(date).format('DD/MM/YY')
        )
    }
    return ''
}

const getArticleFormat = (date) => {
    if (date) {
        return moment(date).format('DD MMM, YYYY')
    }
    return ''
}

const getYearDate = (date) => {
    if (date) {
        return (
            moment(date).format('YYYY')
        )
    }
    return ''
}

const getDateExport = () => (
    moment().format('YYYYMMDDHHmmss')
)

const getMonthMiniYear = (date) => {
    if (date) {
        return (
            moment(date).format('MM/YY')
        )
    }
    return ''
}

const getMonthYear = (date) => {
    if (date) {
        return (
            moment(date).format('MM/YYYY')
        )
    }
    return ''
}

const getWeekYear = (date) => {
    if (date) {
        return (
            moment(date).format('WW/YYYY')
        )
    }
    return ''
}

const getDayMonth = (date) => {
    if (date) {
        return (
            moment(date).format('DD/MM')
        )
    }
    return ''
}

const getYear = (date) => moment(date).year()

const getMiniYear = (date) => moment(date).format('YY')

const getYearOrString = (date, string) => ((getYear(date) === getYear(new Date())) ? string : getYear(date))

const getDayDiff = (date1, date2) => moment(date1).diff(date2, 'days')

const enumerateBetweenDates = (start, end, format = 'days') => Array.from(moment().range(start, end).by(format))

const getDay = (dayNumber) => capitalizeFirstLetter(moment().isoWeekday(dayNumber).format('dddd'))

export {
    getFullDate,
    getArticleFormat,
    getDate,
    getMiniDate,
    getDayDiff,
    getYearDate,
    getYear,
    getHour,
    getDateExport,
    getYearOrString,
    getMonthYear,
    getWeekYear,
    getMonthMiniYear,
    enumerateBetweenDates,
    getMiniYear,
    getDayMonth,
    getFullDateMini,
    getDay,
    getDateWithHour,
    getHourValue,
    convertValidHour,
    getDateWithHourString,
}
