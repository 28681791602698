import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { push } from 'react-router-redux'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import ActionComponent from '../../../components/actions/ActionComponent'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import RotationApp from '../../referencial/components/rotation/components/RotationApp'
import EventsAction from '../../events/actions/EventsAction'
import HomeAction from '../../home/actions/HomeAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import UserViewDto from '../../administration/components/user/dto/UserViewDto'

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

class ScenarRotation extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = this.getInitialState(props)
    }

    getInitialState = () => ({
        currentZones: [],
        currentCultures: [],
        currentModesConduites: [],
        selectedCategory: '',
        displayMode: false,
        dataLoaded: false,
        eventLoaded: {},
        checked: true,
        open: false,
        editMode: this.props.editMode,
        homepageViews: this.props.genericUserView,
        territory: this.props.territory,
        direction: 'max',
    })

    onChangeRotations = (type, newRotations) => {
        if (type === 'ab') {
            this.props.onChangeScenario('constraintsAB', newRotations)
        } else if (type === 'eco') {
            this.props.onChangeScenario('constraintsEco', newRotations)
        }
    }

    componentDidMount() {
        this.setState({ editMode: this.props.editMode })
        if (this.state.editMode) {
            this.props.setEditMode()
        } else {
            this.props.setReadMode()
        }
    }

    render() {
        const { scenario, editMode, applicationSettings } = this.props
        const culturesFiltered = this.props.cultures.filter((ct) => this.props.territoire.cultures.some((c) => c.dataId === ct.id))
        this.props.setHelpPath('creer-et-simuler-des-scenarios/modifier-les-regles-de-rotation/', applicationSettings)
        return (
            <Card className="cardStyle">
                <FormControl disabled={!editMode} style={{ width: '100%', display: 'flex' }}>
                    <Label component="legend">{i18n.respectProportionsAssolements}</Label>
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        value={scenario.respect}
                        onChange={(v) => {
                            this.props.onChangeScenario('respect', JSON.parse(v.target.value))
                        }}
                        row
                    >
                        <FormControlLabel
                            value
                            control={<Radio color="primary" />}
                            label="Oui"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label="Non"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
                <RotationApp
                    scenario={scenario}
                    editMode={editMode}
                    onChangeRotations={this.onChangeRotations}
                    culturesFiltered={culturesFiltered}
                />
            </Card>
        )
    }
}

ScenarRotation.defaultProps = {
    warningModal: false,
}
ScenarRotation.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        contentId: PropTypes.number,
    }),
    warningModal: PropTypes.bool,
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
}

const mapStateToProps = (store) => ({
    genericUserView: store.UserReducer.genericUserView,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsEvent: store.EventsReducer.cmsEvent,
    cmsCategories: store.EventsReducer.cmsCategories,
    document: store.EventsReducer.document,
    users: store.UserReducer.users,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    cultures: store.ReferencialReducer.cultures,
    territoire: store.TerritoryReducer.territoire,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    fetchConduites: ReferencialAction.fetchConduites,
    fetchZones: ReferencialAction.fetchZones,
    fetchCultures: ReferencialAction.fetchCultures,
    fetchUsers: UserAction.fetchUsers,
    checkAdmin: UserAction.checkAdmin,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    fetchCMSEvent: EventsAction.fetchCMSEvent,
    fetchCMSDocument: EventsAction.fetchCMSDocument,
    updateCMSEvent: EventsAction.updateCMSEvent,
    deleteCMSEvent: EventsAction.deleteCMSEvent,
    saveCmsDocument: EventsAction.saveCmsDocument,
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarRotation)
