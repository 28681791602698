import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { groupBy, orderBy, take, maxBy } from 'lodash'
import i18n from 'simple-react-i18n'
import DtoUser from '../../../dto/DtoUser'
import DtoUserCmsStatistic from '../../../dto/DtoUserCmsStatistic'
import CMSCategoryDto from '../../../../../../events/dto/CMSCategoryDto'
import { getDate } from '../../../../../../../utils/DateUtil'
import Table from '../../../../../../../components/datatable/Table'
import DtoUserCmsStatisticItem from '../../../dto/DtoUserCmsStatisticItem'
import EventsAction from '../../../../../../events/actions/EventsAction'
import ProgressBar from '../../../../../../../components/ProgressBar'

class CmsStatisticsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsCategories: {},
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchCMSCategories().then(() => {
            this.setCMSCategories(this.props.cmsCategories)
            this.setState({ dataLoaded: true })
        })
    }

    setCMSCategories = (categories) => {
        const cmsCat = {}
        categories.map((c) => {
            cmsCat[c.id] = c.title
        })
        this.setState({ cmsCategories: cmsCat })
    }

    getTitle(categories, id) {
        for (const key in categories) {
            if (key == id) {
                return categories[key]
            }
        }
    }

    render() {
        const user = this.props.account ? this.props.accountUser : this.props.user
        const userCmsStatistics = this.props.account
            ? this.props.accountUserCmsStatistics
            : this.props.userCmsStatistics
        if (this.state.dataLoaded) {
            const group = groupBy(userCmsStatistics, 'value')
            const visits = take(
                orderBy(Object.keys(group), (key) => group[key].length, ['desc']),
                10,
            ).map((key) => {
                const allCms = group[key]
                const lastOne = maxBy(allCms, 'statisticDate')
                return new DtoUserCmsStatisticItem({
                    category: this.getTitle(this.state.cmsCategories, lastOne.module),
                    title: lastOne.value,
                    visitsCount: allCms.length,
                    lastAccess: getDate(lastOne.statisticDate),
                })
            })
            return (
                <Table
                    title={`10 ${i18n.userCmsStatistics}`}
                    sortable
                    type={new DtoUserCmsStatisticItem({})}
                    data={visits}
                    exportButtonOnHeader
                    exportData={visits}
                    showNbElements={false}
                    exportName={i18n.userCmsStatisticsExport + user.login}
                />
            )
        }
        return <ProgressBar />
    }
}

CmsStatisticsPanel.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountUserCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    user: PropTypes.instanceOf(DtoUser),
    userCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
}

const mapDispatchToProps = {
    fetchCMSCategories: EventsAction.fetchCMSCategories,
}

const mapStateToProps = (store) => ({
    accountUserCmsStatistics: store.AccountReducer.accountUserCmsStatistics,
    accountUser: store.AccountReducer.accountUser,
    userCmsStatistics: store.UserReducer.userCmsStatistics,
    user: store.UserReducer.user,
    cmsCategories: store.EventsReducer.cmsCategories,
})

export default connect(mapStateToProps, mapDispatchToProps)(CmsStatisticsPanel)
