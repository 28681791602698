import React from 'react'
import { chunk, min } from 'lodash'
import i18n from 'simple-react-i18n'

import UUID from 'uuid'
import {
    BLUE,
    DARKBLUE,
    DARKGREEN,
    GREEN,
    GREY,
    LIGHTBLUE,
    ORANGE,
    RED,
    WHITE,
    YELLOW,
    LIGHTGREY,
} from '../components/constants/ColorConstant'
import AppStore from '../store/Appstore'
import SieauAction from '../components/sieau/SieauAction'

const colors = [
    {
        constant: BLUE,
        color: 'blue',
        rgb: 'rgb(38,145,255)',
    },
    {
        constant: DARKBLUE,
        color: 'mediumblue',
        rgb: 'rgb(0,0,205)',
    },
    {
        constant: LIGHTBLUE,
        color: 'aqua',
        rgb: 'rgb(0,255,255)',
    },
    {
        constant: WHITE,
        color: 'white',
        rgb: 'rgb(255,255,255)',
    }, {
        constant: YELLOW,
        color: 'yellow',
        rgb: 'rgb(255,236,19)',
    }, {
        constant: GREEN,
        color: 'green',
        rgb: 'rgb(53,255,38)',
    }, {
        constant: DARKGREEN,
        color: 'darkgreen',
        rgb: 'rgb(24,115,17)',
    }, {
        constant: RED,
        color: 'red',
        rgb: 'rgb(255,0,0)',
    }, {
        constant: ORANGE,
        color: 'orange',
        rgb: 'rgb(255,173,29)',
    }, {
        constant: GREY,
        color: 'grey',
        rgb: 'rgb(136,136,136)',
    }, {
        constant: LIGHTGREY,
        color: 'lightgrey',
        rgb: 'rgb(189,189,189)',
    }, {
        constant: 'null',
        color: '',
        rgb: '',
    },
]

const getColor = (constant) => {
    const color = colors.find((c) => c.constant == constant)
    return color ? color.color : 'white'
}

const addBlankTint = (hexaColor) => {
    const hexaParts = [hexaColor.substring(1, 3), hexaColor.substring(3, 5), hexaColor.substring(5)]
    const addedTints = hexaParts.map((hexa) => min([parseInt(hexa, 16) + 35, 255]).toString(16))
    return `#${addedTints.join('')}`
}

const getRGBColor = (constant) => {
    const color = colors.find((c) => c.constant == constant)
    return color ? color.rgb : 'white'
}

const getRGBColorFromColor = (color) => {
    const found = colors.find((c) => c.color == color)
    return found ? found.rgb : 'white'
}

/* When bool=true => res=GREEN | bool=false => res=RED | bool=undefined => res=GREY  */
const getBooleanColor = (bool) => {
    if (!bool) {
        return (bool === false) ? RED : GREY
    }
    return GREEN
}

const getColorCircleElement = (rgbColor, JSX) => {
    if (JSX) {
        return <span style={{ display: 'inline-block', 'margin-right': '5px', 'border-radius': '10px', width: '9px', height: '9px', 'background-color': rgbColor }} />
    }
    return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${rgbColor};"></span>`
}

const getColorFromPalette = (index) => ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#66c722', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#f775d2', '#c4ccd3'][index % 11]

// const getArrestToken = (typeArrest) => {
//     if (typeArrest === i18n.vigilance) {
//         return (<span className='grey black-text arrests-level-panel'>{ i18n.vigilance }</span>)
//     }
//     if(typeArrest === i18n.crisis){
//         return <span className='red black-text arrests-level-panel'>{ i18n.crisis }</span>
//     }
//     if(typeArrest === i18n.reinforcedAlert){
//         return <span className='orange black-text arrests-level-panel'>{ i18n.reinforcedAlert }</span>
//     }
//     if(typeArrest === i18n.warning){
//         return <span className='yellow black-text arrests-level-panel'>{ i18n.warning }</span>
//     }
//     return null
// }

const getArrestColor = (typeArrest) => {
    switch (typeArrest) {
        case i18n.crisis || 3:
            return 'red'
        case i18n.reinforcedAlert || 2:
            return 'orange'
        case i18n.warning || 1:
            return 'yellow'
        case i18n.vigilance || 0:
            return 'grey'
        default:
            return 'grey'
    }
}

const pickColorModal = (colorList = [], onSelectColor = () => {}, message = i18n.color) => {
    const id = UUID.v4()
    const elements = colorList.map((c, i) => (
        <div className="col s4">
            <span className="arrests-level-panel modal-close" style={{ color: c, 'background-color': c }} onClick={() => onSelectColor(i)}>O</span>
        </div>
    ))
    const groups = chunk(elements, 3).map((g) => (
        <div className="row no-margin">
            { g }
        </div>
    ))
    const popup = {
        id,
        header: message,
        content: groups,
        className: 'pickColorModal',
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const getThresholdColorCodes = () => [
    '#000000',
    '#0000FF',
    '#008000',
    '#00FFFF',
    '#FF0000',
    '#8B008B',
    '#FFFF00',
    '#FFFFFF',
    '#808080',
    '#ADD8E6',
    '#90EE90',
    '#E0FFFF',
    '#CD5C5C',
    '#FF00FF',
    '#FFFFE0',
    '#F5F5F5',
]

const getThresholdColors = () => [
    'black',
    'blue',
    'green',
    'cyan',
    'red',
    'darkmagenta',
    'yellow',
    'white',
    'grey',
    'lightblue',
    'lightgreen',
    'lightcyan',
    'indianred',
    'magenta',
    'lightyellow',
    'whitesmoke',
]

const getThresholdColor = (color) => {
    const clrs = getThresholdColors()
    return color < clrs.length ? clrs[color] : clrs[0]
}

const getThresholdColorCode = (color) => {
    const clrs = getThresholdColorCodes()
    return color < clrs.length ? clrs[color] : clrs[0]
}

const getEventColor = (eventType) => {
    switch (eventType) {
        case 'M':
            return 'RED'
        case 'G':
            return 'BLUE'
        case 'P':
            return 'YELLOW'
        case 'S':
            return 'ORANGE'
        case 'C':
            return 'DARKGREEN'
        default:
            return 'GREY'
    }
}

const getNewsColor = (newsLevel) => {
    switch (newsLevel) {
        case -1:
            return 'null'
        case 1:
            return 'BLUE'
        case 2:
            return 'GREEN'
        case 3:
            return 'ORANGE'
        case 4:
            return 'RED'
        default:
            return 'null'
    }
}

const hexToRGBColor = (hexColor) => {
    if (hexColor) {
        const rgb = hexColor.substring(1).match(/.{2}/g).map((x) => parseInt(x, 16))
        return `rgb(${rgb.join(',')})`
    }
    return null
}

const RGBToHexColor = (color = '') => {
    const rgb = color.replace('rgb(', '').replace(')', '').split(',')
    return `#${rgb.map((c) => {
        const hex = Number(c).toString(16)
        if (hex.length < 2) {
            return `0${hex}`
        }
        return hex
    }).join('')}`
}

export {
    hexToRGBColor,
    RGBToHexColor,
    getColor,
    getThresholdColor,
    getEventColor,
    getBooleanColor,
    getRGBColor,
    getColorCircleElement,
    getColorFromPalette,
    getRGBColorFromColor,
    addBlankTint,
    getArrestColor,
    getNewsColor,
    getThresholdColors,
    getThresholdColorCodes,
    getThresholdColorCode,
    pickColorModal,
}
