import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Icon extends Component {
    getStyle = () => {
        if (this.props.style) {
            return this.props.style
        }
        if (this.props.color) {
            return { color: this.props.color }
        }
        return null
    }

    render() {
        return (
            <i
                style={this.getStyle()}
                data-tooltip={this.props.tooltip}
                className={`material-icons ${!this.props.notClickable ? 'clickable' : ''} ${this.props.size} ${this.props.className} ${this.props.tooltip ? 'tooltipped' : ''}`}
                onClick={this.props.onClick}
            > { this.props.children || this.props.icon || null } 
            </i>
        )
    }
}

Icon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.string,
    children: PropTypes.string,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    notClickable: PropTypes.bool,
}

Icon.defaultProps = {
    size: '',
    className: '',
    onClick: () => {},
}

export default Icon
