import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import { PATH_HOME } from '../constants/RouteConstants'
import HomeAction from '../actions/HomeAction'
import { getPayload } from '../../../utils/ActionUtils'

const checkAuth = (logout) => {
    const payload = getPayload()
    if (!payload) {
        return false
    } if (moment().isAfter(moment(JSON.parse(payload).exp))) {
        logout()
        return false
    }
    return true
}

const AuthRoute = (props) => (checkAuth(props.logout) ? <props.component {...props} /> : <Redirect to={PATH_HOME} />)

const mapDispatchToProps = {
    logout: HomeAction.logout,
}

export default connect(null, mapDispatchToProps)(AuthRoute)
