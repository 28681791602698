export default class SolutionDto {
    constructor(obj) {
        this.codeSolution = obj.codeSolution
        this.description = obj.description
        this.active = obj.active
        this.updateDate = obj.updateDateDa
        this.updateLogin = obj.updateLogin
        this.materialChange = obj.materialChangeDa
        this.degradation = obj.degradation
        this.endDate = obj.endDateD
    }
}
