import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import i18n from 'simple-react-i18n'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import { Flex } from '../../../styles/main'
import { mainPink } from '../../../styles/theme'
import Input from '../../../components/forms/Input'
import { GreenButton } from '../../../styles/button'
import ReferencialAction from '../action/ReferencialAction'
import { hasValue } from '../../../utils/NumberUtil'
import ToastrAction from '../../../utils/ToastrAction'

const Right = styled.div`
    display: flex;
    justify-content: end;
`

const PinkBloc = styled.div`
    width: 100%;
    border: 4px solid ${mainPink};
    border-radius: 5px;
    position: relative;
    margin: 10px 0;
`

const PinkTitle = styled.div`
    width: fit-content;
    height: 30px;
    font-size: 1.4rem;
    color: ${mainPink};
    background-color: white;
    position: absolute;
    top: -20px;
    left: 10px;
    text-align: center;
    padding: 0 10px 0 10px;
`

const Wrapper = styled(Flex)`
    padding: 1rem;
`

const Label = styled.div`
    width: 95%;
    margin: 2px 10px 8px;
`

export function getModalStyle() {
    const top = 60
    const left = 60

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const ModalAddMatriceRefData = ({
    open,
    handleClose,
    onValidate,
}) => {
    const [cultureId, setCultureId] = useState()
    const [department, setDepartment] = useState()
    const [typeSuccession, setTypeSuccession] = useState()
    const [driveModeId, setDriveModeId] = useState()
    const [indicatorId, setIndicatorId] = useState()
    const [value, setValue] = useState()
    
    const {
        cultures,
        conduites,
        indicateurs,
        sandreCodes,
    } = useSelector((store) => ({
        cultures: store.ReferencialReducer.cultures,
        conduites: store.ReferencialReducer.conduites,
        indicateurs: store.ReferencialReducer.indicateurs,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const closeModal = () => {
        handleClose()
    }

    const onCreate = () => {
        if (hasValue(cultureId)
            && hasValue(department)
            && hasValue(typeSuccession)
            && hasValue(driveModeId)
            && hasValue(indicatorId)
            && hasValue(value)) {
            onValidate({
                cultureId,
                department,
                typeSuccession,
                driveModeId,
                indicatorId,
                value,
            })
        } else {
            ToastrAction.warning(i18n.pleaseCompleteAllField)  
        }
    }

    const inputStyle = {
        width: '200px',
        height: '48px',
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={open}
            onClose={closeModal}
            disableBackdropClick
        >
            <div style={{ padding: 10 }}>
                <PinkBloc>
                    <PinkTitle>
                        {i18n.addData} :
                    </PinkTitle>
                    <Wrapper>
                        <FormControl variant="outlined" style={{ marginRight: 10 }}>
                            <Label htmlFor="cultureId">{`${i18n.culture} :`}</Label>
                            <Select
                                name="cultureId"
                                value={cultureId}
                                onChange={(v) => setCultureId(v.target.value)}
                            >
                                {cultures.map((item) => (
                                    <MenuItem value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div>
                            <Label htmlFor="department">{`${i18n.department} :`}</Label>
                            <Input
                                style={inputStyle}
                                value={department}
                                onChange={(v) => setDepartment(v)}
                            />
                        </div>
                        <FormControl variant="outlined" style={{ marginRight: 10 }}>
                            <Label htmlFor="typeSuccession">{`${i18n.typeSuccession} :`}</Label>
                            <Select
                                name="typeSuccession"
                                value={typeSuccession}
                                onChange={(e) => setTypeSuccession(e.target.value)}
                            >
                                {sandreCodes.filter((c) => c.field === 'AGRI_RPG.TYPE_SUCCESSION').map((item) => (
                                    <MenuItem value={item.code}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ marginRight: 10 }}>
                            <Label htmlFor="driveMode">{`${i18n.conduite} :`}</Label>
                            <Select
                                name="driveMode"
                                value={driveModeId}
                                onChange={(e) => setDriveModeId(e.target.value)}
                            >
                                {conduites.map((item) => (
                                    <MenuItem value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ marginRight: 10 }}>
                            <Label htmlFor="indicateur">{`${i18n.indicateurPerf} :`}</Label>
                            <Select
                                name="indicateur"
                                value={indicatorId}
                                onChange={(e) => setIndicatorId(e.target.value)}
                            >
                                {indicateurs.map((item) => (
                                    <MenuItem value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div>
                            <Label htmlFor="value">{i18n.valueLabel}&nbsp;:</Label>
                            <Input
                                style={inputStyle}
                                value={value}
                                onChange={(v) => setValue(parseFloat(v))}
                                type="number"
                                step="0.01"
                            />
                        </div>
                    </Wrapper>
                </PinkBloc>
                <Right>
                    <GreenButton
                        onClick={() => closeModal()}
                        greenReverse
                        borderRadius
                        style={{ margin: '0 1rem 10px' }}
                    >
                        {i18n.close}
                    </GreenButton>
                    <GreenButton
                        onClick={onCreate}
                        borderRadius
                        style={{ margin: '0 1rem 10px' }}
                    >
                        {i18n.add}
                    </GreenButton>
                </Right>
            </div>
        </Dialog>
    )
}

ModalAddMatriceRefData.propTypes = {
    handleClose: PropTypes.func,
    onValidate: PropTypes.func,
    open: PropTypes.bool.isRequired,
}

const mapStateToProps = (store) => ({
    culturesRPG: store.ReferencialReducer.culturesRPG,
})

const mapDispatchToProps = {
    createZone: ReferencialAction.createZone,
    createCulture: ReferencialAction.createCulture,
    createConduite: ReferencialAction.createConduite,
    createIndicateur: ReferencialAction.createIndicateur,
    fetchCultures: ReferencialAction.fetchCultures,
    fetchZones: ReferencialAction.fetchZones,
    fetchConduites: ReferencialAction.fetchConduites,
    fetchIndicateurs: ReferencialAction.fetchIndicateurs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddMatriceRefData)
