const getNameById = (array, id) => {
    const parent = array.find((p) => p.id === id) || {}
    return parent.name
}

const getIdByName = (array, name) => {
    const parent = array.find((p) => p.name === name)
    return parent.id
}

const isNumber = (value) => typeof value === 'number' && isFinite(value)

const isNumberObject = (n) => Object.prototype.toString.apply(n) === '[object Number]'

const isCustomNumber = (n) => isNumber(n) || isNumberObject(n)
// Add a pourcentage after an AGGRID formatted value
const pourcentageFormatter = (params) => {
    if (isCustomNumber(params.value)) {
        return `${params.value}%`
    }
    return params.value
}

const sortById = (a, b) => {
    const idA = a.id
    const idB = b.id
    let comparison = 0
    if (idA && idB) {
        if (idA > idB) {
            comparison = 1
        } else if (idA < idB) {
            comparison = -1
        }
        return comparison
    }
    return comparison
}

const sortByIdRevert = (a, b) => {
    const idA = a.id
    const idB = b.id
    let comparison = 0
    if (idA && idB) {
        if (idA < idB) {
            comparison = 1
        } else if (idA > idB) {
            comparison = -1
        }
        return comparison
    }
    return comparison
}

export { getNameById, getIdByName, pourcentageFormatter, sortById, sortByIdRevert }
