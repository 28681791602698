/* eslint-disable no-plusplus */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isEqual, orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import {
    ExpansionPanel,
    FormControl,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Divider,
    Slider,
    Select,
    MenuItem,
    FormControlLabel,
    Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import { getUser } from '../../../../../utils/SettingUtils'
import { MainContainer, Position, RefTable } from '../../../../../styles/container'
import { SpaceAround, Flex } from '../../../../../styles/main'
import CustomizedSwitch from '../../../../../components/Switch'
import UserViewDto from '../../../../administration/components/user/dto/UserViewDto'
import UpdatePanel from '../../../../../components/UpdatePanel'
import DtoUser from '../../../../administration/components/user/dto/DtoUser'
import AccountAction from '../../../../account/actions/AccountAction'
import ModalElementaryBricks from '../../../../territory/ModalElementaryBricks'
import { GreenButton } from '../../../../../styles/button'

const InputRow = styled.input`
    background-color: ${(props) => props.disabled && '#F0F0F0 !important'};
    height: 48px;
    margin-right: 5px;
`

const Wrapper = styled(Flex)`
    align-items: center;
    justify-content: center;
`

const ContentFlex = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.col ? 'column' : 'row')};
    width: 95%;
    align-items: center;
    margin: 1rem 0rem;
`

const Row = styled.div`
    margin: ${(props) => (props.noMargin ? '1rem 0' : '1rem 2rem 1rem 0')};
    width: ${(props) => (props.width ? props.width : 'auto')};
`

const SliderRow = styled.div`
    margin: ${(props) => (props.noMargin ? '0.6rem 0.5rem' : '0.6rem 5rem 1rem 3rem')};
    width: 70%;
`

const Text = styled.span`
    font-weight: bold;
    font-size: 1rem;
    -webkit-transform: translate(0px, 23px);
    -ms-transform: translate(0px, 23px);
    transform: translate(0px, 23px);
    transform: translate(0px, 23px);
    margin: ${(props) => (props.noMargin ? '1rem 0' : '1rem 2rem 1rem 0')};
`

const eachCultureType = [
    { id: 1, name: "l'ensemble" },
    { id: 0, name: 'chaque' },
]

const cultureType = [
    { id: 0, name: 'culture(s)' },
    { id: 1, name: 'tête(s) de rotation' },
    { id: 2, name: 'pluriannuelle' },
    { id: 3, name: 'culture(s) de printemps' },
    { id: 4, name: 'culture(s) automne' },
    { id: 5, name: 'légumineuse(s)' },
]

class RotationApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            openEco: true,
            openAB: true,
            checked: false,
            editMode: props.editMode ? props.editMode : false,
            homepageViews: props.genericUserView,
            rotationsAB: [],
            rotationsEco: [],
            scenarioRotationsAB: [],
            scenarioRotationsEco: [],
            turn: 1,
        }
    }

    componentDidUpdate() {
        if (this.props.scenario && this.props.editMode !== this.state.editMode) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ editMode: this.props.editMode })
        }
    }

    componentDidMount() {
        if (!this.props.scenario) {
            if (getUser().isAdmin === '1') {
                const actions = {
                    edit: () => this.setEditMode(),
                    export: () => this.props.exportExcelReglesRotations(),
                }
                this.setActions(actions)
            }
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: 'referencial/dashboard',
                },
                {
                    title: i18n.reglesRotation,
                    href: 'referencial/rotation',
                },
            ])
        }
        this.props.fetchCultures()
        if (this.props.scenario) {
            this.props
                .fetchRotationAB()
                .then(() => this.setStateRotation('rotationsAB', this.props.rotationsAB))
            this.props
                .fetchRotationEco()
                .then(() => this.setStateRotation('rotationsEco', this.props.rotationsEco))
            this.setStateRotation('scenarioRotationsAB', this.props.scenario.constraintsAB)
            this.setStateRotation('scenarioRotationsEco', this.props.scenario.constraintsEco)
        } else {
            this.props
                .fetchRotationAB()
                .then(() => this.setStateRotation('rotationsAB', this.props.rotationsAB))
            this.props
                .fetchRotationEco()
                .then(() => this.setStateRotation('rotationsEco', this.props.rotationsEco))
        }
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
        window.scrollTo(0, 0)
    }

    setStateRotation(name, rotations) {
        this.setState({ [name]: rotations })
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = [...this.props.rotationsAB, ...this.props.rotationsEco].filter((r) => r.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    setEditMode = () => {
        this.setState({ editMode: true })
        const actions = {
            cancel: () => this.setReadOnlyMode(),
        }
        actions.save = () => this.onSaveEvent()
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({
            editMode: false,
            homepageViews: this.props.genericUserView,
        })
        this.setActions({
            edit: () => this.setEditMode(),
            export: () => this.props.exportExcelReglesRotations(),
        })
    }

    onSaveEvent = () => {
        if (!this.props.scenario) {
            this.state.rotationsAB.forEach((r) => {
                if (
                    !isEqual(
                        r,
                        this.props.rotationsAB.find((rProps) => rProps.id === r.id),
                    )
                ) {
                    this.props.updateRotationAB(r)
                }
            })
            this.state.rotationsEco.forEach((r) => {
                if (
                    !isEqual(
                        r,
                        this.props.rotationsEco.find((rProps) => rProps.id === r.id),
                    )
                ) {
                    this.props.updateRotationEco(r)
                }
            })
            this.props
                .fetchRotationAB()
                .then(() => this.setStateRotation('rotationsAB', this.props.rotationsAB))
            this.props
                .fetchRotationEco()
                .then(() => this.setStateRotation('rotationsEco', this.props.rotationsEco))
        }
        this.setReadOnlyMode()
    }

    openEco = () => {
        const { openEco } = this.state
        if (openEco) {
            this.setState({ openEco: false })
        } else {
            this.setState({ openEco: true })
        }
    }

    openAB = () => {
        const { openAB } = this.state
        if (openAB) {
            this.setState({ openAB: false })
        } else {
            this.setState({ openAB: true })
        }
    }

    onChangeSwitch = () => {
        const { checked } = this.state
        this.setState({
            checked: !checked,
        })
    }

    parseBool = (v) => {
        if (v === 1) {
            return true
        }
        return false
    }

    unparseBool = (v) => {
        if (v) {
            return 1
        }
        return 0
    }

    onChangeRotation = (type, regleRotation, key, value) => {
        let newRotations = []
        if (this.props.scenario) {
            if (type === 'ab') {
                let newsCultures = regleRotation.cultures
                if (key === 'cultureType') {
                    newsCultures = this.changeCulturesArray(value)
                }
                newRotations = [...this.state.scenarioRotationsAB]
                const index = newRotations.findIndex((r) => r.id === regleRotation.id)
                const regle = newRotations.find((r) => r.id === regleRotation.id)
                const newRegle = {
                    ...regle,
                    cultureType: key === 'cultures' ? 0 : regle.cultureType,
                    cultures: newsCultures,
                    [key]: value,
                }
                newRotations[index] = newRegle
                this.setState({ scenarioRotationsAB: newRotations })
                this.props.onChangeRotations(type, newRotations)
            }
            if (type === 'eco') {
                let newsCultures = regleRotation.cultures
                if (key === 'cultureType') {
                    newsCultures = this.changeCulturesArray(value)
                }
                newRotations = [...this.state.scenarioRotationsEco]
                const index = newRotations.findIndex((r) => r.id === regleRotation.id)
                const regle = newRotations.find((r) => r.id === regleRotation.id)
                const newRegle = {
                    ...regle,
                    cultureType: key === 'cultures' ? 0 : regle.cultureType,
                    cultures: newsCultures,
                    [key]: value,
                }
                newRotations[index] = newRegle
                this.setState({ scenarioRotationsEco: newRotations })
                this.props.onChangeRotations(type, newRotations)
            }
        } else {
            if (type === 'ab') {
                newRotations = [...this.state.rotationsAB]
                const index = newRotations.findIndex((r) => r.id === regleRotation.id)
                const regle = newRotations.find((r) => r.id === regleRotation.id)
                const newRegle = { ...regle, [key]: value }
                newRotations[index] = newRegle
                this.setState({ rotationsAB: newRotations })
            }
            if (type === 'eco') {
                newRotations = [...this.state.rotationsEco]
                const index = newRotations.findIndex((r) => r.id === regleRotation.id)
                const regle = newRotations.find((r) => r.id === regleRotation.id)
                const newRegle = { ...regle, [key]: value }
                newRotations[index] = newRegle
                this.setState({ rotationsEco: newRotations })
            }
        }
    }

    changeCulturesArray = (value) => {
        const { culturesFiltered } = this.props
        let newsCultures = []
        switch (value) {
            case 0:
                newsCultures = culturesFiltered.map((c) => c.id)
                break
            case 1:
                culturesFiltered.forEach((c) => {
                    if (c.teteRotation) {
                        newsCultures.push(c.id)
                    }
                })
                break
            case 2:
                culturesFiltered.forEach((c) => {
                    if (c.periodeSemence === 0) {
                        newsCultures.push(c.id)
                    }
                })
                break
            case 3:
                culturesFiltered.forEach((c) => {
                    if (c.periodeSemence === 1) {
                        newsCultures.push(c.id)
                    }
                })
                break
            case 4:
                culturesFiltered.forEach((c) => {
                    if (c.periodeSemence === 2) {
                        newsCultures.push(c.id)
                    }
                })
                break
            case 5:
                culturesFiltered.forEach((c) => {
                    if (c.legumineuse) {
                        newsCultures.push(c.id)
                    }
                })
                break
            default:
                break
        }
        return newsCultures
    }

    onChangeRange = (type, regleRotation, value) => {
        this.onChangeRotation(type, regleRotation, 'min', parseInt(value[0]))
        this.onChangeRotation(type, regleRotation, 'max', parseInt(value[1]))
    }

    onChangeSelectedData = (key, value) => {
        this.setState({
            selectedData: value,
        })
    }

    onSaveSelectedData = (type, regleRotation) => {
        this.onChangeRotation(type, regleRotation, 'cultures', this.state.selectedData)
    }

    onChangeValue = (type, regleRotation, key, value) => {
        if (
            (key === 'max' && value <= 100)
            || (key === 'min' && value <= regleRotation.max && value >= 0)
        ) {
            this.onChangeRotation(type, regleRotation, key, parseInt(value))
        }
    }

    getForm = (regleRotation, item, type) => {
        const { editMode } = this.state
        let refRegle = {}
        if (type === 'ab') {
            refRegle = this.props.rotationsAB.find((r) => r.id === regleRotation.id)
        } else if (type === 'eco') {
            refRegle = this.props.rotationsEco.find((r) => r.id === regleRotation.id)
        }
        const currentCultures = regleRotation.cultures.map((c) => ({ dataId: c }))
        if (this.props.scenario) {
            if (
                refRegle.toutesCultures
                && regleRotation.cultures.length !== this.props.culturesFiltered.length
            ) {
                this.onChangeRotation(
                    type,
                    regleRotation,
                    'cultures',
                    this.props.culturesFiltered.map((c) => c.id),
                )
            }
        }

        const selectDisabledMode = {
            backgroundColor: 'rgb(240, 240, 240)',
            height: '48px',
            width: '150px',
            marginRight: '5px',
        }

        const selectEditMode = {
            height: '48px',
            width: '150px',
            marginRight: '5px',
        }

        return (
            <>
                <Row noMargin width="15%">
                    <Text noMargin>{regleRotation.id}</Text>
                </Row>
                <Row noMargin width="25%">
                    <Text noMargin>La part de</Text>
                </Row>
                <Row noMargin width="35%">
                    <FormControl variant="outlined" disabled={!editMode} key={regleRotation.id}>
                        <Select
                            name="ensemble"
                            id="ensemble-select"
                            style={!editMode ? selectDisabledMode : selectEditMode}
                            value={this.unparseBool(regleRotation.ensemble)}
                            onChange={(v) => this.onChangeRotation(
                                type,
                                regleRotation,
                                'ensemble',
                                this.parseBool(v.target.value),
                            )}
                        >
                            {eachCultureType.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Row>
                <Row noMargin width="35%">
                    <FormControl variant="outlined" disabled={!editMode} key={regleRotation.id}>
                        <Select
                            name="cultureType"
                            id="cultureType-select"
                            style={!editMode ? selectDisabledMode : selectEditMode}
                            value={regleRotation.cultureType}
                            onChange={(v) => this.onChangeRotation(
                                type,
                                regleRotation,
                                'cultureType',
                                parseInt(v.target.value),
                            )}
                        >
                            {cultureType.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Row>
                {this.props.scenario ? (
                    <div style={{ padding: '0rem 1rem' }}>
                        <ModalElementaryBricks
                            culture
                            iconName="local_florist"
                            number={`${
                                refRegle.toutesCultures
                                    ? this.props.territoire.cultures.length
                                    : regleRotation.cultures.length
                            }/${this.props.territoire.cultures.length}`}
                            text={
                                this.props.territoire.cultures.length > 1
                                    ? i18n.cultures
                                    : i18n.culture
                            }
                            culturesData={this.props.culturesFiltered}
                            onSave={this.onSaveSelectedData}
                            onChange={this.onChangeSelectedData}
                            selectedCultures={
                                refRegle.toutesCultures
                                    ? this.props.culturesFiltered
                                    : currentCultures
                            }
                            clickable
                            isEditMode={refRegle.toutesCultures ? false : editMode}
                            addField={false}
                            typeKey={type}
                            objectifId={regleRotation}
                        />
                    </div>
                ) : (
                    <CustomizedSwitch
                        disabled={!editMode}
                        checked={regleRotation.toutesCultures}
                        onChange={(v) => this.onChangeRotation(
                            type,
                            regleRotation,
                            'toutesCultures',
                            v.target.checked,
                        )}
                        label="Toutes les cultures"
                        key={regleRotation.id}
                    />
                )}
                <Row noMargin width="35%">
                    <Text noMargin>compris entre</Text>
                </Row>
                <InputRow
                    className="sieau-input form-control"
                    disabled={!editMode}
                    style={{ width: '50px' }}
                    value={regleRotation.min}
                    onChange={(v) => this.onChangeValue(
                        type,
                        regleRotation,
                        'min',
                        parseInt(v.target.value) || 0,
                    )}
                />
                <SliderRow noMargin>
                    <Slider
                        disabled={!editMode}
                        value={[regleRotation.min, regleRotation.max]}
                        onChange={(e, v) => this.onChangeRange(type, regleRotation, v)}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                </SliderRow>
                <InputRow
                    className="sieau-input form-control"
                    disabled={!editMode}
                    style={{ width: '50px' }}
                    value={regleRotation.max}
                    onChange={(v) => this.onChangeValue(
                        type,
                        regleRotation,
                        'max',
                        parseInt(v.target.value) || 0,
                    )}
                />
            </>
        )
    }

    sortById = (a, b) => a.id - b.id

    getRotations(rotations, type) {
        rotations.sort(this.sortById)
        return rotations ? (
            rotations.map((r, item) => (
                <>
                    <Wrapper>
                        <ContentFlex>{this.getForm(r, item, type)}</ContentFlex>
                    </Wrapper>
                    <Divider />
                </>
            ))
        ) : (
            <span>Chargement en cours</span>
        )
    }

    resetRotations = (type) => {
        const { rotationsEco, rotationsAB, scenarioRotationsEco, scenarioRotationsAB } = this.state
        const newRotations = []
        switch (type) {
            case 'eco':
                for (let id = 1; id <= 5; id++) {
                    const defaultRule = rotationsEco.find((r) => r.id === id)
                    const scenarioRule = scenarioRotationsEco.find((r) => r.id === id)
                    if (defaultRule && scenarioRule) {
                        scenarioRule.ensemble = defaultRule.ensemble
                        scenarioRule.cultureType = defaultRule.cultureType
                        scenarioRule.min = defaultRule.min
                        scenarioRule.max = defaultRule.max
                        scenarioRule.cultures = this.changeCulturesArray(defaultRule.cultureType)
                        newRotations.push(scenarioRule)
                    }
                }
                this.props.onChangeRotations('eco', newRotations)
                break
            case 'ab':
                for (let id = 1; id <= 5; id++) {
                    const defaultRule = rotationsAB.find((r) => r.id === id)
                    const scenarioRule = scenarioRotationsAB.find((r) => r.id === id)
                    if (defaultRule && scenarioRule) {
                        scenarioRule.ensemble = defaultRule.ensemble
                        scenarioRule.cultureType = defaultRule.cultureType
                        scenarioRule.min = defaultRule.min
                        scenarioRule.max = defaultRule.max
                        scenarioRule.cultures = this.changeCulturesArray(defaultRule.cultureType)
                        newRotations.push(scenarioRule)
                    }
                }
                this.props.onChangeRotations('ab', newRotations)
                break
            default:
                break
        }
    }

    getPanel() {
        const { editMode } = this.state
        const { scenario } = this.props
        return (
            <>
                <ExpansionPanel
                    expanded={this.state.openEco}
                    onChange={this.openEco}
                    backgroundColor="#484848"
                    TransitionProps={{ timeout: 200 }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="expandIcon" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: '0', color: 'white' }}
                    >
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <h6 style={{ margin: '20px' }}>
                                    {scenario
                                        ? `${i18n.objectifRotation} ${i18n.ecoPlus}`
                                        : `${i18n.regleRotation} ${i18n.ecoPlus}`}
                                </h6>
                            </Grid>
                            <Grid item style={{ marginRight: '15px' }}>
                                {scenario && editMode ? (
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        control={(
                                            <GreenButton
                                                disabled={!editMode}
                                                borderRadius
                                                onClick={() => this.resetRotations('eco')}
                                            >
                                                {i18n.defaultValue}
                                            </GreenButton>
                                        )}
                                    />
                                ) : (
                                    ''
                                )}
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {this.getRotations(
                            scenario ? this.state.scenarioRotationsEco : this.state.rotationsEco,
                            'eco',
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    expanded={this.state.openAB}
                    onChange={this.openAB}
                    backgroundColor="#484848"
                    TransitionProps={{ timeout: 200 }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="expandIcon" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0, color: 'white' }}
                    >
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <h6 style={{ margin: '20px' }}>
                                    {scenario
                                        ? `${i18n.objectifRotation} ${i18n.ab}`
                                        : `${i18n.regleRotation} ${i18n.ab}`}
                                </h6>
                            </Grid>
                            <Grid item style={{ marginRight: '15px' }}>
                                {scenario && editMode ? (
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={(
                                            <GreenButton
                                                disabled={!editMode}
                                                borderRadius
                                                onClick={() => this.resetRotations('ab')}
                                            >
                                                {i18n.defaultValue}
                                            </GreenButton>
                                        )}
                                    />
                                ) : (
                                    ''
                                )}
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {this.getRotations(
                            scenario ? this.state.scenarioRotationsAB : this.state.rotationsAB,
                            'ab',
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </>
        )
    }

    render() {
        const { scenario } = this.props
        return (
            <>
                {scenario ? (
                    this.getPanel()
                ) : (
                    <SpaceAround>
                        <MainContainer noWidth>
                            <RefTable>
                                <div style={{ width: '100%', margin: '3rem' }}>
                                    <Position>{this.getUpdatePanel()}</Position>
                                </div>
                                {this.getPanel()}
                            </RefTable>
                        </MainContainer>
                    </SpaceAround>
                )}
            </>
        )
    }
}

RotationApp.propTypes = {
    getLink: PropTypes.func,
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    accountUser: PropTypes.instanceOf(DtoUser),
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    user: store.AccountReducer.accountUser,
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    rotationsAB: store.ReferencialReducer.rotationsAB,
    rotationsEco: store.ReferencialReducer.rotationsEco,
    cultures: store.ReferencialReducer.cultures,
    territoire: store.TerritoryReducer.territoire,
})

const mapDispatchToProps = {
    fetchUser: AccountAction.fetchUser,
    setTitle: HomeAction.setTitle,
    fetchCultures: ReferencialAction.fetchCultures,
    fetchRotationAB: ReferencialAction.fetchRotationAB,
    fetchRotationEco: ReferencialAction.fetchRotationEco,
    updateRotationAB: ReferencialAction.updateRotationAB,
    updateRotationEco: ReferencialAction.updateRotationEco,
    exportExcelReglesRotations: ReferencialAction.exportExcelReglesRotations,
}

export default connect(mapStateToProps, mapDispatchToProps)(RotationApp)
