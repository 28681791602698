import React, { Component } from 'react'
import M from 'materialize-css'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import { slides } from '../constants/SlideConstants'
import HomeAction from '../actions/HomeAction'
import CMSEventDto from '../../events/dto/CMSEventDto'
import { contentsPath } from '../../../conf/basepath'
import { white } from '../../../styles/theme'

class HomeSlide extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imagesSlider: [],
            stepsSlider: [],
            homeSlidesLoaded: false,
            homeStepsLoaded: false,
        }
    }

    componentDidMount() {
        const { cmsSlider } = this.props
        if (!cmsSlider.length) {
            this.props.fetchCmsSlider().then(() => {
                this.setImagesSlides()
            })
        } else {
            this.setImagesSlides()
        }

        const { cmsSliderSteps } = this.props
        if (!cmsSliderSteps.length) {
            this.props.fetchCmsSliderSteps().then(() => {
                this.setStepsSlides()
            })
        } else {
            this.setStepsSlides()
        }
    }

    setImagesSlides = () => {
        const { cmsSlider } = this.props
        const imagesSlider = []
        cmsSlider.forEach((c) => {
            if (c.cmsDocument[0]) {
                imagesSlider.push({
                    id: c.id,
                    order: parseInt(c.subtitle) || null,
                    src: `${contentsPath}CMS/${c.cmsDocument[0].name}`,
                })
            }
        })
        this.setState({ homeSlidesLoaded: true, imagesSlider })
    }

    setStepsSlides = () => {
        const { cmsSliderSteps } = this.props
        const stepsSlider = []
        cmsSliderSteps.forEach((c) => {
            if (c.cmsDocument[0]) {
                stepsSlider.push({
                    id: c.id,
                    order: parseInt(c.subtitle) || null,
                    src: `${contentsPath}CMS/${c.cmsDocument[0].name}`,
                })
            }
        })
        this.setState({ homeStepsLoaded: true, stepsSlider })
    }

    getSlide = (src, index) => (
        <li
            key={index}
            style={{
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: white,
            }}
        />
    )

    getSlidesHome = () => {
        const { homeSlidesLoaded, homeStepsLoaded } = this.state
        const { stepsSlider, imagesSlider } = this.state
        const { home } = this.props

        const slidesCMS = home ? [...stepsSlider] : [...imagesSlider]

        if (homeSlidesLoaded && homeStepsLoaded) {
            const elems = document.querySelectorAll('.slider')
            const options = {
                duration: 750,
                interval: home ? 7000 : 3000,
                indicators: !!home,
            }
            M.Slider.init(elems, options)
            if (home) {
                const indicators = document.querySelectorAll('.indicator-item')
                const diff = Math.abs(indicators.length - slidesCMS.length)
                if (diff) {
                    // eslint-disable-next-line no-plusplus
                    for (let i = 1; i <= diff; i++) {
                        indicators[indicators.length - i].remove()
                    }
                }
            }
        }
        const sortedSlides = sortBy(slidesCMS, 'order', 'id')
        if (sortedSlides.length) {
            return sortedSlides.map((i, index) => (
                this.getSlide(i.src, index)
            ))
        }
        return slides.map((i, index) => (
            this.getSlide(i.src, index)
        ))
    }

    render() {
        const { home } = this.props

        return (
            <div
                id="slider"
                className="slider fullscreen"
                style={{ padding: '0', borderRight: home ? '1px solid #057DCC' : '' }}
            >
                <ul className="slides">
                    {this.getSlidesHome()}
                </ul>
            </div>
        )
    }
}

HomeSlide.propTypes = {
    home: PropTypes.bool,
    fetchCmsSlider: PropTypes.func,
    fetchCmsSliderSteps: PropTypes.func,
    cmsSlider: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
    cmsSliderSteps: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
}

HomeSlide.defaultProps = {
    home: false,
}

const mapStateToProps = (store) => ({
    cmsSlider: store.HomeReducer.cmsSlider,
    cmsSliderSteps: store.HomeReducer.cmsSliderSteps,
})

const mapDispatchToProps = {
    fetchCmsSlider: HomeAction.fetchCmsSlider,
    fetchCmsSliderSteps: HomeAction.fetchCmsSliderSteps,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlide)
