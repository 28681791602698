import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { hasValue } from '../../utils/NumberUtil'

class DisplayedValue extends Component {
    getValueDisplayedBelow = () => {
        const values = this.props.value && this.props.value.split ? (
            this.props.value.split('\n').map((v) => <h6>{v}</h6>)
        ) : (
            <h6>{this.props.value}</h6>
        )
        return (
            <div>
                <h6 className="bold">{this.props.label ? `${this.props.label} :` : null}</h6>
                {values}
            </div>
        )
    }

    getValue = () => (
        <h6>
            <span className="bold">{this.props.label ? [this.props.label, ' : '] : null}</span>
            {hasValue(this.props.value) ? (
                this.props.value
            ) : (
                <span className="padding-left-6" />
            )}
        </h6>
    )

    onClick = () => {
        if (this.props.tableEditable) {
            this.props.onClick()
        }
    }

    render() {
        if (this.props.hideNull && !hasValue(this.props.value)) {
            return null
        }
        return (
            <div className="row no-margin" onClick={this.onClick}>
                {this.props.displayValueBelow ? this.getValueDisplayedBelow() : this.getValue()}
                {/* <h6 className='bold'>{ this.props.label + ' :' }</h6> */}
                {/* { values } */}
                {/* <h6 ><span className='bold'>{ this.props.label + ' : ' }</span>{ this.props.value }</h6> */}
            </div>
        )
    }
}

DisplayedValue.propTypes = {
    label: PropTypes.string,
    value: PropTypes.object,
    hideNull: PropTypes.bool,
    displayValueBelow: PropTypes.bool,
    tableEditable: PropTypes.bool,
    onClick: PropTypes.func,
}

export default DisplayedValue
