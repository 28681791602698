import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { filter } from 'lodash'
import i18n from 'simple-react-i18n'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { getDate } from '../../utils/DateUtil'
import { DATE } from './KeyFigureConstants'
import { getLongNumber, getNumberFormat } from '../../utils/NumberUtil'

class KeyFigurePanel extends Component {
    getContent = () => (
        <Grid container direction="column" justify="center" alignItems="center">
            {this.getRows()}
        </Grid>
    )

    getRows = () => {
        const filterValue = this.props.showAllValues
            ? this.props.data
            : filter(this.props.data, (o) => o.value && o.value != '0')
        return filterValue.map((data) => {
            const value = (() => {
                if (data.typeValue) {
                    if (data.typeValue === DATE) {
                        return [getDate(data.value), getDate(data.value)]
                    }
                    return [getLongNumber(data.value), getNumberFormat(data.value)]
                }
                return [data.value, data.value]
            })()
            const title = (() => {
                try {
                    return i18n[data.title]
                } catch (e) {
                    return data.title
                }
            })()

            return (
                <>
                    <p className="white-text" style={{ fontSize: '1rem', margin: '10px 0 0' }}>
                        {title}&nbsp;:&nbsp;
                    </p>
                    <p className="keyFigurePanelH5 white-text align_center">{value[0]}</p>
                </>
            )
        })
    }

    render() {
        return (
            <Card className="shadow">
                <div
                    className="card-content right-align"
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#057DCC',
                    }}
                >
                    <div className="row no-margin">{this.getContent()}</div>
                </div>
            </Card>
        )
    }
}

KeyFigurePanel.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            typeValue: PropTypes.string,
        }),
    ),
    showAllValues: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    singleLine: PropTypes.bool,
}
KeyFigurePanel.defaultProps = {
    singleLine: false,
}
export default KeyFigurePanel
