import React, { Component } from 'react'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import AppStore from '../../store/Appstore'

const { $ } = window

export default class PopupContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: '',
            selectedStation: {},
        }
    }

    getDefaultProps() {
        return {
            layers: [],
            features: [],
        }
    }

    getFeatureProxy(feature) {
        const handler = {
            get(target, name) {
                // You must use .get to access to props of feature.
                if (name === 'id') {
                    return target.getId()
                }
                return target.get(name)
            },
        }
        return new Proxy(feature, handler)
    }

    componentWillMount() {
        const feature = this.getFeatureProxy(this.props.features[0])
        const lay = this.props.layers.find((la) => la.checkUuid(this.props.features[0].getId()))
        this.setState({ selected: lay.getPopup().content(lay, feature), selectedStation: lay.getPopup().station(lay, feature) })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.features) {
            if (this.props.features.length > 1) {
                this.props.features.forEach((element) => {
                    this[`couche${element.getId()}`].removeEventListener('click', this.handlerListener(element))
                })
            }
            if (nextProps.layers.filter((l) => !!l).length !== 0) {
                const lay = nextProps.layers.find((la) => la.checkUuid(nextProps.features[0].getId()))
                const feature = this.getFeatureProxy(nextProps.features[0])
                this.setState({ selected: lay.getPopup().content(lay, feature), selectedStation: lay.getPopup().station(lay, feature) })
            }
        }
    }

    componentDidMount() {
        this.createListener()
        $('.popupStation')[0].addEventListener('click', () => AppStore.dispatch(push(`/station/${this.state.selectedStation.typeName}/${this.state.selectedStation.id}`)))
    }

    componentDidUpdate(prevProps) {
        // no props check create too much listener
        if (prevProps.features !== this.props.features) {
            this.createListener()
        }
    }

    createListener() {
        if (this.props.features.length > 1) {
            this.props.features.forEach((element) => {
                // React can't trigger click on the map. You must use EventListener, after the DOM render.
                this[`couche${element.getId()}`].removeEventListener('click', this.handlerListener(element))
                this[`couche${element.getId()}`].addEventListener('click', this.handlerListener(element))
            })
            $('.popupStation')[0].removeEventListener('click', () => AppStore.dispatch(push(`/station/${this.state.selectedStation.typeName}/${this.state.selectedStation.id}`)))
            $('.popupStation')[0].addEventListener('click', () => AppStore.dispatch(push(`/station/${this.state.selectedStation.typeName}/${this.state.selectedStation.id}`)))
        }
    }

    handlerListener(element) {
        return () => {
            const feature = this.getFeatureProxy(element)
            const lay = this.props.layers.find((la) => la.checkUuid(element.getId()))
            return this.setState({ selected: lay.getPopup().content(lay, feature), selectedStation: lay.getPopup().station(lay, feature) })
        }
    }

    getContent() {
        // creation of childs components is too hard because you must use eventlistener over 2 components..
        const list = this.props.features.map((element) => {
            const lay = this.props.layers.filter((l) => !!l).find((la) => la.checkUuid(element.getId()))
            const feature = this.getFeatureProxy(element)
            return lay ? (
                <li key={element.getId()}>
                    <a className="truncate clickable blue-text" ref={(el) => { this[`couche${element.getId()}`] = el }}>
                        {lay.getPopup().name(lay, feature)}
                    </a>
                </li>
            ) : null
        }).filter((elem) => !!elem)

        return list.length > 1 ? (
            <div className="row no-margin">
                <div className="col s4 border-right">
                    <ul id="menu">
                        {list}
                    </ul>
                </div>
                <div className="col s8 popupStation">
                    {this.state.selected}
                </div>
            </div>
        ) : (
            <div className="row no-margin">
                <div className="col s12 popupStation">
                    {this.state.selected}
                </div>
            </div>
        )
    }

    render() {
        const content = this.getContent()
        return (
            <div id="popover-content">{content}</div>
        )
    }
}

PopupContent.propTypes = ({
    layers: PropTypes.arrayOf(PropTypes.object),
    features: PropTypes.arrayOf(PropTypes.object),
})
