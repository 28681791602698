import 'babel-polyfill'
import PropTypes from 'prop-types'
import { every, some, without } from 'lodash'

const { $ } = window

const arrayOf = (dto) => PropTypes.arrayOf(PropTypes.instanceOf(dto))

const instanceOf = (dto) => PropTypes.instanceOf(dto)

const createIndex = (tab, keyToUse) => {
    const res = {}
    if (keyToUse) {
        if (tab.length) {
            tab.forEach((o) => {
                res[o[keyToUse]] = o
            })
            return res
        }
        return {}
    }
    if (tab.length) {
        tab.forEach((o) => {
            res[o.id] = o
        })
        return res
    }
    return {}
}

const whenReceiveProps = (props, nextProps, tab) => {
    if (some(tab, (propName) => !props[propName])) {
        return false
    }
    if (!tab.length) {
        return props[tab].length
            ? nextProps[tab].length !== 0 && props[tab].length === 0
            : nextProps[tab].id && !props[tab].id
    }
    const boolTable = tab.map((propName) => {
        const otherProps = without(tab, propName)
        if (props[propName].length === 0 || props[propName].length) {
            return (
                nextProps[propName].length !== 0
                && props[propName].length === 0
                && every(
                    otherProps.map((name) => nextProps[name].length !== 0),
                    (bool) => bool === true,
                )
            )
        }
        return (
            nextProps[propName].id
            && !props[propName].id
            && every(
                otherProps.map((name) => nextProps[name].id),
                (bool) => bool === true,
            )
        )
    })
    return some(boolTable, (bool) => bool === true)
}

const whenPropsAreReceived = (props, nextProps, tab) => {
    if (!props.receivedProps) {
        console.error('cannot access receivedProps')
        return false
    }
    return (
        !every(tab, (propName) => props.receivedProps.includes(propName))
        && every(tab, (propName) => nextProps.receivedProps.includes(propName))
    )
}

const getLabel = (
    propList,
    code,
    labelName,
    key = 'code',
    defaultReturn = '',
) => {
    // eslint-disable-next-line eqeqeq
    const found = propList.find((elem) => elem[key] == code)
    if (found) {
        if (labelName && found[labelName]) {
            return found[labelName]
        }
        if (found.name) {
            return found.name
        }
        if (found.label) {
            return found.label
        }
        if (found.title) {
            return found.title
        }
    }
    return defaultReturn
}

const filterHeaders = (headers, filter) => headers.filter((h) => !filter.includes(h))

const getComponentWithId = (id) => {
    const dom = $(id)[0]
    if (!dom) {
        return null
    }
    const internalInstance = dom[
        Object.keys(dom).find((key) => key.startsWith('__reactInternalInstance$'))
    ]
    if (!internalInstance) {
        return null
    }
    return internalInstance._currentElement._owner._instance
}

const deleteKeys = (obj, tab = []) => Object.keys(obj)
    .filter((k) => !tab.find((o) => o === k))
    .reduce((acc, val) => {
        const r = {}
        r[val] = obj[val]
        return { ...acc, ...r }
    }, {})

const removeNullKeys = (obj) => Object.keys(obj)
    .filter((k) => !(obj[k] === undefined || obj[k] === null))
    .reduce((acc, key) => {
        const r = {}
        r[key] = obj[key]
        return { ...acc, ...r }
    }, {})

const getIndexObject = (tab, keyToUse) => {
    if (keyToUse) {
        return Reflect.apply(
            Object.assign,
            null,
            tab.map((o) => ({ [o[keyToUse]]: o })),
        )
    }
    return Reflect.apply(
        Object.assign,
        null,
        tab.map((o) => ({ [o.id]: o })),
    )
}

const getSandreList = (sandreCodes, field) => sandreCodes.filter((c) => c.field === field)

export {
    deleteKeys,
    arrayOf,
    instanceOf,
    whenReceiveProps,
    getLabel,
    filterHeaders,
    getComponentWithId,
    whenPropsAreReceived,
    getSandreList,
    removeNullKeys,
    getIndexObject,
    createIndex,
}
