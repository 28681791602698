export default class DtoJobParameters {
    constructor(obj = {}) {
        this.routingKey = obj.routingKey
        this.jobType = obj.jobType
        this.message = obj.message
        this.path = obj.path
        this.pathType = obj.pathType
        this.propertiesFile = obj.propertiesFile
        this.parameters = obj.parameters || []
        this.filters = obj.filters || []
        this.dataTypes = obj.dataTypes || []
        this.emails = obj.emails
        this.nextJobId = obj.nextJobId
    }
}
