import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'

class TablePagination extends Component {
    onClick = (value) => {
        this.props.onChangePage(value)
    }

    getRange = (nbPages) => {
        if (nbPages < 5) {
            return range(1, nbPages + 1)
        } if (this.props.index + (this.props.displayPage - 1) >= nbPages) {
            return range(nbPages - (this.props.displayPage - 1), nbPages + 1)
        }
        if (this.props.index > (this.props.displayPage)) {
            return range(this.props.index - (this.props.displayPage - 3), this.props.index + 1)
        }
        return range(1, this.props.displayPage + 1)
    }

    render() {
        const style = { marginRight: '0.5rem' }
        const nbPages = Math.ceil(this.props.nbElement / this.props.nbElementPerPage)
        if (nbPages != 1) {
            const pagination = (() => {
                if (this.props.nbElementPerPage !== -1) {
                    return this.getRange(nbPages).map((idx) => {
                        if (this.props.index === idx) {
                            return (
                                <a
                                    className="btn waves-effect waves-light disabled"
                                    style={style}
                                    aria-controls="tableStationQualito"
                                    disabled="disabled"
                                    key={idx}
                                >{ idx }
                                </a>
                            )
                        }
                        if (this.props.smallPaging) {
                            return null
                        }
                        return (
                            <a
                                className="btn waves-effect waves-light"
                                style={style}
                                aria-controls="tableStationQualito"
                                onClick={() => this.onClick(idx)}
                                key={idx}
                            >{ idx }
                            </a>
                        )
                    })
                }
                return [<a
                    className="btn waves-effect waves-light disabled"
                    style={style}
                    aria-controls="tableStationQualito"
                    disabled="disabled"
                    key={1}
                >1
                </a>]
            })()

            const previous = (() => {
                if (nbPages < this.props.displayPage + 1) {
                    return null
                }
                if (this.props.index === 1) {
                    return (
                        <a
                            className="btn previous disabled"
                            style={style}
                            id="tableStationQualito_previous"
                            aria-controls="tableStationQualito"
                            disabled
                        >&lt;
                        </a>
                    )
                }
                return (
                    <a
                        className="btn previous"
                        style={style}
                        id="tableStationQualito_previous"
                        aria-controls="tableStationQualito"
                        onClick={() => this.onClick(this.props.index - 1)}
                    >&lt;
                    </a>
                )
            })()
            const next = (() => {
                if (nbPages < this.props.displayPage + 1) {
                    return null
                }
                if (this.props.index === nbPages) {
                    return (
                        <a
                            className="btn next disabled"
                            style={style}
                            id="tableStationQualito_next"
                            aria-controls="tableStationQualito"
                            disabled
                        >&gt;
                        </a>
                    )
                }
                return (
                    <a
                        className="btn next"
                        style={style}
                        id="tableStationQualito_next"
                        aria-controls="tableStationQualito"
                        onClick={() => this.onClick(this.props.index + 1)}
                    >&gt;
                    </a>
                )
            })()
            const threeDotsNext = (() => {
                if (nbPages != 1 && nbPages > this.props.displayPage && this.props.index !== nbPages && this.props.index + this.props.displayPage - 1 < nbPages) {
                    return [<a className="btn btn-flat no-hover" style={style}>...</a>,
                        <a
                            className="btn waves-effect waves-light"
                            style={style}
                            aria-controls="tableStationQualito"
                            onClick={() => this.onClick(nbPages)}
                        >{ nbPages }
                        </a>]
                }
                return null
            })()
            const threeDotsPrevious = (() => {
                if (nbPages != 1 && this.props.index > this.props.displayPage) {
                    return [<a
                        className="btn waves-effect waves-light"
                        style={style}
                        aria-controls="tableStationQualito"
                        onClick={() => this.onClick(1)}
                    >{ 1 }
                    </a>,
                        <a className="btn btn-flat no-hover" style={style}>...</a>]
                }
                return null
            })()

            return (
                <div className="input-field dataTables_paginate paging_simple_numbers no-padding-top margin-bottom-1">
                    <div className="pagination">
                        { previous }
                        { this.props.smallPaging ? null : threeDotsPrevious }
                        { pagination }
                        { this.props.smallPaging ? null : threeDotsNext }
                        { next }
                    </div>
                </div>
            )
        }
        return null
    }
}

TablePagination.propTypes = {
    index: PropTypes.number.isRequired,
    nbElement: PropTypes.number.isRequired,
    nbElementPerPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    displayPage: PropTypes.number,
    smallPaging: PropTypes.bool,
}

TablePagination.defaultProps = {
    displayPage: 5,
}

export default TablePagination
