import React from 'react'
import { connect } from 'react-redux'
import { compact, orderBy } from 'lodash'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import ActionComponent from '../../components/actions/ActionComponent'
import ReferencialAction from '../referencial/action/ReferencialAction'
import TerritoryAction from './actions/TerritoryAction'
import AgGridTable from '../../components/datatable/AgGridTable'
import { getUser } from '../../utils/SettingUtils'
import { sortById } from '../../utils/ArrayUtils'
import HomeAction from '../home/actions/HomeAction'
import ProgressBar from '../../components/ProgressBar'

const CardStyle = styled(Card)`
    padding: 2rem;
`

class Assolement extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            rowDataZones: [],
            columnDefsAssolements: [
                {
                    headerName: i18n.zones,
                    field: 'zones',
                },
                {
                    headerName: i18n.cultures,
                    field: 'cultures',
                },
                {
                    headerName: 'Surface totale',
                    field: 'surfaceT',
                },
            ],
            assolements: [],
            zonesSurfaces: [],
            result: [],
            rowDataAssolements: [],
            rowNames: [],
            surfacesLoaded: false,
            assolementsLoaded: false,
            isEditMode: false,
            averageRPGLoaded: false,
        }
    }

    componentDidMount() {
        const { isEditMode } = this.state
        const { territory, applicationSettings, loadingAverageRPG } = this.props
        if (isEditMode) {
            this.setEditModeAssolement()
        } else {
            this.setReadModeAssolement()
        }
        this.setColumnDefsZones(isEditMode)
        this.setColumnDefs(territory, isEditMode)
        this.fetchSetSurfaceData()
        this.props.setHelpPath('creer-un-territoire/renseigner-lassolement/', applicationSettings)
        if (!loadingAverageRPG) {
            this.props.fetchAverageRPGTerritory(territory.id).then(() => this.setState({ averageRPGLoaded: true }))
        }
    }

    fetchSetSurfaceData = () => {
        this.props.fetchZonesSurfacesTerritoires(this.props.territory.id).then(() => {
            this.props.fetchAssolementsTerritoires(this.props.territory.id).then(() => {
                this.checkDatas()
                this.setColumnDefsZones(this.state.isEditMode)
                this.setDataZones(this.props.territory)
                this.setState({ zonesSurfaces: this.props.zonesSurfaces, surfacesLoaded: true })
                this.setColumnDefs(this.props.territory, this.state.isEditMode)
                this.setDataAssolements(this.props.territory)
                this.setState({ assolements: this.props.assolements, assolementsLoaded: true })
            })
        })
    }

    checkDatas = () => {
        const { assolements, zonesSurfaces } = this.props
        let valide = true
        let result = []
        if (zonesSurfaces.length === 0) {
            valide = false
            result = assolements.map((a) => {
                if (a.surface) {
                    return { id: a.zid, valide: false }
                }
                return { id: a.zid, valide: true }
            })
        } else {
            result = zonesSurfaces.map((zone) => {
                const filteredData = assolements.filter((a) => zone.zid === a.zid)
                let total = 0
                filteredData.forEach((d) => {
                    total += d.surface
                })
                if (zone.surface === total) {
                    return { id: zone.zid, valide: true }
                }
                valide = false
                return { id: zone.zid, valide: false }
            })
        }
        this.props.checkAssolement(valide)
        this.setState({ result })
    }

    setEditModeAssolement = () => {
        if (this.props.haveMatrices) {
            this.props.modalUpdateIsOpen()
        } else {
            this.setState({ isEditMode: true })
            this.props.toggleEditMode(true)
            this.setColumnDefsZones(true)
            this.setColumnDefs(this.props.territory, true)
            this.setActions({
                save: () => {
                    this.onSaveEvent()
                    this.setReadModeAssolement()
                },
                cancel: () => {
                    this.fetchSetSurfaceData()
                    this.setReadModeAssolement()
                },
            })
        }
    }

    setReadModeAssolement = () => {
        this.setState({ isEditMode: false })
        this.props.toggleEditMode(false)
        this.setColumnDefsZones(false)
        this.setColumnDefs(this.props.territory, false)
        const { statut } = this.props.territory
        const isAdmin = getUser().isAdmin === '1'
        const anim = getUser().labo === '1'
        if (this.props.territory.referencial) {
            if (isAdmin) {
                this.setActions({
                    edit: () => this.setEditModeDescriptif(),
                    duplicate: () => {
                        this.props.onDuplicateEvent()
                    },
                    deleteCheck: () => this.props.modalIsOpen(),
                })
            } else if (anim) {
                this.setActions({
                    duplicate: () => {
                        this.props.onDuplicateEvent()
                    },
                })
            }
        } else if ((statut === 0 || statut === 1) && (isAdmin || anim)) {
            this.setActions({
                edit: () => this.setEditModeAssolement(),
                duplicate: () => {
                    this.props.onDuplicateEvent()
                },
                deleteCheck: () => this.props.modalIsOpen(),
            })
        } else if (statut === 2) {
            if (isAdmin || anim) {
                this.setActions({
                    deleteCheck: () => this.props.modalIsOpen(),
                })
            }
        }
    }

    setColumnDefsZones(isEditMode, result = this.state.result) {
        const { territory, zones } = this.props
        const comp = this
        this.setState({
            columnDefsZones: [
                {
                    headerName: i18n.zones,
                    field: 'zones',
                },
                {
                    headerName: 'Surface totale (ha)',
                    field: 'surfaceT',
                    editable: isEditMode,
                    cellEditor: 'numericEditor',
                    cellStyle: (params) => {
                        if (params) {
                            const id = comp.getId(territory.zones, zones, params.data.zones)
                            if (result) {
                                const isValide = result.find((r) => r.id === id)
                                if (isValide && !isValide.valide) {
                                    return { backgroundColor: 'lightcoral' }
                                }
                                return null
                            }
                            return null
                        } 
                        return null
                    },
                },
            ],
        })
    }

    setColumnDefs(territoire, isEditMode) {
        const comp = this
        if (territoire) {
            const allIds = territoire.modesConduites.map((mc) => mc.dataId)
            this.props.fetchAllConduites(allIds).then((conduites) => {
                conduites.sort(sortById)
                const { columnDefsAssolements, rowNames } = conduites.reduce(
                    (acc, conduite) => {
                        acc.columnDefsAssolements.push({
                            headerName: conduite.name,
                            field: conduite.name,
                            editable: isEditMode,
                            cellEditor: 'numericEditor',
                        })
                        acc.rowNames.push(conduite.name)
                        return acc
                    },
                    {
                        columnDefsAssolements: [
                            {
                                headerName: i18n.zones,
                                field: 'zones',
                            },
                            {
                                headerName: i18n.cultures,
                                field: 'cultures',
                            },
                            {
                                headerName: 'Surface totale',
                                field: 'surfaceT',
                                cellStyle(params) {
                                    if (params) {
                                        const id = comp.getId(
                                            territoire.zones,
                                            comp.props.zones,
                                            params.data.zones,
                                        )
                                        const { result } = comp.state
                                        const isValide = result.find((r) => r.id === id)
                                        if (isValide && !isValide.valide) {
                                            return { backgroundColor: 'lightcoral' }
                                        }
                                        return null
                                    } 
                                    return null
                                },
                            },
                        ],
                        rowNames: [...this.state.rowNames],
                    },
                )
                this.setState({ columnDefsAssolements, rowNames })
                this.setDataAssolements(territoire)
            })
        }
    }

    setDataAssolements(territoire) {
        const arr = []
        if (territoire.zones) {
            territoire.zones.map((z) => {
                territoire.cultures.forEach((c) => {
                    const row = {
                        zones: this.getDataName(this.props.zones, z.dataId),
                        cultures: this.getDataName(this.props.cultures, c.dataId),
                        surfaceT: 0,
                    }
                    territoire.modesConduites.forEach((mc) => {
                        const surface = this.getAssolement(z.dataId, c.dataId, mc.dataId)
                        if (surface) {
                            const key = this.getDataName(this.props.conduites, mc.dataId)
                            row[key] = surface
                            row.surfaceT += surface
                        }
                    })
                    arr.push(row)
                })
            })
            this.setState({ rowDataAssolements: arr })
        }
    }

    getAssolement(zid, cid, mcid) {
        const assolement = this.props.assolements.find(
            (a) => a.zid === zid && a.cid === cid && a.mcid === mcid,
        )
        return assolement ? parseInt(assolement.surface) : null
    }

    getDataName(props, id) {
        const data = props.find((p) => p.id === id)
        if (data) {
            return data.name
        }
        return ''
    }

    setDataZones(territoire) {
        const arr = []
        if (territoire.zones) {
            territoire.zones.forEach((z) => {
                const zoneSurface = this.props.zonesSurfaces.find((s) => s.zid === z.dataId)
                arr.push({
                    zones: this.getDataName(this.props.zones, z.dataId),
                    surfaceT: zoneSurface ? zoneSurface.surface : 0,
                })
            })
        }
        this.setState({ rowDataZones: arr, surfacesLoaded: true })
    }

    onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            const file = event.target.files[0]
            const name = file.name.replace(/ /g, '_')
            reader.onload = (e) => {
                const tid = this.props.territory.id
                this.setState({ assolementsLoaded: false })
                this.props
                    .assolementUploadExcel({
                        tid,
                        fileName: name.toUpperCase(),
                        fileContent: e.target.result,
                    })
                    .then(() => {
                        this.props.fetchAssolementsTerritoires(tid).then(() => {
                            this.setState({ assolements: this.props.assolements })
                            this.setDataAssolements(this.props.territory)
                            this.setState({ assolementsLoaded: true })
                        })
                    })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    setZonesSurfaces = (surfaces) => {
        const filteredZones = []
        this.props.zones.forEach((zoneProps) => {
            this.props.territory.zones.forEach((z) => {
                if (z.dataId === zoneProps.id) {
                    filteredZones.push(zoneProps)
                }
            })
        })
        const formattedZonesSurfaces = []
        surfaces.forEach((s) => {
            filteredZones.forEach((zone) => {
                if (s.zones === zone.name) {
                    formattedZonesSurfaces.push({
                        tid: this.props.territory.id,
                        zid: zone.id,
                        surface: parseFloat(s.surfaceT),
                    })
                }
            })
        })
        this.setState({ zonesSurfaces: formattedZonesSurfaces })
    }

    getId = (arrayWithId, arrayWithName, name) => {
        const dataFound = []
        arrayWithName.forEach((c) => {
            arrayWithId.forEach((tc) => {
                if (c.id === tc.dataId) {
                    dataFound.push({
                        id: c.id,
                        name: c.name,
                    })
                }
            })
        })
        const data = dataFound.find((mc) => mc.name === name)
        return data ? data.id : null
    }

    setAssolements = (assolements) => {
        const { territory, zones, cultures, conduites } = this.props
        const formattedAssolements = compact(assolements.map((a) => {
            const mcName = Object.keys(a)
            if (mcName.length > 3) {
                for (let i = 3; i < mcName.length; i++) {
                    if (parseFloat(a[mcName[i]])) {
                        return {
                            tid: territory.id,
                            zid: this.getId(territory.zones, zones, a.zones),
                            cid: this.getId(territory.cultures, cultures, a.cultures),
                            mcid: this.getId(territory.modesConduites, conduites, mcName[i]),
                            surface: parseFloat(a[mcName[i]]),
                        }
                    }
                    return null
                }
            }
            return null
        }))
        this.setState({ assolements: formattedAssolements })
    }

    onExportFile = () => {
        this.props.assolementExportExcel(this.props.territory.id)
    }

    onSaveEvent = () => {
        const { zonesSurfaces, assolements } = this.state
        this.setState({ surfacesLoaded: false, assolementsLoaded: false })
        const tid = this.props.territory.id
        this.props
            .setZonesSurfaces(zonesSurfaces, tid)
            .then(() => this.props.setAssolements(assolements, tid))
            .then(() => this.fetchSetSurfaceData())
    }

    render() {
        const { columnDefsZones, rowDataZones, columnDefsAssolements, rowDataAssolements, surfacesLoaded, assolementsLoaded, averageRPGLoaded } = this.state
        const { territory, averageRPGData } = this.props

        return (
            <CardStyle>
                {surfacesLoaded && assolementsLoaded ? (
                    <div
                        className="ag-theme-balham"
                        style={{
                            width: '80vw',
                            marginBottom: 50,
                        }}
                    >
                        <AgGridTable
                            columnDefs={columnDefsZones}
                            rowData={rowDataZones}
                            getRowData={this.setZonesSurfaces}
                            pagination={false}
                            isEditMode={this.state.isEditMode}
                            domLayout
                        />
                        <AgGridTable
                            columnDefs={columnDefsAssolements}
                            rowData={rowDataAssolements}
                            getRowData={this.setAssolements}
                            pagination={false}
                            importableXLSX
                            onUploadFileXLSX={this.onUploadFile}
                            isEditMode={this.state.isEditMode}
                            onExportFile={this.onExportFile}
                            domLayout
                        />
                        {!averageRPGLoaded && <ProgressBar />}
                        {averageRPGLoaded && averageRPGData?.surfacesAverage?.length > 0 && averageRPGData?.id === territory.id && (
                            <div className="ag-theme-balham" style={{ height: 470 }}>
                                <AgGridTable
                                    columnDefs={[
                                        {
                                            headerName: i18n.cultures,
                                            field: 'culture',
                                        },
                                        {
                                            headerName: i18n.zones,
                                            field: 'zoneLabel',
                                        },
                                        {
                                            headerName: `Assolement moyen (ha) sur les 5 dernières années${averageRPGData.nbYears === 5 ? '' : ` (${averageRPGData.nbYears} année(s) de données)`}`,
                                            field: 'surface',
                                        },
                                    ]}
                                    rowData={orderBy(averageRPGData.surfacesAverage, ['surface', 'culture', 'zones'], ['desc', 'asc', 'asc'])}
                                    fullHeaders
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <ProgressBar />
                )}
            </CardStyle>
        )
    }
}

const mapStateToProps = (store) => ({
    users: store.UserReducer.users,
    assolements: store.TerritoryReducer.assolements,
    zonesSurfaces: store.TerritoryReducer.zonesSurfaces,
    averageRPGData: store.TerritoryReducer.averageRPGData,
    loadingAverageRPG: store.TerritoryReducer.loadingAverageRPG,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    setHelpPath: HomeAction.setHelpPath,
    fetchAllConduites: ReferencialAction.fetchAllConduites,
    assolementUploadExcel: TerritoryAction.assolementUploadExcel,
    assolementExportExcel: TerritoryAction.assolementExportExcel,
    fetchAssolementsTerritoires: TerritoryAction.fetchAssolementsTerritoires,
    fetchZonesSurfacesTerritoires: TerritoryAction.fetchZonesSurfacesTerritoires,
    fetchAverageRPGTerritory: TerritoryAction.fetchAverageRPGTerritory,
    setZonesSurfaces: TerritoryAction.setZonesSurfaces,
    setAssolements: TerritoryAction.setAssolements,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Assolement)
