/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { compact, orderBy, flattenDeep } from 'lodash'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import ToastrAction from '../../../utils/ToastrAction'
import TerritoryAction from '../actions/TerritoryAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import HomeAction from '../../home/actions/HomeAction'
import AgGridTable from '../../../components/datatable/AgGridTable'
import ActionComponent from '../../../components/actions/ActionComponent'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { PATH_TERRITORY_DETAIL } from '../../home/constants/RouteConstants'
import ProgressBar from '../../../components/ProgressBar'
import Input from '../../../components/forms/Input'
import UpdatePanel from '../../../components/UpdatePanel'
import ModalWarningDeleteMatrice from './ModalWarningDeleteMatrice'
import ModalIndicator from '../ModalIndicator'
import { grey } from '../../../styles/theme'
import { GreenButton } from '../../../styles/button'
import JobAction from '../../../utils/import/actions/JobAction'
import DashboardAction from '../../referencial/components/dashboard/actions/DashboardAction'

const TextStyle = styled.div`
    margin: 2rem 0;
    font-size: 1.6rem;
    text-decoration: none;
    color: ${grey};
    &:hover {
        color: black;
    }
`

const IconStyle = styled(Icon)`
    font-size: 3rem !important;
    color: ${grey};
    opacity: 0.6;
    margin: 23px 15px 0 0 !important;
    &:hover {
        opacity: 1;
    }
`

const Label = styled.div`
    width: 95%;
    margin: 8px 10px 5px;
`
const LabelName = styled.div`
    width: 95%;
    margin: 8px 10px 0px;
`

const numberSort = (num1 = 0, num2 = 0) => {
    if (Number.isNaN(parseFloat(num1)) || Number.isNaN(parseFloat(num2))) {
        return false
    }
    return parseFloat(num1) - parseFloat(num2)
}

class Matrice extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            rowData: [],
            initialData: [],
            rowNames: [],
            dataLoaded: false,
            currentIndicateurs: [],
            matrice: {},
            initialMatrice: {},
            editable: !!props.newMatrice,
            zonesLink: [],
            culturesLink: [],
            conduitesLink: [],
            openDelete: false,
            openIndicators: false,
            noKML: true,
        }
    }

    componentDidMount() {
        const { mid, communes } = this.props
        this.props.fetchIndicateursMatrices(mid)
        this.props.fetchMatriceRefCount()
        if (!communes.length) {
            this.props.fetchCommunes()
        }
        this.fetchMatriceData()
    }

    componentDidUpdate() {
        if (this.props.territoire.statut === 2) {
            this.setActions({})
        }
    }

    setInitialMatrice(matrice) {
        this.setState({
            initialMatrice: matrice,
            matrice,
        })
        this.setColumnDefs(matrice)
    }

    fetchMatriceData = () => {
        this.props.fetchMatrice(this.props.mid).then(() => {
            const { matrice, territoire } = this.props
            if (matrice.tid) {
                this.props.fetchElementaryBricks(matrice.tid).then(() => {
                    if (matrice.tid !== territoire.id) {
                        this.props.fetchTerritory(matrice.tid).then(() => this.setMatrice())
                    } else {
                        this.setMatrice()
                    }
                })
            }
        })
    }

    setMatrice = () => {
        const { zonesLink, culturesLink, conduitesLink, editable } = this.state
        const { territoire, zones, cultures, conduites, matrice } = this.props
        const arrZones = [
            ...zonesLink,
            ...territoire.zones.map((z) => {
                const zone = zones.find((p) => p.id === z.dataId)
                return {
                    id: z.dataId,
                    name: zone.name,
                    kml: zone.kml,
                }
            }),
        ]
        const arrCultures = [
            ...culturesLink,
            ...territoire.cultures.map((z) => ({
                id: z.dataId,
                name: this.getName(cultures, z.dataId),
            })),
        ]
        const arrConduites = [
            ...conduitesLink,
            ...territoire.modesConduites.map((z) => ({
                id: z.dataId,
                name: this.getName(conduites, z.dataId),
            })),
        ]
        this.setInitialMatrice(matrice)
        this.setState({
            zonesLink: arrZones,
            culturesLink: arrCultures,
            conduitesLink: arrConduites,
            dataLoaded: true,
            noKML: arrZones.some((z) => !z.kml),
            matrice,
        })
        this.props.toggleDataLoaded(true)
        if (editable) {
            this.setEditMode()
        } else {
            this.setReadMode()
        }
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
            {
                title: territoire.name,
                href: `territories/territory/${territoire.id}`,
            },
            {
                title: matrice.name,
                href: `territories/matrice/${matrice.mid}`,
            },
        ])
    }

    setEditMode = () => {
        const { newMatrice } = this.props
        this.props.toggleEditMode(true)
        this.setState({ editable: true })
        this.setColumnDefs(this.state.matrice)
        const actions = newMatrice ? {} : {
            cancel: () => {
                this.setState({ dataLoaded: false })
                this.props.fetchMatrice(this.state.matrice.mid).then(() => {
                    this.setState({ matrice: this.props.matrice })
                    this.setColumnDefs(this.state.matrice)
                    this.setState({ dataLoaded: true })
                    this.props.toggleDataLoaded(true)
                    this.setInitialMatrice(this.state.matrice)
                    this.props.setTitle([
                        {
                            title: i18n.territories,
                            href: 'territories',
                        },
                        {
                            title: this.props.territoire.name,
                            href: `territories/territory/${this.props.territoire.id}`,
                        },
                        {
                            title: this.state.matrice.name,
                            href: `territories/matrice/${this.state.matrice.mid}`,
                        },
                    ])
                    this.setReadMode()
                })
                this.setReadMode()
            },
        }
        this.setActions({
            save: () => {
                this.saveMatrice()
            },
            ...actions,
        })
    }

    setReadMode = () => {
        this.props.toggleEditMode(false)
        this.setState({ editable: false })
        this.setColumnDefs(this.state.matrice)
        if (
            this.props.territoire
            && this.props.territoire.statut !== 2
            && (getUser().isAdmin === '1'
                || (getUser().labo === '1' && !this.props.territoire.referencial))
        ) {
            this.setActions({
                edit: () => {
                    this.setEditMode()
                },
                duplicate: () => this.duplicateMatrice(this.state.matrice),
                deleteCheck: () => this.modalDeleteIsOpen(),
            })
        } else {
            this.setActions({})
        }
    }

    modalDeleteIsOpen = () => {
        this.setState({ openDelete: true })
    }

    modalDeleteIsClose = () => {
        this.setState({ openDelete: false })
    }

    deleteMatrice = () => {
        this.props.deleteMatrice(this.state.matrice.mid).then(() => {
            this.props.push({
                pathname: `${PATH_TERRITORY_DETAIL}/${this.props.territoire.id}`,
                state: { delete: true },
            })
        })
    }

    autoFillMatrice = () => {
        const { matrice } = this.state
        const { matriceRef } = this.props
        if (matriceRef.number) {
            this.setState({ dataLoaded: false })
            this.props.autoFillMatrice(matrice.mid).then(() => {
                this.fetchMatriceData()
            })
        } else {
            ToastrAction.info('La matrice de référence est vide, impossible de lancer le calcul')
        }
    }

    duplicateMatrice = (matrice) => {
        this.setState({ dataLoaded: false })
        this.props.toggleDataLoaded(false)
        const name = `${matrice.name}_dupliqué`
        const newMatrice = {
            ...matrice,
            mid: 0,
            name,
            favoris: false,
        }
        this.props.createMatrice(newMatrice).then(() => {
            this.fetchMatriceData()
        })
    }

    saveMatrice = () => {
        const { columnDefs, rowData, matrice, zonesLink, culturesLink, conduitesLink } = this.state
        this.setReadMode()
        const iids = columnDefs.filter((c) => c.editable)
        let valid = true
        // eslint-disable-next-line no-useless-escape
        const floatRegex = /^$|^[+-]?\d+(\,\d+)?$/
        const allRows = rowData.flatMap((r) => {
            const formatted = compact(iids.map((iid) => {
                const value = r[iid.field] || ''
                if (floatRegex.test(value)) {
                    return {
                        mid: matrice.mid,
                        iid: parseInt(iid.field),
                        zid: this.getId(zonesLink, r.zones),
                        cid: this.getId(culturesLink, r.cultures),
                        mcid: this.getId(conduitesLink, r.conduites),
                        value: parseFloat(value.replace(',', '.')),
                        generatedValue: r[`${iid.field}_generated`],
                    }
                } 
                valid = false
                return null
            }))
            return formatted
        })
        if (valid) {
            this.setState({ dataLoaded: false })
            this.props.toggleDataLoaded(false)
            this.props.updateMatrice(matrice.mid, matrice).then(() => {
                this.props.saveMatriceValues(matrice.mid, allRows).then(() => {
                    this.fetchMatriceData()
                    this.setColumnDefs(matrice)
                    this.setState({ dataLoaded: true })
                    this.props.toggleDataLoaded(true)
                    this.props.setTitle([
                        {
                            title: i18n.territories,
                            href: 'territories',
                        },
                        {
                            title: this.props.territoire.name,
                            href: `territories/territory/${this.props.territoire.id}`,
                        },
                        {
                            title: matrice.name,
                            href: `territories/matrice/${matrice.mid}`,
                        },
                    ])
                })
            })
        } else {
            ToastrAction.error(i18n.valueUnvalid)
        }
    }

    setColumnDefs(matrice) {
        if (matrice.indicateurs) {
            const allIds = matrice.indicateurs.map((i) => i.iid)
            this.props.fetchAllIndicateurs(allIds).then((indicateurs) => {
                const { columnDefs, rowNames } = orderBy(indicateurs, 'id').reduce(
                    (acc, indicateur) => {
                        acc.columnDefs.push({
                            headerName: indicateur.name,
                            field: indicateur.id.toString(),
                            editable: this.state.editable,
                            cellEditor: 'numericEditor',
                            headerTooltip: indicateur.name,
                            width: indicateur.name.length > 20 ? 160 : null,
                            comparator: numberSort,
                            cellStyle: (params) => ({
                                backgroundColor: params.data[`${params.colDef.field}_generated`] ? 'lightgreen' : 'none',
                            }),
                            valueSetter: (params) => {
                                params.data[indicateur.id] = params.newValue
                                params.data[`${indicateur.id}_generated`] = false
                                return true
                            },
                        })
                        acc.rowNames.push({
                            id: indicateur.id,
                            name: indicateur.name,
                        })
                        return acc
                    },
                    {
                        columnDefs: [
                            {
                                headerName: i18n.zones,
                                field: 'zones',
                                headerTooltip: i18n.zones,
                            },
                            {
                                headerName: i18n.cultures,
                                field: 'cultures',
                                headerTooltip: i18n.cultures,
                            },
                            {
                                headerName: i18n.conduites,
                                field: 'conduites',
                                headerTooltip: i18n.conduites,
                            },
                        ],
                        rowNames: [],
                    },
                )
                this.setState({ columnDefs, rowNames })
                this.props.setIndicateurs(rowNames)
            })
            this.setData()
        }
    }

    getName(array, id) {
        const parent = array.find((p) => p.id === id)
        return parent.name
    }

    getId(array, name) {
        const parent = array.find((p) => p.name === name)
        return parent.id
    }

    setData() {
        const { territoire, zones, conduites, cultures } = this.props
        const arr = (() => {
            if (territoire.zones) {
                const zonesTerritoire = orderBy(territoire.zones, 'id')
                const culturesTerritoire = orderBy(territoire.cultures, 'id')
                const conduitesTerritoire = orderBy(territoire.modesConduites, 'id')
                return flattenDeep(zonesTerritoire.map((z) => culturesTerritoire.map((c) => conduitesTerritoire.map((mc) => {
                    let row = {}
                    this.state.matrice.indicateursValues.forEach((v) => {
                        if (v.zid === z.dataId && v.cid === c.dataId && v.mcid === mc.dataId) {
                            row = {
                                ...row,
                                [v.iid]: v.value === 0 || v.value ? v.value.toString().replace('.', ',') : '',
                                [`${v.iid}_generated`]: v.generatedValue,
                            }
                        }
                    })
                    return {
                        zones: this.getName(zones, z.dataId),
                        cultures: this.getName(cultures, c.dataId),
                        conduites: this.getName(conduites, mc.dataId),
                        ...row,
                    }
                }))))
            }
            return []
        })()
        this.setState({ rowData: arr })
    }

    onChangeSelected = (key, value) => {
        this.setState({
            currentIndicateurs: value,
        })
    }

    onSave = (key, value) => {
        const indicateurs = value.map((v) => ({
            mid: this.state.matrice.mid,
            iid: v,
        }))
        const matrice = { ...this.state.matrice, indicateurs }
        this.setState({ matrice })
        this.setColumnDefs(matrice)
    }

    onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            const file = event.target.files[0]
            const name = file.name.replace(/ /g, '_')
            reader.onload = (e) => {
                this.setState({ dataLoaded: false })
                this.props.toggleDataLoaded(false)
                this.props
                    .matriceUploadExcel(this.state.matrice.mid, {
                        mid: this.state.matrice.mid,
                        fileName: name.toUpperCase(),
                        fileContent: e.target.result,
                    })
                    .then(() => {
                        this.fetchMatriceData()
                        this.setReadMode()
                    })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    onExportFile = () => {
        this.props.matriceExportExcel(this.state.matrice.mid)
    }

    onChangeMatrice = (id, event) => {
        if (this.state.editable) {
            const value = event.target ? event.target.value : event
            const { matrice } = this.state
            this.setState({
                matrice: {
                    ...matrice,
                    [id]: value,
                    usermaj: getLogin(),
                },
            })
        }
    }

    onCreateData = (key, id) => {
        const { currentIndicateurs } = this.state
        const newCurrentIndicateurs = [...currentIndicateurs, id]
        this.setState({ currentIndicateurs: newCurrentIndicateurs })
    }

    getSelectedDatas = () => {
        const { currentIndicateurs, matrice } = this.state
        const { indicateurs } = this.props

        const selectionIndics = []
        if (currentIndicateurs.length > 0) {
            currentIndicateurs.forEach((z) => {
                const indicFind = indicateurs.find((propsZone) => propsZone.id === z)
                if (indicFind) {
                    selectionIndics.push({
                        mid: matrice.mid,
                        iid: indicFind.id,
                    })
                } else {
                    selectionIndics.push({
                        mid: matrice.mid,
                        iid: z,
                    })
                }
            })
        }

        return selectionIndics
    }

    render() {
        const { matrice, editable, dataLoaded, openDelete, currentIndicateurs, openIndicators, columnDefs, rowData, noKML } = this.state
        const { applicationSettings, indicateurs, territoire } = this.props
        const selectedDatas = this.getSelectedDatas()
        this.props.setHelpPath('creer-un-territoire/selectionner-les-indicateurs-et-renseigner-la-matrice-technique/', applicationSettings)
        return dataLoaded ? (
            <Card style={{ padding: '10px 25px 60px 25px' }}>
                <LabelName htmlFor="descriptive_name">{i18n.name}&nbsp;:</LabelName>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                            disabled={!editable}
                            style={
                                !editable
                                    ? { backgroundColor: 'rgb(240,240,240)', width: '100%' }
                                    : { width: '100%' }
                            }
                            onChange={(e) => this.onChangeMatrice('name', e)}
                            value={matrice ? matrice.name : null}
                        />
                        <Icon
                            onClick={() => this.onChangeMatrice('favoris', !matrice.favoris)}
                            style={{ color: matrice.favoris ? '#ff9700' : '' }}
                        >
                            star
                        </Icon>
                    </Grid>
                    <Grid item>
                        <UpdatePanel
                            updateLogin={matrice.usermaj}
                            updateDate={matrice.datemaj}
                        />
                    </Grid>
                </Grid>
                <Label htmlFor="descriptive_name">{i18n.descriptive}&nbsp;:</Label>
                <input
                    className="sieau-input form-control input-sm"
                    style={
                        !editable
                            ? { backgroundColor: 'rgb(240,240,240)', width: '100%' }
                            : { width: '100%' }
                    }
                    disabled={!editable}
                    onChange={(e) => this.onChangeMatrice('descriptif', e)}
                    value={matrice ? matrice.descriptif : null}
                />
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} container direction="row" alignItems="center" justifyContent="flex-start">
                        <div onClick={() => this.setState({ openIndicators: true })} className="clickable">
                            <IconStyle>add</IconStyle>
                        </div>
                        <div onClick={() => this.setState({ openIndicators: true })} className="clickable">
                            <TextStyle>
                                <span>{matrice.indicateurs.length}</span>&nbsp;
                                <span>{matrice.indicateurs.length > 1 ? i18n.indicateursPerf : i18n.indicateurPerf}</span>
                            </TextStyle>
                        </div>
                    </Grid>
                    <Grid item>
                        <GreenButton
                            disabled={!editable || !this.props.matrice?.indicateurs?.length || !(territoire.year && (territoire.kml || territoire.cityCode || territoire.department || !noKML))}
                            onClick={this.autoFillMatrice}
                        >
                            {i18n.startCalcul}
                        </GreenButton>
                    </Grid>
                </Grid>
                <ModalIndicator
                    indicateursData={indicateurs}
                    onSave={this.onSave}
                    onChange={this.onChangeSelected}
                    onCreateData={this.onCreateData}
                    selectedIndicateurs={selectedDatas.length > 0 ? selectedDatas : matrice.indicateurs}
                    currentIndicateurs={currentIndicateurs}
                    clickable
                    isEditMode={editable}
                    addField={getUser().isAdmin === '1' || getUser().labo === '1'}
                    matriceId={matrice.mid}
                    open={openIndicators}
                    handleClose={() => this.setState({ openIndicators: false })}
                />
                <div
                    className="ag-theme-balham"
                    style={{
                        height: '70vh',
                        width: '80vw',
                    }}
                >
                    <AgGridTable
                        columnDefs={columnDefs}
                        rowData={rowData}
                        importableXLSX
                        disableImport={!this.props.matrice.indicateurs.length}
                        isEditMode={editable}
                        onUploadFileXLSX={this.onUploadFile}
                        onExportFile={this.onExportFile}
                        fullHeaders
                    />
                </div>
                {openDelete && (
                    <ModalWarningDeleteMatrice
                        handleClose={this.modalDeleteIsClose}
                        open={openDelete}
                        onDeleteMatrice={this.deleteMatrice}
                    />
                )}
            </Card>
        ) : <ProgressBar message="Chargement, cela peut durer quelques instants ..." /> 
    }
}

Matrice.propTypes = {
    getLink: PropTypes.func,
    globalResearch: PropTypes.string,
}

const mapStateToProps = (store) => ({
    territoires: store.TerritoryReducer.territoires,
    territoire: store.TerritoryReducer.territoire,
    matrice: store.TerritoryReducer.matrice,
    cultures: store.ReferencialReducer.cultures,
    conduites: store.ReferencialReducer.conduites,
    zones: store.ReferencialReducer.zones,
    indicateur: store.ReferencialReducer.indicateur,
    indicateurs: store.ReferencialReducer.indicateurs,
    communes: store.ReferencialReducer.communes,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    matriceRef: store.DashboardReducer.matriceRef,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    fetchMatrice: TerritoryAction.fetchMatrice,
    deleteMatrice: TerritoryAction.deleteMatrice,
    fetchTerritory: TerritoryAction.fetchTerritory,
    updateMatrice: TerritoryAction.updateMatrice,
    createMatrice: TerritoryAction.createMatrice,
    autoFillMatrice: TerritoryAction.autoFillMatrice,
    saveMatriceValues: TerritoryAction.saveMatriceValues,
    matriceUploadExcel: TerritoryAction.matriceUploadExcel,
    matriceExportExcel: TerritoryAction.matriceExportExcel,
    fetchCulture: ReferencialAction.fetchCulture,
    fetchElementaryBricks: ReferencialAction.fetchElementaryBricks,
    fetchIndicateursMatrices: ReferencialAction.fetchIndicateursMatrices,
    fetchAllIndicateurs: ReferencialAction.fetchAllIndicateurs,
    fetchCommunes: ReferencialAction.fetchCommunes,
    uploadFile: JobAction.uploadFile,
    fetchMatriceRefCount: DashboardAction.fetchMatriceRefCount,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(Matrice)
