import { ToastrAction, WaitAction } from 'react-aquasys'
import { every } from 'lodash'
import i18n from 'simple-react-i18n'
import AppStore from '../../../store/Appstore'
import {
    RECEIVE_SETTINGS,
    RESET_ADMINISTRATION,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_SELECTED_SEARCH_VALUES,
} from '../constants/AdministrationConstants'
import {
    checkAuth,
    checkError,
    getAuthorization,
    getJson,
} from '../../../utils/ActionUtils'
import ApplicationConf from '../../../conf/ApplicationConf'
import { hasValue } from '../../../utils/NumberUtil'

const AdministrationAction = {
    promiseSavedResearchCriterias() {
        return fetch(ApplicationConf.qualitometer.savedResearchCriterias(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    deleteSavedResearchCriterias(criteriasObjId) {
        return (dispatch) => fetch(
            ApplicationConf.qualitometer.deleteResearchCriterias(
                criteriasObjId,
            ),
            {
                method: 'DELETE',
                headers: getAuthorization(),
            },
        )
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.delete >= 1) {
                    dispatch(
                        AdministrationAction.receiveSavedResearchCriterias(
                            AppStore.getState().AdministrationReducer.savedResearchCriterias.filter(
                                (obj) => obj.id !== criteriasObjId,
                            ),
                        ),
                    )
                } else {
                    throw new Error('')
                }
            })
            .catch((err) => {
                console.error(
                    `${i18n.deleteError
                            + i18n.savedResearchCriterias 
                    } : ${ 
                        err}`,
                )
                ToastrAction.error(i18n.deleteError + i18n.savedResearchCriterias)
            })
    },
    receiveSettings(settings) {
        return { type: RECEIVE_SETTINGS, settings }
    },
    promiseSettings() {
        return fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSettings() {
        return (dispatch) => AdministrationAction.promiseSettings()
            .then((json) => {
                dispatch(AdministrationAction.receiveSettings(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.settings} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.settings)
            })
    },
    receiveApplicationSettings(settings) {
        return { type: RECEIVE_APPLICATION_SETTINGS, settings }
    },
    promiseApplicationSettings() {
        return fetch(ApplicationConf.user.applicationSettings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchApplicationSettings() {
        return (dispatch) => AdministrationAction.promiseApplicationSettings()
            .then((json) => {
                dispatch(
                    AdministrationAction.receiveApplicationSettings(json),
                )
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.settings} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.settings)
            })
    },
    setSelectedSearchValues(stationType, object) {
        return { type: RECEIVE_SELECTED_SEARCH_VALUES, stationType, object }
    },
    updateSetting(settingName, value) {
        return (dispatch) => {
            const setting = AppStore.getState().AdministrationReducer.settings.find(
                (s) => s.parameter === settingName,
            ) || {}
            return fetch(ApplicationConf.user.setting(), {
                method:
                    hasValue(setting.value) && setting.login !== ''
                        ? 'PUT'
                        : 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    parameter: settingName,
                    value,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    if (json.insert === 1 || json.update === 1) {
                        dispatch(AdministrationAction.fetchSettings())
                    } else {
                        console.error(i18n.updateError + i18n.setting)
                        ToastrAction.error(i18n.updateError + i18n.setting)
                    }
                })
                .catch((err) => {
                    console.error(i18n.updateError + i18n.setting + err)
                    ToastrAction.error(i18n.updateError + i18n.setting)
                })
        }
    },
    updateSettings(settingsobj, callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetchArray = Object.keys(settingsobj).map((settingName) => {
                const setting = AppStore.getState().AdministrationReducer.settings.find(
                    (s) => s.parameter === settingName,
                )
                return fetch(ApplicationConf.user.setting(), {
                    method:
                        setting
                        && hasValue(setting.value)
                        && setting.login !== ''
                            ? 'PUT'
                            : 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify({
                        parameter: settingName,
                        value: settingsobj[settingName],
                    }),
                })
                    .then(checkAuth)
                    .then(getJson)
            })
            return Promise.all(fetchArray)
                .then((json) => {
                    dispatch(WaitAction.waitStop())
                    if (
                        every(
                            json,
                            (jsonElem) => jsonElem.insert === 1 || jsonElem.update === 1,
                        )
                    ) {
                        dispatch(AdministrationAction.fetchSettings())
                        ToastrAction.success(i18n.settingsUpdateSuccess)
                        callback()
                    } else {
                        console.error(i18n.updateError + i18n.setting)
                        dispatch(
                            ToastrAction.error(i18n.updateError + i18n.setting),
                        )
                        callback()
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(i18n.updateError + i18n.setting + err)
                    ToastrAction.error(i18n.updateError + i18n.setting)
                })
        }
    },
    reset() {
        return { type: RESET_ADMINISTRATION }
    },
    updateSieauParameters(parameters) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return Promise.all(
                parameters.map((p) => fetch(
                    ApplicationConf.user.sieauParameters(p.parameter),
                    {
                        method: 'PUT',
                        headers: getAuthorization(),
                        body: JSON.stringify({ parameter: p.value }),
                    },
                )
                    .then(checkAuth)
                    .then(getJson)
                    .then(checkError)),
            )
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(AdministrationAction.fetchApplicationSettings())
                    ToastrAction.success(i18n.generalParametersSuccess)
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(i18n.updateError + i18n.parameters + err)
                    ToastrAction.error(i18n.updateError + i18n.parameters)
                })
        }
    },
    promiseUpdatePasswordPolicy(passwordPolicy) {
        return fetch(ApplicationConf.user.sieauParameters('passwordPolicy'), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify({
                parameter: passwordPolicy,
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    putPasswordPolicy(passwordPolicy) {
        return () => AdministrationAction.promiseUpdatePasswordPolicy(
            passwordPolicy,
        )
            .then(() => {
                ToastrAction.success(i18n.generalParametersSuccess)
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },
}

export default AdministrationAction
