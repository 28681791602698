import { template } from 'lodash'
import i18n from 'simple-react-i18n'
import UUID from 'uuid'
import React from 'react'
import { hasValue } from './NumberUtil'
import AppStore from '../store/Appstore'
import ToastrAction from './ToastrAction'
import SieauAction from '../components/sieau/SieauAction'

const checkMandatoryFields = (mandatoryFieldList, object, callback) => {
    const unsetMandatoryFields = mandatoryFieldList.filter((field) => !hasValue(object[field.field]))
    if (unsetMandatoryFields.length) {
        ToastrAction.error(template(i18n.fieldNotSet)({ field: unsetMandatoryFields[0].i18n ? i18n[unsetMandatoryFields[0].i18n] : i18n[unsetMandatoryFields[0].field] }))
    } else {
        callback()
    }
}

const getLocalStorageJson = (key) => {
    const stored = localStorage.getItem(key)
    if (stored) {
        return JSON.parse(decodeURIComponent(window.atob(localStorage.getItem(key))))
    }
    return null
}

const setLocalStorageJson = (key, obj) => {
    const encoded = window.btoa(encodeURIComponent(JSON.stringify(obj)))
    localStorage.setItem(key, encoded)
}

const setDeleteConfirmationModal = (onDelete, message = i18n.deletingConfirmation) => {
    const id = UUID.v4()

    const actions = (
        <div>
            <a className="waves-effect waves-teal btn-flat modal-close">{ i18n.close }</a>
            <a className="waves-effect waves-teal btn-flat modal-close" onClick={onDelete}>{ i18n.confirm }</a>
        </div>
    )
    const popup = {
        id,
        header: message,
        actions,
    }
}

const setMessageModal = (message = '') => {
    const id = UUID.v4()

    const actions = (
        <div>
            <a className="waves-effect waves-teal btn-flat modal-close">{ i18n.close }</a>
        </div>
    )
    const popup = {
        id,
        actions,
        content: (
            <div className="row no-margin">
                <h5>{ message }</h5>
            </div>
        ),
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const setChoiceModal = (message = '', onChoice = {}) => {
    const id = UUID.v4()
    const choices = { ...{ yes: () => {}, no: () => {} }, ...onChoice }
    const actions = (
        <div>
            <a className="waves-effect waves-teal btn-flat modal-close" onClick={choices.yes}>{ i18n.yes }</a>
            <a className="waves-effect waves-teal btn-flat modal-close" onClick={choices.no}>{ i18n.no }</a>
        </div>
    )
    const popup = {
        id,
        actions,
        content: (
            <div className="row no-margin">
                <h5>{ message }</h5>
            </div>
        ),
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

export { checkMandatoryFields, getLocalStorageJson, setLocalStorageJson, setDeleteConfirmationModal, setMessageModal, setChoiceModal }
