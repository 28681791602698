import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'

function FixedSlideNav({ links }) {
    const rows = links.map((link) => (
        <Tooltip title={link.name} placement="right">
            <ListItem
                className="fixed_left_bar_content"
                button
                key={link.className}
                component={Link}
                to={link.href}
            >
                <ListItemIcon className="icon_container">
                    <Icon>{link.icon}</Icon>
                </ListItemIcon>
            </ListItem>
        </Tooltip>
    ))
    return <div className="fixed_left_bar">{rows}</div>
}

FixedSlideNav.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            icons: PropTypes.string,
            name: PropTypes.string,
            className: PropTypes.string,
            collapsedLinks: PropTypes.arrayOf(PropTypes.object),
        }),
    ).isRequired,
}

export default FixedSlideNav
