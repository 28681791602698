import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../../../utils/ToastrAction'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import { getUser } from '../../../../../utils/SettingUtils'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import { MainContainer, Position, RefTable } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import ProgressBar from '../../../../../components/ProgressBar'
import UpdatePanel from '../../../../../components/UpdatePanel'
import AccountAction from '../../../../account/actions/AccountAction'
import ModalWarning from '../../ModalWarning'
import ModalWarningPurge from '../../ModalWarningPurge'
import { getNameById } from '../../../../../utils/ArrayUtils'
import ModalAddReferencial from '../../ModalAddReferencial'

class ZonesApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            columnDefs: [],
            openWarningModal: false,
            openPurgeModal: false,
            openAddModal: false,
            elementsUsed: [],
            rowData: [],
            initialData: [],
            zonesIdSelected: [],
            valuesChanged: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.zones,
                href: 'referencial/zones',
            },
        ])
        this.props.fetchZones().then(() => {
            this.setData(this.props.zones)
            this.setState({ initialData: this.props.zones })
        })
        this.setReadOnlyMode()
    }

    setEditable(editable) {
        this.setState({
            columnDefs: [
                {
                    headerName: i18n.code,
                    field: 'id',
                    headerCheckboxSelection: editable,
                    headerCheckboxSelectionFilteredOnly: editable,
                    checkboxSelection: editable,
                },
                {
                    headerName: i18n.name,
                    field: 'name',
                },
                {
                    headerName: i18n.referencial,
                    field: 'referentiel',
                    cellRenderer: (params) => {
                        const input = document.createElement('input')
                        input.disabled = !editable
                        input.type = 'checkbox'
                        const valueFind = this.state.valuesChanged.find(
                            (value) => value.id === params.data.id,
                        )
                        input.checked = valueFind ? valueFind.referentiel : params.value
                        input.addEventListener('click', () => {
                            if (!this.state.valuesChanged.find((z) => z.id === params.data.id)) {
                                const newData = {
                                    ...params.data,
                                    referentiel: !params.value,
                                }
                                const newState = [...this.state.valuesChanged, newData]
                                this.setState({ valuesChanged: newState })
                            } else {
                                const newState = this.state.valuesChanged.filter(
                                    (z) => z.id !== params.data.id,
                                )
                                this.setState({ valuesChanged: newState })
                            }
                        })
                        return input
                    },
                },
                {
                    headerName: 'KML',
                    field: 'kml',
                },
                {
                    headerName: i18n.user,
                    field: 'usermaj',
                },
                {
                    headerName: i18n.modificationDate,
                    field: 'datemaj',
                },
            ],
        })
    }

    setEditMode = () => {
        this.setEditable(true)
        const actions = {
            cancel: () => {
                this.setState({ dataLoaded: false, zonesIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchZones()
                    .then(() => this.setData(this.props.zones))
                    .then(() => this.setReadOnlyMode())
            },
        }
        if (getUser().isAdmin === '1') {
            actions.new = () => this.setState({ openAddModal: true })
            actions.save = () => {
                this.state.valuesChanged.forEach((z) => {
                    const newZone = {
                        ...z,
                        datemaj: null,
                    }
                    this.props.updateZone(z.id, newZone)
                })
                this.setState({ dataLoaded: false, zonesIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchZones()
                    .then(() => this.setData(this.props.zones))
                    .then(() => this.setReadOnlyMode())
            }
            actions.deleteCheck = () => {
                this.props.deleteZones(this.state.zonesIdSelected).then((json = {}) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                        this.setState({ dataLoaded: false, zonesIdSelected: [], valuesChanged: [] })
                        this.props
                            .fetchZones()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.zones))
                    } else if (json.used) {
                        const elementsUsedWithName = json.used.map((element) => ({
                            id: element,
                            name: getNameById(this.props.zones, element),
                        }))
                        this.setState({ dataLoaded: false, zonesIdSelected: [], valuesChanged: [] })
                        this.props
                            .fetchZones()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.zones))
                        this.setState({ elementsUsed: elementsUsedWithName, openWarningModal: true })
                    }
                })
            }
            actions.purge = () => this.setState({ openPurgeModal: true })
        }
        this.setActions(actions)
    }

    purgeZones = () => {
        this.setState({ dataLoaded: false, zonesIdSelected: [], valuesChanged: [] })
        this.props.purgeZones().then(() => {
            this.props.fetchZones().then(() => this.setData(this.props.zones))
        })
    }

    setReadOnlyMode = () => {
        this.setEditable(false)
        if (getUser().isAdmin === '1') {
            this.setActions({
                edit: () => this.setEditMode(),
                export: () => this.props.exportExcelZones(),
            })
        } else {
            this.setActions({})
        }
    }

    setData(zones) {
        const arr = zones.map((zone) => ({
            id: zone.id,
            name: zone.name,
            referentiel: zone.referentiel,
            usermaj: zone.usermaj,
            datemaj: zone.datemaj,
            kml: zone.kml ? i18n.yes : i18n.no,
        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = this.props.zones.filter((z) => z.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    onSelectZones = (key, data) => {
        this.setState({ zonesIdSelected: data })
    }

    modalAddIsClose = () => {
        this.setState({ dataLoaded: false })
        this.props.fetchZones().then(() => this.setData(this.props.zones))
        this.setState({ openAddModal: false })
    }

    render() {
        const {
            dataLoaded,
            openWarningModal,
            openPurgeModal,
            openAddModal,
            elementsUsed,
            columnDefs,
            rowData,
            frameworkComponents,
        } = this.state

        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <RefTable className="ag-theme-balham">
                            <div style={{ width: '100%', margin: '3rem' }}>
                                <Position>{this.getUpdatePanel()}</Position>
                            </div>
                            <AgGridTable
                                columnDefs={columnDefs}
                                rowData={rowData}
                                frameworkComponents={frameworkComponents}
                                onChange={this.onSelectZones}
                            />
                        </RefTable>
                    ) : (
                        <ProgressBar />
                    )}
                    {openAddModal && (
                        <ModalAddReferencial
                            zone
                            handleClose={this.modalAddIsClose}
                            open={openAddModal}
                        />
                    )}
                    {openWarningModal && (
                        <ModalWarning
                            handleClose={() => this.setState({ openWarningModal: false })}
                            open={openWarningModal}
                            elementsUsed={elementsUsed}
                        />
                    )}
                    {openPurgeModal && (
                        <ModalWarningPurge
                            handleClose={() => this.setState({ openPurgeModal: false })}
                            open={openPurgeModal}
                            purge={this.purgeZones}
                        />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

ZonesApp.propTypes = {
    getLink: PropTypes.func,
    globalResearch: PropTypes.string,
}

const mapStateToProps = (store) => ({
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    globalResearch: store.HomeReducer.globalResearch,
    zones: store.ReferencialReducer.zones,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchZones: ReferencialAction.fetchZones,
    updateZone: ReferencialAction.updateZone,
    createZone: ReferencialAction.createZone,
    deleteZones: ReferencialAction.deleteZones,
    purgeZones: ReferencialAction.purgeZones,
    exportExcelZones: ReferencialAction.exportExcelZones,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ZonesApp)
