import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import { Grid, Icon } from '@material-ui/core'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import ProgressBar from '../../../../../components/ProgressBar'
import { MainContainer, RefTable, Position } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import UserViewDto from '../../../../administration/components/user/dto/UserViewDto'
import UpdatePanel from '../../../../../components/UpdatePanel'
import DtoUser from '../../../../administration/components/user/dto/DtoUser'
import AccountAction from '../../../../account/actions/AccountAction'
import { getFullDateMini } from '../../../../../utils/DateUtil'
import { getSandreLabel } from '../../../../../utils/StringUtil'

class RPGApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            rowData: [],
            year: moment().year(),
            columnDefs: [
                {
                    headerName: 'Id Parcelle',
                    field: 'idParcel',
                },
                {
                    headerName: i18n.year,
                    field: 'year',
                },
                {
                    headerName: i18n.department,
                    field: 'department',
                },
                {
                    headerName: i18n.cultureRPG,
                    field: 'codeRPG',
                },
                {
                    headerName: i18n.typeSuccession,
                    field: 'typeSuccession',
                },
                {
                    headerName: i18n.area,
                    field: 'area',
                },
                {
                    headerName: i18n.user,
                    field: 'usermaj',
                },
                {
                    headerName: i18n.modificationDate,
                    field: 'datemaj',
                },
            ],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: 'RPG Explorer',
                href: 'referencial/rpg',
            },
        ])
        this.props.fetchRPG(this.state.year).then(() => {
            this.setData(this.props.rpg)
        })
        this.props.fetchUser(localStorage.getItem('CCE_LOGIN'))
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = this.props.rpg.filter((c) => c.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    setData(rpg) {
        const arr = rpg.map((d) => ({
            ...d,
            cultureRPG: d.codeRPG,
            typeSuccession: getSandreLabel(this.props.sandreCodes, 'AGRI_RPG.TYPE_SUCCESSION', d.typeSuccession),
            datemaj: getFullDateMini(d.datemaj),
        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    render() {
        const {
            dataLoaded,
            columnDefs,
            rowData,
            year,
        } = this.state
        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <span>{i18n.year}&nbsp;:</span>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <input
                                                className="sieau-input form-control"
                                                value={year}
                                                onChange={(e) => this.setState({ year: e.target.value })}
                                                type="number"
                                                step="1"
                                            />
                                        </Grid>
                                        <Grid item style={{ paddingLeft: 20 }}>
                                            <Icon
                                                className="clickable"
                                                onClick={() => {   
                                                    this.setState({ dataLoaded: false })              
                                                    this.props.fetchRPG(parseInt(this.state.year)).then(() => {
                                                        this.setData(this.props.rpg)
                                                        this.setState({ dataLoaded: true })
                                                    })
                                                }}
                                            >
                                                search
                                            </Icon>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ margin: '3rem' }}>
                                    <Position>{this.getUpdatePanel()}</Position>
                                </Grid>
                            </Grid>
                            <RefTable className="ag-theme-balham">
                                <AgGridTable
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                />
                            </RefTable>
                        </>
                    ) : (
                        <ProgressBar />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

RPGApp.propTypes = {
    getLink: PropTypes.func,
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    accountUser: PropTypes.instanceOf(DtoUser),
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    rpg: store.ReferencialReducer.rpg,
    accountUser: store.AccountReducer.accountUser,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchRPG: ReferencialAction.fetchRPG,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(RPGApp)
