import i18n from 'simple-react-i18n'
import fetch from 'isomorphic-fetch'
import download from 'downloadjs'
import { WaitAction } from 'react-aquasys'
import ToastrAction from '../../ToastrAction'
import ApplicationConf from '../../../conf/ApplicationConf'
import { getDateExport } from '../../DateUtil'
import { getAuthorization, checkAuth } from '../../ActionUtils'

const ExportAction = {
    export(dataPass, type, titleFile) {
        return () => {
            ToastrAction.info(i18n.loadingDocument)
            return fetch(ApplicationConf.export.exportData(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    data: dataPass,
                    type,
                }),
            })
                .then(checkAuth)
                .then((resp) => resp.blob()).then((blob) => {
                    download(blob, `${titleFile}_${getDateExport()}.xlsx`, blob.type)
                })
                .catch((err) => {
                    console.error(`${i18n.exportError} : ${err}`)
                    ToastrAction.error(i18n.exportError)
                })
        }
    },
    kmlExport(exportData) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.export.kmlExport(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    data: window.btoa(unescape(encodeURIComponent(JSON.stringify(exportData)))),
                }),
            })
                .then(checkAuth)
                .then((resp) => resp.blob()).then((blob) => {
                    download(blob, 'exportSieau.kml', blob.type)
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.exportError} : ${err}`)
                    dispatch(WaitAction.waitStop())
                    ToastrAction.error(i18n.exportError)
                })
        }
    },
}

export default ExportAction
