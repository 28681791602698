import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import { WaitAction } from 'react-aquasys'
import AppStore from '../store/Appstore'
import HomeAction from '../pages/home/actions/HomeAction'
import { CCE_TOKEN, TOKEN_CCE_LOGIN } from '../pages/home/constants/HomeConstants'
import ToastrAction from './ToastrAction'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        localStorage.removeItem(CCE_TOKEN)
        AppStore.dispatch(push('/login'))
        AppStore.dispatch(HomeAction.disconnected(i18n.disconnectedError))
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    405: () => {
        AppStore.dispatch(push('/'))
        ToastrAction.error('Non autorisé')
    },
    429: () => {
        ToastrAction.error(i18n.tooManyLoginRequests)
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkStatus = (obj, response) => {
    const code = Object.keys(obj).find((element) => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const fetchWithRetries = (url, options, retries = 2) => {
    const promise = fetch(url, options)
    return promise.then((response) => {
        if (response.status === 502) {
            if (retries === 0) {
                return response
            }
            return fetchWithRetries(url, options, retries - 1)
        }
        return response
    })
}

const checkAuth = (response, overrideStatus = {}) => checkStatus({ ...codes, ...overrideStatus }, response)

const checkAuthWithoutKicking = (response, overrideStatus = { 403: () => { } }) => checkStatus({ ...codes, ...overrideStatus }, response)

const checkError = (json, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        AppStore.dispatch(WaitAction.waitStop())
        throw new Error(json.error)
    }
    return json
}

const getJson = function (response) {
    if (response) {
        return response.json()
    }
    ToastrAction.error(i18n.loadError)
    return {}
}

const getText = function (response) {
    return response.text()
}

const getAuthorization = () => ({
    Authorization: `Bearer ${localStorage.getItem(CCE_TOKEN)}`,
    module: 'coclickeau',
})

const getAuthorizationLogin = () => ({
    Authorization: TOKEN_CCE_LOGIN,
    module: 'coclickeau',
})

const getPayload = () => {
    const token = localStorage.getItem(CCE_TOKEN)
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const getPutFetch = (route, data) => fetch(route, {
    method: 'PUT',
    headers: getAuthorization(),
    body: JSON.stringify(data),
})
    .then(checkAuth)
    .then(getJson)
    .then(checkError)

const promiseAllProgress = (promises, callback) => {
    let d = 0
    callback(0)
    promises.forEach((p) => {
        p.then(() => {
            d++
            callback((d * 100) / promises.length)
        })
    })
    return Promise.all(promises)
}

export {
    checkAuth,
    getJson,
    checkStatus,
    getAuthorization,
    getAuthorizationLogin,
    getPayload,
    getText,
    checkError,
    getPutFetch,
    promiseAllProgress,
    fetchWithRetries,
    checkAuthWithoutKicking,
}
