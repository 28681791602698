import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Icon from '@material-ui/core/Icon'
import Card from '@material-ui/core/Card'
import { orderBy, compact } from 'lodash'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ProgressBar from '../../../components/ProgressBar'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import ActionComponent from '../../../components/actions/ActionComponent'
import HomeAction from '../../home/actions/HomeAction'
import { getArticleFormat } from '../../../utils/DateUtil'
import { PATH_TERRITORY_MATRICE } from '../../home/constants/RouteConstants'
import EventsAction from '../../events/actions/EventsAction'
import { Flex } from '../../../styles/main'
import { lightGrey } from '../../../styles/theme'
import TerritoryAction from '../actions/TerritoryAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import { getUser } from '../../../utils/SettingUtils'
import ModalNewMatrice from './ModalNewMatrice'

const MainWrapper = styled.div`
    padding: 0 8px;
    position: relative;
`

const ContentFooter = styled.div`
    background-color: ${lightGrey};
    padding: 0.2rem 1rem;
    border-bottom: 1px solid rgb(191, 191, 191);
`

const BodyContent = styled(Flex)`
    padding: 5px 0 5px 2.4rem;
`

const Wrapper = styled(Flex)`
    width: 100%;
`

const FooterText = styled.div`
    font-style: italic;
    font-size: 0.8rem;
`

const Title = styled.div`
    width: 40%;
    font-size: 1.3rem;
    font-weight: bold;
`

const Text = styled.div`
    width: 55%;
    font-style: italic;
`

const StarIcon = styled(Icon)`
    margin: 0 !important;
    z-index: 999;
`

class MatricesApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            openFav: true,
            open: true,
            openModal: false,
            dataLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchUsers()
        this.props.fetchMatricesTerritoires(this.props.tid).then(() => {
            this.setState({ dataLoaded: true })
        })
        window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        if (
            this.props.territory
            && this.props.territory.statut !== 2
            && (getUser().isAdmin === '1'
                || (getUser().labo === '1' && !this.props.territory.referencial))
        ) {
            this.setActions({
                new: () => this.modalIsOpen(),
            })
        }
    }

    getContentRow = (content) => (
        <MainWrapper
            className="clickable"
            key={content.mid}
            onClick={() => this.props.push(`${PATH_TERRITORY_MATRICE}/${content.mid}`)}
        >
            <BodyContent>
                <Wrapper>
                    <Title>{content.name}</Title>
                    {content.descriptif ? (
                        <Text>{content.descriptif}</Text>
                    ) : (
                        <Text>{i18n.noDescriptionToShow}</Text>
                    )}
                    <StarIcon
                        key={content.mid}
                        style={{ color: content.favoris ? '#ff9700' : '' }}
                    >
                        star
                    </StarIcon>
                </Wrapper>
            </BodyContent>
            <ContentFooter>
                <FooterText>
                    {`${i18n.createdBy} ${content.usermaj ? content.usermaj : i18n.unknown} ${i18n.the} ${getArticleFormat(content.datecreation)}`}
                </FooterText>
            </ContentFooter>
        </MainWrapper>
    )

    getPlurial = (fav) => {
        const { matrices } = this.props
        const filteredMatrices = matrices.filter((m) => m.favoris === fav)
        return filteredMatrices.length > 1 
    }

    getNoContent = () => (
        <MainWrapper>
            <BodyContent>
                <Wrapper>
                    <Title>{i18n.noMatriceToDisplay}</Title>
                </Wrapper>
            </BodyContent>
        </MainWrapper>
    )

    getMatrices = (fav) => {
        const { matrices } = this.props
        const filteredMatrices = orderBy(compact(matrices.map((m) => {
            if (m.favoris === fav) {
                return m
            }
            return null
        })), 'datemaj', 'desc')
        if (filteredMatrices.length > 0) {
            return filteredMatrices.map((m) => this.getContentRow(m))
        }
        return this.getNoContent()
    }

    handleChange = (fav) => {
        const { openFav, open } = this.state
        if (fav) {
            this.setState({ openFav: !openFav })
        } else {
            this.setState({ open: !open })
        }
    }

    modalIsOpen = () => {
        this.setState({ openModal: true })
    }

    modalIsClose = () => {
        this.setState({ openModal: false })
    }

    render() {
        const { openFav, open, openModal, dataLoaded } = this.state
        const { applicationSettings } = this.props
        this.props.setHelpPath('creer-un-territoire/selectionner-les-indicateurs-et-renseigner-la-matrice-technique/', applicationSettings)
        return (
            <Card>
                {dataLoaded ? (
                    <div style={{ padding: '1rem' }}>
                        <ExpansionPanel
                            expanded={openFav}
                            onChange={() => this.handleChange('fav')}
                            backgroundColor="#484848"
                            TransitionProps={{ timeout: 200 }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon className="expandIcon" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ padding: '0', color: 'white' }}
                            >
                                <h6 style={{ margin: '20px' }}>
                                    {this.getPlurial(true) ? i18n.matricesFav : i18n.matriceFav}
                                </h6>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>{this.getMatrices(true)}</ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel
                            expanded={open}
                            onChange={() => this.handleChange()}
                            backgroundColor="#484848"
                            TransitionProps={{ timeout: 200 }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon className="expandIcon" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ padding: '0', color: 'white' }}
                            >
                                <h6 style={{ margin: '20px' }}>
                                    {this.getPlurial(false) ? i18n.matrices : i18n.matrice}
                                </h6>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>{this.getMatrices(false)}</ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                ) : (
                    <ProgressBar />
                )}
                {openModal && (
                    <ModalNewMatrice
                        open={openModal}
                        handleClose={this.modalIsClose}
                        tid={this.props.tid}
                    />
                )}
            </Card>
        )
    }
}

MatricesApp.propTypes = {
    params: PropTypes.shape({
        categoryId: PropTypes.number,
        globalResearch: PropTypes.string,
    }),
}

const mapDispatchToProps = {
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    setTitle: HomeAction.setTitle,
    setHelpPath: HomeAction.setHelpPath,
    fetchMatricesTerritoires: TerritoryAction.fetchMatricesTerritoires,
    fetchUsers: UserAction.fetchUsers,
    fetchCMSCategories: EventsAction.fetchCMSCategories,
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    push,
}

const mapStateToProps = (store) => ({
    globalResearch: store.HomeReducer.globalResearch,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsEvent: store.EventsReducer.cmsEvent,
    cmsCategories: store.EventsReducer.cmsCategories,
    selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    matrices: store.TerritoryReducer.matrices,
    users: store.UserReducer.users,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps, mapDispatchToProps)(MatricesApp)
