/* eslint-disable linebreak-style */
import React, { PureComponent } from 'react'
import echarts from 'echarts'
import PropTypes from 'prop-types'
import ReactEcharts from 'echarts-for-react'

export default class EchartBar extends PureComponent {
    getOption = () => {
        const { data, legendsData, yLabels, fileTitle, min, max, hideSymbolTooltip, xName, legendParams } = this.props
        const option = {
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        show: true,
                        title: 'Export',
                        name: fileTitle,
                    },
                },
            },
            legend: {
                data: legendsData, // A générer
                orient: 'vertical',
                top: 10,
                left: 0,
                ...legendParams,
            },
            tooltip: {
                formatter(info) {
                    const { value, name, seriesName } = info
                    // eslint-disable-next-line no-nested-ternary
                    const valueString = hideSymbolTooltip ? value : (value > 0 ? `+${value}` : value)
                    return [
                        `<div class="tooltip-title">${seriesName}</div>`,
                        `${name}: ${valueString}%`,
                    ].join('')
                },
            },
            label: {
                formatter(info) {
                    const { value } = info
                    return `${Math.round(value)}%`
                },
            },
            xAxis: {
                name: xName || 'Evolution (%)',
                splitArea: { show: false },
                min,
                max,
            },
            yAxis: {
                axisLabel: {
                    rotate: -0,
                },
                data: yLabels,
                axisLine: { onZero: true },
                splitLine: { show: true },
                splitArea: { show: false },
            },
            grid: {
                left: 250,
                right: 150,
            },
            series: data,
        }
        return option
    }

    renderBrushed = (params) => {
        const brushed = []
        const brushComponent = params.batch[0]

        for (let sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
            const rawIndices = brushComponent.selected[sIdx].dataIndex
            brushed.push(`[Series ${sIdx}] ${rawIndices.join(', ')}`)
        }

        echarts.setOption({
            title: {
                backgroundColor: '#333',
                text: `SELECTED DATA INDICES: \n${brushed.join('\n')}`,
                bottom: 0,
                right: 0,
                width: 100,
                textStyle: {
                    fontSize: 12,
                    color: '#fff',
                },
            },
        })
    }

    render() {
        return (
            <ReactEcharts
                option={this.getOption()}
                style={{ height: '500px', width: '100%' }}
                className="react_for_echarts"
            />
        )
    }
}

EchartBar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    legendsData: PropTypes.arrayOf(PropTypes.object),
    yLabels: PropTypes.arrayOf(PropTypes.object),
    legendParams: PropTypes.instanceOf(PropTypes.object),
    fileTitle: PropTypes.string,
    xName: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    hideSymbolTooltip: PropTypes.bool,
}
