/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import Bar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import TerritoryAction from '../../pages/territory/actions/TerritoryAction'
import {
    PATH_TERRITORY_DETAIL,
    PATH_TERRITORY_MATRICE,
    PATH_TERRITORY_SCENARIO,
} from '../../pages/home/constants/RouteConstants'

const BurgerIcon = styled(Icon)`
    cursor: pointer;
    font-size: 2rem !important;
`

const HeadList = styled.p`
    background-color: #057DCC;
    color: white;
    margin: 0;
    padding: 5px;
    font-weight: bold;
`

const ListResult = styled.div`
    background-color: white;
    padding: 5px;
`

const DropDownCard = styled(Card)`
    background: transparent !important;
    margin: 10px 0 0 0;
    width: 400px;
    height: fit-content;
    max-height: 300px;
    position: absolute;
    top: 55px;
    overflow-y: scroll !important;
    box-shadow: -1px 8px 10px -5px rgba(0, 0, 0, 0.7) !important;
`

class AppBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            resultTerritoires: [],
            resultMatrices: [],
            resultScenarios: [],
            searchValue: '',
            open: false,
        }
    }

    componentDidMount() {
        this.props.fetchAllMatrices()
        this.props.fetchAllScenarios()
        this.props.fetchTerritoires()
    }

    getBreadcrumbs = () => this.props.title.map((o, index) => {
        if (o && o.title) {
            const title = o.title.length > 100 ? `${o.title.substring(0, 100)}...` : o.title
            const last = !this.props.title[index + 1]
            return (
                <a key={index} href={`#/${o.href}`}>
                    <span
                        key={o.title}
                        className={last ? 'breadcrumbLast clickable' : 'breadcrumb clickable'}
                        style={{ display: 'inline-block' }}
                    >
                        {title}
                    </span>
                </a>
            )
        }
        return null
    })

    onSearchValue = (value) => {
        this.setState({ searchValue: value })
        if (value.length >= 3) {
            const search = value.toLowerCase()
            const resultTerritoires = this.props.territoires.filter((t) => t.name.toLowerCase().includes(search))
            const resultMatrices = this.props.allMatrices.filter((m) => m.name.toLowerCase().includes(search))
            const resultScenarios = this.props.allScenarios.filter((s) => s.name.toLowerCase().includes(search))
            this.setState({
                resultTerritoires,
                resultMatrices,
                resultScenarios,
                open: true,
            })
        } else {
            this.setState({ open: false })
        }
    }

    openDropDown = () => {
        if (this.state.searchValue.length >= 3) {
            this.setState({ open: true })
        }
    }

    closeDropDown = () => {
        this.setState({ open: false, searchValue: '' })
    }

    getListElement = (type, element) => {
        switch (type) {
            case 'territoire':
                return (
                    <>
                        <span
                            className="clickable"
                            onClick={() => {
                                this.closeDropDown()
                                this.props.push(`${PATH_TERRITORY_DETAIL}/${element.id}`)
                                window.location.reload()
                            }}
                        >
                            [{element.id}] - {element.name}
                        </span>
                        <br />
                    </>
                )
            case 'matrice':
                return (
                    <>
                        <span
                            className="clickable"
                            onClick={() => {
                                this.closeDropDown()
                                this.props.push(`${PATH_TERRITORY_MATRICE}/${element.mid}`)
                                window.location.reload()
                            }}
                        >
                            {' '}
                            [{element.mid}] - {element.name}
                        </span>
                        <br />
                    </>
                )
            case 'scenario':
                return (
                    <>
                        <span
                            className="clickable"
                            onClick={() => {
                                this.closeDropDown()
                                this.props.push(`${PATH_TERRITORY_SCENARIO}/${element.id}`)
                                window.location.reload()
                            }}
                        >
                            {' '}
                            [{element.id}] - {element.name}
                        </span>
                        <br />
                    </>
                )
            default:
                return ''
        }
    }

    getTerritoiresPanel = () => this.state.resultTerritoires.map((r) => this.getListElement('territoire', r))

    getMatricesPanel = () => this.state.resultMatrices.map((r) => this.getListElement('matrice', r))

    getScenariosPanel = () => this.state.resultScenarios.map((r) => this.getListElement('scenario', r))

    getDropDown = () => {
        const { resultTerritoires, resultMatrices, resultScenarios } = this.state
        return (
            <DropDownCard className="dropDownCard">
                <HeadList>
                    {i18n.territories}&nbsp;({resultTerritoires.length}&nbsp;
                    {resultTerritoires.length > 1 ? i18n.elements : i18n.element})
                </HeadList>
                <ListResult>{this.getTerritoiresPanel()}</ListResult>
                <HeadList>
                    {i18n.matrices}&nbsp;({resultMatrices.length}&nbsp;
                    {resultMatrices.length > 1 ? i18n.elements : i18n.element})
                </HeadList>
                <ListResult>{this.getMatricesPanel()}</ListResult>
                <HeadList>
                    {i18n.scenarios}&nbsp;({resultScenarios.length}&nbsp;
                    {resultScenarios.length > 1 ? i18n.elements : i18n.element})
                </HeadList>
                <ListResult>{this.getScenariosPanel()}</ListResult>
            </DropDownCard>
        )
    }

    render() {
        const actions = this.props.actions.map((action, index) => (
            <List key={index} style={{ color: 'white', marginLeft: '20px' }}>
                {action}
            </List>
        ))

        const { open } = this.state
        return (
            <Bar className="appbar" position="fixed">
                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#29AE99',
                        height: '58px',
                    }}
                >
                    <Toolbar><BurgerIcon onClick={this.props.toggleDrawer(true)}>menu</BurgerIcon>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            align-items="center"
                            style={{ marginLeft: '10px' }}
                        >
                            <Grid item>
                                <Breadcrumbs
                                    separator={
                                        <Icon style={{ color: 'white' }}>keyboard_arrow_right</Icon>
                                    }
                                    aria-label="breadcrumb"
                                    style={{
                                        background: 'transparent',
                                        boxShadow: 'none',
                                        display: 'flex',
                                    }}
                                >
                                    {this.getBreadcrumbs()}
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <TextField
                                placeholder={i18n.search}
                                onFocus={this.openDropDown}
                                onChange={(e) => this.onSearchValue(e.target.value)}
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: '5px',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {open ? this.getDropDown() : ''}
                            {actions}
                        </Grid>
                    </Toolbar>
                </div>
            </Bar>
        )
    }
}

AppBar.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.element).isRequired,
    title: PropTypes.arrayOf(PropTypes.string).isRequired,
    push: PropTypes.func,
    fetchAllMatrices: PropTypes.func,
    fetchAllScenarios: PropTypes.func,
    fetchTerritoires: PropTypes.func,
    toggleDrawer: PropTypes.func,
    territoires: PropTypes.arrayOf(PropTypes.object).isRequired,
    allMatrices: PropTypes.arrayOf(PropTypes.object).isRequired,
    allScenarios: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapDispatchToProps = {
    fetchTerritoires: TerritoryAction.fetchTerritoires,
    fetchAllMatrices: TerritoryAction.fetchAllMatrices,
    fetchAllScenarios: TerritoryAction.fetchAllScenarios,
    push,
}

const mapStateToProps = (store) => ({
    title: store.HomeReducer.title,
    backPath: store.HomeReducer.backPath,
    actions: store.HomeReducer.actions,
    territoires: store.TerritoryReducer.territoires,
    allMatrices: store.TerritoryReducer.allMatrices,
    allScenarios: store.TerritoryReducer.allScenarios,
    cmsEvents: store.EventsReducer.cmsEvents,
})

export default connect(mapStateToProps, mapDispatchToProps)(AppBar)
