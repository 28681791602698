export default class DtoJobLog {
    constructor(obj = {}, jobExecutionId) {
        this.id = obj.id
        this.comment = obj.comment
        this.value = obj.value
        this.date = obj.date
        this.station = obj.station
        this.jobExecutionId = jobExecutionId || obj.jobExecutionId
    }
}
