import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from '../../../../../utils/ToastrAction'
import ReferencialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import AgGridTable from '../../../../../components/datatable/AgGridTable'
import { getUser } from '../../../../../utils/SettingUtils'
import ActionComponent from '../../../../../components/actions/ActionComponent'
import { MainContainer, RefTable, Position } from '../../../../../styles/container'
import { SpaceAround } from '../../../../../styles/main'
import UpdatePanel from '../../../../../components/UpdatePanel'
import AccountAction from '../../../../account/actions/AccountAction'
import ModalWarning from '../../ModalWarning'
import ModalWarningPurge from '../../ModalWarningPurge'
import { getNameById } from '../../../../../utils/ArrayUtils'
import ModalAddReferencial from '../../ModalAddReferencial'
import ProgressBar from '../../../../../components/ProgressBar'

class IndicateursApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            columnDefs: [],
            openWarningModal: false,
            openPurgeModal: false,
            openAddModal: false,
            elementsUsed: [],
            rowData: [],
            initialData: [],
            editable: false,
            indicateursIdSelected: [],
            valuesChanged: [],
        }
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.referencials,
                href: 'referencial/dashboard',
            },
            {
                title: i18n.indicateursPerf,
                href: 'referencial/indicateurs',
            },
        ])
        this.props.fetchIndicateurs().then(() => {
            this.setData(this.props.indicateurs)
            this.setState({ initialData: this.props.indicateurs })
        })
        this.setReadOnlyMode()
    }

    setEditable(editable) {
        this.setState({
            columnDefs: [
                {
                    headerName: i18n.code,
                    field: 'id',
                    headerCheckboxSelection: editable,
                    headerCheckboxSelectionFilteredOnly: editable,
                    checkboxSelection: editable,
                },
                {
                    headerName: i18n.name,
                    field: 'name',
                    sortable: true,
                    filter: true,
                },
                {
                    headerName: i18n.referencial,
                    field: 'referentiel',
                    cellRenderer: (params) => {
                        const input = document.createElement('input')
                        input.disabled = !editable
                        input.type = 'checkbox'
                        const valueFind = this.state.valuesChanged.find(
                            (value) => value.id === params.data.id,
                        )
                        input.checked = valueFind ? valueFind.referentiel : params.value
                        input.addEventListener('click', () => {
                            if (!this.state.valuesChanged.find((z) => z.id === params.data.id)) {
                                const newData = {
                                    ...params.data,
                                    referentiel: !params.value,
                                }
                                const newState = [...this.state.valuesChanged, newData]
                                this.setState({ valuesChanged: newState })
                            } else {
                                const newState = this.state.valuesChanged.filter(
                                    (z) => z.id !== params.data.id,
                                )
                                this.setState({ valuesChanged: newState })
                            }
                        })
                        return input
                    },
                },
                {
                    headerName: i18n.user,
                    field: 'usermaj',
                },
                {
                    headerName: i18n.modificationDate,
                    field: 'datemaj',
                },
            ],
            editable,
        })
    }

    setEditMode = () => {
        this.setEditable(true)
        const actions = {
            cancel: () => {
                this.setState({ dataLoaded: false, indicateursIdSelected: [], valuesChanged: [] })
                this.props
                    .fetchIndicateurs()
                    .then(() => this.setData(this.props.indicateurs))
                    .then(() => this.setReadOnlyMode())
            },
        }
        if (getUser().isAdmin === '1') {
            actions.new = () => {
                this.modalAddIsOpen()
            }
            actions.save = () => {
                this.state.valuesChanged.forEach((i) => {
                    const newIndicateur = {
                        ...i,
                        datemaj: null,
                    }
                    this.props.updateIndicateur(i.id, newIndicateur)
                })
                this.setState({ dataLoaded: false, valuesChanged: [], indicateursIdSelected: [] })
                this.props
                    .fetchIndicateurs()
                    .then(() => this.setData(this.props.indicateurs))
                    .then(() => this.setReadOnlyMode())
            }
            actions.deleteCheck = () => {
                this.props.deleteIndicateurs(this.state.indicateursIdSelected).then((json = {}) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                        this.setState({
                            dataLoaded: false,
                            indicateursIdSelected: [],
                            valuesChanged: [],
                        })
                        this.props
                            .fetchIndicateurs()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.indicateurs))
                    } else if (json.used) {
                        const elementsUsedWithName = json.used.map((element) => ({
                            id: element,
                            name: getNameById(this.props.indicateurs, element),
                        }))
                        this.setState({
                            dataLoaded: false,
                            indicateursIdSelected: [],
                            valuesChanged: [],
                        })
                        this.props
                            .fetchIndicateurs()
                            .then(() => this.setReadOnlyMode())
                            .then(() => this.setData(this.props.indicateurs))
                        this.setState({ elementsUsed: elementsUsedWithName })
                        this.modalWarningIsOpen()
                    }
                })
            }
            actions.purge = () => {
                this.modalPurgeIsOpen()
            }
        }
        this.setActions(actions)
    }

    purgeIndicateurs = () => {
        this.setState({
            dataLoaded: false,
            indicateursIdSelected: [],
            valuesChanged: [],
        })
        this.props.purgeIndicateurs().then(() => {
            this.props.fetchIndicateurs().then(() => this.setData(this.props.indicateurs))
        })
    }

    setReadOnlyMode = () => {
        this.setEditable(false)
        if (getUser().isAdmin === '1') {
            this.setActions({
                edit: () => this.setEditMode(),
                export: () => this.props.exportExcelIndicateurs(),
            })
        } else {
            this.setActions({})
        }
    }

    setData(indicateurs) {
        const arr = indicateurs.map((indicateur) => ({
            id: indicateur.id,
            name: indicateur.name,
            referentiel: indicateur.referentiel,
            usermaj: indicateur.usermaj,
            datemaj: indicateur.datemaj,
        }))
        this.setState({ rowData: arr, dataLoaded: true })
    }

    onSelectIndicateurs = (key, data) => {
        this.setState({ indicateursIdSelected: data })
    }

    modalAddIsOpen = () => {
        this.setState({ openAddModal: true })
    }

    modalAddIsClose = () => {
        this.setState({ dataLoaded: false })
        this.props.fetchIndicateurs().then(() => this.setData(this.props.indicateurs))
        this.setState({ openAddModal: false })
    }

    modalWarningIsOpen = () => {
        this.setState({ openWarningModal: true })
    }

    modalWarningIsClose = () => {
        this.setState({ openWarningModal: false })
    }

    modalPurgeIsOpen = () => {
        this.setState({ openPurgeModal: true })
    }

    modalPurgeIsClose = () => {
        this.setState({ openPurgeModal: false })
    }

    getUpdatePanel = () => {
        const dataWithDatemaj = this.props.indicateurs.filter((i) => i.datemaj)
        if (dataWithDatemaj.length) {
            const lastUpdate = orderBy(dataWithDatemaj, 'datemaj', 'desc')[0]
            return <UpdatePanel updateLogin={lastUpdate.usermaj} updateDate={lastUpdate.datemaj} />
        }
        return null
    }

    render() {
        const {
            dataLoaded,
            openWarningModal,
            openPurgeModal,
            openAddModal,
            elementsUsed,
            columnDefs,
            rowData,
        } = this.state
        return (
            <SpaceAround>
                <MainContainer noWidth>
                    {dataLoaded ? (
                        <RefTable className="ag-theme-balham">
                            <div style={{ width: '100%', margin: '3rem' }}>
                                <Position>{this.getUpdatePanel()}</Position>
                            </div>
                            <AgGridTable
                                columnDefs={columnDefs}
                                rowData={rowData}
                                onChange={this.onSelectIndicateurs}
                            />
                        </RefTable>
                    ) : (
                        <ProgressBar />
                    )}
                    {openAddModal && (
                        <ModalAddReferencial
                            perf
                            handleClose={this.modalAddIsClose}
                            open={openAddModal}
                        />
                    )}
                    {openWarningModal && (
                        <ModalWarning
                            handleClose={this.modalWarningIsClose}
                            open={openWarningModal}
                            elementsUsed={elementsUsed}
                        />
                    )}
                    {openPurgeModal && (
                        <ModalWarningPurge
                            handleClose={this.modalPurgeIsClose}
                            open={openPurgeModal}
                            purge={this.purgeIndicateurs}
                        />
                    )}
                </MainContainer>
            </SpaceAround>
        )
    }
}

IndicateursApp.propTypes = {
    getLink: PropTypes.func,
}

const mapStateToProps = (store) => ({
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    indicateurs: store.ReferencialReducer.indicateurs,
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchIndicateurs: ReferencialAction.fetchIndicateurs,
    updateIndicateur: ReferencialAction.updateIndicateur,
    createIndicateur: ReferencialAction.createIndicateur,
    deleteIndicateurs: ReferencialAction.deleteIndicateurs,
    purgeIndicateurs: ReferencialAction.purgeIndicateurs,
    exportExcelIndicateurs: ReferencialAction.exportExcelIndicateurs,
    fetchUser: AccountAction.fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(IndicateursApp)
