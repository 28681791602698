import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { logo } from '../constants/LogosConstants'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import HomeAction from '../actions/HomeAction'
import CMSEventDto from '../../events/dto/CMSEventDto'
import { contentsPath } from '../../../conf/basepath'

const HomeIcon = styled(Icon)`
    font-size: 3em !important;
    color: black;
`

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logoCms: '',
        }
    }

    componentDidMount() {
        this.props.fetchApplicationSettings()
        const { cmsHome } = this.props
        if (!cmsHome.length) {
            this.props.fetchCmsHome().then(() => {
                this.setHomeCms()
            })
        } else {
            this.setHomeCms()
        }
    }

    setHomeCms = () => {
        const { cmsHome } = this.props
        if (cmsHome.length && cmsHome[0].cmsDocument.length) {
            this.setState({ logoCms: `${contentsPath}CMS/${cmsHome[0].cmsDocument[0].name}` })
        }
    }

    getStyle(page) {
        if (this.props.page === page) {
            return 'item_menu_home underline'
        }
        return 'item_menu_home'
    }

    render() {
        const { logoCms } = this.state

        const style = {
            fontSize: '1.9vh',
        }
        return (
            <div className="home_header">
                <Grid item xs={4}>
                    <Link to="/home">
                        <img
                            src={logoCms || logo.src}
                            alt="logo"
                            className="responsive-img"
                            style={{ marginTop: '10px' }}
                        />
                    </Link>
                </Grid>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                    }}
                >
                    <Link to="/login">
                        <span style={style} className={this.getStyle('login')}>
                            {i18n.loginTitle}
                        </span>
                    </Link>
                    <Link to="/askAccount">
                        <span style={style} className={this.getStyle('askAccount')}>
                            {i18n.askAccountTitle}
                        </span>
                    </Link>
                    <Link to="/contact">
                        <span style={style} className={this.getStyle('contact')}>
                            {i18n.contactTitle}
                        </span>
                    </Link>
                    <Link to="/home">
                        <HomeIcon>home</HomeIcon>
                    </Link>
                </div>
            </div>
        )
    }
}

Header.propTypes = {
    fetchApplicationSettings: PropTypes.func,
    fetchCmsHome: PropTypes.func,
    cmsHome: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
}

const mapStateToProps = (store) => ({
    cmsHome: store.HomeReducer.cmsHome,
})

const mapDispatchToProps = {
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
    fetchCmsHome: HomeAction.fetchCmsHome,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
