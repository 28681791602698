import { RECEIVE_CONTACT, RECEIVE_ALL_CONTACTS, RESET_CONTACT } from '../constants/ContactConstants'
import ContactDto from '../dto/ContactDto'
import ContactItem from '../dto/ContactItem'

export function ContactReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_CONTACTS:
            return { ...state, contacts: action.contacts.map((contact) => new ContactItem(contact)) }
        case RECEIVE_CONTACT:
            return { ...state, contact: new ContactDto(action.contact) }
        case RESET_CONTACT:
            return { ...state, contact: {} }
        default:
            return state
    }
}

export const store = {
    contacts: [],
    contact: {},
}
