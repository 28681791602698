/* eslint-disable prefer-destructuring */
export default class DtoRPG {
    constructor(obj) {
        this.idParcel = obj[0] // idParcel Long,
        this.year = obj[1] // year Int,
        this.department = obj[2] // department String,
        this.codeRPG = obj[3] // codeRPG String,
        this.typeSuccession = obj[4] // typeSuccession Int,
        this.area = obj[5] // area Double,
        this.usermaj = obj[6] // usermaj Option[String] = None,
        this.datemaj = obj[7] // datemaj Option[DateTime] = None
    }
}
