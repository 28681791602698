import fetch from 'isomorphic-fetch'
import { WaitAction } from 'react-aquasys'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import {
    CITIES,
    CITY,
    RECEIVE_CITY_CEDEX,
    RESET_CITY,
} from '../constants/CityConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import ToastrAction from '../../../../../utils/ToastrAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../../utils/ActionUtils'
import {
    PATH_REFERENCIAL_CITY,
    PATH_REFERENCIAL_CITY_LINK,
} from '../../../../home/constants/RouteConstants'

const CityAction = {
    receiveCity(city) {
        return { type: CITY, city }
    },

    receiveCities(cities) {
        return { type: CITIES, cities }
    },

    updateCity(city, code) {
        return (dispatch) => fetch(ApplicationConf.referencial.saveCity(code), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(city),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.update === 1) {
                    dispatch(CityAction.fetchCity(code))
                    ToastrAction.success(i18n.citySuccessUpdated)
                } else {
                    throw new Error()
                }
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.city} : ${err}`)
                ToastrAction.error(i18n.updateError + i18n.city)
            })
    },

    createCity(city) {
        return (dispatch) => fetch(ApplicationConf.referencial.cities(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(city),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.insert) {
                    dispatch(push(PATH_REFERENCIAL_CITY_LINK + city.code))
                    ToastrAction.success(i18n.successfulCreation)
                }
            })
            .catch((err) => {
                console.error(`${i18n.createError + i18n.city} : ${err}`)
                ToastrAction.error(i18n.createError + i18n.city)
            })
    },

    deleteCity(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteCity(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        ToastrAction.success(i18n.elementDeleteSuccess)
                        dispatch(push(PATH_REFERENCIAL_CITY))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.deleteError + i18n.city} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.city)
                })
        }
    },

    purgeCity() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeCity(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`)
                        dispatch(CityAction.fetchCities())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.deleteError + i18n.cities} : ${err}`)
                    ToastrAction.error(i18n.deleteError + i18n.cities)
                })
        }
    },

    replaceCity(city, cityReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceCity(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    id: city,
                    replaceId: cityReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update) {
                        ToastrAction.success(i18n.citySuccessUpdated)
                    } else if (json.update === 0) {
                        ToastrAction.success(`${json.update} ${i18n.replacement}`)
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.replace + i18n.city} : ${err}`)
                })
        }
    },

    fetchCity(code) {
        return (dispatch) => fetch(ApplicationConf.referencial.city(code), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                checkError(json)
                dispatch(CityAction.receiveCity(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.city} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.city)
            })
    },

    promiseCities() {
        return fetch(ApplicationConf.referencial.cities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchCities() {
        return (dispatch) => CityAction.promiseCities()
            .then((json = []) => {
                dispatch(CityAction.receiveCities(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.cities} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.cities)
            })
    },

    receiveCityCedex(cityCedex) {
        return { type: RECEIVE_CITY_CEDEX, cityCedex }
    },

    fetchCityCedex() {
        return (dispatch) => fetch(ApplicationConf.referencial.cityCedex(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(CityAction.receiveCityCedex(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} ${i18n.cedex} : ${err}`)
                ToastrAction.error(`${i18n.fetchError} ${i18n.cedex}`)
            })
    },

    resetCity() {
        return { type: RESET_CITY }
    },
}

export default CityAction
