/* eslint-disable linebreak-style */
import { every, template, uniqBy } from 'lodash'
import i18n from 'simple-react-i18n'
import { WaitAction } from 'react-aquasys'
import ToastrAction from '../../../utils/ToastrAction'
import {
    RECEIVE_CMS_CATEGORIES,
    RECEIVE_CMS_EVENT,
    RECEIVE_CMS_EVENTS,
    RECEIVE_EVENT,
    RECEIVE_HYDROMETRY_STATION_ALL_EVENTS,
    RECEIVE_INSTALLATION_ALL_EVENTS,
    RECEIVE_MONITORED_STATIONS_EVENTS,
    RECEIVE_PIEZOMETRY_ALL_EVENTS,
    RECEIVE_QUALITOMETRY_ALL_EVENTS,
    RECEIVE_STATION_EVENTS,
    RESET_CMS_EVENT,
    RESET_EVENT,
    RESET_STATION_EVENTS,
    UPDATE_CMS_CATEGORIES,
    RECEIVE_PIEZOMETER_DIAGNOSTICS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES,
    RECEIVE_PIEZOMETER_SOLUTIONS,
    RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS,
    RECEIVE_PIEZOMETER_ACTIONS,
    RECEIVE_CMS_DOCUMENT,
} from '../constants/EventsConstants'
import ApplicationConf from '../../../conf/ApplicationConf'
import {
    checkAuth,
    checkError,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../../utils/ActionUtils'
import AppStore from '../../../store/Appstore'
import UserAction from '../../administration/components/user/actions/UserAction'

const EventsAction = {
    receiveCMSEvents(cmsEvents) {
        return { type: RECEIVE_CMS_EVENTS, cmsEvents }
    },

    receiveEvent(event) {
        return { type: RECEIVE_EVENT, event }
    },

    resetEvent() {
        return { type: RESET_EVENT }
    },

    resetCmsEvent() {
        return { type: RESET_CMS_EVENT }
    },

    receiveStationEvents(events) {
        return { type: RECEIVE_STATION_EVENTS, events }
    },

    resetStationEvents() {
        return { type: RESET_STATION_EVENTS }
    },

    receiveQualityAllEvents(qualitometryAllEvents) {
        return { type: RECEIVE_QUALITOMETRY_ALL_EVENTS, qualitometryAllEvents }
    },

    promiseQualityAllEvents() {
        return fetch(ApplicationConf.qualitometer.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchQualiyAllEvents() {
        return (dispatch) => EventsAction.promiseQualityAllEvents()
            .then((json) => {
                dispatch(EventsAction.receiveQualityAllEvents(json))
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.qualityEvents + err)
                ToastrAction.error(i18n.updateError + i18n.qualityEvents)
            })
    },

    receivePiezometryAllEvents(piezometryAllEvents) {
        return { type: RECEIVE_PIEZOMETRY_ALL_EVENTS, piezometryAllEvents }
    },

    promisePiezometryAllEvents() {
        return fetch(ApplicationConf.piezometer.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPiezometryAllEvents() {
        return (dispatch) => EventsAction.promisePiezometryAllEvents()
            .then((json) => {
                dispatch(EventsAction.receivePiezometryAllEvents(json))
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.piezometryEvents + err)
                ToastrAction.error(i18n.updateError + i18n.piezometryEvents)
            })
    },

    receiveHydrometryAllEvents(hydrometryStationAllEvents) {
        return { type: RECEIVE_HYDROMETRY_STATION_ALL_EVENTS, hydrometryStationAllEvents }
    },

    promiseHydrometryAllEvents() {
        return fetch(ApplicationConf.hydrometricStation.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchHydrometryAllEvents() {
        return (dispatch) => EventsAction.promiseHydrometryAllEvents()
            .then((json) => {
                dispatch(EventsAction.receiveHydrometryAllEvents(json))
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.hydrometryEvents + err)
                ToastrAction.error(i18n.updateError + i18n.hydrometryEvents)
            })
    },

    receiveInstallationAllEvents(installationAllEvents) {
        return { type: RECEIVE_INSTALLATION_ALL_EVENTS, installationAllEvents }
    },

    promiseInstallationAllEvents() {
        return fetch(ApplicationConf.installation.getAllEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchInstallationAllEvents() {
        return (dispatch) => EventsAction.promiseInstallationAllEvents()
            .then((json) => {
                dispatch(EventsAction.receiveInstallationAllEvents(json))
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.installationEvents + err)
                ToastrAction.error(i18n.updateError + i18n.installationEvents)
            })
    },

    fetchMonitoredStations() {
        return (dispatch) => fetch(ApplicationConf.station.getMonitoredStations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(EventsAction.receiveMonitoredStationsEvents(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.monitoredStations} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.monitoredStations)
            })
    },

    receiveMonitoredStationsEvents(stationEvents) {
        return { type: RECEIVE_MONITORED_STATIONS_EVENTS, stationEvents }
    },

    promiseCMSCategories() {
        return fetch(ApplicationConf.referencial.cmsCategories(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCMSCategories() {
        return (dispatch) => EventsAction.promiseCMSCategories()
            .then((json) => {
                dispatch(EventsAction.receiveCMSCategories(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError + i18n.categories} : ${err}`)
                ToastrAction.error(i18n.fetchError + i18n.categories)
            })
    },

    receiveCMSCategories(categories) {
        return { type: RECEIVE_CMS_CATEGORIES, categories }
    },

    loadCMSData(callback = () => {}, progressCallback = () => {}) {
        return (dispatch) => {
            const promises = [
                EventsAction.promiseCMSCategories(),
                EventsAction.promiseCMSEvents(),
                UserAction.promiseUsers(),
            ]
            return promiseAllProgress(promises, progressCallback)
                .then((jsonTab) => {
                    dispatch(EventsAction.receiveCMSCategories(jsonTab[0]))
                    dispatch(EventsAction.receiveCMSEvents(jsonTab[1]))
                    dispatch(UserAction.receiveUsers(jsonTab[2]))
                    callback()
                })
                .catch((err) => {
                    console.error(`${i18n.loadError} : ${err}`)
                    ToastrAction.error(i18n.loadError)
                })
        }
    },

    updateCMSCategory(categories, allCategories, callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const promises = categories.map((category) => fetch(ApplicationConf.referencial.cmsCategories(), {
                method: category.isNew ? 'POST' : 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(category),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError))
            return Promise.all(promises)
                .then((jsonTab) => {
                    if (every(jsonTab, (result) => result.insert === 1 || result.update === 1)) {
                        const newCategories = jsonTab.map((result, index) => {
                            if (result.insert === 1) {
                                return { ...categories[index], id: result.id }
                            }
                            return categories[index]
                        })
                        ToastrAction.success(i18n.categoryUpdateSuccess)
                        dispatch({
                            type: UPDATE_CMS_CATEGORIES,
                            categories: uniqBy(newCategories.concat(allCategories), 'id'),
                        })
                        dispatch(WaitAction.waitStop())
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(i18n.updateError + i18n.categories + err)
                    ToastrAction.error(i18n.updateError + i18n.categories)
                })
        }
    },

    deleteCMSCategory(category) {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsCategories(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(category),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.update === 1) {
                    ToastrAction.success(i18n.categoryDeleteSuccess)
                    dispatch(EventsAction.fetchCMSCategories())
                } else {
                    console.error(i18n.deleteError + i18n.categories)
                    ToastrAction.error(i18n.deleteError + i18n.categories)
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.categories + err)
                ToastrAction.error(i18n.deleteError + i18n.categories)
            })
    },

    receiveCMSEvent(cmsEvent) {
        return { type: RECEIVE_CMS_EVENT, cmsEvent }
    },

    fetchCMSEvent(id, callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.cmsEvent(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(EventsAction.receiveCMSEvent(json))
                    callback()
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    console.error(`${i18n.fetchError + i18n.event} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.event)
                })
        }
    },

    promiseCMSEvents() {
        return fetch(ApplicationConf.referencial.cmsEvents(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCMSEvents(page) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return EventsAction.promiseCMSEvents()
                .then((json = []) => {
                    dispatch(EventsAction.receiveCMSEvents(json))
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError + i18n.event} : ${err}`)
                    if (page !== 'dashboard') {
                        ToastrAction.error(i18n.fetchError + i18n.event)
                    }
                })
        }
    },

    updateCMSEvent(event, isNewEvent) {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEvents(), {
            method: isNewEvent ? 'POST' : 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(event),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if ((isNewEvent && json.insert) || (!isNewEvent && json.update)) {
                    const category = AppStore.getState().EventsReducer.cmsCategories.find(
                        (c) => c.id == event.idCategory,
                    )
                    const categoryName = category ? category.title : ''
                    ToastrAction.success(
                        template(
                            isNewEvent ? i18n.contentAddSuccess : i18n.contentUpdateSuccess,
                        )({ content: categoryName }),
                    )
                    dispatch(EventsAction.fetchCMSEvents())
                } else {
                    console.error(i18n.updateError + i18n.event)
                    ToastrAction.error(i18n.updateError + i18n.event)
                }
            })
            .catch((err) => {
                console.error(i18n.updateError + i18n.event + err)
                ToastrAction.error(i18n.updateError + i18n.event)
            })
    },

    postFacebookCMSEvent: (event) => () => fetch(ApplicationConf.referencial.cmsEventFacebook(event.id), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(event),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json) => {
            if (!json.error) {
                ToastrAction.success(i18n.contentSuccessfulyShared)
            } else {
                ToastrAction.error(i18n.contentCannotBeShared)
            }
        })
        .catch((err) => {
            console.error(i18n.contentCannotBeShared + i18n.event + err)
            ToastrAction.error(i18n.contentCannotBeShared)
        }),

    deleteCMSEvent(event) {
        return (dispatch) => fetch(ApplicationConf.referencial.cmsEvents(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(event),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.delete) {
                    const category = AppStore.getState().EventsReducer.cmsCategories.find(
                        (c) => c.id == event.idCategory,
                    )
                    const categoryName = category ? category.title : ''
                    ToastrAction.success(
                        template(i18n.contentDeleteSuccess)({ content: categoryName }),
                    )
                    dispatch(EventsAction.fetchCMSEvents())
                } else {
                    console.error(i18n.deleteError + i18n.event)
                    ToastrAction.error(i18n.deleteError + i18n.event)
                }
            })
            .catch((err) => {
                console.error(i18n.deleteError + i18n.event + err)
                ToastrAction.error(i18n.deleteError + i18n.event)
            })
    },

    createPiezometryEventWithDiagnostic(stationId, eventWithDiagnostic) {
        return (dispatch) => fetch(ApplicationConf.piezometer.eventsWithDiagnostic(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(eventWithDiagnostic),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.eventId) {
                    dispatch(EventsAction.resetEvent())
                    dispatch(EventsAction.fetchEvent('piezometry', stationId, json.eventId))
                    dispatch(EventsAction.fetchStationEvents('piezometry', stationId))
                    ToastrAction.success(i18n.eventAddSuccess)
                } else {
                    ToastrAction.error(i18n.addError + i18n.event)
                    console.error(`${i18n.addError + i18n.event} : ${json}`)
                }
            })
            .catch((err) => {
                console.error(`${i18n.addError + i18n.event} : ${err}`)
                ToastrAction.error(i18n.addError + i18n.event)
            })
    },

    updatePiezometryEventWithDiagnostic(stationId, eventId, eventWithDiagnostic) {
        return (dispatch) => fetch(ApplicationConf.piezometer.eventWithDiagnostic(eventId), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(eventWithDiagnostic),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                if (json.eventId) {
                    dispatch(EventsAction.resetEvent())
                    dispatch(EventsAction.fetchEvent('piezometry', stationId, json.eventId))
                    dispatch(EventsAction.fetchStationEvents('piezometry', stationId))
                    ToastrAction.success(i18n.eventAddSuccess)
                }
            })
            .catch((err) => {
                console.error(`${i18n.addError + i18n.event} : ${err}`)
                ToastrAction.error(i18n.addError + i18n.event)
            })
    },

    receivePiezometerDiagnostics(piezometerDiagnostics) {
        return { type: RECEIVE_PIEZOMETER_DIAGNOSTICS, piezometerDiagnostics }
    },

    fetchPiezometerDiagnostics() {
        return (dispatch) => fetch(ApplicationConf.piezometer.diagnostic(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerDiagnostics(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },

    receivePiezometerDiagnosticsLinkMaterials(piezometerDiagnosticsLinkMaterials) {
        return {
            type: RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS,
            piezometerDiagnosticsLinkMaterials,
        }
    },

    fetchPiezometerDiagnosticsLinkMaterials() {
        return (dispatch) => fetch(ApplicationConf.piezometer.diagnosticLinkMaterial(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerDiagnosticsLinkMaterials(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },

    receivePiezometerDiagnosticsLinkEventTypes(piezometerDiagnosticsLinkEventTypes) {
        return {
            type: RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES,
            piezometerDiagnosticsLinkEventTypes,
        }
    },

    fetchPiezometerDiagnosticsLinkEventTypes() {
        return (dispatch) => fetch(ApplicationConf.piezometer.diagnosticLinkEventType(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerDiagnosticsLinkEventTypes(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },

    receivePiezometerSolutions(piezometerSolutions) {
        return { type: RECEIVE_PIEZOMETER_SOLUTIONS, piezometerSolutions }
    },

    fetchPiezometerSolutions() {
        return (dispatch) => fetch(ApplicationConf.piezometer.solution(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerSolutions(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },

    receivePiezometerSolutionsLinkProblems(piezometerSolutionsLinkProblems) {
        return { type: RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS, piezometerSolutionsLinkProblems }
    },

    fetchPiezometerSolutionsLinkProblems() {
        return (dispatch) => fetch(ApplicationConf.piezometer.solutionLinkProblem(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerSolutionsLinkProblems(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },

    receivePiezometerActions(piezometerActions) {
        return { type: RECEIVE_PIEZOMETER_ACTIONS, piezometerActions }
    },

    fetchPiezometerActions(piezoId, eventId) {
        return (dispatch) => fetch(ApplicationConf.piezometer.diagnosticAction(piezoId, eventId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                dispatch(EventsAction.receivePiezometerActions(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },

    loadGlobalEventsApp(callback = () => {}, progressCallback = () => {}) {
        return (dispatch) => promiseAllProgress(
            [
                EventsAction.promiseQualityAllEvents(),
                EventsAction.promisePiezometryAllEvents(),
                EventsAction.promiseHydrometryAllEvents(),
                EventsAction.promiseInstallationAllEvents(),
            ],
            progressCallback,
        )
            .then((jsonTab) => {
                dispatch(EventsAction.receiveQualityAllEvents(jsonTab[0]))
                dispatch(EventsAction.receivePiezometryAllEvents(jsonTab[1]))
                dispatch(EventsAction.receiveHydrometryAllEvents(jsonTab[2]))
                dispatch(EventsAction.receiveInstallationAllEvents(jsonTab[3]))
                callback()
            })
            .catch((err) => {
                console.error(`${i18n.loadError} : ${err}`)
                ToastrAction.error(i18n.loadError)
            })
    },

    saveCmsDocument(document) {
        return () => fetch(ApplicationConf.files.saveCmsDoc(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(document),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (!json.error) {
                    ToastrAction.success(i18n.contentSuccessfulyShared)
                } else {
                    ToastrAction.error(i18n.contentCannotBeShared)
                }
            })
            .catch((err) => {
                console.error(i18n.contentCannotBeShared + i18n.event + err)
                ToastrAction.error(i18n.contentCannotBeShared)
            })
    },

    receiveCMSDocument(document) {
        return { type: RECEIVE_CMS_DOCUMENT, document }
    },

    fetchCMSDocument(name) {
        return (dispatch) => fetch(ApplicationConf.files.getDocument(name), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json) => {
                dispatch(EventsAction.receiveCMSDocument(json))
            })
            .catch((err) => {
                console.error(`${i18n.fetchError} : ${err}`)
                ToastrAction.error(i18n.fetchError)
            })
    },
}

export default EventsAction
