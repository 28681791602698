import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const GreenSwitch = withStyles({
    switchBase: {
        color: '#44a695',
        '&$checked': {
            color: '#00806B',
        },
        '&$checked + $track': {
            backgroundColor: '#00806B',
        },
    },
    checked: {},
    track: {},
})(Switch)

export default function CustomizedSwitch({ checked, onChange, disabled, label }) {
    return (
        <FormGroup style={{ margin: '4px 0', width: '65%' }}>
            <FormControlLabel
                disabled={disabled}
                control={<GreenSwitch checked={checked} onChange={onChange} />}
                label={label}
            />
        </FormGroup>
    )
}

CustomizedSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
}
