import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import i18n from 'simple-react-i18n'
import Tabs from '@material-ui/core/Tabs'
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@material-ui/core'
import styled from 'styled-components'
import TerritoryAction from '../actions/TerritoryAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import HomeAction from '../../home/actions/HomeAction'
import ActionComponent from '../../../components/actions/ActionComponent'
import { MainContainer } from '../../../styles/container'
import { SpaceAround } from '../../../styles/main'
import ProgressBar from '../../../components/ProgressBar'
import { TabStyle } from '../../../components/TabStyle'
import ScenarDescriptive from './ScenarDescriptive'
import ScenarResult from './ScenarResult'
import ScenarObjectifs from './ScenarObjectifs'
import ScenarRotation from './ScenarRotation'
import { PATH_TERRITORY_MATRICE } from '../../home/constants/RouteConstants'
import { mainBlue } from '../../../styles/theme'
import { getUser, getLogin } from '../../../utils/SettingUtils'
import { hasValue } from '../../../utils/NumberUtil'
import ToastrAction from '../../../utils/ToastrAction'
import { GreenButton } from '../../../styles/button'

const ButtonIcon = styled(Icon)`
    color: #f9f8f6;
`

class ScenarioDetail extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            isRunning: false,
            activeTab: props.scenario.scType === -1 ? 'results' : 'descriptive',
            editMode: false,
            scenario: {},
            initialScenario: {},
            indicateurs: [],
            openDelete: false,
        }
    }

    setInitialScenario(scenario) {
        const objectifs = [
            {
                id: 1,
                scid: this.props.scenario.id,
                category: -2,
                mode: null,
                val_1: null,
                val_2: null,
                actif: false,
                description: '',
                objectifRef: [],
            },
            {
                id: 2,
                scid: this.props.scenario.id,
                category: 1,
                mode: null,
                val_1: null,
                val_2: null,
                actif: false,
                description: '',
                objectifRef: [],
            },
            {
                id: 3,
                scid: this.props.scenario.id,
                category: 0,
                mode: null,
                val_1: null,
                val_2: null,
                actif: false,
                description: '',
                objectifRef: [],
            },
        ]
        const constraintsAB = this.props.rotationsAB.map((r) => ({
            ...r,
            scid: this.props.scenario.id,
        }))
        const constraintsEco = this.props.rotationsEco.map((r) => ({
            ...r,
            scid: this.props.scenario.id,
        }))
        const scenarioFormatted = {
            ...scenario,
            objectifs: scenario.objectifs.length < 1 ? objectifs : [...scenario.objectifs],
            constraintsAB: scenario.constraintsAB.length < 1 ? constraintsAB : [...scenario.constraintsAB],
            constraintsEco: scenario.constraintsEco.length < 1 ? constraintsEco : [...scenario.constraintsEco],
        }
        this.setState({
            initialScenario: scenario,
            scenario: scenarioFormatted,
        })
    }

    componentDidMount() {
        this.setScenario()
    }

    verifyScenario = (scenario) => {
        let isValid = true
        scenario.objectifs.forEach((o) => {
            if (o.actif) {
                const culturesSelected = o.objectifRef.filter((c) => c.refid && c.typeR === 'cid')
                const zonesSelected = o.objectifRef.filter((z) => z.refid && z.typeR === 'zid')
                const conduitesSelected = o.objectifRef.filter((mc) => mc.refid && mc.typeR === 'mcid')
                if ((!hasValue(o.category) || o.category === -2)
                    || (!culturesSelected.length)
                    || (!zonesSelected.length)
                    || (!conduitesSelected.length)
                    || (!hasValue(o.mode))
                    || (!hasValue(o.val_1))
                ) isValid = false
                if (o.category === 0) {
                    const culturesSelectedPour = o.objectifRef.filter((c) => c.refid && c.typeR === 'ofCid')
                    const zonesSelectedPour = o.objectifRef.filter((z) => z.refid && z.typeR === 'ofZid')
                    const conduitesSelectedPour = o.objectifRef.filter((mc) => mc.refid && mc.typeR === 'ofMCid')
                    if (!hasValue(o.val_2)) isValid = false
                    if (o.mode === 1
                        && (!culturesSelectedPour.length
                            || !zonesSelectedPour.length
                            || !conduitesSelectedPour.length
                        )) isValid = false
                }
            }
        })
        return isValid
    }

    setScenario = () => {
        this.props.fetchRotationAB()
        this.props.fetchRotationEco()
        this.props.fetchIndicateurs()
        this.props.fetchScenario(this.props.match.params.id).then(() => {
            const { scenario } = this.props
            if (scenario && scenario.mid) {
                this.props.fetchScenariosMatrice(scenario.mid).then(() => {
                    this.props.fetchMatrice(scenario.mid).then(() => {
                        const { matrice, territoire } = this.props
                        if (matrice?.tid) {
                            this.props.fetchElementaryBricks(matrice.tid)
                            this.getIndicateurs(matrice)
                            this.setInitialScenario(scenario)
                            this.props.fetchAssolementsTerritoires(matrice.tid)
                            if (territoire.id !== matrice.tid) {
                                this.props.fetchTerritory(matrice.tid).then(() => this.setTitle())
                            } else {
                                this.setTitle()
                            }
                        }
                    })
                })
            }
        })
    }

    setTitle = () => {
        const { scenario, matrice, territoire } = this.props
        const scenarioValid = this.verifyScenario(scenario)
        this.props.setTitle([
            {
                title: i18n.territories,
                href: 'territories',
            },
            {
                title: territoire.name,
                href: `territories/territory/${territoire.id}`,
            },
            {
                title: matrice.name,
                href: `territories/matrice/${matrice.mid}`,
            },
            {
                title: scenario.name,
                href: `territories/scenario/${scenario.id}`,
            },
        ])
        this.setState({
            activeTab: scenario.scType === -1 ? 'results' : 'descriptive',
            dataLoaded: true,
            scenarioValid,
        })
        this.setReadMode()
    }

    setEditMode = () => {
        this.setState({ editMode: true })
        this.setActions({
            save: () => {
                this.onSaveEvent()
                this.setReadMode()
            },
            cancel: () => {
                this.setState({ dataLoaded: false })
                this.setScenario()
            },
        })
    }

    onSaveEvent = () => {
        this.setState({
            dataLoaded: false,
        })
        const { scenario } = this.state
        const newScenar = {
            ...scenario,
            usermaj: getLogin(),
        }
        this.props.updateScenario(this.props.match.params.id, newScenar).then(() => {
            this.setScenario()
        })
    }

    setReadMode = (isRunning = false) => {
        const { territoire } = this.props
        const { scenario } = this.state
        if (
            territoire
            && territoire.statut !== 2
            && (getUser().isAdmin === '1'
                || (getUser().labo === '1' && !territoire.referencial))
        ) {
            this.setState({ editMode: false })
            if (isRunning || this.state.isRunning) {
                this.setActions({})
            } else if (scenario.scType === -1) {
                this.setActions({
                    start: () => this.startScenario(),
                })
            } else {
                this.setActions({
                    edit: () => this.setEditMode(),
                    duplicate: () => {
                        this.setState({ dataLoaded: false })
                        const name = `${this.state.scenario.name}_dupliqué`
                        const newScenario = {
                            ...this.state.scenario,
                            name,
                        }
                        this.props
                            .createScenario(newScenario, (this.props.location.state || {}).indicateurs)
                            .then(() => this.setScenario())
                    },
                    deleteCheck: () => this.setState({ openDelete: true }),
                    start: () => this.startScenario(),
                })
            }
        } else {
            this.setActions({})
        }
    }

    startScenario = () => {
        const { scenario, scenarioValid } = this.state
        if (scenarioValid) {
            this.setState({ isRunning: true, activeTab: 'results' })
            this.setReadMode(true)
            this.props.startScenario(scenario.id).then(() => {
                this.props.fetchScenario(scenario.id).then(() => {
                    this.setState({
                        scenario: this.props.scenario,
                        isRunning: false,
                    })
                    this.setReadMode()
                })
            })
        } else {
            ToastrAction.error(`${i18n.error} : ${i18n.scenarioIncomplet}`)
        }
    }

    getIndicateurs(matrice) {
        if (matrice.indicateurs) {
            const allIds = matrice.indicateurs.map((i) => i.iid)
            this.props.fetchAllIndicateurs(allIds).then((indicateurs) => {
                const { indicateursLink } = indicateurs.reduce(
                    (acc, indicateur) => {
                        acc.indicateursLink.push({
                            id: indicateur.id,
                            name: indicateur.name,
                        })
                        return acc
                    },
                    {
                        indicateursLink: [],
                    },
                )
                this.setState({ indicateurs: [...indicateursLink, { id: -1, name: 'Déviation' }] })
            })
        }
    }

    getPanel = () => {
        const { scenario, editMode, isRunning, indicateurs } = this.state
        switch (this.state.activeTab) {
            case 'descriptive':
                return (
                    <ScenarDescriptive
                        editMode={editMode}
                        setEditMode={this.setEditMode}
                        setReadMode={this.setReadMode}
                        scenario={scenario}
                        indicateurs={indicateurs}
                        onChangeScenario={this.onChangeScenario}
                    />
                )
            case 'constraintes':
                return (
                    <ScenarObjectifs
                        editMode={editMode}
                        setEditMode={this.setEditMode}
                        setReadMode={this.setReadMode}
                        scenario={scenario}
                        indicateurs={indicateurs}
                        onChangeScenario={this.onChangeScenario}
                    />
                )
            case 'rotations':
                return (
                    <ScenarRotation
                        editMode={editMode}
                        setEditMode={this.setEditMode}
                        setReadMode={this.setReadMode}
                        scenario={scenario}
                        indicateurs={indicateurs}
                        onChangeScenario={this.onChangeScenario}
                    />
                )
            case 'results':
                return (
                    <ScenarResult
                        editMode={editMode}
                        setEditMode={this.setEditMode}
                        setReadMode={this.setReadMode}
                        scenario={scenario}
                        indicateurs={indicateurs}
                        onChangeScenario={this.onChangeScenario}
                        startScenario={this.startScenario}
                        isRunning={isRunning}
                        statut={this.props.territoire.statut}
                    />
                )
            default:
                return (
                    <ScenarDescriptive
                        editMode={editMode}
                        setEditMode={this.setEditMode}
                        setReadMode={this.setReadMode}
                        scenario={scenario}
                        indicateurs={indicateurs}
                        onChangeScenario={this.onChangeScenario}
                    />
                )
        }
    }

    getActive = (panel) => (panel === this.state.sortBy ? 'active' : '')

    handleChangeTab = (event, value) => {
        this.setState({ activeTab: value })
        this.getActive(value)
    }

    onChangeScenario = (key, value) => {
        let newScenario = {}
        if (key === 'iidDirection') {
            if (value === -1) {
                newScenario = {
                    ...this.state.scenario,
                    [key]: value,
                    direction: 0,
                }
            } else if (value === 1) {
                newScenario = {
                    ...this.state.scenario,
                    [key]: value,
                    objectifs: this.state.scenario.objectifs.map((obj) => {
                        if (obj.category === 1) {
                            return {
                                ...obj,
                                actif: false,
                            }
                        }
                        return obj
                    }),
                }
            } else {
                newScenario = {
                    ...this.state.scenario,
                    [key]: value,
                }
            }
        } else {
            newScenario = {
                ...this.state.scenario,
                [key]: value,
            }
        }
        this.setState({
            scenario: newScenario,
        })
    }

    getDialogDelete = () => {
        const { openDelete, scenario } = this.state
        const { matrice } = this.props
        return (
            <Dialog
                fullWidth
                maxWidth="md"
                open={openDelete}
                onClose={() => this.setState({ openDelete: false })}
            >
                <DialogTitle>{i18n.checkDelete}</DialogTitle>
                <DialogContent>Êtes-vous sûr de vouloir supprimer ce scénario ? Cette action est irréversible.</DialogContent>
                <DialogActions>
                    <GreenButton
                        onClick={() => this.setState({ openDelete: false })}
                        greenReverse
                        borderRadius
                        style={{ margin: '0 1rem 10px' }}
                    >
                        {i18n.cancel}
                    </GreenButton>
                    <GreenButton
                        onClick={() => {
                            this.props.deleteScenario(scenario.id).then(() => {
                                this.props.push({
                                    pathname: `${PATH_TERRITORY_MATRICE}/${matrice.mid}`,
                                    state: {
                                        scenarios: true,
                                    },
                                })
                            })
                        }}
                        borderRadius
                        style={{ margin: '0 1rem 10px' }}
                    >
                        {i18n.delete}
                    </GreenButton>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const { activeTab, dataLoaded, scenario, editMode } = this.state
        const { matrice } = this.props
        
        return (
            <>
                {dataLoaded ? (
                    <SpaceAround>
                        <MainContainer>
                            <GreenButton
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    marginLeft: 0,
                                    marginTop: 0,
                                }}
                                disabled={editMode}
                                onClick={() => this.props.push({
                                    pathname: `${PATH_TERRITORY_MATRICE}/${matrice.mid}`,
                                    state: {
                                        scenarios: true,
                                    },
                                })}
                            >
                                <ButtonIcon style={{ paddingRight: 10 }}>arrow_back</ButtonIcon>Retour à la liste des scénarios
                            </GreenButton>
                            <div style={{ width: '100%' }}>
                                <div style={{ margin: '1rem 0 2rem' }}>
                                    <Tabs
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none',
                                            },
                                        }}
                                        style={{
                                            boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
                                        }}
                                        variant="fullWidth"
                                        value={activeTab}
                                        onChange={this.handleChangeTab}
                                        aria-label="tabs"
                                    >
                                        <TabStyle value="descriptive" disabled={scenario.scType === -1} label={i18n.descriptive} />
                                        <TabStyle value="constraintes" disabled={scenario.scType === -1} label={i18n.objectifs} />
                                        <TabStyle value="rotations" disabled={scenario.scType === -1} label={i18n.reglesRotation} />
                                        <TabStyle value="results" label={i18n.results} />
                                    </Tabs>
                                    <div style={{ border: `${mainBlue} solid` }}>
                                        {this.getPanel()}
                                    </div>
                                </div>
                            </div>
                        </MainContainer>
                        {this.getDialogDelete()}
                    </SpaceAround>
                ) : <ProgressBar />}
            </>
        )
    }
}

ScenarioDetail.propTypes = {
    getLink: PropTypes.func,
    globalResearch: PropTypes.string,
}

const mapStateToProps = (store) => ({
    territoires: store.TerritoryReducer.territoires,
    territoire: store.TerritoryReducer.territoire,
    matrice: store.TerritoryReducer.matrice,
    scenario: store.TerritoryReducer.scenario,
    rotationsAB: store.ReferencialReducer.rotationsAB,
    rotationsEco: store.ReferencialReducer.rotationsEco,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchScenario: TerritoryAction.fetchScenario,
    updateScenario: TerritoryAction.updateScenario,
    deleteScenario: TerritoryAction.deleteScenario,
    startScenario: TerritoryAction.startScenario,
    createScenario: TerritoryAction.createScenario,
    fetchMatrice: TerritoryAction.fetchMatrice,
    fetchTerritory: TerritoryAction.fetchTerritory,
    fetchAssolementsTerritoires: TerritoryAction.fetchAssolementsTerritoires,
    fetchElementaryBricks: ReferencialAction.fetchElementaryBricks,
    fetchRotationAB: ReferencialAction.fetchRotationAB,
    fetchRotationEco: ReferencialAction.fetchRotationEco,
    fetchIndicateurs: ReferencialAction.fetchIndicateurs,
    fetchAllIndicateurs: ReferencialAction.fetchAllIndicateurs,
    fetchScenariosMatrice: TerritoryAction.fetchScenariosMatrice,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioDetail)
