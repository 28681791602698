export default class DtoTerritory {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.statut = obj.statut
        this.referencial = obj.referencial || false
        this.dateCreation = new Date(obj.datecreation)
        this.userMaj = obj.usermaj
        this.dateMaj = new Date(obj.datemaj)
        this.department = obj.department // Option[String],
        this.cityCode = obj.cityCode // Option[String],
        this.kml = obj.kml // Option[String],
        this.kmlFileName = obj.kml ? '1 fichier' : ''
        this.year = obj.year // Option[Int],
        this.cultures = obj.cultures
        this.indicateurs = obj.indicateurs
        this.modesConduites = obj.modesConduites
        this.utilisateurs = obj.utilisateurs
        this.zones = obj.zones
        this.surfacesCultures = obj.surfacesCultures || []
    }
}
