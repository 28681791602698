/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { Icon, Tooltip } from '@material-ui/core'
import UUID from 'uuid'
import { findIndex } from 'lodash'
import { setDeleteConfirmationModal } from '../../utils/FormUtils'
import ImportFileFormat from '../modal/ImportFileFormat'
import SieauAction from '../sieau/SieauAction'
import AppStore from '../../store/Appstore'

export class IAction extends Component {
    notifs() {
        return ''
    }

    content() {
        return ''
    }

    name() {
        return ''
    }

    render() {
        const dropDown = this.content() ? 'dropdown-button' : ''
        return (
            <div className="action-wrapper" style={{ transform: 'translateY(6px)' }}>
                <a
                    className={`right ${dropDown}`}
                    data-activates={this.name()}
                    data-position="bottom"
                    data-tooltip={this.label()}
                    onClick={this.fn()}
                >
                    <Tooltip title={this.label()} placement="bottom">
                        <Icon className="appbarIcon">{this.icon()}</Icon>
                    </Tooltip>
                    {this.notifs()}
                </a>
                {this.content()}
            </div>
        )
    }
}

export class Cancel extends IAction {
    fn = () => this.props.onCancel

    icon = () => 'undo'

    label = () => i18n.cancel
}

export class Clear extends IAction {
    fn = () => this.props.onClear

    icon = () => 'cancel'

    label = () => i18n.clean
}

export class Delete extends IAction {
    fn = () => () => setDeleteConfirmationModal(this.props.onDelete)

    icon = () => 'delete'

    label = () => i18n.delete
}

export class DeleteCheck extends IAction {
    fn = () => this.props.onDelete

    icon = () => 'delete'

    label = () => i18n.delete
}

export class Duplicate extends IAction {
    fn = () => this.props.onDuplicate

    icon = () => 'control_point_duplicate'

    label = () => i18n.duplicate
}

export class Edit extends IAction {
    fn = () => this.props.onEdit

    icon = () => 'edit'

    label = () => i18n.edit
}

export class New extends IAction {
    fn = () => this.props.onNew

    icon = () => 'note_add'

    label = () => i18n.new
}

export class Replace extends IAction {
    fn = () => this.props.onReplace

    icon = () => 'compare_arrows'

    label = () => i18n.replace
}

export class Export extends IAction {
    fn = () => this.props.onExport

    icon = () => 'get_app'

    label = () => i18n.export
}

export class Save extends IAction {
    fn = () => this.props.onSave

    icon = () => 'save'

    label = () => i18n.save
}

export class Start extends IAction {
    fn = () => this.props.onStart

    icon = () => 'play_circle_outline'

    label = () => i18n.startScenario
}

export class Purge extends IAction {
    fn = () => this.props.onPurge

    icon = () => 'delete_forever'

    label = () => i18n.purge
}

export class Compare extends IAction {
    fn = () => this.props.onCompare

    icon = () => 'compare'

    label = () => i18n.compare
}

export class FastArrowNav extends Component {
    getArrowElement(item, direction) {
        const onClick = item ? () => this.props.nav.onClick(item) : () => {}
        const tooltip = item ? this.props.nav.labels.map((o) => item[o] || '').join(' - ') : ''
        const disable = item ? 'tooltipped anim-hover' : 'item-disable non-clickable'
        const additionalClass = direction === 'down' ? 'no-margin' : ''
        return (
            <i
                key={item ? item.id : 0}
                className={`material-icons right ${additionalClass} ${disable}`}
                data-position="bottom"
                data-delay="50"
                data-tooltip={tooltip}
                onClick={onClick}
            >
                {`arrow_drop_${direction}`}
            </i>
        )
    }

    render() {
        const { array } = this.props.nav
        const current = findIndex(array, (o) => o.id === this.props.nav.currentId)
        const position = `${current + 1} sur ${array.length}`
        const prevArrow = this.getArrowElement(current === 0 ? null : array[current - 1], 'up')
        const postArrow = this.getArrowElement(
            current + 1 === array.length ? null : array[current + 1],
            'down',
        )
        return (
            <div>
                <a className="dropdown-button non-clickable">
                    {position}
                    {postArrow}
                    {prevArrow}
                </a>
            </div>
        )
    }
}

export class Links extends Component {
    render() {
        if (this.props.links && this.props.links.length > 0) {
            const links = this.props.links.map((link) => (
                <li>
                    <a href={link.href} target="_blank" rel="noreferrer">
                        <div className="row no-margin valign-wrapper dropdownLink collection-item">
                            <div className="no-margin no-padding col s3 center-align">
                                <img className="w-h-30" src={link.img} />
                            </div>
                            <div className="col s9">
                                <b>{link.label ? link.label : ''}</b>
                            </div>
                        </div>
                    </a>
                </li>
            ))
            return (
                <div className="action-wrapper">
                    <ul id="dropdownLinks" className="dropdown-content dropdownLinks">
                        {links}
                    </ul>
                    <a
                        className="dropdown-button anim-hover nav-actions"
                        data-activates="dropdownLinks"
                    >
                        {i18n.links}
                    </a>
                </div>
            )
        }
        return null
    }
}

export class Update extends IAction {
    fn = () => () => {
        const id = UUID.v4()
        const content = (
            <ImportFileFormat
                defaultFormat={this.props.defaultFormat}
                onUpdate={this.props.onUpdate}
            />
        )
        const actions = (
            <div>
                <a className="waves-effect waves-teal btn-flat modal-close">{i18n.close}</a>
            </div>
        )
        const popup = {
            id,
            actions,
            content,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    icon = () => 'sync'

    label = () => i18n.update
}

export class Shared extends Component {
    render() {
        if (this.props.links && this.props.links.length > 0) {
            const links = this.props.links.map((link) => (
                <li>
                    <a onClick={() => link.onClick(link)}>
                        <div className="row no-margin valign-wrapper dropdownLink collection-item">
                            <div className="no-margin no-padding col s3 center-align">
                                <img className="w-h-30" src={link.img} />
                            </div>
                            <div className="col s9">
                                <b>{link.label ? link.label : ''}</b>
                            </div>
                        </div>
                    </a>
                </li>
            ))
            return (
                <div className="action-wrapper">
                    <ul id="dropdownLinks" className="dropdown-content dropdownLinks">
                        {links}
                    </ul>
                    <a
                        className="dropdown-button anim-hover nav-actions"
                        data-activates="dropdownLinks"
                    >
                        <i className="material-icons left no-margin">share</i>
                    </a>
                </div>
            )
        }
        return null
    }
}
