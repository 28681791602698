import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ApplicationConf from '../../../conf/ApplicationConf'
import ToastrAction from '../../ToastrAction'
import { getJson, getAuthorization, checkAuth } from '../../ActionUtils'

const JobAction = {
    uploadFile(parameters) {
        return () => fetch(ApplicationConf.files.uploadFtp(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(parameters),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json) => {
                if (json.insert) {
                    return json
                }
                throw new Error(json)
            })
            .catch((err) => {
                console.error(err)
                ToastrAction.error(i18n.addError + i18n.element)
            })
    },

    promiseExecuteJob: (id) => fetch(ApplicationConf.job.execute(id), {
        method: 'POST',
        headers: getAuthorization(),
    })
        .then(getJson),
}

export default JobAction
