import i18n from 'simple-react-i18n'
import { hasValue } from './NumberUtil'

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

const getStringWithoutSpace = (string) => string.replace(/\s/g, '')

const substringText = (text, shortNumber = 50) => {
    if (text) {
        if (text.length > shortNumber) {
            return `${text.substring(text, shortNumber)}...`
        }
        return text
    }
    return ''
}

const i18nize = (labelObjects) => labelObjects.map((obj) => ({ ...obj, label: i18n[obj.label] }))

const geti18n = (i18nString) => (i18nString ? i18n[i18nString] : '')

const getI18nTitleDataLength = (i18nSingular, i18nPlurial = '', datasLength = 0) => {
    if (i18nPlurial) {
        if (datasLength > 1) {
            return i18nPlurial
        }
        return i18nSingular
    }
    return i18nSingular
}

const getI18nTitleData = (i18nSingular, i18nPlurial = '', datas = []) => {
    if (i18nPlurial) {
        if (datas) {
            return getI18nTitleDataLength(i18nSingular, i18nPlurial, datas.length)
        }
        return getI18nTitleDataLength(i18nSingular, i18nPlurial)
    }
    return i18nSingular
}

const searchAllCharacters = (str) => str.toLowerCase()
    .replace(new RegExp('\\s', 'g'), '')
    .replace(new RegExp('[àáâãäå]', 'g'), 'a')
    .replace(new RegExp('æ', 'g'), 'ae')
    .replace(new RegExp('ç', 'g'), 'c')
    .replace(new RegExp('[èéêë]', 'g'), 'e')
    .replace(new RegExp('[ìíîï]', 'g'), 'i')
    .replace(new RegExp('ñ', 'g'), 'n')
    .replace(new RegExp('[òóôõö]', 'g'), 'o')
    .replace(new RegExp('œ', 'g'), 'oe')
    .replace(new RegExp('[ùúûü]', 'g'), 'u')
    .replace(new RegExp('[ýÿ]', 'g'), 'y')
    .replace(new RegExp('\\W', 'g'), '')

const getSandreLabel = (sandreCodes, field, code) => {
    const found = sandreCodes.find((c) => c.field == field && c.code == code)
    return found ? found.name : null
}

const formatMilliers = (nb) => {
    const string = `${hasValue(nb) ? nb : ''}`
    const x = string.split('.')
    let x1 = x[0]
    const x2 = x.length > 1 ? `.${x[1]}` : ''
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2')
    }
    return x1 + x2
}

export {
    getStringWithoutSpace,
    substringText,
    i18nize,
    geti18n,
    searchAllCharacters,
    getSandreLabel,
    getI18nTitleData,
    getI18nTitleDataLength,
    capitalizeFirstLetter,
    formatMilliers,
}
